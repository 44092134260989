import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingMastersLedger extends Component{

	constructor(){
		super();

		this.state = {
			data_rows: [],
			new_data_row: {},
			tableFields: [
				{"value": "id", "text": "LEDGER ID", "type": "field"},
				{"value": "ledger_name", "text": "Ledger Name", "type": "field"},
				{"value": "group", "text": "Group", "type": "select"},
				{"value": "opening_balance", "text": "Opening Balance", "type": "field"},
				{"value": "bank_name", "text": "Bank Name", "type": "field"},
				{"value": "bank_branch", "text": "Bank Branch", "type": "field"},
				{"value": "account_number", "text": "Account Number", "type": "field"},
				{"value": "IFSC", "text": "IFSC Code", "type": "field"},
				{"value": "MICR", "text": "MICR Code", "type": "field"},
			],
			defaultSize: 'small',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'ledger_name',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'ledger_name',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_group: [],
		}
	}

	componentDidMount() {
		Client.getAccountingMastersGroup().then(res=>{
			var options_group = []
			res.data_rows.forEach(function(data_row) {
				options_group.push({text: data_row.group_name, value: data_row.id})
			})
			this.setState({
				options_group: options_group,
			})
		})
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createAccountingMastersLedger(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getAccountingMastersLedger(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}

	render(){
		return(
			<div>
				<Button size={this.state.defaultSize} onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
				<div style={{float: 'right'}}>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.tableFields}
							name="data_rows_search_field"
							value={this.state.data_rows_search_field}
							placeholder="Search Field"
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					{
						this.state.data_rows_search_type == 'field'
						?
						<Input type="text"
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
						:
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
					}

					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					<Dropdown
						name='data_rows_limit'
						inline
						options={this.state.options_data_rows_limit}
						value={this.state.data_rows_limit}
						onChange={(e, data) => this.changeStateVariable(e, data)}
				    />
					{
						'Showing ' + this.state.data_rows_offset + ' to ' +
						(
							(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
							(this.state.data_rows_offset + this.state.data_rows_limit) :
							this.state.data_rows_size
						) +
						' of ' + this.state.data_rows_size + ' '
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Input type="text" required fluid name="ledger_name" value={this.state.new_data_row.ledger_name} label="Ledger Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Select selection search options={this.state.options_group} type="text" required fluid name="group" value={this.state.new_data_row.group} label="Parent Group" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Input type="number" required fluid name="opening_balance" value={this.state.new_data_row.opening_balance} label="Opening Balance" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="bank_name" value={this.state.new_data_row.bank_name} label="Bank Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid name="bank_branch" value={this.state.new_data_row.bank_branch} label="Bank Branch" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Input type="text" fluid name="account_number" value={this.state.new_data_row.account_number} label="Account Number" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="IFSC" value={this.state.new_data_row.IFSC} label="IFSC" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid name="MICR" value={this.state.new_data_row.MICR} label="MICR" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default AccountingMastersLedger;
