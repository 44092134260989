import React, {Component} from  'react';

import { Table } from 'semantic-ui-react';

import Client from '../services/Client'

class OrderRow extends Component{

    constructor(props){
        super(props);

        this.state = {
            orderDetails: {}
        }
    }

    componentDidMount() {
        Client.getOrderDetails(this.props.order.orderId).then((res)=>{
            this.setState({
                orderDetails: res,
            })
        })
    }

    render(){

        return(
            <Table.Row>
                <Table.Cell>{this.props.order.orderId}</Table.Cell>
                <Table.Cell>{this.props.order.orderDateAndTime ? this.props.order.orderDateAndTime.substr(6,10) + ', ' + this.props.order.orderDateAndTime.substr(23,5) : null}</Table.Cell>
                <Table.Cell>{this.props.order.deliveryAddress.toUpperCase()[0]+this.props.order.deliveryAddress.substring(1)}</Table.Cell>
                <Table.Cell>{this.props.order.customerName}</Table.Cell>
                <Table.Cell>{this.props.order.customerPhone}</Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>Rs. {this.props.order.amount}</Table.Cell>
                <Table.Cell>{this.props.order.orderStatus}</Table.Cell>
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.orderDetails.payment}</Table.Cell>
                    :
                    null
                }
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.orderDetails.paymentStatus}</Table.Cell>
                    :
                    null
                }
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.props.order.products}</Table.Cell>
                    :
                    null
                }
            </Table.Row>
        )
    };
}

export default OrderRow;
