import React from 'react'
import {Card, Dropdown, Input, Button,Loader,Segment,Dimmer,Icon, Image, Checkbox, Grid, Table, Form} from 'semantic-ui-react'
import Client from '../services/Client';
// import '../style/dashboard.css'
import { BarChart,Bar,LineChart, Line,XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
class DashboardInventory extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tableFields: [
				{"value": "name", "text": "Name", "type": "field"},
				{"value": "description", "text": "Descpription", "type": "field"},
				{"value": "sale", "text": "Sale", "type": "field"},
				{"value": "quotation", "text": "Quotation", "type": "field"},
				{"value": "QSR", "text": "QSR", "type": "field"},
				
            ],
            sort_on:'sale',
            data_rows:[

            ],
            options_type: [
				{text:'Product', value: 1},
				{text:'Service', value: 2}
			],
			options_category: [
				// product options
				{text:'Stationery', value: 'Stationery',type:'1'},
				{text:'Electrical Parts', value: 'Electrical Parts',type:'1'},
				{text:'General HardWare', value: 'General HardWare',type:'1'},
				{text:'AC Parts', value: 'AC Parts',type:'1'},
				{text:'Generator Parts', value: 'Generator Parts',type:'1'},
				{text:'Electronics', value: 'Electronics',type:'1'},
				{text:'Appliances', value: 'Appliances',type:'1'},
				{text:'Fire and Safety', value: 'Fire and Safety',type:'1'},
				{text:'Grocery', value: 'Grocery',type:'1'},
				{text:'Food', value: 'Food',type:'1'},
				{text:'IT Parts', value: 'IT Parts',type:'1'},
				{text:'Surgical Items', value: 'Surgical Items',type:'1'},
				{text:'Medical Etc', value: 'Medical Etc',type:'1'},
				{text:'Chemical', value: 'Chemical',type:'1'},
				{text:'Carpentary Items', value: 'Carpentary Items',type:'1'},
				{text:'Other Products', value: 'Other Products',type:'1'},
				//Service options
				{text:'Plumbing Service', value: 'Plumbing Service',type:'2'},
				{text:'Electrician Service', value: 'Electrician Service',type:'2'},
				{text:'AC Service', value: 'AC Service',type:'2'},
				{text:'Plumbing and Sanitary', value: 'Plumbing and Sanitary',type:'2'},
				{text:'Generator Service', value: 'Generator Service',type:'2'},
				{text:'Fire and Safety Services', value: 'Fire and Safety Services',type:'2'},
				{text:'Printing Service', value: 'Printing Service',type:'2'},
				{text:'IT Services', value: 'IT Services',type:'2'},
				{text:'Carpentary Services', value: 'Carpentary Services',type:'2'},
				{text:'Other Services', value: 'Other Services',type:'2'},

			],
            data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'name',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
                // ledger: [],
                // type:[],
                // category:[]
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
        }
    }
    componentWillMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
        }
        this.getDataRows(this.state)
    }
    componentDidMount(){
        
        // this.prepareGraphDataSingleFactor(this.state.graph_factor)
        var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
				options_to_ledger: options_ledger
			},()=>{
				// if (this.props.ledger_voucher) {
				// 	this.setFilter({}, {name:'ledger',value:this.props.ledger_voucher})
				// }
			})
		})
    }
    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value})
    }
    changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
    }
    getDataRows(state){
        this.setState({loading_inventory_report:true})
        Client.getDashboardInventoryReport('dashboard-inventory',state.data_rows_offset,state.data_rows_limit,null,null,'accounting_invoice_product.'+state.data_rows_search_field,state.data_rows_search_type,state.data_rows_search,state.data_rows_filter,{},null).then(res=>{
            console.log(res);
            this.setState({
                data_rows:res.inventoryReport,
                data_rows_size:res.inventoryReport.length,
                loading_inventory_report:false
            },()=>{
                this.sortReport()
            })
            
        })
    }
    setFilter(e, data){
		
		var filter = {...this.state.data_rows_filter}
		filter[data.name] = data.value || e.target.value
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    sortReport(){

        let sort_key = this.state.sort_on
        let data_rows = [...this.state.data_rows]
        console.log("sorting by :: ",sort_key);
        
        data_rows.sort((a,b)=>{
            return parseInt(b[sort_key]) - parseInt(a[sort_key])
        })
        this.setState({data_rows:data_rows})
    }
    
    render(){
        return(
            <Card>
                <Card.Header>
                    Inventory
                    <span className='header-toggle'>
                        <Checkbox 
                            onClick={()=>{
                                this.setState({
                                    sort_on:this.state.sort_on=="sale"?"QSR":"sale"
                                },()=>{
                                    this.sortReport()
                                })
                            }}
                            toggle label={this.state.sort_on=="sale"?"Best Performing":"Revisable"}/>

                    </span>
                </Card.Header>
                <Card.Content>
                    
                                <div style={{display:'flex',flexWrap:'wrap'}}>
                                    {/* <Checkbox className="small-label" toggle label="Product/Category"/> */}
                                    <Button size={'mini'} style={{padding:0}}>
                                        <Dropdown selection
                                            options={this.state.tableFields}
                                            name="data_rows_search_field"
                                            value={this.state.data_rows_search_field}
                                            placeholder="Search Field"
                                            onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
                                    </Button>
                                    {
                                        this.state.data_rows_search_type == 'field'
                                        ?
                                        <Input type="text"
                                            name="data_rows_search"
                                            size={'mini'}
                                            value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
                                        :
                                        <Button size={this.state.defaultSize} style={{padding:0}}>
                                            <Dropdown selection
                                                search name="data_rows_search"
                                                size={'mini'}
                                                options={this.state['options_' + this.state.data_rows_search_field]}
                                                value={this.state.data_rows_search}
                                                placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
                                        </Button>
                                    }
                                    
                                        {/* <Button
                                            size="mini"
                                            color='teal'
                                            icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
                                            onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
                                        <Dropdown
                                            name='data_rows_limit'
                                            inline
                                            options={this.state.options_data_rows_limit}
                                            value={this.state.data_rows_limit}
                                            onChange={(e, data) => this.changeStateVariable(e, data)}
                                        />
                                        {
                                            '' + this.state.data_rows_offset + ' to ' +
                                            (
                                                (this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
                                                (this.state.data_rows_offset + this.state.data_rows_limit) :
                                                this.state.data_rows_size
                                            ) +
                                            ' of ' + this.state.data_rows_size + ' '
                                        }
                                        <Button
                                            size="mini"
                                            color='teal'
                                            
                                            icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
                                            onClick={(e, data) => this.changeStateVariable(e, data)}></Button> */}
                                        <Form.Select clearable placeholder="Type" selection search options={this.state.options_type} type="text" fluid name="type" value={this.state.data_rows_filter.type || ''} onChange={(e, data) => this.setFilter(e, data)}/>
                                        {
                                            this.state.data_rows_filter.type
                                            ?
                                            <Form.Select selection search 
                                                clearable
                                                placeholder="Category"
                                                options={this.state.options_category.filter(cat=>{
                                                    return cat.type == this.state.data_rows_filter.type
                                                })} 
                                                type="text" fluid name="category" value={this.state.data_rows_filter.category || ''} onChange={(e, data) => this.setFilter(e, data)}/>
                                            :
                                            null
                                        }
                                </div>
                              {
                                  this.state.loading_inventory_report
                                  ?
                                    <Segment style={{width:'100%'}}>
                                        <Dimmer active>
                                        <Loader size="medium">Loading</Loader>
                                        </Dimmer>

                                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                    </Segment>
                                    :
                                     <Table color="blue" key="blue" celled compact unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            {
                                                this.state.tableFields.map((field) => (
                                                    <Table.HeaderCell 
                                                    // onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} 
                                                    // style={{cursor: 'pointer'}}
                                                    >
                                                        {field.text}
                                                        {
                                                            this.state.data_rows_sort_order == "asc" ?
                                                                <Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                            :
                                                                <Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                        }
                                                    </Table.HeaderCell>
                                                ))
                                            }
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                        {
                                            this.state.data_rows.map((data_row, index) => (
                                                <Table.Row className="data-row-table-row" 
                                                // onClick={() => this.showDataRowDetails(index)}
                                                >
                                                {
                                                    this.state.tableFields.map((field) => (
                                                        <Table.Cell style={{backgroundColor: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'red' : 'white' : 'white'),
                                                            color: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'white' : 'black' : 'black')}}>
                                                            {field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}
                                                        </Table.Cell>
                                                    ))
                                                }
                                                </Table.Row>
                                            ))
                                        }
                                        </Table.Body>
                                    </Table>
                            }
               </Card.Content>
            </Card>
        )
    }
}
export default DashboardInventory