import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Modal} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingInvoice extends Component{

	constructor(){
		super();
		var monthSelection = [{text: "All", value: 0}];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}

		this.state = {
			sales: [],
			paymentReceipt: {paymentInvoices:[{}]},
			currentInvoiceSale: {},
			monthSelection: monthSelection,
			customerSelection: [],
			filter: {customer: 0, date: 0},
			totalSale: 0,
			totalTax: 0,
		}
	}

	componentDidMount() {
		this.loadSales();
		this.loadCustomers();
		this.loadInvoiceIds();
		this.showSaleDetails = this.showSaleDetails.bind(this);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	handleInputChangeSale(e, data){
		var sale = this.state.sale;
		sale[data.name] = e.target.value
		this.setState({
			sale: sale
		})
	}

	handleInputChangePayment(e, data){
		var paymentReceipt = {...this.state.paymentReceipt};
		paymentReceipt[data.name] = e.target.value
		this.setState({
			paymentReceipt: paymentReceipt
		})
	}

	loadSales(e, data){
		var filter = {...this.state.filter}
		if(data != null)
			filter[data.name] = data.value
		Client.getAccountingInvoiceSales(filter.customer, filter.date, filter.invoiceId).then(res=>{
			var totalSale = 0;
			var totalTax = 0;
			res.sales.forEach(function(sale){
				totalSale = totalSale + parseFloat(sale.total);
				totalTax = totalTax + parseFloat(sale.total - sale.subTotal);
			})
            this.setState({
                sales: res.sales,
				filter: filter,
				totalSale: totalSale.toFixed(2),
				totalTax: totalTax.toFixed(2)
            })
        })
	}

	loadCustomers(){
		Client.getCustomers().then(res=>{
			var customerSelection = [{text: "All", value: 0}];
			res.data_rows.forEach(function(customer){
				console.log(customer);
				customerSelection.push({
					text: customer.ledger_name,
					value: customer.id
				})
			})
            this.setState({
                customerSelection: customerSelection
            })
        })
	}

	loadInvoiceIds(){
		Client.getAccountingInvoiceIds().then(res=>{
			var invoiceIds = [{
				text: "All Series",
				value: 0
			}];
			res.invoiceSeries.forEach(function(invoiceId){
				invoiceIds.push({
					text: invoiceId,
					value: invoiceId
				})
			})
			this.setState({
                invoiceIds: invoiceIds,
            })
        })
	}

	markInvoiceSent(inv){
		if (window.confirm('SURE? Mark Invoice Sent?')) {
			Client.markAccountingInvoiceSent(inv).then(res=>{
				this.loadSales();
			})
		}
	}

    calculateTax(sale){
        var sale = sale;
        var taxDetails = [];
        var subTotal = 0;
        var total = 0;
		var taxStateType = sale.customer.pos == "AS" ? 0 : 1;
        sale.products.forEach(function(element) {
            var taxDetail = {};
            var found = 0
            if(element.tax != 'N/A'){
                taxDetails.forEach(function(elem) {
                    if(elem.taxPc == element.tax){
                        found = 1;
						elem.taxStateType = taxStateType;
                        elem.taxableAmount = Number(elem.taxableAmount) + (element.qty * element.rate)
                        elem.taxAmount = elem.taxableAmount *  elem.taxPc / 100;
                        elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
                        elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
                    }
                })
                if(found == 0){
					taxDetail['taxStateType'] = taxStateType;
                    taxDetail['taxPc'] = element.tax;
                    taxDetail['taxableAmount'] = element.qty * element.rate;
                    taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
                    taxDetail['taxAmount'] = (element.qty * element.rate) * element.tax / 100;
                    taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
                    taxDetails.push(taxDetail)
                }
            }
            subTotal = subTotal + (element.qty * element.rate);
        });

        taxDetails.forEach(function(elem) {
            total = total + Number(elem.taxAmount);
        });

        // sale.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
        // sale.total = Number(total) + Number(subTotal);
		// sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
        // sale.roundOff = Math.round(sale.total) - Number(sale.total);
        // sale.roundOff = parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2);
        // sale.payableAmount = Math.round(sale.total);
		// if(sale.adjustmentName != undefined && sale.adjustmentName != '')
		// 	sale.payableAmount = Number(sale.payableAmount) + Number(sale.adjustmentAmount || 0);
        // sale.payableAmount = parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2);

        return taxDetails
    }

	calculateTaxByHSN(sale){
        var sale = sale;
        var taxDetails = [];
        var subTotal = 0;
        var total = 0;
		var taxStateType = sale.customer.pos == "AS" ? 0 : 1;
        sale.products.forEach(function(element) {
            var taxDetail = {};
            var found = 0
            if(element.tax != 'N/A'){
                taxDetails.forEach(function(elem) {
                    if(elem.HSN == element.HSN){
                        found = 1;
						elem.taxStateType = taxStateType;
                        elem.taxableAmount = Number(elem.taxableAmount) + (element.qty * element.rate)
                        elem.taxAmount = elem.taxableAmount * elem.taxPc / 100;
                        elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
                        elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
                    }
                })
                if(found == 0){
					taxDetail['taxPc'] = element.tax;
					taxDetail['HSN'] = element.HSN;
					taxDetail['taxStateType'] = taxStateType;
                    taxDetail['taxableAmount'] = element.qty * element.rate;
                    taxDetail['taxAmount'] = (element.qty * element.rate) * element.tax / 100;
					taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
                    taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
                    taxDetails.push(taxDetail)
                }
            }
            subTotal = subTotal + (element.qty * element.rate);
        });

        taxDetails.forEach(function(elem) {
            total = total + Number(elem.taxAmount);
        });

        // sale.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
        // sale.total = Number(total) + Number(subTotal);
		// sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
        // sale.roundOff = Math.round(sale.total) - Number(sale.total);
        // sale.roundOff = parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2);
        // sale.payableAmount = Math.round(sale.total);
		// if(sale.adjustmentName != undefined && sale.adjustmentName != '')
		// 	sale.payableAmount = Number(sale.payableAmount) + Number(sale.adjustmentAmount || 0);
        // sale.payableAmount = parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2);

        return taxDetails
    }

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

    showSaleDetails(print, index){
        if(index != undefined){
            var sale = {...this.state.sales[index]};
            this.setState({
                currentInvoiceIndex: index,
                currentInvoiceSale: sale
            })
        }
        else{
            index = this.state.currentInvoiceIndex
        }

        var sale = {...this.state.sales[index]};
        var taxDetails = this.calculateTax(sale)

        var taxDetailsView = '';
        taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount/2) + '</td></tr>' +
	                                            '<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.taxAmount) + '</td></tr>'
			}
        }.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
                        '<th style="position:relative">'+
                        '<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
                        '<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
                        '<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
        	'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
                        '<th>Amount</th></tr>'
		}

        sale.products.forEach(function(product, index) {
            if(taxDetails.length > 0){
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.desc != null ? product.desc : '') + '</td><td style="text-align:left">' + product.HSN +
	                            '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
	            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.desc != null ? product.desc : '') + '</td><td style="text-align:right">' + product.qty +
	                            '</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td><td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
                            '</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
                            '<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
                            '<table class="productsTableInner">' +
                            '<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
                            '<td style="min-width:50px; max-width:100px; text-align:right">' +
                            this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
                            '</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
        }.bind(this));

        var invoiceHtml = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
            <table>
                <tr>
                    <td style="width:10%">
                        <img style="width:100%" src=${logo}><img/>
                    </td>
                    <td>
                        <p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
                        <p style="padding:0; margin:0 0 0 5px">
                        R G B Road, Ganeshguri<br/>
                        Guwahati, Assam - 781006<br/>
                        GSTIN: 18AAXCS9739A1ZO<br/>
                        PAN: AAXCS9739A<br/>
                        MSME UAM No.: AS03D0001520<br/>
                        Contact: +91-9954683549<br/>
                        E-mail: contact@deskneed.com
                        </p>
                    </td>
                    <td style="font-size:300%; text-align:right; padding-right:40px">
                        TAX INVOICE
                    </td>
                </tr>
            </table>

            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
                <table style="margin:0 0 0 5px">
                    <tr>
                        <td>Invoice Number</td>
                        <td><b>: ${sale.invoiceId}</b></td>
                    <tr>
                    <tr>
                        <td>Invoice Date</td>
                        <td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
                    <tr>
                </table>
            </div>

            <div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
                Bill To
            </div>

            <div style="padding:5px 5px 5px 5px;">
                <p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer.name}</p>
                <p style="padding:0; margin:0">
					${(sale.customer.address && sale.customer.address.trim() != "") ? sale.customer.address + '<br/>' : ''}
                    ${(sale.customer.GSTIN && sale.customer.GSTIN.trim() != "") ? "GSTIN: " + sale.customer.GSTIN : ''}
                </p>
            </div>

            <table class="productsTable">
                ${productsTable}
            </table>

            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
                <tr>
                    <td style="vertical-align:top; padding:5px 0 0 5px; white-space:pre-line">
						<div style="float:right; padding:2px 5px 0 0">E. & O.E.</div>
                        Total in Words:<br/>
                        <b>Rupees ${converter.toWords(sale.payableAmount)}</b>
                        <br/>
                        ${sale.remarks || 'NA'}
                        <br/>
						<b>CHEQUE to be made in favour of SCIENCE2HOME PVT. LTD.</b><br/>
						Company's Bank Details:<br/>
						<b>Acc No: 39010200000238</b><br/>
						<b>IFSC Code: BARB0AMBARI‎</b><br/>
						<b>Bank Of Baroda</b><br/>
						Ambari, Guwahati

						<div style="text-align:center; padding:2px 0px 5px 0">SUBJECT TO GUWAHATI JURISDICTION</div>
                    </td>
                    <td style="border-left:solid 1px #999; padding:0">
                        <div style="width:100%; margin:0; padding:5px 0 5px 0px">
                            <table style="width:100%">
                                <tr><td>Sub Total</td><td style="text-align:right">${parseFloat(Math.round(sale.subTotal * 100) / 100).toFixed(2)}</td></tr>
                                ${taxDetailsView}`
								if(sale.adjustmentName != null && sale.adjustmentName != '')
                                	invoiceHtml = invoiceHtml +
									`<tr><td>${sale.adjustmentName}</td><td style="text-align:right">${sale.adjustmentAmount}</td></tr>`
                                invoiceHtml = invoiceHtml +
								`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2)}</td></tr>
                                <tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2)}</td></tr>
                            </table>
                        </div>
                        <div style="text-align:center; border-top:solid 1px #999; width:100%">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        Authorised Signatory
                        </div>
                    </td>
                </tr>
            </table>

        </body>
        <style>
            body{
                margin: 0em;
            }
            *{
                font-family: sans-serif
            }
            table{
                border-collapse: collapse;
            }
            .productsTable, .productsTableInner{
                width:100%;
            }
            .productsTable td{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTable th{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTableInner td{
                border:none;
            }
            .productsTableInner th{
                border:none;
            }
        </style>
        </html>
        `

        document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
        document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

        if(print){
            var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
    		printwindow.document.write(
                invoiceHtml
    		);
            printwindow.focus();
            setTimeout(function() {
                printwindow.document.title = sale.invoiceId;
                printwindow.print();
                printwindow.close();
                printwindow.document.close();
            }, 500);
        }
    }

	printDeliveryNote(print, index){
        if(index != undefined){
            var sale = {...this.state.sales[index]};
            this.setState({
                currentInvoiceIndex: index,
                currentInvoiceSale: sale
            })
        }
        else{
            index = this.state.currentInvoiceIndex
        }

        var sale = {...this.state.sales[index]};
        var taxDetails = this.calculateTax(sale)

        var taxDetailsView = '';

        var productsTable =
        '<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th></tr>'
        sale.products.forEach(function(product, index) {
            productsTable = productsTable +
            '<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
							(product.desc != null ? product.desc : '') + '</td><td style="text-align:center">' + product.qty +
                            '</td></tr>'
        }.bind(this));

        var invoiceHtml = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
            <table>
                <tr>
                    <td style="width:10%">
                        <img style="width:100%" src=${logo}><img/>
                    </td>
                    <td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
                        <p style="padding:0; margin:0 0 0 5px">
                        R G B Road, Ganeshguri<br/>
                        Guwahati, Assam - 781006<br/>
                        GSTIN: 18AAXCS9739A1ZO<br/>
                        PAN: AAXCS9739A<br/>
                        MSME UAM No.: AS03D0001520<br/>
                        Contact: +91-9954683549<br/>
                        E-mail: contact@deskneed.com
                        </p>
                    </td>
                    <td style="font-size:300%; text-align:right; padding-right:40px">
                        DELIVERY NOTE
                    </td>
                </tr>
            </table>

            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
                <table style="margin:0 0 0 5px">
                    <tr>
                        <td>Reference Number</td>
                        <td><b>: ${sale.invoiceId}</b></td>
                    <tr>
                    <tr>
                        <td>Date</td>
                        <td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
                    <tr>
                </table>
            </div>

            <div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
                Delivery To
            </div>

            <div style="padding:5px 5px 5px 5px;">
                <p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer.name}</p>
                <p style="padding:0; margin:0">
					${(sale.customer.address && sale.customer.address.trim() != "") ? sale.customer.address + '<br/>' : ''}
                    ${(sale.customer.GSTIN && sale.customer.GSTIN.trim() != "") ? "GSTIN: " + sale.customer.GSTIN : ''}
                </p>
            </div>

            <table class="productsTable">
                ${productsTable}
            </table>

            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
                <tr>
                    <td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
                        <br/>
                        ${sale.remarks || 'NA'}
                        <br/>
                    </td>
                    <td style="border-left:solid 1px #999; padding:0; text-align:center">
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        Authorised Signatory
                        </div>
                    </td>
                </tr>
            </table>

        </body>
        <style>
            body{
                margin: 0em;
            }
            *{
                font-family: sans-serif
            }
            table{
                border-collapse: collapse;
            }
            .productsTable, .productsTableInner{
                width:100%;
            }
            .productsTable td{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTable th{
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            .productsTableInner td{
                border:none;
            }
            .productsTableInner th{
                border:none;
            }
        </style>
        </html>
        `

        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

        // if(print){
            var printwindow = window.open('','dhp_inv_delivery_note', 'height=1000, width=1000');
    		printwindow.document.write(
                invoiceHtml
    		);
            printwindow.focus();
            setTimeout(function() {
                printwindow.document.title = sale.invoiceId + "_DELIVERY_NOTE";
                printwindow.print();
                printwindow.close();
                printwindow.document.close();
            }, 500);
        // }
    }

	printPaymentReceipt(print, index){
		if(!print){
			this.setState({
				showPaymentReceiptModal: true
			})
			this.getPaymentDetails();
		}
		else if(print == 2 || this.state.paymentReceipt.paymentStatus == "DUE"){
			Client.addAccountingPaymentDetails(this.state.currentInvoiceSale.invoiceId, this.state.paymentReceipt).then(res=>{
				this.getPaymentDetails();
			})
		}

		if(print == 1){
	        if(index != undefined){
	            var sale = {...this.state.sales[index]};
	            this.setState({
	                currentInvoiceIndex: index,
	                currentInvoiceSale: sale
	            })
	        }
	        else{
	            index = this.state.currentInvoiceIndex
	        }

	        var sale = {...this.state.sales[index]};
	        var taxDetails = this.calculateTax(sale)

	        var taxDetailsView = '';

	        var productsTable =
	        '<tr style="background:#CCC"><th style="min-width:10px">Invoice No.</th><th>Invoice Date</th><th>Invoice Amount</th><th>Payment Amount</th></tr>'
	        productsTable = productsTable +
	        '<tr><td style="text-align:center">' + sale.invoiceId + '</td><td style="text-align:center">' + sale.date + '</td>' +
							'<td style="text-align:center"> Rs.' + sale.payableAmount +
	                        '</td><td style="text-align:center"> Rs.' + this.state.paymentReceipt.amount + '</td></tr>'

	        var invoiceHtml = `
	        <!doctype html>
	        <html>
	        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
	            <table>
	                <tr>
	                    <td style="width:10%">
	                        <img style="width:100%" src=${logo}><img/>
	                    </td>
	                    <td>
							<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
	                        <p style="padding:0; margin:0 0 0 5px">
	                        R G B Road, Ganeshguri<br/>
	                        Guwahati, Assam - 781006<br/>
	                        GSTIN: 18AAXCS9739A1ZO<br/>
	                        PAN: AAXCS9739A<br/>
	                        MSME UAM No.: AS03D0001520<br/>
	                        Contact: +91-9954683549<br/>
	                        E-mail: contact@deskneed.com
	                        </p>
	                    </td>
	                    <td style="font-size:300%; text-align:right; padding-right:40px">
	                        PAYMENT RECEIPT
	                    </td>
	                </tr>
	            </table>

	            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
	                <table style="margin:0 0 0 5px">
	                    <tr>
	                        <td><b>Payment Date</b></td>
	                        <td>: ${this.state.paymentReceipt.date}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Reference Number</b></td>
	                        <td>: ${this.state.paymentReceipt.referenceNumber}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Payment Mode</b></td>
	                        <td>: ${this.state.paymentReceipt.paymentMode}</td>
	                    <tr>
						<tr>
	                        <td><b>Amount Received</b></td>
	                        <td>: Rs.${sale.payableAmount}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Amount Received In Words</b></td>
	                        <td>: Rupees ${converter.toWords(sale.payableAmount)}</td>
	                    <tr>
	                </table>
	            </div>

	            <table class="productsTable">
	                ${productsTable}
	            </table>

	            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
	                <tr>
	                    <td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
	                        <br/>
	                        ${sale.remarks || 'NA'}
	                        <br/>
	                    </td>
	                    <td style="border-left:solid 1px #999; padding:0; text-align:center">
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        Authorised Signatory
	                        </div>
	                    </td>
	                </tr>
	            </table>

	        </body>
	        <style>
	            body{
	                margin: 0em;
	            }
	            *{
	                font-family: sans-serif
	            }
	            table{
	                border-collapse: collapse;
	            }
	            .productsTable, .productsTableInner{
	                width:100%;
	            }
	            .productsTable td{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTable th{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTableInner td{
	                border:none;
	            }
	            .productsTableInner th{
	                border:none;
	            }
	        </style>
	        </html>
	        `

	        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
	        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

	        // if(print){
	            var printwindow = window.open('','dhp_inv_payment_receipt', 'height=1000, width=1000');
	    		printwindow.document.write(
	                invoiceHtml
	    		);
	            printwindow.focus();
	            setTimeout(function() {
	                printwindow.document.title = sale.invoiceId + "_PAYMENT_RECEIPT";
	                printwindow.print();
	                printwindow.close();
	                printwindow.document.close();
	            }, 500);
	        // }
		}
    }

	getPaymentDetails(){
		Client.getAccountingPaymentDetails(this.state.currentInvoiceSale.invoiceId).then(res=>{
			var paymentReceiptDetails = res.paymentDetails;
			res.paymentDetails.paymentInvoices.forEach(function(paymentInvoice){
				if(paymentInvoice.invoiceId == this.state.currentInvoiceSale.invoiceId){
					paymentReceiptDetails.paymentAmount = paymentInvoice.paymentAmount
				}
			}.bind(this))
			this.setState({
				paymentReceipt: res.paymentDetails,
			})
		})
	}

    editSale(){
        localStorage.setItem('currentInvoiceSale', JSON.stringify(this.state.currentInvoiceSale));
        localStorage.setItem('currentInvoiceSaleEdit', 1);
        this.props.history.push('/accounting/vouchers/invoice/create')
    }

	render(){
		return(
		    <div>
				<div style={{width:'100%'}}>
					<Button primary onClick={() => this.props.history.push('/accounting/vouchers/invoice/create')}>CREATE INVOICE</Button>
					<Dropdown search selection name="customer" options={this.state.customerSelection} placeholder="Select Customer" onChange={(e, data) => this.loadSales(e, data)}/>
					<Dropdown search selection name="date" options={this.state.monthSelection} placeholder="Select Month" onChange={(e, data) => this.loadSales(e, data)}/>
					<Dropdown search selection name="invoiceId" options={this.state.invoiceIds} placeholder="Select Invoice Series" onChange={(e, data) => this.loadSales(e, data)}/>
				</div>
				<div style={{width:'100%', padding:'10px'}}>
					<b>Total Sale: Rs.</b>{this.state.totalSale} <b>Total Tax: Rs.</b>{this.state.totalTax}
				</div>
                <div style={{float:'left', width:'30%', overflowY:'scroll', height:'calc(87vh - 50px)'}}>
                {
					this.state.sales.map((sale, index) => (
						<div style={{padding:'5px', margin:'2px', border:'solid #CCC 1px', background: this.state.currentInvoiceIndex == index ? '#777' : '#FFF',
							color: this.state.currentInvoiceIndex == index ? '#FFF' : '#000'}}
							onClick={() => this.showSaleDetails(0, index)}>
							<div style={{color:'#ffffff', fontWeight:'bold',
								background: sale.paymentStatus == "PAID" ? '#5acaea' : '#ff0000', textAlign:'center'}}>
								{sale.paymentStatus}
							</div>
							<table style={{width:'100%'}}>
								<tr style={{fontWeight:'bold'}}>
									<td>{sale.customer.name}</td>
									<td style={{textAlign:'right'}}>Rs.{sale.payableAmount}</td>
								</tr>
								<tr>
									<td>{sale.invoiceId}</td>
									<td style={{textAlign:'right'}}>{sale.date}</td>
								</tr>
							</table>
							<div style={{color:'#ffffff', fontWeight:'bold',
								background: '#5acaea', textAlign:'center'}}>
								{sale.status}
							</div>
							{
								sale.status != "BILL RAISED"
								?
								<Button primary onClick={() => this.markInvoiceSent(sale.invoiceId)}>Mark Sent</Button>
								:
								null
							}
						</div>
					))
                }
                </div>
                <div style={{float:'left', width:'70%', height:'calc(87vh - 50px)', visibility:'hidden'}} id="showInvoiceContainerMain">
                    <div>
                        <button onClick={() => this.editSale()}>EDIT</button>
                        <button onClick={() => this.showSaleDetails(1)}>PRINT</button>
                        <button onClick={() => this.printDeliveryNote(1)}>DELIVERY NOTE</button>
                        <button onClick={() => this.printPaymentReceipt(0)}>PAYMENT RECEIPT</button>
                    </div>
                    <div id="showInvoiceContainer" style={{overflowY:'scroll', height:'calc(82vh - 50px)'}}>
                    </div>
                </div>
				<Modal size="small" open={this.state.showPaymentReceiptModal} onClose={() => this.setState({showPaymentReceiptModal:false})}>
					{
						this.state.paymentReceipt.paymentInvoices.map((receiptInvoice) => (
							<div style={{textAlign:"center"}}>
								Payment ID: {receiptInvoice.paymentId} - Payment Status: {this.state.paymentReceipt.paymentStatus}<br/>
								Payment Date: {receiptInvoice.paymentDate} - Payment Mode: {receiptInvoice.paymentMode}<br/>
								Payment Amount: {receiptInvoice.paymentAmount}<br/>
								TDS Amount: {receiptInvoice.tdsAmount}<br/>
								GST TDS: {receiptInvoice.gstTdsAmount}
							</div>
						))
						// this.state.paymentReceipt.paymentInvoices.length > 0
						// ?
						// LAST PAYMENT DETAILS
						// <div>{this.state.paymentReceipt.paymentStatus}  {this.state.paymentReceipt.paymentInvoices[0].paymentAmount}</div>
						// :
						// null
					}
					<Modal.Content>
						<Input name="date" fluid type="date" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment Date" defaultValue={this.state.paymentReceipt.paymentDate || this.state.currentInvoiceSale.date}/>
						<Input name="amount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Amount" defaultValue={this.state.paymentReceipt.paymentAmount || this.state.currentInvoiceSale.payableAmount}/>
						<Input name="tdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="IT TDS Amount" defaultValue={this.state.paymentReceipt.tdsAmount}/>
						<Input name="gstTdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="GST TDS Amount" defaultValue={this.state.paymentReceipt.gstTdsAmount}/>
						<Input name="paymentMode" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Cash/Credit/Cheque/NEFT" defaultValue={this.state.paymentReceipt.paymentMode}/>
						<Input name="referenceNumber" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Reference Number (If Any)" defaultValue={this.state.paymentReceipt.referenceNumber}/>
						<Input name="paymentId" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment ID (If Any)" defaultValue={this.state.paymentReceipt.paymentId}/>
						<Button fluid onClick={() => this.printPaymentReceipt(1)} primary>PRINT</Button>
						{
							this.state.paymentReceipt.paymentStatus == "PAID"
							?
							<Button fluid onClick={() => this.printPaymentReceipt(2)} primary>ADD TO PAYMENTS</Button>
							:
							null
						}
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default AccountingInvoice;
