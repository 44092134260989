import React, {Component} from 'react';
import '../style/page-center.css'
// import Mailto from 'reactv16-mailto';

class Requests extends Component{

	render(){
		return(
			<div className="page-center">
				<div className="page-center-content" style={{textAlign: "center"}}>
					<strong>Phone:</strong>&nbsp; 9954683549<br/>
					<strong>Email:</strong>&nbsp; contact@doorhopper.in
				</div>
			</div>
		)
	}
}

export default Requests;
