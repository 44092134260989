import React,{Component} from 'react';
import './../style/suggestion.css';

class ProductSuggestion extends Component{
    constructor(props){
        super(props);
        this.handleClick=this.handleClick.bind(this);
    }
    handleClick(){
        this.props.onClick(this.props.product);
    }
    render(){
        return(
            <a onClick={this.handleClick.bind(this)} className="Item" style={{cursor:"pointer"}}>
                {
                    this.props.product.name
                }
            </a>
        )
    }
}
export default ProductSuggestion;