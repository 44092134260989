import React, {Component} from  'react';

import { Modal, Form, Button } from 'semantic-ui-react';

import OrderProductsList from './order-products-list';
import Client from '../services/Client';
import {Link} from 'react-router-dom';
import BookingDetails from './booking-details.js';

var intToMonth = ["Of Month", "Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
var dateSuffix = ["th", "st", "nd", "rd", "th"];

class Booking extends Component{

    constructor(props){

        super(props);

        this.state = {
            booking: this.props.booking,
            bookingDetails: {reason: '',
                subsBookings:[],
                products:[]
            },
            statuses: [["Placed"], ["Price", "Cancelled", "Accepted", "Rejected"], ["Price", "Cancelled", "Service Processing"], ["Price", "Completed"]],
            updateStatus: "",
            availableStatuses: [],
            currentStatus: "",
            products: [],
            notify: 0,
            displayCompletionModal: false,
            displayCancelModal: false,
            displayPriceModal: false,
            finalDetails: {
                method: null,
                amount: null
            },
            selectedSubsBooking: 0,
            selectedSubsBookingIndex: -1,
            loadingComplete: false,
            loadingCancel: false,
            loadingPriceUpdate: false,
            cancelReason:'',
            updatePaymentMode: {},
            updateDiscountAmount: '0',
            updateAdditionalCharges: '0',
            updateVariableAmount: '0',
            updateRoundedAmount: '0'
        }

        this.completePayment = this.completePayment.bind(this);
        this.requestPayment = this.requestPayment.bind(this);
        this.handleFinalDetails = this.handleFinalDetails.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.processBooking = this.processBooking.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
    }

    componentDidMount(){
        this.setState({
            currentStatus: this.props.booking.status,
            booking: this.props.booking
        })
        Client.getBookingDetails(this.props.booking.bookingId).then((res)=>{
            console.log("loaded" + this.props.booking.bookingId)
            console.log(res)
            this.setState({
                bookingDetails: {...this.state.bookingDetails, ...res},
                selectedSubsBooking: res.subsBookings.length > 0 ? res.subsBookings[0].subsBookingId : 0,
                selectedSubsBookingIndex: res.subsBookings.length > 0 ? 0 : -1,
                currentStatus: res.subsBookings.length > 0 ? res.subsBookings[0].bookingStatus : res.orderStatus,
                updateDiscountAmount: res.discount || '0',
                updateAdditionalCharges: res.additionalCharges || '0',
                updateVariableAmount: res.variableAmount || '0',
                updateRoundedAmount: res.roundedAmount || '0',
            })
        })
    }

    processBooking(status, notify, paymentStatus){
        alert(notify)
        return Client.processBooking(this.state.bookingDetails, status, notify, paymentStatus, this.state.selectedSubsBooking).then((res)=>{
            this.setState({
                currentStatus: res.updatedStatus
            })
            return res;
        });
    }

    onStatusChange(event){
        const val = event.target.value;

        if(val == "Completed"){
            this.setState({
                displayCompletionModal: true
            })
        }
        else if(val == "Cancelled"){
            this.setState({
                displayCancelModal: true
            })
        }
        else if(val == "Price"){
            this.setState({
                displayPriceModal: true
            })
        }
        else{
            if(window.confirm("Are you sure you want to update status to " + val + "!")){
                if(window.confirm("DO YOU WANT TO NOTIFY CUSTOMER AND BUSINESS!")){
                    this.processBooking(val, 1);
                }else{
                    this.processBooking(val, 0)
                }
            }
        }
    }

    handleFinalDetails(e, data){

        if(data.name=='paymentMode'){
            this.setState({
                bookingDetails: {
                    ...this.state.bookingDetails,
                    paymentMode: data.value.method,
                    payModeId: data.value.id
                }
            })
        }else{
            this.setState({
                bookingDetails: {
                    ...this.state.bookingDetails,
                    [data.name]: data.value
                }
            })
        }
    }

    handleInputChange(e, data){
        let state = {...this.state};
        state[data.name] = data.value;
        this.setState({...state});
    }

    completePayment(){
        let ping = 2;
        if(window.confirm("Are you sure you want to update status to Completed!")){
            if(window.confirm("DO YOU WANT TO NOTIFY CUSTOMER AND BUSINESS!")){
                ping = 1;
            }else{
                ping = 0;
            }
        }
        if(ping !=2){
            this.setState({
                loadingComplete: true
            })
            this.processBooking('Completed', ping, 'Paid').then((res)=>{
                if(res.responseResult=='success'){
                    this.setState({
                        displayCompletionModal: false,
                        loadingComplete: false
                    })
                }
            });
        }
    }

    requestPayment(){
        let ping = 2;
        if(window.confirm("Are you sure you want to update status to Completed!")){
            if(window.confirm("DO YOU WANT TO NOTIFY CUSTOMER AND BUSINESS!")){
                ping = 1;
            }else{
                ping = 0;
            }
        }
        if(ping !=2){
            this.setState({
                loadingComplete: true
            })
            this.processBooking('Completed', ping, 'Unpaid').then((res)=>{
                if(res.responseResult=='success'){
                    this.setState({
                        displayCompletionModal: false,
                        loadingComplete: false
                    })
                }
            });
        }
    }

    cancelBooking(){
        let ping = 2;
        if(window.confirm("Are you sure you want to CANCEL BOOKING!")){
            if(window.confirm("DO YOU WANT TO NOTIFY CUSTOMER AND BUSINESS!")){
                ping = 1;
            }else{
                ping = 0;
            }
        }
        if(ping !=2){
            this.setState({
                loadingCancel: true
            })
            Client.cancelBooking(this.state.bookingDetails.bookingId, this.state.cancelReason, ping).then((res)=>{
                if(res.responseResult=='success'){
                    this.setState({
                        displayCancelModal: false,
                        loadingCancel: false
                    })
                }
            });
        }
    }

    updatePrice(){
        this.setState({
            loadingPriceUpdate: true
        })
        Client.updateBookingPrice(this.state.bookingDetails.bookingId, this.state.updatePaymentMode, this.state.updateDiscountAmount,
            this.state.updateAdditionalCharges, this.state.updateVariableAmount, this.state.updateRoundedAmount).then((res)=>{
            if(res.responseResult=='success'){
                this.setState({
                    displayPriceModal: false,
                    loadingPriceUpdate: false
                })
            }
        });
    }

    parseDateToText(date){
        var dateDetailed = date.split("/");
        var suffix = "th";
        var date = parseInt(dateDetailed[2]);
        if(date >= dateSuffix.length){
            suffix = "th";
        }
        else{
            suffix = dateSuffix[date];
        }
        var title = date + suffix + " " + intToMonth[parseInt(dateDetailed[1])];
        return title;
    }

    selectSubsBooking(subsBooking, index){
        this.setState({
            selectedSubsBooking: subsBooking.subsBookingId,
            selectedSubsBookingIndex: index,
            currentStatus: subsBooking.bookingStatus
        })
    }

    render(){
        var subsBookingDetails = this.state.bookingDetails.subsBookings.length > 0
            ?
                this.state.bookingDetails.subsBookings[this.state.selectedSubsBookingIndex] ?
                this.state.bookingDetails.subsBookings[this.state.selectedSubsBookingIndex] : this.state.bookingDetails
            :
            this.state.bookingDetails
        console.log(this.state.bookingDetails.bookingId)
        console.log(subsBookingDetails)

        var xyz;

        switch(this.props.booking.mode){
            case "ser":
                xyz =   <div className="booking-card-more">
                        {
                            // <i className="ui print icon" onClick={this.setInvoice} id="booking-print-button"/>
                        }
                            <BookingDetails booking={subsBookingDetails} headBooking={this.state.bookingDetails}/>
                        </div>
                break;
            default:
                xyz =<p>sdv</p>
                break;
        }

        var index, availableStatuses;

        for(var i=0; i<this.state.statuses.length; i++){
            for(var j=0; j<this.state.statuses[i].length; j++){
                if(this.state.statuses[i][j]==this.state.currentStatus){
                    index = i;
                    break;
                }
            }
        }

        if(index<this.state.statuses.length-1){
            availableStatuses = this.state.statuses[index+1];
        }else{
            availableStatuses = null
        }

        if(this.state.displayCompletionModal){
            return(
                <Modal open={true} size="tiny" style={{height: 'auto'}} onClose={function(){this.setState({displayCompletionModal: false})}.bind(this)}>
                    <Modal.Header>
                        Update Booking
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Select name="paymentMode" fluid label='Payment Method' onChange={this.handleFinalDetails} options={
                                JSON.parse(localStorage.getItem('paymentMethods')).filter((item)=>(item.for=='S'||item.for=='A')).map((item, i)=>({key: i, text: item.method, value: item}))
                            }/>
                            <Form.Input name="bookingAmount" fluid label='Amount' value={this.state.bookingDetails.bookingAmount} onChange={this.handleFinalDetails} type='number'/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='grey' content="Request Payment" onClick={this.requestPayment} loading={this.state.loadingComplete}/>
                        <Button color='green' content="Paid and Complete" onClick={this.completePayment} loading={this.state.loadingComplete}/>
                    </Modal.Actions>
                </Modal>
            )
        }

        if(this.state.displayCancelModal){
            return(
                <Modal open={true} size="tiny" style={{height: 'auto'}} onClose={function(){this.setState({displayCancelModal: false})}.bind(this)}>
                    <Modal.Header>
                        Cancel Booking
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Input name="cancelReason" fluid label='Reason For Cancellation' value={this.state.cancelReason} onChange={this.handleInputChange} type='text'/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' content="CANCEL BOOKING" onClick={this.cancelBooking} loading={this.state.loadingCancel}/>
                    </Modal.Actions>
                </Modal>
            )
        }

        if(this.state.displayPriceModal){
            return(
                <Modal open={true} size="tiny" style={{height: 'auto'}} onClose={function(){this.setState({displayPriceModal: false})}.bind(this)}>
                    <Modal.Header>
                        Update Pricing
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Select name="updatePaymentMode" fluid label='Payment Method' onChange={this.handleInputChange} options={
                                JSON.parse(localStorage.getItem('paymentMethods')).filter((item)=>(item.for=='S'||item.for=='A')).map((item, i)=>({key: i, text: item.method, value: item}))
                            }/>
                            <Form.Input name="updateDiscountAmount" fluid label='Discount (If Any)' value={this.state.updateDiscountAmount} onChange={this.handleInputChange} type='text'/>
                            <Form.Input name="updateAdditionalCharges" fluid label='Additional Charges (Any Additional Charges)' value={this.state.updateAdditionalCharges} onChange={this.handleInputChange} type='text'/>
                            <Form.Input name="updateVariableAmount" fluid label='Variable Amount (Only In Slab Rate Bookings)' value={this.state.updateVariableAmount} onChange={this.handleInputChange} type='text'/>
                            <Form.Input name="updateRoundedAmount" fluid label='Collected Amount' value={this.state.updateRoundedAmount} onChange={this.handleInputChange} type='text'/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red' content="UPDATE PRICING" onClick={this.updatePrice} loading={this.state.loadingPriceUpdate}/>
                    </Modal.Actions>
                </Modal>
            )
        }

        return(
            <div className="booking-card">
                <div className="booking-card-tabs" style={{display:'flex', flexDirection:'row', width:'76vw', overflow:'auto', marginBottom:'10px'}}>
                    {
                        this.state.bookingDetails.subsBookings
                        ?
                        this.state.bookingDetails.subsBookings.map((subsBooking, index) => (
                            <div className="expndinnerm2"
                                style={{background:
                                    this.state.selectedSubsBooking == subsBooking.subsBookingId ?
                                    "#5acaea" : "#ffffff"}}
                                onClick={() => this.selectSubsBooking(subsBooking, index)}>
                                    {this.parseDateToText(subsBooking.date)}
                            </div>
                        ))
                        :
                        null
                    }
                </div>
                <div className="booking-data">
                    <div className="booking-card-id">
                        <span className="column thin"><strong>Order Id:</strong></span>
                        <span className="column">{this.state.booking.bookingId}/{subsBookingDetails.subsBookingId}</span>
                    </div>
                    <div className="booking-card-amount">
                        <span className="column thin"><strong>Amount</strong></span>
                        <strong>Rs </strong>{this.state.booking.amount}
                    </div>
                    <div className="booking-card-item">
                        <span className="column thin"><strong>Item:</strong></span>
                        <span className="column">{this.state.booking.item}</span>
                    </div>
                    <div className="booking-card-status">
                        <span className="column thin"><strong>Order Status:</strong></span>
                        <span className="column">{this.state.currentStatus}</span>
                        {
                            (availableStatuses && this.state.currentStatus != "Cancelled" && this.state.currentStatus != "Completed" && this.state.currentStatus != "Rejected")
                            ?
                            <div>
                            {
                                availableStatuses.map((status)=>(
                                    <button className="booking-card-status-button" value={status} onClick={this.onStatusChange.bind(this)}>{status}</button>
                                ))
                            }
                            </div>
                            :
                            null
                        }
                    </div>
                    <div className="booking-card-date">
                        <span className="column">{this.props.booking.bookingDateAndTime}</span>
                    </div>
                    <div className="booking-card-name">
                        <span className="column thin"><strong>Customer Name:</strong></span>
                        <span className="column">{this.props.booking.customerName}</span>
                    </div>
                    {xyz}
                </div>
            </div>
        )
    };
}
export default Booking;
