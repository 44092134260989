import React,{Component} from 'react';
import VerticalMenu from '../components/vertical-menu';
import {Route,Switch,Redirect} from 'react-router-dom';
import AccountingInvoice from "./accounting-invoice";
import AccountingSale from "./accounting-sale";
import CreateAccountingInvoice from "./create-accounting-invoice";
import AccountingQuotation from "./accounting-quotation";
import CreateAccountingQuotation from "./create-accounting-quotation";
import AccountingPurchase from "./accounting-purchase";
import CreateAccountingPurchase from "./create-accounting-purchase";
import AccountingReceipt from "./accounting-receipt";
import AccountingPayment from "./accounting-payment";
import AccountingContra from "./accounting-contra";
import AccountingJournal from "./accounting-journal";
import DeleveryChallan from "./accounting-delivery-challan";
import '../style/print.css';
import '../style/page-container.css';
import Client from '../services/Client';
import {Grid, Menu, Button} from 'semantic-ui-react';
import CreateDeleveryChallan from './create-accounting-delivery-challan';
import AccountingContract from './contract';
import CreateAccountingContract from './create-accounting-contract';
import AccountingPurchaseOrder from './accounting-purchase-order';
import CreateAccountingPurchaseOrder from './create-accounting-purchase-order';
import AccountingDebitNote from './accounting-debit-note';
import CreateAccountingDebitNote from './create-accounting-debit-note';
import AccountingCreditNote from './accounting-credit-note';
import CreateAccountingCreditNote from './create-accounting-credit-note';

class AccountingVouchers extends Component{

    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }

    render(){
        return(
            <div>
                <Grid padded="false">
                    <Grid.Row style={{height: '38px', backgroundColor: '#444444', padding:0}} className="noprint">
                        <Button.Group style={{padding:0, margin:0}}>
                                <Button positive={window.location.pathname.includes("invoice")} onClick={() => this.props.history.push('/accounting/vouchers/invoice')}>Sale</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("quotation")} onClick={() => this.props.history.push('/accounting/vouchers/quotation')}>Quotation</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("challan")} onClick={() => this.props.history.push('/accounting/vouchers/challan')}>Delivery Note</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("contract")} onClick={() => this.props.history.push('/accounting/vouchers/contract')}>Contract</Button>
                                <Button.Or/>
                                <Button positive={window.location.pathname.split('/').includes("purchase")} onClick={() => this.props.history.push('/accounting/vouchers/purchase')}>Purchase</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("purchase-order")} onClick={() => this.props.history.push('/accounting/vouchers/purchase-order')}>Purchase Order</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("payment")} onClick={() => this.props.history.push('/accounting/vouchers/payment')}>Payment</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("receipt")} onClick={() => this.props.history.push('/accounting/vouchers/receipt')}>Receipt</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.split('/').includes("contra")} onClick={() => this.props.history.push('/accounting/vouchers/contra')}>Contra</Button>
                                <Button.Or />
                                <Button positive={window.location.pathname.includes("journal")} onClick={() => this.props.history.push('/accounting/vouchers/journal')}>Journal</Button>
                                <Button.Or/>
                                <Button positive={window.location.pathname.includes("credit-note")} onClick={() => this.props.history.push('/accounting/vouchers/credit-note')}>Credit Note</Button>
                                <Button.Or/>
                                <Button positive={window.location.pathname.includes("debit-note")} onClick={() => this.props.history.push('/accounting/vouchers/debit-note')}>Debit Note</Button>
                        </Button.Group>
                    </Grid.Row>
                    <Grid.Row style={{height: 'calc(100vh - 88px)', padding: '0'}}>
                        <Grid.Column className="noprint" width={this.state.innerWidth>720 ? 3 : 16} visible={this.state.visibleSidebar} as={Menu}
                            inverted style={{display: this.state.visibleSidebar?"inline-block":"none", borderRadius: "0", padding: 0, height:'100%'}}>
                            {//<VerticalMenu/>
                            }
                        </Grid.Column>

                        <Grid.Column width={(this.state.innerWidth>720) ? (this.state.visibleSidebar ? 13: 16) : 16} visible={(this.state.innerWidth>720) ? true : (!this.state.visibleSidebar)} style={{overflowY: "auto", padding: 0, height:'100%'}}>
                            <Switch style={{width: "100%"}}>
                                <Route
                                    path='/accounting/vouchers/invoice/create'
                                    render={(props)=>(<CreateAccountingInvoice toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/invoice'
                                    render={(props)=>(<AccountingSale toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/quotation/create'
                                    render={(props)=>(<CreateAccountingQuotation toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/challan/create'
                                    render={(props)=>(<CreateDeleveryChallan toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/challan'
                                    render={(props)=>(<DeleveryChallan toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                               <Route
                                    path='/accounting/vouchers/quotation'
                                    render={(props)=>(<AccountingQuotation toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/purchase/create'
                                    render={(props)=>(<CreateAccountingPurchase toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/purchase'
                                    render={(props)=>(<AccountingPurchase toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/receipt/create'
                                    render={(props)=>(<AccountingReceipt toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/receipt'
                                    render={(props)=>(<AccountingReceipt toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/payment/create'
                                    render={(props)=>(<AccountingPayment toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/payment'
                                    render={(props)=>(<AccountingPayment toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/contra/create'
                                    render={(props)=>(<AccountingContra toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/contract/create'
                                    render={(props)=>(<CreateAccountingContract toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/contra'
                                    render={(props)=>(<AccountingContra toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/journal/create'
                                    render={(props)=>(<AccountingJournal toggle={this.toggleSidebar}  history={props.history}
                                          location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/journal'
                                    render={(props)=>(<AccountingJournal toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/contract'
                                    render={(props)=>(<AccountingContract toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/purchase-order/create'
                                    render={(props)=>(<CreateAccountingPurchaseOrder toggle={this.toggleSidebar}  history={props.history}
                                            location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/purchase-order'
                                    render={(props)=>(<AccountingPurchaseOrder toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/debit-note/create'
                                    render={(props)=>(<CreateAccountingDebitNote toggle={this.toggleSidebar}  history={props.history}
                                            location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/debit-note'
                                    render={(props)=>(<AccountingDebitNote toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/credit-note/create'
                                    render={(props)=>(<CreateAccountingCreditNote toggle={this.toggleSidebar}  history={props.history}
                                            location={props.location}/>)}
                                />
                                <Route
                                    path='/accounting/vouchers/credit-note'
                                    render={(props)=>(<AccountingCreditNote toggle={this.toggleSidebar}  history={props.history}/>)}
                                />
                            </Switch>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}
export default AccountingVouchers;
