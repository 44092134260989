import React, {Component} from 'react';
import '../style/invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';

class Invoice extends Component{

	state = {
		sale: {
			invoice_id: "",
			sale_id: "",
			order_date: "",
			customer_name: "",
			customer_phone: "",
			customer_gst: "",
			shipment_address: "",
			payment_mode: "",
			products: []
		},
		purchase: {
			invoice_id: "",
			purchase_id: "",
			purchase_date: "",
			vendor_name: "",
			vendor_phone: "",
			vendor_gst: "",
			shipment_address: "",
			payment_mode: "",
			products: []
		},
		store: {}
	}

	componentDidMount() {

		if(localStorage.invoice.charAt(0)=='s'){
			Client.getSaleDetails(localStorage.invoice.substring(1)).then((res)=>{
				this.setState({
					sale: {
						invoice_id: res.invoiceId,
						sale_id: res.saleId,
						order_date: res.saleDateAndTime.substring(6),
						customer_name: res.customerName,
						customer_phone: res.customerPhone,
						customer_gst: res.customerGST,
						shipment_address: res.shipmentAddress || "",
						payment_mode: res.paymentMode,
						products: res.products
					},
					store:{
						name: JSON.parse(localStorage.store).shopName,
						address: JSON.parse(localStorage.store).address[0].address,
						phone: JSON.parse(localStorage.store).address[0].phone,
						email: JSON.parse(localStorage.store).email
					}
				})
			})
		}else{
			Client.getPurchaseDetails(localStorage.invoice.substring(1)).then((res)=>{
				this.setState({
					purchase: {
						invoice_id: res.invoiceId,
						purchase_id: res.purchaseId,
						purchase_date: res.purchaseDateAndTime.substring(6),
						vendor_name: res.vendorName,
						vendor_phone: res.vendorPhone,
						vendor_gst: res.vendorGST,
						shipment_address: res.shipmentAddress || "",
						payment_mode: res.paymentMode,
						products: res.products
					},
					store:{
						name: JSON.parse(localStorage.store).shopName,
						address: JSON.parse(localStorage.store).address[0].address,
						phone: JSON.parse(localStorage.store).address[0].phone,
						email: JSON.parse(localStorage.store).email
					}
				})
			})
		}
	}

	render(){

		let tableData;

		if(localStorage.invoice.charAt(0)=="p"){
			tableData = this.state.purchase.products.map((prod)=>(
	    		<tr>
	    			<td>{prod.productName}</td>
	    			<td className="right aligned">{prod.quantity}</td>
	    			<td className="right aligned">{prod.price}</td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td className="right aligned">{prod.price*prod.quantity}</td>
	        	</tr>
	        ));
		}else{
			tableData = this.state.sale.products.map((prod)=>(
	    		<tr>
	    			<td>{prod.productName}</td>
	    			<td className="right aligned">{prod.quantity}</td>
	    			<td className="right aligned">{prod.price}</td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td></td>
	    			<td className="right aligned">{prod.price*prod.quantity}</td>
	        	</tr>
	        ));
		}
			

		let total = 0;

		if(localStorage.invoice.charAt(0)==="p"){
			this.state.purchase.products.forEach((item) => {
				total+=item.price*item.quantity;
			})
		}else{
			this.state.sale.products.forEach((item) => {
				total+=item.price*item.quantity;
			})
		}

		

		return(
		    <div>
		    	{
		    		localStorage.invoice.charAt(0)=="p"
		    		?
		    		<div id="invoice" className="ui container">
				    	<div className="ui grid">
				    		<div className="one column row">
				    			<h2 className="ui dividing header column">Purchase Details</h2>
				    			<button id="print-button" autoFocus className="ui right floated primary button" style={{position: "absolute", top:"10px", right:"10px"}} onClick={() => {window.print()}}>PRINT&nbsp;<i className="print icon"></i></button>
				    		</div>
				    		<div className="two column row">
				    			<div className="column"><strong>Slip ID:</strong>&nbsp;{this.state.purchase.invoice_id}</div>
				    			<div className="column"><strong>Purchase ID:</strong>&nbsp;{this.state.purchase.purchase_id}</div>
				    			<div className="column"><strong>Purchase Date:</strong>&nbsp;{this.state.purchase.purchase_date}</div>
				    			<div className="column"><strong>Shipment No:</strong>&nbsp;</div>
				    		</div>
				    	</div>

				    	<div className="ui two column grid">
				    		<div className="column">
								<div><strong>Buyer:</strong></div>
								<div>{this.state.purchase.vendor_name}</div>
								<div><strong>Phone:</strong>&nbsp;{this.state.purchase.vendor_phone}</div>
								<div><strong>GST:</strong>&nbsp;{this.state.purchase.vendor_gst}</div>
							</div>
							<div className="column">
								<div><strong>Seller:</strong></div>
								<div>{this.state.store.name}</div>
								<div><strong>Address:</strong>&nbsp;{this.state.store.address}</div>
								<div><strong>Phone:</strong>&nbsp;{this.state.store.phone}</div>
								<div><strong>Email:</strong>&nbsp;{this.state.store.email}</div>
							</div>
				    	</div>

				    	<div className="ui grid">
				    		<div className="one column row">
				    			<div className="column">
				    				<div><strong>Shipment Address:</strong></div>
				    				<div>{this.state.purchase.shipment_address}</div>
				    			</div>
				    		</div>
				    	</div>
						
						<div className="ui grid">
							<div className="column">
								<table className="ui celled structured table">
									<thead>
										<tr>
										  <th>Item</th>
										  <th>Qty</th>
										  <th>Price</th>
										  <th>Discount</th>
										  <th>Taxable Amount</th>
										  <th>GST</th>
										  <th>Tax Amount</th>
										  <th>Delivery Charges</th>
										  <th>Total Amount</th>
										</tr>
									</thead>
									<tbody>
										{tableData}
										<tr>
											<td colSpan="8">Grand Total</td>
											<td className="right aligned">{total}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="ui grid">
				    		<div className="one column row">
				    			<div className="column">
				    				<div><strong>Payment Mode:</strong>&nbsp;{this.state.purchase.payment_mode}</div>
				    			</div>
				    		</div>
				    	</div>

						<div className="ui grid">
							<div className="one column row">
								<div className="column">
								<p><strong>Declaration:</strong><br/><br/>
								We declare that this invoice shows the actual price of the goods described above and that all particulars are true ans corerct. the goods sold are intended for end user consumption and not for resale.<br/>
								<em>*This is a computer generated invoice and does not require a physical signature.</em>
								</p>
								<p><strong>Customer Acknowledgement:</strong><br/><br/>
								I, {this.state.purchase.customer_name}, confirm that the said products are being purchased for my internal/personal consumption and not for resale.<br/>
								</p>
							</div>
							</div>
						</div>
					</div>
					:
					<div id="invoice" className="ui container">
				    	<div className="ui grid">
				    		<div className="one column row">
				    			<h2 className="ui dividing header column">Invoice</h2>
				    			<button id="print-button" autoFocus className="ui right floated primary button" style={{position: "absolute", top:"10px", right:"10px"}} onClick={() => {window.print()}}>PRINT&nbsp;<i className="print icon"></i></button>
				    		</div>
				    		<div className="two column row">
				    			<div className="column"><strong>Invoice ID:</strong>&nbsp;{this.state.sale.invoice_id}</div>
				    			<div className="column"><strong>Sale ID:</strong>&nbsp;{this.state.sale.sale_id}</div>
				    			<div className="column"><strong>Order Date:</strong>&nbsp;{this.state.sale.order_date}</div>
				    			<div className="column"><strong>Shipment No:</strong>&nbsp;</div>
				    		</div>
				    	</div>

				    	<div className="ui two column grid">
				    		<div className="column">
								<div><strong>Buyer:</strong></div>
								<div>{this.state.sale.customer_name}</div>
								<div><strong>Phone:</strong>&nbsp;{this.state.sale.customer_phone}</div>
								<div><strong>GST:</strong>&nbsp;{this.state.sale.customer_gst}</div>
							</div>
							<div className="column">
								<div><strong>Seller:</strong></div>
								<div>{this.state.store.name}</div>
								<div><strong>Address:</strong>&nbsp;{this.state.store.address}</div>
								<div><strong>Phone:</strong>&nbsp;{this.state.store.phone}</div>
								<div><strong>Email:</strong>&nbsp;{this.state.store.email}</div>
							</div>
				    	</div>

				    	<div className="ui grid">
				    		<div className="one column row">
				    			<div className="column">
				    				<div><strong>Shipment Address:</strong></div>
				    				<div>{this.state.sale.shipment_address}</div>
				    			</div>
				    		</div>
				    	</div>
						
						<div className="ui grid">
							<div className="column">
								<table className="ui celled structured table">
									<thead>
										<tr>
										  <th>Item</th>
										  <th>Qty</th>
										  <th>Price</th>
										  <th>Discount</th>
										  <th>Taxable Amount</th>
										  <th>GST</th>
										  <th>Tax Amount</th>
										  <th>Delivery Charges</th>
										  <th>Total Amount</th>
										</tr>
									</thead>
									<tbody>
										{tableData}
										<tr>
											<td colSpan="8">Grand Total</td>
											<td className="right aligned">{total}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div className="ui grid">
				    		<div className="one column row">
				    			<div className="column">
				    				<div><strong>Payment Mode:</strong>&nbsp;{this.state.sale.payment_mode}</div>
				    			</div>
				    		</div>
				    	</div>

						<div className="ui grid">
							<div className="one column row">
								<div className="column">
								<p><strong>Declaration:</strong><br/><br/>
								We declare that this invoice shows the actual price of the goods described above and that all particulars are true ans corerct. the goods sold are intended for end user consumption and not for resale.<br/>
								<em>*This is a computer generated invoice and does not require a physical signature.</em>
								</p>
								<p><strong>Customer Acknowledgement:</strong><br/><br/>
								I, {this.state.sale.customer_name}, confirm that the said products are being purchased for my internal/personal consumption and not for resale.<br/>
								</p>
							</div>
							</div>
						</div>
					</div>
		    	}
				    
			</div>
        )
	}
}

export default Invoice;