import React, {Component} from 'react';
import Client from '../services/Client';

class AccountsEntry extends Component{

	state = {
		open: false,
		entry: this.props.entry
	}

	handleCollapse = () => {
		this.setState({
			open: !this.state.open
		})
	}

    handleInputChange = (e) => {
        let v = this.state.staff;
        v[e.target.name] = e.target.value;
        this.setState({staff: v});
    };

    componentWillReceiveProps = (nextProps) => {
		this.setState({
			entry: nextProps.entry
		})
    }

    toggleActive = (e) => {
        let v = this.state.staff;
        v['active'] = this.state.staff.active?0:1;
        this.setState({staff: v});
        this.handleUpdateStaff();
    };

	render(){
		if(this.state.open){
			return(
			    <div className="ui fluid card" style={{padding: "15px", cursor: "pointer"}}>
			    	<div className="section">
			    		<div className="ui three column grid">
			    			<div className="two wide field">
                                <input type="text" placeholder="Name" name="name" onChange={this.handleInputChange.bind(this)} value={this.state.staff.name}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Phone" name="phone" onChange={this.handleInputChange.bind(this)} value={this.state.staff.phone}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Email" name="email" onChange={this.handleInputChange.bind(this)} value={this.state.staff.email}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="City" name="city" onChange={this.handleInputChange.bind(this)} value={this.state.staff.city}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Role" name="role" onChange={this.handleInputChange.bind(this)} value={this.state.staff.role}/>
                            </div>
                            <div className="two wide field">
                                <input type="Password" placeholder="Password" name="pass" onChange={this.handleInputChange.bind(this)} value={this.state.staff.pass}/>
                            </div>
			    		</div>			    		
	                    <div className="ui button two wide right floated" onClick={this.handleUpdateStaff}>Update</div>
			    	</div>
			    </div>
		    )
		}else{
			return(
				<div className="ui" style={{paddingLeft: "15px", paddingRight: "15px", cursor: "pointer", fontSize:'12px'}}>
			    	<div className="section">
			    		<div className="ui grid">
			    			<div className="three wide column">{this.state.entry.hub}</div>
		    				<div className="three wide column">{this.state.entry.type}</div>
		    				<div className="three wide column">{this.state.entry.mode}</div>
		    				<div className="two wide column">{this.state.entry.date}</div>
		    				<div className="three wide column">{this.state.entry.partner}</div>
		    				<div className="one wide column">{this.state.entry.dr}</div>
		    				<div className="one wide column">{this.state.entry.cr}</div>
			    		</div>
			    	</div>
			    </div>
        	)
		}		
	}
}

export default AccountsEntry;