import React from 'react';
import { Responsive, Segment, Rating, TextArea, Modal, Step, Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Label, Message } from 'semantic-ui-react'
import FileOrdersPreview from './file-order-preview';
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
import Client from '../services/Client';
import {CopyToClipboard} from 'react-copy-to-clipboard';
class VerifyOrder extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            order_data: props.order_data || {},
            checkbox: props.confirmationData ? JSON.parse(props.confirmationData.checkbox) : [true, true, true],
            rating: props.confirmationData ? props.confirmationData.rating : 0,
            review: props.confirmationData ? props.confirmationData.review : '',
            confirmationData: props.confirmationData,
            person_name: props.order_data.customer_data.name,
            person_phone: props.order_data.customer_data.phone,
        }
    }

    componentDidMount(){
    }

    generateLink(){
        this.setState({
            generateLink: true
        })
        Client.initiateOrderVerification(this.state.order_data.slip_no, this.state.person_name, this.state.person_phone).then(res=>{
            if(res.responseResult == "success"){
                var order_data = {...this.state.order_data}
                order_data.confirmation = res.confirmation_data
                this.setState({
                    order_data: order_data,
                    generateLink: false
                })
            }
            else{
                this.setState({
                    linkGenerationError: res.response,
                    generateLink: false
                })
            }
        })
        // this.props.loadOrders();
    }

    orderSteps(status){
        let formated_steps = []
        let steps = this.state.steps
        for (const step in steps) {
            if (steps[step].stages.includes(status)) {
                formated_steps.push({
                    step:step,
                    active:1,
                    icon:steps[step].icon
                })
            } else {
                formated_steps.push({
                    step:step,
                    active:0,
                    icon:steps[step].icon
                })
            }
            console.log(steps[step]);
        }

        return formated_steps
    }

    handleInputChange(e, data){
        var state = {...this.state}
        state[data.name] = e.target.value || data.value
        this.setState(state)
    }

    render(){
        return(
        <>
            <div style={{padding: '2% 10% 2% 10%'}}>
                {
                this.state.order_data.confirmation
                ?
                    this.state.order_data.confirmation.verification_status == "verified"
                    ?
                    <Segment>
                        <Label as='a' color={this.state.confirmationRecorded ? 'green' : 'red'} ribbon>
                            Confirmation Recorded.
                        </Label>
                        <Message>
                            <Message.Header>Confirmed By</Message.Header>
                            <p>
                                {this.state.confirmationData.concerned_name + " (" + this.state.confirmationData.phone + ")"}
                            </p>
                        </Message>
                        <br/><br/>
                        <Checkbox disabled={true} name="checkbox_0" label='Received materials as per list.' checked={this.state.checkbox[0]}/>
                        <br/>
                        <Checkbox disabled={true} name="checkbox_1" label='All materials are in good conditions.' checked={this.state.checkbox[1]}/>
                        <br/>
                        <Checkbox disabled={true} name="checkbox_2" label='Proper installation or service done (If Applicable).' checked={this.state.checkbox[2]}/>
                        <br/><br/>
                        <Label>Rating</Label><br/><Rating disabled={true} name="rating" size="huge" icon='star' defaultRating={0} maxRating={5} rating={this.state.rating}/>
                        <br/><br/>
                        <Form><TextArea disabled={true} fluid name="review" placeholder='Remarks / Review' value={this.state.review}/></Form>
                    </Segment>
                    :
                    <Message style={{cursor:'pointer'}}>
                        {
                            this.state.linkGenerationError
                            ?
                            <Message>{this.state.linkGenerationError}</Message>
                            :
                            <div>
                                <CopyToClipboard text={"https://deskneed.in/order/verify/" + this.state.order_data.confirmation.token}
                                onCopy={() => alert("copied")}>
                                    <span>Link to Verify: {"https://deskneed.in/order/verify/" + this.state.order_data.confirmation.token}<Icon name="copy"/></span>
                                </CopyToClipboard>
                                <br/>
                                <span>Link Sent to: {this.state.order_data.confirmation.concerned_name + " (" + this.state.order_data.confirmation.phone + ")"}</span>
                                <Message warning>
                                    <Message.Header>ALERT!</Message.Header>
                                    <p>Only resend if customer says SMS not received. OR data was incorrect</p>
                                </Message>
                                <Input fluid name="person_name" placeholder="Concerned Person Name" onChange={(e, data) => this.handleInputChange(e, data)} value={this.state.person_name}/>
                                <Input fluid name="person_phone" type="number" placeholder="Phone Number" onChange={(e, data) => this.handleInputChange(e, data)} value={this.state.person_phone}/>
                                <Button color="red" loading={this.generatingLink} onClick={() => this.generateLink()}>Resend SMS!</Button>
                            </div>
                        }
                    </Message>
                :
                <div>
                    <Input fluid name="person_name" placeholder="Concerned Person Name" onChange={(e, data) => this.handleInputChange(e, data)} value={this.state.person_name}/>
                    <Input fluid name="person_phone" type="number" placeholder="Phone Number" onChange={(e, data) => this.handleInputChange(e, data)} value={this.state.person_phone}/>
                    <Button primary loading={this.generatingLink} onClick={() => this.generateLink()}>Generate Link</Button>
                </div>
                }
            </div>
        </>
        )
    }
}
export default VerifyOrder;
