import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Dimmer, Loader, Button, Dropdown, Icon, Modal, Form, Image, Label, Segment} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import SaleView from './sale-voucher-view';
import moment from 'moment';
import QuotationView from './quotation-view';
import DeleveryChallanView from './d-note-view';
import PurchaseView from './purchase-view';
import ContraView from './contra-view'
import PaymentView from './payment-view'
import ReceiptView from './receipt-view'
import JournalView from './journal-view'
const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingLedgerVouchers extends Component{

	constructor(props){
		super(props);

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			start_date: '2019-04-01',
			end_date: '2020-03-31',
			data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "voucher_id", "text": "Voucher Id", "type": "field"},
				{"value": "type", "text": "Type", "type": "field"},
				{"value": "ledger", "text": "Ledger", "type": "select"},
				{"value": "to_ledger", "text": "TO / FROM", "type": "select"},
				{"value": "debit", "text": "Debit", "type": "field"},
				{"value": "credit", "text": "Credit", "type": "field"},
				{"value": "due_by", "text": "Due By", "type": "field"},
			],
			defaultSize: 'mini',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'voucher_id',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
				ledger: []
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 25, value: 25},
				{text: 50, value: 50},
				{text: 100, value: 100},
				{text: 150, value: 150},
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
		}
	}

	componentDidMount() {
		var banks = [7,10];
		var options_voucher_id = [];
		var options_record_ledgers = []
		var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
				options_to_ledger: options_ledger
			},()=>{
				// if (this.props.ledger_voucher) {
				// 	this.setFilter({}, {name:'ledger',value:this.props.ledger_voucher})
				// }
			})
		})
		if(this.props.currentLedger != null && this.props.currentLedger != undefined){
			this.setFilter(null, {name: 'ledger', value: this.props.currentLedger})
			this.getDataRows({...this.state, data_rows_filter: {ledger: [this.props.currentLedger]}});
		}
		else{
			this.getDataRows(this.state);
		}
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
	handleCancel(voucher){
        let data_rows = this.state.reports.map(elem=>{
            if (elem.voucher_id == voucher.voucher_id) {
                return {...elem,...voucher}
            } else {
                return elem
            }
        })
        this.setState({data_rows:data_rows})
    }
    showDataRowDetails(i){
        let currentDataRow = this.state.data_rows[i]
        this.setState({loading_invoice:true,showSaleDetailsModal:true,modal_type:currentDataRow.type})
        switch (currentDataRow.type) {
            case 'sale':
                    Client.getAccountingSales(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]})
                    .then(res=>{
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
                    break;
                case 'quotation':
                        Client.getAccountingInvoiceQuotation(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                        .then(res=>{
                            this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                        })
                        break;
                case 'purchase':
                    Client.getAccountingPurchaseFilters(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                    .then(res=>{
                        console.log(res.data_rows)
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
                    break;
                case 'delivery_note':
                    Client.getAccountingDeleveryChallan(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                    .then(res=>{
                        console.log(res.data_rows)
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
					break;
				case 'contra':
					Client.getAccountingContra(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
					.then(res=>{
						console.log(res.data_rows)
						this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
					})
					break;
				case 'receipt':
					Client.getAccountingReceipt(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
					.then(res=>{
						console.log(res.data_rows)
						this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
					})
					break;
				case 'payment':
					Client.getAccountingPayment(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
					.then(res=>{
						console.log(res.data_rows)
						this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
					})
					break;
				case 'journal':
					Client.getAccountingJournal(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
					.then(res=>{
						console.log(res.data_rows)
						this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
					})
					break;
				default:
					break;
        }
	}
	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createAccountingLedgerVouchers(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {records: [{}]}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getAccountingLedgerVouchers(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search,
			state.data_rows_filter, state.start_date, state.end_date).then(res=>{
				res.data_rows.forEach(function(data_row) {
					data_row.due_by = '--';
					if(data_row.type == "sale" && data_row.status != 'paid'){
						data_row.due_by = this.calculateDueBy(data_row.date);
					}
				}.bind(this));
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size,
				opening_balance: res.opening_balance,
				current_credit: res.current_credit,
				current_debit: res.current_debit,
				closing_balance: res.closing_balance,
			})
		})
	}

	//CUSTOM FUNCTIONS
	changeStateVariableRecord(e, data, index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = this.state.new_data_row;
		records[index][data.name] = data.value || e.target.value
		new_data_row.records = [...records]
		if(data.name == "ledger"){
			this.getRecordReferences(new_data_row, records, index);
		}
		if(data.name == "against_reference"){
			records[index]['options_references'].forEach(function(reference){
				if(reference.value == data.value){
					records[index]['amount'] = reference.payableAmount
				}
			})
		}
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}
	updated(){
		this.setState({
			showCreateDataModal: false,
			creatingNewDataModal: false,
			editMode: false,
			new_data_row: {records: [{}]}
		},()=>{
			this.componentDidMount();
		})
	}
	getRecordReferences(new_data_row, records, index){
		records[index]['loadingReferences'] = true
		this.setState({
			new_data_row: new_data_row
		})
		Client.getAccountingSales(null, null, 'date', 'desc', null, null, null, {'customer': records[index]['ledger'], 'type': 'sale'}).then((res) => {
			records[index]['loadingReferences'] = false
			var options_references = [{text: 'ON ACCOUNT', value: null}]
			res.data_rows.forEach(function(data_row) {
				options_references.push({...data_row, text: data_row.invoiceId + ' - ' + data_row.date + ' - ' + data_row.payableAmount, value: data_row.invoiceId})
			})
			records[index]['options_references'] = options_references
			new_data_row.records = [...records]
			this.setState({
				new_data_row: new_data_row
			})
		})
	}
	calculateDueBy(date){
		var today = new Date();
		date = new Date(date);
		const date1 = new Date(today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate());
		const diffTime = Math.abs(date1.getTime() - date.getTime());
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	}

	addRecord(e){
		e.preventDefault();
		var records = this.state.new_data_row.records;
		var new_data_row = this.state.new_data_row;
		new_data_row.records = [...records, {}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-receipt-record-ledger' + (new_data_row.records.length - 1)).getElementsByTagName('input')[0].focus()
		},300)
		this.makeCalculations();
	}

	removeRecord(index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = {...this.state.new_data_row};
		var records1 = []
		records.forEach(function(element, index1) {
			if(index1 != index){
				records1.push(element)
			}
		});
		new_data_row.records = [...records1]
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}

	makeCalculations(){
		var new_data_row = {...this.state.new_data_row}
		var records_total = 0;
		new_data_row.records.map(function(record){
			records_total = records_total + parseFloat(record.amount)
		})
		new_data_row.records_total = records_total
		this.setState({
			new_data_row: new_data_row
		})
	}

	handleVoucherIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
	}

	setFilter(e, data){
		if(this.props.ledger_voucher){
			console.log("data input",data)
		}
		var filter = {...this.state.data_rows_filter}
		filter[data.name] = [data.value || e.target.value]
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}

	handleDateRangeChange(e, data){
		var state = {...this.state}
		state[data.name] = data.value || e.target.value
		this.setState(state)
		this.getDataRows(state);
	}

	render(){
		return(
			<div>
				<Button size={this.state.defaultSize} onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
				<Button size={this.state.defaultSize} style={{padding:0}}>
					<Dropdown selection
						search name="ledger"
						size={this.state.defaultSize}
						options={this.state['options_ledger']}
						value={this.state.data_rows_filter.ledger[0]}
						placeholder="Ledger" onChange={(e, data) => this.setFilter(e, data)}/>
				</Button>
				<div style={{float: 'right'}}>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.tableFields}
							name="data_rows_search_field"
							value={this.state.data_rows_search_field}
							placeholder="Search Field"
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					{
						this.state.data_rows_search_type == 'select'
						?
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
						:
						<Input type={this.state.data_rows_search_type == 'field' ? 'text' : this.state.data_rows_search_type}
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					}

					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					<Dropdown
						name='data_rows_limit'
						inline
						options={this.state.options_data_rows_limit}
						value={this.state.data_rows_limit}
						onChange={(e, data) => this.changeStateVariable(e, data)}
				    />
					{
						'Showing ' + this.state.data_rows_offset + ' to ' +
						(
							(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
							(this.state.data_rows_offset + this.state.data_rows_limit) :
							this.state.data_rows_size
						) +
						' of ' + this.state.data_rows_size + ' '
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<div>
					<Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
					placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
					<Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
					placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
				</div>
				<Segment.Group size='mini' floated='right'>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell></Table.HeaderCell>
								<Table.HeaderCell>Debit</Table.HeaderCell>
								<Table.HeaderCell>Credit</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Opening</Table.Cell>
								<Table.Cell>{this.state.opening_balance}</Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Current</Table.Cell>
								<Table.Cell>{this.state.current_debit}</Table.Cell>
								<Table.Cell>{this.state.current_credit}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Closing</Table.Cell>
								<Table.Cell>{this.state.closing_balance}</Table.Cell>
								<Table.Cell></Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Segment.Group>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell style={{backgroundColor: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'red' : 'white' : 'white'),
										color: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'white' : 'black' : 'black')}}>
										{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}
									</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {records: [{}]}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Select options={this.state.options_voucher_id}
									allowAdditions
									search
									onAddItem={(e, value) => this.handleVoucherIdAdditions(e, value)}
									type="text"
									name="voucher_id"
									placeholder="Invoice #" label="Voucher #" selection value={this.state.new_data_row.voucher_id}
									onChange ={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									required/>
								<Form.Input type="date" required fluid name="date" value={this.state.new_data_row.date} label="Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select selection search options={this.state.options_ledger} type="text" required fluid name="account" value={this.state.new_data_row.account} label="Account" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="number" fluid required name="amount" value={this.state.new_data_row.amount} label="Amount" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Table celled compact className="accounting-invoice-product-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>LEDGER</Table.HeaderCell>
										<Table.HeaderCell>REFERENCE</Table.HeaderCell>
										<Table.HeaderCell>AMOUNT</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
								{
									this.state.new_data_row.records.map((record, index) => (
										<Table.Row>
											<Table.Cell>
												<Form.Select id={"accounting-receipt-record-ledger" + index} selection search options={this.state.options_record_ledgers}
												type="text" required fluid name="ledger" value={record.ledger} placeholder="Ledger" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell>
												<Form.Select clearable selection search
												options={record.options_references} type="text" required fluid
												loading={record.loadingReferences}
												name="against_reference" value={record.against_reference} placeholder="Against Reference" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell>
												<Form.Input type="number" fluid required name="amount" value={record.amount} placeholder="Amount" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell collapsing>
												<Icon circular name='delete' size='large' onClick={() => this.removeRecord(index)} color="red"/>
											</Table.Cell>
										</Table.Row>
									))
								}
								</Table.Body>
								<Table.Footer fullWidth>
									<Table.Row>
										<Table.HeaderCell colSpan='4'>
											<Button secondary onClick={(e) => this.addRecord(e)}>Add Record</Button>
											<Label size='large' color='blue'>Total: {this.state.new_data_row.records_total}</Label>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
							<Form.Input type="text" fluid name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
				<Modal size="large" open={this.state.showSaleDetailsModal} onClose={() => this.setState({showSaleDetailsModal:false, showCancelInvoiceOption: false,modal_type:false})}>
					<Modal.Content scrolling>
						{
                            this.state.loading_invoice
                            ?
                            <Segment>
                                <Dimmer active>
                                <Loader size='medium'>Loading</Loader>
                                </Dimmer>

                                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'sale'
                            // true
                            ?
                            <SaleView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'quotation'
                            // true
                            ?
                            <QuotationView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'delivery_note'
                            // true
                            ?
                            <DeleveryChallanView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'purchase'
                            // true
                            ?
                            <PurchaseView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
						{
							 (!this.state.loading_invoice) && this.state.modal_type == 'contra'
							 ?
							 <ContraView new_data_row={this.state.currentDataRow} editMode={true} updated={this.updated.bind(this)}/>
							 :
							 null
						}
						{
							 (!this.state.loading_invoice) && this.state.modal_type == 'payment'
							 ?
							 <PaymentView new_data_row={this.state.currentDataRow} editMode={true} updated={this.updated.bind(this)}/>
							 :
							 null
						}
						{
							 (!this.state.loading_invoice) && this.state.modal_type == 'receipt'
							 ?
							 <ReceiptView new_data_row={this.state.currentDataRow} editMode={true} updated={this.updated.bind(this)}/>
							 :
							 null
						}
						{
							 (!this.state.loading_invoice) && this.state.modal_type == 'journal'
							 ?
							 <JournalView new_data_row={this.state.currentDataRow} editMode={true} updated={this.updated.bind(this)}/>
							 :
							 null
						}
					</Modal.Content>

				</Modal>
			</div>
        )
	}
}

export default AccountingLedgerVouchers;
