import React,{Component} from  'react';
import Client from "../services/Client";
import StoreDetails from '../components/storeDetailsContainer'
import AddShopForm from "../components/add-shop-form";
import TopBarInside from "../components/topbar-inside";
import { Dropdown, Input } from 'semantic-ui-react';
import { Divider, Form, Label , Checkbox, Button } from 'semantic-ui-react'
// import '../style/home.css';

import name from'../assets/blue/ic_acc_2.png';
import email from'../assets/blue/mail.png';
import id from'../assets/blue/ic_acc_3.png';
import phone from'../assets/blue/ic_acc_1.png';
import location from'../assets/blue/ic_acc_4.png';
import role from'../assets/blue/role.jpg';
import company from'../assets/blue/company.jpg';

class FranchizeeDashboard extends Component{
    constructor(props){
        super(props)
        this.state={
            showDetails:false,
            fetching:true,
            stores:[],
            store:{},
            cities:[],
            city: localStorage.getItem('city'),
            disabled: true,
            userData: {},
        }
    }
    componentDidMount(){
        
    }
  

    render(){
        return (
            <div>
              
            </div>
        )
    }
}
export default FranchizeeDashboard;
