import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

class CreateAccountingItem extends Component{

	state = {
		item: {
		},
	}

	componentDidMount() {
		this.setState({
			item: this.props.item || {}
		})
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			item: nextProps.item || {}
		})
	}

	handleInputItem(e, data){
		var item = this.state.item;
		item[data.name] = e.target.value
		this.setState({
			item: item
		})
	}

	createItem(){
		this.setState({
			loaderCreatingItem: true
		})
		Client.accountingItemCreate(this.state.item).then(res=>{
			this.props.newProductCreated(res.product);
			this.setState({
				item: {},
				loaderCreatingItem: false
			})
        })
	}

	render(){
		return(
		    <div>
				<Input placeholder='Product Name' name="name" value={this.state.item.name || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputItem(e, data)}/>
				<Input placeholder='HSN' name="HSN" value={this.state.item.HSN || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputItem(e, data)}/>
				<Input placeholder='Rate (Excluding GST)' name="rate" value={this.state.item.rate || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputItem(e, data)}/>
				<Input placeholder='MRP' name="MRP" value={this.state.item.MRP || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputItem(e, data)}/>
				<Input placeholder='Tax %' name="tax" value={this.state.item.tax || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputItem(e, data)}/>
				<Button primary onClick={() => this.createItem()} loading={this.state.loaderCreatingItem}>CREATE ITEM</Button>
			</div>
        )
	}
}

export default CreateAccountingItem;
