import React, {Component} from  'react';

import { Table } from 'semantic-ui-react';

import Client from '../services/Client';

class BookingRow extends Component{

    constructor(props){
        super(props);

        this.state = {
            bookingDetails: {}
        }
    }

    componentDidMount(){
        Client.getBookingDetails(this.props.booking.bookingId).then(
            (res) => {
                this.setState({
                    bookingDetails: res
                })
            }
        )
    }

    render(){

        return(
            <Table.Row>
                <Table.Cell>{this.props.booking.bookingId}</Table.Cell>
                <Table.Cell>{this.props.booking.bookingDateAndTime ? this.props.booking.bookingDateAndTime.substr(6, 10) +', '+ this.props.booking.bookingDateAndTime.substr(23, 5) : null}</Table.Cell>
                {
                    //<Table.Cell>{this.props.booking.visitingDateAndTime ? this.props.booking.visitingDateAndTime.substr(6, 10) +', '+ this.props.booking.visitingDateAndTime.substr(23, 5) : null}</Table.Cell>
                }
                <Table.Cell>{this.props.booking.customerName}</Table.Cell>
                <Table.Cell>{this.props.booking.customerPhone}</Table.Cell>
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.bookingDetails.noOfUnits}</Table.Cell>
                    :
                    null
                }
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.bookingDetails.addons ? this.state.bookingDetails.addons.toString() : null}</Table.Cell>
                    :
                    null
                }
                <Table.Cell>{this.props.booking.amount}</Table.Cell>
                <Table.Cell>{this.props.booking.status || this.state.bookingDetails.bookingStatus}</Table.Cell>
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.bookingDetails.payment}</Table.Cell>
                    :
                    null
                }
                {
                    this.props.extended
                    ?
                    <Table.Cell>{this.state.bookingDetails.paymentStatus}</Table.Cell>
                    :
                    null
                }
            </Table.Row>
        )
    };
}

export default BookingRow;
