import React,{Component} from 'react';
import VerticalMenu from '../components/vertical-menu';
import {Route,Switch,Redirect} from 'react-router-dom';
import '../style/page-container.css';
import AddBusiness from "./add-business";
import Businesses from "../routes/businesses";
import Home from "./home";
import Graphs from "./graphs";
import TopBar from "../components/topbar";
import MobileBar from "../components/mobile-bar";

import { initializeFirebase, askForPermissioToReceiveNotifications } from '../services/push-notifications';

import Attendance from "./attendance";
import AddOrder from './add-order';
import Requests from "./requests";
import Bookings from "./bookings";
import AddBooking from "./add-booking";
import Staffs from "./staffs";
import FinancialReport from './financial-report'
import AccountsLedger from "./accounts-ledger";
import AccountsCash from "./accounts-cash";
import Invoice from "./invoice";
import AccountingVouchers from "./accounting-vouchers";
import AccountingInvoice from "./accounting-invoice";
import CreateAccountingInvoice from "./create-accounting-invoice";
import AccountingQuotation from "./accounting-quotation";
import CreateAccountingQuotation from "./create-accounting-quotation";
import AccountingPurchase from "./accounting-purchase";
import CreateAccountingPurchase from "./create-accounting-purchase";
import AccountingCustomer from "./accounting-customer";
import AccountingVendor from "./accounting-vendor";
import AccountingItem from "./accounting-item";
import Departments from "./departments";
import Lead from "./lead-management";
import AccountingTrialBalance from "./accounting-trial-balance";
import OrderSlip from "./order-slip";
import OrderSlipUpdate from "./order-slip-update";
import OrderSlipPurchase from "./order-slip-purchase";
import OrderSlipCreate from "./create-order-slip";
import AccountingLedgerVouchers from "./accounting-ledger-vouchers";
import AccountingMastersLedger from "./accounting-masters-ledger";
import AccountingMastersGroup from "./accounting-masters-group";
import BookingsPage from "./bookings";
import TrackRider from "./track-rider";
import OrdersPage from "./orders-page";
import '../style/print.css';
import Client from '../services/Client';
import {Grid, Menu} from 'semantic-ui-react';
import Inventory from './inventory';
import Swap from './swap';
import AccountingContract from './contract';
import Due from './due_report';
import VendorDue from './vendor_due_report';
import VendorCycle from './vendor_cycle_report';
import FranchizeeDashboard from './franchizee-dashboard';
import AccountingGstReport from './accounting-gst-report';
import CustomerInsight from './customer-insight';

class PageContainer extends Component{

    constructor(props){
        super(props);

        this.state = {
            cities:[],
            city: localStorage.getItem('city') || '',
            visibleSidebar: false,
            initiated: false,
        }
    }

    // componentDidMount(){

    //     var cities = [{value:'Silchar', text:'Silchar'},{value:'Guwahati', text:'Guwahati'},{value:'Tezpur', text:'Tezpur'}];

    //     localStorage.setItem("cities", JSON.stringify(cities));

    //     this.setState({
    //         cities: cities,
    //         visibleSidebar: window.innerWidth > 720,
    //         innerWidth: window.innerWidth
    //     })

    //     Client.getAppData().then(res=>{
    //         localStorage.setItem('tCategory', JSON.stringify(res.P_CATEGORIES));
    //         localStorage.setItem('serviceCategories', JSON.stringify(res.serviceCategories));
    //         localStorage.setItem('paymentMethods', JSON.stringify(res.paymentMethods));
    //         localStorage.setItem('cities', res.OPERATING_CITIES);

    //         this.setState({
    //             initiated: true,
    //         })
    //     })

    //     this.toggleSidebar = this.toggleSidebar.bind(this);
    // }
    componentDidMount(){
        Client.getAppData().then(res=>{
            localStorage.setItem('tCategory', JSON.stringify(res.P_CATEGORIES));
            localStorage.setItem('serviceCategories', JSON.stringify(res.serviceCategories));
            localStorage.setItem('paymentMethods', JSON.stringify(res.paymentMethods));
            localStorage.setItem('cities', res.OPERATING_CITIES);
            localStorage.setItem('option_statuses', JSON.stringify(res.OPTIONS_STATUSES));
            localStorage.setItem('option_statuses', JSON.stringify(res.OPTIONS_STATUSES));
            var cities;
            if(JSON.parse(localStorage.getItem('userData')).cities != 'All'){
                let string =JSON.parse(localStorage.getItem('userData')).cities
                cities = JSON.parse(string)
            } else {
                cities = res.OPERATING_CITIES
            }

            this.setState({
                authorized_for: res.AUTHORIZED_FOR,
                cities: cities.map((city)=>({text: city, value: city})),
                city: res.OPERATING_CITIES[0],
            },()=>{
                let role, city
                var allowedRoutes=[]
                role = JSON.parse(localStorage.getItem('userData')).role
                // city = JSON.parse(localStorage.getItem('userData')).city
                var JSONrole = ""
                try {
                  JSONrole =  JSON.parse(role);
                } catch (e) {
                    JSONrole= ""
                }
                if(Array.isArray(JSONrole)){
                    JSONrole.forEach(element => {
                        allowedRoutes = [...allowedRoutes,...this.state.authorized_for[element]]
                    });
                } else {
                    allowedRoutes = this.state.authorized_for[role]
                }
                this.setState({
                    // city:city,
                    role:role,
                    allowedRoutes:allowedRoutes,
                    initiated: true
                    },()=>{
                        var pathname= window.location.pathname
                        if (!this.state.allowedRoutes.includes(pathname)) {
                            window.location = '/home'
                        }
                    }
                )
            })

        })

        initializeFirebase();
        askForPermissioToReceiveNotifications().then((token)=> {
            if(Client.loggedIn()){
                Client.updateUserNotification(token, JSON.parse(localStorage.getItem('userData'))).then((res) => {
                })
            }
        });

        window.addEventListener('resize', ()=>{
            if (window.innerWidth>992) {
                this.setState({
                    visibleSidebar:true
                })
            }
            else{
                this.setState({
                    visibleSidebar:false
                })
            }
        })

        this.setState({
            visibleSidebar: window.innerWidth > 720,
            innerWidth: window.innerWidth
        })

        // const pushNotificationSuported = isPushNotificationSupported();
        // if (pushNotificationSuported) {
        //     registerServiceWorker();
        //     initializePushNotifications().then(function(consent){
        //         if(consent === 'granted') {
        //             sendNotification();
        //         }
        //     });
        // }
    }

    toggleSidebar(){
        if(window.innerWidth<992){
            this.setState({
                visibleSidebar: !this.state.visibleSidebar
            })
        }
    }

    handleCityChange(e, data){
        localStorage.setItem('city',data.value);
        this.setState({
            city: data.value
        },()=>{
           window.location.reload()
        })
    }

    render(){

        if(this.state.initiated){
            return(
                <div>
                    <Grid padded="false">
                        <Grid.Row style={{ backgroundColor: '#444444'}} className="noprint marginTopAll">
                            <TopBar handleCityChange={this.handleCityChange.bind(this)} role={this.state.role} cities={this.state.cities} />
                        </Grid.Row>
                        <Grid.Row style={{padding:"0px"}} only='tablet mobile'>
                            <MobileBar visbleSidebar={this.state.visibleSidebar} toggle={this.toggleSidebar.bind(this)}/>
                        </Grid.Row>
                        <Grid.Row className="heightManager" stacked style={{ padding: '0'}}>
                            <Grid.Column id="ver" className="noprint" mobile={10} tablet={6} computer={3} visible={this.state.visibleSidebar} as={Menu} inverted style={{display: this.state.visibleSidebar?"inline-block":"none", borderRadius: "0", padding: 0, height:'100%'}}>
                                <VerticalMenu
                                    authorized_for={this.state.allowedRoutes}
                                    toggle={this.toggleSidebar.bind(this)}/>
                            </Grid.Column>

                            <Grid.Column  mobile="16" tablet="16" computer={13}  style={{overflowY: "auto", padding: 0, height:'100%'}}>

                                <Switch style={{width: "100%"}} onChange={()=>{alert("lol")}}>
                                    <Route
                                        path="/home"

                                        render={(props)=>(<Home toggle={this.toggleSidebar} history={props.history}/>)}
                                    />
                                    <Route
                                        path="/graphs"
                                        render={()=>(<Graphs toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path="/attendance"
                                        render={()=>(<Attendance toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path="/swap"
                                        render={(props)=>(<Swap toggle={this.toggleSidebar} history={props.history}/>)}
                                    />
                                    <Route
                                        path="/businesses/add"
                                        render={()=>(<AddBusiness toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path="/businesses"
                                        render={()=>(<Businesses cities={this.state.cities} toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path="/inventory"
                                        render={()=>(<Inventory toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path="/finance/reports"
                                        render={()=>(<FinancialReport toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/orders/add'
                                        render={()=>(<AddOrder toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/orders'
                                        render={()=>(<BookingsPage toggle={this.toggleSidebar} city={this.state.city} cities={this.state.cities} store={this.state.store} orderOrBook={false}/>)}
                                    />
                                    <Route
                                        path='/bookings/add'
                                        render={()=>(<AddBooking toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/accounting/reports/due'
                                        render={(props)=>(<Due toggle={this.toggleSidebar} history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/reports/vendor/due'
                                        render={(props)=>(<VendorDue toggle={this.toggleSidebar} history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/reports/vendor/cycle'
                                        render={(props)=>(<VendorCycle toggle={this.toggleSidebar} history={props.history}/>)}
                                    />
                                    <Route
                                        path='/bookings'
                                        render={()=>(<BookingsPage toggle={this.toggleSidebar} city={this.state.city} cities={this.state.cities} orderOrBook={true}/>)}
                                    />
                                    <Route
                                        path='/franchizee'
                                        render={(props)=>(<FranchizeeDashboard history={props.history} toggle={this.toggleSidebar} city={this.state.city} cities={this.state.cities} />)}
                                    />
                                    <Route
                                        path='/staffs'
                                        render={()=>(<Staffs cities={this.state.cities} toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/TrackRider'
                                        render={()=>(<TrackRider toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/accounts/ledger'
                                        render={()=>(<AccountsLedger toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/accounts/cash'
                                        render={()=>(<AccountsCash toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/accounts'
                                        render={()=>(<AccountsLedger toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route
                                        path='/requests'
                                        render={()=>(<Requests toggle={this.toggleSidebar}/>)}
                                    />
                                    <Route path='/invoice' component={Invoice} />
                                    <Route
                                        path='/accounting/vouchers/invoice/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/invoice'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/quotation/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/quotation'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/challan'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/purchase/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/purchase-order/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/debit-note/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/credit-note/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/purchase'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/payment/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/payment'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/receipt/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/receipt'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/contra/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/contra'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/contract/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                        location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/contract'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/purchase-order'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                     <Route
                                        path='/accounting/vouchers/debit-note'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/credit-note'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/journal/create'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}
                                              location={props.location}/>)}
                                    />
                                    <Route
                                        path='/accounting/vouchers/journal'
                                        render={(props)=>(<AccountingVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/customer'
                                        render={(props)=>(<AccountingCustomer toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/item'
                                        render={(props)=>(<AccountingItem toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/lead'
                                        render={(props)=>(<Lead cities={this.state.cities} toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/order/slip/update'
                                        render={(props)=>(<OrderSlipUpdate toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/order/slip/purchase'
                                        render={(props)=>(<OrderSlipPurchase toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/order/slip/create'
                                        render={(props)=>(<OrderSlipCreate toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/reports/trial'
                                        render={(props)=>(<AccountingTrialBalance toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/reports/gst'
                                        render={(props)=>(<AccountingGstReport toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/masters/customer'
                                        render={(props)=>(<AccountingCustomer cities={this.state.cities} toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/masters/vendor'
                                        render={(props)=>(<AccountingVendor cities={this.state.cities
                                        } toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/masters/ledger'
                                        render={(props)=>(<AccountingMastersLedger toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/masters/group'
                                        render={(props)=>(<AccountingMastersGroup toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/analytics/customer'
                                        render={(props)=>(<CustomerInsight toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/departments'
                                        render={(props)=>(<Departments toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/order/slip'
                                        render={(props)=>(<OrderSlip toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Route
                                        path='/accounting/ledger/vouchers'
                                        render={(props)=>(<AccountingLedgerVouchers toggle={this.toggleSidebar}  history={props.history}/>)}
                                    />
                                    <Redirect exact from='/' to='/home'/>
                                </Switch>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            )
        }else{
            return(
                <div style={{position: 'absolute', top: "50%", left: "50%"}}>
                    <div className="ui active inverted dimmer">
                        <div className="ui text loader">Loading</div>
                    </div>
                </div>
            )
        }
    }
}
export default PageContainer;
