import React from 'react'
import {Card, Dropdown, Input, Button,Loader,Segment,Dimmer,Icon, Image, Checkbox, Grid, Table, Form, Popup} from 'semantic-ui-react'
import Client from '../services/Client';
import '../style/customer-insight.css'

import { BarChart,Bar,LineChart, Line,XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
class CustomerInsight extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            tableFields: [
				{"value": "name", "text": "Name", "type": "field"},
				{"value": "order_count", "text": "Total Orders", "type": "field"},
                {"value": "order_frequency", "text": "Order Freq", "type": "field"},
                {"value": "last_order_on", "text": "Last Order", "type": "field"},
                {"value": "total_customer_value", "text": "Customer Value"},
                {"value": "avg_order_amount", "text": "Avg Order Amt", "type": "field"},
                {"value": "avg_payment_duration", "text": "Avg Payment Dur", "type": "field"},
                {"value": "apm_sale_amount", "text": "Sale/m", "type": "field"},
                {"value": "apm_sale_growth", "text": "Growth/m", "type": "field"},
                {"value": "target_month_sale", "text": "Target Sale", "type": "field"},
                {"value": "current_month_sale", "text": "Current Sale", "type": "field"},
                {"value": "cash_credit_utilisation", "text": "Credit Used", "type": "field"},
            ],
            sort_on:'sale',
            data_rows:[

            ],
            data_rows_source:[],
            options_type: [
				{text:'Product', value: 1},
				{text:'Service', value: 2}
            ],
            local_sort_key:"",
            local_sort_order:"asc",
			options_category: [
				// product options
				{text:'Stationery', value: 'Stationery',type:'1'},
				{text:'Electrical Parts', value: 'Electrical Parts',type:'1'},
				{text:'General HardWare', value: 'General HardWare',type:'1'},
				{text:'AC Parts', value: 'AC Parts',type:'1'},
				{text:'Generator Parts', value: 'Generator Parts',type:'1'},
				{text:'Electronics', value: 'Electronics',type:'1'},
				{text:'Appliances', value: 'Appliances',type:'1'},
				{text:'Fire and Safety', value: 'Fire and Safety',type:'1'},
				{text:'Grocery', value: 'Grocery',type:'1'},
				{text:'Food', value: 'Food',type:'1'},
				{text:'IT Parts', value: 'IT Parts',type:'1'},
				{text:'Surgical Items', value: 'Surgical Items',type:'1'},
				{text:'Medical Etc', value: 'Medical Etc',type:'1'},
				{text:'Chemical', value: 'Chemical',type:'1'},
				{text:'Carpentary Items', value: 'Carpentary Items',type:'1'},
				{text:'Other Products', value: 'Other Products',type:'1'},
				//Service options
				{text:'Plumbing Service', value: 'Plumbing Service',type:'2'},
				{text:'Electrician Service', value: 'Electrician Service',type:'2'},
				{text:'AC Service', value: 'AC Service',type:'2'},
				{text:'Plumbing and Sanitary', value: 'Plumbing and Sanitary',type:'2'},
				{text:'Generator Service', value: 'Generator Service',type:'2'},
				{text:'Fire and Safety Services', value: 'Fire and Safety Services',type:'2'},
				{text:'Printing Service', value: 'Printing Service',type:'2'},
				{text:'IT Services', value: 'IT Services',type:'2'},
				{text:'Carpentary Services', value: 'Carpentary Services',type:'2'},
				{text:'Other Services', value: 'Other Services',type:'2'},

			],
            data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'name',
			data_rows_search_type: 'field',
            data_rows_search: '',
            local_search_field: 'name',
			local_search_type: 'field',
            local_search: '',
            local_filter:{},
			data_rows_filter: {
                // ledger: [],
                // type:[],
                // category:[]
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
        }
    }
    componentWillMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
        }
        this.getDataRows(this.state)
    }
    componentDidMount(){

        // this.prepareGraphDataSingleFactor(this.state.graph_factor)
        var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
				options_to_ledger: options_ledger
			},()=>{
				// if (this.props.ledger_voucher) {
				// 	this.setFilter({}, {name:'ledger',value:this.props.ledger_voucher})
				// }
			})
		})
    }
    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value})
    }
    changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
        // this.getDataRows(state);
        this.localSearch(state)
    }
    getDataRows(state){
        this.setState({loading_inventory_report:true})
        Client.getCustomerAnalytics('test',this.state.data_rows_offset,this.state.data_rows_limit,this.state.data_rows_sort,this.state.data_rows_sort_order,this.state.data_rows_search_field,this.state.data_rows_search_type,this.state.data_rows_search,this.state.data_rows_filter,{},null)
        .then(res=>{
            console.log(res);
            this.setState({
                data_rows:Object.values(res.orderMetric),
                data_rows_source:Object.values(res.orderMetric).map(elem=>{
                    for (const key in elem) {
                        if (elem.hasOwnProperty(key)) {
                            console.log(typeof elem[key]);

                            if(typeof elem[key] == "number"){
                                elem[key]=elem[key].toFixed(2)
                            }
                        }
                    }
                    return elem
                }),
                data_rows_size:Object.values(res.orderMetric).length,
                loading_inventory_report:false
            })

        })
    }
    setFilter(e, data){

		var filter = {...this.state.data_rows_filter}
		filter[data.name] = data.value || e.target.value
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    localSort(key){

        let sort_key = this.state.local_sort_key
        let sort_order  = this.state.local_sort_order
        if (key == sort_key) {
            console.log("same key reversing order");

            sort_order = (sort_order == "asc")?"dsc":"asc"
        }
        let data_rows =this.state.data_rows
        console.log("sorting by :: ",sort_key,key,this.state.local_sort_order,sort_order);
        this.setState({loading_inventory_report:true})
        data_rows.sort((a,b)=>{
            if (sort_order == "asc") {
                return b[key] < a[key] ? 1:-1
            } else {
                return a[key] < b[key] ? 1:-1
            }
        })
        console.log(data_rows);

        this.setState({data_rows:data_rows,local_sort_key:key,local_sort_order:sort_order,loading_inventory_report:false})
    }
    localSearch(state){
        let local_search  = state.local_search
        let local_search_field = state.local_search_field
        let data_rows = []
        console.log(local_search,local_search_field);

        let data_rows_source = [...this.state.data_rows_source]
        if (local_search.trim() == "") {
            data_rows = data_rows_source
        } else {
            data_rows = data_rows_source.filter(elem=>{

                return elem[local_search_field].toString().toUpperCase().includes(local_search.toUpperCase())
            })
        }
        let local_filter = state.local_filter
        for (const currentFilter in local_filter) {
            data_rows =  data_rows.filter(elem=>{
                return local_filter[currentFilter].includes(elem[currentFilter])
            })
        }

        this.setState({data_rows:data_rows})
    }
    setLocalFilter(e,data){
        console.log(data.name,data.value);
        let local_filter = {...this.state.local_filter,[data.name]:data.value}
        if (data.value.length == 0) {
            delete local_filter[data.name]
        }
        this.setState({local_filter},()=>{
            this.localSearch(this.state)
        })
    }
    render(){
        return(
            <div>
                <div style={{display:'flex',flexWrap:'wrap'}}>
                    {/* <Checkbox className="small-label" toggle label="Product/Category"/> */}

                        <Dropdown selection
                            options={this.state.tableFields}
                            name="local_search_field"
                            value={this.state.local_search_field}
                            placeholder="Search Field"
                            onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>

                    {
                        this.state.data_rows_search_type == 'field'
                        ?
                        <Input type="text"
                            name="local_search"
                            size={'mini'}
                            placeholder="Search"
                            value={this.state.local_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
                        :
                            <Dropdown selection
                                search name="local_search"
                                size={'mini'}

                                options={this.state['options_' + this.state.local_search_field]}
                                value={this.state.local_search}
                                placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>

                    }
                </div>
                {
                    this.state.loading_inventory_report
                    ?
                    <Segment style={{width:'100%'}}>
                        <Dimmer active>
                        <Loader size="medium">Loading</Loader>
                        </Dimmer>

                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                    </Segment>
                    :
                    <Table className="ci-table" color="blue" key="blue" celled compact unstackable>
                        <Table.Header>
                            <Table.Row>
                                {
                                    this.state.tableFields.map((field) => (
                                        <Table.HeaderCell
                                        onClick={(e, data) => this.localSort(field.value)}
                                        style={{cursor: 'pointer'}}
                                        >
                                            {field.text}
                                            {
                                                this.state.local_sort_order == "asc" ?
                                                    <Icon name='angle up' style={{visibility: (this.state.local_sort_key == field.value ? 'visible' : 'hidden')}}/>
                                                :
                                                    <Icon name='angle down' style={{visibility: (this.state.local_sort_key == field.value ? 'visible' : 'hidden')}}/>
                                            }
                                            <Popup
                                                trigger={
                                                    <Icon
                                                        color={
                                                            this.state.local_filter.hasOwnProperty(field.value)
                                                            ?
                                                            "blue"
                                                            :
                                                            "black"
                                                        }
                                                        name='filter' onClick={(e)=>{e.stopPropagation()}} />}
                                                pinned
                                                content={""}
                                                on="click"
                                                openOnTriggerClick={(e)=>{e.stopPropagation()}}
                                                >
                                            <Dropdown selection search
                                                value={this.state.local_filter[field.value]}
                                                onChange={(e,data)=>{this.setLocalFilter(e,data)}}
                                                name={field.value}
                                                multiple
                                                placeholder="Search"
                                                options={this.state.data_rows_source.map((elem)=>{
                                                    return {value:elem[field.value],text:elem[field.value]}
                                                })}
                                            />
                                            </Popup>
                                        </Table.HeaderCell>
                                    ))
                                }
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                        {
                            this.state.data_rows.map((data_row, index) => (
                                <Table.Row className="data-row-table-row"
                                // onClick={() => this.showDataRowDetails(index)}
                                >
                                {
                                    this.state.tableFields.map((field) => (
                                        <Table.Cell >
                                                {/* { data_row["apm_sale_growth"] } */}
                                            {field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}
                                        </Table.Cell>
                                    ))
                                }
                                </Table.Row>
                            ))
                        }
                        </Table.Body>
                    </Table>
                    }
                </div>
        )
    }
}
export default CustomerInsight
