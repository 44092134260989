
import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import '../style/topbar.css'
import { Dropdown, Input,Button,Icon, Label, Comment } from 'semantic-ui-react'

import * as firebase from "firebase/app";
import "firebase/messaging";

const logo = require('../assets/deskneedslogo2.png')
// import LogOut from "../routes/logout";

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

class TopBar extends Component {

    state = {
        cities:this.props.cities
    }

    constructor(props){
        super(props);
        console.log(this.props.cities);
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            cities: nextProps.cities
        })
    }

    componentDidMount(){
        var notificationHistory = JSON.parse(localStorage.getItem('notificationHistory'));
        if(notificationHistory == null || notificationHistory == undefined)
            notificationHistory = [];
        var unseenNotification = 0;
        notificationHistory.forEach((notification) => {
            if(notification.seen == false){
                    unseenNotification++;
            }
        })
        this.setState({
            notificationHistory: notificationHistory,
            unseenNotification: unseenNotification,
            cities: this.props.cities
        })

        try {
            const messaging = firebase.messaging();
            messaging.onMessage((payload) => {
                // console.log('FCM NOTIFICATION', payload);
                var notificationHistory = JSON.parse(localStorage.getItem('notificationHistory'));
                if(notificationHistory == null || notificationHistory == undefined)
                    notificationHistory = [];
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                var dateTime = date+' '+time;
                notificationHistory = [{...payload.data, seen:false, time: dateTime}, ...notificationHistory]
                if(notificationHistory.length > 30)
                    notificationHistory.pop()
                localStorage.setItem('notificationHistory', JSON.stringify(notificationHistory));
                var unseenNotification = 0;
                notificationHistory.forEach((notification) => {
                    if(notification.seen == false){
                            unseenNotification++;
                    }
                })
                this.setState({
                    notificationHistory: notificationHistory,
                    unseenNotification: unseenNotification
                })
                var notification = new Notification(payload.data.title,{
                    body: payload.data.body,
                    icon: payload.data.icon
                });
            });
            console.log('FCM NOTIFICATION LISTEN', "LISTENING");
        } catch (error) {
            console.log('FCM NOTIFICATION LISTEN', "ERROR");
            console.error(error);
        }

        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
    }

    handleVisibilityChange = () => {
        if (!document[hidden]) {
            var notificationHistory = JSON.parse(localStorage.getItem('notificationHistory'));
            if(notificationHistory == null || notificationHistory == undefined)
                notificationHistory = [];

            var indexDbNotifications = [];
            if(window.indexedDB){
                console.log('IndexedDB is supported');
            }
            var request = window.indexedDB.open('dsk_admin_db', 3);
            var db;
            var store;
            request.onsuccess = function(event) {
                console.log('[onsuccess]', request.result);
                db = event.target.result; // === request.result
                var transaction = db.transaction('notifications', 'readwrite');
                var objectStore = transaction.objectStore('notifications');
                var indexDbNotifications = objectStore.getAll();
                indexDbNotifications.onsuccess = function(event) {
                    console.log(indexDbNotifications.result);
                    indexDbNotifications = indexDbNotifications.result.reverse();
                    notificationHistory = [...indexDbNotifications.concat(notificationHistory)];
                    if(notificationHistory.length > 30)
                        notificationHistory.pop()
                    localStorage.setItem('notificationHistory', JSON.stringify(notificationHistory));

                    objectStore.clear();

                    var unseenNotification = 0;
                    console.log(notificationHistory);
                    notificationHistory.forEach((notification) => {
                        if(notification.seen == false){
                                unseenNotification++;
                        }
                    })

                    this.setState({
                        notificationHistory: notificationHistory,
                        unseenNotification: unseenNotification
                    })
                }.bind(this);
            }.bind(this);
            request.onerror = function(event) {
                console.log('[onerror]', request.error);
            };
            request.onupgradeneeded = function(event) {
                console.log('IDB DATABASE UPDATED');
                var db = event.target.result;
                if (!db.objectStoreNames.contains('notifications')) {
                    var store = db.createObjectStore('notifications', {autoIncrement: true});
                }
            };
        }
    }

    setNotificationSeen(index){
        var notificationHistory = JSON.parse(localStorage.getItem('notificationHistory'));
        notificationHistory[index].seen = true
        localStorage.setItem('notificationHistory', JSON.stringify(notificationHistory));

        var unseenNotification = 0;
        notificationHistory.forEach((notification) => {
            if(notification.seen == false){
                    unseenNotification++;
            }
        })
        this.setState({
            notificationHistory: notificationHistory,
            unseenNotification: unseenNotification
        })
    }

    render() {
        return (
            <div className="ui top fixed  menu" id="topbar" style={{padding:0}}>
                <img alt="logo" className="brand-image" src={logo} style={{}} />
                <div className="right menu">
                    <a className="item">
                        <Dropdown
                            search selection
                            clearable
                            placeholder="Select a city"
                            options={this.props.cities}
                            onChange={(e,data)=>this.props.handleCityChange(e,data)}
                            value={localStorage.getItem('city')||''}
                            />
                    </a>
                    <Button
                        icon='bell'
                        color="yellow"
                        label={{ as: 'a', basic: true, content: (this.state.unseenNotification || 0) }}
                        labelPosition='right'
                        onClick={(e, data) => this.setState({showNotificationList: !this.state.showNotificationList})}
                    />
                    <NavLink to="/logout"  className="item">
                        Logout
                    </NavLink>
                </div>
                <div style={{marginTop:'50px', height:'calc(100vh - 50px)', overflowY:'scroll',
                    background:'rgb(235,240,240)' , width:'35vw', position:'fixed', right:'0', zIndex:1000, display: this.state.showNotificationList ? 'block' : 'none'}}>
                    <Comment.Group>
                    {
                        this.state.notificationHistory
                        ?
                        this.state.notificationHistory.map((notification, index) => (
                            <Comment className="notification_comments"
                                style={{backgroundColor: notification.seen ? 'white' : 'yellow'}}>
                                {
                                    notification.icon
                                    ?
                                    <Comment.Avatar src='/images/avatar/small/matt.jpg' />
                                    :
                                    null
                                }
                                <Comment.Content>
                                    <Comment.Author as='a'>{notification.comment_by || "Team Deskneed"}</Comment.Author>
                                    <Comment.Metadata>
                                        <div>{notification.time}</div>
                                        </Comment.Metadata>
                                    <Comment.Text style={{whiteSpace: 'pre-wrap'}}>
                                        <b>{notification.title}</b><br/>
                                        {notification.body}
                                    </Comment.Text>
                                    {
                                        !notification.seen
                                        ?
                                        <Comment.Actions onClick={(e, data) => this.setNotificationSeen(index)}>
                                            <Comment.Action style={{color:'blue'}}>
                                                Mark Seen
                                            </Comment.Action>
                                        </Comment.Actions>
                                        :
                                        null
                                    }
                                </Comment.Content>
                            </Comment>
                        ))
                        :
                        null
                    }
                    </Comment.Group>
                </div>
            </div>
        );
    }
}

export default TopBar;
