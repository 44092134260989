import React, {Component} from 'react';
import Client from '../services/Client';
import  Staff from '../components/staff';
import TopBarInside from "../components/topbar-inside";
import {Input, Table, Button, Dropdown, Modal, Icon, Form, Checkbox, Label} from 'semantic-ui-react';

import '../style/vendors.css';

class Staffs extends Component{
    constructor(){
        super();

        this.state = {
            data_rows: [],
            new_data_row: {},
            tableFields: [
                {"value": "emp_id", "text": "Emp ID", "type": "field"},
                {"value": "name", "text": "Name", "type": "field"},
                {"value": "title", "text": "Title", "type": "field"},
                {"value": "department", "text": "Department", "type": "select"},
                {"value": "blood_group", "text": "Blood Group", "type": "field"},
                {"value": "dob", "text": "DOB", "type": "field"},
                {"value": "email", "text": "Email", "type": "field"},
                {"value": "phone", "text": "Phone", "type": "field"},
                {"value": "post", "text": "Post", "type": "field"},
                {"value": "address", "text": "Address", "type": "field"},
                {"value": "salary", "text": "Salary", "type": "field"},
                {"value": "active", "text": "Active", "type": "field"},                
                {"value": "city", "text": "City", "type": "field"},
                {"value": "role", "text": "Role", "type": "field"},
            ],
            tableButtons: [
            ],
            data_rows_offset: 0,
            data_rows_limit: 10,
            data_rows_size: 0,
            data_rows_sort: 'name',
            data_rows_sort_order: 'asc',
            data_rows_search_field: 'name',
            data_rows_search: '',
            data_rows_filter: {
            },
            options_department: []
        }
    }

    componentDidMount() {
        var options_department = [];
        this.getDataRows(this.state);
        Client.getDepartments().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_department.push({
					text: data_row.department,
					value: data_row.id
				})
			})
			this.setState({
				options_department: options_department
			})
		})
    }

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

    changeStateVariable(e, data){
        // alert(data.name + data.value);
        var state = {...this.state};

        //SELECTIVE OPERATIONS
        if(data.name != "data_rows_offset")
            state['data_rows_offset'] = 0;
        if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
            state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

        //SET VALUE AND MOVE AHEAD
        state[data.name] = data.value;
        this.setState(state)
        this.getDataRows(state);
    }

    changeStateVariableNewDataRow(e, data){
        var new_data_row = {...this.state.new_data_row}
        if (data.name=="city" ) {
            if ( data.value[data.value.length -1]=='All') {
                data.value = ['All']
            } else if(data.value.length > 0 && data.value[data.value.length -1]!='All'){
                data.value = data.value.filter(elem=>{
                    return elem != 'All'
                })
            }
        } 
        new_data_row[data.name] = data.value;
        this.setState({
            new_data_row: new_data_row
        })
    }

    showDataRowDetails(index){
        let new_data_row = {...this.state.data_rows[index]}
        try {
            new_data_row.city = JSON.parse(new_data_row.city)
        } catch (error) {
            console.log('cant parse');
            
            new_data_row.city =[new_data_row.city]
        }
        this.setState({
            showCreateDataModal: true,
            new_data_row: new_data_row,
            editMode: true
        })
    }

    createNewDataRow(){
        this.setState({
            creatingNewDataModal: true
        })
        var state = this.state
        let new_data_row = {...state.new_data_row}
        if (new_data_row.city[0]=='All') {
            new_data_row.city = "All"
        } else {
            new_data_row.city = JSON.stringify(new_data_row.city)
        }
        Client.createStaff(new_data_row, this.state.editMode).then(res=>{
            this.setState({
                showCreateDataModal: false,
                creatingNewDataModal: false,
                editMode: false,
                new_data_row: {}
            })
            this.getDataRows(state);
        })
    }

    getDataRows(state, flagReturn){
        Client.getStaffs(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
            if(flagReturn == 1){
                return res.data_rows
            }
            else{
                this.setState({
                    data_rows: res.data_rows,
                    data_rows_size: res.data_rows_size
                })
            }
        })
    }
    convertSelectToText(key, value){
        console.log(key,value);
        
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
    render(){
        return(
            <div style={{fontSize:'10px'}}>
                <Button onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
                <div style={{float: 'right'}}>
                    <Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
                    <Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
                    <Button icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
                    {'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
                    <Button icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
                </div>
                <Table color="blue" key="blue" collapsing>
                    <Table.Header>
                        <Table.Row>
                        {
                            this.state.tableFields.map((field) => (
                                <Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
                                    {field.text}
                                    {
                                        this.state.data_rows_sort_order == "asc" ?
                                            <Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                        :
                                            <Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                    }
                                </Table.HeaderCell>
                            ))
                        }
                        {
                            this.state.tableButtons.map((field) => (
                                <Table.HeaderCell>
                                    {field.text}
                                </Table.HeaderCell>
                            ))
                        }
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {
                        this.state.data_rows.map((data_row, index) => (
                            <Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
                            {
                                this.state.tableFields.map((field) => (
                                    
                                        field.type == "select"
                                        ?
                                        <Table.Cell>{this.convertSelectToText(field.value,data_row[field.value])}</Table.Cell>
                                        :
                                        <Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>

                                    
                                ))
                            }
                            {
                                this.state.tableButtons.map((field) => (
                                    <Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
                                ))
                            }
                            </Table.Row>
                        ))
                    }
                    </Table.Body>
                </Table>

                <Modal open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {}, editMode: false, creatingNewDataModal: false})}>
                    <Modal.Content>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Input type="text" fluid name="name" value={this.state.new_data_row.name} placeholder="Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input fluid value={this.state.new_data_row.emp_id} name="emp_id" placeholder="Employee Id" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Input type="text" fluid name="email" value={this.state.new_data_row.email} placeholder="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input type="text" fluid name="phone" value={this.state.new_data_row.phone} placeholder="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Dropdown search selection options={this.state.options_department} fluid name="department" value={this.state.new_data_row.department} placeholder="Department" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input type="text" fluid name="role" value={this.state.new_data_row.role} placeholder="Role" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Input name="post" fluid value={this.state.new_data_row.post} placeholder="Post"  onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input name="salary" fluid value={this.state.new_data_row.salary} placeholder="Salary"  onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>                                
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Form.Select 
                                    multiple 
                                    search 
                                    type="text" 
                                    options={[
                                        {text:"All",value:"All"},
                                        ...this.props.cities
                                    ]}
                                    fluid 
                                    name="city" 
                                    value={this.state.new_data_row.city} 
                                    placeholder="City" 
                                    onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input type="text" fluid name="address" value={this.state.new_data_row.address} placeholder="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Input type="date" fluid name="dob" value={this.state.new_data_row.dob} placeholder="D.O.B" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input  fluid name="blood_group" value={this.state.new_data_row.blood_group} placeholder="Blood Group" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Input type="text" fluid name="title" value={this.state.new_data_row.title} placeholder="Title" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                            <Form.Field>
                                <Input type="password" fluid name="password" value={this.state.new_data_row.password} placeholder="Password (IF CHANGING)" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <Button onClick={() => this.createNewDataRow()} primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'EDIT' : 'CREATE'}</Button>
                            </Form.Field>
                            <Form.Field style={{textAlign:'right'}}>
                                <Checkbox label="Active" toggle fluid name="active" value={this.state.new_data_row.active == 1} 
                                    onClick={(e, data) => {
                                        let new_data_row = this.state.new_data_row
                                        new_data_row['active'] = this.state.new_data_row == 1 ? 0 : 1
                                        this.setState({new_data_row})
                                    }}
                                    />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}
export default Staffs;
