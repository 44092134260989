import React, {Component} from 'react';
import '../style/order-slip.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Modal, Icon, Form} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class OrderSlipPurchase extends Component{

	constructor(props){
		super(props);

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;
		// document.write(today);

		this.state = {
			today: today,
			data_rows: [],
			new_data_row: {slip_no: props.parent_data_row != undefined ? props.parent_data_row.slip_no : undefined, date: today},
			tableFields: [
				{"value": "slip_no", "text": "Slip No.", "type": "field"},
				{"value": "date", "text": "Purchase Date", "type": "field"},
				{"value": "vendor", "text": "Vendor", "type": "select"},
				{"value": "invoice", "text": "Invoice No.", "type": "field"},
				{"value": "amount", "text": "Invoice Amount", "type": "field"},
				{"value": "payment", "text": "Payment Status", "type": "select"},
				{"value": "remarks", "text": "Remarks", "type": "field"},
			],
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'desc',
			data_rows_search_field: 'vendor',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: props.filter || {},
			options_vendor: [],
			options_payment: [{"text": "DUE", "value": "due"}, {"text": "PAID", "value": "paid"}],
			viewMode: props.viewMode || null,
			parent_data_row: props.parent_data_row != undefined ? props.parent_data_row : {},
		}
	}

	componentDidMount() {
		var options_vendor = [];
		var optionsCustomers = [];
		var optionsDepartments = [];
		this.getDataRows(this.state);
		Client.getVendors().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_vendor.push({
					text: data_row.ledger_name,
					value: data_row.id
				})
			})
			this.setState({
				options_vendor: options_vendor
			})
		})
		Client.getCustomers().then(res=>{
			res.data_rows.forEach(function(data_row){
				optionsCustomers.push({
					text: data_row.ledger_name,
					value: data_row.id
				})
			})
			this.setState({
				optionsCustomers: optionsCustomers
			})
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			data_rows_filter: nextProps.filter || {},
			viewMode: nextProps.viewMode || null,
			parent_data_row: nextProps.parent_data_row || {},
			new_data_row: {slip_no: nextProps.parent_data_row != undefined ? nextProps.parent_data_row.slip_no : undefined, date: this.state.today},
		})
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};

		//SELECTIVE OPERATIONS
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		state[data.name] = data.value;
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value + ' : N/A';
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	toggleCreateDataModal(){
		this.setState({
			showCreateDataModal:false,
			new_data_row: {slip_no: this.state.parent_data_row != undefined ? this.state.parent_data_row.slip_no : undefined, date: this.state.today},
			editMode: false,
			creatingNewDataModal: false
		})
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createOrderSlipPurchase(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {slip_no: this.state.parent_data_row != undefined ? this.state.parent_data_row.slip_no : undefined, date: this.state.today},
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getOrderSlipPurchases(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}

	render(){
		return(
			<div style={{fontSize:'12px'}}>
				<div style={{display: (this.state.viewMode == "dialogForm" ? 'none' : 'block')}}>
					<Button size='small' onClick={() => this.setState({showCreateDataModal:true})} primary>ADD PURCHASE</Button>
					<div style={{float: 'right'}}>
						<Button icon="repeat" color="red" size="small" onClick={() => this.componentDidMount()}/>
						<Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
						{
							this.state.data_rows_search_type == 'field'
							?
							<Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
							:
							<Dropdown selection search name="data_rows_search" options={this.state['options_' + this.state.data_rows_search_field]} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						}
						<Button size='small' icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
						{'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
						<Button size='small' icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					</div>
					<Table color="blue" key="blue" collapsing>
						<Table.Header>
							<Table.Row>
							{
								this.state.tableFields.map((field) => (
									<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
										{field.text}
										{
											this.state.data_rows_sort_order == "asc" ?
												<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
											:
												<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										}
									</Table.HeaderCell>
								))
							}
							{
								this.state.tableButtons.map((field) => (
									<Table.HeaderCell>
										{field.text}
									</Table.HeaderCell>
								))
							}
							</Table.Row>
						</Table.Header>

						<Table.Body>
						{
							this.state.data_rows.map((data_row, index) => (
								<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
								{
									this.state.tableFields.map((field) => (
										<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
									))
								}
								{
									this.state.tableButtons.map((field) => (
										<Table.Cell>{field.type == "button" ? <Button size='small' style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
									))
								}
								</Table.Row>
							))
						}
						</Table.Body>
					</Table>
				</div>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.toggleCreateDataModal()}>
					<Modal.Content>
						<Form size="mini" onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="slip_no" value={this.state.new_data_row.slip_no} label="Slip No." onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="date" required name="date" value={this.state.new_data_row.date} label="Purchase Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select selection required search options={this.state.options_vendor} name="vendor" value={this.state.new_data_row.vendor} label="Vendor" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" required name="invoice" value={this.state.new_data_row.invoice} label="Invoice No." onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="number" required name="amount" value={this.state.new_data_row.amount} label="Invoice Amount" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Select type="text" required selection search options={this.state.options_payment} name="payment" value={this.state.new_data_row.payment} label="Payment Status" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Input type="text" name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default OrderSlipPurchase;
