import React, {Component} from 'react';
// import $ from 'jquery';
import Client from '../services/Client';
import  Staff from '../components/staff';
import TopBarInside from "../components/topbar-inside";
import { Dropdown, Input, Modal, Button, Header, Icon } from 'semantic-ui-react';
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
const { compose, withProps, withStateHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow,
} = require("react-google-maps");

class TrackRider extends Component{

    state = {
        fetching: true,
        fetchLocation: false,
        city: localStorage.getItem('city'),
        map: "",
        date: '',
        modalData: {},
        startAt: '',
        endAt: '',
        rider: '',
        staffs: [],
        riders: [],
        alerts: [],
        currentData: {},
        markersMap: {},
        polysMap: [],
        colors: ["#FF0000", "#00FF00", "#0000FF", "#FFFFFF", "#000000", "#FFFF00", "#FF00FF", "#00FFFF"],
        countPoly: 0,
        markersLoc: [],
        markersShowInfoLoc: [],
        linesPath: [],
        lastLocation: { lat: 24.758445, lng: 92.7877878 },
        cities: JSON.parse(localStorage.getItem('cities'))
    };

    handleShowModal = (data) => {
        this.setState({ modalOpen: true, modalData: data })}

    handleCloseModal = () => this.setState({ modalOpen: false })

    handleCityChange(e, data){
        localStorage.setItem('city',data.value);
        this.setState({
            city: data.value,
            fetching: true,
        })
        this.getStaffs(data.value);
    }

    handleStaffChange(e, data){
        // alert(data.value);
        this.setState({
            rider: data.value,
            fetching: true,
            fetchLocation: true,
        })
        // this.getRiderLocTrack(data.value, this.state.date, this.state.startAt, this.state.endAt);
        // localStorage.setItem('city',data.value);
        // this.setState({
        //     city: data.value
        // })
        // this.getStaffs(data.value);
    }

    handleInputChange = (e) => {
        this.setState({
            date: e.target.name == 'date' ? e.target.value : this.state.date,
            startAt: e.target.name == 'startAt' ? e.target.value : this.state.startAt,
            endAt: e.target.name == 'endAt' ? e.target.value : this.state.endAt,
            fetchLocation: true,
            fetching: true,
        })
        // this.getRiderLocTrack(this.state.rider, e.target.name == 'date' ? e.target.value : this.state.date,
        // e.target.name == 'startAt' ? e.target.value : this.state.startAt,  e.target.name == 'endAt' ? e.target.value : this.state.endAt)
    };

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        var today = new Date();
        console.log(today);
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        var hh = today.getHours();
        var mi = today.getMinutes();
        if(dd<10){
            dd='0'+dd;
        }
        if(mm<10){
            mm='0'+mm;
        }
        if(hh<10){
            hh='0'+hh;
        }
        if(mi<10){
            mi='0'+mi;
        }
        var date = yyyy+'-'+mm+'-'+dd;
        console.log(date);
        var endAt = hh + ':' + mi;
        this.setState({
            date: date,
            startAt: "00:00",
            endAt: endAt
        })
        this.getStaffs(this.state.city);
    }

    getRiderLocTrack(id, date, startAt, endAt){
        Client.getRiderLocTrack(id, date, startAt, endAt).then((res)=>{
            console.log(res);
            this.showLocationData(id, res);
        });
    }

    getRiderSOS(id, date, startAt, endAt){
        Client.getRiderSOS(id, date, startAt, endAt).then((res)=>{
            console.log(res);
            this.showSOS(res);
        });
    }

    getStaffs(city){
        Client.getStaffs(city).then((res)=>{
            var riders = [];
            res.staffs.forEach(function (staff){
                var rider = {};
                rider.text = staff.name + " : " + staff.id;
                rider.value = staff.id;
                riders.push(rider);
            });
            this.setState({
                staffs: res.staffs,
                riders:riders,
                rider:res.staffs[0].id,
                fetchLocation: true
            })
            // this.getRiderLocTrack(res.staffs[0].id, this.state.date, this.state.startAt, this.state.endAt);
        })
    }

    showSOS(res){
        var alerts = [];

        res.sos.forEach(function(value, index){
            var alert = {};
            alert.id = value.id;
            alert.time = value.time.date;
            alert.priority = "HIGH";
            alert.alert = "SOS";
            alert.location = value.lat + "," + value.lng;
            alert.speed = value.speed;
            alert.files = value.files;
            alert.weather = value.weather;
            alerts.push(alert);
        });
        //
        // locations.forEach(function(value, index){
        //     // console.log(value.time.split(" ")[1].split(":")[0] + "::" + (addedInfo.time.split(" ")[1].split(":")[0]));
        //     // if(60 * (Math.abs(value.time.split(" ")[1].split(":")[0] - addedInfo.time.split(" ")[1].split(":")[0])) +
        //     //     Math.abs(value.time.split(":")[1] - addedInfo.time.split(":")[1]) >= 3){
        //     //     addDataMarker(value, null, "D");
        //     //     addedInfo = value;
        //     // }
        //     // if(value.speed != null){
        //     //     this.addDataMarker(value, null, "D");
        //     //     addedInfo = value;
        //     //     currentData = value;
        //     // }
        // });
        this.setState({
            alerts:alerts,
            fetching: false,
            fetchLocation: false,
        })
    }

    showLocationData(id, res){

        var count = 0;
        var locations = [];
        var lastLocation = {};
        var path = [];
        var markersLoc = [];
        var currentData = {};
        var markersShowInfoLoc = [];
        var linesPath = [];
        var shownLoc = res.locations[0];
        var addedLoc = res.locations[0];
        var addedInfo = res.locations[0];
        var countPoly = this.state.countPoly;
        var polysMap = this.state.polysMap;

        res.locations.forEach(function(value, index){
            if(index == 0){
                // this.addMarker(value, "SS", "SS");
                var markerLoc = {};
                markerLoc.value = value;
                markerLoc.title = "SS";
                markerLoc.label = "SS";
                markersLoc.push(markerLoc);
                shownLoc = value;
                locations.push(value);
                lastLocation = value;
                currentData = value;
            }
            if(Math.abs(value.lat - shownLoc.lat) >= 0.0003 ||
                     Math.abs(value.lng - shownLoc.lng) >= 0.0003){
                shownLoc = value;
                locations.push(value);
                lastLocation = value;
                currentData = value;
                // addMarker(value, null, ++count+"");
                // console.log(count + JSON.stringify(value));
            }
        });

        locations.forEach(function(value, index){
            // console.log(value.time.split(" ")[1].split(":")[0] + "::" + (addedInfo.time.split(" ")[1].split(":")[0]));
            // if(60 * (Math.abs(value.time.split(" ")[1].split(":")[0] - addedInfo.time.split(" ")[1].split(":")[0])) +
            //     Math.abs(value.time.split(":")[1] - addedInfo.time.split(":")[1]) >= 3){
            //     addDataMarker(value, null, "D");
            //     addedInfo = value;
            // }
            // if(value.speed != null){
            //     this.addDataMarker(value, null, "D");
            //     addedInfo = value;
            //     currentData = value;
            // }

            if(Math.abs(value.lat - addedLoc.lat) >= 0.005 ||
                     Math.abs(value.lng - addedLoc.lng) >= 0.005){
                // console.log(value);
                // console.log(path);
                if(path.length > 1){
                    var markerLoc = {};
                    markerLoc.value = addedLoc;
                    markerLoc.title = null;
                    markerLoc.label = "E";
                    markersLoc.push(markerLoc);
                    // this.addMarker(addedLoc, null, "E");

                    var markerLoc = {};
                    markerLoc.value = value;
                    markerLoc.title = null;
                    markerLoc.label = "S";
                    markersLoc.push(markerLoc);
                    // this.addMarker(value, null, "S");

                    linesPath = [...linesPath, path];

                    // var flightPath = new google.maps.Polyline({
                    //     path: path,
                    //     geodesic: true,
                    //     strokeColor: "hsl("+ ++countPoly*50 % 360 +", 100%, 50%)",
                    //     strokeOpacity: 1.0,
                    //     strokeWeight: 6
                    // });
                    // flightPath.setMap(this.state.map);
                    // polysMap.push(flightPath);
                }
                else{
                    var markerLoc = {};
                    markerLoc.value = addedLoc;
                    markerLoc.title = null;
                    markerLoc.label = "X";
                    markersLoc.push(markerLoc);
                    // this.addMarker(addedLoc, null, "E");

                    var markerLoc = {};
                    markerLoc.value = value;
                    markerLoc.title = null;
                    markerLoc.label = "S";
                    markersLoc.push(markerLoc);
                    // this.addMarker(value, null, "S");

                    // this.addMarker(addedLoc, null, "X");
                    // this.addMarker(value, null, "S");
                }
                path = [];
            }
            addedLoc = value;
            path.push(addedLoc);
            //
            // if(index == locations.length - 1){
            //     var flightPath = new google.maps.Polyline({
            //         path: path,
            //         geodesic: true,
            //         strokeColor: "hsl("+ ++countPoly*50 % 360 +", 100%, 50%)",
            //         strokeOpacity: 1.0,
            //         strokeWeight: 6
            //     });
            //     flightPath.setMap(this.state.map);
            //     polysMap.push(flightPath);
            //
            //     this.addMarker(addedLoc, null, "E");
            // }
        });

        markersShowInfoLoc = Array(markersLoc.length).fill(0);
        this.setState({
            markersLoc: markersLoc,
            linesPath: linesPath,
            lastLocation: lastLocation,
            markersShowInfoLoc: markersShowInfoLoc,
            currentData: currentData,
            fetchLocation: false,
        })

        this.getRiderSOS(id, this.state.date, this.state.startAt, this.state.endAt);
    }

    onToggleOpen(index){
        var x = this.state.markersShowInfoLoc;
        x = Array(this.state.markersLoc.length).fill(0);
        x[index] = !x[index];
        this.setState({
            markersShowInfoLoc:x
        })
    }

    render(){
        if(this.state.fetchLocation)
            this.getRiderLocTrack(this.state.rider, this.state.date, this.state.startAt, this.state.endAt);

        const MapWithAMakredInfoWindow = compose(
            withStateHandlers(() => ({
                isOpen: false,
                }),
            ),
            withScriptjs,
            withGoogleMap
        )(props =>
            <GoogleMap
                defaultZoom={14}
                defaultCenter={this.state.lastLocation}>
                {
                    this.state.markersLoc.map((markerLoc, index) => (
                    <Marker
                        position={markerLoc.value}
                        label={markerLoc.label}
                        onClick={() => this.onToggleOpen(index)}>
                        {
                            this.state.markersShowInfoLoc[index]
                            ?
                            <InfoBox
                                onCloseClick={() => this.onToggleOpen(index)}
                                options={{ closeBoxURL: ``, enableEventPropagation: true }}>
                                <div style={{ backgroundColor: `yellow`, opacity: 0.75, padding: `12px` }}>
                                    <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
                                        {JSON.stringify(markerLoc.value) + "\n" + markerLoc.label}
                                    </div>
                                </div>
                            </InfoBox>
                            :
                            null
                        }
                    </Marker>
                    ))
                }
                {
                    this.state.linesPath.map((linePath, index) => (
                    <Polyline
                        path={linePath}
                        strokeWeight={6}
                        strokeColor={"hsl("+ index*50 % 360 +", 100%, 50%)"}>
                    </Polyline>
                    ))
                }
            </GoogleMap>
        );
        return(
            <div className="ui grid">
                <div className="twelve wide column">
                    <Dropdown
                        placeholder='Select City' search selection
                        options={this.state.cities}
                        onChange={this.handleCityChange.bind(this)}
                        style={{borderRadius:'0px', height:'40px'}}
                        defaultValue={localStorage.getItem('city')}>
                    </Dropdown>
                    <Dropdown
                        placeholder='Select Rider' search selection
                        options={this.state.riders}
                        onChange={this.handleStaffChange.bind(this)}
                        value={this.state.rider}
                        style={{borderRadius:'0px', height:'40px'}}>
                    </Dropdown>
                    Date: <input type="date"
                        name="date"
                        style={{borderRadius:'0px', height:'40px'}}
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.date}>
                    </input>
                    Start At: <input type="time"
                        name="startAt"
                        style={{borderRadius:'0px', height:'40px'}}
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.startAt}>
                    </input>
                    End At: <input type="time"
                        name="endAt"
                        style={{borderRadius:'0px', height:'40px'}}
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.endAt}>
                    </input>
                    <br/>
                    <center><p>CURRENT</p></center>
                    {
                        <div style={{backgroundColor:"#FF0000", border:"2px solid #000", margin:"5px"}}>
                            <p>{this.state.currentData.time}</p>
                            <p>{this.state.currentData.lat}</p>
                            <p>{this.state.currentData.speed}</p>
                            <p>{this.state.currentData.weather}</p>
                        </div>
                    }
                    {
                        !this.state.fetching
                        ?
                        <MapWithAMakredInfoWindow
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCeqTAw-szKse5PB4_cv4YYKM2xTTA-rIA&v=3.exp&libraries=geometry,drawing,places"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `400px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                        />
                        :
                        null
                    }
                </div>
                <div className="four wide column" style={{height:"600px", overflow:"scroll"}}>
                    <center><p>ALERTS</p></center>
                    {
                        this.state.alerts.map((alert, index) => (
                            <div style={{backgroundColor:"#FF0000", border:"2px solid #000", margin:"5px"}} onClick={() => this.handleShowModal(alert)}>
                                <p>{alert.time}</p>
                                <p>{alert.priority}</p>
                                <p>{alert.alert}</p>
                                <p>{alert.location}</p>
                                <p>{alert.speed}</p>
                                <p>{alert.weather}</p>
                            </div>
                        ))
                    }
                </div>

                <Modal
                    open={this.state.modalOpen}
                    basic
                    size='small'>
                    <Header icon='browser' content='ALERT DATA' />
                    <Modal.Content>
                        <div style={{backgroundColor:"#FFF", color:"#000", padding:"10px", border:"2px solid #000", margin:"5px"}}>
                            <p>{this.state.modalData.time}</p>
                            <p>{this.state.modalData.priority}</p>
                            <p>{this.state.modalData.alert}</p>
                            <p>{this.state.modalData.location}</p>
                            <p>{this.state.modalData.speed}</p>
                            <p>{this.state.modalData.weather}</p>
                            {
                                [...Array(this.state.modalData.files)].map((x, i) => (
                                    <div>
                                        <a href = {"https://s3.ap-south-1.amazonaws.com/doorhopperimg/rider_track_sos_files/" + x}>
                                                `https://s3.ap-south-1.amazonaws.com/doorhopperimg/rider_track_sos_files/{x}`
                                        </a>
                                    </div>
                                ))
                            }
                        </div>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color='green' onClick={this.handleCloseModal} inverted>
                        <Icon name='checkmark' /> DONE
                      </Button>
                    </Modal.Actions>
                  </Modal>

            </div>
        )
    }
}
export default TrackRider;
