import React,{Component} from 'react';
import Client from '../services/Client';
import {Redirect} from 'react-router-dom';
import { Form } from 'semantic-ui-react'
import '../style/login.css'

import { initializeFirebase } from '../services/push-notifications';
import { askForPermissioToReceiveNotifications } from '../services/push-notifications';

class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            unExpectedErrors:'',
            redirect:false,
            fetching:false,
            fields:{
                phone:'',
                key:''
            },
            fieldErrors:{},
        };
    }

    onInputChange(e){
        const fields=this.state.fields;
        fields[e.target.name]=e.target.value;
        this.setState({
            fields
        })
    }

    onSubmitClick(e){
        e.preventDefault();
        this.setState({
        })
        const user=this.state.fields;
        const fieldErrors=this.validate(user);
        this.setState({
            fieldErrors
        });

        if((Object.keys(fieldErrors)).length){
            window.setTimeout(() => {
                this.setState({
                    fieldErrors: {}
                });
            }, 2000);
            return;
        }
        this.setState({
            fetching:true
        })
        Client.login(user.phone,user.key)
        .then(
            (response)=>{
                if(response.responseError==='invalidCredentials'){
                    this.setState({
                        fetching:false,
                        fieldErrors:{
                            ...this.state.fieldErrors,
                            error:'Invalid Phone or Password'
                        }
                    })
                }
                else {
                    Client.setToken(response.token);
                    localStorage.setItem('user',user.phone);
                    console.log(response);

                    localStorage.setItem('userData',JSON.stringify(response))
                    this.setState({
                        redirect:true
                    })

                    // initializeFirebase();
                    // askForPermissioToReceiveNotifications(response);
                }
            }
        )
        .catch((err)=>{
            console.log(err);

            this.setState({
                fetching:false,
                unExpectedErrors:'Sorry,We could not log you in.Something Wrong Happened'
            })

        })
    }
    validate=(user)=>{
        const errors={};
        if(!user.phone)errors.phone='Phone is required';
        if(!user.key)errors.key='Password is required';
        return errors;
    };
    render(){
        const errors=Object.keys(this.state.fieldErrors).map((key)=>(
            <li  className="ui red  message" key={key} >
                {this.state.fieldErrors[key]}
            </li>
        ))
        if(this.state.redirect){
            return(
                <Redirect to='/home' />
            )
        }else {
            return (

                <div id="loginform" className="ui middle aligned center aligned grid" style={{height: 100 + "%"}}>
                    <div className="column" style={{maxWidth: 450 + "px"}}>
                        <h2 className="ui blue image header">
                            <div className="content">
                                Log-in to your account
                            </div>
                        </h2>
                        <Form className="ui large form" loading={this.state.fetching}>
                            <div className="ui stacked segment">
                                <div className="field">
                                    <div className="ui left icon  input">
                                        <i className="mobile icon"></i>
                                        <input type="text" name="phone" placeholder="Phone*"
                                               value={this.state.fields.phone} required={true}
                                               onChange={this.onInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="ui left icon input">
                                        <i className="key icon"></i>
                                        <input type="password" name="key" value={this.state.fields.key}
                                               placeholder="Password*
                                               " required={true}
                                               onChange={this.onInputChange.bind(this)}/>
                                    </div>
                                </div>
                                <button type="submit" className="ui fluid large blue submit button"
                                     onClick={this.onSubmitClick.bind(this)}>Login
                                </button>
                            </div>

                            <div style={{color: "red"}}>
                                <ul>
                                    {
                                        errors
                                    }
                                </ul>
                            </div>
                            <div className="ui info">
                                {
                                    this.state.unExpectedErrors
                                }
                            </div>
                        </Form>

                    </div>
                </div>

            )
        }
    }
}

export default Login;
