import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import CreateAccountingCustomer from './create-accounting-customer';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class Departments extends Component{

	constructor(){
		super();

		this.state = {
			data_rows: [],
			new_data_row: {},
			tableFields: [
				{"value": "department", "text": "Department Name", "type": "field"},
				{"value": "parent_department", "text": "Parent Department", "type": "field"},
				{"value": "head", "text": "Department Head", "type": "field"},
			],
			tableButtons: [
				{"value": "", "text": "EMPLOYEES", "type": "button"}
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'department',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'department',
			data_rows_search: '',
			data_rows_filter: {
			},
		}
	}

	componentDidMount() {
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};

		//SELECTIVE OPERATIONS
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		state[data.name] = data.value;
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createDepartment(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state, flagReturn){
		Client.getDepartments(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
			if(flagReturn == 1){
				return res.data_rows
			}
			else{
				this.setState({
					data_rows: res.data_rows,
					data_rows_size: res.data_rows_size
				})
			}
		})
	}

	render(){
		return(
			<div style={{fontSize:'10px'}}>
				<Button onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
				<div style={{float: 'right'}}>
					<Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					<Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					<Button icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					{'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
					<Button icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Table color="blue" key="blue" collapsing>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						{
							this.state.tableButtons.map((field) => (
								<Table.HeaderCell>
									{field.text}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
								))
							}
							{
								this.state.tableButtons.map((field) => (
									<Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content>
						<Form size="mini" onSubmit={() => this.createNewDataRow()}>
							<Form.Input type="text" required fluid name="department" value={this.state.new_data_row.department} label="Department" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Input type="text" required fluid name="parent_department" value={this.state.new_data_row.parent_department} label="Parent Department" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Input type="text" fluid name="head" value={this.state.new_data_row.head} label="Department Head" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'EDIT' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default Departments;
