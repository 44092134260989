import React,{Component} from 'react';
import AlertContainer from 'react-alert';
import { Button, Modal } from 'semantic-ui-react';
import Client from "../services/Client";
class AddShopForm extends Component{
    constructor(props){
        super(props);
        this.onInputChange=this.onInputChange.bind(this);
        this.state={
            shop:{
                type:'Retailer',
                shopName:"",
                contactPrimary:"",
                address:"",
                city:"",
                zipcode:"",
                email:"",
                openAt:"",
                closeAt:""
            }
        }
    }
    onInputChange(e){
        const shop=this.state.shop;
        shop[e.target.name]=e.target.value;
        this.setState({
            shop:shop
        })
    }
    alertOptions = {
        offset: 14,
        position: 'top right',
        theme: 'dark',
        time: 3000,
        transition: 'scale'
    };
    showMessage=(message,type)=>{
        this.msg.show(message, {
            time: 3000,
            type: type,
        })
    }
    onSubmit(e){
        e.preventDefault();
        const store=this.state.shop;
        for (let s of Object.keys(store)){
            if(!store[s]){
                this.showMessage("Required Fields Are Missing","error");
                return;
            }
        }
        Client.addStore(store).then(
            res=>{
                if(res.responseResult==="success"){
                    const shop= {
                        ...this.state.shop,
                        shopId: res.storeId
                    };
                    let shopList=JSON.parse(localStorage.getItem('stores'));
                    shopList=[
                        ...shopList,
                        shop
                    ]
                    localStorage.setItem("stores",JSON.stringify(shopList));
                    this.showMessage("Shop Has Been Added Successfully",'success');
                }
                else {
                    this.showMessage("Something Wrong Happened",'error');
                }

            },
        ).catch(err=>{
            this.showMessage("Something Wrong Happened,We Couldn't Add The Store.Check Your Internet Connection",'error');
        })

    }
    render() {
        return(
            <Modal trigger={<Button className="ui right floated green basic button">Add Shop</Button>}>
                <Modal.Header as="h1">Add Shop</Modal.Header>
                <AlertContainer ref={a => this.msg = a} {...this.alertOptions} />
                <Modal.Content>
                    <Modal.Description>
                        <form className="ui form">
                            <div className="three fields">
                                <div className="field">
                                    <label>Shop Name*</label>
                                    <input onChange={this.onInputChange} type="text" name="shopName" placeholder="Shop Name"/>
                                </div>
                                <div className="field">
                                    <label>Bussiness Type*</label>
                                    <select onChange={this.onInputChange} name="type" value={this.state.shop.type}>
                                        <option value={"Retailer"}>Retailer</option>
                                        <option value={"Service"}>Service</option>
                                        <option value={"Retailer Cum Service"}>Retailer Cum Service</option>
                                    </select>
                                </div>
                                <div className="field">
                                    <label>Primary Contact*</label>
                                    <input onChange={this.onInputChange}  type="text" name="contactPrimary" placeholder="Primary Contact"/>
                                </div>
                            </div>
                            <div className="field">
                                <label>Address*</label>
                                <textarea onChange={this.onInputChange}  rows={1} type="text" name="address" placeholder="Address"/>
                            </div>
                            <div className="two fields">
                                <div className="field">
                                    <label>Opens At*</label>
                                    <input onChange={this.onInputChange}  type="text" name="openAt" placeholder="Opens At"/>
                                </div>
                                <div className="field">
                                    <label>Closes At*</label>
                                    <input onChange={this.onInputChange}  type="text" name="closeAt" placeholder="Closes At"/>
                                </div>
                            </div>

                            <div className="three fields">
                                <div className="field">
                                    <label>City*</label>
                                    <input onChange={this.onInputChange}  type="text" name="city" placeholder="City"/>
                                </div>
                                <div className="field">
                                    <label>Zipcode*</label>
                                    <input onChange={this.onInputChange}  type="text" name="zipcode" placeholder="Zip Code"/>
                                </div>
                                <div className="field">
                                    <label>Email*</label>
                                    <input onChange={this.onInputChange}   type="email" name="email" placeholder="Email"/>
                                </div>
                            </div>
                            <button   className="ui green button" type="submit" onClick={this.onSubmit.bind(this)}>Submit</button>
                        </form>

                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}
export  default AddShopForm;