import React from 'react';
import moment from 'moment';
import '../assets/calendar.css';
import { Placeholder,Dimmer, Dropdown, Form, Label , Checkbox, Button,Loader } from 'semantic-ui-react'

export default class Daydetails extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            new_data_row:[],
            options_leave:[
                {value:'SL',text:'Sick Leave'},
                {value:'CL',text:'Casual Leave'},
                {value:'HD',text:'Half Day'},
                {value:'HO',text:'Holiday'},
                {value:'WH',text:'Work From Home'},
                {value:'UL',text:'Unpaid Leave'},
            ]
        }
    }
    componentDidMount(){
    }

    render(){
        // let absentClass = (day.time_in == null?" absent ":"")
        // let presentClass = day.time_in != null && day.time_out != null ?" present ":""
        // let justInClass = day.time_in != null && day.time_out == null ?" just-in ":""
        if (this.props.day_details) {
            var details = this.props.day_details
            var status = 'Present'
            var leave_type = details.leave_type || 'Not Declared Yet'
            if (details.time_in == null) {
                status = 'Absent'
            }
            if(details.time_in != null && details.time_out == null){
                status = 'Punched In But Never Punched Out'
            }
            var Authorized = (JSON.parse(localStorage.getItem('userData')).role == "admin" 
                        || 
                        JSON.parse(localStorage.getItem('userData')).role == "city_head" 
                        || 
                        JSON.parse(localStorage.getItem('userData')).role == "hr")
            var showLeaveType = (status == 'Absent' || status == 'Punched In But Never Punched Out')
        }
        var today =new Date()
        var monthSeparator = (today.getMonth() + 1) >= 10 ? '-' :'-0'
        var dateSeparator = today.getDate() >= 10 ? '-' :'-0'
        let formatted_date = today.getFullYear()+ monthSeparator + (today.getMonth() + 1) + dateSeparator + today.getDate() 
        console.log(formatted_date);
        
        return(
            <>
            {
                this.props.day_details?
                <>
                <h1>Day Detail</h1>
                <h4>DATE : {details.date}</h4>
                <div>
                    <span style={{marginRight:'5px'}}>STATUS : {status}</span>  
                    {  
                        details.date == formatted_date
                        ?
                            details.time_in == null
                            ?
                            <Button color="blue" onClick={()=>{this.props.punchIn(details.date)}}>Punch In</Button>
                            :
                                details.time_out == null
                                ?
                                <Button color="blue" onClick={()=>{this.props.punchOut(details.date)}}>Punch Out</Button>
                                :
                                null
                        :
                        null
                        
                    }
                    <br></br>
                    Time In : {details.time_in || 'N/A'} 
                    <br></br>
                    Time Out : {details.time_out || 'N/A'} 
                    <br></br>
                    {
                        showLeaveType
                        ?
                        <div>
                            Leave Type : {leave_type}
                        </div>
                        :null
                    }
                    </div>
                </>
                :
                null
            }
            {
                details != undefined && details.leave_type == null && Authorized && showLeaveType?
                <div style={{display:'flex', marginTop:'5px'}}>
                    <Dropdown 
                        search
                        selection
                        mini
                        name="mark" 
                        placeholder="Mark Leave Type"
                        options={this.state.options_leave}
                        onChange={(e,data)=>{this.setState({mark:data.value})}} />
                    {
                        this.state.mark
                        ?
                        <Button mini icon="tick" color="blue" onClick={()=>{
                            this.props.mark(this.state.mark)
                            this.setState({mark:false})
                        }}>Mark</Button>
                        :
                        null
                   }

                </div>
                :
                null
            }
            
            </>
        )
    }
}