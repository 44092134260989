import React, { Component } from 'react';
import Client from '../services/Client';
import "../style/swap.css"
import { Table,Dropdown, Input,Button,Icon, Grid ,Segment ,Loader,Image,Dimmer,Select, Form } from 'semantic-ui-react'
class Swap extends Component {
    constructor(){
        super()
        var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
		    dd = '0'+dd
		}
		if(mm<10) {
		    mm = '0'+mm
		}
		today = yyyy + '-' + mm + '-' + dd;
        this.state = {
            new_data_row: {
				voucher_id: "",
				remarks: 'Hope to serve you again soon',
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A'}],
				taxable_amount: 0,
				round_off: 0,
				total: 0,
				order_id: null,
				date: today
			},
            data_rows: [],
            data_rows_right:[],
			product_details: [
			],
			tableFields: [
				// {"value": "name", "text": "Name", "type": "field"},
				{"value": "name", "text": "Name", "type": "field"},
				{"value": "HSN", "text": "HSN / SAC", "type": "field"},
				{"value": "rate", "text": "Rate", "type": "field"},
				{"value": "MRP", "text": "MRP", "type": "field"},
				{"value": "tax", "text": "Tax", "type": "select"},
				// {"value": "type", "text": "TYPE", "type": "select"},
                // {"value": "category", "text": "CATEGORY", "type": "select"},
                // {value:'percentage',text:"Per%",type:'input'},
                // {"value":"description","text":"Description","type":"field"},
                {"value":"transactions","text":"","type":"button"},
                {"value": "option", "text": "", "type": "button"},
                
            ],
            tableRightFields: [
				// {"value": "name", "text": "Name", "type": "field"},
				{"value": "name", "text": "Name", "type": "field"},
				{"value": "HSN", "text": "HSN / SAC", "type": "field"},
				{"value": "rate", "text": "Rate", "type": "field"},
				{"value": "MRP", "text": "MRP", "type": "field"},
				{"value": "tax", "text": "Tax", "type": "select"},
				// {"value": "type", "text": "TYPE", "type": "select"},
                // {"value": "category", "text": "CATEGORY", "type": "select"},
                // {value:'percentage',text:"Per%",type:'input'},
                {"value":"description","text":"Description","type":"field"},
                {"value":"transactions","text":"","type":"button"},
                {"value": "option", "text": "", "type": "button"},
                
            ],
            tableFields_2: [
                {"value": "ledger_name", "text": "Ledger", "type": "field"},
				{"value": "date", "text": "Date", "type": "field"},
				{"value": "transaction_type", "text": "Type", "type": "field"},
				{"value": "qty", "text": "QT", "type": "field"},
				{"value": "tax", "text": "TX", "type": "field"},
                {"value": "rate", "text": "RT-NT", "type": "field"},
                {"value": "description","text":"Description","type":"field"},
				{"value": "option", "text": "Option", "type": "button"}
			],
            defaultSize: 'tiny',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'name',
            data_rows_sort_order: 'asc',
			data_rows_right_sort: 'name',
            data_rows_right_sort_order: 'asc',
			data_rows_search_field: 'name',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
			},
			options_tax: [
				{text:'0 %', value: 0},
				{text:'5 %', value: 5},
				{text:'12 %', value: 12},
				{text:'18 %', value: 18},
				{text:'28 %', value: 28}
			],
			options_data_rows_limit: [
				{text:'10', value: 10},
				{text:'20', value: 20},
				{text:'30', value: 30},
				{text:'40', value: 40},
				{text:'50', value: 50}
			],
			options_type: [
				{text:'Product', value: 1},
				{text:'Service', value: 2}
			],
			options_category: [
				{text:'Stationery', value: 'Stationery'},
				{text:'Plumbing Service', value: 'Plumbing Service'},
				{text:'Electrician Service', value: 'Electrician Service'},
				{text:'AC Service', value: 'AC Service'},
				{text:'Other Repair Service', value: 'Other Repair Service'},
				{text:'Electrical Parts', value: 'Electrical Parts'},
				{text:'Hardware and Sanitary', value: 'Hardware and Sanitary'},
				{text:'AC Parts', value: 'AC Parts'},
				{text:'Generator Parts', value: 'Generator Parts'},
				{text:'Electronics', value: 'Electronics'},
				{text:'Printing Service', value: 'Printing Service'},
				{text:'Grocery', value: 'Grocery'},
				{text:'Food', value: 'Food'},
				{text:'Others', value: 'Others'}
			],
			options_group: [],
            form_mode: 'inline',
            selectedDataRow:[],
            percentageChange: 0,
            options_transactions:[],
            transaction_types:[],
            taxDetails: [],
			searchCustomers: [],
			customer: {name:''},
			searchProducts: [{productId: 0, name: 'CREATE NEW', text:'CREATE NEW', value:0}],
			createProduct: false,
			options_voucher_id: [],
			options_branch:[],
			options_tax:[
				{text: '0 %', value: '0.00'},
				{text: '5 %', value: '5.00'},
				{text: '12 %', value: '12.00'},
				{text: '18 %', value: '18.00'},
				{text: '28 %', value: '28.00'},
                {text: 'N/A', value: 'N/A'}
            ],
            options_contract:[],
            options_purchase:[],
            source:"inventory"
        }
    }
    componentDidMount() {
        this.getDataRows(this.state);
        let order_id = localStorage.getItem('/accounting/vouchers/invoice/create')
		if(order_id){
			this.handleInputChangeSale(null, {name:'order_id', value:order_id})
			localStorage.removeItem('/accounting/vouchers/invoice/create')
		}
		this.loadCustomers();
		this.loadInvoiceIds();
		if(localStorage.getItem('currentInvoiceSale') && localStorage.getItem('currentInvoiceSaleEdit') == 1){
			var new_data_row = JSON.parse(localStorage.getItem('currentInvoiceSale'));
			new_data_row.products.forEach(function(product,i) {
				product.options_product = [{text: product.name, value: product.product_id}]
				var options_description = [];
				product.options_description.forEach(function(description){
					options_description.push({
						text: description,
						value: description
					})
				})
				product.options_description = options_description
			})
			this.getCustomerBranches(new_data_row.customer);
			this.setState({
				new_data_row: new_data_row
			})

			this.calculateTax()
		}
    }
    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';
		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
    changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}
    shiftDataRow(index){
        let new_data_row_right = {...this.state.data_rows[index]}
        new_data_row_right['rate'] = (new_data_row_right['rate'] *100)/( 100 - this.state.percentageChange)
        new_data_row_right['rate'] = new_data_row_right['rate'].toFixed(2) 
        new_data_row_right['product_id'] = new_data_row_right['id']
        new_data_row_right['product_name']  = new_data_row_right['name']
        // delete new_data_row_right['id']
        // delete new_data_row_right['name']
        let data_rows_right =  [...this.state.data_rows_right,new_data_row_right];
        this.setState({
                data_rows_right:data_rows_right
            },()=>{console.log(this.state.data_rows_right)
        })      
    }
    shiftMultipleDataRows(){
        let say_rigth = []
        say_rigth = this.state.data_rows.filter(elem=>{
            return this.state.selectedDataRow.includes(elem.id)
        })
        let data_rows_right = [...this.state.data_rows_right]
        say_rigth.forEach(elem=>{
            let new_data_row_right = {...elem}
            new_data_row_right['rate'] = (new_data_row_right['rate'] *100)/( 100 - this.state.percentageChange)
            new_data_row_right['rate'] = new_data_row_right['rate'].toFixed(2) 
            new_data_row_right['product_id'] = new_data_row_right['id']
            new_data_row_right['product_name']  = new_data_row_right['name']
            // delete new_data_row_right['id']
            // delete new_data_row_right['name']
            data_rows_right.push(new_data_row_right)
        })
        this.setState({data_rows_right,selectedDataRow:[]})
    }
    removeDataRow(index){
        // alert(index)
        let data_rows_right =  this.state.data_rows_right;
        data_rows_right.splice(index,1)
        this.setState({
            data_rows_right:data_rows_right
        },()=>{console.log(this.state.data_rows_right)})
    }
    setPercentageForProduct(id,percentage){
        if(percentage==null || percentage == '' || percentage == undefined){
            let percentage_for_product = this.state.percentage_for_product
            delete percentage_for_product[id]
            this.setState({percentage_for_product})
        } else {
            this.setState({percentage_for_product:{...this.state.percentage_for_product,[id]:percentage}})
        }
    }
    showDataRowDetails(index){
		if(this.state.form_mode == 'inline'){
			this.setState({
				showCreateDataForm: true,
				new_data_row: this.state.data_rows[index],
				editMode: true
			})
		}
		else if(this.state.form_mode == 'modal'){
			this.setState({
				showCreateDataModal: true,
				new_data_row: this.state.data_rows[index],
				editMode: true
			})
		}
		this.setProductDetails(index)
    }
    createNewDataRow(){
		this.setState({creatingNewDataModal: true})
		var state = this.state
		Client.accountingItemCreate(this.state.new_data_row).then(res=>{
			if(this.props.newProductCreated != undefined)
				this.props.newProductCreated(res.product);
			this.setState({
				showCreateDataModal: false,
				showCreateDataForm: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {}
			})
			this.getDataRows(state);
        })
	}

	showCreateDataRow(){
		if(this.state.form_mode == 'inline'){
		this.setState({
				showCreateDataForm: true
			})
		}
		else if(this.state.form_mode == 'modal'){
			this.setState({
				showCreateDataModal: true
			})
		}
	}

	hideCreateDataRow(){
		this.setState({
			showCreateDataModal:false,
			showCreateDataForm:false,
			new_data_row: {},
			editMode: false,
			creatingNewDataModal: false
		})
	}
	getDataRows(state){
        this.setState({loading_inventory:true})
		Client.getAccountingItems(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter).then(res=>{
				console.log(res.item)
            this.setState({
                source:"inventory",
                product_details:[],
                loading_inventory:false,
                data_rows: res.items,
                selectedDataRow:[],
				data_rows_size: res.items.length
            })
        })
    }
    sort(){
        if (this.state.data_rows_right.length == 0) {
            alert("can't sort empty array")
        }else{
            const sortKey = this.state.data_rows_right_sort
            let data_rows_right = this.state.data_rows_right
            const currentOrder =this.state.data_rows_right_sort_order
            data_rows_right.sort(function(a, b){
                var nameA,nameB
                if (typeof a[sortKey] == 'number') {
                    nameA=a[sortKey]
                    nameB=b[sortKey]
                }else{
                    nameA=a[sortKey].toLowerCase()
                    nameB=b[sortKey].toLowerCase()
                }
                if (nameA < nameB) //sort string ascending
                    return currentOrder=='asc'? -1:1 
                if (nameA > nameB)
                    return currentOrder=='asc'? 1:-1
                return 0 //default return value (no sorting)
            })
            this.setState({data_rows_right})
        }
    }
    sortDataRowsRight(e, data){
        if (this.state.data_rows_right_sort == data) {
            const currentOrder =this.state.data_rows_right_sort_order
            const newOrder= currentOrder == 'asc'?'desc':'asc';
            this.setState({data_rows_right_sort_order:newOrder},()=>{this.sort()})
        }
        else{
            this.setState({data_rows_right_sort: data},
                ()=>{
                    this.sort();
                    console.log("called");
            })
        }
    }
    changePercentage(e,data){
        this.setState({[data.name]:data.value})
    }
    setProductDetails(index){
        this.setState({loading_product_details:index})
		const filters = {product_id : [index]} 
		const offset = 0
		const limit = 50
		Client.getItemTransactions(filters,offset,limit).then(res =>{
            console.log(res)
            let temp_types = res.data_rows.map(elem=>({value:elem.transaction_type,text:elem.transaction_type.toUpperCase()}))
            let options_transactions = [ ... new Set(temp_types)]
			this.setState({
                observedProductId:index,
                product_details:res.data_rows,
                selectedDataRow:[],
                loading_product_details:false,
                options_transactions
			})
		})	
    }
    rateWtCalcutator(rate,tax){
		const taxby100 = tax/100
		const taxFactor = 1 + (tax/100)
		let rateWT = rate * taxFactor
		return Math.round(rateWT,2)
	}

	sortProductDetails(e, data){
        if (this.state.product_details_sort == data) {
            const currentOrder =this.state.product_details_sort_order
            const newOrder= currentOrder == 'asc'?'desc':'asc';
            this.setState({product_details_sort_order:newOrder},()=>{this.sort()})
        }
        else{
            this.setState({product_details_sort: data},
                ()=>{
                    this.sort();
                    console.log("called");
            })
        }
    }
    dataRowSelector(id){
        let selectedDataRow = this.state.selectedDataRow
        if(selectedDataRow.includes(id)){
            selectedDataRow = selectedDataRow.filter(elem=>{return elem != id})
        }else{
            selectedDataRow=[...selectedDataRow,id]
        }
        this.setState({selectedDataRow})
    }
    shiftFromDetails(data_row){
        let observedProduct = this.state.data_rows.filter(elem=>{return elem.id == this.state.observedProductId})
        observedProduct = {...observedProduct[0]}
        let temp_row = {...data_row}
        // console.log(temp_row);
        temp_row['rate'] = (temp_row['rate'] *100)/( 100 - this.state.percentageChange)
        temp_row['rate'] = temp_row['rate'].toFixed(2) 
        temp_row['product_id'] =observedProduct['id'] || temp_row['id']
        temp_row['product_name']  =observedProduct['name'] || temp_row['name']
        temp_row['HSN'] = temp_row['hsn'] || temp_row['HSN']
        // delete temp_row['id']
        // delete temp_row['name']
        temp_row = {...observedProduct,...temp_row}
        delete temp_row['hsn']
        let data_rows_right =  [...this.state.data_rows_right,temp_row];
        this.setState({
            data_rows_right:data_rows_right,
            },()=>{console.log(this.state.data_rows_right)
        })
    }
    //from voucher modules
    createSale(){
		// var new_data_row = {...this.state.new_data_row}
		// var products = new_data_row.products
		// products.forEach(function(product){
		// 	delete product.options_product
		// 	delete product.options_description
		// 	delete product.searchingProducts
		// })
		// Client.accountingInvoiceCreateSale(new_data_row, this.state.customer, localStorage.getItem('currentInvoiceSaleEdit')).then(res=>{
	    //     this.props.history.push('/accounting/vouchers/invoice')
        // })
    }
    loadCustomers(){
		var options_record_ledgers = [];
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_record_ledgers.push({text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''), value: data_row.id})
			})
			this.setState({
				options_customer: options_record_ledgers,
			})
		})
	}

	loadInvoiceIds(){
		Client.getAccountingInvoiceIds().then(res=>{
			var options_voucher_id = [];
			if(localStorage.getItem('currentInvoiceSaleEdit') == 1){
				options_voucher_id.push({
					text: this.state.new_data_row.voucher_id,
					value: this.state.new_data_row.voucher_id
				})
			}
			res.invoiceIds.forEach(function(voucher_id){
				options_voucher_id.push({
					text: voucher_id,
					value: voucher_id
				})
			})
			this.setState({
                options_voucher_id: options_voucher_id,
            })
        })
    }
    getCustomerContract(value){
        let filter = {customer:[value]}
        this.setState({loading_contract:true,source:"inventory",product_details:[],options_contract:[]})
        Client.getAccountingInvoiceContract(null,null,null,null,null,null,null,filter).then(res=>{
            let options_contract = res.data_rows.map(elem=>{
                let processed_products = elem.products.map(product=>{
                    return {...product,ledger_name:elem.customer_details.name,date:elem.date,type:"Contract"}
                })
                return {text:elem.voucher_id,value:processed_products}
            })
            this.setState({loading_contract:false,options_contract})
        })
    }
    getOrderPurchase(value){
        let filter = {order_id:[value]}
        this.setState({loading_purchase:true})
        Client.getAccountingPurchaseFilters(null, null, null,null, 'order_id', 'field', value, {}).then(res=>{
            let options_purchase = res.data_rows.map(elem=>{
                let processed_products = elem.products.map(product=>{
                    return {...product,ledger_name:elem.vendor_details.name,date:elem.date,type:"Purchse"}
                })
                return {text:elem.voucher_id,value:processed_products}
            })
            this.setState({loading_purchase:false,options_purchase})
        })
    }
    handleInputChangeSale(e, data){
		var new_data_row = this.state.new_data_row;
		if (data.name == 'order_id') {
            this.setState({options_purchase:[]})
            if (this.state.source=="purchase") {
                this.setState({product_details:[]})
            }
			this.setState({loading_form_order_id:true})
            this.getOrderPurchase(data.value)
			Client.getOrderSlips('get',null,null,null,null,null,null,null,{slip_no:[data.value]})
			.then(res=>{
				if (res.data_rows_size > 0) {
					new_data_row.cust_branch = res.data_rows[0].branch?res.data_rows[0].branch:''
					new_data_row.customer = res.data_rows[0].customer?res.data_rows[0].customer:''
                    this.getCustomerBranches(new_data_row.customer)
                    this.getCustomerContract(new_data_row.customer)
				} else {
					new_data_row.cust_branch = ''
					new_data_row.customer = ''
				}
				this.setState({new_data_row,loading_form_order_id:false})
			})
		}
		if(data.name == 'customer'){
			delete new_data_row.cust_branch
            // delete new_data_row.order_id
            new_data_row.order_id = ''
            this.getCustomerBranches(data.value);
            this.getCustomerContract(data.value) 
            // this.getCustomerPurchase(data.value)           
		}
		if(data.name == 'cust_branch'){
			data.value = data.value != '' ? data.value : undefined
		}
		var value = data.value || e.target.value
		new_data_row[data.name] = value
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
    }
    calculateTax(){
		setTimeout(() => {
			var new_data_row = this.state.new_data_row;
			var taxDetails = [];
			var taxable_amount = 0;
			var total = 0;
			var taxStateType = this.state.customer.pos == "AS" ? 0 : 1;
			this.state.new_data_row.products.forEach(function(element) {
				var taxDetail = {};
				var found = 0
				if(element.tax != 'N/A'){
					taxDetails.forEach(function(elem) {
						if(elem.taxPc == element.tax){
							found = 1;
							elem.taxStateType = taxStateType;
							elem.taxableAmount = Number(elem.taxableAmount) + Number((element.qty * element.rate).toFixed(2));
							// elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
							elem.taxAmount = (elem.taxableAmount * elem.taxPc / 100).toFixed(2);
							// elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
						}
					})
					if(found == 0){
						taxDetail['taxStateType'] = taxStateType;
						taxDetail['taxPc'] = element.tax;
						taxDetail['taxableAmount'] = (element.qty * element.rate).toFixed(2);
						// taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
						taxDetail['taxAmount'] = (taxDetail['taxableAmount'] * element.tax / 100).toFixed(2);
						// taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
						taxDetails.push(taxDetail)
					}
				}
				taxable_amount = taxable_amount + Number((element.qty * element.rate).toFixed(2));
			});

			taxDetails.forEach(function(elem) {
				total = total + Number(elem.taxAmount);
				console.log(total);
			});
			new_data_row.taxable_amount = taxable_amount.toFixed(2);
			new_data_row.total = (Number(total) + Number(taxable_amount)).toFixed(2);
			// new_data_row.total = parseFloat(Math.round(new_data_row.total * 100) / 100).toFixed(2);
			new_data_row.payable_amount = Math.round(new_data_row.total).toFixed(2);
			if(new_data_row.adjustment_name != undefined && new_data_row.adjustment_name != ''){
				new_data_row.payable_amount = Math.round(Number(new_data_row.payable_amount) + Number(new_data_row.adjustment_amount || 0)).toFixed(2);
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total) - Number(new_data_row.adjustment_amount)).toFixed(2);
			}
			else{
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total)).toFixed(2);
			}
			// new_data_row.round_off = parseFloat(Math.round(new_data_row.round_off * 100) / 100).toFixed(2);
			// new_data_row.payable_amount = parseFloat(Math.round(new_data_row.payable_amount * 100) / 100).toFixed(2);
			this.setState({
				taxDetails: taxDetails,
				new_data_row: new_data_row
			})
		},300)
    }
    getCustomerBranches(customer){
		this.setState({
			showBranch: true,
			loading_branches: true
		})
		Client.getBranchesOfCustomer(customer).then((res)=>{
			let options_branch = []
			if (res.data_rows_size > 0) {
				res.data_rows.forEach(element => {
					let temp = {
						value: element.id,
						text: element.branch_name
					}
					options_branch.push(temp)
				})
				this.setState({
					options_branch: options_branch,
					loading_branches: false
				})
			} else {
				this.setState({
					showBranch: false,
					loading_branches: false
				})
			}
		})
    }
    setSource(source){
        if (source == "contract") {
            this.setState({product_details:this.state.options_contract[0].value,source})
        } else if(source == "inventory"){
            this.setState({product_details:[],source})
        } else if(source== "purchase") {
            this.setState({product_details:this.state.options_purchase[0].value,source})
        }
    }
    editVoucher(to){
        let data = this.state.new_data_row
        data["products"] = this.state.data_rows_right;
        console.log(data);
        localStorage.setItem('currentInvoiceSaleEdit', 1);
        localStorage.setItem('currentInvoiceSale', JSON.stringify(data));
        switch (to) {
            case 'sale':
                this.props.history.push('/accounting/vouchers/invoice/create')       
                break;
            case 'quotation':
                this.props.history.push('/accounting/vouchers/quotation/create')
                break;
            case 'd-note':
                this.props.history.push('/accounting/vouchers/challan/create')
                break;
            case 'contract':
                this.props.history.push('/accounting/vouchers/contract/create')
                break;
            default:
                break;
        }
    }
    addDescriptionToProduct(index,data){
        let data_rows_right = this.state.data_rows_right
        data_rows_right[index]['options_description']=[...data_rows_right[index]['options_description'],data.value]
        data_rows_right[index]['description'] = data.value
        this.setState({data_rows_right})
    }
    changeDescriptionOfProduct(index,data){
        let data_rows_right = this.state.data_rows_right
        data_rows_right[index]['description'] = data.value
        this.setState({data_rows_right})
    }
    setProductRate(index,data){
        let data_rows_right = this.state.data_rows_right
        data_rows_right[index]['rate'] = data.value
        this.setState({data_rows_right})
    }
    render() {
        return (
            <Form onSubmit={()=>{this.createSale()}}>
                <Form.Group widths='equal'>
					<Form.Select options={this.state.options_voucher_id}
						allowAdditions
						search
						onAddItem={(e, value) => this.handleInvoiceIdAdditions(e, value)}
						type="text"
						name="voucher_id"
						placeholder="Invoice #" label="Invoice #" selection value={this.state.new_data_row.voucher_id}
						onChange ={(e, data) => this.handleInputChangeSale(e, data)}
						required/>
					<Form.Input placeholder='Order #' label="Order #" name="order_id" value={this.state.new_data_row.order_id}
							onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				</Form.Group>

					{
						this.state.loading_form_order_id
						?
						<Segment>
							<Dimmer active>
							<Loader />
							</Dimmer>
							<Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
						</Segment>
						:
						<Form.Group widths='equal'>
							<Form.Select selection search options={this.state.options_customer} name='customer'
								className="accounting-invoice-input"
								id="accounting-invoice-customer-input"
								placeholder='Choose Customer'
								label="Customer"
								loading={this.state.loading_form_order_id}
								autoComplete="off"
								value={this.state.new_data_row.customer}
								onChange={(e, data) => this.handleInputChangeSale(e, data)}
								required/>
								{
									this.state.new_data_row.customer != null && this.state.showBranch?
										<Form.Select loading={this.state.loading_branch || this.state.loading_form_order_id} clearable selection search options={this.state.options_branch} name="cust_branch" value={this.state.new_data_row.cust_branch} label="Branch" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
									:null
								}
						</Form.Group>
					}

				<Form.Input type='date' placeholder="Invoice Date" label="Invoice Date" name="date" value={this.state.new_data_row.date}
					 onChange={(e, data) => this.handleInputChangeSale(e, data)}
					 required/>
                <Button.Group fluid wide>
                    <Button 
                        loading={this.state.loading_inventory}
                        icon={"check"}
                        color={this.state.source == "inventory"?"green":"white"}
                        onClick={(e)=>{
                                e.preventDefault()
                                this.getDataRows(this.state)
                            }}
                        >
                        Inventory</Button>
                    <Button
                        loading={this.state.loading_contract}
                        disabled={!this.state.options_contract.length}
                        color={this.state.source == "contract"?"green":"white"}
                        icon={this.state.source == "contract"?"check":""}
                        onClick={(e)=>{
                                e.preventDefault()
                                this.setSource("contract")
                            }}
                        >
                        Contract
                    </Button>
                    <Button
                        loading={this.state.loading_purchase}
                        disabled={!this.state.options_purchase.length}
                        color={this.state.source == "purchase"?"green":"white"}

                        icon={this.state.source == "purchase"?"check":""}
                        onClick={(e)=>{
                                e.preventDefault()
                                this.setSource("purchase")
                            }}
                        >
                        Purchase
                    </Button>

                </Button.Group>
            <Grid fluid wide className="swap-box">
                <Grid.Column width={this.state.product_details.length?8:8}>
                    <Grid.Row>
                        <div style={{float: 'right'}}>
                            <Button size={this.state.defaultSize} icon="repeat" color="red" 
                                onClick={(e) => {
                                    e.preventDefault()
                                    this.componentDidMount()}
                                    }/>
                            <Button size={this.state.defaultSize} style={{padding:0}}>
                                <Dropdown 
                                    selection
                                    options={this.state.tableFields}
                                    name="data_rows_search_field"
                                    className="console-dropdown"
                                    value={this.state.data_rows_search_field}
                                    placeholder="Search Field"
                                    onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
                            </Button>
                            {
                                this.state.data_rows_search_type == 'field'
                                ?
                                <Input type="text"
                                    name="data_rows_search"
                                    className="console-dropdown"
                                    size={this.state.defaultSize}
                                    value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
                                :
                                // <Button size={this.state.defaultSize} style={{padding:0}}>
                                // </Button>
                                <Dropdown selection
                                    search name="data_rows_search"
                                    className="console-dropdown"
                                    // size={this.state.defaultSize}
                                    options={this.state['options_' + this.state.data_rows_search_field]}
                                    value={this.state.data_rows_search}
                                    placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
                            }

                            <Button
                                size={this.state.defaultSize}
                                color='teal'
                                icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
                                onClick={(e, data) => {
                                    e.preventDefault()
                                    this.changeStateVariable(e, data)}
                                }></Button>
                            <Dropdown
                                name='data_rows_limit'
                                inline
                                options={this.state.options_data_rows_limit}
                                value={this.state.data_rows_limit}
                                onChange={(e, data) => this.changeStateVariable(e, data)}
                            />
                            {
                                // 'Showing ' + 
                                this.state.data_rows_offset + 
                                // ' to ' +
                                    ' - ' +
                                (
                                    (this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
                                    (this.state.data_rows_offset + this.state.data_rows_limit) :
                                    this.state.data_rows_size
                                ) +
                                ' of ' + this.state.data_rows_size + ' '
                            }
                            
                            <Button
                                size={this.state.defaultSize}
                                color='teal'
                                icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
                                onClick={(e, data) =>{
                                    e.preventDefault()
                                    this.changeStateVariable(e, data)}
                                }></Button>
                        </div>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <span className="transaction-console">
                            {
                                this.state.product_details.length
                                ?
                                    <div style={{display:"inline-block"}}>
                                        <Button size={this.state.defaultSize}  
                                            onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.setState({product_details:[],product_details:[], observedProductId:false,options_transactions:[],})
                                                }} 
                                            icon="left arrow" color="black"></Button>
                                            {
                                                this.state.source == "contract"
                                                ?
                                                <Form.Select
                                                    inline
                                                    loading={this.state.loading_contract}
                                                    className="console-dropdown"
                                                    options={this.state.options_contract}
                                                    placeholder="Choose Contract"
                                                    value={this.state.product_details}
                                                    onChange={(e,data)=>{
                                                        this.setState({product_details:data.value}
                                                    )}
                                                    }
                                                    />
                                                :
                                                null
                                                
                                            }
                                            {
                                                this.state.source == "purchase"
                                                ?
                                                <Form.Select
                                                    loading={this.state.loading_purchase}
                                                    options={this.state.options_purchase}
                                                    placeholder="Choose Purchase"
                                                    onChange={(e,data)=>{
                                                            this.setState({product_details:data.value}
                                                        )}
                                                    }
                                                    />
                                                :
                                                null
                                            }
                                            {
                                                this.state.source=="inventory"
                                                ?
                                                <Form.Select
                                                    inline
                                                    size="small"
                                                    clearable
                                                    multiple
                                                    className="console-dropdown"
                                                    options={this.state.options_transactions}
                                                    name="transaction_types"
                                                    search
                                                    placeholder="Select Transaction Type"
                                                    selection
                                                    onChange={(e,data)=>this.setState({[data.name]:data.value})}
                                                    value={this.state.transaction_types}
                                                    />
                                                :
                                                null
                                            }

                                    </div>
                                :
                                null
                            }
                            <div style={{float:'right'}}>
                                <Input 
                                    size="small"
                                    name="percentageChange" 
                                    onChange={(e,data)=>this.changePercentage(e,data)} 
                                    value={this.state.percentageChange} />
                                <Button disabled={!this.state.selectedDataRow.length} floated="right" 
                                    onClick={(e)=>{
                                            e.preventDefault()
                                            this.shiftMultipleDataRows()
                                        }} 
                                    icon="right arrow"></Button>
                            </div>
                        </span>
                        {
                            this.state.product_details.length 
                            ?
                            <Table color="blue" key="blue" size="small" className="swap" compact sortable celled>
                                    <Table.Header >
                                        <Table.Row>
                                        {
                                            this.state.product_details ?
                                                this.state.tableFields_2.map((field, index) => (
                                                    <Table.HeaderCell 
                                                     style={{cursor: 'pointer'}}
                                                    onClick={field.value !='option'?(e, data) => this.sortProductDetails(e,field.value):null} 
                                                    style={{cursor: 'pointer'}}
                                                    >
                                                    {
                                                        this.state.source != "inventory"  && field.value=="transaction_type"
                                                        ?
                                                        "Name"
                                                        :
                                                        field.text
                                                    }
                                                    {   
                                                        
                                                        this.state.product_details_sort_order == 'asc' ?
                                                            <Icon name='angle up' style={{visibility: (this.state.product_details_sort == field.value ? 'visible' : 'hidden')}}/>
                                                        :
                                                            <Icon name='angle down' style={{visibility: (this.state.product_details_sort == field.value ? 'visible' : 'hidden')}}/>
                                                        
                                                    }
                                                    </Table.HeaderCell>
                                                ))
                                                :
                                                null
                                        }
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body >
                                    {
                                        this.state.product_details.map((data_row, index) => (
                                                this.state.transaction_types.includes(data_row.transaction_type) || !this.state.transaction_types.length
                                                ?
                                                <Table.Row className="data-row-table-row"  >
                                                {
                                                    this.state.tableFields_2.map((field, index1) => (
                                                        <Table.Cell >
                                                        {
                                                            field.type=='select' 
                                                            ?
                                                            this.convertSelectToText(field.value, data_row[field.value]) 
                                                            :
                                                                field.type =='calulateable'
                                                                ?
                                                                this.rateWtCalcutator(data_row.rate,data_row.tax)
                                                                :
                                                                field.type == 'button'
                                                                ?
                                                                <Button icon="right arrow" size="tiny" onClick={(e)=>{
                                                                    e.stopPropagation()
                                                                    this.shiftFromDetails(data_row)
                                                                }}/>
                                                                :
                                                                this.state.source != "inventory" && field.value=="transaction_type"
                                                                ?
                                                                data_row.name
                                                                :
                                                                data_row[field.value]
                                                        }
                                                        </Table.Cell>
                                                    ))
                                                }
                                                </Table.Row>
                                            :null
                                            ) 
                                        )
                                        
                                    }
                                    </Table.Body>
                                </Table>
                            :
                            <Table color="blue" key="blue" size="small" className="swap"  compact sortable celled>
                                <Table.Header>
                                    <Table.Row>
                                    {
                                        this.state.tableFields.map((field) => (
                                            <Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
                                                {field.text}
                                                {
                                                    this.state.data_rows_sort_order == "asc" ?
                                                        <Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                    :
                                                        <Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                }
                                            </Table.HeaderCell>
                                        ))
                                    }
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                {
                                    this.state.data_rows.map((data_row, index) => (
                                        <Table.Row onClick={()=>{this.dataRowSelector(data_row.id)}} className={this.state.selectedDataRow.includes(data_row.id)?"data-row-table-row selected":"data-row-table-row"}>
                                        {
                                            this.state.tableFields.map((field) => (
                                                <Table.Cell >
                                                    {
                                                        field.type=='select' //if
                                                        ? 
                                                        this.convertSelectToText(field.value, data_row[field.value])
                                                        :
                                                            field.type=='button' // else if
                                                            ?   
                                                                field.value=='transactions'
                                                                ?
                                                                <Button
                                                                    size="tiny"
                                                                    loading={this.state.loading_product_details== data_row.id}
                                                                    icon="info"

                                                                    onClick={(e)=>{
                                                                        e.stopPropagation()
                                                                        e.preventDefault()
                                                                        this.setProductDetails(data_row.id)
                                                                    }} 
                                                                />
                                                                :
                                                                <Button size="tiny" onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    e.preventDefault()
                                                                    this.shiftDataRow(index)
                                                                }} icon="right arrow"></Button>
                                                            :
                                                            data_row[field.value] // else
                                                        
                                                }
                                                </Table.Cell>
                                            ))
                                        }
                                    
                                        </Table.Row>
                                    ))
                                }
                                </Table.Body>
                            </Table>
                        }
                        </Grid.Column>
                    </Grid.Row>
                </Grid.Column>
                {
                    !false
                    ?
                
                <Grid.Column width={this.state.product_details.length?8:8}>
                    <Grid.Row>
                    <Button disabled={!this.state.data_rows_right.length} floated="right" color="red" onClick={()=>{this.setState({data_rows_right:[]})}} icon="remove"></Button>
                        <Table color="blue" key="blue" size="small" className="swap" sortable celled compact>
                            <Table.Header>
                                <Table.Row>
                                {
                                    this.state.tableRightFields.map((field) => (
                                        field.value != 'transactions'
                                        ?
                                        <Table.HeaderCell 
                                            onClick={field.value !='option' && field.value!='description'?(e, data) => this.sortDataRowsRight(e,field.value):null} 
                                            style={{cursor: 'pointer'}}
                                            >
                                            {field.text}
                                            {   
                                                field.value !='option' && field.value!='description'?
                                                (this.state.data_rows_right_sort_order == 'asc' ?
                                                    <Icon name='angle up' style={{visibility: (this.state.data_rows_right_sort == field.value ? 'visible' : 'hidden')}}/>
                                                :
                                                    <Icon name='angle down' style={{visibility: (this.state.data_rows_right_sort == field.value ? 'visible' : 'hidden')}}/>)
                                                :null
                                            }
                                        </Table.HeaderCell>
                                        :
                                        null
                                    ))
                                }
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {   
                                this.state.data_rows_right
                                ?
                                    this.state.data_rows_right.map((data_row, index) => (
                                        <Table.Row className="data-row-table-row" >
                                        { 
                                            this.state.tableRightFields.map((field) => (
                                                field.value != 'transactions'
                                                ?
                                                    <Table.Cell>
                                                        {field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) :
                                                            field.type=='button'
                                                            ?
                                                            <Button size="tiny" color="red" icon="remove"   onClick={() => this.removeDataRow(index)}> 
                                                                
                                                            </Button>
                                                            :
                                                            field.value == "description" && data_row.type !='Contract' && data_row.type != 'Purchase'
                                                            ?
                                                            <Dropdown
                                                                search
                                                                selection
                                                                allowAdditions
                                                                onAddItem={(e,data)=>{this.addDescriptionToProduct(index,data)}}
                                                                onChange={(e,data)=>{this.changeDescriptionOfProduct(index,data)}}
                                                                options={data_row.options_description.map(elem=>{return {text:elem,value:elem}})}
                                                                defaultValue={data_row.description}
                                                            />
                                                            :
                                                            field.value == "rate"
                                                            ?
                                                            <Input type="number" step="any" style={{width:'80px'}} name="rate" value={data_row.rate} onChange={(e,data)=>this.setProductRate(index,data)} placeholder="Enter Rate"/>
                                                            :
                                                            data_row[field.value]
                                                        }
                                                    </Table.Cell>
                                                :
                                                null   
                                            ))
                                        }
                                        </Table.Row>
                                    ))
                                :
                                null
                            }
                            </Table.Body>
                        </Table>
                    </Grid.Row>
                </Grid.Column>
                :
                null
            }
            </Grid>
            <Button.Group fluid wide>
                <Button
                    onClick={(e)=>{
                        e.preventDefault()
                        this.editVoucher("sale")
                    }}
                    >
                    Create Sale
                </Button>
                <Button.Or/>
                <Button
                    onClick={(e)=>{
                        e.preventDefault()
                        this.editVoucher("quotation")
                    }}
                    >
                    Create Quotation
                </Button>
                <Button.Or/>
                <Button
                    onClick={(e)=>{
                        e.preventDefault()
                        this.editVoucher("d-note")
                    }}
                    >
                    Create D-note
                </Button>
                <Button.Or/>
                <Button
                    onClick={(e)=>{
                        e.preventDefault()
                        this.editVoucher("contract")
                    }}
                    >
                    Create Contract
                </Button>
            </Button.Group>
            </Form>
        );
    }
}

export default Swap;
