import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input,Segment, Table, Button, Dropdown, Icon, Checkbox, Modal, Form, Card,Image, Loader, Dimmer, Header, Grid} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import { BarChart,Bar,LineChart,Label, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');
class LeadManagement extends Component{

	constructor(){
		super();
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;
		var startdate = yyyy + '-' + mm + '-01'
		const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
		const RADIAN = Math.PI / 180;
		const renderCustomizedLabel = ({
		  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
		}) => {
		const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
		const x = cx + radius * Math.cos(-midAngle * RADIAN);
		const y = cy + radius * Math.sin(-midAngle * RADIAN);
		 return (
			    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
			      {`${(percent * 100).toFixed(0)}%`}
			    </text>
 		 	);
		};
		
		
		this.state = {
			comments:[],
			multipleAddMode: true,
			data_rows: [],
			temp_label:[],
			customer_graph_data: false,
			new_data_row: {date: dateVaue, new_data_rows:[{}]},
			tableFields: [
				{"value": "date", "text": "Lead Date", "type": "date"},
				{"value": "customer_name", "text": "Customer Name", "type": "field"},
				{"value": "organisation_name", "text": "Organisation Name", "type": "field"},
				{"value": "address", "text": "Address", "type": "field"},
				{"value":"source","text":"source","type":"select"},
				{"value": "phone", "text": "Phone", "type": "field"},
				{"value": "email", "text": "Email", "type": "field"},
				{"value": "service", "text": "Service", "type": "field"},
				{"value": "remarks", "text": "Remarks", "type": "field"},
				{"value": "status", "text": "Status", "type": "field"},
			],
			observed_data_row:{},
			defaultSize: 'mini',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'customer_name',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {},
			graph_staff:false,
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_source:[
				{text:'Just Dial',value:'just_dail'},
				{text:'India Mart',value:'india_mart'},
				{text:'offline',value:'offline'}	
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
			options_staff: [],
			options_graph_staff : [],
			// chartOptions: chartOptions,
			// chartOptions2: chartOptions2,
			// chartOptions3: chartOptions3,
			renderCustomizedLabel : renderCustomizedLabel,
			COLORS: COLORS,
			graph_start_date:startdate,
			graph_end_date:dateVaue
		}
		// this.prepareGraphData = this.prepareGraphData.bind(this)
	}

	componentDidMount() {
		if(localStorage.getItem('options_source')){
			let options_source = JSON.parse(localStorage.getItem('options_source'))
			this.setState({options_source})
		}
		var banks = [7,10];
		var options_voucher_id = [];
		var options_record_ledgers = []
		var options_ledger = []
		this.setState({start_date:this.range(false),end_date:this.range(true),})
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
			options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				all_customers:res.data_rows,
				options_ledger: options_ledger,
				options_to_ledger: options_ledger
			})
		})
		Client.getStaffs(null, null, null, null, null, null, {}, null).then(res=>{
			var options_staff = [{text:'All', value:null}]
			res.data_rows.forEach(function(element) {
				options_staff.push({
					text:element.name , value:element.id
				})
			});
        	this.setState({
				options_staff: options_staff,
        	})
		})
		let filter_staff = {role:['marketing']}
		Client.getStaffs(null, null, null, null, "role", "marketing", {},"filtered").then(res=>{
			var options_graph_staff = []
			var graph_staff = []
			res.data_rows.forEach(function(element) {
				options_graph_staff.push({
					text:element.name , value:element.id
				})
				graph_staff.push(element.id)
			});
        	this.setState({
				options_graph_staff,
				graph_staff
        	})
		})
		this.getDataRows(this.state);
	}
	addSource(e,data){
		let source = this.state.options_source.filter(elem=>{return elem.value == data.value})
		if(!source.length){
			this.setState({options_source:[...this.state.options_source,{text:data.value.toUpperCase(),value:data.value}]},()=>{
				localStorage.setItem('options_source',JSON.stringify(this.state.options_source))
			})
		}
		
	}
    perfectDecimal(number){
		return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }
    changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;
		
		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
		state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
		state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';
		if (data.name == 'filter_staff') {
			state['data_rows_filter']={'staff':[data.value]}
			if (data.value == null) {
				state['data_rows_filter']={}
			}
		}
		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}
	
	changeStateVariableNewDataRow(e, data, index){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value || data.checked;
		if(this.state.multipleAddMode == 0 || index == undefined){
			index = 0
		}
		var new_data_rows = [...this.state.new_data_row.new_data_rows]
		new_data_rows[index][data.name] = data.value;
		new_data_row.new_data_rows = new_data_rows
		this.setState({
			new_data_row: new_data_row,
			multipleAddMode: data.name == 'multiple' ? data.checked :  this.state.multipleAddMode
		})
	}
	
	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
	
	postComment(){
		this.setState({loading:true})
		Client.createLeadComment('create',{show_customer:0,comment:this.state.new_comment,lead:this.state.observed_data_row.id})
		.then(res=>{
			this.getLeadComment()
			this.setState({new_comment:''})
		})
		.catch(err=>{
			console.log(err);
			
		})
	}
	getLeadComment(){
		Client.getLeadComment('comments',{lead:[this.state.observed_data_row.id]}).then(res=>{
			this.setState({
				comments:res.data_rows,
				loading:false
			})
		})
		.catch(err=>{
			this.setState({
				// comments:res.data_rows,
				loading:false
			})
		})
	}
	showDataRowDetails(index){
		let observed_data_row = this.state.data_rows[index]
		if (observed_data_row.customer) {
			this.getTransactions(observed_data_row)
		}else {
			this.setState({
				loading:true,
				show_details_modal:true,
				observed_data_row:observed_data_row
			},()=>{this.getLeadComment()})
		}
	}
	getTransactions(observed_data_row){
		let customer_obj = this.state.all_customers.find(elem=>{return elem.id == observed_data_row.customer})
		this.setState({show_details_modal:true,observed_data_row:{...observed_data_row,customer_obj}},()=>{
			Client.getLeadTransactions(null,null,null,null,null,null,{customer:[observed_data_row.customer]},{date:[this.state.graph_start_date,this.state.graph_end_date]})
			.then(res=>{
				this.setState({customer_graph_data:res.data_rows},()=>{
					this.prepareCustomerGraph()
					this.getLeadComment()
				})
			})
		})
	}
	rehydrateCustomerData(e,data){
		console.log(e,data);
		this.setState({[data.name]:data.value},()=>{
			this.getTransactions(this.state.observed_data_row)
		})
		
	}
	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createLead({...state.new_data_row, multiple: this.state.multipleAddMode}, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {date: state.new_data_row.date, new_data_rows:[{}]}
			})
			this.getDataRows(state);
		})
	}
	connectCustomer(){
		this.setState({conecting_customer:true})
		var state = this.state

		Client.createLead({...state.new_data_row, multiple: 0}, 1).then(res=>{
			this.setState({
				show_details_modal: false,
				conecting_customer: false,
				editMode: false,
				new_data_row:{date: state.new_data_row.date, new_data_rows:[{}]},
				observed_data_row: {}
			})
			this.getDataRows(state);
		})
	}
	getDataRows(state){
		Client.getLead('limeted',state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, 
			state.data_rows_filter,{})
			.then(res=>{
				console.log('limited',res.data_rows)
				this.setState({
					data_rows: res.data_rows,
					data_rows_size: res.data_rows_size,
					opening_balance: res.opening_balance,
					current_credit: res.current_credit,
					current_debit: res.current_debit,
					closing_balance: res.closing_balance,
				})
		})
		
	}

	//CUSTOM FUNCTIONS
	getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
	occuranceOfLabelInAxis(label_value,lable_name,axis_value,axis_name,data_rows){
		var matched = data_rows.filter((elem)=>{
			return elem[axis_name] == axis_value && elem[lable_name] == label_value
		})
		return matched.length
	}
	prepareGraphData(label="staff",axis="date"){	
		this.setState({[axis+'_graph']:false})		
		var data_rows = []
		var staffArr =[]
		if(this.state.graph_staff){
			staffArr = this.state.graph_staff
		} else {
			this.state.options_graph_staff.forEach((elem)=>{
				staffArr.push(elem.value)
			})
		}
		let filter={staff:[...staffArr]}
		let range = {date :[this.state.start_date, this.state.end_date]}
		Client.getLead(axis,0, null, null,null,null, null, null, filter,range).then((res)=>{							
			data_rows = res.data_rows
			var master_data = {}
			var distinct_axises = this.getDistictIn(axis,data_rows)
			for(var key in distinct_axises){
				var distinct_axis_data = distinct_axises[key]
				master_data[key] = this.getDistictIn(label,distinct_axis_data)				
			}
			let data_for_graph = []
			for(var key in master_data){
				var value = master_data[key]
				var mapping = {}
				for(var key1 in value){
					console.log("key",key1)
					let name = this.staffName(key1)
					mapping[name] = value[key1].length
				}
				data_for_graph.push({
					name:key, ...mapping
				})
			}
			console.log(data_for_graph)
			this.setState({
				[axis + '_graph']:data_for_graph,
				graphs_loading:false
			})
		})
	}
	range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0' 
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
	handleDateRangeChange(e,data){
		this.setState({
			[data.name]:data.value,
			graphs_loading:true
		},()=>{
			this.prepareGraphData("staff","date")
			this.prepareGraphData("staff","address")			
		})
	}
	staffName = (staff)=>{

		if (staff == null) {
			console.log(staff);
			return 'undefined'
		}
		var staffarr = this.state.options_graph_staff.filter((elem)=>{
			return elem.value == staff
		})
		console.log(staffarr,this.state.options_graph_staff);
		
		return staffarr[0].text
	}
	showReportModal(){
		this.prepareGraphData("staff","date")
		this.prepareGraphData("staff","address")
		this.setState({modalReport:true})
	}
	addProduct(e){
		e.preventDefault();
		var products = this.state.new_data_row.new_data_rows;
		var new_data_row = this.state.new_data_row;
		new_data_row.new_data_rows = [...products, {date: new_data_row.date}]
		this.setState({
			new_data_row: new_data_row
		})
	}
	removeProduct(id){
		var products = [...this.state.new_data_row.new_data_rows];
		var new_data_row = {...this.state.new_data_row};
		var products1 = []
		var found = 0;
		products.forEach(function(element, index) {
			if(index != id){
				products1.push(element)
			}
			else
				found = 1
		});
		new_data_row.new_data_rows = [...products1]
		this.setState({
			new_data_row: new_data_row
		})
	}
	withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.month.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
	}
	showCustomerGraph(){
		this.setState({show_customer_graph:true})
	}
	prepareCustomerGraph(){
		let temp_label = []
		let customer_graph_data = this.state.customer_graph_data
		let with_month = this.withMonth(customer_graph_data)
		console.log(with_month);
		let data_rows = with_month
		var master_data = {}
		var distinct_axises = this.getDistictIn("month",data_rows)
		for(var key in distinct_axises){
			var distinct_axis_data = distinct_axises[key]
			master_data[key] = this.getDistictIn("type",distinct_axis_data)				
		}
		console.log("master",master_data)
		let data_for_graph = []
		for(var key in master_data){
			var value = master_data[key]
			var mapping = {}
			// console.log("each key",value)
			for(var key1 in value){
				temp_label.push(key1)
				console.log("key",key1)
				// let name = this.staffName(key1)
				mapping[key1] = 0
				 value[key1].forEach(element => {
					 mapping[key1] +=parseInt(element.sum)
				 });
			}
			// console.log(mapping,":mapping");
			
			data_for_graph.push({
				name:key, ...mapping
			})
		}
		// console.log("graph::",data_for_graph)
		temp_label=[...new Set(temp_label)]
		let sums ={}
		customer_graph_data.forEach(elem=>{
			console.log(elem)
			if(sums.hasOwnProperty(elem.type)){
				sums[elem.type] += parseInt(elem.sum)
			} else{
				sums[elem.type] = parseInt(elem.sum)
			}
		})
		this.setState({graph_customer:data_for_graph,temp_label,sums})
		
	}
	
	render(){
		return(
			<div>
				<Button size={this.state.defaultSize} onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
			    <Button size={this.state.defaultSize} onClick={() => this.showReportModal()} color="green">REPORT</Button>
			    <div style={{float: 'right'}}>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.options_staff}
							name="filter_staff"
							value={this.state.filter_staff}
							placeholder="Select Staff"
							search
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.tableFields}
							name="data_rows_search_field"
							value={this.state.data_rows_search_field}
							placeholder="Search Field"
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					{
						this.state.data_rows_search_type == 'select'
						?
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
						:
						<Input type={this.state.data_rows_search_type == 'field' ? 'text' : this.state.data_rows_search_type}
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					<Dropdown
						name='data_rows_limit'
						inline
						options={this.state.options_data_rows_limit}
						value={this.state.data_rows_limit}
						onChange={(e, data) => this.changeStateVariable(e, data)}
				    />
					{
						'Showing ' + this.state.data_rows_offset + ' to ' +
						(
							(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
							(this.state.data_rows_offset + this.state.data_rows_limit) :
							this.state.data_rows_size
						) +
						' of ' + this.state.data_rows_size + ' '
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal}
					closeOnDimmerClick={false}
					onClose={() => this.setState({showCreateDataModal:false, new_data_row: {date: this.state.new_data_row.date, new_data_rows:[{}]}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content scrolling>
						<Button icon="close" 
								color="red"
								style={{position:'absolute',top:'-10px',right:'-10px',zIndex:2000}}
								onClick={()=>{
									this.setState({showCreateDataModal:false, new_data_row: {date: this.state.new_data_row.date, new_data_rows:[{}]}, editMode: false, creatingNewDataModal: false})
								}}>

							</Button>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Input type="date" fluid required name="date" value={this.state.new_data_row.date}/>
							    <Form.Checkbox toggle fluid name="multiple" checked={this.state.multipleAddMode}
								onChange={(e, data) => this.changeStateVariableNewDataRow(e, {...data, name:'multiple'})}/>
							</Form.Group>
							{
								this.state.multipleAddMode
								?
								<Table compact className="accounting-invoice-product-table">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Customer</Table.HeaderCell>
											<Table.HeaderCell>Organisation</Table.HeaderCell>
											<Table.HeaderCell>Address</Table.HeaderCell>
											<Table.HeaderCell>City</Table.HeaderCell>
											<Table.HeaderCell>Phone</Table.HeaderCell>
											<Table.HeaderCell>Email</Table.HeaderCell>
											<Table.HeaderCell>Service</Table.HeaderCell>
											<Table.HeaderCell>Source</Table.HeaderCell>
											<Table.HeaderCell>Remarks</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
									{
										this.state.new_data_row.new_data_rows.map((new_data_row, index) => (
											<Table.Row>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="customer_name" value={new_data_row.customer_name} placeholder="Customer Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="organisation_name" value={new_data_row.organisation_name} placeholder="Organisation" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="address" value={new_data_row.address} placeholder="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Dropdown 
													selection 
													fluid required name="city"
													value={new_data_row.city} placeholder="City"  
													options={this.props.cities}
													onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="phone" value={new_data_row.phone} placeholder="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="email" value={new_data_row.email} placeholder="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input maxLength="150" type="text" fluid required name="service" value={new_data_row.service} placeholder="Service Required" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Select search maxLength="150"  options={this.state.options_source} fluid required name="source" allowAdditions onAddItem={(e,data)=>{this.addSource(e,data)}} allowAdditions onAddItem={(e,data)=>{this.addSource(e,data)}} value={new_data_row.source} placeholder="Source" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input maxLength="500" type="text" fluid required name="remarks" value={new_data_row.remarks} placeholder="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<img src={RemoveImage} onClick={() => this.removeProduct(index)} style={{width:'25px', height:'25px'}}/>
											</Table.Cell>
											</Table.Row>
										))
									}
									</Table.Body>
									<Table.Footer>
										<Button primary onClick={(e) => this.addProduct(e)}>MORE LEAD</Button>
									</Table.Footer>
								</Table>
								:
								<div>
								<Form.Input type="text" fluid required name="customer_name" value={this.state.new_data_row.customer_name} label="Customer Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="organisation_name" value={this.state.new_data_row.organisation_name} label="Organisation" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="address" value={this.state.new_data_row.address} label="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Group widths='equal'>
									<Form.Input type="text" fluid required name="phone" value={this.state.new_data_row.phone} label="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" fluid required name="email" value={this.state.new_data_row.email} label="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Select 
										maxLength="150"  
										allowAdditions onAddItem={(e,data)=>{this.addSource(e,data)}}
										options={this.state.options_source} fluid required 
										name="source" value={this.state.new_data_row.source} 
										placeholder="Source" 
										label="Source"
										search
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
									<Form.Select 
										selection 
										fluid required name="city"
										value={this.state.new_data_row.city} placeholder="City"  
										options={this.props.cities}
										label="City"
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
								</Form.Group>
								<Form.Input type="text" fluid required name="service" value={this.state.new_data_row.service} label="Service Required" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</div>
							}
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
				<Modal size="large" open={this.state.modalReport}  onClose={() => this.setState({modalReport:false,date_graph:false,address_graph:false})}>
						<Modal.Content scrolling>
							<div style={{display:'inline-flex'}}>
								<Dropdown 
									multiple
									selection
									search
									placeholder="Choose Staffs"
									options={this.state.options_graph_staff}
										onChange={(e,data)=>{ this.setState({graph_staff:data.value})}}
										/> 
								<Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
									placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
								<Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
									placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
								<div>
									<Loader color="black" black size="medium" loading={this.state.graphs_loading}/>
								</div>
							</div>
							<hr></hr>
				    	    <Grid divided='vertically'>
    							<Grid.Row columns={2}>
									<Grid.Column  >
										{
											this.state.date_graph
											?
											<LineChart width={500} height={300} data={this.state.date_graph}
												margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
												<CartesianGrid strokeDasharray="10 10" />
												<XAxis dataKey="name" />
												<YAxis />
												<Tooltip />
												<Legend />
												{	
													this.state.graph_staff.length > 0?
													this.state.graph_staff.map((staff,i)=>
														<Line type="monotone" dataKey={this.staffName(staff)} fill={"#"+Math.floor(100000 + Math.random() * 900000)} />
													)
													:
													this.state.options_graph_staff.map((staff,i)=>
														<Line type="monotone" dataKey={staff.text} fill={"#"+Math.floor(100000 + Math.random() * 900000)} />
													)
												}
											</LineChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>
										
												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}	
     						 		</Grid.Column>
     						 		<Grid.Column>
										{
											this.state.address_graph
											?
											<BarChart
													width={500}
													height={300}
													data={this.state.address_graph}
													margin={{
													top: 5, right: 30, left: 20, bottom: 5,
													}}
												>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis label="Month" dataKey="name" />
													<YAxis label="Amount" />
													<Tooltip />
													<Legend />
													{	
														this.state.graph_staff.length > 0?
														this.state.graph_staff.map((staff,i)=>
															<Bar type="monotone" dataKey={this.staffName(staff)} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
														)
														:
														this.state.options_graph_staff.map((staff,i)=>
															<Bar type="monotone" dataKey={staff.text} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
														)
													}
												</BarChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>
										
												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}
     						 		</Grid.Column>
     						 	</Grid.Row>
							</Grid>
						</Modal.Content>			
                </Modal>
				<Modal size="large" open={this.state.show_details_modal} 
					onClose={()=>{
						this.setState({
							show_details_modal:false,
							observed_data_row:false,
							new_data_row:{date: this.state.new_data_row.date, new_data_rows:[{}]},
							customer_graph_data:[],
							graph_customer:false,
							sums:{}
						})
					}}>
					
					<Modal.Content scrolling>
						<div className="lead-rows">
							<div>
								<span className="lead-label">Customer Name: </span>
								<span>{this.state.observed_data_row.customer_name}</span>
							</div>
							<div>
								<span className="lead-label">Service: </span>
								<span>{this.state.observed_data_row.service}</span>
							</div>
							<div>
								<span className="lead-label">Organisation: </span>
								<span>{this.state.observed_data_row.organisation_name}</span>
							</div>
						</div>
						<div className="lead-rows">
							<div>
								<span className="lead-label">Lead Date: </span>
								<span>{this.state.observed_data_row.date}</span>
							</div>
							<div>
								<span className="lead-label">Phone: </span>
								<span>{this.state.observed_data_row.phone}</span>
							</div>
							<div>
								<span className="lead-label">Email: </span>
								<span>{this.state.observed_data_row.email}</span>
							</div>
						</div>
						<div className="lead-rows">
							<div>
								<span className="lead-label">Address : </span>
								<span>{this.state.observed_data_row.address}</span>
							</div>
							<div>
								<span className="lead-label">City: </span>
								<span>{this.state.observed_data_row.city}</span>
							</div>
							<div>
								<span className="lead-label">Remark: </span>
								<span>{this.state.observed_data_row.remarks}</span>
							</div>
						</div>
						{
							!this.state.observed_data_row.customer
							?
							<div>
								<div className="lead-rows">
									<Dropdown
										selection
										search
										options={this.state.options_ledger}
										fluid wide
										onChange={(e,data)=>{
											let new_data_row = this.state.new_data_row
											new_data_row={...this.new_data_row,...this.state.observed_data_row,customer:data.value}
											new_data_row['new_data_rows'] = [{...this.state.observed_data_row,customer:data.value}]
											this.setState({
												new_data_row
											})
										}}
										/>
									<Button onClick={()=>{this.connectCustomer()}} loading={this.state.conecting_customer}>Connect</Button>
								</div>
							</div>
							:
							null
						}

						<Checkbox toggle checked={this.state.modal_mode==1} 
								onClick={()=>{
									this.setState({modal_mode:this.state.modal_mode==1?0:1})
								}}
								/>

						{
							this.state.modal_mode && this.state.observed_data_row.customer_obj
							?
							<>
							<hr></hr>
								<Grid columns="equal">
									<Grid.Column>
										<h3>Customer : {this.state.observed_data_row.customer_obj.ledger_name}</h3>
										<h3>Total Sum</h3>

										{
											this.state.temp_label.map(label=>{
												return(
													<h4>{label.toUpperCase()} : {this.state.sums[label]}</h4>
												)
											})
										}
									</Grid.Column>
									<Grid.Column>
									<Input 	
										type="date" 
										value={this.state.graph_start_date} 
										name="graph_start_date"
										onChange={(e,data)=>{this.rehydrateCustomerData(e,data)}}
										// label={<Label basic>Start</Label>}
										label={{ content: 'FROM' }}
										/>
									<Input 	
										type="date" 
										value={this.state.graph_end_date}
										name="graph_end_date"
										onChange={(e,data)=>{this.rehydrateCustomerData(e,data)}}
										// label={<Label basic>End</Label>}
										label={{ content: 'TO' }}
										/>
										
										{
											this.state.graph_customer
											?
											<LineChart
													width={500}
													height={300}
													data={this.state.graph_customer}
													margin={{
													top: 5, right: 30, left: 20, bottom: 5,
													}}
												>
													<CartesianGrid strokeDasharray="3 3" />
													<XAxis dataKey="name" />
													<YAxis />
													<Tooltip />
													<Legend />
													{
														this.state.temp_label.map(elem=>{
															return <Line type="monotone" dataKey={elem} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
														})
													}
														{/* <Bar type="monotone" dataKey={"sale"} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} /> */}
						
													
												</LineChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>
										
												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}
									</Grid.Column>
								</Grid>
							</>
							:
							null
						}
						{
							!this.state.modal_mode
							?
							<div>
							<h3>Customer : {this.state.observed_data_row.customer_obj?this.state.observed_data_row.customer_obj.ledger_name:'Not Connected Yet'}</h3>
							<Card.Content style={{backgroundColor:'#ffffff'}}>
									{
										this.state.loading?
										<Segment>
											<Dimmer active>
												<Loader />
											</Dimmer>
										</Segment>:
										<div style={{maxHeight:'35vh',overflowY:'auto',borderBottom:'1px solid black'}}>
										{(this.state.comments.length?
										this.state.comments.map((comment,i)=>{
											
											return(
											<>
												<div
													style={{backgroundColor:comment.commenter==1?'greenyellow':'none',padding:'0px 5px',borderRadius:'5px',border:"1px solid grey",marginTop:"3px",}}>
													<div className="toggleContainer">
														<span style={{fontWeight:'bold',color:'steelblue'}}>{comment.commenter_type == "App\\Staffs" ? comment.staff_name : (comment.commenter_name + " (Customer)")}</span>
														
														<small style={{display:'block',color:'grey',marginTop:'-10px'}}>{comment.commented_on}</small>
													</div>
													<p style={{fontSize:'1rem',textTransform:'capitalize',color:'drakgrey'}}>{comment.comment}</p>
												</div>
											</>
										)})
										:
										<Card.Meta>
											*No Comments Yet
										</Card.Meta>)}
										</div>
									}
									<div style={{diaplay:'block'}}>
										<Input 
											autoComplete="off" autofocus id='commentInput'
											// onClick={this.commentBoxClick.bind(this)}
											// onKeyDown={(e)=>this.commentBoxKeyDown(e)}
											onChange={(e, data) => this.setState({[data.name]:data.value})}
											labelPosition="right"
											icon={{ name: 'send', circular: true, link: true, onClick:this.postComment.bind(this) }}
											value={this.state.new_comment} 
											name="new_comment"
											fluid wide required placeholder="Write new comment" />
									</div>
								</Card.Content>
							</div>
							:
							null
						}
					</Modal.Content>
				</Modal>
						
			</div>
        )
	}
}
export default LeadManagement;








































