import React from 'react';
import { Container,Button ,Form,Card,Icon,Grid ,Input,Select, Dropdown, GridColumn, Image, Checkbox, Modal, Popup, Table, FormButton, Segment, Dimmer, Loader, Label } from 'semantic-ui-react'
import { BrowserRouter,Router, Link,Route, Switch, withRouter } from 'react-router-dom';
// import FileViewer from 'react-file-viewer';
// import 'file-viewer'
import Client from '../services/Client';

class FileOrdersPreview extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            added_files: [],
            show_preview:0,
            loading_order_preview:false,
            files: props.files != undefined || props.files != null ? JSON.parse(props.files) : [],
            files_check: props.files,
            order_data_row: {...props.order_data_row, edit: true},
            loadingImage: true
        }
    }

    componentDidMount(){
        this.fileInputRef = React.createRef();
        this.handleFileAddition = this.handleFileAddition.bind(this);
    }

    changeNewDataRow(data,e){
    }

    changeFilePreviewIndex(index){
        this.setState({show_preview: index, imageSwitchGap: true})
        setTimeout(() => {
            this.setState({imageSwitchGap: false})
        }, 1000);
    }

    handleFileAddition(e){
        let added_files = [...this.state.added_files]
        let files = [...this.state.files]
        const target_files =  Array.from(e.target.files)

        let data = new FormData()

        for (let index = 0; index < target_files.length; index++) {
            const file = target_files[index];
            files = [...files, {
                add_flag: true,
                file_upload_key: 'image'+index,
                file_key: URL.createObjectURL(file),
                file_name: file.name,
                file_type: file.type.split("/").pop()
            }]

            data.append('image'+index,file,file.name)
        }
        this.setState({
            files: files,
            uploadData: data
        })
    }

    async fileReader(file){
        let reader = new FileReader();
        reader.onload =  (e) => {
            file.result = e.target.result
            // files.push({'file':file})
            return file
        };
        await reader.readAsDataURL(file);
    }

    updateOrder(){
        this.setState({updatingOrder: true})
        Client.createOrderSlip({...this.state.order_data_row, files: this.state.files}, true, this.state.uploadData).then(res=>{
            this.setState({updatingOrder: false})
            this.props.orderUpdated();
        })
    }

    downloadFile(file){
        window.open(file, 'dsk_download_tab');
    }

    render(){
        return(
            <div style={{display: "flex", justifyContent: "center", flexWrap: "wrap"}}>
            {
                this.state.files.length > 0
                ?
                    !this.state.imageSwitchGap
                    ?
                    // <file-viewer
                    //     style={{width:'100%',flexGrow:1,display:'flex',justifyContent:'center'}}
                    //     id={this.state.files[this.state.show_preview].file.name+"."+this.state.files[this.state.show_preview].file.type.split('/')[1]}
                    //     filename={this.state.files[this.state.show_preview].file_key}
                    //     url={"https://dsk-order-files.s3.ap-south-1.amazonaws.com/"+this.state.files[this.state.show_preview].file_key}
                    // />
                        this.state.files[this.state.show_preview].file_key.includes("blob:")
                        ?
                        <div>
{
                        // <FileViewer
                        //     style={{width:'500px'}}
                        //     fileType={this.state.files[this.state.show_preview].file_type}
                        //     filePath={this.state.files[this.state.show_preview].file_key}/>
}
                        </div>
                        :
                        <div style={{display:'flex', flexDirection:'horizontal', alignItems:'center'}}>
{
                            // <FileViewer
                            //     style={{width:'500px'}}
                            //     fileType={this.state.files[this.state.show_preview].file_key.split(".").pop().toLowerCase()}
                            //     filePath={"https://dsk-order-files.s3.ap-south-1.amazonaws.com/"+this.state.files[this.state.show_preview].file_key}/>
}
                            <Button primary icon="download" onClick={() => this.downloadFile("https://dsk-order-files.s3.ap-south-1.amazonaws.com/"+this.state.files[this.state.show_preview].file_key)}/>
                        </div>
                    :
                    <Loader active inline='centered' />
                :
                <p>No Files Added.</p>
            }
                <div style={{width:'100%', display:'flex', justifyContent:'center', flexWrap: 'wrap'}}>
                    <Button size="tiny" icon="angle left" onClick={() => this.changeFilePreviewIndex(this.state.show_preview-1)} disabled={this.state.show_preview-1 < 0}/>
                    {
                        this.state.files.map((file, index)=>(
                            <Label onClick={() => this.changeFilePreviewIndex(index)} style={{cursor:'pointer'}} color={index == this.state.show_preview ? "blue" : "grey"}>{file.file_key}</Label>
                        ))
                    }
                    <Button size="tiny" icon="angle right" onClick={() => this.changeFilePreviewIndex(this.state.show_preview+1)} disabled={this.state.show_preview+1 >= this.state.files.length}/>

                    <Button
                        icon="attach"
                        color="green"
                        size="tiny"
                        style={{fontSize:'12px'}}
                        onClick={(e) => {
                            e.preventDefault()
                            this.inputElement.click();}}/>
                    <input
                        ref={input => this.inputElement = input}
                        type="file"
                        hidden
                        multiple="multiple"
                        onChange={(e)=>this.handleFileAddition(e)}/>

                    <Button onClick={() => this.updateOrder()} loading={this.state.updatingOrder} size="tiny">
                        UPDATE
                    </Button>
                </div>
            </div>
        )
    }
}
export default FileOrdersPreview;
