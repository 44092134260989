import React, {Component} from 'react';

import Client from '../services/Client';
import { Header, Modal, Table } from 'semantic-ui-react';

class BookingDetails extends Component{


    constructor(props){

        super(props);

        this.state = {
            open: false,
            editing: false,
            booking: props.booking,
            headBooking: props.headBooking
        }

        this.toggleListOpen = this.toggleListOpen.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
        this.checkAddonSelected = this.checkAddonSelected.bind(this);
        this.handleEdits = this.handleEdits.bind(this);
        this.handleAddon = this.handleAddon.bind(this);
        this.handleComment = this.handleComment.bind(this);
    }

    componentWillReceiveProps(p){

        this.setState({
            booking: p.booking,
            headBooking: p.headBooking
        })
    }

    toggleListOpen(){
        this.setState({
            open: !this.state.open
        })
    }

    toggleEdit(){
        if(this.state.editing){
            Client.updateBooking({
                bookingId: this.state.booking.bookingId,
                addons: JSON.stringify(this.state.booking.addons.map((addon)=>(addon.addonId))),
                addonOffers: this.state.booking.offers,
                noOfUnits: this.state.booking.noOfUnits,
                additionalComments: this.state.booking.additionalComments
            }).then((res)=>{
                console.log(res);
            })
            this.setState({
                editing: false
            })
        }else{
            this.setState({
                editing: true
            })
        }
    }

    checkAddonSelected(id){
        for(var i=0; i<this.state.booking.addons.length; i++){
            if(this.state.booking.addons[i].addonId==id){
                return true
            }
        }
        return false;
    }

    handleEdits(e){
        this.setState({
            booking: {
                ...this.state.booking,
                [e.target.name]: e.target.value
            }
        })
    }

    handleAddon(e){
        var id = e.target.value;
        var checked = e.target.checked;

        alert(id);
        alert(checked);

        if(checked){
            for(var i=0; i<this.state.booking.addonsAvailable.length; i++){
                if(this.state.booking.addonsAvailable[i].addonId == id){
                    this.setState({
                        booking: {
                            ...this.state.booking,
                            addons: [
                                ...this.state.booking.addons,
                                this.state.booking.addonsAvailable[i]
                            ]
                        }
                    });
                }
            }
        }else{
            for(var j=0; j<this.state.booking.addons.length; j++){
                if(this.state.booking.addons[j].addonId == id){
                    var addons = [...this.state.booking.addons];
                    addons.splice(j, 1);
                    this.setState({
                        booking: {
                            ...this.state.booking,
                            addons: addons
                        }
                    });
                }
            }
        }
    }

    handleComment(e){
        if(e.target.value.includes(this.props.booking.additionalComments)){
            this.setState({
                booking: {
                    ...this.state.booking,
                    additionalComments: e.target.value
                }
            })
        }
    }

    showTrackingLog(){
        this.setState({
            showBookingTracking: true
        })
    }

    render(){

        let total = 0;

        if(this.state.open){
            return(
                <div className="booking-details-container" style={{zIndex: '5'}}>
                    {
                        this.state.editing
                        ?
                        <i className="ui save large icon booking-edit-icon" onClick={this.toggleEdit}/>
                        :
                        <i className="ui edit large icon booking-edit-icon" onClick={this.toggleEdit}/>
                    }
                    <i className="ui time large icon booking-edit-icon" style={{marginRight:'50px'}} onClick={() => this.showTrackingLog()}/>
                    <div className="booking-details-header" onClick={this.toggleListOpen}><strong>Details: </strong></div>
                    <div className="booking-list-headers">
                        <div className=""><strong>SERVICE</strong></div>
                        <div className=""><strong>PROVIDER</strong></div>
                        <div className=""><strong>UNITS</strong></div>
                    </div>
                    <div className="booking-list-content">
                        <div className="">{this.props.booking.service}</div>
                        <div className="">{this.props.booking.provider}</div>
                        {
                            this.state.editing
                            ?
                            <span><input type="number" step="0.01" name="noOfUnits" style={{width: '100px'}} value={this.state.booking.noOfUnits} onChange={this.handleEdits} /> {this.props.booking.unit}</span>
                            :
                            <div className="">{this.state.booking.noOfUnits+" "+this.state.booking.unit}</div>
                        }
                    </div>
                    {
                        this.state.editing
                        ?
                        <textarea className="booking-comment" onChange={this.handleComment} value={this.state.booking.additionalComments==this.props.booking.additionalComments?this.props.booking.additionalComments+'\n':this.state.booking.additionalComments} />
                        :
                        <div className="booking-comment"><strong>Additional Comments:</strong> {this.state.booking.additionalComments}</div>
                    }
                    <div className="booking-addons">Addons: &nbsp;
                        {
                            this.props.booking.addonsAvailable.map((addon)=>(
                                <span>
                                    <input type='checkbox' disabled={!this.state.editing} checked={this.checkAddonSelected(addon.addonId)} onChange={this.handleAddon} value={addon.addonId} />&nbsp;{addon.addon}&nbsp;
                                </span>
                            ))
                        }
                    </div>
                    <div className="booking-visiting-header">Visiting Details:</div>
                    {
                        this.props.booking.onDoor == 0
                        ?
                        <div className="booking-visiting-content">
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Name:</strong></span>
                                    <span className="column">{this.props.booking.visitingDetails.name}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui twp column grid">
                                    <span className="column thin"><strong>Phone:</strong></span>
                                    <span className="column">{this.props.booking.visitingDetails.phone}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Pickup Date And Time:</strong></span>
                                    <span className="column">{this.props.booking.visitingDateAndTime}</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="booking-visiting-content">
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Name:</strong></span>
                                    <span className="column">{this.props.booking.visitingAddress.name}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Phone:</strong></span>
                                    <span className="column">{this.props.booking.visitingAddress.phone}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Address:</strong></span>
                                    <span className="column">{this.props.booking.visitingAddress.addressLine1 + ", " + this.props.booking.visitingAddress.address}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui two column grid">
                                    <span className="column thin"><strong>Delivery Date And Time:</strong></span>
                                    <span className="column">{this.props.booking.visitingDateAndTime}</span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="booking-visiting-header">Payment Details:</div>
                    <div className="booking-visiting-content">
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Payment Mode:</strong></span>
                                <span className="column">{this.props.booking.payment}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Booking Amount:</strong></span>
                                <span className="column">{this.props.booking.bookingAmount}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Additional Charges:</strong></span>
                                <span className="column">{this.props.booking.additionalCharges}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Total Amount:</strong></span>
                                <span className="column">{this.props.booking.totalAmount}</span>
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={this.state.showBookingTracking}
                        onClose={() => this.setState({showBookingTracking: false})}
                        basic
                        size='small'
                        >
                        <Header icon='time' content='Tracking Details' />
                        <Modal.Content>
                        <Table celled>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>STATUS</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED ON</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED BY</Table.HeaderCell>
                                <Table.HeaderCell>REMARKS</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {
                                this.state.headBooking.bookingTracking.length > 0
                                ?
                                this.state.headBooking.bookingTracking.map((tracking) => (
                                    <Table.Row>
                                        <Table.Cell>
                                            {tracking.status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedOn}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedBy}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.reason}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                :
                                <Table.Row><Table.Cell>NO STATUS UPDATED YET</Table.Cell></Table.Row>
                            }
                            </Table.Body>
                        </Table>
                        </Modal.Content>
                    </Modal>
                </div>
            )
        }
        return(
            <div className="sales-product-list-header" onClick={this.toggleListOpen}>
                <strong>More Details:</strong><span className="right floated"><i className="caret down icon"></i></span>
            </div>
        )
    }
}

export default BookingDetails;
