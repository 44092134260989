import React from 'react'
import {Card, Dropdown, Input,Loader,Segment,Dimmer,Icon, Image, Checkbox, Grid, Table} from 'semantic-ui-react'
import Client from '../services/Client';
import '../style/dashboard.css'
import { BarChart,Bar,LineChart, Line,XAxis, YAxis, Label, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
class DashboardEarning extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            graph_data:[
                {name:'2019-11-1',expenses:1,earning:2},
                {name:'2019-11-3',expenses:4,earning:7},
                {name:'2019-11-5',expenses:3,earning:4},
            ],
            graph_factor:'quarter',
            tableFields: [
				{"value": "date", "text": "V_Date", "type": "date"},
				{"value": "voucher_id", "text": "V_Id", "type": "field"},
				{"value": "debit", "text": "Debit", "type": "field"},
				{"value": "credit", "text": "Credit", "type": "field"},
            ],
            data_rows:[],
            data_rows_table:[],
            data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'voucher_id',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
				ledger: []
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
            options_to_ledger: [],
            single_factor_graph:[]
        }
    }
    componentWillMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
        }
    }
    componentDidMount(){
        
        var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
            res.data_rows.forEach(function(data_row) {
                options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
                options_ledger: options_ledger,
				options_to_ledger: options_ledger
			},()=>{
                // if (this.props.ledger_voucher) {
                    // 	this.setFilter({}, {name:'ledger',value:this.props.ledger_voucher})
                    // }
                })
            })
        this.getDataRows(this.state)
    }
    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value})
    }
    changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
    }
    getDataRows(state){
        this.setState({loading_graph:true})
		Client.getDashboardEarningsReport(null,null,null,null,null,null,null,null,null,{date:[this.state.start_date,this.state.end_date]},null).then(res=>{
           this.setState({
                data_rows:res.totalReport,
                loading_graph:false
           },()=>{
            this.prepareGraphDataSingleFactor(this.state.graph_factor)

           })
        })
    }
    setFilter(e, data){
		if(this.props.ledger_voucher){
			console.log("data input",data)
		}
		var filter = {...this.state.data_rows_filter}
		filter[data.name] = [data.value || e.target.value]
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
    aggregateCountForKey(data_row,key){
        let returnalbe = []
        let aggregated_data = {}
        data_row.forEach(elem=>{
            if (aggregated_data.hasOwnProperty(elem[key])) {
                aggregated_data[elem[key]].earnings = parseInt(aggregated_data[elem[key]].earnings) + parseInt(elem.earnings )
                aggregated_data[elem[key]].expenses = parseInt(aggregated_data[elem[key]].expenses) + parseInt(elem.expenses )
            } else {
                aggregated_data[elem[key]] = {expenses:parseInt(elem.expenses)||0}
                aggregated_data[elem[key]] = {...aggregated_data[elem[key]],earnings:parseInt(elem.earnings)||0}
            }
        })
        for(var key in aggregated_data){

            returnalbe.push({
                name:key,expenses:aggregated_data[key]["expenses"],earnings:aggregated_data[key]["earnings"]
            })
        }
        return returnalbe
    }
    prepareGraphDataSingleFactor(axis="date"){
		// this.setState({['single_factor_graph']:false})
		var data_rows = []
		let filter={}
		let range = {slip_date :[this.state.start_date, this.state.end_date]}
		// Client.getOrderSlips(axis+'dash',0, null,"slip_date", "asc",null, null, null, filter,range).then((res)=>{
            data_rows =
                axis == "month"
                ?
                this.withMonth(this.state.data_rows)
                :
                    axis == "week"
                    ?
                    this.withWeekAndMonth(this.state.data_rows)
                    :
                        axis == "quarter"
                        ?
                        this.withQuarter(this.state.data_rows)
                        :
                        this.state.data_rows
            
			let data_for_graph =this.aggregateCountForKey(data_rows,axis)
			
			console.log("master from dashboard orders",data_for_graph)
			this.setState({[ 'single_factor_graph']:data_for_graph})
		// })
    }
    handleGraphDataChange(e,data){
        this.setState({
			[data.name]:data.value,
			loading_graph:true
		},()=>{
            // alert('mode')
			// this.prepareGraphDataSingleFactor(this.state.graph_factor)
			this.getDataRows()
		})
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    withQuarter(array) {
        let quarterArr = [ "4", "4", "4", "1", "1", "1", "2", "2", "2", "3", "3", "3" ]
		let withQuarterArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let quarter = quarterArr[parseInt(date[1])-1]
            let year = date[0].slice(2,4)
            let transition = quarter == 4 ? (parseInt(year)-1)+'-'+year:year+'-'+(parseInt(year)+1)
			let temp = {...elem,quarter:transition+'-'+'Q'+quarter}
			withQuarterArray.push(temp)
        })
        console.log("with month array",withQuarterArray);
        return withQuarterArray
        .sort((a,b)=>{
            return a.quarter>b.quarter ? 1:-1
        })
    }

    withWeekAndMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
            
            let javascriptDate = new Date(parseInt(date[0]),parseInt(date[1])-1,parseInt(date[2]))
            console.log('week_number',javascriptDate.getWeekOfMonth())
			let temp = {...elem,week:'W'+(javascriptDate.getWeekOfMonth()+1     )+'-'+month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    render(){
        return(
            <Card>
                <Card.Header>
                    Earnings
                </Card.Header>
                <Card.Content>
                    <Grid divided stackable>
                        <Grid.Row >
                            <Grid.Column width={16} className="no-padding">
                            <div className="console-earning" style={{display:'inline-flex'}}>
                                <Input size="mini" type="date" name="start_date" label={{ content: 'FROM' }}
                                    placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
                                <Input size="mini" type="date" name="end_date" label={{ content: 'TO' }}
                                    placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
                                <Loader loading = {this.state.loading_graph}/>
                                <Dropdown
                                    name="graph_factor"
                                    value={this.state.graph_factor}
                                    // selection
                                    style={{minHeight:0,minWidth:0}}
                                    onChange={(e,data)=>{this.handleGraphDataChange(e,data)}}
                                    options={[
                                        {text:'Daily',value:'date'},
                                        {text:'Weekly',value:'week'},
                                        {text:'Monthly',value:'month'},
                                        {text:'Quarterly',value:'quarter'},
                                    ]}
                                />
                            </div>
                            {
                                !this.state.loading_graph
                                ?
                                <center>
                                    <LineChart width={300} height={300} data={this.state.single_factor_graph}
                                        margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="10 10" />
                                        <XAxis dataKey="name" >
                                            <Label angle={0} value='Month' position='insideBottom' style={{textAnchor: 'middle'}} />
                                        </XAxis>
                                        <YAxis >
                                            <Label angle={-90} value='Amount' position='insideLeft' style={{textAnchor: 'middle'}} />
                                        </YAxis>
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="earnings" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
                                        <Line type="monotone" dataKey="expenses" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
                                    </LineChart>
                                </center>
                                :
                                <Segment style={{width:'100%'}}>
                                    <Dimmer active>
                                    <Loader size="medium">Loading</Loader>
                                    </Dimmer>

                                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                </Segment>
                            }
                            </Grid.Column>
                            {/* <Grid.Column width={7} className="no-padding">
                                <Dropdown
                                    style={{
                                        minWidth:0,
                                        minHeight:0
                                    }}
                                    selection
                                    search
                                    name={'ledger'}
                                    value={this.state.data_rows_filter.ledger[0]}
                                    options={this.state.options_ledger}
                                    onChange={(e, data) => this.setFilter(e, data)}
                                    placeholder={'Ledger'}
                                />
                                <Table color="blue" key="blue" celled compact unstackable>
                                    <Table.Header>
                                        <Table.Row>
                                            {
                                                this.state.tableFields.map((field) => (
                                                    <Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
                                                        {field.text}
                                                        {
                                                            this.state.data_rows_sort_order == "asc" ?
                                                                <Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                            :
                                                                <Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
                                                        }
                                                    </Table.HeaderCell>
                                                ))
                                            }
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                        {
                                            this.state.data_rows_table.map((data_row, index) => (
                                                <Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
                                                {
                                                    this.state.tableFields.map((field) => (
                                                        <Table.Cell style={{backgroundColor: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'red' : 'white' : 'white'),
                                                            color: (field.value == 'due_by' ? data_row[field.value] >= 30 ? 'white' : 'black' : 'black')}}>
                                                            {field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}
                                                        </Table.Cell>
                                                    ))
                                                }
                                                </Table.Row>
                                            ))
                                        }
                                        </Table.Body>
                                    </Table>
                            </Grid.Column> */}
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </Card>
        )
    }
}
export default DashboardEarning