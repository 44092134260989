import React, {Component} from 'react';
import $ from 'jquery';
import Client from '../services/Client';
import  AccountsEntry from '../components/AccountsEntry';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react'
import TopBarInside from "../components/topbar-inside";

import '../style/vendors.css';

class AccountsCash extends Component{

    constructor(props) {
        super(props);

        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
        var date = yyyy + "-" + mm + "-" + dd;

        this.state = {
            fetching: true,
            cities: ["Silchar", "Guwahati", "Tezpur"],
            hubs: ["CENTRAL", "SILCHAR", "GUWAHATI", "TEZPUR"],
            paymentModes: ["CASH", "PAYTM", "RAZORPAY", "BANK SBI", "BANK SYND"],
            entryType: ["EXPENSES", "RENT", "OFFICE", "TANGIBLE", "TECH", "FUND IN"],
            entryType1: ["RECEIPT"],
            entryType2: ["PAYMENT", "COMMISION"],
            entries: [],
            paymentBy: [],
            paymentTo: [],
            city: localStorage.getItem('city'),
            partner: '',
            startDate: '',
            endDate: date,
            morePage: 1,
            page: 0,
            lastRequest: '',
            entry: {
                id: '',
                mode: 'BANK SBI',
                type: 'EXPENSES',
                partner: '',
                partnerName: '',
                partnerId: '',
                amount: '',
                date: date,
                hub: 'CENTRAL',
                comments: '',
                dr: '',
                cr: ''
            }
        };
    }

    handleCityChange = (e, data) => {
        localStorage.setItem('city',data.value);
        var payTos = [];
        this.fetchBusinesses(data.value)
        this.setState({
            city: data.value
        })
    };

    componentDidMount(){
        var payTos = [];
        this.fetchBusinesses("Silchar");
    }

    handleScrollDiv(event){
        if(this.state.morePage == 1 && this.state.lastRequest != this.state.page){
            if ($("#entriesContainer").scrollTop() + $("#entriesContainer").height()
            + 200 >= $("#entriesInnerContainer").height()){
                this.setState({
                    lastRequest:this.state.page,
                    loading: 1
                })
                this.fetchAccounts(this.state.city, this.state.partnerSelected, this.state.startDate, this.state.endDate, this.state.page);
            }
        }
    }

    handleInputChange = (e) => {
        var v = this.state.entry;
        v[e.target.name] = e.target.value;
        this.setState({
            entry: v
        })
    };

    handleStartDateChange = (e) => {
        // var t = e.target.value.split("/");
        // console.log(e.target.value);
        // console.log(t);
        // var date = t[2] + "-" + t[1] + "-" + t[0];
        // var v = this.state.entry;
        // v[e.target.name] = e.target.value;
        this.setState({
            startDate: e.target.value
        })
        this.fetchAccounts(this.state.city, this.state.partner, e.target.value, this.state.endDate, 0);
    };

    handleEndDateChange = (e) => {
        this.setState({
            endDate: e.target.value
        })
        this.fetchAccounts(this.state.city, this.state.partner, this.state.startDate, e.target.value, 0);
    };

    handlePartnerChange = (e) => {
        this.fetchAccounts(this.state.city, e.target.value, this.state.startDate, this.state.endDate, 0);

        this.setState({
            partner: e.target.value
        })
    };

    handleAddEntry = () => {
        Client.createCBEntry(this.state.entry).then(
            (res) => {
                if(res.responseResult === "success"){
                    var x = this.state.entry;
                    if(x.type === "TRANSFER"){
                        x.id = res.addedEntryId;
                        x.partnerId = x.partner;
                        x.partner = x.partnerName;
                        if(this.state.entryType1.includes(x.type)){
                            x.dr = x.amount;
                            x.cr = 0;
                        }
                        else{
                            x.dr = 0;
                            x.cr = x.amount;
                        }
                        this.setState({
                            entries: [
                                x, ...this.state.entries
                            ],
                            entry:{
                                id: '',
                                amount: '',
                                comments: '',
                                dr: '',
                                cr: ''
                            }
                        })
                    }
                    else{
                        this.fetchAccounts(this.state.city, "All", this.state.startDate, this.state.endDate, 0);
                    }
                }
            }
        );
    };

    fetchAccounts = (city, xyz, startDate, endDate, page) => {
        Client.getAccountsCash(city, startDate, endDate, xyz, page)
        .then((res)=>{
            var ent = [];
            if(page != 0){
                var ent = this.state.entries;
            }
            this.setState({
                fetching: false,
                entries: [...ent, ...res.entries],
                receipt: res.receipt,
                commision: res.commision,
                payment: res.payment,
                morePage: res.morePage,
                partnerSelected: xyz,
                page: parseInt(res.page) + 1,
                loading: 0
            })
        })
    }

    fetchBusinesses = (xyz) => {
        var payTos = [];
        Client.getBusinesses("All", xyz, null)
        .then((res)=> {
            res.businessesList.forEach(function (business){
                var pp = {};
                pp.id = business.storeId
                pp.name = business.storeName
                payTos = [...payTos, pp];
            })
            var x = this.state.entry;
            x.partner = res.businessesList[0].storeId;
            this.setState({
                paymentTo: payTos,
                fetching:false,
                loading: 0,
                entry: x
            })
        })

        this.fetchAccounts(this.state.city, "All", this.state.startDate, this.state.endDate, 0);
    }

    render(){
        const entryTypes = this.state.entryType.map((type)=>{
            return(
                <option className="item"  key={type} value={type}>{type}</option>
            )
        });
        const cities = this.state.cities.map((type)=>{
            return(
                <option className="item"  key={type} value={type}>{type}</option>
            )
        });
        const hubs = this.state.hubs.map((type)=>{
            return(
                <option className="item"  key={type} value={type}>{type}</option>
            )
        });
        const paymentModes = this.state.paymentModes.map((type)=>{
            return(
                <option className="item"  key={type} value={type}>{type}</option>
            )
        });
        const paymentTo = this.state.paymentTo.map((type)=>{
            return(
                <option className="item"  key={type.id} value={type.id}>{type.name}</option>
            )
        });
        return(
            <div>
                <TopBarInside cities={JSON.parse(localStorage.getItem('cities'))} handleCityChange={this.handleCityChange.bind(this)}/>
                <div className="ui compact menu">
                    <select selectedValue={this.state.paymentTo[0]} onChange={this.handlePartnerChange.bind(this)}>
                        <option className="item" key="All" value="">All</option>
                        {paymentTo}
                    </select>
                    Start:<input value={this.state.startDate} key="startDate" name="startDate" type="date" onChange={this.handleStartDateChange.bind(this)}></input>
                    End:<input value={this.state.endDate} key="endDate" name="endDate" type="date" onChange={this.handleEndDateChange.bind(this)}></input>
                </div>
                <br/>
                <p style={{fontWeight:'bold', paddingTop:'5px', paddingBottom:'5px', margin:'0'}}>
                    Create Entry:
                </p>
                <div className="section" style={{padding:'15px'}}>
                    <div className="ui grid">
                        <select className="two wide computer four wide mobile column" value={this.state.entry.hub} name="hub" onChange={this.handleInputChange.bind(this)}>
                            {hubs}
                        </select>
                        <select className="two wide computer four wide mobile column" value={this.state.entry.type} name="type" onChange={this.handleInputChange.bind(this)}>
                            {entryTypes}
                        </select>
                        <select className="two wide computer four wide mobile column" value={this.state.entry.mode} name="mode" onChange={this.handleInputChange.bind(this)}>
                            {paymentModes}
                        </select>
                        <input className="three wide computer four wide mobile column" value={this.state.entry.date} key="date" name="date" type="date" onChange={this.handleInputChange.bind(this)}></input>
                        <input className="two wide computer four wide mobile column" placeholder="Amount" value={this.state.entry.amount} key="amount" name="amount" type="number" onChange={this.handleInputChange.bind(this)}></input>
                        <input className="two wide computer four wide mobile column" placeholder="Comments" value={this.state.entry.comments} key="comments" name="comments" type="text" onChange={this.handleInputChange.bind(this)}></input>
                        <div className="ui button" onClick={this.handleAddEntry}>Add</div>
                    </div>
                </div>

                <br/>
                <div style={{height:'20px', paddingLeft:'30px', paddingRight:'30px'}}>
                    <strong>RECEIVED:</strong>
                    <div style={{float:'right'}}>{this.state.receipt}</div>
                </div>
                <div style={{height:'20px', paddingLeft:'30px', paddingRight:'30px'}}>
                    <strong>PAID:</strong>
                    <div style={{float:'right'}}>{this.state.payment}</div>
                </div>
                <div style={{height:'20px', paddingLeft:'30px', paddingRight:'30px'}}>
                    <strong>BALANCE:</strong>
                    <div style={{float:'right'}}>{this.state.receipt - this.state.payment}</div>
                </div>

                <h4 className="ui dividing header">Entries</h4>
                <div className="section" style={{paddingLeft:'15px', paddingRight:'15px'}}>
                    <div className="ui grid">
                        <div className="three wide column"><strong>HUB</strong></div>
                        <div className="three wide column"><strong>TYPE</strong></div>
                        <div className="three wide column"><strong>MODE</strong></div>
                        <div className="two wide column"><strong>DATE</strong></div>
                        <div className="three wide column"><strong>PARTNER</strong></div>
                        <div className="one wide column"><strong>DR</strong></div>
                        <div className="one wide column"><strong>CR</strong></div>
                    </div>
                </div>

                <div className="container" onScroll={this.handleScrollDiv.bind(this)} style={{overflow:"auto", height:"250px"}} id="entriesContainer">
                    <div id="entriesInnerContainer">
                    {
                        this.state.entries
                        ?
                        this.state.entries.map((item, key)=>(
                            <AccountsEntry entry={item} />
                        ))
                        :
                        null
                    }
                    </div>
                    {
                        this.state.loading === 1
                        ?
                        <Loader active />
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}
export default AccountsCash;
