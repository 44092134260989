import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Image, Label, Segment, Checkbox, Message, Loader, Dimmer} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

export default class PurchaseOrderView extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "ledger", "text": "Vendor", "type": "select"},
				{"value": "voucher_id", "text": "Invoice Id", "type": "field"},
				{"value": "order_id", "text": "Order Id", "type": "field"},
				{"value": "payable_amount", "text": "Amount", "type": "field"},
				{"value": "status", "text": "Status", "type": "select"},
			],
			defaultSize: 'small',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'ledger',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			addToInvoiceProductDetails: {products: []},
			options_ledger: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
			options_status:[
				{text: 'DUE', value: 'due'},
				{text: 'PAID', value: 'paid'}
			]
		}
    }
    componentDidMount(){
        if (this.props.data_row) {
            this.showDataRowDetails(0,false,)
        }
    }
    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }
    showDataRowDetails(index, print, heading){
		if(index == undefined || index == null)
			index = this.state.currentDataRowIndex || 0

		var purchase ;
        if (this.props.data_row) {
            purchase = this.props.data_row
        } else {
            purchase = {...this.state.data_rows[index]};
        }
		var taxDetails = this.calculateTax(purchase)

		this.setState({
			currentDataRowIndex: index,
			currentDataRow: purchase
		})

		var taxDetailsView = '';
		taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>' +
												'<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount) + '</td></tr>'
			}
		}.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
						'<th>Amount</th></tr>'
		}

		purchase.products.forEach(function(product, index) {
			if(taxDetails.length > 0){
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
								'</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td><td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
							'</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						${heading || "PURCHASE ORDER"}
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Invoice Number</td>
						<td><b>: ${purchase.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Invoice Date</td>
						<td><b>: ${purchase.date.substring(8, 10) + '/' + purchase.date.substring(5, 7) + '/' + purchase.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				PURCHASE FROM
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">
				${purchase.vendor_details.name}
				${
					(purchase.vendor_details.branchSuffix && purchase.vendor_details.branchSuffix.trim() !="" && purchase.vendor_details.branchSuffix != null)
					?
					   "("+purchase.vendor_details.branchSuffix.trim()+")"
					:
					""
			   }
				</p>
				<p style="padding:0; margin:0">
					${(purchase.vendor_details.address && purchase.vendor_details.address.trim() != "") ? purchase.vendor_details.address + '<br/>' : ''}
					${(purchase.vendor_details.GSTIN && purchase.vendor_details.GSTIN.trim() != "") ? "GSTIN: " + purchase.vendor_details.GSTIN : ''}
				</p>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				REMARKS
			</div>
			<div style="padding:5px 5px 5px 5px;">
					${purchase.remarks}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px">
						<div style="float:right; padding:2px 5px 0 0">E. & O.E.</div>
						Total in Words:<br/>
						<b>Rupees ${converter.toWords(purchase.payable_amount)}</b>
						<br/>
						${purchase.remarks != undefined && purchase.remarks != '' ? purchase.remarks.replace(/\n/g, "<br />") : ""}
						<br/>
						<br/>
						Hope to serve you again soon.
						<br/>
						<br/>
						<b>CHEQUE to be made in favour of SCIENCE2HOME PVT. LTD.</b><br/>
						Company's Bank Details:<br/>
						<b>Acc No: 39010200000238</b><br/>
						<b>IFSC Code: BARB0AMBARI‎</b><br/>
						<b>Bank Of Baroda</b><br/>
						Ambari, Guwahati

						<div style="text-align:center; padding:2px 0px 5px 0">SUBJECT TO GUWAHATI JURISDICTION</div>
					</td>
					<td style="border-left:solid 1px #999; padding:0">
						<div style="width:100%; margin:0; padding:5px 0 5px 0px">
							<table style="width:100%">
								<tr><td>Sub Total</td><td style="text-align:right">${parseFloat(Math.round(purchase.taxable_amount * 100) / 100).toFixed(2)}</td></tr>
								${taxDetailsView}`
								if(purchase.adjustment_name != null && purchase.adjustment_name != '')
									invoiceHtml = invoiceHtml +
									`<tr><td>${purchase.adjustment_name}</td><td style="text-align:right">${purchase.adjustment_amount}</td></tr>`
								invoiceHtml = invoiceHtml +
								`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(purchase.round_off * 100) / 100).toFixed(2)}</td></tr>
								<tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(purchase.payable_amount * 100) / 100).toFixed(2)}</td></tr>
							</table>
						</div>
						<div style="text-align:center; border-top:solid 1px #999; width:100%">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		this.setState({
			showPurchaseDetailsModal: true
		})

		setTimeout(function(){
			document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		}, 500);

		if(print){
			var printwindow = window.open('','dhp_inv_purchase', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = purchase.voucher_id;
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		}
	}

	printDeliveryNote(print){

		var index = this.state.currentDataRowIndex
		        var purchase ;
        if (this.props.data_row) {
            purchase = this.props.data_row
        } else {
            purchase = {...this.state.data_rows[index]};
        }
		var taxDetails = this.calculateTax(purchase)

		var taxDetailsView = '';

		var productsTable =
		'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th></tr>'
		purchase.products.forEach(function(product, index) {
			productsTable = productsTable +
			'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
							(product.description != null ? product.description : '') + '</td><td style="text-align:center">' + product.qty +
							'</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						DELIVERY NOTE
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Reference Number</td>
						<td><b>: ${purchase.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Date</td>
						<td><b>: ${purchase.date.substring(8, 10) + '/' + purchase.date.substring(5, 7) + '/' + purchase.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Delivery To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">${purchase.vendor_details.name}</p>
				<p style="padding:0; margin:0">
					${(purchase.vendor_details.address && purchase.vendor_details.address.trim() != "") ? purchase.vendor_details.address + '<br/>' : ''}
					${(purchase.vendor_details.GSTIN && purchase.vendor_details.GSTIN.trim() != "") ? "GSTIN: " + purchase.vendor_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
						<br/>
						Hope to serve you again soon.
						<br/>
					</td>
					<td style="border-left:solid 1px #999; padding:0; text-align:center">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		// document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		// document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

		// if(print){
			var printwindow = window.open('','dhp_inv_delivery_note', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = purchase.voucher_id + "_DELIVERY_NOTE";
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		// }
	}

	calculateTax(purchase){
		var purchase = purchase;
		var taxDetails = [];
		var subTotal = 0;
		var total = 0;
		var taxStateType = purchase.vendor_details.pos == "AS" ? 0 : 1;
		purchase.products.forEach(function(element) {
			var taxDetail = {};
			var found = 0
			if(element.tax != 'N/A'){
				taxDetails.forEach(function(elem) {
					if(elem.taxPc == element.tax){
						found = 1;
						elem.taxStateType = taxStateType;
						elem.taxable_amount = Number(elem.taxable_amount) + (element.qty * element.rate)
						elem.tax_amount = elem.taxable_amount *  elem.taxPc / 100;
						elem.taxable_amount = parseFloat(Math.round(elem.taxable_amount * 100) / 100).toFixed(2);
						elem.tax_amount = parseFloat(Math.round(elem.tax_amount * 100) / 100).toFixed(2);
					}
				})
				if(found == 0){
					taxDetail['taxStateType'] = taxStateType;
					taxDetail['taxPc'] = element.tax;
					taxDetail['taxable_amount'] = element.qty * element.rate;
					taxDetail['taxable_amount'] = parseFloat(Math.round(taxDetail['taxable_amount'] * 100) / 100).toFixed(2);
					taxDetail['tax_amount'] = (element.qty * element.rate) * element.tax / 100;
					taxDetail['tax_amount'] = parseFloat(Math.round(taxDetail['tax_amount'] * 100) / 100).toFixed(2);
					taxDetails.push(taxDetail)
				}
			}
			subTotal = subTotal + (element.qty * element.rate);
		});

		taxDetails.forEach(function(elem) {
			total = total + Number(elem.tax_amount);
		});

		// purchase.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
		// purchase.total = Number(total) + Number(subTotal);
		// purchase.total = parseFloat(Math.round(purchase.total * 100) / 100).toFixed(2);
		// purchase.roundOff = Math.round(purchase.total) - Number(purchase.total);
		// purchase.roundOff = parseFloat(Math.round(purchase.roundOff * 100) / 100).toFixed(2);
		// purchase.payableAmount = Math.round(purchase.total);
		// if(purchase.adjustmentName != undefined && purchase.adjustmentName != '')
		// 	purchase.payableAmount = Number(purchase.payableAmount) + Number(purchase.adjustmentAmount || 0);
		// purchase.payableAmount = parseFloat(Math.round(purchase.payableAmount * 100) / 100).toFixed(2);

		return taxDetails
	}

	editPurchase(){
		localStorage.setItem('currentPurchaseOrder', JSON.stringify(this.state.currentDataRow));
		localStorage.setItem('currentPurchaseOrderEdit', 1);
		this.props.history.push('/accounting/vouchers/purchase-order/create')
	}

	triggerAddToInvoice(){
		this.setState({
			loadingCorrespondingInvoice: true,
			showPurchaseDetailsModal: false,
			showAddToInvoice: true,
			addToInvoiceProductDetails: {products: [...this.state.currentDataRow.products]}
		})
		Client.getAccountingSale(null, null, null, null, 'order_id', 'field', this.state.currentDataRow.order_id, undefined).then(res=>{
			this.setState({
				correspondingOrderInvoiceDetails: res.data_rows.length > 0 ? res.data_rows[0] : undefined,
				correspondingOrderInvoice: res.data_rows.length > 0 ? res.data_rows[0].voucher_id : undefined,
				loadingCorrespondingInvoice: false,
			})
		})
	}

	handleAddToInvoiceProductChange(e, data, index){
		var addToInvoiceProductDetails = {...this.state.addToInvoiceProductDetails}
		if(data.name == 'product'){
			var products = [...this.state.addToInvoiceProductDetails.products]
			if(index == -1){
				products.forEach(function(product){
					product['add'] = data.checked ? 1 : 0
					addToInvoiceProductDetails['products'] = products
				})
			}
			else{
				var product = {...products[index]}
				product['add'] = data.checked ? 1 : 0
				products[index] = product
				addToInvoiceProductDetails['products'] = products
			}
		}
		else{
			addToInvoiceProductDetails[data.name] = data.value || e.target.value
		}
        this.setState({
			addToInvoiceProductDetails: addToInvoiceProductDetails,
			addToInvoiceProductAllChecked: (index == -1 ? (data.checked ? 1 : 0) : 0)
		})
	}

	addProductsToInvoice(){
		this.setState({
			loadingProductsToInvoice: true
		})
		Client.addProductsToInvoice(this.state.correspondingOrderInvoice, this.state.currentDataRow.id,
			this.state.currentDataRow.order_id, this.state.addToInvoiceProductDetails).then((res)=>{
			this.triggerAddToInvoice();
			this.setState({
				messageForAddProducts: "PRODUCTS ADDED. PLEASE CHECK INVOICE AND RE-EDIT!!",
				loadingProductsToInvoice: false
			})
		})
	}
    render(){
        return <>
                <div>
                    <Button size={this.state.defaultSize} onClick={() => this.editPurchase()}><Icon name='edit'/>EDIT</Button>
                    <Button.Group size={this.state.defaultSize}>
                        <Button size={this.state.defaultSize} onClick={() => this.showDataRowDetails(null, 1)}>
                            <Icon name='print'/> PRINT
                        </Button>
                    </Button.Group>
                    <Button size={this.state.defaultSize} onClick={() => this.triggerAddToInvoice()}>
                        ADD TO INVOICE
                    </Button>
                </div>
                <div>

                    <div id="showInvoiceContainer" >
                    </div>
                </div>
                <Modal open={this.state.showAddToInvoice} onClose={() => this.setState({showAddToInvoice:false, messageForAddProducts: undefined})}>
					<Modal.Content scrolling>
						<Message>
							<Message.Header>Corresponding Invoice Details</Message.Header>
							<p>
								{
									this.state.correspondingOrderInvoice || this.state.loadingCorrespondingInvoice
									?
									<div>
										<Segment style={{display: this.state.loadingCorrespondingInvoice ? 'block' : 'none'}}>
											<Dimmer active>
												<Loader />
											</Dimmer>
											<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
										</Segment>
										{this.state.messageForAddProducts}
										Invoice ID: <b>{this.state.correspondingOrderInvoice}</b>
									</div>
									:
									<div>
										"NO INVOICE Found. NEW INVOICE WILL BE GENERATED Automatically"
										<br/>
										<div style={{display:'flex'}}>
											<Form.Field>
												<Form.Input label="Invoice Date" type='date' size={this.state.defaultSize} name="date"
												onChange={(e, data) => this.handleAddToInvoiceProductChange(e, data)}/>
											</Form.Field>
											<Form.Field>
												<Form.Input label="Conversion margin (%)" name="conversion_margin" size={this.state.defaultSize}
												onChange={(e, data) => this.handleAddToInvoiceProductChange(e, data)}/>
											</Form.Field>
										</div>
									</div>
								}
							</p>
						</Message>
						<Table celled compact className="accounting-invoice-product-table">
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell compact>
										<Checkbox toggle checked={this.state.addToInvoiceProductAllChecked}
										 onChange={(e, data) => this.handleAddToInvoiceProductChange(e, {...data, name:'product'}, -1)}/>
									</Table.HeaderCell>
									<Table.HeaderCell>PRODUCT NAME</Table.HeaderCell>
									<Table.HeaderCell>QUANTITY</Table.HeaderCell>
									<Table.HeaderCell>RATE (NON GST)</Table.HeaderCell>
									<Table.HeaderCell>RATE (GST)</Table.HeaderCell>
									<Table.HeaderCell>TAX %</Table.HeaderCell>
									<Table.HeaderCell>TOTAL</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
							{
								this.state.addToInvoiceProductDetails.products.map((record, index) => (
									<Table.Row>
										<Table.Cell compact>
											<Checkbox toggle checked={record.add}
											onChange={(e, data) => this.handleAddToInvoiceProductChange(e, {...data, name:'product'}, index)}/>
										</Table.Cell>
										<Table.Cell>
										{record.name}
										</Table.Cell>
										<Table.Cell>
										{record.qty}
										</Table.Cell>
										<Table.Cell>
										{record.rate}
										</Table.Cell>
										<Table.Cell>
										{record.rate}
										</Table.Cell>
										<Table.Cell>
										{record.tax}
										</Table.Cell>
										<Table.Cell>
										{(record.rate * record.qty).toFixed(2)}
										</Table.Cell>
									</Table.Row>
								))
							}
							</Table.Body>
							<Table.Footer>
								<Button primary onClick={() => this.addProductsToInvoice()} style={{display: this.state.messageForAddProducts != undefined ? 'none' : 'block'}}
								loading={this.state.loadingProductsToInvoice}>
									ADD TO INVOICE
								</Button>
							</Table.Footer>
						</Table>
					</Modal.Content>
				</Modal>
        </>
    }
}
