import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Image, Label} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class ContraView extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "voucher_id", "text": "Voucher Id", "type": "field"},
				{"value": "ledger", "text": "Account", "type": "select"},
				{"value": "to_ledger", "text": "TO / FROM", "type": "select"},
				{"value": "amount", "text": "Amount", "type": "field"},
			],
			defaultSize: 'mini',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'voucher_id',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50},
				{text: 100, value: 100},
				{text: 150, value: 150},
				{text:200, value: 200},
				{text: 250, value: 250},
				{text: 500, value: 500},
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_to_ledger: [],
		}
	}

	componentDidMount() {
		var banks = [7,10];
		var options_voucher_id = [];
		var options_ledger = []
		var options_record_ledgers = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				if(banks.includes(data_row.group))
					options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
				options_record_ledgers: options_ledger,
				options_to_ledger: options_ledger
			})
		})
		Client.getNewContraIds().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_voucher_id.push({
					text: data_row,
					value: data_row
				})
			})
			this.setState({
				options_voucher_id: options_voucher_id
			})
        })
        if (this.props.new_data_row) {
            this.setState({new_data_row:this.props.new_data_row,editMode:this.props.editMode || false})
        }
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		// if (data.name == "data_rows_search_field" && data.value == "amount") {
		// 	state[data.name] = "payable_amount"
		// }
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows({
			...state,
			data_rows_search_field:state.data_rows_search_field == "amount"
				?
				"payable_amount"
				:
				state.data_rows_search_field
		});
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createAccountingContra(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {records: [{}]}
			})
			this.props.updated()
		})
	}

	getDataRows(state){
		Client.getAccountingContra(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}

	//CUSTOM FUNCTIONS
	changeStateVariableRecord(e, data, index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = this.state.new_data_row;
		records[index][data.name] = data.value || e.target.value
		new_data_row.records = [...records]
		this.setState({
			new_data_row: new_data_row
		})
	}

	addRecord(e){
		e.preventDefault();
		var records = this.state.new_data_row.records;
		var new_data_row = this.state.new_data_row;
		new_data_row.records = [...records, {}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-receipt-record-ledger' + (new_data_row.records.length - 1)).getElementsByTagName('input')[0].focus()
		},300)
	}

	removeRecord(index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = {...this.state.new_data_row};
		var records1 = []
		records.forEach(function(element, index1) {
			if(index1 != index){
				records1.push(element)
			}
		});
		new_data_row.records = [...records1]
		this.setState({
			new_data_row: new_data_row
		})
	}

	makeCalculations(){
		var new_data_row = {...this.state.new_data_row}
		var records_total = 0;
		new_data_row.records.map(function(record){
			records_total = records_total + parseFloat(record.amount)
		})
		new_data_row.records_total = records_total
		this.setState({
			new_data_row: new_data_row
		})
	}

	handleVoucherIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
	}

	render(){
		return(<Modal.Content scrolling>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Select options={
									this.state.editMode?
										[...this.state.options_voucher_id,
										{text:this.state.new_data_row.voucher_id,value:this.state.new_data_row.voucher_id}]
										:this.state.options_voucher_id
									}
									allowAdditions
									search
									onAddItem={(e, value) => this.handleVoucherIdAdditions(e, value)}
									type="text"
									name="voucher_id"
									placeholder="Invoice #" label="Voucher #" selection value={this.state.new_data_row.voucher_id}
									onChange ={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									required/>
								<Form.Input type="date" required fluid name="date" value={this.state.new_data_row.date} label="Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select selection search options={this.state.options_ledger} type="text" required fluid name="ledger" value={this.state.new_data_row.ledger} label="Account" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="number" step=".01" fluid required name="amount" value={this.state.new_data_row.amount} label="Amount" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Table celled compact className="accounting-invoice-product-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>LEDGER</Table.HeaderCell>
										<Table.HeaderCell>AMOUNT</Table.HeaderCell>
										<Table.HeaderCell></Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
								{
									this.state.new_data_row.records.map((record, index) => (
										<Table.Row>
											<Table.Cell>
												<Form.Select id={"accounting-receipt-record-ledger" + index} selection search options={this.state.options_record_ledgers}
												type="text" required fluid name="ledger" value={record.ledger} placeholder="Ledger" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell>
												<Form.Input type="number" step=".01" fluid required name="amount" value={record.amount} placeholder="Amount" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell collapsing>
												<Icon circular name='delete' size='large' onClick={() => this.removeRecord(index)} color="red"/>
											</Table.Cell>
										</Table.Row>
									))
								}
								</Table.Body>
								<Table.Footer fullWidth>
									<Table.Row>
										<Table.HeaderCell colSpan='4'>
											<Button secondary onClick={(e) => this.addRecord(e)}>Add Record</Button>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
							<Form.Input type="text" fluid name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
        )
	}
}

export default ContraView;
