import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, TextArea,Dimmer,Loader,Image,Segment, Form} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import Inventory from "./inventory";

class CreateAccountingPurchase extends Component{

	constructor(){
		super();
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
		    dd = '0'+dd
		}
		if(mm<10) {
		    mm = '0'+mm
		}
		today = yyyy + '-' + mm + '-' + dd;
		this.state = {
			new_data_row: {
				voucher_id: "",
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A'}],
				taxable_amount: 0,
				round_off: 0,
				payable_amount: 0,
				order_id: null,
				date: today,
				terms: 'Terms:\n* Payment: Within 15 days from the date of delivery / service.\n* Freight Charges: Extra as actual.\n* Quotation Validity: 15 days. Rates may vary thereafter.',
				contact_person: 'Name: Deskneed (Science2home Pvt. Ltd.).\nPhone: +91-9954683549.\nEmail: contact@deskneed.com.',
			},
			taxDetails: [],
			options_vendor: [],
			options_voucher_id: [],
			vendor: {name:''},
			options_products: [{productId: 0, name: 'CREATE NEW', text:'CREATE NEW', value:0}],
			createProduct: false,
			options_tax:[
				{text: '0 %', value: '0.00'},
				{text: '5 %', value: '5.00'},
				{text: '12 %', value: '12.00'},
				{text: '18 %', value: '18.00'},
				{text: '28 %', value: '28.00'},
				{text: 'N/A', value: 'N/A'}]
		}
	}

	componentDidMount() {
		let order_id = localStorage.getItem('/accounting/vouchers/purchase/create')
		if(order_id){
			this.handleInputChangeSale(null, {name:'order_id', value:order_id})
			localStorage.removeItem('/accounting/vouchers/purchase/create')
		}
		// this.loadCustomers()
		var options_vendor = []
		var options_voucher_id = []

		Client.getAccountingMastersLedger(null,null,null,null,null,null,null,{group:[151,152]}).then(res=>{
			res.data_rows.forEach(function(data_row){
				options_vendor.push({
					text: data_row.ledger_name,
					value: data_row.id
				})
			})
			this.setState({
				options_vendor: options_vendor
			})
		})
		Client.getNewPurchaseIds().then(res=>{
			if(localStorage.getItem('currentPurchaseEdit') == 1){
				options_voucher_id.push({
					text: this.state.new_data_row.voucher_id,
					value: this.state.new_data_row.voucher_id
				})
			}
			res.data_rows.forEach(function(data_row){
				options_voucher_id.push({
					text: data_row,
					value: data_row
				})
			})
			this.setState({
				options_voucher_id: options_voucher_id
			})
		})

		if(localStorage.getItem('currentPurchase') && localStorage.getItem('currentPurchaseEdit') == 1){
			var new_data_row = JSON.parse(localStorage.getItem('currentPurchase'));
			new_data_row.products.forEach(function(product,i) {
				product.options_product = [{text: product.name, value: product.product_id}]
				var options_description = [];
				product.options_description.forEach(function(description){
					options_description.push({
						text: description,
						value: description
					})
				})
				product.options_description = [{text:'Empty',value:undefined},...options_description]
			})
			this.getCustomerBranches(new_data_row.customer);
			this.setState({
				new_data_row: new_data_row
			})

			this.calculateTax()
		}
	}

	componentWillUnmount(){
		localStorage.setItem('currentPurchaseEdit', 0);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	addProduct(e){
		e.preventDefault();
		var products = this.state.new_data_row.products;
		var new_data_row = this.state.new_data_row;
		new_data_row.products = [...products, {id:products.length, qty: 0, rate: 0, tax: 'N/A'}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-invoice-product-input-name' + (new_data_row.products.length - 1)).getElementsByTagName('input')[0].focus()
	    },300)
	}

	removeProduct(id){
		alert(id)
		var products = [...this.state.new_data_row.products];
		var new_data_row = {...this.state.new_data_row};
		var products1 = []
		var found = 0;
		products.forEach(function(element, index) {
			if(index != id){
				products1.push(element)
			}
			else
				found = 1
		});
		new_data_row.products = [...products1]
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}
	// loadCustomers(){
	// 	var options_record_ledgers = [];
	// 	Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
	// 		res.data_rows.forEach(function(data_row) {
	// 			options_record_ledgers.push({text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''), value: data_row.id})
	// 		})
	// 		this.setState({
	// 			options_customer: options_record_ledgers,
	// 		})
	// 	})
	// }
	handleInputChangeSale(e, data){
		var new_data_row = this.state.new_data_row;
		// if (data.name == 'order_id') {
		// 	this.setState({loading_form_order_id:true})
		// 	Client.getOrderSlips('get',null,null,null,null,null,null,null,{slip_no:[data.value]})
		// 	.then(res=>{
		// 		if (res.data_rows_size > 0) {
		// 			new_data_row.branch = res.data_rows[0].branch?res.data_rows[0].branch:''
		// 			new_data_row.customer = res.data_rows[0].customer?res.data_rows[0].customer:''
		// 			new_data_row.showBranch =res.data_rows[0].customer?1:0
		// 			Client.getBranchesOfCustomer(new_data_row.customer).then((res)=>{
		// 				let options_branch = []
		// 				if (res.data_rows_size > 0) {
		// 					res.data_rows.forEach(element => {
		// 						let temp = {
		// 							value : element.id,
		// 							text : element.branch_name
		// 						}
		// 						options_branch.push(temp)
		// 					})
		// 					new_data_row.showBranch =1
		// 					new_data_row.loading_branch = 0
		// 					this.setState({
		// 						options_branch:options_branch,
		// 						new_data_row,
		// 					})
		// 				} else {
		// 					new_data_row.showBranch = 0
		// 					new_data_row.loading_branch = 0

		// 				}
		// 			})
		// 		} else {
		// 			new_data_row.branch = ''
		// 			new_data_row.customer = ''
		// 			new_data_row.showBranch = 0
		// 		}
		// 		this.setState({new_data_row,loading_form_order_id:false})
		// 	})
		// }
		new_data_row[data.name] = data.value || e.target.value
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}
	handleProductDescriptionAddition(index,e,data){
		var new_data_row = this.state.new_data_row;
		let products = new_data_row.products
		products[index].options_description.push({text:data.value,value:data.value})
		new_data_row['products']= products
		this.setState({new_data_row})
	}

	getCustomerBranches(customer){
		this.setState({
			showBranch: true,
			loading_branches: true
		})
		Client.getBranchesOfCustomer(customer).then((res)=>{
			let options_branch = []
			if (res.data_rows_size > 0) {
				res.data_rows.forEach(element => {
					let temp = {
						value: element.id,
						text: element.branch_name
					}
					options_branch.push(temp)
				})
				this.setState({
					options_branch: options_branch,
					loading_branches: false
				})
			} else {
				this.setState({
					showBranch: false,
					loading_branches: false
				})
			}
		})
	}
	handleInputChangeProduct(index, e, data){
		var value = data.value || e.target.value;
		var products = this.state.new_data_row.products;
		var purchase = this.state.new_data_row;
		var options_product = [...products[index].options_product]
		if(data.name == "product_id"){
			products[index]['product_id'] = data.value
			products[index]['qty'] = 1
			for(var i = 0; i < options_product.length; i++){
				var prod = options_product[i]
				if(prod.product_id == data.value){
					products[index]['name'] = prod.name
					products[index]['rate'] = prod.rate
					products[index]['tax'] = prod.tax
					products[index]['HSN'] = prod.HSN
					products[index]['options_description'] = [{text:'Empty',value:undefined}, ...prod.options_description.map(description=>{return{text:description,value:description}})]
					break;
				}
			}
			// this.setState({
				// 	purchase: purchase
				// })
				this.calculateTax();
		}
		else{
			products[index][data.name] = value
		}
		purchase.products = [...products]
		this.setState({
			purchase: purchase
		})

		this.calculateTax()
	}

	handleProductAddition(index, e, { value }) {
		this.setState({
			createProduct: true,
			indexForNewProduct: index,
		})
	}

	handleInvoiceIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
	}

	calculateTax(){
		setTimeout(() => {
			var sale = this.state.new_data_row;
			var taxDetails = [];
			var taxable_amount = 0;
			var total = 0;
			var taxStateType = this.state.vendor.pos == "AS" ? 0 : 1;
			this.state.new_data_row.products.forEach(function(element) {
				var taxDetail = {};
				var found = 0
				if(element.tax != 'N/A'){
					taxDetails.forEach(function(elem) {
						if(elem.taxPc == element.tax){
							found = 1;
							elem.taxStateType = taxStateType;
							elem.taxableAmount = Number(elem.taxableAmount) + (element.qty * element.rate)
							elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
							elem.taxAmount = elem.taxableAmount * elem.taxPc / 100;
							elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
						}
					})
					if(found == 0){
						taxDetail['taxStateType'] = taxStateType;
						taxDetail['taxPc'] = element.tax;
						taxDetail['taxableAmount'] = element.qty * element.rate;
						taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
						taxDetail['taxAmount'] = (element.qty * element.rate) * element.tax / 100;
						taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
						taxDetails.push(taxDetail)
					}
				}
				taxable_amount = taxable_amount + (element.qty * element.rate);
			});

			taxDetails.forEach(function(elem) {
				total = total + Number(elem.taxAmount);
				console.log(total);
			});
			sale.taxable_amount = parseFloat(Math.round(taxable_amount * 100) / 100).toFixed(2);
			sale.total = Number(total) + Number(taxable_amount);
			sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
			sale.round_off = Math.round(sale.total) - Number(sale.total);
			sale.round_off = parseFloat(Math.round(sale.round_off * 100) / 100).toFixed(2);
			sale.payable_amount = Math.round(sale.total);
			if(sale.adjustment_name != undefined && sale.adjustment_name != '')
				sale.payable_amount = Number(sale.payable_amount) + Number(sale.adjustment_aamount || 0);
			sale.payable_amount = parseFloat(Math.round(sale.payable_amount * 100) / 100).toFixed(2);
			this.setState({
				taxDetails: taxDetails,
				new_data_row: sale
			})
		},300)
	}

	handleRoundOffChange(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row.payable_amount = Number(new_data_row.payable_amount) - Number(new_data_row.round_off)
		new_data_row.round_off = e.target.value
		new_data_row.payable_amount = Number(new_data_row.payable_amount) + Number(new_data_row.round_off)
		this.setState({
			new_data_row: new_data_row
		})
	}

	searchProducts(index, e){
		var find = e != null ? e.target.value : ''
		var new_data_row = {...this.state.new_data_row}
		var products = [...new_data_row.products]
		products[index].searchingProducts = true
		new_data_row.products = products
		this.setState({
			new_data_row: new_data_row
		})
		Client.searchAccountingInvoiceProduct(find).then(res=>{
			var searchProducts = [...res.products];
			searchProducts.forEach(function(sc){
				sc['text'] = sc.name;
				sc['value'] = sc.product_id;
			})
			// this.state.new_data_row.products.forEach(function(product){
			// 	if(product.product_id != null && product.product_id != undefined && product.product_id != '')
			// 		searchProducts.push({...product, text: product.name, value: product.product_id})
			// })
			products[index].options_product = searchProducts
			products[index].searchingProducts = false
			new_data_row.products = products
            this.setState({
                // options_product: searchProducts,
				new_data_row: new_data_row,
				// searchingProducts: false
            })
        })
	}

	onKeyDown(index, e, searchIndex){
		// if(e.target.name == "customer" || e.target.name == "customer-suggestions"){
		// 	if(e.key=="ArrowDown"){
				// e.preventDefault();
				// let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				// if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
				// 	suggestions[searchIndex + 1].focus();
				// else if(suggestions.length > 0 && searchIndex == undefined)
				// 	suggestions[0].focus();
			// }
			// if(e.key=="ArrowUp"){
				// e.preventDefault();
				// let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				// if(suggestions.length > 0 && searchIndex != 0)
				// 	suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				// else if(searchIndex == 0){
				// 	document.getElementById('accounting-invoice-customer-input').focus()
				// }
		// 	}
		// }
	}

	newProductCreated(product){
		var products = this.state.new_data_row.products;
		var sale = this.state.new_data_row;
		products[this.state.indexForNewProduct] = product
		products[this.state.indexForNewProduct]['productId'] = product.itemId
		products[this.state.indexForNewProduct]['qty'] = 1
		sale.products = [...products]
		this.setState({
			new_data_row: sale,
			createProduct: false
		})
		this.calculateTax();
	}

	handleAdditionTax(index, e, { value }){
		value = value + '';
		var tax = [...this.state.options_tax, {text: value, value: value}];
		this.setState({
			options_tax: tax
		})
	}

	createPurchase(){
		var new_data_row = {...this.state.new_data_row}
		var flagInvalidForm = false;
		if (!this.state.vendor) {
			flagInvalidForm = true;
			alert("Please Select Customer")
			return 0
		}
		if (!new_data_row.voucher_id || new_data_row.voucher_id.trim() == '') {
			flagInvalidForm = true;
			alert("Invoice # Must Be Selected")
			return 0
		}
		// new_data_row.products.forEach((item, i) => {
		// 	if (item.tax == 0 || item.tax == 'N/A') {
		// 		if (window.confirm('There is product with 0% or N/A tax. Are you sure to proceed?')) {
		// 			return true
		// 		} else {
		// 			flagInvalidForm = true;
		// 			return false
		// 		}
		// 	}
		// });

		if(flagInvalidForm){
			alert("Errors in Voucher Creation. Please check data again.")
		}
		else{
			var products = new_data_row.products
			products.forEach(function(product){
				delete product.options_product
				delete product.options_description
				delete product.searchingProducts
			})
			new_data_row['products'] = products
			console.log(new_data_row);

			Client.accountingCreatePurchase(new_data_row, this.state.vendor, localStorage.getItem('currentPurchaseEdit')).then(res=>{
		        this.props.history.push('/accounting/vouchers/purchase')
	        })
		}
	}

	render(){
		var amountTableTaxData = <div></div>;
		this.state.taxDetails.forEach(function(taxDetail){
			if(taxDetail.taxStateType == 1)
			{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount)}</td></tr>
				</tbody>
			}
			else{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
					<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
				</tbody>
			}
		}.bind(this))

		return(
		    <div>
				<div style={{width:'100%', height:'100%', padding:'10%', background:'rgba(0,0,0,0.5)', position:'absolute', top:0, zIndex:1, visibility:this.state.createProduct ? 'visible' : 'hidden'}}>
					<div style={{height:'100%', overflowY:'scroll', background:'white'}}>
						<img src={RemoveImage} width='25px' height="25px" onClick={()=>this.setState({createProduct:false})}/>
						<Inventory newProductCreated={(product)=>this.newProductCreated(product)}/>
					</div>
				</div>
				<Form size="mini" onSubmit={() => this.createPurchase()}>
					<Form.Group widths='equal'>
						<Form.Select options={this.state.options_voucher_id}
							allowAdditions
							search
							onAddItem={(e, value) => this.handleInvoiceIdAdditions(e, value)}
							type="text"
							name="voucher_id"
							placeholder="Invoice #" label="Invoice #" selection value={this.state.new_data_row.voucher_id}
							onChange ={(e, data) => this.handleInputChangeSale(e, data)}
							required/>
						<Form.Input placeholder='Order #' label="Order #" name="order_id" value={this.state.new_data_row.order_id}
								onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
					</Form.Group>

					{/* {
						this.state.loading_form_order_id
						? */}
						{/* <Segment>
							<Dimmer active>
							<Loader />
							</Dimmer>
							<Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
						</Segment>
						: */}
						<Form.Group widths='equal'>
							<Form.Select selection search options={this.state.options_vendor} name='vendor'
								className="accounting-invoice-input"
								id="accounting-invoice-vendor-input"
								placeholder='Choose Vendor'
								label="Vendor"
								// loading={this.state.loading_form_order_id}
								autoComplete="off"
								value={this.state.new_data_row.vendor}
								onChange={(e, data) => this.handleInputChangeSale(e, data)}
								required/>
								{/* {
									this.state.new_data_row.customer !=null && this.state.new_data_row.showBranch?
										<Form.Select loading={this.state.new_data_row.loading_branch || this.state.loading_form_order_id} clearable selection search options={this.state.options_branch} name="cust_branch" value={this.state.new_data_row.branch} label="Branch" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
									:
									null
								} */}
						</Form.Group>
					{/* } */}
				<Form.Input type='date' placeholder="Invoice Date" label="Invoice Date" name="date" value={this.state.new_data_row.date}
					 onChange={(e, data) => this.handleInputChangeSale(e, data)}
					 required/>
				<Form.Group widths='equal'>
					<Form.TextArea placeholder="Contact Person" label="Contact Person" name="contact_person" value={this.state.new_data_row.contact_person}
						onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
					<Form.TextArea placeholder="Remarks" label="Remarks" name="remarks" value={this.state.new_data_row.remarks}
						onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				</Form.Group>
				<Table compact className="accounting-invoice-product-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>ITEM</Table.HeaderCell>
							<Table.HeaderCell>HSN</Table.HeaderCell>
							<Table.HeaderCell>QTY</Table.HeaderCell>
							<Table.HeaderCell>RATE</Table.HeaderCell>
							<Table.HeaderCell>TAX</Table.HeaderCell>
							<Table.HeaderCell>AMOUNT</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
					{
						this.state.new_data_row.products.map((product, index) => (
							<Table.Row>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Select search options={product.options_product}
										placeholder="Product Name" name="product_id" autoComplete="off"
										className="accounting-invoice-product-input"
										id={"accounting-invoice-product-input-name-" + index}
										allowAdditions
										onSearchChange={(e) => this.searchProducts(index, e)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										onAddItem={(e, value) => this.handleProductAddition(index, e, value)}
										value={product.product_id}
										loading={product.searchingProducts}/>
									<Dropdown
										options={product.options_description}
										placeholder="Description"
										allowAdditions
										search
										className="accounting-invoice-product-input"
										selection
										name="description"
										onAddItem={(e, value) => this.handleProductDescriptionAddition(index, e, value)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										value={product.description || ''}
										/>
									{/* <Form.Input value={product.description} placeholder="Description" name="description"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/> */}
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.HSN} placeholder="HSN" name="HSN"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.qty} placeholder="Quantity" name="qty"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.rate} placeholder="Rate" name="rate"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Select
										options={this.state.options_tax}
										placeholder="Tax %"
										allowAdditions
										search
										selection
										name="tax"
										onAddItem={(e, value) => this.handleAdditionTax(index, e, value)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										value={product.tax}
										/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={this.perfectDecimal(product.qty * product.rate)} placeholder="Amount" name="amount"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<img src={RemoveImage} onClick={() => this.removeProduct(index)} style={{width:'25px', height:'25px'}}/>
								</Table.Cell>
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>
				<Button secondary onClick={(e) => this.addProduct(e)}>Add Product</Button>
				<div style={{display:'inline-block', float:'right', marginRight:'20px'}}>
				<table>
					<tr><td>Sub Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.taxable_amount}</td></tr>
					{
						this.state.taxDetails.map((taxDetail) => (
							taxDetail.taxStateType == 1
							?
							<tbody>
								<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount)}
								</td></tr>
							</tbody>
							:
							<tbody>
								<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
								<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
							</tbody>
						))
						//amountTableTaxData
					}
					<tr><td>Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.total}</td></tr>
					<tr>
						<td>
						<Form.Input value={this.state.new_data_row.adjustment_name} placeholder="Adjustment" name="adjustment_name"
							 onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
						<td>
						<Form.Input value={this.state.new_data_row.adjustment_amount} placeholder="Amount" name="adjustment_amount" type="number"
							 onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
					</tr>
					<tr>
						<td>Round Off</td>
						<td>
							<Form.Input value={this.state.new_data_row.round_off} placeholder="Round" name="round_off" type="number"
								 onChange={(e, data) => this.handleRoundOffChange(e, data)} required/>
						</td>
					</tr>
					<tr><td>Payable Amount</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.payable_amount}</td></tr>
				</table>
				<Form.Button primary type='submit'>CREATE PURCHASE</Form.Button>
				</div>
				</Form>
			</div>
        )
	}
}

export default CreateAccountingPurchase;
