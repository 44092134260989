import React, {Component} from 'react';
import '../style/add-sale.css'
import Client from '../services/Client'
import AddSaleProductAdded from "../components/add-sale-product-added";
import AddSaleSuggestion from '../components/add-sale-suggestion';
import {Link} from 'react-router-dom';

class AddOrder extends Component{

    state = {

        sale_id: "",
        customer_name: "",
        customer_id: "",
        customer_phone: "",
        customer_gst: "",
        order_date: "",
        payment_mode: "cash",
        salesperson_name: "",
        shipment_reqd: false,
        shipment_date: "",
        delivery_man: "",
        products: [],
        product: {
            delivery: 0,
            product: 0,
            name: "",
            barcode: "",
            quantity: 1,
            price: 0
        },
        suggestions: [],
        recentSale: {},
        total: 0

    };

    updateTotal(){
        let t = 0;
        this.state.products.forEach((item) => {
          t += item.price*item.quantity;
        })
        this.setState({total: t});
    }

    componentDidMount() {
        if(localStorage.getItem('recentSale')!==null){
            this.setState({
                recentSale: JSON.parse(localStorage.recentSale),
                payment_mode: JSON.parse(localStorage.recentSale).payment_mode,
                salesperson_name: JSON.parse(localStorage.recentSale).salesperson_name
            });
        }
        this.setState({
            order_date: new Date().toISOString().substring(0,10)
        })
    }

    //handle input changes for add product fields
    onInputChange = (e) => {
        const product=this.state.product;
        product[e.target.name]=e.target.value;
        this.setState({
            product
        })
        if(e.target.name==="barcode"){
             this.searchBarcode(e.target.value);
        }
        if(e.target.name==="name"){
             this.suggestProducts(e.target.value);
        }
    };

    onDetailsInputChange = (e) => {

        if(e.target.name==="customer_phone"){
            const inputParts = e.target.value.trim().split(' ');
            let phone = "";
            inputParts.forEach((part) => {
              phone = phone.concat(part);
            });
            this.setState({customer_phone: phone});
            if(phone.length>=10){
                phone = phone.substring(phone.length, phone.length-10);
                this.setState({customer_phone: phone});
                this.searchCustomer(phone);
            }
        }else{
            const s = this.state;
            s[e.target.name]=e.target.value;
            this.setState(s);
        }

    };

    //auto input customer name on entering customer phone

    searchCustomer = (find) => {
        Client.searchCustomer(find).then((res)=>{
            if(res.customers.length>0){
                this.setState({
                    customer_name: res.customers[0].name,
                    customer_gst: res.customers[0].GST,
                    customer_id: res.customers[0].id
                });
            }else{
                this.setState({
                    customer_name: "",
                    customer_gst: "",
                    customer_id: ""
                });
            }
        });
    }

    //suggest products on product name input - MSJ

    suggestProducts = (find) => {
        if(find){
            let suggestions = [];
            Client.suggestProducts(find).then((res)=>{
                this.setState({suggestions: []}); //clear state before adding new products - MSJ
                res.shopProducts.forEach((product)=>{
                    if(product.variants.length==1){
                        suggestions = [
                            ...suggestions,
                            {
                                name: product.productName,
                                barcode: product.variants[0].barcode || '0000000000',
                                price: product.variants[0].price,
                                id: product.variants[0].id
                            }
                        ]
                    }else{
                        product.variants.forEach((variant) => {
                            suggestions = [
                                ...suggestions,
                                {
                                    name: product.productName + " (" + variant.quantity + ")",
                                    barcode: variant.barcode || '0000000000',
                                    price: variant.price,
                                    id: variant.id
                                }
                            ]
                        })
                    }
                })
            }).then(()=>{
                    this.setState({
                        suggestions: suggestions
                    })
                }
            );
        }else{
            this.setState({suggestions: []}); //clear state when no input - MSJ
        }
    }

    //on suggestion click
    handleSuggestionClick = (name, barcode, price, id) => {

        let prods = this.state.products;

        let count = 0;
        prods.forEach((product) => {
            if(product.barcode==barcode){
                product.quantity = product.quantity+1;
                count++;
            }
        })

        if(count==0){
            let p = this.state.product;
            p.name = name;
            p.barcode = barcode;
            p.price = price;
            p.product = id;
            p.quantity = 1;
            this.setState({product: p, suggestions:[]});
            this.handleAddProduct();
        }else{
            this.setState({
                products: prods,
                product: {
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: ""
                },
                suggestions: []
            })
            this.updateTotal();
        }
    };

    handleAddProduct = () => {

        if(this.state.product.product){
            this.setState({
                products:[
                    ...this.state.products,
                    this.state.product
                ],
                product:{
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: ""
                }
            })
        }else{
            console.log("Invalid Product");
        }

        this.updateTotal();

    };

    handleProductRemove = (index) => {
        let p = this.state.products;
        p.splice(index, 1);
        this.setState({
            products: p
        });
        this.updateTotal();
    }

    handleCreateSale = (e) => {

        e.preventDefault();

        if(this.state.customer_name.trim()&&this.state.customer_phone.trim&&this.state.payment_mode.trim()&&this.state.products.length){

            Client.createSale(this.state.customer_name, this.state.customer_phone, this.state.customer_gst, this.state.order_date, this.state.payment_mode, this.state.salesperson_name, this.state.shipment_date, this.state.delivery_man, this.state.products, "sis").then(
                (response)=>{

                    if(response.responseResult==="success"){
                        this.setState({
                            recentSale: {
                                sale_id: response.saleId,
                                invoice_id: response.invoiceId,
                                customer_name: this.state.customer_name,
                                customer_id: this.state.customer_id,
                                customer_phone: this.state.customer_phone,
                                customer_gst: this.state.sale_id,
                                order_date: this.state.order_date,
                                payment_mode: "cash",
                                salesperson_name: this.state.salesperson_name,
                                shipment_reqd: this.state.shipment_reqd,
                                shipment_date: this.state.shipment_date,
                                delivery_man: this.state.delivery_man,
                                products: this.state.products,
                                shipment_address: "Address Address Address Address Address Address"
                            }
                        });
                        localStorage.setItem("recentSale", JSON.stringify(this.state.recentSale));
                    }
                    if(response.responseError==='invalidCredentials'){
                        this.setState({
                            fetching:false,
                            fieldErrors:{
                                ...this.state.fieldErrors,
                                error:'Invalid Phone or Password'
                            }
                        })
                    }
                }
            ).catch((err)=>{
                this.setState({
                    fetching:false,
                    unExpectedErrors:'Sorry,We could not log you in.Something Wrong Happened'
                })
            })
        }else alert("Please fill in the required fields.");


    }

    handleCheckBox = (e) => {
        this.setState({
            shipment_reqd: e.target.checked,
            shipment_date: "",
            delivery_man: ""
        });
    }

    searchBarcode = (barcode) => {

        let prods = this.state.products;

        let count = 0;
        prods.forEach((product) => {
            if(product.barcode==barcode){
                product.quantity = product.quantity+1;
                count++;
            }
        })

        if(count===0){

            const rootState = this.state.product;

            Client.searchBarcode(barcode).then(
                (response)=>{
                    if(response.responseError==='invalidCredentials'){
                        this.setState({
                            fieldErrors:{
                                ...this.state.fieldErrors,
                                error:'Invalid Phone or Password'
                            }
                        })
                    }
                    else {
                        if(response.productDetailsCount>0){
                            rootState.name = response.productDetails[0].productName;
                            rootState.product = response.productDetails[0].variants[0].id;
                            rootState.price = response.productDetails[0].variants[0].price;
                            rootState.quantity = 1;
                            this.setState({
                                product: rootState
                            })
                            this.handleAddProduct();
                        }
                    }
                }
            ).catch((err)=>{
                this.setState({
                    fetching:false,
                    unExpectedErrors:'Sorry,We could not log you in.Something Wrong Happened'
                })
                console.log(err);
            })
        }else{
            this.setState({
                products: prods,
                product: {
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: ""
                }
            })
            this.updateTotal();
        }
    }

    handleQtyChange = (index, qty) => {
        let p = this.state.products;
        p[index].quantity = parseInt(qty);
        this.setState({
            products: p
        });
        this.updateTotal();
    }

    setInvoice = () => {
        localStorage.setItem("invoice", "s"+this.state.recentSale.sale_id);
    }

    render(){

        return(
            <div className="add-sale-container">
                <form className="ui form">
                    <div className="three fields">
                        <div className="required field">
                            <label htmlFor="order_date">Order Date</label>
                            <input name="order_date" type="date" placeholder="Order Date" value={this.state.order_date} onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="required field">
                            <label htmlFor="payment_mode">Payment Method</label>
                            <select className="ui fluid dropdown">
                                <option value="cash">Cash</option>
                                <option value="online">Online</option>
                            </select>
                            {/*<input name="payment_mode" type="text" placeholder="Payment Method" value={this.state.payment_mode} onChange={this.onDetailsInputChange.bind(this)}/>*/}

                        </div>
                        <div className="field">
                            <label htmlFor="salesperson_name">Salesperson</label>
                            <input name="salesperson_name" type="text" placeholder="Salesperson Name" value={this.state.salesperson_name} onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                    </div>

                    <h4 className="ui dividing header">Add New Sale</h4>
                    <div className="three fields">
                        <div className="required field">
                            <label htmlFor="customer_phone">Customer Phone No.</label>
                            <input name="customer_phone" type="text" required value={this.state.customer_phone} placeholder="Customer Phone No." onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="required field">
                            <label htmlFor="customer_name">Customer Name</label>
                            <input name="customer_name" type="text" value={this.state.customer_name} placeholder="Customer Name" onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="field">
                            <label htmlFor="customer_gst">Customer GST No.</label>
                            <input name="customer_gst" type="text" value={this.state.customer_gst} placeholder="Customer GST No." onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                    </div>

                    {
                        (this.state.shipment_reqd)
                        ?
                        <div className="three fields">
                            <div className="field" style={{marginTop: "20px"}}>
                                <input
                                    type="checkbox"
                                    name="shipment_reqd"
                                    style={{marginTop: "5px"}}
                                    onChange={this.handleCheckBox.bind(this)}
                                />
                            &nbsp;&nbsp;&nbsp;Shipping Required
                            </div>
                            <div className="field">
                                <label htmlFor="shipment_date">Shipment Date</label>
                                <input name="shipment_date" type="date" placeholder="Shipment Date" onChange={this.onDetailsInputChange.bind(this)}/>
                            </div>
                            <div className="field">
                                <label htmlFor="delivery_man">Delivery Man</label>
                                <input name="delivery_man" type="text" placeholder="Delivery Man" onChange={this.onDetailsInputChange.bind(this)}/>
                            </div>
                        </div>
                        :
                        <div className="three fields">
                            <div className="field">
                                <input
                                    type="checkbox"
                                    name="shipment_reqd"
                                    style={{marginTop: "5px"}}
                                    onChange={this.handleCheckBox.bind(this)}
                                />
                                &nbsp;&nbsp;&nbsp;Shipping Required
                            </div>
                        </div>
                    }
                    <div className="field">
                        <table className="ui basic four column table">
                            <thead>
                                <tr>
                                    <th>Barcode</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.products.map((product, key)=>(
                                    <AddSaleProductAdded product={product} index={key} onQuantityChange={this.handleQtyChange} onremove={this.handleProductRemove} updateTotal={this.updateTotal.bind(this)}/>
                                ))}
                                <tr>
                                    <td>Total</td>
                                    <td></td>
                                    <td></td>
                                    <td>{this.state.total}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="four fields">
                            <div className="field">
                                <input
                                    type="text"
                                    name="barcode"
                                    value={this.state.product.barcode}
                                    placeholder="BARCODE"
                                    onChange={this.onInputChange.bind(this)}/>
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    name="name"
                                    value={this.state.product.name}
                                    placeholder="Product"
                                    onChange={this.onInputChange.bind(this)}/>
                            </div>
                            <div className="field"></div>
                            <div className="field">
                                <button type="button" style={{}} className="ui button right floated" onClick={this.handleCreateSale.bind(this)}>CREATE SALE</button>
                            </div>

                        </div>
                        <div id="suggestions">
                            {this.state.suggestions.map((suggestion, key)=>(
                                <AddSaleSuggestion name={suggestion.name} barcode={suggestion.barcode} price={suggestion.price} id={suggestion.id} click={this.handleSuggestionClick} />
                            ))}
                        </div>

                    </div>
                </form>
                {
                    (Object.keys(this.state.recentSale).length == 0) ? <div></div> :
                    <div style={{marginTop: "25px"}}>
                        <div><Link to='/invoice' style={{float: "right"}} onClick={this.setInvoice}>Generate Invoice</Link></div>
                        <h4 className="ui dividing header">Recent Sale</h4>
                        <div className="ui grid">
                            <div className="four wide column"><strong>Sale ID:</strong>&nbsp;&nbsp;{this.state.recentSale.sale_id}</div>
                            <div className="four wide column"><strong>Customer Name:</strong>&nbsp;&nbsp;{this.state.recentSale.customer_name}</div>
                            <div className="four wide column"><strong>Customer Phone:</strong>&nbsp;&nbsp;{this.state.recentSale.customer_phone}</div>
                            <div className="four wide column"><strong>Order Date:</strong>&nbsp;&nbsp;{this.state.recentSale.order_date}</div>
                            <div className="four wide column"><strong>Payment Mode:</strong>&nbsp;&nbsp;{this.state.recentSale.payment_mode}</div>
                            <div className="four wide column"><strong>Salesperson:</strong>&nbsp;&nbsp;{this.state.recentSale.salesperson_name}</div>
                            <div className="eight wide column"><strong>Shipment Address:</strong>&nbsp;&nbsp;{this.state.recentSale.shipment_address}</div>
                            <div className="sixteen wide column"><strong>Products:</strong>&nbsp;&nbsp;{this.state.recentSale.products.map((product)=>(<span>{product.name}, &nbsp;</span>))}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AddOrder;
