import React, {Component} from  'react';
import OrderProductsList from './order-products-list';
import OrderImoDetails from './order-imo-details';
import OrderPndDetails from './order-pnd-details';
import Client from '../services/Client';
import {Link} from 'react-router-dom';

var intToMonth = ["Of Month", "Jan", "Feb", "Mar", "Apr", "May", "June", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
var dateSuffix = ["th", "st", "nd", "rd", "th"];

class Order extends Component{

    state = {
        orderDetails:{
            subsOrders:[],
            products:[]
        },
        statuses:["Processing Payment", "Payment Failed", "Placed", "Accepted", "Packed", "Delivered"],
        updateStatus: "",
        currentStatus: "",
        products: [],
        notify: 0,
        selectedSubsOrder: 0,
        selectedSubsOrderIndex: -1,
    }

    componentDidMount() {
        this.setState({
            currentStatus: this.props.order.status
        })
        Client.getOrderDetails(this.props.order.orderId).then((res)=>{
            console.log("loaded" + this.props.order.orderId)
            console.log(res)
            this.setState({
                orderDetails: {...this.state.orderDetails, ...res},
                products: res.products,
                selectedSubsOrder: res.subsOrders.length > 0 ? res.subsOrders[0].subsOrderId : 0,
                selectedSubsOrderIndex: res.subsOrders.length > 0 ? 0 : -1,
                currentStatus: res.subsOrders.length > 0 ? res.subsOrders[0].orderStatus : res.orderStatus
            })
        })
    }

    setInvoice = () => {
        localStorage.setItem('invoice','s'+this.props.sale.sales_id);
    }

    updateOrder(val){
        Client.updateOrder(this.state.orderDetails, val, this.state.notify, this.state.selectedSubsOrder).then((res)=>{
            this.setState({
                currentStatus: res.updatedStatus
            })
        });
    }

    onStatusChange = (event) => {
        const val = event.target.value;

        if (window.confirm("Are you sure you want to update status to " + val + "!") == true) {
            // txt = "You pressed OK!";
            var prom = new Promise((resolve, reject)=>{
                if (window.confirm("DO YOU WANT TO NOTIFY CUSTOMER AND BUSINESS!") == true) {
                    // txt = "You pressed OK!";
                    this.setState({
                        updateStatus:val,
                        notify: 1
                    })
                }
                else {
                     this.setState({
                         updateStatus:val,
                         notify: 0
                     })
                }
                resolve(true);
            });
            prom.then(()=>{
                this.updateOrder(val);
            });
        }
        else {
            // console.log("no");
        }
    }

    parseDateToText(date){
        var dateDetailed = date.split("/");
        var suffix = "th";
        var date = parseInt(dateDetailed[2]);
        if(date >= dateSuffix.length){
            suffix = "th";
        }
        else{
            suffix = dateSuffix[date];
        }
        var title = date + suffix + " " + intToMonth[parseInt(dateDetailed[1])];
        return title;
    }

    selectSubsOrder(subsOrder, index){
        this.setState({
            selectedSubsOrder: subsOrder.subsOrderId,
            selectedSubsOrderIndex: index,
            currentStatus: subsOrder.orderStatus
        })
    }

    render(){
        var subsOrderDetails = this.state.orderDetails.subsOrders.length > 0
            ?
                this.state.orderDetails.subsOrders[this.state.selectedSubsOrderIndex] ?
                this.state.orderDetails.subsOrders[this.state.selectedSubsOrderIndex] : this.state.orderDetails
            :
            this.state.orderDetails
        console.log(this.state.orderDetails.orderId)
        console.log(subsOrderDetails)

        var xyz;
        switch(this.props.order.mode){
            case "pnp":
            case "prd":
            case "pup":
                xyz =   <div className="booking-card-more">
                            <OrderProductsList order={subsOrderDetails} headOrder={this.state.orderDetails}/>
                        </div>
                break;
            case "imo":
                xyz =  <div className="booking-card-more">
                            {
                                //<i className="ui print icon" onClick={this.setInvoice} id="order-print-button"/>
                            }
                            <OrderImoDetails order={this.state.orderDetails} headOrder={this.state.orderDetails}/>
                        </div>
                break;
            case "pnd":
                xyz =   <div className="booking-card-more">
                            {
                                //<i className="ui print icon" onClick={this.setInvoice} id="order-print-button"/>
                            }
                            <OrderPndDetails order={this.state.orderDetails} headOrder={this.state.orderDetails}/>
                        </div>
                break;
            default:
                xyz =   <p>sdv</p>
                break;
        }

        var temp = this.state.statuses.indexOf(this.state.currentStatus);
        if(++temp < this.state.statuses.length){
            var status = this.state.statuses[temp];
        }

        // <div className="ui right floated"><Link to='/invoice'><i className="print large icon" style={{cursor: "pointer"}} onClick={this.setInvoice}></i></Link></div>
        return(
            <div className="booking-card">
                <div className="booking-card-tabs" style={{display:'flex', flexDirection:'row', width:'76vw', overflow:'auto', marginBottom:'10px'}}>
                    {
                        this.state.orderDetails.subsOrders
                        ?
                        this.state.orderDetails.subsOrders.map((subsOrder, index) => (
                            <div className="expndinnerm2"
                                style={{background:
                                    this.state.selectedSubsOrder == subsOrder.subsOrderId ?
                                    "#5acaea" : "#ffffff"}}
                                onClick={() => this.selectSubsOrder(subsOrder, index)}>
                                    {this.parseDateToText(subsOrder.date)}
                            </div>
                        ))
                        :
                        null
                    }
                </div>

                <div className="booking-data">
                    <div className="booking-card-id">
                        <span className="column thin"><strong>Order Id:</strong></span>
                        <span className="column">&nbsp;{subsOrderDetails.orderId}/{subsOrderDetails.subsOrderId}</span>
                    </div>
                    <div className="booking-card-amount">
                        <span className="column thin"><strong>Amount</strong></span>
                        <span className="column">&nbsp;{subsOrderDetails.totalAmount}</span>
                    </div>
                    <div className="booking-card-item">
                        <span className="column thin"><strong>Item:</strong></span>
                        <span className="column">&nbsp;{this.props.order.products}</span>
                    </div>
                    <div className="booking-card-status">
                        <span className="column thin"><strong>Order Status:</strong></span>
                        <span className="column">&nbsp;{this.state.currentStatus}</span>
                        {(temp < this.state.statuses.length && !(this.state.currentStatus == "Cancelled" || this.state.currentStatus == "Delivered"))?
                        <button className="booking-card-status-button" value={status} onClick={this.onStatusChange.bind(this)}>{status}</button>
                        :null
                        }
                    </div>
                    <div className="booking-card-date">
                        <span className="column thin"><strong>Order Date:</strong></span>
                        <span className="column">&nbsp;{subsOrderDetails.orderDateAndTime}</span>
                    </div>
                    <div className="booking-card-name">
                        <span className="column thin"><strong>Customer Name:</strong></span>
                        <span className="column">&nbsp;{this.props.order.name}</span>
                    </div>
                    {xyz}
                </div>
            </div>
        )
    };
}
export default Order;
