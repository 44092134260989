import React, {Component} from 'react';
import $ from 'jquery';
import Booking from "../components/booking";
import BookingRow from "../components/booking-row";
import Client from '../services/Client'

import { Table } from 'semantic-ui-react';

class BookingsList extends Component{

    state = {
        disabled: false,
        bookings: [],
        page: 0,
        morePage: 1,
        lastRequest: 0,
        storeId: 0
    }

    handleScroll(){
        // var container = document.getElementById('scroll');
        // if(container.scrollTop >= (container.scrollHeight-container.offsetHeight)){
        //     this.props.handleScroll();
        // }
    }

    componentDidMount() {

        this.setState({
            bookings: this.props.bookings
        })

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillReceiveProps(props){

        this.setState({
            bookings: props.bookings
        })
    }

    handleSort(sortBy){

        if(sortBy == this.state.sortBy){

            this.props.handleSort(sortBy, this.state.sortOrder == '' || this.state.sortOrder == 'descending' ? 'asc' : 'desc');

            this.setState({
                sortOrder: this.state.sortOrder=='' || this.state.sortOrder=='descending' ? 'ascending' : 'descending',
                sortBy: sortBy
            })

        }else{

            this.setState({
                sortOrder: 'ascending',
                sortBy: sortBy
            })

            this.props.handleSort(sortBy, 'asc');
        }
    }

    render(){
        return(
            <div className="bookingsListContainer" onScroll={this.handleScroll}>
                {
                    this.props.refreshing
                    ?
                    <div style={{position: 'absolute', top: "50%", left: "50%"}}>
                        <div className="ui active inverted dimmer">
                            <div className="ui text loader">Loading</div>
                        </div>
                    </div>
                    :
                    this.props.view == 'card'
                    ?
                    <div className="bookingsListInnerContainer">
                    {
                        this.state.bookings.map((booking, key)=>(
                            <Booking booking={booking} />
                        ))
                    }
                    </div>
                    :
                    this.props.view == 'list'
                    ?
                    <Table sortable style={{borderRadius: 0}}>
                        <Table.Header>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='bookingId' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('bookingId')}.bind(this)}>
                            ID</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='bookingDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('bookingDateAndTime')}.bind(this)}>
                            Order Date</Table.HeaderCell>
                            {
                                // <Table.HeaderCell
                                //     sorted = {this.state.sortBy=='visitingDateAndTime' && this.state.sortOrder}
                                //     onClick = {function(){this.handleSort('visitingDateAndTime')}.bind(this)}>
                                // Delivery Date</Table.HeaderCell>
                            }
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerName' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerName')}.bind(this)}>
                            Customer</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerPhone' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerPhone')}.bind(this)}>
                            Customer Phone</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='amount' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('grossAmount')}.bind(this)}>
                            Amount</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='amount' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('grossAmount')}.bind(this)}>
                            Order Status</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.bookings.map((booking)=>(
                                    <BookingRow booking={booking} storeId={this.props.storeId} />
                                ))
                            }
                        </Table.Body>
                    </Table>
                    :
                    <Table sortable style={{fontSize: '0.8em', borderRadius: 0}}>
                        <Table.Header>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='bookingId' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('bookingId')}.bind(this)}>
                            ID</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='bookingDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('bookingDateAndTime')}.bind(this)}>
                            Order Date</Table.HeaderCell>
                            {
                                // <Table.HeaderCell
                                //     sorted = {this.state.sortBy=='visitingDateAndTime' && this.state.sortOrder}
                                //     onClick = {function(){this.handleSort('visitingDateAndTime')}.bind(this)}>
                                // Delivery Date</Table.HeaderCell>
                            }
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerName' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerName')}.bind(this)}>
                            Customer</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerPhone' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerPhone')}.bind(this)}>
                            Customer Phone</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='quantity' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('quantity')}.bind(this)}>
                            Quantity</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='addons' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('quantity')}.bind(this)}>
                            Addons</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='amount' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('grossAmount')}.bind(this)}>
                            Amount</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='status' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('status')}.bind(this)}>
                            Booking Status</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='paymentMode' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('paymentMode')}.bind(this)}>
                            Payment Mode</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='paymentStatus' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('paymentStatus')}.bind(this)}>
                            Payment Status</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.bookings.map((booking)=>(
                                    <BookingRow booking={booking} extended={true} storeId={this.props.storeId} />
                                ))
                            }
                        </Table.Body>
                    </Table>
                }
            </div>
        )
    }
}
export default BookingsList;
