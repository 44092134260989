import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Menu, Placeholder,Grid} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import '../style/inventory.css';
import SaleView from './sale-voucher-view';
import QuotationView from './quotation-view';
import PurchaseView from './purchase-view';
import DeleveryChallanView from './d-note-view';
import $ from 'jquery';
import {useDropzone} from 'react-dropzone';
import Dropzone from 'react-dropzone';
import S3FileUpload from 'react-s3';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

const s3config = {
    bucketName: 'deskneed',
    dirName: 'products', /* optional */
    region: 'ap-south-1',
    accessKeyId: 'AKIA6KSSIFF4J3TGFMWC',
    secretAccessKey: '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',
}

class Inventory extends Component{

	constructor(){
		super();

		this.state = {
			showCreateDataForm: window.location.pathname.includes("/accounting/vouchers/invoice"),
			selectedImages: [],
			data_rows: [],
			product_details: [
			],
			new_data_row: {},
			voucher:{},
			tableFields: [
				{"value": "id", "text": "ID", "type": "field"},
				{"value": "name", "text": "Name", "type": "field"},
				{"value": "HSN", "text": "HSN / SAC", "type": "field"},
				{"value": "rate", "text": "Rate", "type": "field"},
				{"value": "MRP", "text": "MRP", "type": "field"},
				{"value": "tax", "text": "Tax", "type": "select"},
				{"value": "type", "text": "TYPE", "type": "select"},
				{"value": "category", "text": "CATEGORY", "type": "select"}
			],
			tableFields_2: [
                {"value": "ledger_name", "text": "Ledger", "type": "field"},
				{"value": "date", "text": "Date", "type": "field"},
				{"value": "transaction_type", "text": "Type", "type": "field"},
				{"value": "qty", "text": "QT", "type": "field"},
				{"value": "tax", "text": "TX", "type": "field"},
				{"value": "rate", "text": "RT-NT", "type": "field"},
				{"value": "rate_wt", "text": "RT-WT", "type": "calulateable"}
			],
			defaultSize: 'small',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'name',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'name',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
			},
			options_tax: [
				{text:'0 %', value: 0},
				{text:'5 %', value: 5},
				{text:'12 %', value: 12},
				{text:'18 %', value: 18},
				{text:'28 %', value: 28}
			],
			options_data_rows_limit: [
				{text:'10', value: 10},
				{text:'20', value: 20},
				{text:'30', value: 30},
				{text:'40', value: 40},
				{text:'50', value: 50}
			],
			options_type: [
				{text:'Product', value: 1},
				{text:'Service', value: 2}
			],
			options_category: [
				// product options
				{text:'Stationery', value: 'Stationery',type:'1'},
				{text:'Electrical Parts', value: 'Electrical Parts',type:'1'},
				{text:'General HardWare', value: 'General HardWare',type:'1'},
				{text:'Painting Materials', value: 'Painting Materials',type:'1'},
                {text:'Civil Materials', value: 'Civil Materials',type:'1'},
                {text:'Glass and Aluminium', value: 'Glass Aluminium',type:'1'},
				{text:'AC Parts', value: 'AC Parts',type:'1'},
				{text:'Generator Parts', value: 'Generator Parts',type:'1'},
				{text:'Electronics', value: 'Electronics',type:'1'},
				{text:'Appliances', value: 'Appliances',type:'1'},
				{text:'Fire and Safety', value: 'Fire and Safety',type:'1'},
                {text:'Grocery', value: 'Grocery',type:'1'},
				{text:'Housekeeping', value: 'Housekeeping',type:'1'},
				{text:'Food', value: 'Food',type:'1'},
				{text:'IT Parts', value: 'IT Parts',type:'1'},
				{text:'Surgical Items', value: 'Surgical Items',type:'1'},
				{text:'Medical Etc', value: 'Medical Etc',type:'1'},
				{text:'Chemical', value: 'Chemical',type:'1'},
				{text:'Carpentary Items', value: 'Carpentary Items',type:'1'},
				{text:'Corporate Gifting', value: 'Corporate Gifting',type:'1'},
				{text:'Other Products', value: 'Other Products',type:'1'},
				//Service options
				{text:'Plumbing Service', value: 'Plumbing Service',type:'2'},
                {text:'Painting Service', value: 'Painting Service',type:'2'},
                {text:'Electrician Service', value: 'Electrician Service',type:'2'},
                {text:'Carpentry Services', value: 'Carpentry Services',type:'2'},
				{text:'Cleaning Service', value: 'Cleaning Service',type:'2'},
                {text:'Pest Control', value: 'Pest Control',type:'2'},
                {text:'Plumbing and Sanitary', value: 'Plumbing and Sanitary',type:'2'},
				{text:'AC Service', value: 'AC Service',type:'2'},
				{text:'Generator Service', value: 'Generator Service',type:'2'},
				{text:'Fire and Safety Services', value: 'Fire and Safety Services',type:'2'},
				{text:'Printing Service', value: 'Printing Service',type:'2'},
				{text:'IT Services', value: 'IT Services',type:'2'},
				{text:'Other Services', value: 'Other Services',type:'2'},

			],
			options_group: [],
			form_mode: 'inline',
			product_details_sort:'ledger_name',
			product_details_sort_order:'asc',
		}
	}

	componentDidMount() {
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		if(data.name == 'type' && data.value != new_data_row.type){
			delete new_data_row.category
		}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		if(this.state.form_mode == 'inline'){
			this.setState({
				showCreateDataForm: true,
				new_data_row: this.state.data_rows[index],
				editMode: true
			})
		}
		else if(this.state.form_mode == 'modal'){
			this.setState({
				showCreateDataModal: true,
				new_data_row: this.state.data_rows[index],
				editMode: true
			})
		}
		this.setProductDetails(this.state.data_rows[index].id)
	}

	createNewDataRow(){
		this.setState({creatingNewDataModal: true})
		var state = this.state
		Client.accountingItemCreate(this.state.new_data_row).then(res=>{
			if(this.props.newProductCreated != undefined)
				this.props.newProductCreated(res.product);
			this.setState({
				showCreateDataModal: false,
				showCreateDataForm: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {}
			})
			this.getDataRows(state);
        })
	}

	showCreateDataRow(){
		if(this.state.form_mode == 'inline'){
		this.setState({
				showCreateDataForm: true
			})
		}
		else if(this.state.form_mode == 'modal'){
			this.setState({
				showCreateDataModal: true
			})
		}
	}

	hideCreateDataRow(){
		this.setState({
			showCreateDataModal:false,
			showCreateDataForm:false,
			new_data_row: {},
			editMode: false,
			creatingNewDataModal: false
		})
	}

	getDataRows(state){
		Client.getAccountingItems(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter).then(res=>{
				console.log(res.item)
            this.setState({
				data_rows: res.items,
				data_rows_size: res.items.length
            })
        })
	}

	selectedFiles(selectedFiles){
		 console.log(selectedFiles)
		 this.setState({
			 selectedImages: selectedFiles
		 })
		 S3FileUpload
	    .uploadFile(selectedFiles[0], s3config)
	    .then(data => console.log(data))
	    .catch(err => console.error(err))
	}
	setProductDetails(index){
		const filters ={product_id : [index]}
		const offset = 0
		const limit = 50
		Client.getItemTransactions(filters,offset,limit,'date','desc').then(res =>{
			console.log(res)
			this.setState({
				product_details:res.data_rows
			})
		})

	}
	rateWtCalcutator(rate,tax){
		const taxby100 = tax/100
		const taxFactor = 1 + (tax/100)
		let rateWT = rate * taxFactor
		return Math.round(rateWT,2)
	}
	sort(){
        if (this.state.product_details.length == 0) {
            alert("can't sort empty array")
        }else{
            const sortKey = this.state.product_details_sort
            let product_details = this.state.product_details
            const currentOrder =this.state.product_details_sort_order
            product_details.sort(function(a, b){
                var nameA,nameB
                if (typeof a[sortKey] == 'number') {
                    nameA=a[sortKey]
                    nameB=b[sortKey]
                }else{
                    nameA=a[sortKey].toLowerCase()
                    nameB=b[sortKey].toLowerCase()
                }
                if (nameA < nameB) //sort string ascending
                    return currentOrder=='asc'? -1:1
                if (nameA > nameB)
                    return currentOrder=='asc'? 1:-1
                return 0 //default return value (no sorting)
            })
            this.setState({product_details})
        }
	}
	sortProductDetails(e, data){
        if (this.state.product_details_sort == data) {
            const currentOrder =this.state.product_details_sort_order
            const newOrder= currentOrder == 'asc'?'desc':'asc';
            this.setState({product_details_sort_order:newOrder},()=>{this.sort()})
        }
        else{
            this.setState({product_details_sort: data},
                ()=>{
                    this.sort();
                    console.log("called");
            })
        }
	}
	showVoucher(voucher){
		console.log(voucher);
		// (voucher.type)
		let fetched_voucher={}
		switch (voucher.transaction_type) {
			case 'sale':
				Client.getAccountingSales(null,null,null,null,null,null,null,{id:[voucher.voucher_key]},{},'sale')
				.then(res=>{
					fetched_voucher= res.data_rows[0]
					// alert('reached')
					this.setState({voucher:fetched_voucher,showVoucherModal:'sale'})
				})
				break;
			case 'quotation':
				Client.getAccountingInvoiceQuotation(null,null,null,null,null,null,null,{id:[voucher.voucher_key]},{},'sale')
				.then(res=>{
					fetched_voucher= res.data_rows[0]
					// alert('reached')
					this.setState({voucher:fetched_voucher,showVoucherModal:'quotation'})
				})
				break;
			case 'purchase':
				Client.getAccountingPurchaseFilters(null,null,null,null,null,null,null,{id:[voucher.voucher_key]},{},'sale')
				.then(res=>{
					fetched_voucher= res.data_rows[0]
					// alert('reached')
					this.setState({voucher:fetched_voucher,showVoucherModal:'purchase'})
				})
			case 'delivery_note':
				Client.getAccountingDeleveryChallan(null,null,null,null,null,null,null,{id:[voucher.voucher_key]},{},'sale')
				.then(res=>{
					fetched_voucher= res.data_rows[0]
					// alert('reached')
					this.setState({voucher:fetched_voucher,showVoucherModal:'delivery_note'})
				})
			default:
				break;
		}
	}


	render(){
		return(
			<div>
			<Modal open={this.state.showVoucherModal} onClose={()=>this.setState({voucher:false,showVoucherModal:false})}>
				<Modal.Content scrolling>
					{
						this.state.showVoucherModal == 'sale'
						?
						<SaleView history={this.props.history} data_row={this.state.voucher}/>
						:
						null
					}
					{
						this.state.showVoucherModal == 'quotation'
						?
						<QuotationView history={this.props.history} data_row={this.state.voucher}/>
						:
						null
					}
					{
						this.state.showVoucherModal == 'purchase'
						?
						<PurchaseView history={this.props.history} data_row={this.state.voucher}/>
						:
						null
					}
					{
						this.state.showVoucherModal == 'delivery_note'
						?
						<DeleveryChallanView history={this.props.history} data_row={this.state.voucher}/>
						:
						null
					}
					</Modal.Content>
			</Modal>
				{
					this.state.showCreateDataModal || this.state.showCreateDataForm
					?
					<Button size={this.state.defaultSize} onClick={() => this.hideCreateDataRow()} color="red">HIDE FORM</Button>
					:
					<Button size={this.state.defaultSize} onClick={() => this.showCreateDataRow()} primary>CREATE</Button>
				}
				<div style={{float: 'right'}}>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.tableFields}
							name="data_rows_search_field"
							value={this.state.data_rows_search_field}
							placeholder="Search Field"
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					{
						this.state.data_rows_search_type == 'field'
						?
						<Input type="text"
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
						:
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
					}

					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					<Dropdown
						name='data_rows_limit'
						inline
						options={this.state.options_data_rows_limit}
						value={this.state.data_rows_limit}
						onChange={(e, data) => this.changeStateVariable(e, data)}
				    />
					{
						'Showing ' + this.state.data_rows_offset + ' to ' +
						(
							(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
							(this.state.data_rows_offset + this.state.data_rows_limit) :
							this.state.data_rows_size
						) +
						' of ' + this.state.data_rows_size + ' '
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Grid columns={2} style={{width:'100%', display: this.state.showCreateDataForm ? 'inline-block' : 'none'}}>
					<Grid.Column>
						<Dropzone
							onDrop={acceptedFiles => this.selectedFiles(acceptedFiles)}>
							{({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps({class: "imageDropzone"})}>
						        <input {...getInputProps()} />
						        <p>Drag 'n' drop some files here, or click to select files</p>
								{
									this.state.selectedImages.map((file) => (
										<img src={URL.createObjectURL(file)} style={{width:'75px', display:'inline-block'}}/>
									))
								}
						      </div>
						    </section>
						  )}
						</Dropzone>
						<Form size='mini' onSubmit={() => this.createNewDataRow()}>
		 					<Form.Input type="text" required fluid name="name" value={this.state.new_data_row.name || ''} label="Name" placeholder='Enter name' onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="HSN" value={this.state.new_data_row.HSN || ''} label="HSN/SAC" placeholder='Enter HSN/SAC' onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select selection search options={this.state.options_tax} type="text" required fluid name="tax" value={this.state.new_data_row.tax || ''} label="Tax" placeholder='Enter tax' onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="MRP" value={this.state.new_data_row.MRP || ''} label="MRP" placeholder='Enter MRP' onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid name="rate" value={this.state.new_data_row.rate || ''} label="Rate" placeholder='Enter rate' onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select selection search options={this.state.options_type} type="text" fluid name="type" value={this.state.new_data_row.type || ''} label="Type" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								{
									this.state.new_data_row.type
									?
									<Form.Select selection search
										options={this.state.options_category.filter(cat=>{
											return cat.type == this.state.new_data_row.type
										})}
										type="text" fluid name="category" value={this.state.new_data_row.category || ''} label="Category" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									:
									null
								}
							</Form.Group>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Grid.Column>
					<Grid.Column floated="right" style={{padding: 0, margin: '10px 0'}}>
						<Table color="blue" key="blue" size="small" sortable celled>
							<Table.Header style={{display:'table'}}>
								<Table.Row>
								{
									this.state.product_details ?
										this.state.tableFields_2.map((field, index) => (
											<Table.HeaderCell
											width={index <= 2 ? (5 - index) : 1} style={{cursor: 'pointer'}}
											onClick={field.value !='option'?(e, data) => this.sortProductDetails(e,field.value):null}
                                            style={{cursor: 'pointer'}}
                                            >
                                            {field.text}
                                            {

                                                this.state.product_details_sort_order == 'asc' ?
                                                    <Icon name='angle up' style={{visibility: (this.state.product_details_sort == field.value ? 'visible' : 'hidden')}}/>
                                                :
                                                    <Icon name='angle down' style={{visibility: (this.state.product_details_sort == field.value ? 'visible' : 'hidden')}}/>

                                            }
											</Table.HeaderCell>
										))
										:
										null
								}
								</Table.Row>
							</Table.Header>

							<Table.Body style={{display:'block', maxHeight:'40vh', overflowY:'scroll'}}>
							{
								this.state.product_details.map((data_row, index) => (
									<Table.Row className="data-row-table-row" onClick={() => this.showVoucher(data_row)}>
									{
										this.state.tableFields_2.map((field, index1) => (
											<Table.Cell width={index1 <= 2 ? (5 - index1) : 1}>
											{field.type=='select'
												?this.convertSelectToText(field.value, data_row[field.value])
												:(field.type =='calulateable'
													?this.rateWtCalcutator(data_row.rate,data_row.tax)
													:data_row[field.value])
											}
											</Table.Cell>
										))
									}
									</Table.Row>
								))
							}
							</Table.Body>
						</Table>
					</Grid.Column>
				</Grid>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select'
									? this.convertSelectToText(field.value, data_row[field.value])
									:data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal} style={{marginTop:'auto !important',width:'80%'}} onClose={() => this.hideCreateDataRow()}>
					<Modal.Content>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Input type="text" required fluid name="name" value={this.state.new_data_row.name || ''} label="Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="HSN" value={this.state.new_data_row.HSN || ''} label="HSN/SAC" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select selection search options={this.state.options_tax} required fluid name="tax" value={this.state.new_data_row.tax || ''} label="Tax" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" fluid name="rate" value={this.state.new_data_row.rate || ''} label="Rate" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid name="MRP" value={this.state.new_data_row.MRP || ''} label="MRP" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}
export default Inventory;
