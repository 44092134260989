import React from 'react'
import {Card, Dropdown, Input,Loader,Segment,Dimmer,Icon, Image, Checkbox, Grid, Table, Message} from 'semantic-ui-react'
import Client from '../services/Client';
import '../style/dashboard.css'
import { BarChart,Bar,LineChart, Line,XAxis, YAxis, CartesianGrid, Tooltip, Legend,Label, PieChart, Pie, Sector, Cell} from 'recharts';
class DashboardCustomer extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            graph_data:[
                {name:'2019-11-1',expenses:1,earning:2},
                {name:'2019-11-3',expenses:4,earning:7},
                {name:'2019-11-5',expenses:3,earning:4},
            ],
            graph_factor:'slip_date',
            tableFields: [
				{"value": "date", "text": "V_Date", "type": "date"},
				{"value": "voucher_id", "text": "V_Id", "type": "field"},
				// {"value": "type", "text": "Type", "type": "field"},
				// {"value": "ledger", "text": "Ledger", "type": "select"},
				// {"value": "to_ledger", "text": "TO / FROM", "type": "select"},
				{"value": "debit", "text": "Debit", "type": "field"},
				{"value": "credit", "text": "Credit", "type": "field"},
				// {"value": "due_by", "text": "Due By", "type": "field"},
			],
			graph_voucher_types:["sale","quotation","payment","purchase","receipt","contra","journal","contract","delivery-note","payment-record","receipt-record","contra-record","journal-record"],
			options_month:[],
			customer:'',
            data_rows:[],
            data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'voucher_id',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'voucher_id',
			data_rows_search_type: 'field',
			data_rows_search: '',
			data_rows_filter: {
				ledger: []
			},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_parent_group: [],
			options_voucher_id: [],
			options_ledger: [],
			options_customer: [],
		}
		this.prepareGraphDataSingleFactor = this.prepareGraphDataSingleFactor.bind(this)
    }
    componentWillMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
		}
		this.setMonthSelections()
		let options_customer = []
		Client.getAccountingMastersLedger(null,null,null,null,null,null,null,{group:[151,152]}).then(res=>{
			res.data_rows.forEach(function(data_row){
				options_customer.push({
					text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''),
					value: data_row.id
				})
			})
			this.setState({
				options_customer: options_customer
			})
		})
    }
    componentDidMount(){
        
        // this.prepareGraphDataSingleFactor(this.state.graph_factor)
        var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
				options_to_ledger: options_ledger
			},()=>{
				// if (this.props.ledger_voucher) {
				// 	this.setFilter({}, {name:'ledger',value:this.props.ledger_voucher})
				// }
			})
		})
		// this.getDataRows()
	}
	setMonthSelections(){
		var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
		})
	}
    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value})
    }
    changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
    }
    getDataRows(state){
		if (this.state.customer == "" || this.state.customer == null) {
			this.setState({message:'please select a customer'})
			
		} else {
			this.setState({loading_graph:true,message:false})
			
			console.log("entered have customer")
			Client.getDashboardCustomerReport(null,null,null,null,null,null,null,null,{customer:[this.state.customer]},{date:[this.state.start_date,this.state.end_date]},null).then(res=>{
				this.setState({voucher_records:res.customerVoucherReport,order_records:res.customerOrderReport,loading_graph:false},()=>{
					// this.prepareGraphDataSingleFactor('month','voucher_records')
					// this.prepareGraphDataSingleFactor('month','order_records')
				})
			})
		}
    }
    setFilter(e, data){
		if(this.props.ledger_voucher){
			console.log("data input",data)
		}
		var filter = {...this.state.data_rows_filter}
		filter[data.name] = [data.value || e.target.value]
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
    aggregateCountForKey(data_row,key){
        let returnalbe = []
        let aggregated_data = {}
        data_row.forEach(elem=>{
            if (aggregated_data.hasOwnProperty(elem[key])) {
				this.state.graph_voucher_types.forEach(type=>{

					aggregated_data[elem[key]][type] = parseInt(aggregated_data[elem[key]][type]) + parseInt(elem[type] || 0)
				})
				// aggregated_data[elem[key]].expenses = parseInt(aggregated_data[elem[key]].expenses) + parseInt(elem.expenses )
            } else {
				aggregated_data[elem[key]] = {}
				this.state.graph_voucher_types.forEach(type=>{
					aggregated_data[elem[key]] = {...aggregated_data[elem[key]],type:parseInt(elem[type])||0}
				})
                // aggregated_data[elem[key]] = {expenses:parseInt(elem.expenses)||0}
                // aggregated_data[elem[key]] = {...aggregated_data[elem[key]],earnings:parseInt(elem.earnings)||0}
            }
        })
        for(var key in aggregated_data){
			let row = { name:key}
			this.state.graph_voucher_types.forEach(type=>{
				row = {...row,[type]:aggregated_data[key][type]}
			})
            returnalbe.push(row)
        }
        return returnalbe
    }
    prepareGraphDataSingleFactor(axis="date",data_in_concern){
		// this.setState({['single_factor_graph']:false})
		console.log("customer:: started");
		
		var data_rows = []
		let filter={}
		let range = {slip_date :[this.state.start_date, this.state.end_date]}
		// Client.getOrderSlips(axis+'dash',0, null,"slip_date", "asc",null, null, null, filter,range).then((res)=>{
            data_rows =
                axis == "month"
                ?
                this.withMonth(this.state[data_in_concern])
                :
                    axis == "week"
                    ?
                    this.withWeekAndMonth(this.state.data_in_concern)
                    :
                        axis == "quater"
                        ?
                        this.withQuater(this.state[data_in_concern])
                        :
                        this.state[data_in_concern]
            
			let data_for_graph =this.aggregateCountForKey(data_rows,axis)
			
			console.log("master from dashboard customer",data_for_graph)
			this.setState({[ data_in_concern+'_single_factor_graph']:data_for_graph})
		// })
    }
    handleGraphDataChange(e,data){
        this.setState({
			[data.name]:data.value,
			// loading_graph:true
		},()=>{
            // alert('mode')
			// this.prepareGraphDataSingleFactor(this.state.graph_factor)
			this.getDataRows()
		})
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.month.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }

    withWeekAndMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.slip_date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
            
            let javascriptDate = new Date(parseInt(date[0]),parseInt(date[1])-1,parseInt(date[2]))
            console.log('week_number',javascriptDate.getWeekOfMonth())
			let temp = {...elem,week:'W'+(javascriptDate.getWeekOfMonth()+1     )+'-'+month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    render(){
        return(
            <Card>
                <Card.Header>
                    Customers
                </Card.Header>
                <Card.Content>
                                <div className="console-earning" style={{display:'flex'}}>
									<Input size="mini" type="date" name="start_date" label={{ content: 'FROM' }}
										placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
									<Input size="mini" type="date" name="end_date" label={{ content: 'TO' }}
										placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
									<Dropdown
										name="customer"
										options={this.state.options_customer}
										style={{height:'30px',minHeight:'0px',minWidth:'0px'}}
										className="drp1"
										search
										selection
										onChange={(e, data) => this.handleGraphDataChange(e, data)}
										value={this.state.customer}
										/>
                                </div>
                            {
                                !this.state.loading_graph
                                ?
                                <center>
									{
										this.state.message
										?
										<Message>
											<Message.Header>{this.state.message}</Message.Header>						
										</Message>
										:
										null
									}
									<span style={{textAlign:'center'}}>
										<span>Vourcher Amounts / Month</span>
										<BarChart width={400} height={280} data={this.state.voucher_records}
											margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
											<CartesianGrid strokeDasharray="10 10" />
											<XAxis  dataKey="month" >
												<Label angle={0} value='Month' position='insideBottom' style={{textAnchor: 'bottom'}} />
											</XAxis>
											<YAxis>
												<Label angle={-90} value='Amount' position='insideLeft' style={{textAnchor: 'middle'}} />
											</YAxis>
											<Tooltip />
											<Legend />
											{
												this.state.graph_voucher_types.map(type=>{
													return(<Bar type="monotone" dataKey={type} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />)
												})
											}
										</BarChart>
									</span>
									<span style={{textAlign:'center'}}>
										<span>Order Count / Month</span>
										<LineChart width={400} height={280} data={this.state.order_records}
											margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
											<CartesianGrid strokeDasharray="10 10" />
											<XAxis  dataKey="month" >
												<Label angle={0} value='Month' position='insideBottom' style={{textAnchor: 'bottom'}} />
											</XAxis>
											<YAxis>
												<Label angle={-90} value='Count' position='insideLeft' style={{textAnchor: 'middle'}} />
											</YAxis>
											<Tooltip />
											<Legend />
												<Line type="monotone" dataKey={'count'} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />)
												
										</LineChart>
									</span>
                                </center>
                                :
                                <Segment style={{width:'100%'}}>
                                    <Dimmer active>
                                    <Loader size="medium">Loading</Loader>
                                    </Dimmer>

                                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                </Segment>
                            }
                </Card.Content>
            </Card>
        )
    }
}
export default DashboardCustomer