import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Image, Button, Dropdown, Icon, Modal, Form, Segment,Label, Loader, Dimmer, Header, Grid} from 'semantic-ui-react';
import { ComposedChart,BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

export default class SaleView extends Component{
    constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "customer", "text": "Customer", "type": "select"},
				{"value": "voucher_id", "text": "Invoice Id", "type": "field"},
				{"value": "order_id", "text": "Order Id", "type": "field"},
				{"value": "payable_amount", "text": "Amount", "type": "field"},
				{"value": "status", "text": "Status", "type": "field"},
			],
			defaultSize: 'small',
			tableButtons: [
			],
			paymentReceipt: {paymentInvoices:[{}]},
			currentDataRow: {},
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'customer',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_customer: [],
			options_voucher_id: [],
			options_to_ledger: [],
			graph_customer:false,
			data_rows_branch:'',
			options_branch:[
			],
			options_branch_sort:[
			],
		}
    }
    componentDidMount(){
        if (this.props.data_row) {
            this.showDataRowDetails(0,false)
        }
    }
    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }
    handleInputChangePayment(e, data){
		var paymentReceipt = {...this.state.paymentReceipt};
		paymentReceipt[data.name] = e.target.value
		this.setState({
			paymentReceipt: paymentReceipt
		})
	}
    showDataRowDetails(index, print, heading){
		if(index == undefined || index == null)
			index = this.state.currentDataRowIndex || 0

        var sale ;
        if (this.props.data_row) {
            sale = this.props.data_row
        } else {
            sale = {...this.state.data_rows[index]};
        }
		var taxDetails = this.calculateTax(sale)

		this.setState({
			currentDataRowIndex: index,
			currentDataRow: sale
		})
		let v_id = sale.voucher_id.split('')
		let v_id_start = v_id.splice(0,3);
		v_id_start = v_id_start.join('')
		if(heading == null || heading == undefined || heading == ''){
			if( v_id_start== 'C-C'){
				heading = 'ESTIMATE';
			}
		}
		var taxDetailsView = '';
		taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>' +
												'<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount) + '</td></tr>'
			}
		}.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
						'<th>Amount</th></tr>'
		}

		sale.products.forEach(function(product, index) {
			if(taxDetails.length > 0){
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
								'</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td><td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
							'</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						${heading || "TAX INVOICE"}
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Invoice Number</td>
						<td><b>: ${sale.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Invoice Date</td>
						<td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Bill To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">

				${sale.customer_details.name}
				${
					 (sale.customer_details.branchSuffix && sale.customer_details.branchSuffix.trim() !="" && sale.customer_details.branchSuffix != null)
					 ?
						"("+sale.customer_details.branchSuffix.trim()+")"
					 :
					 ""
				}

				</p>
				<p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
					${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<div style="display: grid; grid-template-areas:'left left right''left left right''left left footer'; border-top: solid 1px #999; width:100%">
				<div style="grid-area:left; padding:5px 0 0 5px; border-right: solid 1px #999; position:relative">
					<div style="float:right; padding:2px 5px 0 0">E. & O.E.</div>
					Total in Words:<br/>
					<b>Rupees ${converter.toWords(sale.payable_amount)}</b>
					<br/>
					<br/>
					<div style="white-space:pre-line">${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}</div>
					<br/>
					<br/>
					<b>CHEQUE to be made in favour of SCIENCE2HOME PVT. LTD.</b><br/>
					Company's Bank Details:<br/>
					<b>Acc No: 39010200000238</b><br/>
					<b>IFSC Code: BARB0AMBARI‎</b><br/>
					<b>Bank Of Baroda</b><br/>
					Ambari, Guwahati
                    <br/>

					<div style="text-align:center; padding:2px 0px 5px 0; position:absolute; bottom:0; left:50%; transform:translateX(-50%)">SUBJECT TO GUWAHATI JURISDICTION</div>
				</div>
				<div style="grid-area:right; width:100%; margin:0; padding:5px 0 5px 0px">
					<table style="width:100%">
						<tr><td>Sub Total</td><td style="text-align:right">${parseFloat(Math.round(sale.taxable_amount * 100) / 100).toFixed(2)}</td></tr>
						${taxDetailsView}`
						if(sale.adjustment_name != null && sale.adjustment_name != '')
							invoiceHtml = invoiceHtml +
							`<tr><td>${sale.adjustment_name}</td><td style="text-align:right">${sale.adjustment_amount}</td></tr>`
						invoiceHtml = invoiceHtml +
						`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(sale.round_off * 100) / 100).toFixed(2)}</td></tr>
						<tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(sale.payable_amount * 100) / 100).toFixed(2)}</td></tr>
					</table>
				</div>
				<div style="grid-area:footer ;text-align:center; border-top:solid 1px #999; width:100%">
					<br/>
					<br/>
					<br/>
					<br/>
					<br/>
					Authorised Signatory
				</div>
			</div>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		this.setState({
			showSaleDetailsModal: true
		})

		setTimeout(function(){
			document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		}, 500);

		if(print){
			var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id;
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		}
	}

	printDeliveryNote(print){

		var index = this.state.currentDataRowIndex
        var sale ;
        if (this.props.data_row) {
            sale = this.props.data_row
        } else {
            sale = {...this.state.data_rows[index]};
        }
		var taxDetails = this.calculateTax(sale)

		var taxDetailsView = '';

		var productsTable =
		'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th></tr>'
		sale.products.forEach(function(product, index) {
			productsTable = productsTable +
			'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
							(product.description != null ? product.description : '') + '</td><td style="text-align:center">' + product.qty +
							'</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						DELIVERY NOTE
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Reference Number</td>
						<td><b>: ${sale.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Date</td>
						<td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Delivery To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
				<p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
					${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
						<br/>
						${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}
						<br/>
					</td>
					<td style="border-left:solid 1px #999; padding:0; text-align:center">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		// document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		// document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

		// if(print){
			var printwindow = window.open('','dhp_inv_delivery_note', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id + "_DELIVERY_NOTE";
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		// }
	}

	calculateTax(sale){
        console.log(sale);

		var sale = sale;
		var taxDetails = [];
		var subTotal = 0;
		var total = 0;
		var taxStateType = sale.customer_details.pos == "AS" ? 0 : 1;
		sale.products.forEach(function(element) {
			var taxDetail = {};
			var found = 0
			if(element.tax != 'N/A'){
				taxDetails.forEach(function(elem) {
					if(elem.taxPc == element.tax){
						found = 1;
						elem.taxStateType = taxStateType;
						elem.taxable_amount = Number(elem.taxable_amount) + (element.qty * element.rate)
						elem.tax_amount = elem.taxable_amount *  elem.taxPc / 100;
						elem.taxable_amount = parseFloat(Math.round(elem.taxable_amount * 100) / 100).toFixed(2);
						elem.tax_amount = parseFloat(Math.round(elem.tax_amount * 100) / 100).toFixed(2);
					}
				})
				if(found == 0){
					taxDetail['taxStateType'] = taxStateType;
					taxDetail['taxPc'] = element.tax;
					taxDetail['taxable_amount'] = element.qty * element.rate;
					taxDetail['taxable_amount'] = parseFloat(Math.round(taxDetail['taxable_amount'] * 100) / 100).toFixed(2);
					taxDetail['tax_amount'] = (element.qty * element.rate) * element.tax / 100;
					taxDetail['tax_amount'] = parseFloat(Math.round(taxDetail['tax_amount'] * 100) / 100).toFixed(2);
					taxDetails.push(taxDetail)
				}
			}
			subTotal = subTotal + (element.qty * element.rate);
		});

		taxDetails.forEach(function(elem) {
			total = total + Number(elem.tax_amount);
		});

		// sale.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
		// sale.total = Number(total) + Number(subTotal);
		// sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
		// sale.roundOff = Math.round(sale.total) - Number(sale.total);
		// sale.roundOff = parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2);
		// sale.payableAmount = Math.round(sale.total);
		// if(sale.adjustmentName != undefined && sale.adjustmentName != '')
		// 	sale.payableAmount = Number(sale.payableAmount) + Number(sale.adjustmentAmount || 0);
		// sale.payableAmount = parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2);

		return taxDetails
	}

	printPaymentReceipt(print, index){
		if(!print){
			this.setState({
				showPaymentReceiptModal: true
			})
			this.getPaymentDetails();
		}
		else if(print == 2 || this.state.paymentReceipt.paymentStatus == "DUE"){
			Client.addAccountingPaymentDetails(this.state.currentDataRow.invoiceId, this.state.paymentReceipt).then(res=>{
				this.getPaymentDetails();
			})
		}

		if(print == 1){
	        if(index != undefined){
                var sale ;
                if (this.props.data_row) {
                    sale = this.props.data_row
                } else {
                    sale = {...this.state.data_rows[index]};
                }
	            this.setState({
	                currentInvoiceIndex: index,
	                currentDataRow: sale
	            })
	        }
	        else{
	            index = this.state.currentDataRowIndex
	        }

            var sale ;
            if (this.props.data_row) {
                sale = this.props.data_row
            } else {
                sale = {...this.state.data_rows[index]};
            }
	        var taxDetails = this.calculateTax(sale)

	        var taxDetailsView = '';

	        var productsTable =
	        '<tr style="background:#CCC"><th style="min-width:10px">Invoice No.</th><th>Invoice Date</th><th>Invoice Amount</th><th>Payment Amount</th></tr>'
	        productsTable = productsTable +
	        '<tr><td style="text-align:center">' + sale.voucher_id + '</td><td style="text-align:center">' + sale.date + '</td>' +
							'<td style="text-align:center"> Rs.' + sale.payable_amount +
	                        '</td><td style="text-align:center"> Rs.' + this.state.paymentReceipt.amount + '</td></tr>'

	        var invoiceHtml = `
	        <!doctype html>
	        <html>
	        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
	            <table>
	                <tr>
	                    <td style="width:10%">
	                        <img style="width:100%" src=${logo}><img/>
	                    </td>
	                    <td>
							<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
	                        <p style="padding:0; margin:0 0 0 5px">
	                        R G B Road, Ganeshguri<br/>
	                        Guwahati, Assam - 781006<br/>
	                        GSTIN: 18AAXCS9739A1ZO<br/>
	                        PAN: AAXCS9739A<br/>
	                        MSME UAM No.: AS03D0001520<br/>
	                        Contact: +91-9954683549<br/>
	                        E-mail: contact@deskneed.com
	                        </p>
	                    </td>
	                    <td style="font-size:300%; text-align:right; padding-right:40px">
	                        PAYMENT RECEIPT
	                    </td>
	                </tr>
	            </table>

	            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
	                <table style="margin:0 0 0 5px">
	                    <tr>
	                        <td><b>Payment Date</b></td>
	                        <td>: ${this.state.paymentReceipt.date}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Reference Number</b></td>
	                        <td>: ${this.state.paymentReceipt.referenceNumber}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Payment Mode</b></td>
	                        <td>: ${this.state.paymentReceipt.paymentMode}</td>
	                    <tr>
						<tr>
	                        <td><b>Amount Received</b></td>
	                        <td>: Rs.${sale.payable_amount}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Amount Received In Words</b></td>
	                        <td>: Rupees ${converter.toWords(sale.payable_amount)}</td>
	                    <tr>
	                </table>
	            </div>

	            <table class="productsTable">
	                ${productsTable}
	            </table>

	            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
	                <tr>
	                    <td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
	                        <br/>
	                        ${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}
	                        <br/>
	                    </td>
	                    <td style="border-left:solid 1px #999; padding:0; text-align:center">
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        Authorised Signatory
	                        </div>
	                    </td>
	                </tr>
	            </table>

	        </body>
	        <style>
	            body{
	                margin: 0em;
	            }
	            *{
	                font-family: sans-serif
	            }
	            table{
	                border-collapse: collapse;
	            }
	            .productsTable, .productsTableInner{
	                width:100%;
	            }
	            .productsTable td{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTable th{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTableInner td{
	                border:none;
	            }
	            .productsTableInner th{
	                border:none;
	            }
	        </style>
	        </html>
	        `

	        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
	        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

	        // if(print){
	            var printwindow = window.open('','dhp_inv_payment_receipt', 'height=1000, width=1000');
	    		printwindow.document.write(
	                invoiceHtml
	    		);
	            printwindow.focus();
	            setTimeout(function() {
	                printwindow.document.title = sale.invoiceId + "_PAYMENT_RECEIPT";
	                printwindow.print();
	                printwindow.close();
	                printwindow.document.close();
	            }, 500);
	        // }
		}
    }

	getPaymentDetails(){
		Client.getAccountingPaymentDetails(this.state.currentDataRow.invoiceId).then(res=>{
			var paymentReceiptDetails = res.paymentDetails;
			res.paymentDetails.paymentInvoices.forEach(function(paymentInvoice){
				if(paymentInvoice.invoiceId == this.state.currentDataRow.invoiceId){
					paymentReceiptDetails.paymentAmount = paymentInvoice.paymentAmount
				}
			}.bind(this))
			this.setState({
				paymentReceipt: res.paymentDetails,
			})
		})
	}

    editSale(){
		// console.log(this.state.currentDataRow,"data");
		// alert('pause')
		let data_row = this.state.currentDataRow
		data_row['cust_branch'] = data_row['customer_details']
										?
									data_row['customer_details']['branchId']
										:
									data_row['branch']

		localStorage.setItem('currentInvoiceSale', JSON.stringify(this.state.currentDataRow));
		localStorage.setItem('currentInvoiceSaleEdit', 1);
		this.props.history.push('/accounting/vouchers/invoice/create')
	}

	cancelInvoice(){
		this.setState({loadingCancelInvoice: true})
		Client.cancelAccountingInvoiceSale(this.state.currentDataRow.voucher_id, this.state.cancelReason).then(res=>{
			let currentDataRow = this.state.currentDataRow
			currentDataRow['status'] = "cancelled"
			currentDataRow.cancelReason = this.state.cancelReason
			this.setState({currentDataRow},()=>{
				if (this.props.handleCancel) {
					this.props.handleCancel(currentDataRow)
				}
			})
			// var state = {...this.state}
			// var data_rows = [...state.data_rows]
			// var currentDataRow = {...state.currentDataRow}
			// currentDataRow.status = "cancelled";
			// currentDataRow.debit = 0;
			// currentDataRow.credit = 0;
			// data_rows[state.currentDataRowIndex] = currentDataRow
			// this.setState({
			// 	loadingCancelInvoice: false,
			// 	showCancelInvoiceOption: false,
			// 	data_rows: data_rows,
			// 	totalSale: state.totalSale - currentDataRow.payable_amount,
			// 	totalTax: state.totalTax - currentDataRow.tax_amount
			// })
			// this.showDataRowDetails(state.currentDataRowIndex);
		})
	}
	changeStateVariable(e, data, stopLoad){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name || e.target.name] = data.value || e.target.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field" || data.name == "data_rows_search"){
			var filter = this.state.data_rows_filter
			delete filter.cust_branch
			state.data_rows_filter = filter
			state.showBranch = 0
		}
		//SELECTIVE OPERATIONS
		if(this.state.data_rows_search_field=="customer" && data.name == "data_rows_search"){
			state.showBranch = 1
			state.loading_branch = 1
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch_sort:options_branch,
						loading_branch:0
					})
				} else {
					this.setState({
						showBranch:0,
						options_branch_sort:''
					})
				}
			})
		}
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
			state['data_rows_search']=''
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name == "cust_branch"){
			if(data.value != "" && data.value != undefined){
				var filter = {...this.state.data_rows_filter, cust_branch:[data.value]}
			}
			else
				var filter = {...this.state.data_rows_filter, cust_branch:[]}
			state.data_rows_filter =filter
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state);
		if(!stopLoad)
			this.getDataRows(state);
	}
    render(){
        return <>
        {
            this.state.currentDataRow.status != "cancelled"
            ?
            <div>
                <h3 style={{width:'100%', backgroundColor:'blue', textAlign:'center', color:'white', padding:'5px 0'}}>
    				PAYMENT MADE: {this.state.currentDataRow.paidAmount + ' / ' + this.state.currentDataRow.payable_amount}
    			</h3>

                <Button size={this.state.defaultSize} onClick={() => this.editSale()}><Icon name='edit'/>EDIT</Button>
                <Button.Group size={this.state.defaultSize}>
                    <Button size={this.state.defaultSize} onClick={() => this.showDataRowDetails(null, 1)}>
                        <Icon name='print'/> INVOICE
                    </Button>
                    <Button.Or />
                    <Button size={this.state.defaultSize} onClick={() => this.showDataRowDetails(null, 1, "PRO FORMA")}>
                        <Icon name='print' /> PRO FORMA
                    </Button>
                    <Button.Or />
                    {/* <Button size={this.state.defaultSize} onClick={() => this.printDeliveryNote(1)}><Icon name='print' />
                        D NOTE
                    </Button> */}
                </Button.Group>
                <Button size={this.state.defaultSize} onClick={() => this.printPaymentReceipt(0)}>PAYMENT RECEIPT</Button>
                {
					// new rule:: can't cancel invoice if the paid amount is > or = to 50% of payable amount
					this.state.currentDataRow.paidAmount < this.state.currentDataRow.payable_amount
                    // this.state.currentDataRow.status != "paid"
                    ?
                        this.state.showCancelInvoiceOption
                        ?
                        <div>
                            <Input type="text" style={{width:'100%'}} name="cancelReason" placeholder="Reason for cancelling" value={this.state.cancelReason} onChange={(e, data) => this.changeStateVariable(e, data, true)}/>
                            <Button color="red" size={this.state.defaultSize} onClick={() => this.cancelInvoice()} loading={this.state.loadingCancelInvoice}>CONFIRM CANCEL</Button>
                        </div>
                        :
                        <Button color="red" size={this.state.defaultSize} onClick={() => this.setState({showCancelInvoiceOption: true})}>CANCEL INVOICE</Button>
                    :
                    null
                }
            </div>
            :
            <h3 style={{width:'100%', backgroundColor:'red', textAlign:'center', color:'white', padding:'5px 0'}}>
				CANCELLED
				<br/>
				{this.state.currentDataRow.cancelReason || this.state.currentDataRow.cancel_reason}
			</h3>
        }

        <div id="showInvoiceContainer" >
        </div>
        <Modal size="small" open={this.state.showPaymentReceiptModal} onClose={() => this.setState({showPaymentReceiptModal:false})}>
					{
						this.state.paymentReceipt.paymentInvoices.map((receiptInvoice) => (
							<div style={{textAlign:"center"}}>
								Payment ID: {receiptInvoice.paymentId} - Payment Status: {this.state.paymentReceipt.paymentStatus}<br/>
								Payment Date: {receiptInvoice.paymentDate} - Payment Mode: {receiptInvoice.paymentMode}<br/>
								Payment Amount: {receiptInvoice.paymentAmount}<br/>
								TDS Amount: {receiptInvoice.tdsAmount}<br/>
								GST TDS: {receiptInvoice.gstTdsAmount}
							</div>
						))
						// this.state.paymentReceipt.paymentInvoices.length > 0
						// ?
						// LAST PAYMENT DETAILS
						// <div>{this.state.paymentReceipt.paymentStatus}  {this.state.paymentReceipt.paymentInvoices[0].paymentAmount}</div>
						// :
						// null
					}
					<Modal.Content>
						<Input name="date" fluid type="date" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment Date" defaultValue={this.state.paymentReceipt.paymentDate || this.state.currentDataRow.date}/>
						<Input name="amount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Amount" defaultValue={this.state.paymentReceipt.paymentAmount || this.state.currentDataRow.payable_amount}/>
						<Input name="tdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="IT TDS Amount" defaultValue={this.state.paymentReceipt.tdsAmount}/>
						<Input name="gstTdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="GST TDS Amount" defaultValue={this.state.paymentReceipt.gstTdsAmount}/>
						<Input name="paymentMode" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Cash/Credit/Cheque/NEFT" defaultValue={this.state.paymentReceipt.paymentMode}/>
						<Input name="referenceNumber" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Reference Number (If Any)" defaultValue={this.state.paymentReceipt.referenceNumber}/>
						<Input name="paymentId" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment ID (If Any)" defaultValue={this.state.paymentReceipt.paymentId}/>
						<Button fluid onClick={() => this.printPaymentReceipt(1)} primary>PRINT</Button>
						{
							this.state.paymentReceipt.paymentStatus == "PAID"
							?
							<Button fluid onClick={() => this.printPaymentReceipt(2)} primary>ADD TO PAYMENTS</Button>
							:
							null
						}
					</Modal.Content>
				</Modal>
        </>
    }

}
