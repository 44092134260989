import React from 'react';
import moment from 'moment';
import '../assets/calendar.css';
import { Dropdown, Dimmer,Loader,Grid, Select, Form, Label , Checkbox, Button } from 'semantic-ui-react'

export default class Calendar extends React.Component {
    state = {
        dateContext: moment(),
        today: moment(),
        showMonthPopup: false,
        showYearPopup: false,
        selectedDay: null
    }

    constructor(props) {
        super(props);
        this.width = props.width || "350px";
        this.style = props.style || {};
        this.style.width = this.width; // add this
    }


    weekdays = moment.weekdays(); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
    weekdaysShort = moment.weekdaysShort(); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    months = moment.months();

    year = () => {
        return this.state.dateContext.format("Y");
    }
    month = () => {
        return this.state.dateContext.format("MMMM");
    }
    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    }
    currentDate = () => {
        console.log("currentDate: ", this.state.dateContext.get("date"));
        return this.state.dateContext.get("date");
    }
    currentDay = () => {
        return this.state.dateContext.format("D");
    }

    firstDayOfMonth = () => {
        let dateContext = this.state.dateContext;
        let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
        return firstDay;
    }

    setMonth = (month) => {
        let monthNo = this.months.indexOf(month);
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("month", monthNo);
        this.setState({
            dateContext: dateContext
        },()=>{
            this.props.onMonthChange && this.props.onMonthChange(this.state.dateContext);
        });
    }
    onSelectChange = (e, data) => {
        this.setMonth(data.value);
        

    }
    SelectMonthList = () => {
        let monthsOptions=[]
        this.months.map((data) => {
                const month = {key:data,value:data,text:data}
                monthsOptions.push(month)
        })
        return (
            <Dropdown 
                onChange={this.onSelectChange}
                value={this.month()}
                search selection options={monthsOptions} 
                fluid wide
                />
        );
    }

    setYear = (year) => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("year", year);
        this.setState({
            dateContext: dateContext
        },()=>{
            this.props.onYearChange && this.props.onYearChange(this.state.dateContext);
        })
    }
    onYearChange = (e,data) => {
        this.setYear(data.value);
        //this.props.onYearChange && this.props.onYearChange(e, data.value);
    }
    YearNav = () => {
        var d = new Date();
        var n = d.getFullYear();
        let years = []
        for (let index = 2017; index <= n; index++) {
            const year = {key:index.toString(),value:index.toString(),text:index.toString()}
            years.push(year);  
        }
        return (
                <Dropdown 
                    onChange={this.onYearChange}
                    value={this.state.dateContext.format("YYYY")}
                    search selection options={years}
                    fluid wide 
                />
        );
    }

    onDayClick = (e, day) => {
        this.setState({
            selectedDay: day
        }, () => {
            console.log("SELECTED DAY: ", this.state.selectedDay);
        });

        this.props.onDayClick && this.props.onDayClick(e, day);
    }

    render() {
        // Map the weekdays i.e Sun, Mon, Tue etc as <td>
        let weekdays = this.weekdaysShort.map((day) => {
            return (
                <td key={day} className="week-day">{day}</td>
            )
        });

        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(
                <td key={i * 80} className="emptySlot">
                    {""}
                </td>
            );
        }
        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            var day = this.props.attendanceList[d-1]
            let currentClass = (d == this.currentDay() ? "day current-day": "day");
            let selectedClass = (d == this.state.selectedDay ? " selected-day " : "")
            let absentClass = (day.time_in == null?" absent ":"")
            let presentClass = day.time_in != null && day.time_out != null ?" present ":""
            let justInClass = day.time_in != null && day.time_out == null ?" just-in ":""
            daysInMonth.push(
                <td key={d} className={currentClass + selectedClass + absentClass + justInClass + presentClass} 
                onClick={(e)=>{this.onDayClick(e, d)}}>
                    <span style={{position:'relative'}}>
                        <small style={{position:'absolute',top:'-15px',left:'-12px'}} >{day.leave_type || ''}</small>
                        {d}
                    </span>
                </td>
            );
        }

        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if ((i % 7) !== 0) {
                cells.push(row);
            } else {
                let insertRow = cells.slice();
                rows.push(insertRow);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                let insertRow = cells.slice();
                rows.push(insertRow);
            }
            if ((i % 7) == 0) {
                console.log('sunday');
            }
        });

        let trElems = rows.map((d, i) => {
            return (
                <tr key={i*100}>
                    {d}
                </tr>
            );
        })

        return (
            <div className="calendar-container" style={this.style}>
                <table className="calendar">
                    <thead>
                        <tr className="calendar-header">
                            <td colSpan="3">
                                <this.SelectMonthList />
                            </td>
                            <td colSpan="1"></td>
                            <td colSpan="3">
                                <this.YearNav />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {weekdays}
                        </tr>
                        {
                            this.props.show?
                                trElems
                            :
                            <Dimmer active >
                                <Loader size='large'>Loading Attendance List</Loader>
                            </Dimmer>
                        }
                    </tbody>
                </table>

            </div>

        );
    }
}