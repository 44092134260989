import React, {Component} from 'react';
import '../style/order-slip.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
// import {Checkbox,Input, Container,Table, Button, Dropdown, Modal, Icon, Form, Loader, Segment, Dimmer, Image,Placeholder, Label,Responsive,Card, Grid} from 'semantic-ui-react';
import {Grid,Container,Dimmer,Popup,Table, Modal,Button,Checkbox,Loader,Image,Segment,Icon, Dropdown}  from "semantic-ui-react";
import RemoveImage from '../assets/blue/ic_minus.png';
import OrderSlipUpdate from "./order-slip-update";
import OrderSlipPurchase from "./order-slip-purchase";
import { BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

// import {
// 	DateInput,
// 	TimeInput,
// 	DateTimeInput,
// 	DatesRangeInput
//   } from 'semantic-ui-calendar-react';
const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

export default class FinancialReprot extends Component{
    constructor(props){
        super(props)
        this.state ={
            financialsDataList: [],
            data_rows:[],
            labels:{},
            current_month_data:false,
            show_for_type:'All',
            show_for_group_name:'All',
        }
    }
    componentDidMount(){
        var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
        })
        Client.getFinancialReports("graph_data",null,null,null,null,null,null,null,null,null,null,).then((res)=>{
			let data_rows = res.data_rows
            this.setState({data_rows},()=>{
                this.prepareGraphData("type","month",this.state.show_for_group_name,'group_name',this.state.show_records)
                this.prepareGraphData("group_name","month",this.state.show_for_type,'type',true)
            })
        })
    }
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
	occuranceOfLabelInAxis(label_value,lable_name,axis_value,axis_name,data_rows){
		var matched = data_rows.filter((elem)=>{
			return elem[axis_name] == axis_value && elem[lable_name] == label_value
		})
		return matched.length
	}
	prepareGraphData(label="staff",axis="date",show_for=false,basis='group_name',records=false){
        this.setState({[axis+'_graph']:false})
        var data_rows = this.state.data_rows
        var master_data = {}
        var distinct_axises = this.getDistictIn(axis,data_rows)
        for(var key in distinct_axises){
            var distinct_axis_data = distinct_axises[key]
            master_data[key] = this.getDistictIn(label,distinct_axis_data)
        }
        let temp_labels = []

        let data_for_graph = []
        for(var key in master_data){
            var value = master_data[key]
            var mapping = {}
            for(var key1 in value){
                temp_labels.push(key1)
                let itterateable =  show_for
                                    ?
                                        show_for == 'All'
                                        ?
                                        value[key1]
                                        :
                                        value[key1].filter(elem=>{return elem[basis] == show_for})
                                    :
                                    value[key1]
                let sum = itterateable.reduce((a,b)=>{return parseInt(a) + parseInt(b.sum_payable_amount)}, 0)/ 1000
                // console.log(records)
                if ( records ? true : !key1.includes("_record") ) {
                    console.log(key1,records)
                    if(sum > 0) {       // only non zero values go in graph data
                        mapping[key1] = sum
                    }
                }
            }
            data_for_graph.push({
                name:key, ...mapping
            })
        }
        let labels  = [... new Set(temp_labels)]
        this.setState({
            [(show_for?'individual_':'')+label + '_graph']:data_for_graph,
            [label+"_label"] : labels
        })
    }

	range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
    }
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    setCurrentMonthData(e,data){
        this.setState({current_month_data:false,current_month:null},()=>{
            let current_month_data = this.state.data_rows.filter(data_row=> {return data_row.month == data.value} )
            let purchase_data = [], sale_data = [], receipts_agst_sale_data = [], payments_agst_purchase_data = [], loans_received_data = [];
            let p_and_a_data = [], salary_data = [], loans_repaid_data = [], duties_and_taxes_data = [], other_expenses_data = [], uncategorised_data = [];
            let purchase = 0,sale = 0,payment=0,payments_agst_purchase = 0 ,receipts_agst_sale = 0, receipt = 0;
            let salary=0, p_and_a = 0,other_expenses = 0, loans_repaid = 0, loans_received = 0, duties_and_taxes = 0, uncategorised = 0;
            current_month_data.forEach(elem=>{
                if(elem.type == 'purchase'){
                    purchase += parseFloat(elem.sum_payable_amount);
                    purchase_data.push(elem);
                }
                else if(elem.type == 'sale'){
                    sale += parseFloat(elem.sum_payable_amount);
                    sale_data.push(elem);
                }
                else if(elem.type == 'receipt_record' && (elem.group_name == "Sundry Debitor" || elem.group_name == "Sundry Creditor")){
                    receipts_agst_sale += parseFloat(elem.sum_payable_amount);
                    receipts_agst_sale_data.push(elem);
                }
                else if(elem.type == 'payment_record' && (elem.group_name == "Sundry Debitor" || elem.group_name == "Sundry Creditor")){
                    payments_agst_purchase += parseFloat(elem.sum_payable_amount);
                    payments_agst_purchase_data.push(elem);
                }
                else if(elem.type == 'payment_record' && (elem.group_name == "EMPLOYEE PETROL AND M")){
                    p_and_a += parseFloat(elem.sum_payable_amount);
                    purchase_data.push(elem);
                }
                else if(elem.type == 'payment_record' && (elem.group_name == "EMPLOYEE SALARY")){
                    salary += parseFloat(elem.sum_payable_amount);
                    salary_data.push(elem);
                }
                else if(elem.type == 'receipt_record' && (elem.group_name.toLowerCase().includes("loans"))){
                    loans_received += parseFloat(elem.sum_payable_amount);
                    loans_received_data.push(elem);
                }
                else if(elem.type == 'payment_record' && (elem.group_name.toLowerCase().includes("loans"))){
                    loans_repaid += parseFloat(elem.sum_payable_amount);
                    loans_repaid_data.push(elem);
                }
                else if((elem.type == 'payment_record' || elem.type == "journal_record") && (elem.group_name.toLowerCase().includes("duties and taxes"))){
                    duties_and_taxes += parseFloat(elem.sum_payable_amount);
                    duties_and_taxes_data.push(elem);
                }
                else if((elem.type == 'payment_record' || elem.type == "journal_record") && (elem.group_name.toLowerCase().includes("expense"))){
                    other_expenses = other_expenses + parseFloat(elem.sum_payable_amount);
                    other_expenses_data.push(elem);
                }
                else{
                    uncategorised = uncategorised + parseFloat(elem.sum_payable_amount);
                    uncategorised_data.push(elem);
                }
            })
            console.log(current_month_data)
            let less_payment_made  = purchase - payments_agst_purchase
            let less_reciepts_done = sale - receipts_agst_sale
            let cash_flow = receipts_agst_sale - payments_agst_purchase
            let gross_profit = sale - purchase
            let net_profit = gross_profit - (p_and_a + salary + other_expenses)
            let cash_burn = parseFloat(salary) + parseFloat(p_and_a) + parseFloat(other_expenses)
            current_month_data = {
                sale:sale.toFixed(2),
                purchase:purchase.toFixed(2),
                less_payment_made:less_payment_made.toFixed(2),
                less_reciepts_done:less_reciepts_done.toFixed(2),
                cash_flow:cash_flow.toFixed(2),
                gross_profit:gross_profit.toFixed(2),
                other_expenses:other_expenses.toFixed(2),
                salary:salary.toFixed(2),
                petrol_and_allowances_paid:p_and_a.toFixed(2),
                net_profit:net_profit.toFixed(2),
                loans_repaid: loans_repaid.toFixed(2),
                loans_received: loans_received.toFixed(2),
                cash_burn: cash_burn.toFixed(2),
                duties_and_taxes: duties_and_taxes.toFixed(2),
                uncategorised: uncategorised.toFixed(2),
                sale_data:sale_data,
                purchase_data:purchase_data,
                other_expenses_data:other_expenses_data,
                salary_data:salary_data,
                petrol_and_allowances_paid_data:p_and_a_data,
                loans_repaid_data: loans_repaid_data,
                loans_received_data: loans_received_data,
                duties_and_taxes_data: duties_and_taxes_data,
                uncategorised_data: uncategorised_data,
            }
            this.setState({
                current_month_data,
                current_month:data.value
            })
        })
    }
    render(){
        return(
            <div>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Container textAlign="center" >
                                <span >

                                    MONTH WISE TYPE WISE TRANSACTION REPORTS

                                </span>  <br/>
                                <Checkbox slider checked={this.state.show_records}
                                    style={{marginRight:'15px'}}
                                    onChange={()=>{
                                        this.setState({show_records:!this.state.show_records},()=>{
                                            this.prepareGraphData('type','month',this.state.show_for_group_name,'group_name',this.state.show_records)
                                        })
                                    }}
                                    />
                                <Dropdown
                                    style={{marginLeft:'5px', }}
                                    size="small"
                                    options={[{text:'All',value:'All'},...this.state.group_name_label?this.state.group_name_label.map(name=>({text:name,value:name})):[]]}
                                    search
                                    selection
                                    defaultValue="All"
                                    placeholder="Select A Group"
                                    onChange={(e,data)=>{
                                        this.setState({show_for_group_name:data.value},()=>{
                                            this.prepareGraphData('type','month',this.state.show_for_group_name,'group_name',this.state.show_records)
                                        })
                                    }}
                                />

                                {
                                    this.state.individual_type_graph
                                    ?
                                    <LineChart width={500} height={300} data={this.state.individual_type_graph}
                                        margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="10 10" />
                                        <XAxis dataKey="name" />
                                        <YAxis label={{ value: 'Thousands', angle: -90, position: 'insideLeft' }}/>
                                        <Tooltip />
                                        <Legend />
                                        {

                                            this.state.type_label.map(
                                                (type,i)=>
                                                    <Line type="monotone" dataKey={type} fill={this.getRandomColor()} />
                                            )
                                        }
                                    </LineChart>
                                    :
                                    <Segment>
                                        <Dimmer active>
                                            <Loader size='medium'>Loading</Loader>
                                        </Dimmer>
                                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                    </Segment>
                                }
                            </Container>
                        </Grid.Column>
                        <Grid.Column >
                            <Container textAlign="center">
                                <span >
                                    MONTH WISE TYPE WISE TRANSACTION REPORTS
                                </span>  <br/>
                                <Dropdown
                                    style={{marginLeft:'5px', }}
                                    size="small"
                                    options={[{text:'All',value:'All'},...this.state.type_label?this.state.type_label.map(name=>({text:name,value:name})):[]]}
                                    search
                                    selection
                                    defaultValue="All"
                                    placeholder="Select A Type"
                                    onChange={(e,data)=>{
                                        this.setState({show_for_type:data.value},()=>{
                                            this.prepareGraphData('group_name','month',this.state.show_for_type,'type',true)
                                        })
                                    }}
                                />
                                {
                                    this.state.individual_group_name_graph
                                    ?
                                    <LineChart width={500} height={300} data={this.state.individual_group_name_graph}
                                        margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="10 10" />
                                        <XAxis dataKey="name" />
                                        <YAxis label={{ value: 'Thousands', angle: -90, position: 'insideLeft' }}/>
                                        <Tooltip />
                                        <Legend />
                                        {

                                            this.state.group_name_label.map(
                                                (group_name,i)=>
                                                    <Line type="monotone" dataKey={group_name} fill={this.getRandomColor()} />
                                            )
                                        }
                                    </LineChart>
                                    :
                                    <Segment>
                                        <Dimmer active>
                                            <Loader size='medium'>Loading</Loader>
                                        </Dimmer>
                                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                    </Segment>
                                }
                            </Container>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Dropdown
                                options={this.state.options_month}
                                value={this.state.current_month}
                                selection
                                placeholder="Select A Month"
                                onChange={(e,data)=>{this.setCurrentMonthData(e,data)}}
                                />
                            <br/>
                            <br/>
                            {
                                this.state.current_month_data
                                ?
                                <Grid columns={6} divided style={{textAlign: 'center'}}>
                                    <Grid.Row>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.sale_data})}>
                                            <Popup
                                                content='SALE' trigger={<Icon basic name='info' />} /> <br/>
                                            Sale<br/>
                                            { this.state.current_month_data.sale }
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.sale_data})}>
                                            <Popup
                                                content='PURCHASE' trigger={<Icon basic name='info' />} /> <br/>
                                            Purchase<br/>
                                            { this.state.current_month_data.purchase }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Popup
                                                content='SALE - PURCHASE' trigger={<Icon basic name='info' />} /> <br/>
                                            Gross Profit (Before GST & Other Tax)<br/>
                                            {this.state.current_month_data.gross_profit}
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.salary_data})}>
                                            <Popup
                                                content='EMPLOYEE SALARIES PAID' trigger={<Icon basic name='info' />} /> <br/>
                                            Salary<br/>
                                            {this.state.current_month_data.salary}
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.petrol_and_allowances_paid_data})}>
                                            <Popup
                                                content='EMPLOYEE PETROL AND ALLOWANCES PAID' trigger={<Icon basic name='info' />} /> <br/>
                                            Petrol And Allowances Paid<br/>
                                            {this.state.current_month_data.petrol_and_allowances_paid}
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.other_expenses_data})}>
                                            <Popup
                                                content='EXPENSES OTHER THAN SALARIES AND ALLOWANCES' trigger={<Icon basic name='info' />} /> <br/>
                                            Other Expenses<br/>
                                            { this.state.current_month_data.other_expenses}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.payments_agst_purchase_data})}>
                                            <Popup
                                                content='PURCHASES - PAYMENTS AGST PURCHASE' trigger={<Icon basic name='info' />} /> <br/>
                                            Less Payment Made<br/>
                                            { this.state.current_month_data.less_payment_made }
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.receipts_agst_sale_data})}>
                                            <Popup
                                                content='SALES - RECEIPTS AGST SALE' trigger={<Icon basic name='info' />} /> <br/>
                                            Less Receipts Done<br/>
                                            { this.state.current_month_data.less_reciepts_done }
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.duties_and_taxes_data})}>
                                            <Popup
                                                content='Duties and Taxes Paid' trigger={<Icon basic name='info' />} /> <br/>
                                            Duties & Taxes Paid<br/>
                                            { this.state.current_month_data.duties_and_taxes}
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Popup
                                                content='GROSS PROFIT LESS SALARIES, ALLOWANCES, OTHER EXPENSES, TAXES PAID' trigger={<Icon basic name='info' />} /> <br/>
                                            Net Profit (Before GST & Other Tax)<br/>
                                            {this.state.current_month_data.net_profit}
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Popup
                                                content='RECEIPTS - PAYMENTS' trigger={<Icon basic name='info' />} /> <br/>
                                            Cash Flow<br/>
                                            { this.state.current_month_data.cash_flow }
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Popup
                                                content='NET PROFIT - SALARIES + ALLOWANCES + OTHER EXPENSES - DUTIES AND TAXES PAID' trigger={<Icon basic name='info' />} /> <br/>
                                            CASH BURN<br/>
                                            {this.state.current_month_data.cash_burn}
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.loans_received_data})}>
                                            <Popup
                                                content='LOANS REPAID' trigger={<Icon basic name='info' />} /> <br/>
                                            LOANS RECEIVED<br/>
                                            {this.state.current_month_data.loans_received}
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.loans_repaid_data})}>
                                            <Popup
                                                content='LOANS REPAID' trigger={<Icon basic name='info' />} /> <br/>
                                            LOANS REPAID<br/>
                                            {this.state.current_month_data.loans_repaid}
                                        </Grid.Column>
                                        <Grid.Column onClick={() => this.setState({showFinancialsData: true, financialsDataList: this.state.current_month_data.uncategorised_data})}>
                                            <Popup
                                                content='UNCATEGORISED' trigger={<Icon basic name='info' />} /> <br/>
                                            UNCATEGORISED<br/>
                                            {this.state.current_month_data.uncategorised}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>                                    :
                                null
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

                <Modal open={this.state.showFinancialsData} onClose={() => this.setState({showFinancialsData: false})}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Month</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                                <Table.HeaderCell>Group</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {
                            this.state.financialsDataList.map((financialsData) => (
                                <Table.Row>
                                    <Table.Cell>{financialsData.month}</Table.Cell>
                                    <Table.Cell>{financialsData.type}</Table.Cell>
                                    <Table.Cell>{financialsData.group_name}</Table.Cell>
                                    <Table.Cell>{financialsData.sum_payable_amount}</Table.Cell>
                                </Table.Row>
                            ))
                        }
                        </Table.Body>
                    </Table>
                </Modal>
            </div>
        )
    }
}
