
import React,{Component} from 'react';
import { Header, Modal } from 'semantic-ui-react';
let Barcode = require('react-barcode');
class ProductDetails extends Component{
    constructor(props){
        super(props);
        this.state={
            addVariant:false,
            check:0,
            variants:props.variants,
            selectedVariant:props.variants[0],
            productName:props.productName
        }
        this.handleChange=this.handleChange.bind(this);
    }
    handleChange(e){

        this.setState({
            selectedVariant:this.state.variants[e.target.value],
            check:e.target.value
        })
    }
    handleVariantAddClick(){
        this.setState({
            addVariant:true
        })
    }

    render(){
        return(
            <Modal size="small" defaultOpen onClose={this.props.onClose}>
                {
                    this.state.addVariant?(
                        <Modal.Content image>
                            <img alt="thumbnail"  height={`200px`} width={`200px`} src={this.state.selectedVariant.image?this.state.selectedVariant.image:'http://www.agronas.pl/img/brakfoto.png'} />
                            <Modal.Description style={{paddingLeft:`20px`}}>
                                <form className="ui form">
                                        <div className="field">
                                            <label>Variant</label>
                                            <input required type="text" name="variant"  placeholder="Kg/lt/color"/>
                                        </div>
                                        <div className="field">
                                            <label>Price(Rs)</label>
                                            <input required type="text" name="price"   placeholder="Price"/>
                                        </div>
                                    <div className="field">
                                        <label>Stock</label>
                                        <input required type="text" name="stock"   placeholder=""/>
                                    </div>
                                </form>
                            </Modal.Description>
                    </Modal.Content>):(
                        <Modal.Content image>
                        <img alt="thumbnail"  height={`200px`} width={`200px`} src={this.state.selectedVariant.image?this.state.selectedVariant.image:'http://www.agronas.pl/img/brakfoto.png'} />
                        <Modal.Description style={{paddingLeft:`20px`}}>
                            <button onClick={this.handleVariantAddClick.bind(this)} className="ui right floated green basic button">
                                Add Variant
                            </button>
                            <Header as="h1">{this.state.productName}</Header>
                            {
                                this.state.variants.length>1?(
                                    <div>
                                        <form className="ui form">
                                            <div className="inline fields">
                                                <label>Variants</label>
                                                {
                                                    this.state.variants.map((v,index)=>{
                                                        return(
                                                            <div key={index}  className="field">
                                                                <div className="ui radio checkbox">
                                                                    <input checked={+this.state.check===index}  key={index} onChange={this.handleChange} type="radio" name="variant" value={index}/>
                                                                    <label>{v.quantity}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </form>
                                        <p><strong>Price</strong>&nbsp;&nbsp;{this.state.selectedVariant.price}</p>
                                        <p><strong>Availability</strong>&nbsp;&nbsp;{this.state.selectedVariant.stock==-2?"Always" +
                                            " Available":this.state.selectedVariant.stock}</p>
                                        <div><span style={{marginTop:'-10px'}}><strong >Barcode</strong></span>&nbsp;&nbsp;&nbsp;</div>
                                        <Barcode value={this.state.selectedVariant.barcode}marginTop={10} textMargin={0} height={20} width={1} fontSize={12} margin={0} />
                                        <p>{this.state.selectedVariant.description}</p>
                                    </div>
                                ):(
                                    <div>
                                        <p><strong>Price</strong>&nbsp;&nbsp;{this.state.selectedVariant.price}</p>
                                        <div><span style={{marginTop:'-10px'}}><strong >Barcode</strong></span>&nbsp;&nbsp;&nbsp;</div>
                                        <Barcode value={this.state.selectedVariant.barcode}marginTop={10} textMargin={0} height={20} width={1} fontSize={12} margin={0} />
                                        <p><strong>Availability</strong>&nbsp;&nbsp;{this.state.selectedVariant.stock==-2?"Always" +
                                            " Available":this.state.selectedVariant.stock}</p>
                                        <p>{this.state.selectedVariant.description}</p>
                                    </div>
                                )
                            }

                        </Modal.Description>
                    </Modal.Content>)
                }

            </Modal>
        )
    }
}
export default ProductDetails;
