import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Image, Label, Segment, TableHeader} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import AccountingLedgerVouchers from './accounting-ledger-vouchers'
import Due from './due_report';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingTrialBalance extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			start_date: '2019-04-01',
			end_date: '2020-03-31',
			multipleAddMode: true,
			data_rows: [],
			new_data_row: {date: dateVaue, new_data_rows:[{}]},
			tableFields: [
				{"value": "ledger", "text": "Ledger", "type": "select"},
				{"value": "group", "text": "Group", "type": "select"},
				{"value": "opening_balance", "text": "Opening Balance", "type": "field"},
				{"value": "credit", "text": "Credit", "type": "field"},
				{"value": "debit", "text": "Debit", "type": "field"},
				{"value": "period_difference", "text": "Period Difference", "type": "field"},
				{"value": "current_balance", "text": "Current Balance", "type": "field"}
			],
			defaultSize: 'mini',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'ledger',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'group',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 25, value: 25},
				{text: 50, value: 50},
				{text: 250, value: 250},
				{text: 500, value: 500},
				{text: 1000, value: 1000},
				{text: 1500, value: 1500},
				{text: 2000, value: 2000},
			],
			// options_parent_group: [],
			// options_voucher_id: [],
			options_ledger: [],
			options_group: [],
		}
	}

	componentDidMount() {
		var options_group = []
		var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
			})
		})
		Client.getAccountingMastersGroup(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_group.push({text: data_row.group_name, value: data_row.id})
			})
			this.setState({
				options_group: options_group,
			})
		})
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data, index){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value || data.checked;
		if(this.state.multipleAddMode == 0 || index == undefined){
			index = 0
		}
		var new_data_rows = [...this.state.new_data_row.new_data_rows]
		new_data_rows[index][data.name] = data.value;
		new_data_row.new_data_rows = new_data_rows
		this.setState({
			new_data_row: new_data_row,
			multipleAddMode: data.name == 'multiple' ? data.checked :  this.state.multipleAddMode
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		// this.setState({
		// 	showCreateDataModal: true,
		// 	new_data_row: this.state.data_rows[index],
		// 	editMode: true
		// })
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createLead({...state.new_data_row, multiple: this.state.multipleAddMode}, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {date: state.new_data_row.date, new_data_rows:[{}]}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getAccountingTrialBalance(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search,
			state.data_rows_filter, state.start_date, state.end_date).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size,
				balance_sum: res.current_balance
			})
		})
	}

	//CUSTOM FUNCTIONS

	handleDateRangeChange(e, data){
		var state = {...this.state}
		state[data.name] = data.value || e.target.value
		this.setState(state)
		this.getDataRows(state);
	}

	showLedgerVouchers(ledger){
		this.setState({
			currentLedger: ledger.ledger,
			showLedgerVouchersModal: true
		})
	}

	render(){
		return(
			<div>
				<Button size={this.state.defaultSize} onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
				<div style={{float: 'right'}}>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Button size={this.state.defaultSize} style={{padding:0}}>
						<Dropdown selection
							options={this.state.tableFields}
							name="data_rows_search_field"
							value={this.state.data_rows_search_field}
							placeholder="Search Field"
							onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					</Button>
					{
						this.state.data_rows_search_type == 'select'
						?
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
						:
						<Input type={this.state.data_rows_search_type == 'field' ? 'text' : this.state.data_rows_search_type}
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					}

					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					<Dropdown
						name='data_rows_limit'
						inline
						options={this.state.options_data_rows_limit}
						value={this.state.data_rows_limit}
						onChange={(e, data) => this.changeStateVariable(e, data)}
				    />
					{
						'Showing ' + this.state.data_rows_offset + ' to ' +
						(
							(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
							(this.state.data_rows_offset + this.state.data_rows_limit) :
							this.state.data_rows_size
						) +
						' of ' + this.state.data_rows_size + ' '
					}
					<Button
						size={this.state.defaultSize}
						color='teal'
						icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
						onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<div>
					<Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
					placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
					<Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
					placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleDateRangeChange(e, data)}/>
				</div>
				<Segment.Group size='mini' floated='right'>
					<Table>
						<Table.Header>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Balance Sum</Table.Cell>
								<Table.Cell>{this.state.balance_sum}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Segment.Group>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
							<Table.HeaderCell>

							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showLedgerVouchers(data_row)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}
								{
									data_row.group == 151
									?
									<Table.Cell>
										<Button
											onClick={(e)=>{
												e.stopPropagation()
												this.setState({
													show_due_modal:true,
													due_customer:data_row.ledger
												})
											}}
											>
											Due Report
										</Button>
									</Table.Cell>
									:
									null
								}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showLedgerVouchersModal}
					onClose={() => this.setState({showLedgerVouchersModal:false, currentLedger: undefined})}>
					<Modal.Content scrolling>
						<AccountingLedgerVouchers toggle={this.toggleSidebar}  history={this.props.history} currentLedger={this.state.currentLedger}/>
					</Modal.Content>
				</Modal>

				<Modal open={this.state.showCreateDataModal}
					onClose={() => this.setState({showCreateDataModal:false, new_data_row: {date: this.state.new_data_row.date, new_data_rows:[{}]}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Input type="date" fluid required name="date" value={this.state.new_data_row.date}/>
							    <Form.Checkbox toggle fluid name="multiple" checked={this.state.multipleAddMode}
								onChange={(e, data) => this.changeStateVariableNewDataRow(e, {...data, name:'multiple'})}/>
							</Form.Group>
							{
								this.state.multipleAddMode
								?
								<Table compact className="accounting-invoice-product-table">
									<Table.Header>
										<Table.Row>
											<Table.HeaderCell>Customer</Table.HeaderCell>
											<Table.HeaderCell>Organisation</Table.HeaderCell>
											<Table.HeaderCell>Address</Table.HeaderCell>
											<Table.HeaderCell>Phone</Table.HeaderCell>
											<Table.HeaderCell>Email</Table.HeaderCell>
											<Table.HeaderCell>Service</Table.HeaderCell>
											<Table.HeaderCell>Remarks</Table.HeaderCell>
										</Table.Row>
									</Table.Header>
									<Table.Body>
									{
										this.state.new_data_row.new_data_rows.map((new_data_row, index) => (
											<Table.Row>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="customer_name" value={new_data_row.customer_name} placeholder="Customer Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="organisation_name" value={new_data_row.organisation_name} placeholder="Organisation" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="address" value={new_data_row.address} placeholder="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
													<Form.Input type="text" fluid required name="phone" value={new_data_row.phone} placeholder="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="email" value={new_data_row.email} placeholder="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="service" value={new_data_row.service} placeholder="Service Required" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<Form.Input type="text" fluid required name="remarks" value={new_data_row.remarks} placeholder="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data, index)}/>
											</Table.Cell>
											<Table.Cell className="accounting-invoice-product-tablecell">
												<img src={RemoveImage} onClick={() => this.removeProduct(index)} style={{width:'25px', height:'25px'}}/>
											</Table.Cell>
											</Table.Row>
										))
									}
									</Table.Body>
									<Table.Footer>
										<Button primary onClick={(e) => this.addProduct(e)}>MORE LEAD</Button>
									</Table.Footer>
								</Table>
								:
								<div>
								<Form.Input type="text" fluid required name="customer_name" value={this.state.new_data_row.customer_name} label="Customer Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="organisation_name" value={this.state.new_data_row.organisation_name} label="Organisation" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="address" value={this.state.new_data_row.address} label="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Group widths='equal'>
									<Form.Input type="text" fluid required name="phone" value={this.state.new_data_row.phone} label="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" fluid required name="email" value={this.state.new_data_row.email} label="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Input type="text" fluid required name="service" value={this.state.new_data_row.service} label="Service Required" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" fluid required name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</div>
							}
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
				<Modal size="large" open={this.state.show_due_modal} onClose={()=>this.setState({show_due_modal:false,due_customer:null})}>
					<Modal.Content scrolling>
						<Due customer={this.state.due_customer}/>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default AccountingTrialBalance;
