import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button,Tab ,Loader,Image,Segment,Dimmer, Dropdown, Icon, Modal, Form, Checkbox, Grid, TableHeader, TableRow} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import CreateAccountingCustomer from './create-accounting-customer';
import { equal } from 'assert';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingCustomer extends Component{

	constructor(){
		super();

		this.state = {
			data_rows: [],
			new_data_row: {branch_name:'MAIN BRANCH'},
			new_branch_row: {},
			new_user_row:{approval_required:0},
			tableFields: [
				{"value": "ledger_name", "text": "Customer Name", "type": "field"},
				{"value": "phone", "text": "Phone", "type": "field"},
				{"value": "email", "text": "Email", "type": "field"},
				{"value": "GSTIN", "text": "GSTIN", "type": "field"},
				{"value": "address", "text": "Address", "type": "field"},
				{"value": "city", "text": "City", "type": "field"},
				{"value": "pincode", "text": "Pincode", "type": "field"},
				// {"value": "pincode", "text": "Pincode", "type": "field"},
				{"value": "pos", "text": "Place of Supply", "type": "select"}
			],
			options_branch:[
				{"value": "undefined", "text": "undefined"},
			],
			tableButtons: [
				// {"value": "", "text": "PAYMENTS", "type": "button"}
			],
			user_fields:[
				{text:'id',value:'account_id'},
				{text:'Name',value:'name'},
				{text:'Email',value:'email'},
				{text:'Phone',value:'phone'},
				{text:'UserName',value:'username'},
				{text:'City',value:'city'},
				{text:'Role',value:'role'},
				{text:'Active',value:'active'},
				{text:'Branch',value:'corp_branch'},
				{text:'Approval',value:'approval_required'},
				// {text:'Approvers',value:'approvers'},
			],
			options_pos: [
				{text: "1 - JAMMU AND KASHMIR", value: 1},
				{text: "2 - HIMACHAL PRADESH", value: 2},
				{text: "3 - PUNJAB", value: 3},
				{text: "4 - CHANDIGARH", value: 4},
				{text: "5 - UTTARAKHAND", value: 5},
				{text: "6 - HARYANA", value: 6},
				{text: "7 - DELHI", value: 7},
				{text: "8 - RAJASTHAN", value: 8},
				{text: "9 - UTTAR PRADESH", value: 9},
				{text: "10 - BIHAR", value: 10},
				{text: "11 - SIKKIM", value: 11},
				{text: "12 - ARUNACHAL PRADESH", value: 12},
				{text: "13 - NAGALAND", value: 13},
				{text: "14 - MANIPUR", value: 14},
				{text: "15 - MIZORAM", value: 15},
				{text: "16 - TRIPURA", value: 16},
				{text: "17 - MEGHLAYA", value: 17},
				{text: "18 - ASSAM", value: 18},
				{text: "19 - WEST BENGAL", value: 19},
				{text: "20 - JHARKHAND", value: 20},
				{text: "21 - ODISHA", value: 21},
				{text: "22 - CHATTISGARH", value: 22},
				{text: "23 - MADHYA PRADESH", value: 23},
				{text: "24 - GUJARAT", value: 24},
				{text: "25 - DAMAN AND DIU", value: 25},
				{text: "26 - DADRA AND NAGAR HAVELI", value: 26},
				{text: "27 - MAHARASHTRA", value: 27},
				{text: "28 - ANDHRA PRADESH(BEFORE DIVISION)", value: 28},
				{text: "29 - KARNATAKA", value: 29},
				{text: "30 - GOA", value: 30},
				{text: "31 - LAKSHWADEEP", value: 31},
				{text: "32 - KERALA", value: 32},
				{text: "33 - TAMIL NADU", value: 33},
				{text: "34 - PUDUCHERRY", value: 34},
				{text: "35 - ANDAMAN AND NICOBAR ISLANDS", value: 35},
				{text: "36 - TELANGANA", value: 36},
				{text: "37 - ANDHRA PRADESH (NEW)", value: 37},
				{text: "18 / AS - ASSAM (DO NOT SELECT | VIEW ONLY)", value: 'AS'},
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'ledger_name',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'ledger_name',
			data_rows_search: '',
			data_rows_filter: {
			},
			form:false,
			branches:[],
			showBranches : true,
			users:[
				{
					account_id		:	13,
					name			:'Vaibhav Tayal',
					email			:'vaibhav@deskneed.com',
					phone			:'7002269748',
					username		:'vaibhav.test',
					city			:["GUWAHATI"],
					role			:'admin',
					active			:	1,
					corp_branch		:[340],
					approval_required:	0,
					approvers		:	null
				},
			]
		}
	}

	componentDidMount() {
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		var state = {...this.state};
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';
		state[data.name] = data.value;
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		new_data_row[data.name] = ('' + new_data_row[data.name]).trim();
		if (data.name=="GSTIN") {
			data.value = data.value.trim()
			let gst_code = data.value.slice(0,2)
			new_data_row['pos'] = parseInt(gst_code)
		}
		if(data.name == "pos"){
			new_data_row[data.name] = parseInt(data.value)
		}
		this.setState({
			new_data_row: new_data_row
		},()=>{console.log(this.state.new_data_row);
		})
	}
	changeStateVariableNewBranchRow(e, data){
		var new_branch_row = {...this.state.new_branch_row}
		new_branch_row[data.name] = data.value;
		if (data.name=="GSTIN") {
			data.value = data.value.trim()
			let gst_code = data.value.slice(0,2)
			new_branch_row['pos'] = gst_code
		}
		this.setState({
			new_branch_row: new_branch_row
		},()=>{console.log(this.state.new_branch_row);
		})
	}
	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true,
		},
			()=>{
				Client.getBranchesOfCustomer(this.state.new_data_row.id).then((res)=>{
					let options_branch = []
					let new_branch_row = this.state.new_branch_row;
					new_branch_row['ledger_name']  = this.state.new_data_row.ledger_name
					new_branch_row['customer']  = this.state.new_data_row.id
					if (res.data_rows_size > 0) {
						res.data_rows.forEach(element => {
							let temp = {
								value : element.id,
								text : element.name_suffix
							}
							options_branch.push(temp)
						})
						this.setState({
							options_branch:options_branch,
							branches:res.data_rows,
							new_branch_row:new_branch_row
						})
					}
				})
				this.getCustomerUsers()
			}
		)
	}
	getCustomerUsers(){
		this.setState({loading_users:true})
		Client.getCustomerUser('user',{'corp_ledger':[this.state.new_data_row.id]})
		.then(res=>{
			this.setState({loading_users:false,users:res.data_rows})
		})
		.catch(err=>{
			this.setState({loading_users:false})
		})
	}
	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createCustomer(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				create_new_customer:false,
				new_data_row: {branch_name: 'MAIN BRANCH'}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getCustomers(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}
	handleBranchChange(i){
		let branch = this.state.branches[i]
		let customer = this.state.new_data_row.id
		branch['customer']=customer
		// alert('test')
		this.setState({
			form:true,
			editMode:true,
			new_branch_row:branch,
			current_branch:i

		})
	}
	createNewBranchRow(){
		this.setState({
			creatingNewBranchModal: true
		})
		var state = this.state
		Client.createBranch(this.state.new_branch_row, this.state.editMode).then(res=>{
			// alert('next')
			// this.setState({

			// })
			Client.getBranchesOfCustomer(this.state.new_data_row.id).then((res)=>{
				let options_branch = []
				let new_branch_row = this.state.new_branch_row;
				new_branch_row['ledger_name']  = this.state.new_data_row.ledger_name
				new_branch_row['customer']  = this.state.new_data_row.id
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.name_suffix
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch:options_branch,
						branches:res.data_rows,
						new_branch_row:new_branch_row,
						creatingNewDataModal: false,
						creatingNewBranchModal: false,
						editMode: true,
						new_branch_row: {},
						// new_data_row: {},
						form:false
					})
				}
			})
		})
	}
	discard(event){
		event.preventDefault()
		let new_branch_row_clean = {};
		new_branch_row_clean['ledger_name']  = this.state.new_data_row.ledger_name
		new_branch_row_clean['customer']  = this.state.new_data_row.id
		this.setState({
			new_branch_row:new_branch_row_clean,
			editMode:true,
			form:false,
			current_branch:{}
		})
	}
	newUserRowChange(e,data){
		let new_user_row =this.state.new_user_row
		if (data.name == 'corp_branch' && !new_user_row.hasOwnProperty('corp_branch') && data.value.length) {
			console.log(data)
			new_user_row['city'] = data.options.find(elem=>(elem.value == data.value[0])).city
			// console.log();

		}
		new_user_row[data.name]=data.value
		this.setState({new_user_row})
	}
	editUser(userobj){
		// alert('edit called')
		let user = {...userobj}
		user.city = JSON.parse(user.city)
		user.approvers = JSON.parse(user.approvers)
		let new_user_row={...user,edit:1}
		console.log(user);

		this.setState({new_user_row})
	}
	createNewUser(){
		let new_user_row =this.state.new_user_row
		Client.createCustomerUser('create',{...new_user_row,active:1,customer:this.state.new_data_row.id}).then(res=>{
			this.getCustomerUsers()
			this.setState({new_user_row:{approval_required:0},add_user:false})
		})
	}
	render(){
		return(
			<div style={{fontSize:'10px'}}>
				<Button onClick={() => this.setState({showCreateDataModal:true,create_new_customer:true})} primary>CREATE</Button>
				<div style={{float: 'right'}}>
					<Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					<Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					<Button icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					{'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
					<Button icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Table color="blue" key="blue" collapsing>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						{
							this.state.tableButtons.map((field) => (
								<Table.HeaderCell>
									{field.text}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}
							{
								this.state.tableButtons.map((field) => (
									<Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal}
					onClose={() => this.setState({
						showCreateDataModal:false,
						new_user_row:{approval_required:0},
						new_branch_row:{},
						add_user:false,
						new_data_row: {branch_name: 'MAIN BRANCH'},
						editMode: false,
						creatingNewDataModal: false,
						form:false,
						showBranches:true,
						current_branch:undefined,
						create_new_customer:false
					})}>
					<Modal.Content scrolling>
						{
							!this.state.create_new_customer?
							<>
							<h3>{this.state.new_data_row.ledger_name}</h3>
							<Tab
								panes={[
									{
										menuItem:'Branch',
										render:()=>
											<Tab.Pane >
												{ this.state.editMode ?
													<div style={{maxHeight:'12rem',overflowY:"scroll",marginBottom:'25px'}}>
														<Table compact >
															<Table.Header>
																<Table.HeaderCell>Suffix</Table.HeaderCell>
																<Table.HeaderCell>Branch Name</Table.HeaderCell>
																<Table.HeaderCell>Address</Table.HeaderCell>
																<Table.HeaderCell>City</Table.HeaderCell>
																<Table.HeaderCell>GSTIN</Table.HeaderCell>
																<Table.HeaderCell>Contact Person</Table.HeaderCell>
																<Table.HeaderCell>Phone</Table.HeaderCell>
															</Table.Header>
															<Table.Body >
																{this.state.branches.map((branch,i)=>(
																	<Table.Row className="table-row" style={{background:this.state.current_branch == i?"red":'white',cursor:'pointer'}} onClick={()=>this.handleBranchChange(i)}>
																		<Table.Cell>{branch.name_suffix}</Table.Cell>
																		<Table.Cell>{branch.branch_name}</Table.Cell>
																		<Table.Cell>{branch.address}</Table.Cell>
																		<Table.Cell>{branch.city}</Table.Cell>
																		<Table.Cell>{branch.GSTIN}</Table.Cell>
																		<Table.Cell>{branch.contact_person}</Table.Cell>
																		<Table.Cell>{branch.phone}</Table.Cell>
																	</Table.Row>
																))}
															</Table.Body>
														</Table>
													</div>
												:null}
												<div style={{display:'flex',justifyContent:'space-between'}}>
													{
														!this.state.form  ?
														<>
															{/* <Checkbox toggle checked={this.state.showBranches}
															onClick={()=>this.setState({showBranches:!this.state.showBranches})}/> */}
															<Form.Button
																primary
																onClick={()=>{this.setState({form:'add',editMode:false})}}
																style={{marginBottom:'8px'}}
																>
																	New Branch
															</Form.Button>
														</>
														:null
													}
												</div>

												{/* Add New Branch Form */}
												<Form size="mini" style={{display:(this.state.form?'block':'none')}} onSubmit={() => this.createNewBranchRow()}>
													<h3>{this.state.editMode?'Edit Branch :':'ADD BRANCH TO'} {this.state.new_data_row.ledger_name}</h3>
													<Form.Group widths="equal">
														<Form.Input type="text"
															required fluid name="name_suffix"
															value={this.state.new_branch_row.name_suffix || ''}
															label="suffix_name"
															onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
														<Form.Input type="text" required fluid name="branch_name" value={this.state.new_branch_row.branch_name || ''} label="Branch Name" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
													</Form.Group>
													<Form.Group widths="equal">
														<Form.Input type="text" required fluid name="phone" value={this.state.new_branch_row.phone || ''} label="Phone" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
														<Form.Input type="text" required fluid name="email" value={this.state.new_branch_row.email || ''} label="Email" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
													</Form.Group>
													<Form.Group widths="equal">
														<Form.Input type="text" required fluid name="GSTIN" value={this.state.new_branch_row.GSTIN || ''} label="GSTIN" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
														<Form.Select
															label="Place of Supply"
															name="pos"
															fluid required
															search
															selection
															placeholder="Select POS"
															options={this.state.options_pos}
															value={parseInt(this.state.new_branch_row.pos) || ''}
															onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}
														/>
														</Form.Group>
														<Form.Group widths="equal">
																<Form.Input type="text" required fluid name="address" value={this.state.new_branch_row.address || ''} label="Address" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
																<Form.Input type="text"
																	required fluid name="contact_person"
																	value={this.state.new_branch_row.contact_person || ''}
																	label="Contact Person"
																	onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
															</Form.Group>
															<Form.Group widths='equal'>
																<Form.Select required fluid name="city" options={this.props.cities.map(elem=>{return{text:elem.text.toUpperCase(),value:elem.value.toUpperCase()}})} value={this.state.new_branch_row.city || ''} label="City" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
																<Form.Input type="text" required fluid name="pincode" value={this.state.new_branch_row.pincode || ''} label="Pincode" onChange={(e, data) => this.changeStateVariableNewBranchRow(e, data)}/>
															</Form.Group>
															<div style={{display:'flex',justifyContent:'space-between'}}>
																<Form.Button type="submit" primary loading={this.state.creatingNewBranchModal}>{this.state.editMode?'Edit':'CREATE'}</Form.Button>
																<Form.Button type="buttom" color="black" onClick={(event)=>{this.discard(event)}}>
																	Discard
																</Form.Button>
															</div>
														</Form>
											</Tab.Pane>
									},
									{
										menuItem:'User',
										render:()=>
											<Tab.Pane>
												<Button primary={!this.state.add_user}
													onClick={()=>{this.setState({add_user:!this.state.add_user,new_user_row:{approval_required:0}})}}
												>
														{!this.state.add_user?'Add User':'Back'}
												</Button>
												{
													!this.state.add_user
													?
														this.state.loading_users
														?
															<Segment>
																<Dimmer active>
																	<Loader size='medium'>Loading</Loader>
																</Dimmer>
																<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
															</Segment>
														:
														<Table compact>
															<TableHeader>
															{
																this.state.user_fields.map(field=>(
																	<Table.HeaderCell>{field.text}</Table.HeaderCell>
																	)
																)
															}
															</TableHeader>

															{
																this.state.users.map(user=>(
																	<Table.Row
																	style={{
																		backgroundColor:this.state.new_user_row.account_id == user.account_id?'red':'white'
																		}}
																	onClick={()=>this.editUser(user)}>
																		{
																			this.state.user_fields.map(field=>(
																				<Table.Cell>
																				{
																					field.value == "corp_branch"
																					?
																					user[field.value].map(elem=>{
																						let branch = this.state.branches.find(elem1=>(elem1.id == elem))
																						console.log(branch);

																						return branch.branch_name+" "
																					})
																					:
																					field.value == 'city'
																					?
																					JSON.parse(user[field.value])
																					:
																					user[field.value]
																				}
																				</Table.Cell>
																			))
																		}
																	</Table.Row>
																	)
																)
															}
														</Table>
													:null
												}
												{
													this.state.add_user || this.state.new_user_row.edit ==1
													?
													<Form>
														<Form.Group widths='equal'>
															<Form.Field>
																<label>Name</label>
																<Input value={this.state.new_user_row.name || ''} fluid placeholder='Name' name="name" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
															<Form.Field>
																<label>Phone</label>
																<Input value={this.state.new_user_row.phone || ''} fluid placeholder='Phone' name="phone" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
														</Form.Group>
														<Form.Group widths='equal'>
															<Form.Field>
																<label>Email</label>
																<Input value={this.state.new_user_row.email || ''} fluid placeholder='Email' name="email" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
															<Form.Field>
																<label>Branch</label>
																<Dropdown
																	selection
																	multiple
																	name="corp_branch"
																	options={this.state.branches.map(elem=>(
																		{value:elem.id,text:elem.branch_name,city:elem.city}
																	))}
																	onChange={(e,data)=>{this.newUserRowChange(e,data)}}
																	value={this.state.new_user_row.corp_branch?this.state.new_user_row.corp_branch:[]
																		}
																	placeholder='Choose Branch'
																	/>
															</Form.Field>
														</Form.Group>
														<Form.Group widths='equal'>
															<Form.Field>
																<label>Role</label>
																<Dropdown
																	selection
																	options={[
																		{text:"Admin",value:'admin'},
																		{text:"User",value:'user'}
																	]}
																	name="role"
																	onChange={(e,data)=>{this.newUserRowChange(e,data)}}
																	value={this.state.new_user_row.role || ''}
																	placeholder='Choose Role'
																	/>
															</Form.Field>
															<Form.Field>
																<label>Username</label>
																<Input value={this.state.new_user_row.username || ''} fluid placeholder='Username' name="username" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
														</Form.Group>
														<Form.Group widths='equal'>
															<Form.Field>
																<label>Admin Password</label>
																<Input type={'password'} value={this.state.new_user_row.admin_key || ''} fluid placeholder='Admin Password' name="admin_key" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
															<Form.Field
																// style={{display:this.state.new_data_row.edit?'none':'inline-block'}}
																>
																<label>Password</label>
																<Input type={'password'} value={this.state.new_user_row.key || ''} fluid placeholder='Password' name="key" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
															<Form.Field
																// style={{display:this.state.new_data_row.edit?'none':'inline-block'}}
																>
																<label>Confirm Password</label>
																<Input type={'password'} value={this.state.new_user_row.confirm_key || ''} fluid placeholder='Confirm Password' name="confirm_key" onChange={(e,data)=>{this.newUserRowChange(e,data)}}/>
															</Form.Field>
														</Form.Group>
														<span style={{display:"flex",justifyContent:"space-between"}}>
															<Button
																color={this.state.new_data_row.edit?'black':'green'}
																loading={this.state.createing_user}
																onClick={()=>{this.createNewUser()}}>
																	{this.state.new_user_row.edit?'Edit':'Create'}
															</Button>
															<span className="approve">
																<span className="check-box-approve">
																	<span>
																		Needs Approval?
																	</span>
																	<Checkbox checked={this.state.new_user_row.approval_required==1} onChange={(e)=>this.newUserRowChange(e,{name:"approval_required",value:this.state.new_user_row.approval_required==1?0:1})}/>
																</span>
																<Dropdown
																	multiple
																	// options={this.state.users.map(user=>{return{text:user.username,value:user.account_id}})}
																	options={this.state.users.map(user=>{
																		return {text:user.name.toUpperCase(),value:user.account_id}
																	})}
																	onChange={(e,data)=>{this.newUserRowChange(e,data)}}
																	name="approvers"
																	search
																	selection
																	placeholder="Choose Authorities"
																	value={this.state.new_user_row.approvers || ''}
																/>
															</span>
														</span>
													</Form>

													:
													null
												}
											</Tab.Pane>
									}
								]}
								/>
							</>
							:
							<Form size="mini" style={{marginBottom:'8px'}} onSubmit={() => this.createNewDataRow()}>
								<Form.Group  widths='equal'>
									<Form.Input type="text"
										required fluid name={"ledger_name"}
										value={this.state.new_data_row.ledger_name}
										label="Name"
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input type="text" required fluid name="branch_name" value={this.state.new_data_row.branch_name} label="Branch Name" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" fluid name="name_suffix" value={this.state.new_data_row.name_suffix} label="Name Suffix" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input type="text" required fluid name="phone" value={this.state.new_data_row.phone} label="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" required fluid name="email" value={this.state.new_data_row.email} label="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Group widths="equal">
									<Form.Input type="text" required fluid name="GSTIN" value={this.state.new_data_row.GSTIN} label="GSTIN" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Select
										label="Place of Supply"
										name="pos"
										fluid required
										search
										selection
										placeholder="Select POS"
										options={this.state.options_pos}
										value={parseInt(this.state.new_data_row.pos)}
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									/>
								</Form.Group>
								<Form.Group widths='equal'>
									<Form.Input type="text" required fluid name="address" value={this.state.new_data_row.address} label="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" required fluid name="contact_person" value={this.state.new_data_row.contact_person} label="Contact Person" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Group widths='equal'>
									<Form.Select required fluid name="city" options={this.props.cities.map(elem=>{return{text:elem.text.toUpperCase(),value:elem.value.toUpperCase()}})} value={this.state.new_data_row.city} label="City" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
									<Form.Input type="text" required fluid name="pincode" value={this.state.new_data_row.pincode} label="Pincode" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								</Form.Group>
								<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'EDIT' : 'CREATE'}</Form.Button>
							</Form>
						}
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default AccountingCustomer;
