import React,{Component} from 'react';
import AddBusinessForm from "../components/add-business-form";

class AddBusiness extends Component{
    render(){
        return(
            <AddBusinessForm/>
        )
    }
}
export default AddBusiness;
