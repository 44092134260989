import React, {Component} from 'react';
import Client from '../services/Client';

class Staff extends Component{

	constructor(props){
		super(props);

		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		var date = yyyy + "-" + mm + "-" + dd;

		this.state = {
			open: false,
			staff: this.props.staff,
			showSalary: 0,
			hubs: ["CENTRAL", "SILCHAR", "GUWAHATI", "TEZPUR"],
			paymentModes: ["CASH", "PAYTM", "RAZORPAY", "BANK SBI", "BANK SYND"],
			entry: {
				id: '',
				mode: 'BANK SBI',
				type: 'EXPENSES',
				partner: '',
				partnerName: '',
				partnerId: '',
				amount: '',
				date: date,
				hub: 'CENTRAL',
				comments: '',
				dr: '',
				cr: ''
			}
		}
	}

    componentWillReceiveProps = (nextProps) => {
		this.setState({
			staff: nextProps.staff
		})
    }

	handleCollapse = () => {
		this.setState({
			open: !this.state.open
		})
	}

    handleInputChange = (e) => {
        let v = this.state.staff;
        v[e.target.name] = e.target.value;
        this.setState({staff: v});
    };

    handleShowSalary = (e) => {
        this.setState({showSalary: 1});
    };

    toggleActive = (e) => {
        let v = this.state.staff;
        v['active'] = this.state.staff.active?0:1;
        this.setState({staff: v});
        this.handleUpdateStaff();
    };

    handleUpdateStaff = () => {
        Client.updateStaff(this.state.staff).then(
            (res) => {
                if(res.responseResult === "success"){
			        let v = this.state.staff;
			        v['active'] = res.active;
                    this.setState({
                    	open: false,
                    	staff: v
                    })
                }
            }
        );
    };

    handleCreditSalary = () => {
        Client.creditStaffSalary(this.state.staff.id, this.state.entry).then(
            (res) => {
                if(res.responseResult === "success"){
			        alert("credited");
                }
            }
        );
    };

    handleInputChange = (e) => {
        var v = this.state.entry;
        v[e.target.name] = e.target.value;
        this.setState({
            entry: v
        })
    };

	render(){
		const hubs = this.state.hubs.map((type)=>{
			return(
				<option className="item"  key={type} value={type}>{type}</option>
			)
		});
		const paymentModes = this.state.paymentModes.map((type)=>{
			return(
				<option className="item"  key={type} value={type}>{type}</option>
			)
		});

		if(this.state.open){
			return(
			    <div className="ui fluid card" style={{padding: "15px", cursor: "pointer"}}>
			    	<div className="section">
			    		<div className="ui three column grid form">
			    			<div className="two wide field">
                                <input type="text" placeholder="Name" name="name" onChange={this.handleInputChange.bind(this)} value={this.state.staff.name}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Phone" name="phone" onChange={this.handleInputChange.bind(this)} value={this.state.staff.phone}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Email" name="email" onChange={this.handleInputChange.bind(this)} value={this.state.staff.email}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="City" name="city" onChange={this.handleInputChange.bind(this)} value={this.state.staff.city}/>
                            </div>
                            <div className="two wide field">
                                <input type="text" placeholder="Role" name="role" onChange={this.handleInputChange.bind(this)} value={this.state.staff.role}/>
                            </div>
                            <div className="two wide field">
                                <input type="Password" placeholder="Password" name="pass" onChange={this.handleInputChange.bind(this)} value={this.state.staff.pass}/>
                            </div>
			    		</div>
	                    <div className="ui button two wide right floated" onClick={this.handleUpdateStaff}>Update</div>
			    	</div>
			    </div>
		    )
		}else{
			return(
				<div className="ui fluid card" style={{padding: "15px", cursor: "pointer"}}>
			    	<div className="section">
			    		<div className="ui twelve column grid">
			    			<div className="column three wide"><strong>Name.</strong>:&nbsp;&nbsp; {this.state.staff.name}</div>
		    				<div className="column three wide"><strong>Phone.</strong>:&nbsp;&nbsp; {this.state.staff.phone}</div>
		    				<div className="column four wide"><strong>Email.</strong>:&nbsp;&nbsp; {this.state.staff.email}</div>
		    				<div className="column two wide"><strong>City.</strong>:&nbsp;&nbsp; {this.state.staff.city}</div>
		    				<div className="column two wide"><strong>Role.</strong>:&nbsp;&nbsp; {this.state.staff.role}</div>
			    		</div>

	                    <div className="ui button two wide right floated" onClick={this.handleCollapse}>Change</div>
						<div className="ui button two wide right floated" onClick={this.toggleActive}>
							{this.state.staff.active == 1? "Deactivate" : "Activate"}
						</div>
						<div className="ui button two wide right floated" onClick={this.handleShowSalary}>Salary/Benefit/Awards</div>
			    	</div>
					{
						this.state.showSalary == 1
						?
						<div className="section" style={{padding:'15px'}}>
							<div className="ui grid">
								<select className="two wide computer four wide mobile column" value={this.state.entry.hub} name="hub" onChange={this.handleInputChange.bind(this)}>
									{hubs}
								</select>
								<select className="two wide computer four wide mobile column" value={this.state.entry.mode} name="mode" onChange={this.handleInputChange.bind(this)}>
									{paymentModes}
								</select>
								<input className="three wide computer four wide mobile column" value={this.state.entry.date} key="date" name="date" type="date" onChange={this.handleInputChange.bind(this)}></input>
								<input className="two wide computer four wide mobile column" placeholder="Amount" value={this.state.entry.amount} key="amount" name="amount" type="number" onChange={this.handleInputChange.bind(this)}></input>
								<input className="two wide computer four wide mobile column" placeholder="Comments" value={this.state.entry.comments} key="comments" name="comments" type="text" onChange={this.handleInputChange.bind(this)}></input>
								<div className="ui button" onClick={this.handleCreditSalary}>Add</div>
							</div>
						</div>
						:
						null
					}
			    </div>
        	)
		}
	}
}

export default Staff;
