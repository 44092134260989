import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {Table, Button} from 'semantic-ui-react';

import '../style/print.css';

class PrintOrders extends Component{

	render(){

		const orders = JSON.parse(localStorage.getItem('ordersForPrint')||'[]');

		return(
			<div>
				<Link to='/orders'><Button className="noprint" style={{marginLeft: '1em', marginTop: '1em'}}>Back</Button></Link>
				<Button className="noprint" color="green" style={{marginLeft: '0.5em', marginTop: '1em'}} onClick={function(){window.print();}}>Print</Button>
				<Table>
					<Table.Header>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>Date</Table.HeaderCell>
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>Phone</Table.HeaderCell>
						<Table.HeaderCell>Address</Table.HeaderCell>
						<Table.HeaderCell>Amount</Table.HeaderCell>
						<Table.HeaderCell>Status</Table.HeaderCell>
						<Table.HeaderCell>Pay Mode</Table.HeaderCell>
						<Table.HeaderCell>Pay Status</Table.HeaderCell>
						<Table.HeaderCell>Products</Table.HeaderCell>
					</Table.Header>
					<Table.Body>
						{
							orders.map((order)=>(
								<Table.Row>
									<Table.Cell>{order.orderId}</Table.Cell>
									<Table.Cell>{order.orderDateAndTime ? order.orderDateAndTime.substr(6,10) + ', ' + order.orderDateAndTime.substr(23,5) : null}</Table.Cell>
									<Table.Cell>{order.customerName.substr(0,15)}</Table.Cell>
									<Table.Cell>{order.customerPhone.substr(0,15)}</Table.Cell>
									<Table.Cell>{order.deliveryAddress.toUpperCase()[0]+order.deliveryAddress.substring(1,30)}</Table.Cell>
									<Table.Cell>Rs. {order.amount}</Table.Cell>
									<Table.Cell>{order.orderStatus.substr(0,10)}</Table.Cell>
									<Table.Cell>{order.paymentMode.substr(0,20)}</Table.Cell>
									<Table.Cell>{order.paymentStatus.substr(0,15)}</Table.Cell>
									<Table.Cell>{order.products.substr(0,30)}</Table.Cell>
								</Table.Row>
							))
						}
					</Table.Body>
				</Table>
			</div>
		)
	}

}

export default PrintOrders;
