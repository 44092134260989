import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import SaleView from './sale-voucher-view';
import {Input, Table, Image, Button, Dropdown, Icon, Modal, Form, Segment,Label, Loader, Dimmer, Header, Grid} from 'semantic-ui-react';
import { ComposedChart,BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

import RemoveImage from '../assets/blue/ic_minus.png';
import CreditNoteView from './credit-note-view';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingCreditNote extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			data_rows: [],
			new_data_row: {records: [{}], date: dateVaue},
			tableFields: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "customer", "text": "Customer", "type": "select"},
				{"value": "voucher_id", "text": "Invoice Id", "type": "field"},
				{"value": "order_id", "text": "Order Id", "type": "field"},
				{"value": "payable_amount", "text": "Amount", "type": "field"},
				{"value": "status", "text": "Status", "type": "field"},
			],
			defaultSize: 'small',
			tableButtons: [
			],
			paymentReceipt: {paymentInvoices:[{}]},
			currentDataRow: {},
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'date',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'customer',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50},
				{text: 100, value: 100},
				{text: 150, value: 150},
				{text:200, value: 200},
				{text: 250, value: 250},
				{text: 500, value: 500},
			],
			options_customer: [],
			options_voucher_id: [],
			options_to_ledger: [],
			graph_customer:false,
			data_rows_branch:'',
			options_branch:[
			],
			options_branch_sort:[
			],
		}
	}

	componentDidMount() {
		var banks = [7,10];
		var options_voucher_id = [];
		var options_record_ledgers = []
		var options_customer = []
        this.setState({start_date:this.range(false),end_date:this.range(true),})
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_customer.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_customer: options_customer
			})
		})
		this.setMonthSelections();
		this.getDataRows(this.state);
		setTimeout(()=>{
			var self = "sale"
			if (localStorage.getItem('voucher-redirect-month-'+self)) {
				this.setFilter({}, {name:"date",value:[localStorage.getItem('voucher-redirect-month-'+self)]})
				localStorage.removeItem('voucher-redirect-month-'+self)
			}
			if (localStorage.getItem('voucher-redirect-date-'+self)) {
				this.changeStateVariable({},{
					name:'data_rows_search_field',
					value:'date'
				})
				setTimeout(()=>{
					this.changeStateVariable({},{
						name:'data_rows_search',
						value:localStorage.getItem('voucher-redirect-date-'+self)
					})
					localStorage.removeItem('voucher-redirect-date-'+self)
				},300)
			}
		},500)
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data, stopLoad){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name || e.target.name] = data.value || e.target.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field" || data.name == "data_rows_search"){
			var filter = this.state.data_rows_filter
			delete filter.cust_branch
			state.data_rows_filter = filter
			state.showBranch = 0
		}
		// if (data.name == "data_rows_search_field" && data.value == "amount") {
		// 	state[data.name] = "payable_amount"
		// }
		//SELECTIVE OPERATIONS
		if(this.state.data_rows_search_field=="customer" && data.name == "data_rows_search"){
			state.showBranch = 1
			state.loading_branch = 1
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch_sort:options_branch,
						loading_branch:0
					})
				} else {
					this.setState({
						showBranch:0,
						options_branch_sort:''
					})
				}
			})
		}
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
			state['data_rows_search']=''
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name == "cust_branch"){
			if(data.value != "" && data.value != undefined){
				var filter = {...this.state.data_rows_filter, cust_branch:[data.value]}
			}
			else
				var filter = {...this.state.data_rows_filter, cust_branch:[]}
			state.data_rows_filter =filter
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state);
		if(!stopLoad){
			this.getDataRows({
				...state,
				data_rows_search_field:state.data_rows_search_field == "amount"
					?
					"payable_amount"
					:
					state.data_rows_search_field
			});
		}
	}

	changeStateVariableNewDataRow(e, data){
		if(data.name == 'customer'){
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch:options_branch,
					})
				}
			})
		}
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createAccountingSale(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {records: [{}]}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getAccountingCreditNote(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort,
			state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter).then(res=>{
			var totalSale = 0;
			var totalTax = 0;
			res.data_rows.forEach(function(sale){
				totalSale = totalSale + parseFloat(sale.payable_amount);
				totalTax = totalTax + parseFloat(sale.tax_amount);
			})
            this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size,
				totalSale: totalSale.toFixed(2),
				totalTax: totalTax.toFixed(2)
			})
		})
	}

	//CUSTOM FUNCTIONS
	changeStateVariableRecord(e, data, index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = this.state.new_data_row;
		records[index][data.name] = data.value || e.target.value
		new_data_row.records = [...records]
		if(data.name == "ledger"){
			this.getRecordReferences(new_data_row, records, index);
		}
		if(data.name == "against_reference"){
			records[index]['options_references'].forEach(function(reference){
				if(reference.value == data.value){
					records[index]['amount'] = reference.payable_amount
				}
			})
		}
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}

	getRecordReferences(new_data_row, records, index){
		records[index]['loadingReferences'] = true
		this.setState({
			new_data_row: new_data_row
		})
		Client.getAccountingSales(null, null, 'date', 'desc', null, null, null, {'customer': records[index]['ledger'], 'type': 'sale'}).then((res) => {
			records[index]['loadingReferences'] = false
			var options_references = [{text: 'ON ACCOUNT', value: null}]
			res.data_rows.forEach(function(data_row) {
				options_references.push({...data_row, text: data_row.voucher_id + ' - ' + data_row.date + ' - ' + data_row.payable_amount, value: data_row.voucher_id})
			})
			records[index]['options_references'] = options_references
			new_data_row.records = [...records]
			this.setState({
				new_data_row: new_data_row
			})
		})
	}

	addRecord(e){
		e.preventDefault();
		var records = this.state.new_data_row.records;
		var new_data_row = this.state.new_data_row;
		new_data_row.records = [...records, {}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-receipt-record-ledger' + (new_data_row.records.length - 1)).getElementsByTagName('input')[0].focus()
		},300)
		this.makeCalculations();
	}

	removeRecord(index){
		var records = [...this.state.new_data_row.records];
		var new_data_row = {...this.state.new_data_row};
		var records1 = []
		records.forEach(function(element, index1) {
			if(index1 != index){
				records1.push(element)
			}
		});
		new_data_row.records = [...records1]
		this.setState({
			new_data_row: new_data_row
		})
		this.makeCalculations();
	}

	makeCalculations(){
		var new_data_row = {...this.state.new_data_row}
		var records_total = 0;
		new_data_row.records.map(function(record){
			records_total = records_total + parseFloat(record.amount)
		})
		new_data_row.records_total = records_total
		this.setState({
			new_data_row: new_data_row
		})
	}

	handleVoucherIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
	}

	handleInputChangePayment(e, data){
		var paymentReceipt = {...this.state.paymentReceipt};
		paymentReceipt[data.name] = e.target.value
		this.setState({
			paymentReceipt: paymentReceipt
		})
	}

	setFilter(e, data){
		var filter = {...this.state.data_rows_filter}
		var array = data.value;
		array.forEach(function(element, index){
			if(element == null){
				array.splice(index, 1)
			}
		})
		filter[data.name] = data.value
		var state = {...this.state}
		state.data_rows_filter = filter
		this.setState(state)
		this.getDataRows(state)
	}

	setMonthSelections(){
		var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
		})
	}

	showDataRowDetails(index, print, heading){
		if(index == undefined || index == null)
			index = this.state.currentDataRowIndex || 0

		var sale = {...this.state.data_rows[index]};
		var taxDetails = this.calculateTax(sale)

		this.setState({
			currentDataRowIndex: index,
			currentDataRow: sale
		})
		let v_id = sale.voucher_id.split('')

		let v_id_start = v_id.splice(0,3);
		v_id_start = v_id_start.join('')
		if(heading == null || heading == undefined || heading == ''){
			if( v_id_start== 'C-C'){
				heading = 'ESTIMATE';
			}
		}
		var taxDetailsView = '';
		taxDetails.forEach(function(taxDetail, index) {
			if(taxDetail.taxStateType == 0){
				taxDetailsView = taxDetailsView +
				'<tr><td>CGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>' +
												'<tr><td>SGST (' + taxDetail.taxPc / 2 + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount/2) + '</td></tr>'
			}
			else{
				taxDetailsView = taxDetailsView +
				'<tr><td>IGST (' + taxDetail.taxPc + '%)</td><td style="text-align:right">' + this.perfectDecimal(taxDetail.tax_amount) + '</td></tr>'
			}
		}.bind(this));


		if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">CGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">SGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th><th>Amount</th></tr>'
		}
		else if(taxDetails.length > 0){
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>HSN/SAC</th><th>Qty</th><th>Rate</th>' +
						'<th style="position:relative">'+
						'<div style="position:absolute; height:1.5em; background:#999; width:1px; bottom:0; left:51px"></div>' +
						'<div style="border-bottom:solid 1px #999">IGST</div><table class="productsTableInner">' +
						'<tr><th style="width:50px">%</th><th style="min-width:50px; max-width:100px">Amt.</th></tr></table></th>'+
						'<th>Amount</th></tr>'
		}
		else{
			var productsTable =
			'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th><th>Rate</th>' +
						'<th>Amount</th></tr>'
		}

		sale.products.forEach(function(product, index) {
			if(taxDetails.length > 0){
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:left">' + product.HSN +
								'</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}
			else{
				productsTable = productsTable +
				'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
								(product.description != null ? product.description : '') + '</td><td style="text-align:right">' + product.qty +
								'</td><td style="text-align:right">' + product.rate + '</td>';
			}

			if(taxDetails.length > 0 && taxDetails[0].taxStateType == 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td><td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax/2 + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/200) + '</td></tr></table>' +
							'</td>'
			}
			else if(taxDetails.length > 0){
				productsTable = productsTable + '<td style="position:relative">'+
							'<div style="position:absolute; height:100%; background:#999; width:1px; top:0; left:51px"></div>' +
							'<table class="productsTableInner">' +
							'<tr><td style="width:50px; text-align:right">' + product.tax + '%</td>'+
							'<td style="min-width:50px; max-width:100px; text-align:right">' +
							this.perfectDecimal(product.rate * product.qty * product.tax/100) + '</td></tr></table>' +
							'</td>'
			}
			productsTable = productsTable + '<td style="text-align:right">' + this.perfectDecimal(product.rate * product.qty) + '</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						${heading || "TAX INVOICE"}
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Invoice Number</td>
						<td><b>: ${sale.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Invoice Date</td>
						<td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Bill To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
				<p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
					${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px">
						<div style="float:right; padding:2px 5px 0 0">E. & O.E.</div>
						Total in Words:<br/>
						<b>Rupees ${converter.toWords(sale.payable_amount)}</b>
						<br/>
						<br/>
						${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}
						<br/>
						<br/>
						<b>CHEQUE to be made in favour of SCIENCE2HOME PVT. LTD.</b><br/>
						Company's Bank Details:<br/>
						<b>Acc No: 39010200000238</b><br/>
						<b>IFSC Code: BARB0AMBARI‎</b><br/>
						<b>Bank Of Baroda</b><br/>
						Ambari, Guwahati

						<div style="text-align:center; padding:2px 0px 5px 0">SUBJECT TO GUWAHATI JURISDICTION</div>
					</td>
					<td style="border-left:solid 1px #999; padding:0">
						<div style="width:100%; margin:0; padding:5px 0 5px 0px">
							<table style="width:100%">
								<tr><td>Sub Total</td><td style="text-align:right">${parseFloat(Math.round(sale.taxable_amount * 100) / 100).toFixed(2)}</td></tr>
								${taxDetailsView}`
								if(sale.adjustment_name != null && sale.adjustment_name != '')
									invoiceHtml = invoiceHtml +
									`<tr><td>${sale.adjustment_name}</td><td style="text-align:right">${sale.adjustment_amount}</td></tr>`
								invoiceHtml = invoiceHtml +
								`<tr><td>Rounding</td><td style="text-align:right">${parseFloat(Math.round(sale.round_off * 100) / 100).toFixed(2)}</td></tr>
								<tr style="font-weight:bold"><td>Total</td><td style="text-align:right">${parseFloat(Math.round(sale.payable_amount * 100) / 100).toFixed(2)}</td></tr>
							</table>
						</div>
						<div style="text-align:center; border-top:solid 1px #999; width:100%">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		this.setState({
			showSaleDetailsModal: true
		})

		setTimeout(function(){
			document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		}, 500);

		if(print){
			var printwindow = window.open('','dhp_inv_sale', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id;
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		}
	}

	printDeliveryNote(print){

		var index = this.state.currentDataRowIndex
		var sale = {...this.state.data_rows[index]};
		var taxDetails = this.calculateTax(sale)

		var taxDetailsView = '';

		var productsTable =
		'<tr style="background:#CCC"><th style="min-width:10px">#</th><th>Item & Description</th><th>Qty</th></tr>'
		sale.products.forEach(function(product, index) {
			productsTable = productsTable +
			'<tr><td style="text-align:center">' + (index + 1) + '</td><td>' + product.name + '<br/>' +
							(product.description != null ? product.description : '') + '</td><td style="text-align:center">' + product.qty +
							'</td></tr>'
		}.bind(this));

		var invoiceHtml = `
		<!doctype html>
		<html>
		<body style="font-size:10px; margin:10px; border: solid #999 1px;">
			<table>
				<tr>
					<td style="width:10%">
						<img style="width:100%" src=${logo}><img/>
					</td>
					<td>
						<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
						<p style="padding:0; margin:0 0 0 5px">
						R G B Road, Ganeshguri<br/>
						Guwahati, Assam - 781006<br/>
						GSTIN: 18AAXCS9739A1ZO<br/>
						PAN: AAXCS9739A<br/>
						MSME UAM No.: AS03D0001520<br/>
						Contact: +91-9954683549<br/>
						E-mail: contact@deskneed.com
						</p>
					</td>
					<td style="font-size:300%; text-align:right; padding-right:40px">
						DELIVERY NOTE
					</td>
				</tr>
			</table>

			<div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
				<table style="margin:0 0 0 5px">
					<tr>
						<td>Reference Number</td>
						<td><b>: ${sale.voucher_id}</b></td>
					<tr>
					<tr>
						<td>Date</td>
						<td><b>: ${sale.date.substring(8, 10) + '/' + sale.date.substring(5, 7) + '/' + sale.date.substring(0, 4)}</b></td>
					<tr>
				</table>
			</div>

			<div style="font-weight:bold; padding:3px 0px 2px 5px; background:#CCC; border-top:solid 1px #999; border-bottom:solid 1px #999">
				Delivery To
			</div>

			<div style="padding:5px 5px 5px 5px;">
				<p style="font-weight:bold; font-size:14px; padding:0; margin:0">${sale.customer_details.name}</p>
				<p style="padding:0; margin:0">
					${(sale.customer_details.address && sale.customer_details.address.trim() != "") ? sale.customer_details.address + '<br/>' : ''}
					${(sale.customer_details.GSTIN && sale.customer_details.GSTIN.trim() != "") ? "GSTIN: " + sale.customer_details.GSTIN : ''}
				</p>
			</div>

			<table class="productsTable">
				${productsTable}
			</table>

			<table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
				<tr>
					<td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
						<br/>
						${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}
						<br/>
					</td>
					<td style="border-left:solid 1px #999; padding:0; text-align:center">
						<br/>
						<br/>
						<br/>
						<br/>
						<br/>
						Authorised Signatory
						</div>
					</td>
				</tr>
			</table>

		</body>
		<style>
			body{
				margin: 0em;
			}
			*{
				font-family: sans-serif
			}
			table{
				border-collapse: collapse;
			}
			.productsTable, .productsTableInner{
				width:100%;
			}
			.productsTable td{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTable th{
				border-top:solid 1px #999;
				border-left:solid 1px #999;
			}
			.productsTableInner td{
				border:none;
			}
			.productsTableInner th{
				border:none;
			}
		</style>
		</html>
		`

		// document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
		// document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

		// if(print){
			var printwindow = window.open('','dhp_inv_delivery_note', 'height=1000, width=1000');
			printwindow.document.write(
				invoiceHtml
			);
			printwindow.focus();
			setTimeout(function() {
				printwindow.document.title = sale.voucher_id + "_DELIVERY_NOTE";
				printwindow.print();
				printwindow.close();
				printwindow.document.close();
			}, 500);
		// }
	}

	calculateTax(sale){
		var sale = sale;
		var taxDetails = [];
		var subTotal = 0;
		var total = 0;
		var taxStateType = sale.customer_details.pos == "AS" ? 0 : 1;
		sale.products.forEach(function(element) {
			var taxDetail = {};
			var found = 0
			if(element.tax != 'N/A'){
				taxDetails.forEach(function(elem) {
					if(elem.taxPc == element.tax){
						found = 1;
						elem.taxStateType = taxStateType;
						elem.taxable_amount = Number(elem.taxable_amount) + (element.qty * element.rate)
						elem.tax_amount = elem.taxable_amount *  elem.taxPc / 100;
						elem.taxable_amount = parseFloat(Math.round(elem.taxable_amount * 100) / 100).toFixed(2);
						elem.tax_amount = parseFloat(Math.round(elem.tax_amount * 100) / 100).toFixed(2);
					}
				})
				if(found == 0){
					taxDetail['taxStateType'] = taxStateType;
					taxDetail['taxPc'] = element.tax;
					taxDetail['taxable_amount'] = element.qty * element.rate;
					taxDetail['taxable_amount'] = parseFloat(Math.round(taxDetail['taxable_amount'] * 100) / 100).toFixed(2);
					taxDetail['tax_amount'] = (element.qty * element.rate) * element.tax / 100;
					taxDetail['tax_amount'] = parseFloat(Math.round(taxDetail['tax_amount'] * 100) / 100).toFixed(2);
					taxDetails.push(taxDetail)
				}
			}
			subTotal = subTotal + (element.qty * element.rate);
		});

		taxDetails.forEach(function(elem) {
			total = total + Number(elem.tax_amount);
		});

		// sale.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
		// sale.total = Number(total) + Number(subTotal);
		// sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
		// sale.roundOff = Math.round(sale.total) - Number(sale.total);
		// sale.roundOff = parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2);
		// sale.payableAmount = Math.round(sale.total);
		// if(sale.adjustmentName != undefined && sale.adjustmentName != '')
		// 	sale.payableAmount = Number(sale.payableAmount) + Number(sale.adjustmentAmount || 0);
		// sale.payableAmount = parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2);

		return taxDetails
	}

	printPaymentReceipt(print, index){
		if(!print){
			this.setState({
				showPaymentReceiptModal: true
			})
			this.getPaymentDetails();
		}
		else if(print == 2 || this.state.paymentReceipt.paymentStatus == "DUE"){
			Client.addAccountingPaymentDetails(this.state.currentDataRow.invoiceId, this.state.paymentReceipt).then(res=>{
				this.getPaymentDetails();
			})
		}

		if(print == 1){
	        if(index != undefined){
	            var sale = {...this.state.sales[index]};
	            this.setState({
	                currentInvoiceIndex: index,
	                currentDataRow: sale
	            })
	        }
	        else{
	            index = this.state.currentDataRowIndex
	        }

	        var sale = {...this.state.data_rows[index]};
	        var taxDetails = this.calculateTax(sale)

	        var taxDetailsView = '';

	        var productsTable =
	        '<tr style="background:#CCC"><th style="min-width:10px">Invoice No.</th><th>Invoice Date</th><th>Invoice Amount</th><th>Payment Amount</th></tr>'
	        productsTable = productsTable +
	        '<tr><td style="text-align:center">' + sale.voucher_id + '</td><td style="text-align:center">' + sale.date + '</td>' +
							'<td style="text-align:center"> Rs.' + sale.payable_amount +
	                        '</td><td style="text-align:center"> Rs.' + this.state.paymentReceipt.amount + '</td></tr>'

	        var invoiceHtml = `
	        <!doctype html>
	        <html>
	        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
	            <table>
	                <tr>
	                    <td style="width:10%">
	                        <img style="width:100%" src=${logo}><img/>
	                    </td>
	                    <td>
							<p style="font-weight:bold; font-size:14px; padding:0; margin:0 0 2px 5px">DESKNEED<br>(Science2home Pvt. Ltd.)</p>
	                        <p style="padding:0; margin:0 0 0 5px">
	                        R G B Road, Ganeshguri<br/>
	                        Guwahati, Assam - 781006<br/>
	                        GSTIN: 18AAXCS9739A1ZO<br/>
	                        PAN: AAXCS9739A<br/>
	                        MSME UAM No.: AS03D0001520<br/>
	                        Contact: +91-9954683549<br/>
	                        E-mail: contact@deskneed.com
	                        </p>
	                    </td>
	                    <td style="font-size:300%; text-align:right; padding-right:40px">
	                        PAYMENT RECEIPT
	                    </td>
	                </tr>
	            </table>

	            <div style="border-top:solid 1px #999; padding: 5px 0 5px 0">
	                <table style="margin:0 0 0 5px">
	                    <tr>
	                        <td><b>Payment Date</b></td>
	                        <td>: ${this.state.paymentReceipt.date}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Reference Number</b></td>
	                        <td>: ${this.state.paymentReceipt.referenceNumber}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Payment Mode</b></td>
	                        <td>: ${this.state.paymentReceipt.paymentMode}</td>
	                    <tr>
						<tr>
	                        <td><b>Amount Received</b></td>
	                        <td>: Rs.${sale.payable_amount}</td>
	                    <tr>
	                    <tr>
	                        <td><b>Amount Received In Words</b></td>
	                        <td>: Rupees ${converter.toWords(sale.payable_amount)}</td>
	                    <tr>
	                </table>
	            </div>

	            <table class="productsTable">
	                ${productsTable}
	            </table>

	            <table style="border-top:solid 1px #999; width:100%; margin:0 0 0 0px">
	                <tr>
	                    <td style="vertical-align:top; padding:5px 0 0 5px; text-align:center">
	                        <br/>
	                        ${(sale.remarks && sale.remarks !='')? sale.remarks: 'Hope to serve you again soon.'}
	                        <br/>
	                    </td>
	                    <td style="border-left:solid 1px #999; padding:0; text-align:center">
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        <br/>
	                        Authorised Signatory
	                        </div>
	                    </td>
	                </tr>
	            </table>

	        </body>
	        <style>
	            body{
	                margin: 0em;
	            }
	            *{
	                font-family: sans-serif
	            }
	            table{
	                border-collapse: collapse;
	            }
	            .productsTable, .productsTableInner{
	                width:100%;
	            }
	            .productsTable td{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTable th{
	                border-top:solid 1px #999;
	                border-left:solid 1px #999;
	            }
	            .productsTableInner td{
	                border:none;
	            }
	            .productsTableInner th{
	                border:none;
	            }
	        </style>
	        </html>
	        `

	        // document.getElementById('showInvoiceContainer').innerHTML = invoiceHtml
	        // document.getElementById('showInvoiceContainerMain').style.visibility = 'visible'

	        // if(print){
	            var printwindow = window.open('','dhp_inv_payment_receipt', 'height=1000, width=1000');
	    		printwindow.document.write(
	                invoiceHtml
	    		);
	            printwindow.focus();
	            setTimeout(function() {
	                printwindow.document.title = sale.invoiceId + "_PAYMENT_RECEIPT";
	                printwindow.print();
	                printwindow.close();
	                printwindow.document.close();
	            }, 500);
	        // }
		}
    }

	getPaymentDetails(){
		Client.getAccountingPaymentDetails(this.state.currentDataRow.invoiceId).then(res=>{
			var paymentReceiptDetails = res.paymentDetails;
			res.paymentDetails.paymentInvoices.forEach(function(paymentInvoice){
				if(paymentInvoice.invoiceId == this.state.currentDataRow.invoiceId){
					paymentReceiptDetails.paymentAmount = paymentInvoice.paymentAmount
				}
			}.bind(this))
			this.setState({
				paymentReceipt: res.paymentDetails,
			})
		})
	}

    editSale(){
		localStorage.setItem('currentInvoiceSale', JSON.stringify(this.state.currentDataRow));
		localStorage.setItem('currentInvoiceSaleEdit', 1);
		this.props.history.push('/accounting/vouchers/credit-note/create')
	}

	cancelInvoice(){
		this.setState({loadingCancelInvoice: true})
		Client.cancelAccountingInvoiceSale(this.state.currentDataRow.voucher_id, this.state.cancelReason).then(res=>{
			var state = {...this.state}
			var data_rows = [...state.data_rows]
			var currentDataRow = {...state.currentDataRow}
			currentDataRow.status = "cancelled";
			currentDataRow.debit = 0;
			currentDataRow.credit = 0;
			data_rows[state.currentDataRowIndex] = currentDataRow
			this.setState({
				loadingCancelInvoice: false,
				showCancelInvoiceOption: false,
				data_rows: data_rows,
				totalSale: state.totalSale - currentDataRow.payable_amount,
				totalTax: state.totalTax - currentDataRow.tax_amount
			})
			this.showDataRowDetails(state.currentDataRowIndex);
		})
	}
	// Graphs 
	getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
	occuranceOfLabelInAxis(label_value,lable_name,axis_value,axis_name,data_rows){
		var matched = data_rows.filter((elem)=>{
			return elem[axis_name] == axis_value && elem[lable_name] == label_value
		})
		return matched.length
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    countReportLeaves(data_rows){
        var sick_leaves = 0
        var casual_leaves = 0
        var unpaid_leaves = 0
        var half_days = 0
        var holidays = 0
        var undefined_leaves  = 0
        var present_days = 0
        data_rows.forEach(elem=>{
            switch (elem.leave_type) {
                case 'SL':
                        sick_leaves++
                    break;
                case 'CL':
                    casual_leaves++
                break;
                case 'UL':
                    unpaid_leaves++
                break;
                case 'HD':
                    half_days++
                break;
                case 'HO':
                    holidays++
                break;
                default:
                    break;
            }
            if(elem.time_in != null && elem.leave_type == null){
                present_days++
            }
        })
        const report_leaves = {
            sl:sick_leaves,
            cl:casual_leaves,
            ul:unpaid_leaves,
            hd:half_days,
            ho:holidays,
            pd:present_days
        }
        this.setState({report_leaves})
    }
	prepareGraphData(label,axis){	
		this.setState({[axis+'_graph']:false})		
        var data_rows = []
        var stfIDs = []
        this.state.options_customer.forEach((elem)=>{
            stfIDs.push(elem.value)
		})
		console.log(this.state.graph_customer)
        stfIDs = (this.state.graph_customer && this.state.graph_customer != null)  ? [this.state.graph_customer] : stfIDs
		let filter={customer:[...stfIDs]}
        let range = {date :[this.state.start_date, this.state.end_date]}
        this.setState({loading_report:true,report_leaves:false})
		Client.getAccountingSales(null,null,"date","asc",null,null,null,filter,range,axis+Math.random()).then((res)=>{
            let leaveType = this.state.leave_type?this.state.leave_type:[]					
			data_rows = axis == "month"?this.withMonth(res.data_rows,leaveType):res.data_rows//chopped in months
			var master_data = {}
			var distinct_axises = this.getDistictIn(axis,data_rows)
			// for(var key in distinct_axises){
			// 	var distinct_axis_data = distinct_axises[key]
			// 	master_data[key] = this.getDistictIn(label,distinct_axis_data)				
			// }
			let data_for_graph = []
			for(var key in distinct_axises){
				// var value = distinct_axises[key]
				var mapping = {}
				// for(var key1 in value){
					let amount = 0
					distinct_axises[key].forEach((elem)=>{
						amount += parseInt(elem.payable_amount)
					})
					mapping["sale"] = distinct_axises[key].length
					mapping['amount'] = amount
				// }
				data_for_graph.push({
					name:key, ...mapping
				})
			}
			console.log("data_axis",distinct_axises,"master ",master_data)
			console.log(data_for_graph)
			this.setState({[axis + '_graph']:data_for_graph,loading_report:false})
		})
	}
	range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0' 
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
	handleGraphParamChange(e,data){
		this.setState({
			[data.name]:data.value
		},()=>{
			this.prepareGraphData("customer","month")
			this.prepareGraphData("customer","date")
		})
	}
	staffName = (staff)=>{
		var staffarr = this.state.options_customer.filter((elem)=>{
			return elem.value == staff
		})
		return staffarr[0].text
	}
	showReportModal(){
		this.setState({modalReport:true},()=>{
			this.prepareGraphData("customer","month")
			this.prepareGraphData("customer","date")
		})
	}
	handleCancel(voucher){
			let data_rows = this.state.data_rows.map(elem=>{
				if (elem.voucher_id == voucher.voucher_id) {
					return {...elem,...voucher}
				} else {
					return elem
				}
			})
			this.setState({data_rows})
	}
	render(){
		return(
			<div>
				<div style={{display:'inline-flex'}}>
				<Button size={this.state.defaultSize} onClick={() => this.props.history.push('/accounting/vouchers/credit-note/create')} primary>CREATE</Button>
			    <Button size={this.state.defaultSize} onClick={() => this.showReportModal()} color="green">REPORT</Button>				
				<Button size={this.state.defaultSize} style={{padding:0}}>
					<Dropdown multiple search selection clearable
						size={this.state.defaultSize}
						name="date" options={this.state.options_month}
						value={this.state.data_rows_filter.date}
						placeholder="Select Month" onChange={(e, data) => this.setFilter(e, data)}/>
				</Button>
				</div>
				<div style={{display:'inline-block'}}>
					<div style={{display:'inline-flex'}}>
						<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								options={this.state.tableFields}
								name="data_rows_search_field"
								value={this.state.data_rows_search_field}
								placeholder="Search Field"
								onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
						</Button>
					</div>
					{
						this.state.data_rows_search_type == 'select'
						?
						<Button size={this.state.defaultSize} style={{padding:0}}>
							<Dropdown selection
								search name="data_rows_search"
								size={this.state.defaultSize}
								options={this.state['options_' + this.state.data_rows_search_field]}
								value={this.state.data_rows_search}
								placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						</Button>
						:
						<Input type={this.state.data_rows_search_type == 'field' ? 'text' : this.state.data_rows_search_type}
							name="data_rows_search"
							size={this.state.defaultSize}
							value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					}
					<div style={{display:'inline-block'}}>
						{
							this.state.data_rows_search_field == "customer" && this.state.showBranch 
							?
							<Dropdown loading={this.state.loading_branch} clearable selection search name="cust_branch" options={this.state.options_branch_sort} placeholder="Select Branch" onChange={(e, data) => this.changeStateVariable(e, data)}/>
							:
							null
						}
					</div>
					<div style={{display:'inline-block'}}>
						<Button
							size={this.state.defaultSize}
							color='teal'
							icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit}
							onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
						<Dropdown
							name='data_rows_limit'
							inline
							className="limit-drop"
							options={this.state.options_data_rows_limit}
							value={this.state.data_rows_limit}
							onChange={(e, data) => this.changeStateVariable(e, data)}
						/>
						{
							'Showing ' + this.state.data_rows_offset + ' to ' +
							(
								(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
								(this.state.data_rows_offset + this.state.data_rows_limit) :
								this.state.data_rows_size
							) +
							' of ' + this.state.data_rows_size + ' '
						}
						<Button
							size={this.state.defaultSize}
							color='teal'
							icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit}
							onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					</div>
				</div>
				<Segment.Group size='mini' floated='right'>
					<Table>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell>Description</Table.HeaderCell>
								<Table.HeaderCell>Amount</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							<Table.Row>
								<Table.Cell>Total Sale</Table.Cell>
								<Table.Cell>{this.state.totalSale}</Table.Cell>
							</Table.Row>
							<Table.Row>
								<Table.Cell>Tax Amount</Table.Cell>
								<Table.Cell>{this.state.totalTax}</Table.Cell>
							</Table.Row>
						</Table.Body>
					</Table>
				</Segment.Group>
				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body style={{height:'15vh', overflowY:'scroll'}}>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>
										{
											field.value == "customer"
											?
												""+data_row.customer_details.name +
													(
														(data_row.customer_details.branchName && data_row.customer_details.branchName.trim() != "" &&  data_row.customer_details.branchName !=null)
													?
													" ("+data_row.customer_details.branchName+")"
													:
													""
													) 
											:
												(field.type=='select'
												?
													this.convertSelectToText(field.value, data_row[field.value])
												:
												data_row[field.value])
										}
									</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal size="large"  open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {records: [{}]}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content scrolling>
						<Form size={this.state.defaultSize} onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths='equal'>
								<Form.Select options={this.state.options_voucher_id}
									allowAdditions
									search
									onAddItem={(e, value) => this.handleVoucherIdAdditions(e, value)}
									type="text"
									name="voucher_id"
									placeholder="Invoice #" label="Voucher #" selection value={this.state.new_data_row.voucher_id}
									onChange ={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									required/>
								<Form.Input type="date" required fluid name="date" value={this.state.new_data_row.date} label="Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select selection search options={this.state.options_customer} type="text" required fluid name="account" value={this.state.new_data_row.account} label="Account" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="number" fluid required name="amount" value={this.state.new_data_row.amount} label="Amount" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Table celled compact className="accounting-invoice-product-table">
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell>LEDGER</Table.HeaderCell>
										<Table.HeaderCell>REFERENCE</Table.HeaderCell>
										<Table.HeaderCell>AMOUNT</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
								{

									this.state.new_data_row.records.map((record, index) => (
										<Table.Row>
											<Table.Cell>
												<Form.Select id={"accounting-receipt-record-ledger" + index} selection search options={this.state.options_record_ledgers}
												type="text" required fluid name="ledger" value={record.ledger} placeholder="Ledger" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell>
												<Form.Select clearable selection search
												options={record.options_references} type="text" required fluid
												loading={record.loadingReferences}
												name="against_reference" value={record.against_reference} placeholder="Against Reference" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell>
												<Form.Input type="number" fluid required name="amount" value={record.amount} placeholder="Amount" onChange={(e, data) => this.changeStateVariableRecord(e, data, index)}/>
											</Table.Cell>
											<Table.Cell collapsing>
												<Icon circular name='delete' size='large' onClick={() => this.removeRecord(index)} color="red"/>
											</Table.Cell>
										</Table.Row>
									))
								}
								</Table.Body>
								<Table.Footer fullWidth>
									<Table.Row>
										<Table.HeaderCell colSpan='4'>
											<Button secondary onClick={(e) => this.addRecord(e)}>Add Record</Button>
											<Label size='large' color='blue'>Total: {this.state.new_data_row.records_total}</Label>
										</Table.HeaderCell>
									</Table.Row>
								</Table.Footer>
							</Table>
							<Form.Input type="text" fluid name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>

				<Modal size="large" open={this.state.showSaleDetailsModal} onClose={() => this.setState({showSaleDetailsModal:false, showCancelInvoiceOption: false})}>
					<Modal.Content scrolling>
						<CreditNoteView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
					</Modal.Content>
					
				</Modal>

				<Modal size="small" open={this.state.showPaymentReceiptModal} onClose={() => this.setState({showPaymentReceiptModal:false})}>
					{
						this.state.paymentReceipt.paymentInvoices.map((receiptInvoice) => (
							<div style={{textAlign:"center"}}>
								Payment ID: {receiptInvoice.paymentId} - Payment Status: {this.state.paymentReceipt.paymentStatus}<br/>
								Payment Date: {receiptInvoice.paymentDate} - Payment Mode: {receiptInvoice.paymentMode}<br/>
								Payment Amount: {receiptInvoice.paymentAmount}<br/>
								TDS Amount: {receiptInvoice.tdsAmount}<br/>
								GST TDS: {receiptInvoice.gstTdsAmount}
							</div>
						))
						// this.state.paymentReceipt.paymentInvoices.length > 0
						// ?
						// LAST PAYMENT DETAILS
						// <div>{this.state.paymentReceipt.paymentStatus}  {this.state.paymentReceipt.paymentInvoices[0].paymentAmount}</div>
						// :
						// null
					}
					<Modal.Content>
						<Input name="date" fluid type="date" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment Date" defaultValue={this.state.paymentReceipt.paymentDate || this.state.currentDataRow.date}/>
						<Input name="amount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Amount" defaultValue={this.state.paymentReceipt.paymentAmount || this.state.currentDataRow.payable_amount}/>
						<Input name="tdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="IT TDS Amount" defaultValue={this.state.paymentReceipt.tdsAmount}/>
						<Input name="gstTdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="GST TDS Amount" defaultValue={this.state.paymentReceipt.gstTdsAmount}/>
						<Input name="paymentMode" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Cash/Credit/Cheque/NEFT" defaultValue={this.state.paymentReceipt.paymentMode}/>
						<Input name="referenceNumber" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Reference Number (If Any)" defaultValue={this.state.paymentReceipt.referenceNumber}/>
						<Input name="paymentId" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment ID (If Any)" defaultValue={this.state.paymentReceipt.paymentId}/>
						<Button fluid onClick={() => this.printPaymentReceipt(1)} primary>PRINT</Button>
						{
							this.state.paymentReceipt.paymentStatus == "PAID"
							?
							<Button fluid onClick={() => this.printPaymentReceipt(2)} primary>ADD TO PAYMENTS</Button>
							:
							null
						}
					</Modal.Content>
				</Modal>
				<Modal open={this.state.sale_voucher_show}>
					<Modal.Content scrolling>

					</Modal.Content>
				</Modal>
				<Modal open={this.state.modalReport} size="large" onClose={() => this.setState({modalReport:false,date_graph:false,address_graph:false})}>
				    	<Modal.Content scrolling>
							<div style={{display:'inline-flex'}}>
								<Dropdown 
									clearable
									selection
									search
									placeholder="Choose Customer"
									options={this.state.options_customer}
									name = "graph_customer"
										onChange={(e,data)=>{ this.handleGraphParamChange(e,data)}}
										/> 
								<Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
									placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphParamChange(e, data)}/>
								<Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
									placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphParamChange(e, data)}/>
							</div>
							
							<hr></hr>
				    	    <Grid divided='vertically'>
    							<Grid.Row  columns={2}>
									<Grid.Column  >
										{
											this.state.month_graph
											?
											<LineChart width={500} height={300} data={this.state.month_graph}
												margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
												<CartesianGrid strokeDasharray="10 10" />
												<XAxis dataKey="name" />
												<YAxis yAxisId="left" dataKey='sale' label={{ value: 'Sale', angle: -90, position: 'insideLeft' }} stroke='#41527D' strokeWidth={3} />												
												<YAxis  tickFormatter={tick => {return tick/100000}} yAxisId="right"  orientation="right" label={{ value: 'Amount', angle: -90, position: 'outsideLeft', offset: 30}} dataKey='amount' stroke='#41527D' strokeWidth={3} />												<Tooltip />
												<Legend />		
												<Line yAxisId="left" type="monotone" dataKey="sale" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
												<Line yAxisId="right" type="monotone" dataKey="amount" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
											</LineChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>
												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}	
     						 		</Grid.Column>
									<Grid.Column  >
										{
											this.state.date_graph
											?
											<LineChart width={500} height={300} data={this.state.date_graph}
												margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
												<CartesianGrid strokeDasharray="10 10" />
												<XAxis dataKey="name" />	
												<YAxis yAxisId="left2" dataKey='sale' label={{ value: 'Sale', angle: -90, position: 'insideLeft' }} stroke='#41527D' strokeWidth={3} />												
												<YAxis yAxisId="right2" orientation="right" label={{ value: 'Amount', angle: -90, position: 'outsideLeft', offset: 30}} dataKey='amount' stroke='#41527D' strokeWidth={3} />												<Tooltip />
												<Legend />	
												<Line yAxisId="left2" type="monotone" dataKey="sale" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
												<Line yAxisId="right2" type="monotone" dataKey="amount" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
											</LineChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>
										
												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}	
									</Grid.Column>
     						 	</Grid.Row>
							</Grid>
						</Modal.Content>			
                </Modal>

			</div>
        )
	}
}

export default AccountingCreditNote;
