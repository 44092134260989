import React, {Component} from 'react';
import '../style/order-slip.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Checkbox, Progress, Input, Header, Table, Button, Message, Dropdown, Modal, Icon, Form, Loader, Segment, Dimmer, Image,Placeholder, Label,Responsive,Card, Grid, Step, Breadcrumb} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import OrderSlipUpdate from "./order-slip-update";
import OrderSlipPurchase from "./order-slip-purchase";
import FileOrdersPreview from "../components/file-order-preview";
import { BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

import {
	DateInput,
	TimeInput,
	DateTimeInput,
	DatesRangeInput
  } from 'semantic-ui-calendar-react';
import SaleView from './sale-voucher-view';
import QuotationView from './quotation-view';
import PurchaseView from './purchase-view';
import DeleveryChallanView from './d-note-view';
import VerifyOrder from '../components/verify-order';
const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class OrderSlip extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;
		// document.write(today);
		const userData = JSON.parse(localStorage.getItem('userData'))
		// const emp_string = userData.emp_id.split('/')
		// const emp_id = emp_string[emp_string.length - 1]
		const emp_id = userData.staff_id
		const emp_dept = userData.department
		this.state = {
			analytics: [],
			contracts: [],
			selectedContractProducts: [],
			emp_dept:emp_dept,
			emp_id:emp_id,
			today: today,
			data_rows: [],
			new_data_row: {slip_date: today, 'current_status': 'initiated', list: [{}]},
			tableFields: [
				{"value": "slip_no", "text": "Slip No.", "type": "field"},
				{"value": "priority", "text": "Priority", "type": "field"},
				{"value": "slip_date", "text": "Slip Date", "type": "date"},
				{"value": "customer", "text": "Customer", "type": "select"},
				{"value": "requirement", "text": "Requirement", "type": "field"},
				{"value": "vendor", "text": "Vendor", "type": "select"},
				{"value": "current_status", "text": "Status", "type": "select"},
				{"value": "next_date", "text": "Next Update On", "type": "date"},
				{"value": "current_department", "text": "Department", "type": "select"},
				{"value": "current_employee", "text": "Employee", "type": "select"},
				{"value": "revenue_margin", "text": "Margin", "type": "field"},
			],
			tableFieldsVoucher: [
				{"value": "date", "text": "Voucher Date", "type": "date"},
				{"value": "ledger", "text": "Vendor / Customer", "type": "select"},
				{"value": "voucher_id", "text": "Invoice Id", "type": "field"},
				{"value": "order_id", "text": "Order Id", "type": "field"},
				{"value": "payable_amount", "text": "Amount", "type": "field"},
				{"value": "status", "text": "Status", "type": "select"},
			],
			tableButtons: [
				// {"value": "updates", "text": "UPDATES", "type": "button"},
				{"value": "purchases", "text": "PURCHASES", "type": "button"}
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'slip_date',
			data_rows_sort_order: 'desc',
			data_rows_search_field: 'customer',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_branch:'',
			breadcrumbs:[],
			options_branch:[
				{},
			],
			options_branch_sort:[
				{},
			],
			marking_issue_show_customer:1,
			marking_issue_comment:'',
			data_rows_filter: {},
			data_rows_remove_filter: {},
			// '{"current_status":["initiated","follow","site_visit","quotation","quotation_follow","work_due","work_progress","invoice","payment","feedback"]}',
			// options_current_status: [
			// 	{"value": "initiated", "text": "INITIATED", "after": ""},
			// 	{"value": "follow", "text": "FOLLOW UP", "after": "initiated"},
			// 	{"value": "site_visit", "text": "SITE VISIT", "after": "initiated,follow"},
			// 	{"value": "quotation", "text": "QUOTATION", "after": "initiated,follow,site_visit"},
			// 	{"value": "quotation_follow", "text": "QUOTATION FOLLOW", "after": "quotation"},
			// 	{"value": "work_due", "text": "WORK DUE", "after": "initiated,follow,site_visit,quotation,quotation_follow"},
			// 	{"value": "work_progress", "text": "WORK IN PROGRESS", "after": "initiated,follow,site_visit,quotation,quotation_follow,work_due"},
			// 	{"value": "invoice", "text": "INVOICE", "after": "work_progress"},
			// 	{"value": "payment", "text": "PAYMENT", "after": "invoice"},
			// 	{"value": "feedback", "text": "FEEDBACK", "after": "payment,invoice"},
			// 	{"value": "completed", "text": "COMPLETED", "after": "feedback,payment"},
			// 	{"value": "cancelled", "text": "CANCELLED", "after": "initiated,follow,site_visit,quotation,quotation_follow,work_due"}],

			options_customer: [],
			options_vendor: [],
			options_current_department: [],
			options_current_employee: [],
			options_current_employee_comments: [],
			options_company_users: [],
			options_order_via: [
				{text: 'Call', value: 'call'},
				{text: 'SMS', value: 'sms'},
				{text: 'Email', value: 'email'},
				{text: 'Justdial', value: 'jd'},
				{text: 'India mart', value: 'im'},
				{text: 'Offline Marketing', value: 'omark'},
				{text: 'Others', value: 'oth'},
			],
			selected_data_row: {},
			viewInnerDetail: 'purchases',
			data_mode: 'active',
			show_comment_of :'',
			show_contact_of :'',
			comments:'',
			new_comment : '',
			showView:'cards',
			autoCompleteText:'',
			delimeter:'',
			showLoaderOn:'',
			graph_employee: [],
			graph_status:[],
			removeableVendor:[],
			removeableTeam:[],
			redirect_to:[
				{text:'Sale',value:'/accounting/vouchers/invoice/create',key:'sale'},
				{text:'Quotation',value:'/accounting/vouchers/quotation/create',key:'quotation'},
				{text:'Purchase',value:'/accounting/vouchers/purchase/create',key:'purchase'},
				{text:'Challan',value:'/accounting/vouchers/challan/create',key:'delivery_note'},
			],
			currentDataRow:{},
			paymentReceipt: {paymentInvoices:[{}]},
			currentInvoiceSale: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50},
				{text: 100, value: 100},
				{text: 150, value: 150},
				{text:200, value: 200},
				{text: 250, value: 250},
				{text: 500, value: 500},
			],
			count_bar_limit:6
		}
	}

	componentDidMount() {
		window.addEventListener('resize', ()=>{
            if (window.innerWidth<992) {
                this.setState({
					showView:'cards'
                })
            }
		})
		let options_current_status = JSON.parse(localStorage.getItem('option_statuses'))
		var options_vendor = [{text: "UNASSIGNED", value: null}];
		// var options_vendor = [{text: "UNASSIGNED", value: null}];
		var options_customer = [];
		var options_current_department = [{text: "UNASSIGNED", value: null, statuses: 'completed,cancelled'}];
		var options_current_employee = [{text: "UNASSIGNED", value: null, department: '1'}];
		var options_current_employee_comments = []
		this.setState({start_date:this.range(false),end_date:this.range(true),})
		this.setState({
			loadingDataRows: true,
			options_current_status
		})

		Client.getStaffs(null, null, null, null, null, null, {}, null).then(res=>{
			res.data_rows.forEach(function(data_row){
				options_current_employee.push({
					text: data_row.name,
					value: data_row.id,
					department: data_row.department
				})
				var nameArr = data_row.name.split(' ')
				options_current_employee_comments.push({
						text: nameArr.join('_'),
						value: data_row.id,
						department: data_row.department
				})
			})
			this.setState({
				options_current_employee: options_current_employee
			})
			this.setState({
				options_current_employee_comments: options_current_employee_comments
			})
		})
		var options_current_vendor_comments =[]
		Client.getVendors().then((res)=>{
			res.data_rows.forEach(function(data_row){
				var nameArr = data_row.ledger_name.split(' ')
				options_current_vendor_comments.push({
						text: nameArr.join('_'),
						value: data_row.id+":"+nameArr.join('_'),
				})
			})
			res.data_rows.forEach((data_row)=>{
				options_vendor.push({
					text: data_row.ledger_name,
					value: data_row.id
				})
			})
			this.setState({
				options_current_vendor_comments: options_current_vendor_comments,
				options_vendor:options_vendor
			})
		})
		Client.getDepartments().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_current_department.push({
					text: data_row.department,
					value: data_row.id,
					statuses: data_row.statuses
				})
			})
			this.setState({
				options_current_department: options_current_department
			})
		})
		// Client.getVendors(null,null,null,null,null,null,null,'for create').then(res=>{
		// 	options_vendor.push({
		// 		text: 'UNASSIGNED',
		// 		value: null
		// 	})

		// 	this.setState({
		// 		options_vendor: options_vendor
		// 	})
		// })
		Client.getAccountingMastersLedger(null,null,null,null,null,null,null,{group:[151,152]}).then(res=>{
			res.data_rows.forEach(function(data_row){
				options_customer.push({
					text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''),
					value: data_row.id
				})
			})
			this.setState({
				options_customer: options_customer
			})
		})
		this.getDataRows(this.state);
		// let filter_staff = {role:['marketing']}
		if (localStorage.getItem('order-redirect-status')) {
			let redirectStatus = localStorage.getItem('order-redirect-status')
			localStorage.removeItem('order-redirect-status')
			setTimeout(()=>{
			// 	this.changeStateVariable({},{name:'data_rows_search_field',value:'current_status'})
			// 	setTimeout(()=>{
			// 		// if (redirectStatus == 'cancelled' || redirectStatus == 'completed') {
			// 		// 	this.setArchiveFilter()
			// 		// } else {
			// 		// 	this.setActiveFilter()
			// 		// }
			// 		this.changeStateVariable({},{name:'data_rows_search',value:redirectStatus})
			// 	},300)
			this.setFilter({},{name:'current_status',value:redirectStatus},false)
			},1000)
		}
		setTimeout(()=>{
			if (localStorage.getItem('order-redirect-month')) {
				this.changeStateVariable({},{
					name:'data_rows_search_field',
					value:'next_date'
				},false)
				setTimeout(()=>{
					this.changeStateVariable({},{
						name:'data_rows_search',
						value:localStorage.getItem('order-redirect-month')+'-01'
					})
					localStorage.removeItem('order-redirect-month')
				},300)
				// this.setFilter({},{name:'next_date',value:localStorage.getItem('order-redirect-month')+'-01'})
				// localStorage.removeItem('order-redirect-month')
			}
			if (localStorage.getItem('order-redirect-date')) {
				this.changeStateVariable({},{
					name:'data_rows_search_field',
					value:'next_date'
				},false)
				setTimeout(()=>{
					this.changeStateVariable({},{
						name:'data_rows_search',
						value:localStorage.getItem('order-redirect-date')
					})
					localStorage.removeItem('order-redirect-date')
				},300)
				// this.setFilter({},{name:'next_date',value:localStorage.getItem('order-redirect-date')})
				// localStorage.removeItem('order-redirect-date')
			}
		},500)
		this.checkShortHandsInComment = this.checkShortHandsInComment.bind(this)

	}
	setFilter(e, data,exact,type="less",del=false,remove=false, fetch=true){
		var filter = {...this.state.data_rows_filter}
		// var array = data.value;
		if (this.state.data_rows_search_field == data.name) {
			this.changeStateVariable({},{
				name:'data_rows_search_field',
				value:'customer'
			},false)
		}
		if (del && !filter.hasOwnProperty(data.name)) {
			delete filter[del]
		}
		console.log(this.state.data_rows_remove_filter,"ini");

		let temp_remove =remove?{...remove,...this.state.data_rows_remove_filter}:{...this.state.data_rows_remove_filter}
		console.log(data.name,temp_remove,this.state.data_rows_remove_filter,data.value,"test");

		if (!exact) { // For array filters
			if (filter.hasOwnProperty(data.name)) {
				if (filter[data.name].value.includes(data.value)) {
					filter[data.name].value = filter[data.name].value.filter(elem =>{return elem!=data.value})
					if (!filter[data.name].value.length) {
						delete filter[data.name]
					}
				} else {
					filter[data.name].value.push(data.value)
				}
			} else {
				filter[data.name] = {filter_type:'value_in',value:[data.value]}
			}
			if (temp_remove.hasOwnProperty(data.name)) {
				temp_remove[data.name].value.includes(data.value)
				temp_remove[data.name].value = temp_remove[data.name].value.filter(elem=>{return elem != data.value})
				console.log("remove filter",temp_remove,remove,data.name);
			}
		} else { // for exact filters
			if (filter.hasOwnProperty(data.name)) {
				if (filter[data.name].filter_type == 'value_'+type) {
					delete filter[data.name]
				} else {
					delete filter[data.name]
					filter[data.name]={filter_type:'value_'+type,value:data.value}

				}
			} else {
				filter[data.name]={filter_type:'value_'+type,value:data.value}
			}
		}

		var state = {...this.state}
		state.data_rows_filter = filter
		console.log(temp_remove);
		if (!state.data_rows_filter.hasOwnProperty("next_date") &&!state.data_rows_filter.hasOwnProperty("current_employee")) {
			temp_remove={}
		}
		state.data_rows_remove_filter = temp_remove
		this.setState(state)
		if(fetch)
			this.getDataRows(state)
	}
    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }
	toggleCommentOf(e,data){
		if (this.state.show_comment_of == data) {
			this.setState({
				show_comment_of:'',
				new_comment:''
				},()=>{
					// console.log(this.state.show_comment_of,'removed')
			})
		}else{
			this.setState({
				show_comment_of:data,
				new_comment:'',
				show_contact_of:''
			},()=>{
				this.populateRemoveables(data)
				this.getComments()
			})
		}
	}
	populateRemoveables(data){
		var current_order = this.state.data_rows.filter((order)=>{
			return order.slip_no == data
		})
		if(current_order.length > 0){
			var teams = current_order[0].contacts.filter((team)=>{
				return team.type == "TEAM"
			})
			var vendors = current_order[0].contacts.filter((team)=>{
				return team.type == "VENDOR"
			})
			var removeableTeam = teams.map((team)=>{
				var name = team.name.split(' ')
				let preparedName = name.join('_')
				return {text:team.name,value:team.id+":"+preparedName}
			})
			var removeableVendor = vendors.map((vendor)=>{
				var name = vendor.name.split(' ')
				let preparedName = name.join('_')
				return {text:vendor.name,value:vendor.id+":"+preparedName}
			})
			this.setState({removeableTeam,removeableVendor})
		}
	}
	getComments(){
		this.setState({loading:true})
		const offset = 0, limit ='', sort='', sort_order='', search_field='', search='', filter={slip_no:this.state.show_comment_of}
		Client.getOrderSlipComment(offset, limit, sort, sort_order, search_field, search, filter).then(
			(res)=>{
				this.setState({comments:res.data_rows,loading:false},()=>{
					// this.setState({loading:false})
					this.extractBreadcrumbs(this.state.comments)
				})
			}
		)
	}
	postComment(){
		this.setState({loading:true})
		const edit=false
		let wordsInComment = this.state.new_comment.split(' ');
		wordsInComment.forEach(word => {
			if( word.indexOf("@")!=-1 ){
				this.setState({reloadOnPost:true})
			}
		})
		const new_data_row = {comment:this.state.new_comment,slip_no:this.state.show_comment_of,show_customer:this.state.new_comment_visibility?1:0}
		Client.createOrderSlipComments(new_data_row, edit).then(()=>{
			this.setState({
				new_comment:'',
			})
			if (this.state.reloadOnPost === true) {
				this.setState({
					reloadOnPost:false,
					show_comment_of:''
				},()=>{
					this.componentDidMount()
				})
			} else {
				this.getComments()
			}

		})
	}
	toggleContactOf(e,data){
		if (data === this.state.show_contact_of) {
			this.setState({show_contact_of:''},()=>{console.log(this.state.show_contact_of,'removed')})
		}else{
			this.setState({
				show_contact_of:data,
				show_comment_of:''
			},()=>{
				// console.log(this.state.show_contact_of,'Added')
			})
		}
	}
	checkForDelimeters(){
		const newcomment = this.state.new_comment.split(' ');
		switch (newcomment[newcomment.length -1]) {
			case 'assign@':
				this.setState({delimeter:'assign'},()=>{
					document.getElementById('assignDrop').click()
				})
				break;
			case 'schedule@':
				this.setState({delimeter:'schedule'},()=>{
					document.getElementById('schedulePicker').focus()
					document.getElementById('schedulePicker').click()
				})
				break;
			case 'update@':
				this.setState({delimeter:'update'},()=>{
					document.getElementById('updateDrop').click()
				})
				break;
			case 'inform@':
				this.setState({delimeter:'inform'},()=>{
					document.getElementById('informDrop').click()
				})
				break;
			case 'vendor@':
				this.setState({delimeter:'vendor'},()=>{
					document.getElementById('vendorDrop').click()
				})
				break;
			case 'remove_vendor@':
				this.setState({delimeter:'remove_vendor'},()=>{
					document.getElementById('removeVendorDrop').click()
				})
				break;
			case 'remove_inform@':
				this.setState({delimeter:'remove_inform'},()=>{
					document.getElementById('removeInformDrop').click()
				})
				break;
			default:
				break;
		}
	}
	keyDown(event){
		if(event.which === 13 || event.keyCode === 13){
			this.editInput();
		}
	}
	onLabelSelect= (event,data)=>{
		// console.log(this.state.autoCompleteText);

		setTimeout(() => {
			if(this.state.autoCompleteText!='')
				this.editInput()
		}, 100);
	}
	editInput = ()=>{
		let newcomment = this.state.new_comment;
		// console.log(newcomment);
			if ((this.state.delimeter == 'assign') || (this.state.delimeter== 'inform')) {
				var object = this.state.options_current_employee_comments.filter((employee)=>{
					return this.state.autoCompleteText == employee.value
				})
				newcomment += this.state.autoCompleteText+':'+object[0].text +';; '
			}
			else{
				newcomment += this.state.autoCompleteText + ';; '
			}
			this.setState({new_comment:newcomment},()=>{
				this.setState({delimeter:'',autoCompleteText:''})
			},()=>{
				document.getElementById('commentInput').focus()
			})
	}
	commentBoxKeyDown(event){
		if (event.which == 8 || event.keyCode == 8){
			this.onBackSpace(event)
		}
		if (event.which == 13 || event.keyCode == 13){
			this.onCommentBoxEnter()
		}
	}
	onCommentBoxEnter(){
		this.postComment()
	}
	onBackSpace(event){
		const cursorPos = event.target.selectionStart
		var newcomment = this.state.new_comment;
		let wordsInComment = newcomment.split(' ');
		let Elementindex = 0
		let wordStartsAt=0 //so that cursor position can be compared
		// console.log(wordsInComment)
		for (let index = 0; index < wordsInComment.length; index++) {
			let element = wordsInComment[index]
			if((wordStartsAt + element.length) < cursorPos){
				Elementindex++
				// console.log(element + ":" + element.length,"index :"+index,"wordStartAt :"+wordStartsAt);
			}
			wordStartsAt += element.length + 1
		}
		// console.log(cursorPos,wordsInComment,Elementindex);
		if (wordsInComment[Elementindex].indexOf("@")!=-1) {
			wordsInComment.splice(Elementindex,1)
			newcomment = wordsInComment.join(' ');
			this.setState({new_comment:newcomment})
		}
	}
	checkShortHandsInComment(comment,i){

		let shortHandsArr = []
		// let breadcrumbs = this.state.breadcrumbs
		if (comment.includes('@')) {
			let wordsInComment = comment.split(' ');
			wordsInComment.forEach(word => {
				if( word.indexOf("@")!=-1 ){
					let arr = word.split('@')
					let value = ''
					if(arr[0]=='assign'){
						let trail = arr[1].split(':');
						value = trail[1]
					}else{
						value = arr[1]
					}
					let string = arr[0]+"ed to "+ value
					shortHandsArr.push(

						<span class="badge">
							{string}
						</span>
					)
				}
			});
				return shortHandsArr;
		}
	}
	extractBreadcrumbs(comments){
		// let shortHandsArr = []
		// console.log("extracting")
		let breadcrumbs = []
		comments.map((comment,i)=>{
			if (comment.comment.includes('@')) {
				let wordsInComment = comment.comment.split(' ');
				wordsInComment.forEach(word => {
					if( word.indexOf("@")!=-1 ){
						// console.log(word,"::");

						let arr = word.split('@')
						if (arr[0].includes('update')) {
							// console.log("pushing :: ",word)
							breadcrumbs.push({index:i,status:arr[1],id:comment.id})
						}
					}
				});
			}
		})
		this.setState({breadcrumbs,extracted_comments:false,breadcrumb_index:false})

	}
	breadCrumbClickHandler(i){
		// console.log(i,this.state.breadcrumbs);

		let comments = [...this.state.comments]
		let sequence_start_comment_index = this.state.breadcrumbs[i].index
		let sequence_end_comment_index =
			i+1==this.state.breadcrumbs.length
			?
			comments.length -1
			:
			this.state.breadcrumbs[i+1].index
		let extracted_comments = comments.slice(sequence_start_comment_index,sequence_end_comment_index+1)
		this.setState({extracted_comments,breadcrumb_index:i})
	}
	autoComplete(e,data){
		this.setState({autoCompleteText:data.value})
	}
	onDataTimeSelect(event,data){
		let timeString = data.value.replace(/\//g,'-')
		this.setState({autoCompleteText:timeString},()=>{
			this.editInput()
		})
	}
	commentBoxClick(){
		if(this.state.delimeter != ''){
			this.editInput()
		}
	}
	toggleCommentVisibility(e,data,status){
		const edit =1
		const checked = status == 1 ? 0:1
		const new_data_row = {
			slip_no:this.state.show_comment_of,
			id:data.value,
			show_customer: checked
		}
		let index = null;
		let comments =  this.state.comments;
		comments.forEach((comment,i)=>{
			if(comment.id == data.value){
				index= i
			}
		})
		// console.log(index)
		if (index !=null) {
			// console.log(comments[index].show_customer);
			comments[index].show_customer= comments[index].show_customer==1?0:1;
			// console.log(comments[index].show_customer);
		}
		this.setState({showLoaderOn:data.value},()=>{
			Client.createOrderSlipComments(new_data_row, edit).then(()=>{
				this.setState({comments,showLoaderOn:''},()=>{
					// this.extractBreadcrumbs(this.state.comments)
				})
				// console.log('done',index)
			}).catch((err)=>{
				this.setState({showLoaderOn:''})
				// console.log(err)
				}
			)
		})
	}
	toggleNewCommentVisibility(e, data){
		this.setState({new_comment_visibility:!this.state.new_comment_visibility},()=>{
			// console.log(this.state.new_comment_visibility);
		})

	}
	toggleView(e, data){
		if (this.state.showView=='table') {
			this.setState({showView:'cards'})
		}
		else{
			this.setState({showView:'table'})
		}
	}
	changeStateVariable(e, data,fetch=true){
		var state = {...this.state};
		state[data.name] = data.value;
		if(data.name == "data_rows_search_field" || data.name == "data_rows_search"){
			var filter = this.state.data_rows_filter
			delete filter.cust_branch
			state.data_rows_filter = filter
			state.showBranch = 0
		}
		//SELECTIVE OPERATIONS
		if(this.state.data_rows_search_field=="customer" && data.name == "data_rows_search"){
			state.showBranch = 1
			state.loading_branch = 1
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch_sort:options_branch,
						loading_branch:0
					})
				} else {
					this.setState({
						showBranch:0,
						options_branch_sort:''
					})
				}
			}).catch(err=>{
				this.setState({
					showBranch:0,
					options_branch_sort:''
				})
			})
		}
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
			state['data_rows_search'] = ''
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset" && data.name != "new_comment")
			state['data_rows_offset'] = 0;
		if(data.name == "cust_branch"){
			var filter = {...this.state.data_rows_filter}
			if(data.value != "" && data.value != undefined){
				filter['cust_branch'] = {filter_type: 'value_equal', value: data.value}
			}
			else
				filter['cust_branch'] = undefined
			state.data_rows_filter = filter
		}
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';


		//SET VALUE AND MOVE AHEAD
		this.setState(state,()=>{
			if(data.name == "new_comment"){
				this.checkForDelimeters()
			}
			if (fetch) {
				this.getDataRows(state);
			}
		})
	}
	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		if(data.name == 'customer'){
			delete new_data_row.cust_branch
			new_data_row.showBranch = 1
			new_data_row.loading_branch = 1
			Client.getBranchesOfCustomer(data.value).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					new_data_row.loading_branch = 0
					this.setState({
						options_branch:options_branch,
						new_data_row,
					})
				} else {
					new_data_row.showBranch = 0
					new_data_row.loading_branch = 0
					this.setState({new_data_row})
				}
			})
			Client.getCustomerUser('user',{'corp_ledger':[data.value]}).then(res=>{
				this.setState({loading_users:false,options_company_users:res.data_rows.map((data_row) => ({text: data_row.name, value: data_row.account_id}))})
			}).catch(err=>{
				this.setState({loading_users:false})
			})
		}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}
	convertSelectToText(key, value){
		if(key == 'ledger'){
			if (this.state.voucher_name == 'purchase') {
				key = 'vendor'
			} else {
				key = 'customer'
			}
		}
		// console.log(key,value);
		var datas = this.state['options_' + key];
		// console.log(datas,key,value);
		try {
			value = JSON.parse(value)
		} catch (error) {
			// return 0
		}
		if (datas) {
			for(var i = 0; i < datas.length; i++){
				var data = datas[i];
				if(data.value == value){
					return data.text;
				}
			}
		}
		return value + ' : N/A';
	}
	viewInnerDetailButton(value){
		var state = {...this.state}
		state['viewInnerDetail'] = value;
		this.setState(state);
	}
	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true,
			selected_data_row_index: index,
			selected_data_row: this.state.data_rows[index],
			loading_branch:1
		},()=>{
			Client.getBranchesOfCustomer(this.state.selected_data_row.customer).then((res)=>{
				let options_branch = []
				if (res.data_rows_size > 0) {
					res.data_rows.forEach(element => {
						let temp = {
							value : element.id,
							text : element.branch_name
						}
						options_branch.push(temp)
					})
					this.setState({
						options_branch,
						options_branch_sort:options_branch,
						loading_branch:0
					})
				} else {
					this.setState({
						showBranch:0,
						options_branch_sort:'',
						loading_branch:0
					})
				}
			})
		})
	}
	toggleCreateDataModal(){
		this.setState({
			showCreateDataModal:false,
			new_data_row: {slip_date: this.state.today, 'current_status': 'initiated', list: [{}]},
			editMode: false,
			creatingNewDataModal: false
		})
	}
	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createOrderSlip(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				last_created:res.slip_no,
				new_data_row: {slip_date: this.state.today, 'current_status': 'initiated', list: [{}]},
				data_rows_sort:"slip_no",
				data_rows_sort_order:"desc"
			},()=>{
				this.getDataRows(this.state);
			})
		})
	}

	getDataRows(state){
		// console.log(state.data_rows_filter);
		this.setState({
			showFilesModal: false
		})
		Client.getOrderSlips("get",	state.data_rows_offset,	state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order,
			state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search, state.data_rows_filter, {}, state.data_rows_remove_filter)
			.then(res=>{
				let analytics = res.analytics.filter(elem=>{
					return elem.status != null && elem.status.trim() != ''
				})

				res.data_rows.map((data_row)=>{
					var listDisplay = []
					var list = JSON.parse(data_row.list)
					data_row.list = list;
					if(data_row.list != undefined && data_row.list != null)
	                {
						list.forEach(function(listElement, index)
						{
							if(listElement.name != undefined && listElement.name.trim() != '')
							listDisplay.push((listElement.name + " (" + listElement.rate + ") - " + listElement.qty))
						})
						data_row.listDisplay = listDisplay;
					}
				})

				this.setState({
					data_rows: res.data_rows,
					analytics:analytics
					.sort((a,b)=>{
						return a.status > b.status?1:-1
					})
					,
					data_rows_size: res.data_rows_size,
					loadingDataRows: false
			},()=>{
				this.getLastCreated()
			})
		})

		var filter = {
			...state.data_rows_filter,
			current_employee:{filter_type:"value_equal",value:this.state.emp_id},
		}
		var remove_filter = {
			current_status:{filter_type:"value_in",value:["cancelled","completed"]}
		}
		Client.getOrderSlips("get123", 0, undefined, state.data_rows_sort, state.data_rows_sort_order,
		state.data_rows_search_field, state.data_rows_search_type, state.data_rows_search,
		filter, {}, remove_filter)
		.then(res=>{
			var my_work_delayed = 0;
			var my_work_total = 0;
			res.data_rows.map((data_row)=>{
				if(data_row['next_date'] <= this.state.today){
					my_work_delayed++;
				}
				my_work_total++;
			})
			this.setState({
				my_work_delayed: my_work_delayed,
				my_work_total: my_work_total
			})
		})
	}
	getLastCreated(){
		let last_order = this.state.data_rows.filter(order=>{
			return order.slip_no == this.state.last_created
		})
		if (Array.isArray(last_order)) {
			this.setState({last_order:last_order[0]})
		}
	}
	//CUSTOM FUNCTIONS
	createDepartmentSequence(){
		var departments = [...this.state.options_current_department]
		var status = this.state.new_data_row.current_status;
		var department_seq = []
		for(var i = 0; i < departments.length; i++){
			var department = departments[i];
			var department_split = department.statuses.split(",");
			if(department_split.includes(status)){
				department_seq.push(department)
			}
		}
		return department_seq;
	}
	shortHandsButton(e,data){
		var value = this.state.new_comment
		var new_comment =  value + ' ' + data
		var new_data = {name:'new_comment',value:new_comment}
		this.changeStateVariable(e,new_data)
	}
	createEmployeeSequence(){
		var employees = [...this.state.options_current_employee]
		var department = this.state.new_data_row.current_department
		var employee_seq = []
		for(var i = 0; i < employees.length; i++){
			var employee = employees[i];
			if(employee.department == department  || employee.department == 1){
				employee_seq.push(employee)
			}
		}
		return employee_seq;
	}
	updateSelectedDataRow(updated_data){
		var selected_data_row = {...this.state.selected_data_row}
		var selected_data_row_index = this.state.selected_data_row_index
		var data_rows = [...this.state.data_rows]
		selected_data_row.current_status = updated_data.status
		selected_data_row.priority = updated_data.priority || selected_data_row.priority
		selected_data_row.next_date = updated_data.next_date
		selected_data_row.current_department = updated_data.department
		selected_data_row.current_employee = updated_data.employee
		data_rows[selected_data_row_index] = selected_data_row
		this.setState({
			selected_data_row: selected_data_row,
			data_rows: data_rows
		})
	}
	setActiveFilter(){
		// var state = {...this.state}
		// var filter = JSON.parse(this.state.data_rows_filter)
		// filter['current_status'] = this.state.options_current_status.map(status=>{
		// 	if (status.active == 1) {
		// 		return status.value
		// 	}
		// })
		// state.data_rows_filter = JSON.stringify(filter)
		// state.data_mode = 'active'
		// this.setState(state)
		// this.getDataRows(state);
	}
	setArchiveFilter(){
		// var state = {...this.state}
		// var filter = JSON.parse(this.state.data_rows_filter)
		// filter['current_status'] = this.state.options_current_status.map(status=>{
		// 	if (status.active == 0) {
		// 		return status.value
		// 	}
		// })
		// state.data_rows_filter = JSON.stringify(filter)
		// state.data_mode = 'archive'
		// this.setState(state)
		// this.getDataRows(state);
	}
	changeSortKey(e, data){
		this.changeStateVariable(e, {"name":"data_rows_sort", "value":data.value})
	}
	toggleOrdering(e){
		this.changeStateVariable(e, {"name":"data_rows_sort", "value":this.state.data_rows_sort})
	}
	availableStatusArr(currentStatus){
		let all_statuses = this.state.options_current_status
		var status_options = [];
		all_statuses.forEach((item,i)=>{
			if (item.after.split(',').includes(currentStatus)) {
				status_options.push(item)
			}
		})
		return status_options;
	}
	// graph
	withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.slip_date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        // console.log("with month array",withMonthArray);
        return withMonthArray
    }
	getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
	occuranceOfLabelInAxis(label_value,lable_name,axis_value,axis_name,data_rows){
		var matched = data_rows.filter((elem)=>{
			return elem[axis_name] == axis_value && elem[lable_name] == label_value
		})
		return matched.length
	}
	prepareGraphDataMultiline(label="staff",axis="date"){
		this.setState({[axis + '_update_graph']:false})
		var data_rows = []
		let options_staff = this.state.options_current_employee.filter(elem=>{
			return elem.value != null
		})
		var staffs =
			this.state.graph_employee.length > 0
			?
			this.state.graph_employee
			:
			options_staff.map(elem => {
				return elem.value
			})
		let filter={staff:[...staffs]}
		// let filter={}
		let range = {slip_date :[this.state.start_date, this.state.end_date]}
		Client.getOrderSlipsReport(axis,0,null, "slip_date", "asc",null, null, null, filter,range).then((res)=>{
			data_rows = res.data_rows
			var master_data = {}
			var distinct_axises = this.getDistictIn(axis,data_rows)
			for(var key in distinct_axises){
				var distinct_axis_data = distinct_axises[key]
				master_data[key] = this.getDistictIn(label,distinct_axis_data)
			}
			let data_for_graph = []
			for(var key in master_data){
				var value = master_data[key]
				var mapping = {}
				for(var key1 in value){
					let name = this.staffName(key1)
					mapping[name] = value[key1].length
				}
				data_for_graph.push({
					name:key, ...mapping
				})
			}
			// console.log("update master",data_for_graph,axis)
			this.setState({[axis + '_update_graph']:data_for_graph,loading_graph:false})
		})
	}
	prepareGraphDataSingleFactor(axis="slip_date"){
		this.setState({[axis + '_single_factor_graph']:false})
		var data_rows = []
		let filter={}
		let range = {slip_date :[this.state.start_date, this.state.end_date]}
		Client.getOrderSlips(axis,0, null,"slip_date", "asc",null, null, null, filter,range).then((res)=>{
			data_rows = axis == "month"?this.withMonth(res.data_rows):res.data_rows//chopped in months
			var distinct_axises = this.getDistictIn(axis,data_rows)
			let data_for_graph = []
			for(var key in distinct_axises){
				var value = distinct_axises[key]
				var mapping = {}
					let name="count"
					mapping[name] = value.length
				data_for_graph.push({
					name:key, ...mapping
				})
			}
			// console.log("master",data_for_graph)
			this.setState({[axis + '_single_factor_graph']:data_for_graph,loading_graph:false})
		})
	}
	range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
	handleGraphDataChange(e,data){
		this.setState({
			[data.name]:data.value,
			loading_graph:true
		},()=>{
			this.prepareGraphDataSingleFactor("slip_date")
			this.prepareGraphDataSingleFactor("month")
			this.prepareGraphDataMultiline("current_status","slip_date")
		})
	}
	staffName = (staff)=>{
		// console.log(staff)

		var staffarr = this.state.options_current_employee.filter((elem)=>{
			return elem.value == staff
		})
		return staffarr.length>0?staffarr[0].text:staff
	}
	showReportModal(){
		this.setState({
			modalReport:true,
			loading_graph:true
		},()=>{
			this.prepareGraphDataSingleFactor("slip_date")
			this.prepareGraphDataSingleFactor("month")
			this.prepareGraphDataMultiline("current_status","slip_date")
		})
	}
	showVoucherDetails=(data_row, module_name)=>{
		// console.log("data_row:",data_row,module_name)
		// data_row['cust_branch'] = data_row['customer_details']?data_row['customer_details']['branchId']:data_row['branch']
		// delete data_row['branch']
		this.setState({
			show_voucher:module_name,
			data_row_voucher:data_row,
			loading_voucher_for:false,
			[module_name+'loading']:false,
		},()=>{console.log("added")})
	}
	voucherList(voucher_list,voucher_name,redirect_to,order_id){
		this.setState({
			voucher_list:voucher_list,
			voucher_name:voucher_name,
			show_voucher:'list',
			voucher_redirect_to:redirect_to,
			loading_voucher_for:false,
			voucher_order_id:order_id,
			[redirect_to+'loading']:false

		})
	}
	onVoucherListSelect(id){
		let data_row = this.state.voucher_list.filter(elem=>{return elem.id == id})
		this.showVoucherDetails(data_row[0],this.state.voucher_name)
		// this.setState({voucher_list:false,voucher_name:false})
	}
	voucherAction(order_id,redirect_to,existing_vouchers){
		if (existing_vouchers.includes(redirect_to.key)) {
			this.setState({[redirect_to.key+'loading']:true,loading_voucher_for:order_id})
			switch (redirect_to.key) {
				case 'sale':
					Client.getAccountingSales(null,null,null,null,null,null,null,{order_id:[order_id]},)
					.then(res=>{
						this.showVoucherDetails(res.data_rows[0],'sale')
					})
					break;
				case 'quotation':
					Client.getAccountingInvoiceQuotation(null,null,null,null,null,null,null,{order_id:[order_id]},null,'sale')
					.then(res=>{
						this.showVoucherDetails(res.data_rows[0],'quotation')
					})
					break;
				case 'purchase':
					Client.getAccountingPurchaseFilters(null,null,null,null,null,null,null,{order_id:[order_id]},null,'sale')
					.then(res=>{
						// console.log(res.data_rows)
						this.voucherList(res.data_rows,'purchase',redirect_to,order_id)
					})
					break;
				case 'delivery_note':
					Client.getAccountingDeleveryChallan(null,null,null,null,null,null,null,{order_id:[order_id]},null,'sale')
					.then(res=>{
						// console.log(res.data_rows)
						this.voucherList(res.data_rows,'delivery_note',redirect_to,order_id)
					})
					break;
				default:
					break;
			}
		} else {
			console.log(order_id,redirect_to)
			localStorage.setItem(redirect_to.value,order_id)
			this.props.history.push(redirect_to.value)
		}
	};
	showTodayWork(type){
		if (this.state.data_rows_search_field == "next_date") {
			// this.changeStateVariable({},{
			// 	name:'data_rows_search_field',
			// 	value:'slip_no'
			// },false)
			setTimeout(()=>{
				this.setFilter({},{name:'next_date',value:this.state.end_date},true,type,"current_status",{current_status:{filter_type:"value_in",value:["cancelled","completed"]}})
			},300)
		}else{
			this.setFilter({},{name:'next_date',value:this.state.end_date},true,type,"current_status",{current_status:{filter_type:"value_in",value:["cancelled","completed"]}})
		}
	}

	showMyWork(){
		// this.changeStateVariable({},{
		// 	name:'data_rows_search_field',
		// 	value:'slip_no'
		// },false)
		setTimeout(()=>{
			this.setFilter({},
				{name:'current_employee',
				value:this.state.emp_id},
				false,undefined,"current_status",
				{current_status:{filter_type:"value_in",value:["cancelled","completed"]}})
		},300)
		this.setState({
			data_rows_sort: 'next_date',
			data_rows_sort_order: 'asc'
		})
	}

	markIssueModalLaunch(slip_no){
		this.setState({marking_issue:slip_no})
	}
	markIssue(){
		let slip_no = this.state.marking_issue
		let comment = 'update@issue;; '+this.state.marking_issue_comment
		let show_customer = this.state.marking_issue_show_customer
		let new_data_row = {slip_no,comment,show_customer}
		this.setState({marking_issue_loader:true})
		Client.createOrderSlipComments(new_data_row, 0).then(()=>{
			let data_rows = this.state.data_rows
			let observed_order = data_rows.find(elem=>{return elem.slip_no == slip_no})
			observed_order.current_status = "issue"
			this.setState({marking_issue_loader:false,data_rows,marking_issue:false,marking_issue_comment:'',marking_issue_show_customer:1})

		})
	}

	addToList(addOrRemove, index){
		var new_data_row = {...this.state.new_data_row}
		var list = [...new_data_row.list]
		if(addOrRemove){
			list.push({name:''});
		}
		else{
			list.splice(index, 1);
		}
		var listDisplay = []
		list.forEach((item, i) => {
			if(item.name != undefined && item.name.trim() != '')
				listDisplay.push((item.name + " (" + item.rate + ") - " + item.qty))
		});
		new_data_row.list = list
		new_data_row.listDisplay = listDisplay
		this.setState({
			new_data_row: new_data_row
		})
	}
	modifyListElement(e, data, index){
		var new_data_row = {...this.state.new_data_row}
		var list = [...new_data_row.list]
		var listElement = {...list[index]}
		listElement[data.name] = data.value.substring(0,30)
		listElement["qty"] = listElement["qty"] == undefined ? 0 : listElement["qty"]
		listElement["rate"] = listElement["rate"] == undefined ? 0 : listElement["rate"]
		list[index] = listElement
		var listDisplay = []
		list.forEach((item, i) => {
			if(item.name != undefined && item.name.trim() != '')
				listDisplay.push((item.name + " (" + item.rate + ") - " + item.qty))
		});
		new_data_row.list = list
		new_data_row.listDisplay = listDisplay
		this.setState({
			new_data_row: new_data_row
		})
	}

	updateList(){
		if(this.state.editMode){
			this.setState({
				updatingOrder: true
			})
			this.setState({
				creatingNewDataModal: true
			})
			var state = this.state
			Client.createOrderSlip(state.new_data_row, true).then(res=>{
				this.setState({
					showCreateDataModal: false,
					creatingNewDataModal: false,
					editMode: false,
					last_created:res.slip_no,
					new_data_row: {slip_date: this.state.today, 'current_status': 'initiated', list: [{}]},
					data_rows_sort:"slip_no",
					data_rows_sort_order:"desc",
                    updatingOrder: false,
                    showListModal: false
				},()=>{
					this.getDataRows(this.state);
				})
			})
		}
		else{
			this.setState({
				showListModal: false
			})
		}
	}

	initiateOrderList(data_row){
		if(data_row.list == undefined || data_row.list == null){
			data_row.list = [{}]
		}
		this.setState({showListModal:true, new_data_row: data_row, data_row_list_check: data_row.list, editMode:true})
	}

	showListModal(e, data){
		e.preventDefault();
		this.setState({
			showListModal:true,
			contracts: [],
			selectedContractProducts: []
		})
		// this.changeStateVariableNewDataRow(e, data)
	}

	loadContracts(){
		this.setState({loadingContracts:true, selectedContractProducts: {}})
		let filter ={}
		// if (this.state.cust_branch != 'All' && this.state.cust_branch != null) {
		//   filter= {cust_branch:[this.state.cust_branch]}
		// } else {
		//   let filter_branches = this.props.app_data.user_details.branches.map(branch => {return branch.id})
		//   filter = {cust_branch:[...filter_branches]}
		// }
		filter = {customer:[this.state.new_data_row.customer]}
		Client.getAccountingInvoiceContract(0, undefined, "date",
			"desc", undefined, undefined, undefined, filter).then(res=>{
				var totalSale = 0;
				var totalTax = 0;
				res.data_rows.forEach(function(sale){
					totalSale = totalSale + parseFloat(sale.payable_amount);
					totalTax = totalTax + parseFloat(sale.tax_amount);
				})
				this.setState({
					contracts: res.data_rows,
					loadingContracts:false
				})
		})
	}

	populateContract(e, data){
		var contract = {...this.state.contracts[data.value]}
		this.setState({
			selectedContractProducts:contract.products
		})
	}

	addProductToList(addOrRemove, index){
		var new_data_row = {...this.state.new_data_row}
		var list = [...new_data_row.list]
		var listDisplay = []
		if(addOrRemove){
			var product = this.state.selectedContractProducts[index];
			if(list.length > 0 && !list[0].name)
			{
				list.splice(0, 1);
			}
			var found = false;
			list.forEach((item, i) => {
				if(item.product_id == product.product_id &&
				// (product.name + (product.description != "" ? " (" + product.description + ")" : "")) == item.name){
				product.rate == item.rate){
					found = true;
					return;
				}
				if(item.name != undefined && item.name.trim() != '')
					listDisplay.push((item.name + " (" + item.rate + ") - " + item.qty))
			});
			// res.data_rows.map((data_row)=>{
			// 	var list = JSON.parse(data_row.list)
			// 	data_row.list = list;
			// 	if(data_row.list != undefined && data_row.list != null)
			// 	{
			// 		list.forEach(function(listElement, index)
			// 		{
			// 			if(listElement.name != undefined && listElement.name.trim() != '')
			// 			listDisplay.push((listElement.name + " (" + listElement.rate + ") - " + listElement.qty))
			// 		})
			// 		data_row.listDisplay = listDisplay;
			// 	}
			// })

			if(found){
				alert("Product Already Added");
			}
			else{
				list.push({
					product_id: product.product_id,
					name: product.name + (product.description != "" ? " (" + product.description + ")" : ""),
					qty:1,
					rate: product.rate
				});
			}
		}
		else{
			list.splice(index, 1);
		}
		new_data_row.list = list
		new_data_row.listDisplay = listDisplay
		alert(listDisplay)
		this.setState({
			new_data_row: new_data_row
		})
	}

	render(){
		var innerDetails = '';
		if(this.state.viewInnerDetail == "updates")
			innerDetails = <OrderSlipUpdate updateSelectedDataRow={(data) => this.updateSelectedDataRow(data)} filter={{slip_no: this.state.selected_data_row.slip_no}}
				parent_data_row={this.state.selected_data_row} style={{display: (this.state.viewInnerDetail == "purchases" ? 'block' : 'none')}}/>
		else if(this.state.viewInnerDetail == "purchases")
			innerDetails = <OrderSlipPurchase updateSelectedDataRow={(data) => this.updateSelectedDataRow(data)} filter={{slip_no: this.state.selected_data_row.slip_no}}
				parent_data_row={this.state.selected_data_row} style={{display: (this.state.viewInnerDetail == "purchases" ? 'block' : 'none')}}/>
		const options_current_employee = [
				{ key: '.badal', text: '.badal', value: '.1.badal' },
				{ key: '.john', text: '.john', value: '.2.john' },
				{ key: '.hari', text: '.hari', value: '.3.hari' },
			]
		const options_status = [
				{ key: '.invoice', text: '.invoice', value: '.1.invoice' },
				{ key: '.quotation', text: '.quotation', value: '.2.quotation' },
				{ key: '.done', text: '.done', value: '.3.done' },
			]
		var count_bar_index = 0
		return(
			<div style={{fontSize:'12px'}} className="order-page">
				<div style={{display:'flex', alignItems:'center', paddingBottom:'3px'}}>
					<Button size="small" style={{height:'100%'}} color={this.state.data_rows_filter.hasOwnProperty('current_employee') ? "green" : "blue"}
						onClick={()=>{this.showMyWork()}}>
						MY WORK
					</Button>
					<Progress size="medium" style={{width:'30%', background:'#CCCCCC', margin:'0'}} value={this.state.my_work_delayed || 0}
						total={this.state.my_work_total || 0} inverted progress="ratio" error />
					{
						this.state.analytics.map((analytic)=>{
						if ((analytic.status != null && analytic.status == 'issue') ) {
							return(
								<Button size="small" color={(this.state.data_rows_filter.hasOwnProperty('current_status')
									&&
									this.state.data_rows_filter.current_status.value.includes(analytic.status))
									?
									"green" : "blue"}
									onClick={()=>{
										setTimeout(()=>{
											this.setFilter({},{name:'current_status',value:analytic.status},false)
										},300)
									}}>
									<span >
										{analytic.status.toUpperCase()}
									</span><br/>
									<span>
										{
											analytic.status != "cancelled" && analytic.status != "completed"
											?
											<>
												<span className="delayed">{analytic.delayed}</span>/
											</>
											:
											null
										}
										<span className="total">{analytic.total}</span>
									</span>
								</Button>
							)}
						})
					}
				</div>
				<div style={{display:'flex', width:'100%', overflowX:'scroll', height:'80px'}}>
					<Button size="small" style={{}} color={this.state.data_rows_filter.hasOwnProperty('next_date')
							&&
							this.state.data_rows_filter.next_date.filter_type == "value_less"
							?
							"green" : "blue"
						} onClick={()=>{this.showTodayWork('less')}}>
						<span>
							DELAYED
						</span>
					</Button>
					<Button size="small" style={{}} color={this.state.data_rows_filter.hasOwnProperty('next_date')
						&&
						this.state.data_rows_filter.next_date.filter_type == "value_less"
						?
						"green" : "blue"
						} onClick={()=>{this.showTodayWork('like')}}>
						<span>
							TODAY
						</span>
					</Button>
					{
						this.state.analytics.map((analytic)=>{
						if ((analytic.status != null && analytic.status != '' && analytic.status != undefined && analytic.status != "issue") ) {
							return(
								<Button size="small" color={this.state.data_rows_filter.hasOwnProperty('current_status')
									&&
									this.state.data_rows_filter.current_status.value.includes(analytic.status)
									?
									"green" : "blue"
									}
									onClick={()=>{
										setTimeout(()=>{
											this.setFilter({},{name:'current_status',value:analytic.status},false)
										},300)
									}}>
									<span className="status-name">
										{analytic.status.toUpperCase()}
									</span><br/>
									<span className="count-info">
										{
											analytic.status != "cancelled" && analytic.status != "completed"
											?
											<>
												<span className="delayed">{analytic.delayed}</span>/
											</>
											:
											null
										}
										<span className="total">{analytic.total}</span>
									</span>
								</Button>
							)}
						})
					}
					{/* <Button
						color="black"
						size="small"
						onClick={()=>{
							this.setState({
								count_bar_limit :this.state.count_bar_limit == 6?this.state.analytics.length:6
							})
						}}
						icon={this.state.count_bar_limit == 6?"add":"remove"}>
							{this.state.count_bar_limit == 6?"More":"Less"}
					</Button> */}
				</div>
				<div className="top">
					<div style={{display:'flex',flexWrap:'wrap'}}>
						<Button size='small' onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
						{/* {
						<Button.Group size='small'>
							<Button positive={this.state.data_mode=='active'} onClick={() => this.setActiveFilter()}>ACTIVE</Button>
							<Button.Or />
							<Button positive={this.state.data_mode=='archive'} onClick={() => this.setArchiveFilter()}>ARCHIVE</Button>
						</Button.Group>
						} */}

						<div style={{display:'inline-flex'}} >
							<Button icon="repeat" color="red" size="small" onClick={() => this.componentDidMount()}/>
						</div>
						{
							this.state.showView=='cards'?
							<div style={{display:'inline-block'}}>
								<Button compact basic color="black" icon labelPosition='right'>
									<Dropdown onChange={this.changeSortKey.bind(this)} name='right arrow' value={this.state.data_rows_sort} options={this.state.tableFields}/>
									<Icon basic onClick={this.toggleOrdering.bind(this)} name={this.state.data_rows_sort_order==="asc"?"up arrow":' down arrow'}/>
								</Button>
							</div>:null
						}
						<div style={{display:'inline-flex'}}>
							<Button size={this.state.defaultSize} onClick={() => this.showReportModal()} color="green">REPORT</Button>
						</div>
						<div style={{display:'inline-flex',textAlign:'center',padding:'0px',marginTop:'5px',marginLeft:'5px'}}>
							<Responsive minWidth={992} >
								<Checkbox onChange={(e, data) => this.toggleView()} toggle />
							</Responsive>
						</div>
						<div style={{display:'inline-flex'}} >
							<Dropdown selection search
							options={this.state.tableFields.filter(elem=>{
								return !this.state.data_rows_filter.hasOwnProperty(elem.value)

							})} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
							{
								this.state.data_rows_search_type == 'select'
								?
								<Dropdown clearable selection search value={this.state.data_rows_search} name="data_rows_search" options={this.state['options_' + this.state.data_rows_search_field]} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
								:

								<Input
									type={this.state.data_rows_search_type == 'field' ? 'text' : this.state.data_rows_search_type}
									name="data_rows_search"
									value={this.state.data_rows_search}
									placeholder="Search"
									icon={
										(this.state.data_rows_search_type == 'field') && (this.state.data_rows_search != '')
										?
										<Icon name='delete' link
											onClick={()=>{this.changeStateVariable({}, {
												name:'data_rows_search',
												value:''
											})}}
										/>
										:
										false
									}
									onChange={(e, data) => this.changeStateVariable(e, data)}/>
							}
						</div>
						<div style={{display:'inline-block'}}>
						{
							this.state.data_rows_search_field == "customer" && this.state.showBranch
							?
							<Dropdown loading={this.state.loading_branch} clearable selection search name="cust_branch" options={this.state.options_branch_sort} placeholder="Select Branch" onChange={(e, data) => this.changeStateVariable(e, data)}/>
							:
							null
						}
						</div>
						<div style={{display:'inline-flex'}}>
							<Button size='small' color='teal' icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
							<Dropdown
								name='data_rows_limit'
								inline
								// className="limit-drop"
								options={this.state.options_data_rows_limit}
								value={this.state.data_rows_limit}
								onChange={(e, data) => this.changeStateVariable(e, data)}
								/>
								{ this.state.data_rows_offset + ' to ' +
								(
									(this.state.data_rows_offset + this.state.data_rows_limit) <= this.state.data_rows_size ?
									(this.state.data_rows_offset + this.state.data_rows_limit) :
									this.state.data_rows_size
								) +
								' of ' + this.state.data_rows_size + ' '}
							<Button size='small' color='teal' icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
						</div>
					</div>
					<hr></hr>
				</div>
				{
					this.state.loadingDataRows
					?
					<Segment>
					<Dimmer active>
					<Loader />
					</Dimmer>

					<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
					</Segment>
					:
					<>
					{	this.state.showView=='table'?
						<div className="order-cards">
							<Table  color="blue" key="blue" collapsing>
								<Table.Header>
									<Table.Row>
									{
										this.state.tableFields.map((field, i) => (
											<Table.HeaderCell key={i} onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
												{field.text}
												{
													this.state.data_rows_sort_order == "asc" ?
														<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
													:
														<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
												}
											</Table.HeaderCell>
										))
									}
									</Table.Row>
								</Table.Header>

								<Table.Body>
								{
									this.state.data_rows.map((data_row, index) => (
										<Table.Row key={index} className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}
											style={{background: (data_row['next_date'] <= this.state.today ? '#FF0000' : '#FFFFFF'), color: (data_row['next_date'] <= this.state.today ? '#FFFFFF' : '#000000')}}>
										{
											this.state.tableFields.map((field,index) => (
												<Table.Cell key={index}>
												{
													field.value == "customer"
													?
														data_row.customer_name ? data_row.customer_name : data_row.vendor[0]
													:
														(field.type=='select'
														?
															this.convertSelectToText(field.value, data_row[field.value])
															// 'test'
														:
														data_row[field.value])
												}
												</Table.Cell>
											))
										}
										</Table.Row>
									))
								}
								</Table.Body>
							</Table>
						</div>
						:
						<div className="order-cards edge-flat">
								{
									this.state.data_rows.map((data_row, index)=>{
										let status = this.availableStatusArr(data_row.current_status)
										let contact_types = data_row.contacts.map((element)=>{
											return element.type;
										})
										let distinct_types = [... new Set(contact_types)]
									return(
										<Card key={index} style={{background: (data_row['next_date'] <= this.state.today ? 'rgb(0, 181, 173)' : '#FFFFFF')}} fluid wide key={data_row.slip_no}>
											<Card.Content>
												<Card.Description style={{fontWeight:'bold',fontSize:'1.1rem',color:data_row['next_date'] <= this.state.today ?'white':'black'}}>
													<span style={{cursor:'pointer',color:data_row['next_date'] <= this.state.today ?'white':'blue'}} onClick={() => this.showDataRowDetails(index)}>
														{
															data_row['next_date'] <= this.state.today
															?
															<Icon name="circle" color="red"/>
															:
															null
														}
														#{data_row.slip_no}:
													</span>
													{data_row.customer_name}
													<span className="redirect-btns">
															{
																data_row.current_status != "issue"
																?
																<Button
																	negative
																	size="tiny"
																	onClick={()=>this.markIssueModalLaunch(data_row.slip_no)}
																	>
																	Mark Issue
																</Button>
																:
																null
															}
															{
																this.state.redirect_to.map(elem=>(
																	<Button
																		color={Object.keys(data_row.vouchers).includes(elem.key)?'green':'whitesmoke'}
																		size="tiny"
																		loading={this.state[elem.key+'loading'] && this.state.loading_voucher_for == data_row.slip_no}
																		onClick={()=>{this.voucherAction(data_row.slip_no,elem,data_row.existing_vouchers)}}>
																			{elem.text}
																			{
																				Object.keys(data_row.vouchers).includes(elem.key)
																				?
																				" ("+data_row.vouchers[elem.key]+")"
																				:
																				null
																			}
																	</Button>
																))
															}
													</span>
												</Card.Description>

												<Card.Description style={{fontSize:'0.9rem',color:data_row['next_date'] <= this.state.today ?'white':'black'}}>
													<span className='date'>{data_row.slip_date}</span>
													<b style={{float:'right',color:data_row['next_date'] <= this.state.today ?'white':'black',textTransform:'uppercase'}}>{data_row.current_status}</b>
												</Card.Description>
												<Card.Description style={{fontWeight:'bold',fontSize:'1rem',color:data_row['next_date'] <= this.state.today ?'white':'blue'}}>
													Requirements : {data_row.requirement}
												</Card.Description>
												<Card.Description style={{fontWeight:'bold',fontSize:'1rem',color:data_row['next_date'] <= this.state.today ?'white':'blue'}}>
						                            {
														(data_row.listDisplay != undefined && data_row.listDisplay != '')
														?
														<Message
											    		// onDismiss={() => {this.setState({shrinkMessage:{}})}}
														onClick={() => {this.setState({showListModal:true, new_data_row: data_row, data_row_list_check: data_row.list, editMode: true})}}
						                                style={{cursor:'pointer', display: (data_row.listDisplay != undefined && data_row.listDisplay != '') ? 'block' : 'none'}} list={data_row.listDisplay}>
														</Message>
														:
														<Button size="tiny" onClick={() => {this.initiateOrderList(data_row)}} icon="list ul" color="blue"/>
													}
						                            <Button size="tiny" onClick={()=>{this.setState({showFilesModal:true, selected_data_row: data_row, data_row_files_check: data_row.list})}} icon="attach" color="blue"/>
													<Button size="tiny" color={data_row.confirmation ? (data_row.confirmation.verification_status == "verified" ? "green" : "red") : "blue"} icon="thumbs up" onClick={() => this.setState({showCompleteVerification: true, orderConfirmationData: data_row.confirmation, selected_order: data_row})}/>
												</Card.Description>
												<Card.Description style={{fontWeight:'bold',fontSize:'1rem',color:data_row['next_date'] <= this.state.today ?'white':'black'}}>
														{this.convertSelectToText('current_employee',data_row.current_employee)}
														<span style={{float:'right'}}> {data_row.next_date}</span>
												</Card.Description>
												<Card.Description>
													People : {
														data_row.contacts.map((contact)=>(
														<span style={{textDecoration:'underline',marginLeft:5,display:'inline-flex'}}>
															{contact.name}
														</span>
														))
													}
												</Card.Description>
												</Card.Content>
											<Card.Content extra style={{padding:'3px 5px',backgroundColor:'#ffffff'}}>
												<span className="toggleBtn"
													onClick={(e, data) => this.toggleCommentOf(e, data_row.slip_no)}
													>
													<Icon name={this.state.show_comment_of === data_row.slip_no?'up arrow':'down arrow'} />
													Comments
												</span>
												<span  className="toggleBtn" style={{float:'right'}}
													onClick={(e, data) => this.toggleContactOf(e, data_row.slip_no)}
													>
													<Icon basic name={this.state.show_contact_of === data_row.slip_no?'up arrow':'down arrow'} />
													Contacts
												</span>
											</Card.Content>
											{	//comments will show up here
												this.state.show_comment_of === data_row.slip_no?
												<Card.Content style={{backgroundColor:'#ffffff'}}>
													{
														this.state.breadcrumbs.length
														?
														<Step.Group className="desktop-bread" unstackable size="tiny">
														  <Step active={this.state.extracted_comments == false} onClick={()=>this.setState({extracted_comments:false,breadcrumb_index:false})}>
															  <Step.Content>
																  <Step.Title>All</Step.Title>
															  </Step.Content>
														  </Step>
														  {
															  this.state.breadcrumbs.map((bread_crumb,i)=>{
																  return(
																	  <Step active={i===this.state.breadcrumb_index} onClick={()=>this.breadCrumbClickHandler(i)}>
																		  <Step.Content>
																			  <Step.Title>{bread_crumb.status.slice(0,bread_crumb.status.length -2).toUpperCase()}</Step.Title>
																		  </Step.Content>
																	  </Step>
																  )
															  })
														  }
														  </Step.Group>
														:
														null
													}
													{
														this.state.breadcrumbs.length
														?
														<Breadcrumb className="mobile-bread">
															  <Breadcrumb.Section  active={this.state.extracted_comments == false} onClick={()=>this.setState({extracted_comments:false,breadcrumb_index:false})}>
																  All
															  </Breadcrumb.Section>
															  <Breadcrumb.Divider>
																  <Icon name="angle right"/>
															  </Breadcrumb.Divider>
															{
															  this.state.breadcrumbs.map((bread_crumb,i)=>{
																  return(
																	  <>
																	  <Breadcrumb.Section active={i===this.state.breadcrumb_index} onClick={()=>this.breadCrumbClickHandler(i)}>
																		  {bread_crumb.status.slice(0,bread_crumb.status.length -2).toUpperCase()}
																	  </Breadcrumb.Section>
																	  <Breadcrumb.Divider>
																		  <Icon name="angle right"/>
																	  </Breadcrumb.Divider>
																	  </>
																  )
															  })
														  }
														</Breadcrumb>
														:
														null
													}
													{
														this.state.loading?
														<Segment>
															<Dimmer active>
																<Loader />
															</Dimmer>
														</Segment>:
														<div style={{maxHeight:'35vh',overflowY:'auto',borderBottom:'1px solid black'}}>

														{(this.state.comments.length?
														this.state[this.state.extracted_comments?'extracted_comments':'comments'].map((comment,i)=>{
															var emp_id = 0;
															if(comment.commenter_type == "App\\Staffs")
															{
																const emp_string = comment.staff.split('/')
																emp_id = emp_string[emp_string.length - 1]
															}
															return(
															<>
																<div
																	style={{backgroundColor:comment.commenter==1?'greenyellow':'none',padding:'0px 5px',borderRadius:'5px',border:"1px solid grey",marginTop:"3px",}}>
																	<div className="toggleContainer">
																		<span style={{fontWeight:'bold',color:'steelblue'}}>{comment.commenter_type == "App\\Staffs" ? comment.staff_name : (comment.commenter_name + " (Customer)")}</span>
																		{
																			(comment.commenter_type == "App\\Staffs" && (this.state.emp_dept === 1) || (comment.emp_id === emp_id))?
																				this.state.showLoaderOn !== comment.id ?
																					<Checkbox
																						checked={comment.show_customer}
																						value={comment.id} onChange={(e, data) => this.toggleCommentVisibility(e, data,comment.show_customer)}
																						small
																						style={{float:'right',marginTop:10}} />
																				:<Loader active size='tiny' inline style={{float:'right',marginTop:10}}/>
																			:null
																		}
																		<small style={{display:'block',color:'grey',marginTop:'-10px'}}>{comment.commented_on}</small>
																	</div>
																	<p style={{fontSize:'1rem',textTransform:'capitalize',color:'drakgrey'}}>{comment.comment}</p>
																</div>
																<span className="badgeContainer">
																	{this.checkShortHandsInComment(comment.comment,i)}

																</span>
															</>
														)})
														:
														<Card.Meta>
															*No Comments Yet
														</Card.Meta>)}
														</div>
													}
													<div className="toggleContainer" style={{display:'flex',justifyContent:"space-between",paddingTop:'5px',borderTop:'1px solid black'}}>
														<div className="btns" style={{color:'teal'}}>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'assign@')} style={{marginRight:'10px',cursor:'pointer'}}>Assign</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'schedule@')} style={{marginRight:'10px',cursor:'pointer'}}>Schedule</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'update@')} style={{marginRight:'10px',cursor:'pointer'}}>Update</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'inform@')} style={{marginRight:'10px',cursor:'pointer'}}>Inform</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'vendor@')} style={{marginRight:'10px',cursor:'pointer'}}>Vendor</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'remove_inform@')} style={{marginRight:'10px',cursor:'pointer'}}>Remove Inform</span>
															<span className="short-btn" onClick={(e)=>this.shortHandsButton(e,'remove_vendor@')} style={{marginRight:'10px',cursor:'pointer'}}>Remove Vendor</span>
														</div>
														<Checkbox   id="toggle" value={this.state.new_comment_visibility} onChange={(e, data) => this.toggleNewCommentVisibility(e, data)} small  />
													</div>
													<div style={{diaplay:'block'}}>
														<Input autoComplete="off" autofocus id='commentInput'
														onClick={this.commentBoxClick.bind(this)}
														onKeyDown={(e)=>this.commentBoxKeyDown(e)}
														onChange={(e, data) => this.changeStateVariable(e, data)}
															label={
																this.state.delimeter === 'assign'
																?
																<Dropdown search
																	id = "assignDrop"
																	selection
																	upward
																	onClose={(event,data)=>this.onLabelSelect(event,data)}
																	onChange={(e, data) => this.autoComplete(e, data)}
																	defaultValue='' options={this.state.options_current_employee_comments}
																	onKeyDown={(e)=>this.keyDown(e)}/>
																	:
																	this.state.delimeter === 'update'
																	?
																	<Dropdown search
																		id = "updateDrop"
																		upward
																		onClose={(event,data)=>this.onLabelSelect(event,data)}
																		selectOnNavigation="flase"
																		selection
																		onChange={(e, data) => this.autoComplete(e, data)}
																		defaultValue='' options={status}
																		onKeyDown={this.keyDown.bind(this)}/>
																		:
																		this.state.delimeter === 'schedule'?

																		<DateTimeInput
																			onKeyDown={(e)=>this.keyDown(e)}
																			closable
																			disable
																			divider="_"
																			dateFormat="YYYY/MM/DD"
																			hideMobileKeyboard
																			value={this.state.autoCompleteText}
																			id="schedulePicker"
																			onChange={(event,data)=>this.onDataTimeSelect(event,data)} />

																		:
																		this.state.delimeter === 'inform'
																		?
																		<Dropdown search
																			id = "informDrop"
																			upward
																			selectOnNavigation="flase"
																			onClose={(event,data)=>this.onLabelSelect(event,data)}
																			selection
																			onChange={(e, data) => this.autoComplete(e, data)}
																			defaultValue='' options={this.state.options_current_employee_comments}
																			onKeyDown={this.keyDown.bind(this)}/>
																			:
																			this.state.delimeter === 'vendor'
																			?
																			<Dropdown search
																				id = "vendorDrop"
																				upward
																				selectOnNavigation="flase"
																				onClose={(event,data)=>this.onLabelSelect(event,data)}
																				selection
																				onChange={(e, data) => this.autoComplete(e, data)}
																				defaultValue='' options={this.state.options_current_vendor_comments}
																				onKeyDown={this.keyDown.bind(this)}/>
																				:
																				this.state.delimeter === 'remove_vendor'
																				?
																				<Dropdown search
																					id = "removeVendorDrop"
																					upward
																					selectOnNavigation="flase"
																					onClose={(event,data)=>this.onLabelSelect(event,data)}
																					selection
																					onChange={(e, data) => this.autoComplete(e, data)}
																					defaultValue='' options={this.state.removeableVendor}
																					onKeyDown={this.keyDown.bind(this)}/>
																					:
																					this.state.delimeter === 'remove_inform'
																					?
																					<Dropdown search
																						id = "removeInformDrop"
																						upward
																						selectOnNavigation="flase"
																						onClose={(event,data)=>this.onLabelSelect(event,data)}
																						selection
																						onChange={(e, data) => this.autoComplete(e, data)}
																						defaultValue='' options={this.state.removeableTeam}
																						onKeyDown={this.keyDown.bind(this)}/>
																						:null


															}
															labelPosition="right"
															icon={{ name: 'send', circular: true, link: true, onClick:this.postComment.bind(this) }} value={this.state.new_comment} name="new_comment" fluid wide required placeholder="Write new comment" />
														</div>
												</Card.Content>
												:null
											}
											{	//contacts will show up here
												this.state.show_contact_of === data_row.slip_no?
												<Card.Content>
													{

														data_row.contacts.length > 0?
														<Table celled compact unstackable>
														<Table.Header >
															<Table.Row  className="contactRows">
																<Table.HeaderCell>Name</Table.HeaderCell>
																<Table.HeaderCell>Phone</Table.HeaderCell>
																<Table.HeaderCell>E-mail</Table.HeaderCell>
															</Table.Row>
														</Table.Header>
														<Table.Body>
															{
																distinct_types.map(type=>{
																	return(
																	<>
																		<Table.Row textAlign="center" className="contactRows" fullWidth>
																			<Table.Cell colSpan={3} className="typeTitle">{type}</Table.Cell>
																		</Table.Row>
																		{data_row.contacts.map((contact)=>(
																			contact.type == type?
																				<Table.Row className="contactRows" >
																					<Table.Cell>{contact.name}</Table.Cell>
																					<Table.Cell>{contact.phone}</Table.Cell>
																					<Table.Cell>{contact.email}</Table.Cell>
																				</Table.Row>
																				:null)
																			)
																		}
																	</>
																	)
																})
															}

														</Table.Body>
													</Table>
														:
													<Card.Meta>
														*No Contacts Yet
													</Card.Meta>
													}


												</Card.Content>
												:null
											}

										</Card>
									)})
								}
						</div>
					}
					</>
				}

				<Modal size="large" open={this.state.showCreateDataModal} onClose={() => this.toggleCreateDataModal()}>
					<Modal.Content scrolling>
					<Form size="mini" onSubmit={() => this.createNewDataRow()}>
						<Form.Group widths='equal'>
							{
								this.state.editMode?
								<Label color="blue" style={{fontSize:'25px', fontWeight:'bold'}}>{this.state.new_data_row.slip_no}</Label>
								:
								null
								// <Form.Input type="text" required fluid name="slip_no" value={this.state.new_data_row.slip_no} label="Slip No." onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							}
							<Form.Input type="date" required name="slip_date" value={this.state.new_data_row.slip_date} label="Slip Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Select type="text" required selection search options={this.state.options_customer} name="customer" value={this.state.new_data_row.customer} label="Customer" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							{
								(this.state.new_data_row.customer !=null && this.state.new_data_row.showBranch) || this.state.editMode?
									<Form.Select
										type="text"
										loading={this.state.loading_branch || this.state.new_data_row.loading_branch}
										clearable required selection search
										options={this.state.options_branch}
										name="cust_branch" value={this.state.new_data_row.cust_branch}
										label="Branch" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								:null
							}
						</Form.Group>
						{
							(this.state.new_data_row.customer != null && this.state.new_data_row.showBranch) || this.state.editMode ?
								<Form.Select
									type="text"
									loading={this.state.loading_branch || this.state.new_data_row.loading_branch}
									clearable selection search
									options={this.state.options_company_users}
									name="corp_account" value={this.state.new_data_row.corp_account}
									label="Company User" onChange={(e, data) => this.showListModal(e, data)}/>
							:null
						}
						{
							(this.state.new_data_row.listDisplay != undefined && this.state.new_data_row.listDisplay != '')
							?
							<Message
							// onDismiss={() => {this.setState({shrinkMessage:{}})}}
							onClick={() => {this.setState({showListModal:true, selected_data_row: this.state.new_data_row, data_row_list_check: this.state.new_data_row.list})}}
							style={{cursor:'pointer', display: (this.state.new_data_row.listDisplay != undefined && this.state.new_data_row.listDisplay != '') ? 'block' : 'none'}} list={this.state.new_data_row.listDisplay}>
							</Message>
							:
							<Button icon="list ul" color="blue" style={{float:"right"}} size="small"
	                        	onClick={(e, data) => this.showListModal(e, data)}></Button>
						}
						<Form.TextArea type="text" required fluid name="requirement" value={this.state.new_data_row.requirement} label="Requirement" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
						<Form.Group widths='equal'>
							<Form.Select type="text" selection search options={this.state.options_vendor} name="vendor" value={this.state.new_data_row.vendor} label="Vendor" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Select type="text" selection search options={this.createDepartmentSequence()} name="current_department" value={this.state.new_data_row.current_department} label="Current Department" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Select type="text" selection search options={this.createEmployeeSequence()} name="current_employee" value={this.state.new_data_row.current_employee} label="Current Employee" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Form.Input type="date" required name="next_date" value={this.state.new_data_row.next_date} label="Next Update Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
						</Form.Group>
						<Form.Button disabled={this.state.creatingNewDataModal} type='submit' primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
					</Form>
						{
							this.state.editMode?
					    	<Button.Group widths='2'>
							{
								this.state.tableButtons.map((field) => (
										<Button inverted size='tiny' color={this.state.viewInnerDetail==field.value ? 'blue' : 'green'} onClick={() => this.viewInnerDetailButton(field.value)}>{field.text}</Button>
								))
							}
							</Button.Group>
							:
							null
						}
						{
							this.state.editMode?
							<div style={{height:'200px',overflowY:'scroll'}}>
								{innerDetails}
							</div>
							:
							null
						}
					</Modal.Content>
				</Modal>
				<Modal open={this.state.modalReport} size={'large'}  onClose={() => this.setState({
					modalReport:false,
					slip_date_update_graph:false,
					month_single_factor_graph:false,
					show_update_graph:false,
					slip_date_single_factor_graph:false,
					graph_employee:[],

					})}>
				    	<Modal.Content scrolling>

							<div style={{display:'inline-flex'}}>
									<Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
										placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
									<Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
										placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
									<Loader loading = {this.state.loading_graph}/>
								</div>
							<hr></hr>
							<Grid divided='vertically'>
    							<Grid.Row columns={2}>
									<Grid.Column  >
										{
											this.state.slip_date_single_factor_graph
											?
											<LineChart width={500} height={300} data={this.state.slip_date_single_factor_graph}
												margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
												<CartesianGrid strokeDasharray="10 10" />
												<XAxis dataKey="name" />
												<YAxis />
												<Tooltip />
												<Legend />
												<Line type="monotone" dataKey="count" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
											</LineChart>
											:
											<Segment>
												<Dimmer active>
												<Loader size='medium'>Loading</Loader>
												</Dimmer>

												<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
											</Segment>
										}
     						 		</Grid.Column>
     						 		{
										this.state.month_single_factor_graph
										?
										<LineChart width={500} height={300} data={this.state.month_single_factor_graph}
											margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
											<CartesianGrid strokeDasharray="10 10" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Line type="monotone" dataKey="count" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
										</LineChart>
										:
										<Segment>
											<Dimmer active>
											<Loader size='medium'>Loading</Loader>
											</Dimmer>

											<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
										</Segment>
									}
     						 	</Grid.Row>
								  <div style={{display:"inline-flex"}}>
									<Dropdown
										multiple
										selection
										search
										placeholder="Choose Status"
										options={this.state.options_current_status}
											onChange={(e,data)=>{ this.setState({graph_status:data.value})}}
										/>
									<Dropdown
										selection
										search
										name="graph_employee"
										placeholder = "Choose Staff"
										options = {this.state.options_current_employee}
										onChange = {(e,data)=>{this.handleGraphDataChange(e,data)}}
										/>
								  </div>
    							<Grid.Row columns={2}>

									<Grid.Column  >
									{
										this.state.slip_date_update_graph
										?
										<LineChart width={500} height={300} data={this.state.slip_date_update_graph}
											margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
											<CartesianGrid strokeDasharray="10 10" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											{	this.state.graph_status.length>0
												?
												this.state.graph_status.map((status)=>(
													<Line type="monotone" dataKey={status} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
												))
												:
												this.state.options_current_status.map((status)=>(
													<Line type="monotone" dataKey={status.value} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
												))
											}
										</LineChart>
										:
										<Segment>
											<Dimmer active>
											<Loader size='medium'>Loading</Loader>
											</Dimmer>

											<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
										</Segment>
									}
     						 		</Grid.Column>

								</Grid.Row>
							</Grid>
						</Modal.Content>
                </Modal>
				<Modal open={this.state.last_order} onClose={()=>{this.setState({last_order:false,last_created:false})}}>
					<Modal.Header>
						<span style={{color:'green'}}>Slip No: {this.state.last_order?this.state.last_order.slip_no+' ' : ''}</span>
						 <span style={{float:'right'}}>Order Created <Icon name="check"/></span>
				   </Modal.Header>
				    <Modal.Content  >
						<Grid columns={2}>
							<Grid.Column>
								Current Status:  {this.state.last_order? this.state.last_order.current_status : ''}
							</Grid.Column>
							<Grid.Column>
								Customer Name: {this.state.last_order? this.state.last_order.customer_name : ''}
							</Grid.Column>
							<Grid.Column>
								Requirement: {this.state.last_order?this.state.last_order.requirement: ''}
							</Grid.Column>
							<Grid.Column>
								Next Date: {this.state.last_order?this.state.last_order.next_date: ''}
							</Grid.Column>
						</Grid>
					</Modal.Content>
				</Modal>
				<Modal open={this.state.show_voucher} onClose={()=>{
						this.setState({
							show_voucher:false,
							data_row_voucher:false,
							voucher_order_id:false,
							voucher_name:false,
							voucher_list:false,
							voucher_redirect_to:false
						})
					}}>
					<Modal.Content scrolling>
					{
						this.state.voucher_list && this.state.data_row_voucher
						?
						<Button color="red" floated="right" onClick={()=>this.setState({data_row_voucher:false,show_voucher:'list'})}>Back To List</Button>
						:
						null
					}
					{
						this.state.voucher_list && (!this.state.data_row_voucher)
						?
						<>
						<Button onClick={()=>{this.voucherAction(this.state.voucher_order_id,this.state.voucher_redirect_to,[])}}>ADD</Button>
						<Table color="blue" key="blue" size="small" compact>
							<Table.Header>
								<Table.Row>
								{
									this.state.tableFieldsVoucher.map((field) => (
										<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
											{field.text}
											{
												this.state.data_rows_sort_order == "asc" ?
													<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
												:
													<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
											}
										</Table.HeaderCell>
									))
								}
								</Table.Row>
							</Table.Header>

							<Table.Body>
							{
								this.state.voucher_list.map((data_row, index) => (
									<Table.Row className="data-row-table-row" onClick={() => this.onVoucherListSelect(data_row.id)}>
									{
										this.state.tableFieldsVoucher.map((field) => (
											<Table.Cell>
											{
												field.value == "ledger"
												?
													data_row.customer_name ? data_row.customer_name : data_row.vendor
												:
													(field.type=='select'
													?
														this.convertSelectToText(field.value, data_row[field.value])
													:
													data_row[field.value])
											}
											</Table.Cell>
										))
									}
									</Table.Row>
								))
							}
							</Table.Body>
						</Table>
						</>
						:
						null
					}
					{
						this.state.show_voucher == 'sale'
						?
						<SaleView history={this.props.history} data_row={this.state.data_row_voucher}/>
						:
						null
					}
					{
						this.state.show_voucher == 'quotation'
						?
						<QuotationView history={this.props.history} data_row={this.state.data_row_voucher}/>
						:
						null
					}
					{
						this.state.show_voucher == 'purchase'
						?
						<PurchaseView history={this.props.history} data_row={this.state.data_row_voucher}/>
						:
						null
					}
					{
						this.state.show_voucher == 'delivery_note'
						?
						<DeleveryChallanView history={this.props.history} data_row={this.state.data_row_voucher}/>
						:
						null
					}
					</Modal.Content>
				</Modal>
				<Modal size="small" open={this.state.showPaymentReceiptModal} onClose={() => this.setState({showPaymentReceiptModal:false})}>
					{
						this.state.paymentReceipt.paymentInvoices.map((receiptInvoice) => (
							<div style={{textAlign:"center"}}>
								Payment ID: {receiptInvoice.paymentId} - Payment Status: {this.state.paymentReceipt.paymentStatus}<br/>
								Payment Date: {receiptInvoice.paymentDate} - Payment Mode: {receiptInvoice.paymentMode}<br/>
								Payment Amount: {receiptInvoice.paymentAmount}<br/>
								TDS Amount: {receiptInvoice.tdsAmount}<br/>
								GST TDS: {receiptInvoice.gstTdsAmount}
							</div>
						))
						// this.state.paymentReceipt.paymentInvoices.length > 0
						// ?
						// LAST PAYMENT DETAILS
						// <div>{this.state.paymentReceipt.paymentStatus}  {this.state.paymentReceipt.paymentInvoices[0].paymentAmount}</div>
						// :
						// null
					}
					<Modal.Content>
						<Input name="date" fluid type="date" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment Date" defaultValue={this.state.paymentReceipt.paymentDate || this.state.currentInvoiceSale.date}/>
						<Input name="amount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Amount" defaultValue={this.state.paymentReceipt.paymentAmount || this.state.currentInvoiceSale.payableAmount}/>
						<Input name="tdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="IT TDS Amount" defaultValue={this.state.paymentReceipt.tdsAmount}/>
						<Input name="gstTdsAmount" fluid type="number" onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="GST TDS Amount" defaultValue={this.state.paymentReceipt.gstTdsAmount}/>
						<Input name="paymentMode" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Cash/Credit/Cheque/NEFT" defaultValue={this.state.paymentReceipt.paymentMode}/>
						<Input name="referenceNumber" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Reference Number (If Any)" defaultValue={this.state.paymentReceipt.referenceNumber}/>
						<Input name="paymentId" fluid onChange={(e, data) => this.handleInputChangePayment(e, data)}
							placeholder="Payment ID (If Any)" defaultValue={this.state.paymentReceipt.paymentId}/>
						<Button fluid onClick={() => this.printPaymentReceipt(1)} primary>PRINT</Button>
						{
							this.state.paymentReceipt.paymentStatus == "PAID"
							?
							<Button fluid onClick={() => this.printPaymentReceipt(2)} primary>ADD TO PAYMENTS</Button>
							:
							null
						}
					</Modal.Content>
				</Modal>
				<Modal open={this.state.marking_issue}
					onClose={()=>{
						this.setState({marking_issue_loader:false,marking_issue:false,marking_issue_comment:'',marking_issue_show_customer:1})

					}}
					>
					<Modal.Content>
						<Input
							style={{
								marginBottom:'10px'
							}}
							fluid wide
							placeholder="Enter Remark.."
							name="marking_issue_comment"
							value={this.state.marking_issue_comment}
							onChange={(e,data)=>{
								this.setState({[data.name]:data.value || e.target.value})
							}}
						/>
						<Checkbox
							style={{
								marginBottom:'10px'
							}}
							checked={this.state.marking_issue_show_customer}
							onClick={()=>{
								this.setState({marking_issue_show_customer:!this.state.marking_issue_show_customer})
							}}
							label="Show Customer"
							toggle/>
						<Button
							fluid wide
							positive
							onClick={()=>this.markIssue()}
							loading={this.state.marking_issue_loader}
							>
							Mark Issue
						</Button>
					</Modal.Content>
				</Modal>

				<Modal
					open={this.state.showFilesModal}
					onClose={()=>this.setState({showFilesModal: false})}>
					<FileOrdersPreview files={this.state.selected_data_row.files} order_data_row={this.state.selected_data_row} orderUpdated={() => this.getDataRows(this.state)}/>
				</Modal>

				<Modal open={this.state.showCompleteVerification} onClose={() => this.setState({showCompleteVerification: false, confirmationData: undefined, selected_order: {}})}>
					<Modal.Content>
						<VerifyOrder confirmationData={this.state.orderConfirmationData} order_data={this.state.selected_order}/>
					</Modal.Content>
				</Modal>

				<Modal
					open={this.state.showListModal}
					onClose={() => {this.setState({showListModal:false})}}
					size={this.state.contracts.length > 0 ? "large" : "small"} closeIcon>
						<Modal.Content>
							<Header block textAlign='center'>Create List.</Header>
							<Button size="tiny" disabled={!this.state.new_data_row.customer} color="blue" loading={this.state.loadingContracts} onClick={() => this.loadContracts()}>Load Contracts</Button>
							{
								this.state.contracts.length > 0
								?
								<Dropdown
								size="tiny"
								placeholder='Select Contract'
								fluid selection search
								options={this.state.contracts.map(function(elem, index) {
									return {
										text: elem.voucher_id,
										value: index,
									}
								})}
								onChange={(e, data) => this.populateContract(e, data)}/>
								:
								null
							}
							<Grid>
							<Grid.Row columns={this.state.selectedContractProducts.length > 0 ? "2" : "1"}>
								{
								this.state.selectedContractProducts.length > 0
								?
									<Grid.Column style={{overflowY:'scroll', height:'40vh'}}>
										<Table celled compact size="small">
											<Table.Body>
											{
												this.state.selectedContractProducts.map((product, index) => (
													<Table.Row>
														<Table.Cell>
															{product.name + (product.description != "" ? " (" + product.description + ")" : "")}
														</Table.Cell>
														<Table.Cell>
															{product.rate}
														</Table.Cell>
														<Table.Cell>
															<Button size="tiny" icon="arrow alternate circle right" onClick={() => this.addProductToList(true, index)}></Button>
														</Table.Cell>
													</Table.Row>
												))
											}
											</Table.Body>
										</Table>
									</Grid.Column>
									:
									null
								}
								<Grid.Column style={{overflowY:'scroll', maxHeight:'40vh'}}>
									<Table celled compact size="small">
										<Table.Body>
										{
											this.state.new_data_row.list != undefined
											?
											this.state.new_data_row.list.map((list_element, index)=>(
												<Table.Row>
													<Table.Cell width={8}>
														<Input disabled={list_element.product_id != undefined} fluid placeholder='Item Name' maxLenght="20" name="name" value={list_element.name} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
													</Table.Cell>
													<Table.Cell width={5}>
														<Input disabled={list_element.product_id != undefined} fluid type="number" iconPosition='left' placeholder='Rate (Approx)' name="rate" value={list_element.rate} onChange={(e, data) => this.modifyListElement(e, data, index)}>
															<Icon name='rupee sign' />
															<input/>
														</Input>
													</Table.Cell>
													<Table.Cell width={3}>
														<Input fluid type="number" placeholder='Quantity' name="qty" value={list_element.qty} onChange={(e, data) => this.modifyListElement(e, data, index)}/>
													</Table.Cell>
													<Table.Cell>
														<Button size="tiny" floated="right" color="red" icon="close" style={{display: (this.state.new_data_row.list.length > 0 ? "inline" : "none")}} onClick={() => this.addToList(false, index)}/>
													</Table.Cell>
												</Table.Row>
											))
											:
											null
										}
										</Table.Body>
									</Table>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row columns={1}>
								<Grid.Column>
								<Button size="tiny" floated="right" color="blue" onClick={() => this.addToList(true)}>Add More</Button>
								<Button size="tiny" floated="right" color="green" onClick={() => this.updateList(this.state.editMode)} loading={this.state.updatingOrder}>
									{this.state.editMode ? "Update" : "Done"}
								</Button>
								</Grid.Column>
							</Grid.Row>
							</Grid>
						</Modal.Content>
					</Modal>

			</div>
        )
	}
}

export default OrderSlip;
