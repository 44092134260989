import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import Client from '../services/Client';

const PrivateRoute=({component,...restProps})=>{
    if(Client.loggedIn()){
        Client.getUserData( localStorage.getItem('user'),localStorage.getItem('token'),localStorage.getItem('regId')).then(res=>{
            if(res.responseResult == "failure"){
                window.localStorage.clear();
            }
            else{
                localStorage.setItem('userData', JSON.stringify(res));
            }
        });
    }

    return(
        <Route {...restProps} render={
            (props)=>(
                Client.loggedIn()
                ?
                (React.createElement(component,props))
                :
                (<Redirect to={{pathname:'/login'}}/>)
            )
        } />
    )
};
export default PrivateRoute;
