import React, {Component} from 'react';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Image, Label, Segment, Header, Grid} from 'semantic-ui-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';

const converter = require('number-to-words');
class Graphs extends Component{
    constructor(props){
        super(props)
        this.state ={
            data:[
                {
                name: 'date 1', badal: 4000, Kamal: 2400, 
                },
                {
                name: 'date 2', Badal: 3000, Kamal: 1398, 
                },
                {
                name: 'date 3', Badal: 2000, Kamal: 9800, 
                },
                {
                name: 'date 4', Badal: 2780, Kamal: 3908, 
                },
                {
                name: 'date 5', Badal: 1890, Kamal: 4800, 
                },
                {
                name: 'date 6', Badal: 2390, Kamal: 3800, 
                },
                {
                name: 'date 7', Badal: 3490, Kamal: 4300, 
                },
          ]
        }
    }
    render() {
        return (
        <LineChart
            width={500}
            height={300}
            data={this.state.data}
            margin={{
            top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Kamal" stroke="#8884d8" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="Badal" stroke="#82ca9d" />
        </LineChart>
        );
    }
}
export default Graphs;
