import React from 'react'
import {Card, Dropdown, Input,Loader,Segment,Dimmer,Image, Checkbox} from 'semantic-ui-react'
import Client from '../services/Client';
import '../style/dashboard.css'
import { BarChart,Bar,LineChart, Label,Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
class DashboardOrder extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            orders_analytics:[
            ],
            graph_factor:'quarter'
        }
        this.getReport = this.getReport.bind(this)
        this.prepareGraphDataSingleFactor = this.prepareGraphDataSingleFactor.bind(this)
    }
    componentWillMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
        }
    }
    componentDidMount(){
        
        this.getReport()
    }
    getReport(){
        this.setState({loading_graph:true})
        let range = {
                slip_date:[this.state.start_date,this.state.end_date],
                // date:[this.state.start_date,this.state.end_date]
            }
        Client.getDashboardOrderReport(null,null,null,null,null,null,null,null,null,range,null).then(res=>{
            console.log(res);
            this.setState({orderCountReport:res.orderCountReport,vouchers_analytics:res.voucherCountReport,orders_analytics:res.currentStatusReport,loading_graph:false},()=>{
                this.prepareGraphDataSingleFactor(this.state.graph_factor,'orderCountReport')
                this.prepareGraphDataSingleFactor(this.state.graph_factor,'vouchers_analytics')
            })
        })
    }
    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value},()=>{
            this.getReport()
        })
    }
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
    }
    aggregateCountForKey(data_row,key){
        let returnalbe = []
        let aggregated_data = {}
        data_row.forEach(elem=>{
            if (aggregated_data.hasOwnProperty(elem[key])) {
                aggregated_data[elem[key]].count = parseInt(aggregated_data[elem[key]].count) + parseInt(elem.count )
            } else {
                aggregated_data[elem[key]] = {count:parseInt(elem.count)}
            }
        })
        for(var key in aggregated_data){

            returnalbe.push({
                name:key,count:aggregated_data[key]["count"]
            })
        }
        return returnalbe
    }
    prepareGraphDataSingleFactor(axis="date",data_in_concern){
		// this.setState({loading_graph:true})
		var data_rows = []
		let filter={}
		let range = {slip_date :[this.state.start_date, this.state.end_date]}
		// Client.getOrderSlips(axis+'dash',0, null,"slip_date", "asc",null, null, null, filter,range).then((res)=>{
            data_rows =
                axis == "month"
                ?
                this.withMonth(this.state[data_in_concern])
                :
                    axis == "week"
                    ?
                    this.withWeekAndMonth(this.state[data_in_concern])
                    :
                        axis == "quarter"
                        ?
                        this.withQuarter(this.state[data_in_concern])
                        :
                        this.state[data_in_concern]
                //chopped in months
            // var distinct_axises = this.getDistictIn(axis,data_rows)
            
            
			let data_for_graph =this.aggregateCountForKey(data_rows,axis)
			
			console.log("master from dashboard orders",data_for_graph)
			this.setState({[ data_in_concern+'_single_factor_graph']:data_for_graph})
		// })
    }
    handleGraphDataChange(e,data){
        this.setState({
			[data.name]:data.value,
			loading_graph:true
		},()=>{
			this.getReport()
		})
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    withQuarter(array) {
        let quarterArr = [ "4", "4", "4", "1", "1", "1", "2", "2", "2", "3", "3", "3" ]
		let withQuarterArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let quarter = quarterArr[parseInt(date[1])-1]
            let year = date[0].slice(2,4)
            let transition = quarter == 4 ? (parseInt(year)-1)+'-'+year:year+'-'+(parseInt(year)+1)
			let temp = {...elem,quarter:transition+'-'+'Q'+quarter}
			withQuarterArray.push(temp)
        })
        console.log("with month array",withQuarterArray);
        return withQuarterArray
        .sort((a,b)=>{
            return a.quarter>b.quarter ? 1:-1
        })
    }

    withWeekAndMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
            
            let javascriptDate = new Date(parseInt(date[0]),parseInt(date[1])-1,parseInt(date[2]))
            console.log('week_number',javascriptDate.getWeekOfMonth())
			let temp = {...elem,week:'W'+(javascriptDate.getWeekOfMonth()+1     )+'-'+month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    render(){
        return(
            <Card>
            <Card.Header>
                Orders
            </Card.Header>
            <Card.Content>
            {
                this.state.orders_analytics
                ?
                <div className={"count-bar"}>
                    {	
                        this.state.orders_analytics.map((analytic)=>{
                        
                        // console.log('index',count_bar_index);
                        
                        if ((analytic.status != null && analytic.status != '' && analytic.status != undefined) ) {
                            // count_bar_index=count_bar_index+1
                            return(
                                <span className={"count-box"} >
                                    <span className="status-name">
                                        {analytic.status.toUpperCase()}
                                    </span>
                                    <span className="count-info">
                                        <span className="delayed">{analytic.delayed || 0}</span>
                                        /
                                        <span className="total">{analytic.total}</span>
                                    </span>
                                </span>
                            )
                        }
                    })}
                </div>
                :
                null
            }
            </Card.Content>
            <Card.Content>
                <div className="console-earning" style={{display:'inline-flex'}}>
                        <Input size="mini" type="date" name="start_date" label={{ content: 'FROM' }}
                            placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
                        <Input size="mini" type="date" name="end_date" label={{ content: 'TO' }}
                            placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphDataChange(e, data)}/>
                        <Loader loading = {this.state.loading_graph}/>
                        <Dropdown
                            name="graph_factor"
                            value={this.state.graph_factor}
                            // selection
                            style={{minHeight:0,minWidth:0}}
                            onChange={(e,data)=>{this.handleGraphDataChange(e,data)}}
                            options={[
                                {text:'Daily',value:'date'},
                                {text:'Weekly',value:'week'},
                                {text:'Monthly',value:'month'},
                                {text:'Quarterly',value:'quarter'},
                            ]}
                        />
                </div>
                {
                    !this.state.loading_graph
                    ?
                    <span className="multigraph-pane" >
                        <span style={{textAlign:'center'}}>
                            <span>Order</span>
                            <LineChart width={280} height={270} data={this.state.orderCountReport_single_factor_graph}
                                margin={{ top: 0, right: 0, left: 0, bottom:0 }}>
                                    <CartesianGrid strokeDasharray="10 10" />
                                <XAxis dataKey="name" >
                                    <Label angle={0} value={this.state.graph_factor.toUpperCase()} position="bottom"  />
                                </XAxis>
                                <YAxis >
                                    <Label angle={-90} value='Count' position='insideLeft' style={{textAnchor: 'bottom'}} />
                                </YAxis>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
                            </LineChart>
                        </span>
                        <span style={{textAlign:'center'}}>
                            <span>Vouchers</span>
                            <LineChart width={280} height={270} data={this.state.vouchers_analytics_single_factor_graph}
                                margin={{ top: 0, right: 0, left: 0, bottom:0 }}>
                                    <CartesianGrid strokeDasharray="10 10" />
                                <XAxis dataKey="name" >
                                    <Label angle={0}  value={this.state.graph_factor.toUpperCase()} position="insideBottom"  />
                                </XAxis>
                                <YAxis >
                                    <Label angle={-90} value='Count' position="insideLeft" style={{textAnchor: 'insideBottom'}} />
                                </YAxis>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="count" stroke={"#"+Math.floor(100000 + Math.random() * 900000)} />
                            </LineChart>
                        </span>
                    </span>
         
                    :
                    <Segment style={{width:'100%'}}>
                        <Dimmer active>
                        <Loader size="medium">Loading</Loader>
                        </Dimmer>

                        <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                    </Segment>
                }
            </Card.Content>
            
        </Card>
    
        )
    }
}
export default DashboardOrder