import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import "firebase/messaging";

import Client from './Client'

export const initializeFirebase = () => {
    var firebaseConfig = {
        apiKey: "AIzaSyCpLv-SJWBqADz54lu_N-40qqhRxn67FOg",
        authDomain: "deskneed-admin.firebaseapp.com",
        databaseURL: "https://deskneed-admin.firebaseio.com",
        projectId: "deskneed-admin",
        storageBucket: "deskneed-admin.appspot.com",
        messagingSenderId: "280903579512",
        appId: "1:280903579512:web:f7a20685d6e05230f73a8d",
        measurementId: "G-HBVWRVZFCE"
    };
    // Initialize Firebase
    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }
    // firebase.analytics();
}

export const askForPermissioToReceiveNotifications = async (userData) => {

    try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();
        const token = await messaging.getToken();

        return token;
    } catch (error) {
        console.error(error);
    }
}
