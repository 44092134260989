import $ from 'jquery';
import createHistory from 'history/createBrowserHistory';
import SmartHitter from './smart-hitter';

import { initializeFirebase } from '../services/push-notifications';
import { askForPermissioToReceiveNotifications } from '../services/push-notifications';

//const serverAddress = '';
//const serverAddress = 'http://localhost:3000';
// const serverAddress = 'http://demo-desk.us-west-2.elasticbeanstalk.com';
const serverAddress = 'https://admin.deskneed.com';
// const serverAddress = 'http://192.168.0.3/doorhopperServer/public';
// const serverAddress = 'http://192.168.0.08/doorhopperServer/public';
// const serverAddress = 'http://192.168.0.11/deskneed/public';
// const serverAddress = 'http://192.168.1.101/doorhopperServer/public';
// const serverAddress = 'http://192.168.43.34/doorhopperServer/public';
// const serverAddress = 'http://10.10.99.196';
// const serverAddress = 'http://192.168.0.2';
// const serverAddress = 'http://192.168.43.171';

var sh = new SmartHitter();

let md5=require('md5');
let monitor_city = localStorage.getItem('city');
const Client={
    checkAndFetch:(address) => {
        return fetch(
            address,
            {
                method:'POST',
                accept:'application/json',
            }
        )
        .then((res)=>res.text())
        .then((res)=>{
            if(res.substr(14,4)=="unau"){
                const history = createHistory();
                localStorage.clear();
                history.push('/logout');
                window.location.reload();
                return({vendors:[]})
            }else{
                return JSON.parse(res);
            }
        })
    },

    login:(phone,key)=>{
        const regId=md5(phone);

        localStorage.setItem('regId',regId);
        return fetch(
            `${serverAddress}/api/vdhp/account/login?phone=${phone}&key=${key}&regId=${regId}`,
            {
                method:'POST',
                accept:'application/json',
            }
        ).then((response)=>response.json());
    },

    updateUserNotification:(notificationId, userData)=>{
        return fetch(
            `${serverAddress}/api/vdhp/account/notification/register?phone=${userData.phone}&token=${userData.token}&notificationId=${notificationId}`,
            {
                method:'POST',
                accept:'application/json',
            }
        ).then((response)=>response.json());
    },

    getUserData:(user,token, regId)=>{
        return fetch(
            `${serverAddress}/api/vdhp/account/details?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&regId=${regId}`,
            {
                method:'POST',
                accept:'application/json',
            }
        ).then((response)=>response.json());
    },

    loggedIn:()=> {
        return localStorage.getItem('token');
    },
    setToken:(token)=>{
        localStorage.setItem('token',token);
    },
    getAppData:()=>{
            return fetch(
                `${serverAddress}/api/merchantAppData`,
            ).then((response)=>response.json())
        },
    getBusinesses(category, city){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/business/get?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&businessType=${category}&city=${city}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    updateBusiness(businessDetails){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/business/update?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&` + $.param(businessDetails),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    attemptDirectBusiness(businessDetails){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/business/direct?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&storeId=` +businessDetails.storeId,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    // getStaffs(city){
    //     const token=localStorage.getItem('token');
    //     const user=localStorage.getItem('user');
    //     return fetch(
    //         `${serverAddress}/api/vdhp/staff/get?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&city=${city}`,{
    //             method:'POST',
    //             accept:'application/json'
    //         }
    //     ).then((res)=>res.json())
    // },
    updateStaff(staff){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/staff/update?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&staff=` + JSON.stringify(staff),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    // createStaff(staff){
    //     const token=localStorage.getItem('token');
    //     const user=localStorage.getItem('user');
    //     return fetch(
    //         `${serverAddress}/api/vdhp/staff/create?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&staff=` + JSON.stringify(staff),{
    //             method:'POST',
    //             accept:'application/json'
    //         }
    //     ).then((res)=>res.json())
    // },
    initiateOrderVerification(order_id, name, phone){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const city=localStorage.getItem('city');

        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&user=${user}&order_id=${order_id}&concerned_name=${name}&concerned_phone=${phone}`;
        return sh.hit('initiateOrderVerification', `${serverAddress}/api/vdhp/order/complete/verify/initiate`, 'POST', data)
    },

    getOrders(page, status, storeId, filters, find, sort, sortOrder){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const city=localStorage.getItem('city');

        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&page=${page ? page : 0}` + (storeId ? `&storeId=${storeId || null}` : ``) + `&city=${city ? city : "All"}`+
            (find ? `&find=${find}` : ``) + (sort ? `&sort=${sort}` : ``) +
            (sortOrder ? `&sortOrder=${sortOrder}` : ``) + `&status=${status}&` + (filters ? $.param(filters) : '');
        return sh.hit('getOrders', `${serverAddress}/api/vdhp/order/get`, 'POST', data)
        // return fetch(`${serverAddress}/api/vdhp/order/get`,
        // {
        //     method: 'POST',
        //     body: JSON.stringify({
        //         token: token,
        //         phone: user,
        //         city: city,
        //         ...filters,
        //         page: page,
        //         status: status,
        //         storeId: storeId,
        //         find: find,
        //         sort: sort,
        //         sortOrder: sortOrder
        //     }),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }).then((res)=>(res.json()))
    },
    getOrderDetails(orderId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/order/details?orderId=${orderId}&phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}`,
            {
                method: 'POST',
                accept: 'application/json'
            }
        ).then((res)=>(res.json()))
    },
    getItemTransactions(filter,offset,limit,sort='date',sort_order='desc'){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user}&sort=${sort}&sort_order=${sort_order}&monitor_city=${monitor_city ||  ''}&token=${token}&offset=${offset}&limit=${limit}&filter=${JSON.stringify(filter)||'{}'}`;
        return sh.hit('getTransactions', `${serverAddress}/api/vdhp/accounting/item/transactions`, 'POST', data)
    },
    getAttendance(filter,month ,range={},networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let rangeToSearch = range ? `&range=${JSON.stringify(range)||'{}'}` : `&search_field=date&search=${month || ''}`
        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&filter=${JSON.stringify(filter)||'{}'}${rangeToSearch}`;
        return sh.hit('getAttendance/'+networkReqNAME, `${serverAddress}/api/vdhp/team/attendance/get`, 'POST', data)
    },
    markLeave(new_data_row){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&new_data_row=${JSON.stringify(new_data_row)}`;
        return sh.hit('markLeave', `${serverAddress}/api/vdhp/team/attendance/mark`, 'POST', data)
    },
    punchIn(new_data_row){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&new_data_row=${JSON.stringify(new_data_row)}`;
        return sh.hit('markLeave', `${serverAddress}/api/vdhp/team/attendance/mark`, 'POST', data)
    },
    punchOut(new_data_row){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&new_data_row=${JSON.stringify(new_data_row)}`;
        return sh.hit('markLeave', `${serverAddress}/api/vdhp/team/attendance/mark`, 'POST', data)
    },
    updateOrder(orderDetails, status, notify, subsId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const date=localStorage.getItem('updateDate');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&status=${status||''}&notify=${notify||''}&date=${date||''}&subscriptionId=${subsId||''}&orderId=${orderDetails.orderId}`;

        return fetch(
            `${serverAddress}/api/vdhp/order/update/status`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    cancelOrder(orderId, reason, notify, subsId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const date=localStorage.getItem('updateDate');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&status=Cancelled&cancelReason=${reason||''}`
            +`&notify=${notify||''}&date=${date||''}&subscriptionId=${subsId||''}&orderId=${orderId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/order/cancel`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getBookings(page, status, storeId, filters, find, sort, sortOrder){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const city=localStorage.getItem('city');

        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&page=${page ? page : 0}` + (storeId ? `&storeId=${storeId || null}` : ``) + `&city=${city ? city : "All"}`+
            (find ? `&find=${find}` : ``) + (sort ? `&sort=${sort}` : ``) +
            (sortOrder ? `&sortOrder=${sortOrder}` : ``) + `&status=${status}&` + (filters ? $.param(filters) : '');
        return sh.hit('getBookings', `${serverAddress}/api/vdhp/booking/get`, 'POST', data)
    },
    getBookingDetails(bookingId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/booking/details?bookingId=${bookingId}&phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}`,
            {
                method: 'POST',
                accept: 'application/json'
            }
        ).then((res)=>(res.json()))
    },
    processBooking(bookingDetails, status, notify, paymentStatus, subsId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const date=localStorage.getItem('updateDate');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&status=${status||''}&amount=${bookingDetails.bookingAmount||''}`
            +`&notify=${notify||''}&date=${date||''}&subscriptionId=${subsId||''}&` + $.param(bookingDetails);

        return fetch(
            `${serverAddress}/api/vdhp/booking/update/status`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    cancelBooking(bookingId, reason, notify, subsId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const date=localStorage.getItem('updateDate');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&status=Cancelled&cancelReason=${reason||''}`
            +`&notify=${notify||''}&date=${date||''}&subscriptionId=${subsId||''}&bookingId=${bookingId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/booking/cancel`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    updateBookingPrice(bookingId, updatePaymentMode,updateDiscountAmount, updateAdditionalCharges, updateVariableAmount, updateRoundedAmount, subsId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        const date=localStorage.getItem('updateDate');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&updateAdditionalCharges=${updateAdditionalCharges||''}`
            +`&updatePaymentMode=${updatePaymentMode.id || ''}&updateVariableAmount=${updateVariableAmount||''}`
            +`&updateRoundedAmount=${updateRoundedAmount||''}&updateDiscountAmount=${updateDiscountAmount||''}&date=${date||''}&subscriptionId=${subsId||''}&bookingId=${bookingId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/booking/update/price`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    updateBooking(bookingData){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&` + $.param(bookingData);

        return fetch(
            `${serverAddress}/api/vdhp/booking/update`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccounts(city, startDate, endDate, partner, page){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/account/get?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&city=${city}&startDate=${startDate}&endDate=${endDate}&partner=${partner}&page=${page}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    getAccountsCash(city, startDate, endDate, partner, page){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/account/cashBook?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&city=${city}&startDate=${startDate}&endDate=${endDate}&partner=${partner}&page=${page}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    updateAccountsEntry(staff){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/account/update?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&staff=` + JSON.stringify(staff),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    createAccountsEntry(entry){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/account/create?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&entry=` + JSON.stringify(entry),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    createCBEntry(entry){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/account/cb/create?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&entry=` + JSON.stringify(entry),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    creditStaffSalary(id, entry){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/staff/salary/credit?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&staff=${id}&entry=` + JSON.stringify(entry),{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    getRiderLocTrack(id, date, startAt, endAt){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/rider/track/get?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&id=${id}&date=${date}&startAt=${startAt}&endAt=${endAt}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    getRiderSOS(id, date, startAt, endAt){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/rider/track/sos?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&id=${id}&date=${date}&startAt=${startAt}&endAt=${endAt}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    searchAccountingInvoiceCustomer(find){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/customer/search?phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&find=${find}`,{
                method:'POST',
                accept:'application/json'
            }
        ).then((res)=>res.json())
    },
    searchAccountingInvoiceProduct(find,customer){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user}&monitor_city=${monitor_city ||  ''}&token=${token}&find=${find}&customer=${customer}`

        return sh.hit('/api/vdhp/accounting/invoice/product/search', `${serverAddress}/api/vdhp/accounting/invoice/product/search`, 'POST', data)
    },
    accountingInvoiceCreateSale(sale, customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(sale);
        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingCreateCreditNote(sale, customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(sale);
        return fetch(
            `${serverAddress}/api/vdhp/accounting/cn/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingInvoiceSales(customer, date, invoiceId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&customer=${customer||''}&date=${date||''}&invoiceId=${invoiceId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },

    cancelAccountingInvoiceSale(invoiceId, reason){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&voucher_id=${invoiceId||''}&reason=${reason||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/cancel`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingSales(offset, limit, sort, sort_order, search_field, search_type, search, filter,range,networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}`+
            `&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range) || '{}'}`;

        return sh.hit('/api/vdhp/accounting/invoice/get/'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/invoice/get`, 'POST', data)
    },
    getAccountingCreditNote(offset, limit, sort, sort_order, search_field, search_type, search, filter,range,networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}`+
            `&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range) || '{}'}`;

        return sh.hit('/api/vdhp/accounting/cn/get/'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/cn/get`, 'POST', data)
    },
    accountingInvoiceCreateQuotation(sale, customer, edit){
        // sale['customer'] = customer;
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(sale);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/quotation/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingInvoiceCreateContract(sale, customer, edit){
        // sale['customer'] = customer;
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(sale);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/contract/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingInvoiceCreateDeleveryChallan(sale, customer, edit){
        // sale['customer'] = customer;
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(sale);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/dnote/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingQuotationToInvoice(sale, date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&invoiceGenerationDate=${date||''}&` + $.param(sale);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/quotation/invoice`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingCreatePurchase(purchase, customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(purchase);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/purchase/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingCreatePurchaseOrder(purchase, customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(purchase);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/po/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingCreateDebitNote(purchase, customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(purchase);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/dn/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },

    getAccountingSale(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/purchase/get', `${serverAddress}/api/vdhp/accounting/purchase/get`, 'POST', data)
    },
    getAccountingPurchaseFilters(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/accounting/purchase/get', `${serverAddress}/api/vdhp/accounting/purchase/get`, 'POST', data)
    },
    getNewPurchaseIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/purchase/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getNewPurchaseOrderIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/po/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getNewDebitNoteIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/dn/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },

    getAccountingPurchaseOrderFilters(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/accounting/po/get', `${serverAddress}/api/vdhp/accounting/po/get`, 'POST', data)
    },
    getAccountingDebitNoteFilters(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/accounting/dn/get', `${serverAddress}/api/vdhp/accounting/dn/get`, 'POST', data)
    },

    getAccountingDeleveryChallan(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        // let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&customer=${filter.customer||''}&month=${filter.date||''}&date=${filter.filterDate||''}&invoiceId=${filter.invoiceId||''}`;
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${ search || '' }&filter=${JSON.stringify(filter)||'{}'}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/dnote/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingInvoiceQuotation(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        // let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&customer=${filter.customer||''}&month=${filter.date||''}&date=${filter.filterDate||''}&invoiceId=${filter.invoiceId||''}`;
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${ search || '' }&filter=${JSON.stringify(filter)||'{}'}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/quotation/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingInvoiceContract(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        // let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&customer=${filter.customer||''}&month=${filter.date||''}&date=${filter.filterDate||''}&invoiceId=${filter.invoiceId||''}`;
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${ search || '' }&filter=${JSON.stringify(filter)||'{}'}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/contract/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    addAccountingInvoiceQuotationUpdate(update, invoiceId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&quotation=${invoiceId||''}&` + $.param(update);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/quotation/update/add`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingPaymentDetails(invoiceId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&invoiceId=${invoiceId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/payment/details`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    addAccountingPaymentDetails(invoiceId, paymentDetails){
        alert(paymentDetails);
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&invoiceId=${invoiceId||''}&paymentId=${paymentDetails.paymentId||''}`+
        `&date=${paymentDetails.date||''}&paymentAmount=${paymentDetails.amount||''}&tdsAmount=${paymentDetails.tdsAmount||''}&gstTdsAmount=${paymentDetails.gstTdsAmount||''}`+
        `&paymentMode=${paymentDetails.paymentMode||''}&referenceNumber=${paymentDetails.referenceNumber||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/payment/add`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingInvoiceIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getNewCreditNoteIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/cn/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingQuotationIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/quotation/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingContractIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/contract/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingDeleveryChallanIds(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/dnote/id/new`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    markAccountingInvoiceSent(invoiceId){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        alert(invoiceId)

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&invoiceId=${invoiceId||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/invoice/sent`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingVendorCreate(vendor, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(vendor);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/vendor/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingVendors(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/vendor/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    accountingItemCreate(customer, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&` + $.param(customer);

        return fetch(
            `${serverAddress}/api/vdhp/accounting/item/create`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    getAccountingItems(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return fetch(
            `${serverAddress}/api/vdhp/accounting/item/get`,{
                method:'POST',
                body: data || '',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            }
        ).then((res)=>res.json())
    },
    // getOrderSlips(networkReqNAME,offset, limit, sort, sort_order, search_field, search_type, search, filter,range={},remove_filter){
    //     const token=localStorage.getItem('token');
    //     const user=localStorage.getItem('user');

    //     let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
    //         `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range)||''}&remove_filter=${JSON.stringify(remove_filter)}`;

    //     return sh.hit('/api/vdhp/order/slip/get/'+networkReqNAME, `${serverAddress}/api/vdhp/order/slip/get`, 'POST', data)
    // },
    getOrderSlipsReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter,range={}){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range)||''}`;
        return sh.hit('/api/vdhp/order/slip/report/get/'+networkReqNAME, `${serverAddress}/api/vdhp/order/slip/reports`, 'POST', data)
    },
    getOrderSlips(networkReqNAME="", offset, limit, sort, sort_order, search_field, search_type, search, filter={},range={},remove_filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range)||''}&remove_filter=${JSON.stringify(remove_filter)}`;

        return sh.hit('/api/vdhp/order/slip/get/'+networkReqNAME, `${serverAddress}/api/vdhp/order/slip/get`, 'POST', data)
    },
    createOrderSlip(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/order/slip/create', `${serverAddress}/api/vdhp/order/slip/create`, 'POST', data)
    },
    createOrderSlip(new_slip, edit, form_body){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;
        return fetch(`${serverAddress}/api/vdhp/order/slip/create?`+data, {
            method: 'POST',
            body: form_body||'',
        }).then(res=>res.json())
        // return sh.hit('/order/create'+networkReqNAME, `${serverAddress}/corp/order/create`, 'POST',data,form_body)
    },
    getVendors(offset, limit, sort, sort_order, search_field, search, filter,networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/vendor/get/'+networkReqNAME, `${serverAddress}/api/vdhp/vendor/get`, 'POST', data)
    },
    createVendor(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/vendor/create', `${serverAddress}/api/vdhp/vendor/create`, 'POST', data)
    },
    getCustomers(offset, limit, sort, sort_order, search_field, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;
        return sh.hit('/api/vdhp/customer/get', `${serverAddress}/api/vdhp/customer/get`, 'POST', data)
    },
    getLeadTransactions(offset, limit, sort, sort_order, search_field, search, filter,range){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}&range=${JSON.stringify(range)||''}`;
        return sh.hit('/api/vdhp/lead/transactions/get', `${serverAddress}/api/vdhp/lead/transactions`, 'POST', data)
    },
    getBranchesOfCustomer(search,networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&filter={"customer":${search}}`;

        return sh.hit('/api/vdhp/customer/branch/get/'+networkReqNAME, `${serverAddress}/api/vdhp/customer/branch/get`, 'POST', data)
    },
    createBranch(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/customer/branch/create', `${serverAddress}/api/vdhp/customer/branch/create`, 'POST', data)
    },
    createCustomer(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/customer/create', `${serverAddress}/api/vdhp/customer/create`, 'POST', data)
    },
    getDepartments(offset, limit, sort, sort_order, search_field, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/department/get', `${serverAddress}/api/vdhp/department/get`, 'POST', data)
    },
    createDepartment(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/department/create', `${serverAddress}/api/vdhp/department/create`, 'POST', data)
    },
    getOrderSlipUpdates(offset, limit, sort, sort_order, search_field, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/order/slip/update/get', `${serverAddress}/api/vdhp/order/slip/update/get`, 'POST', data)
    },
    createOrderSlipUpdate(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/order/slip/update/create', `${serverAddress}/api/vdhp/order/slip/update/create`, 'POST', data)
    },
    getOrderSlipComment(offset, limit, sort, sort_order, search_field, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/order/slip/comment/get', `${serverAddress}/api/vdhp/order/slip/comment/get`, 'POST', data)
    },
    createOrderSlipComments(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/order/slip/comment/create', `${serverAddress}/api/vdhp/order/slip/comment/create`, 'POST', data)
    },
    getGeneratedOrderInvoice(order_id){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&order_id=${order_id}`;

        return sh.hit('/api/vdhp/order/slip/invoice/generated', `${serverAddress}/api/vdhp/order/slip/invoice/generated`, 'POST', data)
    },

    getOrderSlipPurchases(offset, limit, sort, sort_order, search_field, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||''}`;

        return sh.hit('/api/vdhp/order/slip/purchase/get', `${serverAddress}/api/vdhp/order/slip/purchase/get`, 'POST', data)
    },
    createOrderSlipPurchase(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/order/slip/purchase/create', `${serverAddress}/api/vdhp/order/slip/purchase/create`, 'POST', data)
    },
    getStaffs(offset, limit, sort, sort_order, search_field, search, filter,networkReqNAME=""){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search=${search||''}&filter=${JSON.stringify(filter)||{}}`;

        return sh.hit('/api/vdhp/staff/get/'+networkReqNAME, `${serverAddress}/api/vdhp/staff/get`, 'POST', data)
    },
    createStaff(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit || 0}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/staff/create', `${serverAddress}/api/vdhp/staff/create`, 'POST', data)
    },
    createAccountingMastersGroup(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/masters/group/create', `${serverAddress}/api/vdhp/accounting/masters/group/create`, 'POST', data)
    },
    getAccountingMastersGroup(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/masters/group/get', `${serverAddress}/api/vdhp/accounting/masters/group/get`, 'POST', data)
    },
    getAccountingMastersGroupAll(){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return sh.hit('/api/vdhp/accounting/masters/group/all', `${serverAddress}/api/vdhp/accounting/masters/group/all`, 'POST', data)
    },
    createAccountingMastersLedger(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/masters/ledger/create', `${serverAddress}/api/vdhp/accounting/masters/ledger/create`, 'POST', data)
    },
    getAccountingMastersLedger(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/masters/ledger/get', `${serverAddress}/api/vdhp/accounting/masters/ledger/get`, 'POST', data)
    },
    createAccountingSale(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/invoice/create', `${serverAddress}/api/vdhp/accounting/invoice/create`, 'POST', data)
    },
    getAccountingSale(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/invoice/get', `${serverAddress}/api/vdhp/accounting/invoice/get`, 'POST', data)
    },
    createAccountingReceipt(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/receipt/create', `${serverAddress}/api/vdhp/accounting/receipt/create`, 'POST', data)
    },
    getAccountingReceipt(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/receipt/get', `${serverAddress}/api/vdhp/accounting/receipt/get`, 'POST', data)
    },
    getNewReceiptIds(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return sh.hit('/api/vdhp/accounting/receipt/id/new', `${serverAddress}/api/vdhp/accounting/receipt/id/new`, 'POST', data)
    },
    createAccountingPayment(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/payment/create', `${serverAddress}/api/vdhp/accounting/payment/create`, 'POST', data)
    },
    getAccountingPayment(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/payment/get', `${serverAddress}/api/vdhp/accounting/payment/get`, 'POST', data)
    },
    getNewPaymentIds(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return sh.hit('/api/vdhp/accounting/payment/id/new', `${serverAddress}/api/vdhp/accounting/payment/id/new`, 'POST', data)
    },
    createAccountingContra(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/contra/create', `${serverAddress}/api/vdhp/accounting/contra/create`, 'POST', data)
    },
    getAccountingContra(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/contra/get', `${serverAddress}/api/vdhp/accounting/contra/get`, 'POST', data)
    },
    getNewContraIds(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return sh.hit('/api/vdhp/accounting/contra/id/new', `${serverAddress}/api/vdhp/accounting/contra/id/new`, 'POST', data)
    },
    createAccountingJournal(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/accounting/journal/create', `${serverAddress}/api/vdhp/accounting/journal/create`, 'POST', data)
    },
    getAccountingJournal(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/journal/get', `${serverAddress}/api/vdhp/accounting/journal/get`, 'POST', data)
    },
    getNewJournalIds(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`;

        return sh.hit('/api/vdhp/accounting/journal/id/new', `${serverAddress}/api/vdhp/accounting/journal/id/new`, 'POST', data)
    },
    getAccountingLedgerVouchers(offset, limit, sort, sort_order, search_field, search_type, search, filter, start_date, end_date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&start_date=${start_date||''}&end_date=${end_date||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/ledger/vouchers/get', `${serverAddress}/api/vdhp/accounting/ledger/vouchers/get`, 'POST', data)
    },
    addProductsToInvoice(voucher_id, purchase_id, order_id, products){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&invoice_id=${voucher_id || ''}&purchase_id=${purchase_id || ''}`+
        `&order_id=${order_id || ''}&`+$.param(products);

        return sh.hit('/api/vdhp/accounting/invoice/product/add', `${serverAddress}/api/vdhp/accounting/invoice/product/add`, 'POST', data)
    },
    createLead(new_slip, edit){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');
        new_slip = encodeURIComponent(JSON.stringify(new_slip))

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&edit=${edit||''}&new_data_row=${new_slip}`;

        return sh.hit('/api/vdhp/lead/create', `${serverAddress}/api/vdhp/lead/create`, 'POST', data)
    },
    getLead(offset, limit, sort, sort_order, search_field, search_type, search, filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/lead/get', `${serverAddress}/api/vdhp/lead/get`, 'POST', data)
    },
    getLead(networkReqNAME, offset, limit, sort, sort_order, search_field, search_type, search, filter, range={}){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range)||'{}'}`;

        return sh.hit('/api/vdhp/lead/get/' + networkReqNAME, `${serverAddress}/api/vdhp/lead/get`, 'POST', data)
    },
    getAccountingTrialBalance(offset, limit, sort, sort_order, search_field, search_type, search, filter, start_date, end_date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&start_date=${start_date||''}&end_date=${end_date||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/accounting/reports/trial/get', `${serverAddress}/api/vdhp/accounting/reports/trial/get`, 'POST', data)
    },
    getFinancialReports(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, start_date, end_date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&search_field=${search_field||''}&search_type=${search_type||''}&search=${search||''}&start_date=${start_date||''}&end_date=${end_date||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/finance/reports/get/'+networkReqNAME, `${serverAddress}/api/vdhp/finance/reports/get`, 'POST', data)
    },
    getLeadComment(networkReqNAME="",filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/lead/comment/get/'+networkReqNAME, `${serverAddress}/api/vdhp/lead/comment/get`, 'POST', data)
    },
    createLeadComment(networkReqNAME="",new_data_row){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`+
            `&new_data_row=${JSON.stringify(new_data_row)||'{}'}`;

        return sh.hit('/api/vdhp/lead/comment/create/'+networkReqNAME, `${serverAddress}/api/vdhp/lead/comment/create`, 'POST', data)
    },
    getCustomerUser(networkReqNAME="",filter){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}`;

        return sh.hit('/api/vdhp/customer/user/get/'+networkReqNAME, `${serverAddress}/api/vdhp/customer/user/get`, 'POST', data)
    },
    createCustomerUser(networkReqNAME="",new_data_row){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}`+
            `&new_data_row=${JSON.stringify(new_data_row)||'{}'}`;

        return sh.hit('/api/vdhp/customer/user/create/'+networkReqNAME, `${serverAddress}/api/vdhp/customer/user/create`, 'POST', data)
    },
    getDueReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}`;

        return sh.hit('/api/vdhp/accounting/report/due/get'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/reports/due/get`, 'POST', data)
    },
    getVendorDueReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}`;

        return sh.hit('/api/vdhp/accounting/report/vendor/due/get'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/reports/vendor/due/get`, 'POST', data)
    },
    getVendorCycleReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}`;

        return sh.hit('/api/vdhp/accounting/report/vendor/cycle/get'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/reports/vendor/cycle/get`, 'POST', data)
    },
    getDaybookReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/report/daybook'+networkReqNAME, `${serverAddress}/api/vdhp/dashboard/daybook`, 'POST', data)
    },
    getDashboardOrderReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/report/order/dash'+networkReqNAME, `${serverAddress}/api/vdhp/dashboard/orders`, 'POST', data)
    },
    getDashboardInventoryReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}`+
            `&response_limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}&search=${search||''}&search_field=${search_field||''}&search_type=${search_type||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/report/Inventory'+networkReqNAME, `${serverAddress}/api/vdhp/dashboard/inventory`, 'POST', data)
    },
    getCustomerAnalytics(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}`+
            `&response_limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}&search=${search||''}&search_field=${search_field||''}&search_type=${search_type||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/analytics/customer', `${serverAddress}/api/vdhp/analytics/customers`, 'POST', data)
    },
    getDashboardEarningsReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/report/earnings'+networkReqNAME, `${serverAddress}/api/vdhp/dashboard/earnings`, 'POST', data)
    },
    getDashboardCustomerReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/report/customer'+networkReqNAME, `${serverAddress}/api/vdhp/dashboard/customers`, 'POST', data)
    },
    getGstReport(networkReqNAME="",offset, limit, sort, sort_order, search_field, search_type, search, filter, range,date){
        const token=localStorage.getItem('token');
        const user=localStorage.getItem('user');

        let data = `phone=${user||''}&monitor_city=${monitor_city ||  ''}&token=${token||''}&offset=${offset||''}&limit=${limit||''}&sort=${sort||''}&sort_order=${sort_order||''}`+
            `&filter=${JSON.stringify(filter)||'{}'}&range=${JSON.stringify(range) || '{}'}&date=${date}`;

        return sh.hit('/api/vdhp/accounting/reports/gstr1/get'+networkReqNAME, `${serverAddress}/api/vdhp/accounting/reports/gstr1/get`, 'POST', data)
    },

}
export default Client;
