import React, { Component } from 'react';
import {Route,Switch} from 'react-router-dom';
import './App.css';
import PageContainer from './routes/page-container';
import Login from "./routes/login";
import PrivateRoute from "./components/private-router";
import LogOut from "./routes/logout";
import PrintOrders from "./routes/print-orders";
import PrintBookings from "./routes/print-bookings";
// import PrivateLoginRoute from "./components/private-login-route";

class App extends Component {
render() {
    return (
        <div>
            <Switch>
                <Route path='/login' component={Login} />
                <Route path="/logout" component={LogOut} />
                <Route path='/print/bookings' component={PrintBookings} />
                <Route path='/print/orders' component={PrintOrders} />
                <PrivateRoute path='/' component={PageContainer} />
            </Switch>
        </div>
    );
  }
}

export default App;
