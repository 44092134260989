import React, {Component} from 'react';

class VendorSuggestion extends Component{

	handleClick = () => {
		this.props.click(this.props.vendor.name, this.props.vendor.id);
	}

	render(){
		return(
	    	<div style={{padding: "10px 10px", backgroundColor: "#dddddd"}} onClick={this.handleClick}>{this.props.vendor.phone}&nbsp;({this.props.vendor.name})</div>
        )
	}
}

export default VendorSuggestion;