import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import XLSX from 'xlsx'
import {saveAs} from 'file-saver';
import {Input, Table, Dropdown,Button, TextArea,Modal,Segment,Loader,Dimmer,Image, Form, Checkbox} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import Inventory from "./inventory";
// import CreateAccountingQuotation from './create-accounting-quotation-archieved';

class CreateDeleveryChallan extends Component{

	constructor(){
		super();
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
		    dd = '0'+dd
		}
		if(mm<10) {
		    mm = '0'+mm
		}
		today = yyyy + '-' + mm + '-' + dd;
		this.state = {
			sale: {
				invoiceId: "",
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A'}],
				subTotal: 0,
				roundOff: 0,
				total: 0,
				orderId: null,
				date: today,
			},
			new_data_row: {
				voucher_id: "",
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A'}],
				taxable_amount: 0,
				round_off: 0,
				total: 0,
				order_id: null,
				date: today,
				remarks: '',
				contact_person: 'Name: Deskneed (Science2home Pvt. Ltd.).\nPhone: +91-9954683549.\nEmail: contact@deskneed.com.',
				challan_mode: false
			},
			taxDetails: [],
			searchCustomers: [],
			customer: {name:''},
			searchProducts: [{productId: 0, name: 'CREATE NEW', text:'CREATE NEW', value:0}],
			createProduct: false,
			options_voucher_id: [],
			options_branch:[
			],
			options_invoice_id:[]
		}
		this.fileInputRef = React.createRef();
		this.excelToJson = this.excelToJson.bind(this)
	}

	componentDidMount() {
		let order_id = localStorage.getItem('/accounting/vouchers/challan/create')
		if(order_id){
			this.handleInputChangeSale(null, {name:'order_id', value:order_id})
			localStorage.removeItem('/accounting/vouchers/challan/create')
		}
		// this.loadCustomers();
		this.loadInvoiceIds();

		var options_ledger = [];
		Client.getAccountingMastersLedger(null,null,null,null,null,null,null,{group:[151,152]}).then(res=>{
			res.data_rows.forEach(function(data_row){
				options_ledger.push({
					text: data_row.ledger_name,
					value: data_row.id
				})
			})
			this.setState({
				options_ledger: options_ledger
			})
		})

		console.log(localStorage.getItem('currentDelevery') ,localStorage.getItem('currentDeleveryEdit'))
		if(localStorage.getItem('currentDelevery') && localStorage.getItem('currentDeleveryEdit') == 1){
			var new_data_row = JSON.parse(localStorage.getItem('currentDelevery'));
			new_data_row.products.forEach(function(product,i) {
				if(product.product_id != undefined || product.product_id != null){
					product.options_product = [{text: product.name, value: product.product_id}]
					var options_description = [];
					product.options_description.forEach(function(description){
						options_description.push({
							text: description,
							value: description
						})
					})
					product.options_description = options_description
				}
				else{
					product.input_type = 'name'
					var options_description = [{
							text: product.description,
							value: product.description
						}]
					product.options_description = [{text:'Empty',value:undefined},...options_description]
				}
			})
			this.getCustomerBranches(new_data_row.customer);
			this.setState({
				new_data_row: new_data_row
			})

			this.calculateTax()
		}
	}

	componentWillUnmount(){
		localStorage.setItem('currentDeleveryEdit', 0);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	addProduct(e){
		e.preventDefault();
		var products = this.state.new_data_row.products;
		var new_data_row = this.state.new_data_row;
		new_data_row.products = [...products, {id:products.length, qty: 0, rate: 0, tax: 'N/A',input_type:this.state.universal_type}]
		this.setState({
			new_data_row: new_data_row
		},()=>{
			setTimeout(	()=>{
				let container = document.getElementById('accounting-invoice-product-input-name-' + (new_data_row.products.length - 1))
				if (this.state.universal_type != 'name') {
					console.log(container);

					container.getElementsByTagName('input')[0].focus()
				} else {
					console.log(container);

					container.focus()
				}
			}
			,300)

		})
	}

	removeProduct(id){
		var products = [...this.state.new_data_row.products];
		var new_data_row = {...this.state.new_data_row};
		var products1 = []
		var found = 0;
		products.forEach(function(element, index) {
			if(index != id){
				products1.push(element)
			}
			else
				found = 1
		});
		new_data_row.products = [...products1]
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}

	handleInputChangeSale(e, data){
		var new_data_row = this.state.new_data_row;
		if (data.name == 'order_id') {
			this.setState({loading_form_order_id:true,orderSlipNotSpecified:false})
			Client.getOrderSlips('get',null,null,null,null,null,null,null,{slip_no:{filter_type:'value_like',value:data.value}})
			.then(res=>{
				if (res.data_rows_size > 0) {
					new_data_row.cust_branch = res.data_rows[0].branch?res.data_rows[0].branch:''
					new_data_row.customer = res.data_rows[0].customer?res.data_rows[0].customer:''
					this.getCustomerBranches(new_data_row.customer)
				} else {
					new_data_row.cust_branch = ''
					new_data_row.customer = ''
				}
				this.setState({new_data_row,loading_form_order_id:false})
			})
		}
		if(data.name == 'customer'){
			this.setState({orderSlipNotSpecified:true})
			delete new_data_row.cust_branch
			new_data_row.order_id = ""
			this.getCustomerBranches(data.value);
			this.setState({branch_issue:false})
		}
		if(data.name == 'cust_branch'){
			this.setState({orderSlipNotSpecified:true})
			delete new_data_row.branch
			new_data_row.order_id = ""
			data.value = data.value != '' ? data.value : undefined
			this.setState({branch_issue:false})
		}
		var value = data.value || e.target.value
		new_data_row[data.name] = value
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}
	handleInputChangeSaleTextArea(e, data){
		var sale = this.state.sale;
		sale[data.name] = e.target.value || data.value
		this.setState({
			sale: sale
		})

		this.calculateTax()
	}
	handleKeyDownRate(e, data, index){
		if(e.keyCode == 13){
			var products = this.state.new_data_row.products;
			var purchase = this.state.new_data_row;
			products[index]['rate'] = this.stringToCalculate(e.target.value)
			purchase.products = [...products]
			this.setState({
				purchase: purchase
			})

			this.calculateTax()
		}
	}

	stringToCalculate(value){
		return this.perfectDecimal(eval(value.substring(1, value.length)));
	}
	checkAll(){
		let new_universal_type = this.state.universal_type == 'name'?'id':'name'
		let new_data_row = this.state.new_data_row
		new_data_row.products = [{id: 0,input_type:new_universal_type}]
		this.setState({
			new_data_row,
			universal_type:new_universal_type,
		})
	}
	handleProductDescriptionAddition(index,e,data){
		var new_data_row = this.state.new_data_row;
		let products = new_data_row.products
		products[index].options_description.push({text:data.value,value:data.value})
		new_data_row['products']= products
		this.setState({new_data_row})
	}

	getCustomerBranches(customer){
		this.setState({
			showBranch: true,
			loading_branches: true
		})
		Client.getBranchesOfCustomer(customer).then((res)=>{
			let options_branch = []
			if (res.data_rows_size > 0) {
				res.data_rows.forEach(element => {
					let temp = {
						value: element.id,
						text: element.branch_name
					}
					options_branch.push(temp)
				})
				this.setState({
					options_branch: options_branch,
					loading_branches: false
				})
			} else {
				this.setState({
					showBranch: false,
					loading_branches: false
				})
			}
		})
	}

	handleInputChangeProduct(index, e, data){
		var value = data.value || e.target.value
		var allowCalculate = true
		var products = this.state.new_data_row.products;
		var purchase = this.state.new_data_row;
		var found = 0;

		if(data.name == "product_id"){
			var products = this.state.new_data_row.products;
			var purchase = this.state.new_data_row;
			var options_product = [...products[index].options_product]
			products[index]['product_id'] = data.value
			products[index]['qty'] = 1
			for(var i = 0; i < options_product.length; i++){
				var prod = options_product[i]
				if(prod.product_id == data.value){
					products[index]['name'] = prod.name
					products[index]['rate'] = prod.rate
					products[index]['tax'] = prod.tax
					products[index]['HSN'] = prod.HSN
					products[index]['options_description'] = [{text:'Empty',value:undefined}, ...prod.options_description.map(description=>{return{text:description,value:description}})]
					break;
				}
			}
		}
		else if(data.name == 'rate'){
			if(parseFloat(value) === value){
			}
			else{
				if(value.charAt(0) == "="){
					allowCalculate = false;
					// this.stringToCalculate(value)
				}
			}
			products[index][data.name] = e.target.value
		} else if(data.name == 'input_type'){
			console.log(products[index]);//debug
			delete products[index]
			products[index] = {id: 0, qty: 0, rate: 0, tax: 'N/A'}
			console.log(products[index]);//debug
			products[index][data.name] = data.value
			let flag_universal_type = 'name'
			products.forEach(product=>{
				if (product.input_type != 'name') {
					flag_universal_type = 'id'
				}
			})
			this.setState({universal_type:flag_universal_type})
		} else{
			products[index][data.name] = data.value
		}
		purchase.products = [...products]
		this.setState({
			purchase: purchase
		})
		console.log(data)
		if(allowCalculate)
			this.calculateTax()
	}

	handleProductAddition(index, e, { value }) {
		this.setState({
			createProduct: true,
			indexForNewProduct: index,
		})
	}

	handleInvoiceIdAdditions(e, {value}){
		this.setState({
			options_invoice_id: [
				...this.state.options_invoice_id,
				{text: value, value: value}
			],

		})
	}

	calculateTax(){
		setTimeout(() => {
			var new_data_row = this.state.new_data_row;
			var taxDetails = [];
			var taxable_amount = 0;
			var total = 0;
			var taxStateType = this.state.customer.pos == "AS" ? 0 : 1;
			this.state.new_data_row.products.forEach(function(element) {
				var taxDetail = {};
				var found = 0
				if(element.tax != 'N/A'){
					taxDetails.forEach(function(elem) {
						if(elem.taxPc == element.tax){
							found = 1;
							elem.taxStateType = taxStateType;
							elem.taxableAmount = Number(elem.taxableAmount) + Number((element.qty * element.rate).toFixed(2));
							// elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
							elem.taxAmount = (elem.taxableAmount * elem.taxPc / 100).toFixed(2);
							// elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
						}
					})
					if(found == 0){
						taxDetail['taxStateType'] = taxStateType;
						taxDetail['taxPc'] = element.tax;
						taxDetail['taxableAmount'] = (element.qty * element.rate).toFixed(2);
						// taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
						taxDetail['taxAmount'] = (taxDetail['taxableAmount'] * element.tax / 100).toFixed(2);
						// taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
						taxDetails.push(taxDetail)
					}
				}
				taxable_amount = taxable_amount + Number((element.qty * element.rate).toFixed(2));
			});

			taxDetails.forEach(function(elem) {
				total = total + Number(elem.taxAmount);
				console.log(total);
			});
			new_data_row.taxable_amount = taxable_amount.toFixed(2);
			new_data_row.total = (Number(total) + Number(taxable_amount)).toFixed(2);
			// new_data_row.total = parseFloat(Math.round(new_data_row.total * 100) / 100).toFixed(2);
			new_data_row.payable_amount = Math.round(new_data_row.total).toFixed(2);
			if(new_data_row.adjustment_name != undefined && new_data_row.adjustment_name != ''){
				new_data_row.payable_amount = Math.round(Number(new_data_row.payable_amount) + Number(new_data_row.adjustment_amount || 0)).toFixed(2);
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total) - Number(new_data_row.adjustment_amount)).toFixed(2);
			}
			else{
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total)).toFixed(2);
			}
			// new_data_row.round_off = parseFloat(Math.round(new_data_row.round_off * 100) / 100).toFixed(2);
			// new_data_row.payable_amount = parseFloat(Math.round(new_data_row.payable_amount * 100) / 100).toFixed(2);
			this.setState({
				taxDetails: taxDetails,
				new_data_row: new_data_row
			})
		},300)
	}

	searchCustomer(e, data){
		this.showCustomerSuggestions()
		this.loadCustomers(e.target.value)
	}

	handleRoundOffChange(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row.payable_amount = (Number(new_data_row.payable_amount || 0) - Number(new_data_row.round_off || 0)).toFixed(2);
		new_data_row.round_off = e.target.value
		new_data_row.payable_amount = (Number(new_data_row.payable_amount || 0) + Number(new_data_row.round_off || 0)).toFixed(2);
		this.setState({
			new_data_row: new_data_row
		})
	}

	searchProducts(index, e){
		var find = e != null ? e.target.value : ''
		var new_data_row = {...this.state.new_data_row}
		var products = [...new_data_row.products]
		products[index].searchingProducts = true
		new_data_row.products = products
		this.setState({
			new_data_row: new_data_row
		})
		Client.searchAccountingInvoiceProduct(find).then(res=>{
			var searchProducts = [...res.products];
			searchProducts.forEach(function(sc){
				sc['text'] = sc.name;
				sc['value'] = sc.product_id;
			})
			// this.state.new_data_row.products.forEach(function(product){
			// 	if(product.product_id != null && product.product_id != undefined && product.product_id != '')
			// 		searchProducts.push({...product, text: product.name, value: product.product_id})
			// })
			products[index].options_product = searchProducts
			products[index].searchingProducts = false
			new_data_row.products = products
            this.setState({
                // options_product: searchProducts,
				new_data_row: new_data_row,
				// searchingProducts: false
            })
        })
	}

	loadCustomers(){
		var options_record_ledgers = [];
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_record_ledgers.push({text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''), value: data_row.id})
			})
			this.setState({
				options_customer: options_record_ledgers,
			})
		})
	}

	loadInvoiceIds(){
		Client.getAccountingDeleveryChallanIds().then(res=>{
			var options_voucher_id = [];
			if(localStorage.getItem('currentDeleveryEdit') == 1){
				options_voucher_id.push({
					text: this.state.new_data_row.voucher_id,
					value: this.state.new_data_row.voucher_id
				})
			}
			res.invoiceIds.forEach(function(voucher_id){
				options_voucher_id.push({
					text: voucher_id,
					value: voucher_id
				})
			})
			this.setState({
                options_voucher_id: options_voucher_id,
            })
        })
	}

	selectCustomer(){
	}

	selectProduct(){
	}

	showCustomerSuggestions(hide){
		if(hide){
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "block";
		}
	}

	showProductSuggestions(index, hide){
		if(hide){
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "block";
		}
	}

	onKeyDown(index, e, searchIndex){
		if(e.target.name == "customer" || e.target.name == "customer-suggestions"){
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-customer-input').focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showCustomerSuggestions(1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				this.setState({
					customer: this.state.searchCustomers[searchIndex]
				})
				this.calculateTax()
				document.getElementById('accounting-invoice-customer-input').value = this.state.searchCustomers[searchIndex].name;
				document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
			}
		}
		else{
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-product-input-name' + index).focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showProductSuggestions(index, 1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				if(this.state.searchProducts[searchIndex]['name'] == "CREATE NEW"){
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
					this.setState({
						createProduct: true,
						indexForNewProduct: index,
					})
				}
				else{
					var products = this.state.new_data_row.products;
					var new_data_row = this.state.new_data_row;
					products[index] = this.state.searchProducts[searchIndex]
					products[index]['qty'] = 1
					new_data_row.products = [...products]
					this.setState({
						new_data_row: new_data_row
					})

					this.calculateTax();
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
				}
			}
		}
	}

	newProductCreated(product){
		var products = this.state.new_data_row.products;
		var new_data_row = this.state.new_data_row;
		products[this.state.indexForNewProduct] = product
		products[this.state.indexForNewProduct]['productId'] = product.itemId
		products[this.state.indexForNewProduct]['qty'] = 1
		new_data_row.products = [...products]
		this.setState({
			new_data_row: new_data_row,
			createProduct: false
		})
		this.calculateTax();
	}

	createSale(){
		var new_data_row = {...this.state.new_data_row}
		var products = new_data_row.products
		var flagInvalidForm = false;
		if (!this.state.new_data_row.challan_mode) {
			if (this.state.showBranch) {
				if (this.state.new_data_row.cust_branch == undefined) {
					this.setState({branch_issue:true})
					flagInvalidForm = true;
					return 0
				}
			}
			if (!this.state.customer) {
				flagInvalidForm = true;
				alert("Please Select Customer")
				return 0
			}
			if (!new_data_row.voucher_id || new_data_row.voucher_id.trim() == '') {
				flagInvalidForm = true;
				alert("Voucher # Must Be Selected")
				return 0
			}
			new_data_row.products.forEach((item, i) => {
				if (item.tax == 0 || item.tax == 'N/A') {
					if (window.confirm('There is product with 0% or N/A tax. Are you sure to proceed?')) {
						return true
					} else {
						flagInvalidForm = true;
						return false
					}
				}
			});
		}

		if(flagInvalidForm){
			alert("Errors in Voucher Creation. Please check data again.")
		}
		else{
			products.forEach(function(product){
				delete product.options_product
				delete product.options_description
				delete product.searchingProducts
			})
			new_data_row['products'] = products
			console.log(new_data_row);
			Client.accountingInvoiceCreateDeleveryChallan(this.state.new_data_row, this.state.customer, localStorage.getItem('currentDeleveryEdit')).then(res=>{
		        this.props.history.push('/accounting/vouchers/challan')
	        })
		}
	}
	handleClose(){
		this.setState({
			createProduct:false,
		})
	}
	//excel handler
	excelToJson(e){
		e.preventDefault();
		var oFile = e.target.files[0];
        var sFilename = oFile.name;
        var reader = new FileReader();
        var result = {};
		// this.setState({loading_excel:true})
        reader.onload =  (e) =>{
            var data = e.target.result;
            data = new Uint8Array(data);
            let workbook = XLSX.read(data, {type: 'array'});
			window.table = workbook.Sheets['Sheet1']
			let old_ref = workbook.Sheets['Sheet1']['!ref']
			let new_ref = 'A2:'+old_ref.split(':')[1]
			 workbook.Sheets['Sheet1']['!ref'] = new_ref
			var json_table = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet1']);
			let options_tax = this.state.options_tax
			json_table.forEach(elem=>{
				elem.input_type = 'name'

				elem.options_description = [{text:elem.description,value:elem.description}]
			})
			let new_data_row = this.state.new_data_row
			let user_entered_products = new_data_row.products.filter(elem=>{
				return elem.product_id || elem.product_name
			})
			new_data_row.products = [...user_entered_products,...json_table]
			this.setState({new_data_row,loading_excel:false})
        };
        reader.readAsArrayBuffer(oFile);
	}
	excelfyDataRows(e){
		e.preventDefault()
		let work_book = XLSX.utils.book_new()
		let work_sheet = XLSX.utils.aoa_to_sheet([
			 ['text'	,'number'	,'text'],
			 ['product_name','qty','description']
		 ])
		 XLSX.utils.book_append_sheet(work_book,work_sheet,"Sheet1")
		 let style_rule = {
			 fill: {
				 patternType: "solid", // none / solid
				 fgColor: {rgb: "FF000000"},
				 bgColor: {rgb: "0000FFFF"}
			 },
		 };
		 let style_head ={
			 font: {
						 name: 'Times New Roman',
						 sz: 18,
						 color: {rgb: "#FF000000"},
						 bold: true,
						 italic: false,
						 underline: false
					 },
		 }
		 //for rules
		 // work_book.Sheets['Sheet1']['A1'].s = style_rule
		 // work_book.Sheets['Sheet1']['B1'].s = style_rule
		 // work_book.Sheets['Sheet1']['C1'].s = style_rule
		 // work_book.Sheets['Sheet1']['D1'].s = style_rule
		 // work_book.Sheets['Sheet1']['E1'].s = style_rule
		 // work_book.Sheets['Sheet1']['F1'].s = style_rule
		 // //for header
		 // work_book.Sheets['Sheet1']['A2'].s = style_head
		 // work_book.Sheets['Sheet1']['B2'].s = style_head
		 // work_book.Sheets['Sheet1']['C2'].s = style_head
		 // work_book.Sheets['Sheet1']['D2'].s = style_head
		 // work_book.Sheets['Sheet1']['E2'].s = style_head
		 // work_book.Sheets['Sheet1']['F2'].s = style_head
		 let exported = XLSX.write(work_book,{bookType:'xlsx',type:'array'})
		 saveAs(new Blob([exported],{type:'application/octet-stream'}),'products_d_note.xlsx')
    }
	render(){
		var amountTableTaxData = <div></div>;
		this.state.taxDetails.forEach(function(taxDetail){
			if(taxDetail.taxStateType == 1)
			{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr>
						<td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount)}</td>
					</tr>
				</tbody>
			}
			else{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
					<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
				</tbody>
			}
		}.bind(this))

		return(
		    <div>
				{/*<div style={{width:'100%', height:'100%',padding:'10%', background:'rgba(0,0,0,0.8)', position:'fixed', top:0,left:0, zIndex:2000, visibility:this.state.createProduct ? 'visible' : 'hidden'}}>
					<div style={{height:'100%', overflowY:'scroll', background:'white', padding:'1%'}}>
						<img src={RemoveImage} width='25px' height="25px" onClick={()=>this.setState({createProduct:false})}/>
						<Inventory newProductCreated={(product)=>this.newProductCreated(product)}/>
					</div>
		</div>*/}
				<Modal
					open={this.state.createProduct}
					onClose={this.handleClose.bind(this)}
					size='large'>
					<Modal.Content scrolling>
						<Inventory newProductCreated={(product)=>this.newProductCreated(product)}/>
					</Modal.Content>
				</Modal>
				<Form size="mini" onSubmit={() => this.createSale()}>
				<Form.Group widths='equal'>
					<Form.Select
						options={this.state.options_voucher_id}
						allowAdditions
						search
						onAddItem={(e, value) => this.handleInvoiceIdAdditions(e, value)}
						type="text"
						name="voucher_id"
						placeholder="Invoice #" label="Invoice #" selection
						value={this.state.new_data_row.voucher_id}
						onChange ={(e, data) => this.handleInputChangeSale(e, data)}
						required/>
					<Form.Input placeholder='Order #' label="Order #" name="order_id" error={this.state.orderSlipNotSpecified} value={this.state.new_data_row.order_id}
						 onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				</Form.Group>
					<Checkbox toggle checked={this.state.new_data_row.challan_mode} onChange={(e, data) => this.setState({new_data_row: {...this.state.new_data_row, challan_mode: data.checked}})}/>
					{
					this.state.new_data_row.challan_mode == false
					?
 						this.state.loading_form_order_id
						?
						<Segment>
							<Dimmer active>
							<Loader />
							</Dimmer>
							<Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
						</Segment>
						:
						<Form.Group widths='equal'>
							<Form.Select selection search options={this.state.options_ledger} name='customer'
								className="accounting-invoice-input"
								id="accounting-invoice-customer-input"
								placeholder='Choose Customer'
								label="Customer"
								loading={this.state.loading_form_order_id}
								autoComplete="off"
								value={this.state.new_data_row.customer}
								onChange={(e, data) => this.handleInputChangeSale(e, data)}
								required/>
								{
									this.state.new_data_row.customer !=null && this.state.showBranch
									?
									<Form.Select
										required
										error={this.state.branch_issue}
										loading={this.state.loading_branch || this.state.loading_form_order_id}
										clearable selection search
										options={this.state.options_branch}
										name="cust_branch" value={this.state.new_data_row.cust_branch}
										label={this.state.branch_issue?"Branch (Please Select Branch)":"Branch"} onChange={(e, data) => this.handleInputChangeSale(e, data)}
									/>
									:
									null
								}
						</Form.Group>
					:
					<Form.Select selection search options={this.state.options_ledger} name='vendor'
						className="accounting-invoice-input"
						id="accounting-invoice-vendor-input"
						placeholder='Choose Vendor'
						label="Vendor"
						autoComplete="off"
						value={this.state.new_data_row.vendor}
						onChange={(e, data) => this.handleInputChangeSale(e, data)}
						required/>
					}
				<Form.Input type='date' placeholder="Invoice Date" label="Invoice Date" name="date" value={this.state.new_data_row.date}
					 onChange={(e, data) => this.handleInputChangeSale(e, data)}
					 required/>
				{/* <TextArea value={this.state.new_data_row.contact_person} placeholder="Contact Person" name="contact_person"
				className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)} style={{width:'50%'}}/>
				<TextArea value={this.state.sale.vendor_details} placeholder="Vendor Details" name="vendor_details"
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)} style={{width:'50%'}}/> */}
				<Table compact className="accounting-invoice-product-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								<Checkbox checked={this.state.universal_type=='name'} onClick={(e)=>{this.checkAll()}}/>
							</Table.HeaderCell>
							<Table.HeaderCell>ITEM</Table.HeaderCell>
							<Table.HeaderCell>HSN</Table.HeaderCell>
							<Table.HeaderCell>QTY</Table.HeaderCell>
							<Table.HeaderCell>RATE</Table.HeaderCell>
							<Table.HeaderCell>TAX</Table.HeaderCell>
							<Table.HeaderCell>AMOUNT</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
					{
						!this.state.loading_excel
						?
						this.state.new_data_row.products.map((product, index) => (
							<Table.Row>
								<Table.Cell>
									<Checkbox checked={product.input_type == 'name'} onClick={(e)=>{this.handleInputChangeProduct(index,e,{name:'input_type',value:product.input_type == 'name'?'id':'name'})}}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									{
										product.input_type == 'name' && (product.product_id == null || product.product_id == '')
										?
										<Form.Input
											placeholder="Product Name"
											className="accounting-invoice-product-input"
											name="product_name"
											autoComplete="off"
											value={product.product_name || product.name}
											id={"accounting-invoice-product-input-name-" + index}
											onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
										:
										<Form.Select search options={product.options_product}
											placeholder="Product Name" name="product_id" autoComplete="off"
											className="accounting-invoice-product-input"
											id={"accounting-invoice-product-input-name-" + index}
											allowAdditions
											onSearchChange={(e) => this.searchProducts(index, e)}
											onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
											onAddItem={(e, value) => this.handleProductAddition(index, e, value)}
											value={product.product_id}
											loading={this.state.searchingProducts}/>

									}

									<Dropdown
										options={product.options_description}
										placeholder="Description"
										allowAdditions
										search
										className="accounting-invoice-product-input"
										selection
										name="description"
										onAddItem={(e, value) => this.handleProductDescriptionAddition(index, e, value)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										value={product.description || ''}
										/>
									{/* <Form.Input value={product.description} placeholder="Description" name="description"
										 onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/> */}
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.HSN || ''} placeholder="HSN" name="HSN"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.qty || ''} placeholder="Quantity" name="qty"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.rate || ''} placeholder="Rate" name="rate"
										onKeyDown={(e, data) => this.handleKeyDownRate(e, data, index)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.tax || ''} placeholder="Tax %" name="tax"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={ product.rate ? this.perfectDecimal(product.qty * product.rate) : ''} placeholder="Tax %" name="amount"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<img src={RemoveImage} onClick={() => this.removeProduct(index)} style={{width:'25px', height:'25px'}}/>
								</Table.Cell>
							</Table.Row>
						))
						:
						<Table.Row>
							<Table.Cell colSpan={7}>
								<Segment>
									<Dimmer active>
									<Loader size='medium'>Loading</Loader>
									</Dimmer>
									<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
								</Segment>
							</Table.Cell>
						</Table.Row>

					}
					</Table.Body>
				</Table>
				<div style={{display:'flex',justifyContent:'space-between'}}>
					<Button primary onClick={(e) => this.addProduct(e)}>Add Product</Button>
					<span>
						<Button
							color="black"
							content="Choose File"
							labelPosition="left"
							icon="file"
							onClick={(e) => {
								e.preventDefault()
								this.fileInputRef.current.click(e)
							}}
						/>
						<input
							ref={this.fileInputRef}
							type="file"
							hidden
							onChange={(e)=>{this.excelToJson(e)}}
						/>
						<Button labelPosition="left" onClick={(e) => this.excelfyDataRows(e)} icon=" file">Sample File</Button>
					</span>
				</div>
				<TextArea value={this.state.new_data_row.remarks} placeholder="Remarks" name="remarks"
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<div style={{display:'inline-block', float:'right', marginRight:'20px'}}>
				<table>
					<tr><td>Sub Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.taxable_amount}</td></tr>
					{
						this.state.taxDetails.map((taxDetail) => (
							taxDetail.taxStateType == 1
							?
							<tbody>
								<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount)}
								</td></tr>
							</tbody>
							:
							<tbody>
								<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
								<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
							</tbody>
						))
						//amountTableTaxData
					}
					<tr><td>Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.total}</td></tr>
					<tr>
						<td>
						<Input value={this.state.new_data_row.adjustment_name} placeholder="Adjustment" name="adjustment_name"
							className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
						<td>
						<Input value={this.state.new_data_row.adjustment_amount} placeholder="Amount" name="adjustment_amount" type="number" step="any"
							className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
					</tr>
					<tr>
						<td>Round Off</td>
						<td>
							<Form.Input value={this.state.new_data_row.round_off} placeholder="Round" name="round_off" type="number" step="any"
								 onChange={(e, data) => this.handleRoundOffChange(e, data)} required/>
						</td>
					</tr>
					<tr><td>Payable Amount</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.payable_amount}</td></tr>
				</table>
				<Form.Button primary type='submit'>GENERATE CHALLAN</Form.Button>
				</div>
				</Form>
			</div>
        )
	}
}

export default CreateDeleveryChallan;
