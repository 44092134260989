import React,{Component} from  'react';
import Client from "../services/Client";
import StoreDetails from '../components/storeDetailsContainer'
import AddShopForm from "../components/add-shop-form";
import TopBarInside from "../components/topbar-inside";
import Calender from "../components/calender"
import Daydetails from "../components/day-details"
import { Dropdown, Form,Icon,Input,Divider, Grid, Segment,Image,Modal, Loader, Label , Dimmer, Button, GridColumn, Table } from 'semantic-ui-react'
import { BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
import SaleView from './sale-voucher-view';
import AccountingLedgerVouchers from './accounting-ledger-vouchers'
// import '../style/home.css';
import '../style/due.css'
// import '../style/attendance.css';
import moment from 'moment';
import QuotationView from './quotation-view';
import DeleveryChallanView from './d-note-view';
import PurchaseView from './purchase-view';
import ReceiptView from './receipt-view';

class Due extends Component{
    constructor(props){
        super(props);
        var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
            start_date: '2019-04-01',
			end_date: '2020-03-31',
            defaultSize: 'mini',
			data_rows: [],
			today: dateVaue,
			observed_payment: {records: [{}], date: dateVaue},
            left_table_fields:[
                {text:'Invoice No.',value:'voucher_id'},
                {text:'Date',value:'date'},
                {text:'amount',value:'payable_amount'},
                {text:'Due By',value:'due_by'},
                {text:'Due Amount',value:'due_amount'},
            ],
            right_table_fields:[
                {text:'Payment Date',value:'date'},
                {text:'Payment Amount',value:'payable_amount'},
                {text:'Payment Account',value:'account'},
            ],
            options_ledger:[],
            reports:[

            ],
            unmarked_payments:[

            ],
            new_unmarked_row:{records:[]},
            // observed_payment:{},
            // new_data_row:{}
            reports_sort:'asc',
            unmarked_payments_sort:'asc',
            reports_sort_key:'date',
            unmarked_payments_sort_key:'date',
            currentDataRow:{}
        }
        this.accountIdToName = this.accountIdToName.bind(this)
    }
    componentDidMount(){
        let options_ledger=[]
        var banks = [7,10];
        var options_voucher_id = [];
        var options_record_ledgers = []
        // Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
		// 	res.data_rows.forEach(function(data_row) {
		// 	options_ledger.push({text: data_row.ledger_name, value: data_row.id})
		// 	})
		// 	this.setState({
		// 		options_ledger: options_ledger,
		// 	})
        // })
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach((data_row)=> {
                options_ledger.push({text: data_row.ledger_name, value: data_row.id})
				if(!banks.includes(data_row.group)){
					options_record_ledgers.push({text: data_row.ledger_name, value: data_row.id})
				}
			})
			this.setState({
				options_ledger: options_ledger,
				options_record_ledgers: options_record_ledgers,
				options_to_ledger: options_record_ledgers
			},()=>{
                if (this.props.customer) {
                    this.consoleDataChange({},{name:'customer',value:this.props.customer})
                }
            })
		})
		Client.getNewReceiptIds().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_voucher_id.push({
					text: data_row,
					value: data_row
				})
			})
			this.setState({
				options_voucher_id: options_voucher_id
			})
        })
    }
    showPaymentModal(i){
        let observed_payment = this.state.unmarked_payments[i]
        this.setState({loading_unmarked:true,show_payment_modal:true})
        Client.getAccountingReceipt(null,null,null,null,null,null,null,{voucher_id:[observed_payment.voucher_id]}).then(res=>{

            this.setState({
                new_unmarked_row:res.data_rows[0] || {records:[]},
                // show_payment_modal:true
                loading_unmarked:false,
                editMode:true
            })
        })
    }
    // getPayments(){
	// 	Client.getAccountingPayment(null,null,null,null,null,null,null,{ledger:[this.state.customer]}).then(res=>{
	// 		this.setState({
	// 			payments: res.data_rows,
	// 			// data_rows_size: res.data_rows_size
	// 		})
	// 	})
    // }
    getPayments(state){
		Client.getDueReport(null,null,null,state.reports_sort_key,state.reports_sort,null,null,null,{customer:[state.customer]}, {date:[state.start_date, state.end_date]}).then(res=>{
			this.setState({
                unmarked_payments:res.unmarked_payments,
				reports: res.data_rows,
			})
		})
	}
    consoleDataChange(e,data){
        this.setState({[data.name]:data.value},()=>{
            // this.getData()
            this.getPayments(this.state)
        })
    }
    showDataRowDetails(i){
        let currentDataRow = this.state.reports[i]
        this.setState({loading_invoice:true,showSaleDetailsModal:true,modal_type:currentDataRow.type})
        switch (currentDataRow.type) {
            case 'sale':
                    Client.getAccountingSales(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]})
                    .then(res=>{
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
                    break;
                case 'quotation':
                        Client.getAccountingInvoiceQuotation(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                        .then(res=>{
                            this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                        })
                        break;
                case 'purchase':
                    Client.getAccountingPurchaseFilters(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                    .then(res=>{
                        console.log(res.data_rows)
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
                    break;
                case 'delivery_note':
                    Client.getAccountingDeleveryChallan(null,null,null,null,null,null,null,{voucher_id:[currentDataRow.voucher_id]},null,'sale')
                    .then(res=>{
                        console.log(res.data_rows)
                        this.setState({currentDataRow:res.data_rows[0],loading_invoice:false})
                    })
                    break;
            default:
                break;
        }
    }
    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_unmarked_row = {...this.state.new_unmarked_row}
		new_unmarked_row[data.name] = data.value;
		this.setState({
			new_unmarked_row: new_unmarked_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
    updated(){
		// this.setState({
		// 	show_payment_modal: true
		// })
		// var state = this.state
		// Client.createAccountingReceipt(state.new_unmarked_row, this.state.editMode).then(res=>{
			this.setState({
				show_payment_modal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_unmarked_row: {records: [{}]}
			},()=>{

                this.getPayments(this.state);
            })
		// })
	}
    handleCancel(voucher){
        let data_rows = this.state.reports.map(elem=>{
            if (elem.voucher_id == voucher.voucher_id) {
                return {...elem,...voucher}
            } else {
                return elem
            }
        })
        this.setState({reports:data_rows})
    }
    accountIdToName(id){
        let name = this.state.options_ledger.find(elem=>{return elem.value == id})
        return name.text
    }
    changeStateVariableRecord(e, data, index){
		var records = [...this.state.new_unmarked_row.records];
		var new_unmarked_row = this.state.new_unmarked_row;
		records[index][data.name] = data.value || e.target.value
		new_unmarked_row.records = [...records]
		if(data.name == "ledger"){
			this.getRecordReferences(new_unmarked_row, records, index);
		}
		if(data.name == "against_reference"){
			records[index]['options_references'].forEach(function(reference){
				if(reference.value == data.value){
					records[index]['amount'] = reference.payable_amount
				}
			})
		}
		this.setState({
			new_unmarked_row: new_unmarked_row
		})
		this.makeCalculations(new_unmarked_row);
	}

	getRecordReferences(new_data_row, records, index){
		records[index]['loadingReferences'] = true
		this.setState({
			new_data_row: new_data_row
		})
		Client.getAccountingSales(null, null, 'date', 'desc', null, null, null,
		{'customer': [records[index]['ledger']], 'type': ['sale'], 'status': ['created', 'due', 'follow', 'unpaid']}).then((res) => {
			records[index]['loadingReferences'] = false
			var options_references = [{text: 'ON ACCOUNT', value: null}]
			res.data_rows.forEach(function(data_row) {
				options_references.push({...data_row, text: data_row.voucher_id + ' - ' + data_row.date + ' - ' + data_row.payable_amount, value: data_row.voucher_id})
			})
			records[index]['options_references'] = options_references
			new_data_row.records = [...records]
			this.setState({
				new_data_row: new_data_row
			})
		})
	}

	addRecord(e){
		e.preventDefault();
		var records = this.state.new_unmarked_row.records;
		var new_unmarked_row = this.state.new_unmarked_row;
		new_unmarked_row.records = [...records, {}]
		this.setState({
			new_unmarked_row: new_unmarked_row
		})
		setTimeout(() => {
			document.getElementById('accounting-receipt-record-ledger' + (new_unmarked_row.records.length - 1)).getElementsByTagName('input')[0].focus()
		},300)
		this.makeCalculations(new_unmarked_row);
	}

	removeRecord(index){
		var records = [...this.state.new_unmarked_row.records];
		var new_unmarked_row = {...this.state.new_unmarked_row};
		var records1 = []
		records.forEach(function(element, index1) {
			if(index1 != index){
				records1.push(element)
			}
		});
		new_unmarked_row.records = [...records1]
		this.setState({
			new_unmarked_row: new_unmarked_row
		})
		this.makeCalculations(new_unmarked_row);
	}

	makeCalculations(new_unmarked_row){
		var records_total = 0;
		new_unmarked_row.records.map(function(record){
			records_total = records_total + parseFloat(record.amount)
		})
		new_unmarked_row.records_total = records_total
		this.setState({
			new_unmarked_row: new_unmarked_row
		})
	}

	handleVoucherIdAdditions(e, {value}){
		this.setState({
			options_voucher_id: [
				...this.state.options_voucher_id,
				{text: value, value: value}
			]
		})
    }
    handleSort(tableName,key){
        console.log(tableName,key);

        var state = {...this.state}
        let table_data = state[tableName]
        let sort= state[tableName+'_sort']

        let sort_key = state[tableName+'_sort_key']
        if(key == sort_key){
            sort = sort == 'asc'? 'desc' : 'asc'
        }
         table_data.sort((a,b)=>{
            //manage type conflicts'
            let first, second ;
            if (key == 'payable_amount' || key == 'due_amount') {
                 first = parseFloat(a[key])
                 second = parseFloat(b[key])
            } else {
                 first = a[key]
                 second = b[key]
            }
            if (key== 'due_by') {
                if ( sort == 'asc') {
                    return (new Date(this.state.today) - new Date( a['date']))/ (1000 * 3600 * 24) > (new Date(this.state.today) - new Date( b['date']))/ (1000 * 3600 * 24)?1:-1
                } else {
                    return (new Date(this.state.today) - new Date( a['date']))/ (1000 * 3600 * 24) < (new Date(this.state.today) - new Date( b['date']))/ (1000 * 3600 * 24)?1:-1
                }
            } else {
                if ( sort == 'asc') {
                    return first > second ?1:-1
                } else {
                    return first < second?1:-1
                }
            }
        })
        console.log(sort,sort_key,key,table_data);

        state[tableName] = table_data
        state[tableName+'_sort'] = sort
        state[tableName+'_sort_key'] =key
        this.setState(state)
        // this.getPayments(state)
    }

	handleDateRangeChange(e, data){
		var state = {...this.state}
		state[data.name] = data.value || e.target.value
		this.setState(state)
		this.getDataRows(state);
	}

    render(){
        return (
            <>
                <span style={{display:'flex',justifyContent:'space-between'}}>
                    <span style={{display:'flex'}}>
                        <Dropdown

                            options={this.state.options_ledger}
                            selection
                            search
                            // inline
                            placeholder="Select a customer"
                            name="customer"
                            value={this.state.customer}
                            onChange={(e,data)=>{this.consoleDataChange(e,data)}}
                        />
                        <Button
                             disabled={!this.state.customer}
                             onClick={()=>this.setState({show_ledger_voucher:true})}
                             positive>
                            Show Ledger Vouchers
                        </Button>
                        <Button
                            icon="repeat"
                            negative
                            onClick={()=>this.getPayments(this.state)}
                        />
                    </span>
                    <div>
                        <Input
                            type="date" name="start_date" label={{ content: 'FROM' }}
                            placeholder="Start Date" value={this.state.start_date}
                            onChange={(e, data) => this.consoleDataChange(e, data)}
                            />
                        <Input
                            type="date" name="end_date" label={{ content: 'TO' }}
                            placeholder="End Date" value={this.state.end_date}
                            onChange={(e, data) => this.consoleDataChange(e, data)}
                            />
                    </div>
                </span>
                <Grid columns="equal" fluid wide className="no-pad">
                    <Grid.Column>
                        <Table  compact>
                            <Table.Header>
                                {
                                    this.state.left_table_fields.map(field=>(
                                        <Table.HeaderCell
                                            onClick={()=>{this.handleSort('reports',field.value)}}
                                        >
                                            {field.text}
                                            {
                                                this.state.reports_sort_key == field.value
                                                ?
                                                <Icon name={this.state.reports_sort == 'asc'?'angle up':'angle down'}/>
                                                :
                                                null
                                            }
                                        </Table.HeaderCell>
                                    ))
                                }
                            </Table.Header>
                            {
                                this.state.reports.map((invoice,i)=>(
                                    <Table.Row onClick={()=>{this.showDataRowDetails(i)}} style={{backgroundColor: invoice['due_amount'] <= invoice['payable_amount']*.021 ? 'white' : 'red'}}>
                                        {
                                            this.state.left_table_fields.map(field=>(
                                                field.value == 'due_by'
                                                ?
                                                <Table.Cell>
                                                {
                                                    (new Date(this.state.today) - new Date( invoice['date']))/ (1000 * 3600 * 24)
                                                }
                                                days
                                                </Table.Cell>
                                                :
                                                <Table.Cell>{invoice[field.value]}</Table.Cell>
                                            ))
                                        }
                                    </Table.Row>
                                ))
                            }
                        </Table>
                    </Grid.Column>
                    <Grid.Column>
                        <Table compact>
                            <Table.Header>
                                {
                                    this.state.right_table_fields.map(field=>(
                                        <Table.HeaderCell
                                        onClick={()=>{this.handleSort('unmarked_payments',field.value)}}
                                        >
                                            {field.text}
                                            {
                                                this.state.unmarked_payments_sort_key == field.value
                                                ?
                                                <Icon name={this.state.unmarked_payments_sort == 'asc'?'angle up':'angle down'}/>
                                                :
                                                null
                                            }
                                        </Table.HeaderCell>
                                    ))
                                }
                            </Table.Header>
                            {
                                this.state.unmarked_payments.map((payment,i)=>(
                                    <Table.Row
                                        onClick={()=>{this.showPaymentModal(i)}}
                                        >
                                        {
                                            this.state.right_table_fields.map(field=>(
                                                field.value == 'account'
                                                ?
                                                <Table.Cell>
                                                    {
                                                        this.accountIdToName(payment[field.value])
                                                    }
                                                </Table.Cell>
                                                :
                                                <Table.Cell>{payment[field.value]}</Table.Cell>
                                            ))
                                        }
                                    </Table.Row>
                                ))
                            }
                        </Table>
                    </Grid.Column>
                </Grid>
                <Modal size="large" open={this.state.showSaleDetailsModal} onClose={() => this.setState({showSaleDetailsModal:false, showCancelInvoiceOption: false,modal_type:false})}>
					<Modal.Content scrolling>
						{
                            this.state.loading_invoice
                            ?
                            <Segment>
                                <Dimmer active>
                                <Loader size='medium'>Loading</Loader>
                                </Dimmer>

                                <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                            </Segment>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'sale'
                            // true
                            ?
                            <SaleView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'quotation'
                            // true
                            ?
                            <QuotationView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'delivery_note'
                            // true
                            ?
                            <DeleveryChallanView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
                        {
                            (!this.state.loading_invoice) && this.state.modal_type == 'purchase'
                            // true
                            ?
                            <PurchaseView history={this.props.history} handleCancel={this.handleCancel.bind(this)} data_row={this.state.currentDataRow}/>
                            :
                            null
                        }
					</Modal.Content>

				</Modal>
                <Modal open={this.state.show_payment_modal}
					onClose={() => this.setState({show_payment_modal:false, new_unmarked_row: {records: [{}], date: this.state.today}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content scrolling>
                    {
                        this.state.loading_unmarked
                        ?
                        <Segment>
                            <Dimmer active>
                            <Loader size='medium'>Loading</Loader>
                            </Dimmer>

                            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                        </Segment>
                        :
                        <ReceiptView new_data_row={this.state.new_unmarked_row} editMode={this.state.editMode} updated={this.updated.bind(this)}/>
                    }
                    </Modal.Content>
				</Modal>
                {/* Ledger Vouchers */}
                <Modal
                    size="large"
                    open={this.state.show_ledger_voucher}
                    onClose={()=>this.setState({show_ledger_voucher:false})}
                    >
                    <Modal.Content scrolling>
                       <AccountingLedgerVouchers
                            currentLedger={this.state.customer}
                        />
                    </Modal.Content>
                </Modal>
            </>
        )
    }
}
export default Due;
