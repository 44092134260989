import React, {Component} from 'react';
import { Header, Modal, Table, Icon } from 'semantic-ui-react';

class OrderPndDetails extends Component{

    constructor(props){
        super(props);
        this.state = {
            open: false,
            list: this.props.list,
            order: this.props.order,
            cancelReason: "N/A",
            headOrder: this.props.headOrder
        };
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            list: nextProps.list,
            order: nextProps.order,
            headOrder: nextProps.headOrder
        })
    }

    handleListOpen = () => {
        this.setState({open: true});
    };

    handleListClose = () => {
        this.setState({open: false});
    };

    showTrackingLog(){
        this.setState({
            showBookingTracking: true
        })
    }

    render(){

        let total = 0;

        let products = [];

        if(this.state.open){
            return(
                <div>
                    <Icon name='time' size='big' onClick={() => this.showTrackingLog()}/>
                    <h3 onClick={this.handleListClose}>Pickup Details:</h3>
                    <div className="booking-visiting-content">
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Name:</strong></span>
                                <span className="column">{this.props.order.pickupAddress ? this.props.order.pickupAddress.name : null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Phone:</strong></span>
                                <span className="column">{this.props.order.pickupAddress ? this.props.order.pickupAddress.phone : null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Address:</strong></span>
                                <span className="column">{this.props.order.pickupAddress ? this.props.order.deliveryAddress.addressLine1 + ", " + this.props.order.deliveryAddress.address : null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Pickup Date And Time:</strong></span>
                                <span className="column">{this.props.order.pickupAddress ? this.props.order.pickupDateAndTime : null}</span>
                            </div>
                        </div>
                    </div>
                    <h3>Delivery Details:</h3>
                    <div className="booking-visiting-content">
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Name:</strong></span>
                                <span className="column">{this.props.order.deliveryAddress ? this.props.order.deliveryAddress.name: null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Phone:</strong></span>
                                <span className="column">{this.props.order.deliveryAddress ? this.props.order.deliveryAddress.phone : null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Address:</strong></span>
                                <span className="column">{this.props.order.deliveryAddress ? this.props.order.deliveryAddress.addressLine1 + ", " + this.props.order.deliveryAddress.address : null}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Delivery Date And Time:</strong></span>
                                <span className="column">{this.props.order.deliveryDateAndTime}</span>
                            </div>
                        </div>
                    </div>
                    <div className="booking-visiting-header">Payment Details:</div>
                    <div className="booking-visiting-content">
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Payment Mode:</strong></span>
                                <span className="column">{this.props.order.paymentMode}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Order Amount:</strong></span>
                                <span className="column">{this.props.order.orderAmount}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Delivery Charges:</strong></span>
                                <span className="column">{this.props.order.deliveryCharge}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui two column grid">
                                <span className="column thin"><strong>Total Amount:</strong></span>
                                <span className="column">{this.props.order.totalAmount}</span>
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={this.state.showBookingTracking}
                        onClose={() => this.setState({showBookingTracking: false})}
                        basic
                        size='small'
                        >
                        <Header icon='time' content='Tracking Details' />
                        <Modal.Content>
                        <Table celled>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>STATUS</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED ON</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED BY</Table.HeaderCell>
                                <Table.HeaderCell>REMARKS</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {
                                this.state.headOrder.orderTracking.length > 0
                                ?
                                this.state.headOrder.orderTracking.map((tracking) => (
                                    <Table.Row>
                                        <Table.Cell>
                                            {tracking.status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedOn}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedBy}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.reason}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                :
                                <Table.Row><Table.Cell>NO STATUS UPDATED YET</Table.Cell></Table.Row>
                            }
                            </Table.Body>
                        </Table>
                        </Modal.Content>
                    </Modal>
                </div>
            )
        }
        else{
            return(
                <div className="sales-product-list-header" onClick={this.handleListOpen}>
                    <strong>More Details:</strong><span className="right floated"><i className="caret down icon"></i></span>
                </div>
            )
        }
    }
}

export default OrderPndDetails;
