import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import createHistory from 'history/createBrowserHistory';
import { Grid, Menu, Button, Popup, Tab, Dropdown } from 'semantic-ui-react';

import '../style/page-center.css';

import Client from '../services/Client';
import BookingsList from '../components/bookings-list';
import OrdersList from '../components/orders-list';

import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import '../style/bookings.css';

class Bookings extends Component{

    adminOrBiz = true;

    constructor(props){

        super(props);

        this.state = {
            currentStore: JSON.parse(localStorage.getItem('currentStore')||'{}').storeId,
            innerWidth: 0,
            view: 'card',
            disabled: false,
            expandSettings: true,
            showSettings: false,
            rangeEnabled: false,
            bookings: [],
            orders: [],
            statuses:
                this.props.orderOrBook
                ?
                ["Placed", "Accepted", "Rejected", "Service Processing", "Completed", "Cancelled"]
                :
                ["Payment Failed", "Processing Payment", "Rejected", "Placed", "Accepted", "Packed", "Picked Up", "Delivered", "Completed", "Unsuccessful", "Delayed", "Cancelled"],
            status: 'Placed',
            bookings: [],
            page: 0,
            morePage: 0,
            filters: {
                startDate: '',
                endDate: '',
                payMode: '',
                bookingDateMode: 1
            },
            bookingsCount: null,
            cashReceived: null,
            paytmReceived: null,
            onlineReceived: null,
            upiReceived: null,
            cashCount: null,
            paytmCount: null,
            onlineCount: null,
            upiCount: null,
            dhpReceivable: null,
            dhpCommisionDue: null,
            dhpCommision: null,
            paymentMethods: JSON.parse(localStorage.getItem('paymentMethods')||'{}'),
            cities: [{"text":"All", "value":"All"}, ...props.cities],
            city: props.city
        }

        this.changeView = this.changeView.bind(this);
        this.toggleRange = this.toggleRange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.refreshBookings = this.refreshBookings.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleBookingDateMode = this.handleBookingDateMode.bind(this);
        this.handleDateSelect = this.handleDateSelect.bind(this);
        this.handleEndDateSelect = this.handleEndDateSelect.bind(this);
        this.handleStartDateSelect = this.handleStartDateSelect.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);
        this.handlePaymentModeFilter = this.handlePaymentModeFilter.bind(this);
        this.handlePaymentMethodChange = this.handlePaymentMethodChange.bind(this);
        // comment out for biz
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStoreChange = this.handleStoreChange.bind(this);
        this.handleUpdateDate = this.handleUpdateDate.bind(this);

        this.initiateHandleScroll = this.initiateHandleScroll.bind(this);

    }

    componentWillReceiveProps(p){

        this.setState({
            view: 'card',
            disabled: false,
            showSettings: false,
            rangeEnabled: false,
            bookings: [],
            orders: [],
            statuses: p.orderOrBook ?
            ["Placed", "Accepted", "Rejected", "Service Processing", "Completed", "Cancelled"]
            :
            ["Payment Failed", "Processing Payment", "Rejected", "Placed", "Accepted", "Packed", "Picked Up", "Delivered", "Completed", "Unsuccessful", "Cancelled"],
            status: 'Placed',
            bookings: [],
            page: 0,
            morePage: 0,
            filters: {
                startDate: '',
                endDate: '',
                payMode: '',
                bookingDateMode: 1
            },
            bookingsCount: null,
            cashReceived: null,
            paytmReceived: null,
            onlineReceived: null,
            upiReceived: null,
            cashCount: null,
            paytmCount: null,
            onlineCount: null,
            upiCount: null,
            dhpReceivable: null,
            dhpCommisionDue: null,
            dhpCommision: null,
            paymentMethods: JSON.parse(localStorage.getItem('paymentMethods')||'{}'),
            city: p.city,
            cities: [{"text":"All", "value":"All"}, ...p.cities]
        });

        // comment out for biz

        if(this.adminOrBiz){
            this.fetchStores(localStorage.getItem('city'));
        }
    }

    componentDidMount(){

        this.setState({
            innerWidth: window.innerWidth,
            cities: [{"text":"All", "value":"All"}, ...this.props.cities],
            city: this.props.city
        })

        if(this.props.orderOrBook){
            Client.getBookings(0, this.state.statuses[0], this.adminOrBiz?null:JSON.parse(localStorage.getItem('store')||'{}').storeId).then((res)=>{
                this.setState({
                    bookings: res.bookingsList,
                    page: Number(res.page) + 1,
                    morePage: Number(res.morePage),
                    bookingsCount: res.bookingsCount,
                    cashReceived: res.cashReceived,
                    paytmReceived: res.paytmReceived,
                    onlineReceived: res.onlineReceived,
                    upiReceived: res.upiReceived,
                    cashCount: res.cashCount,
                    paytmCount: res.paytmCount,
                    onlineCount: res.onlineCount,
                    upiCount: res.upiCount,
                    dhpReceivable: res.dhpReceivable,
                    dhpCommisionDue: res.dhpCommisionDue,
                    dhpCommision: res.dhpCommision,
                    refreshing: false
                });
            });
        }
        else{
            Client.getOrders(0, this.state.status, this.adminOrBiz?null:JSON.parse(localStorage.getItem('store')||'{}').storeId).then((res)=>{
                this.setState({
                    orders: res.ordersList,
                    page: Number(res.page) + 1,
                    morePage: Number(res.morePage),
                    ordersCount: res.ordersCount,
                    cashReceived: res.cashReceived,
                    paytmReceived: res.paytmReceived,
                    onlineReceived: res.onlineReceived,
                    upiReceived: res.upiReceived,
                    cashCount: res.cashCount,
                    paytmCount: res.paytmCount,
                    onlineCount: res.onlineCount,
                    upiCount: res.upiCount,
                    dhpReceivable: res.dhpReceivable,
                    dhpCommisionDue: res.dhpCommisionDue,
                    dhpCommision: res.dhpCommision,
                    refreshing: false
                });
            });
        }
    }

    refreshBookings(status, filters, page, find, sort, sortOrder, storeId){
        this.setState({refreshing: true});

        if(this.props.orderOrBook){
            Client.getBookings(page == 0 ? 0 : (page || this.state.page), status || this.state.status,
            this.adminOrBiz ? (storeId || this.state.currentStore) : JSON.parse(localStorage.getItem('store')||'{}').storeId, filters || this.state.filters, find, sort, sortOrder).then((res)=>{
                this.setState({
                    bookings: res.bookingsList,
                    page: Number(res.page) + 1,
                    morePage: Number(res.morePage),
                    bookingsCount: res.bookingsCount,
                    cashReceived: res.cashReceived,
                    paytmReceived: res.paytmReceived,
                    onlineReceived: res.onlineReceived,
                    upiReceived: res.upiReceived,
                    cashCount: res.cashCount,
                    paytmCount: res.paytmCount,
                    onlineCount: res.onlineCount,
                    upiCount: res.upiCount,
                    dhpReceivable: res.dhpReceivable,
                    dhpCommisionDue: res.dhpCommisionDue,
                    dhpCommision: res.dhpCommision,
                    refreshing: false
                });
            });
        }
        else{
            Client.getOrders(page == 0 ? 0 : (page || this.state.page), status || this.state.status,
            this.adminOrBiz ? (storeId || this.state.currentStore) : JSON.parse(localStorage.getItem('store')||'{}').storeId, filters || this.state.filters, find, sort, sortOrder).then((res)=>{
                this.setState({
                    orders: res.ordersList,
                    page: Number(res.page) + 1,
                    morePage: Number(res.morePage),
                    ordersCount: res.ordersCount,
                    cashReceived: res.cashReceived,
                    paytmReceived: res.paytmReceived,
                    onlineReceived: res.onlineReceived,
                    upiReceived: res.upiReceived,
                    cashCount: res.cashCount,
                    paytmCount: res.paytmCount,
                    onlineCount: res.onlineCount,
                    upiCount: res.upiCount,
                    dhpReceivable: res.dhpReceivable,
                    dhpCommisionDue: res.dhpCommisionDue,
                    dhpCommision: res.dhpCommision,
                    refreshing: false
                });
            });
        }
    }

    handleTabChange(e, data){

        this.setState({
            status: this.state.statuses[data.activeIndex],
            lastRequest: null
        });

        this.refreshBookings(this.state.statuses[data.activeIndex], null, 0);
    }

    changeView(){

        this.setState({
            view: this.state.view == 'card' ? 'list' : this.state.view  == 'list' ? 'extended' : 'card'
        })
    }

    toggleRange(e){

        this.setState({
            rangeEnabled: e.target.checked,
            filters: {
                ...this.state.filters,
                startDate: '',
                endDate: ''
            }
        })
    }

    handleSort(sort, sortOrder){
        this.refreshBookings(null, null, 0, null, sort, sortOrder);
    }

    handleSearch(e){
        if(e.target.value){
            this.refreshBookings('All', null, 0, e.target.value);
            this.setState({
                showSearchResults: true
            })
        }else{
            this.setState({
                showSearchResults: false
            })
        }
    }

    handleFilter(e){

        var filters = {};

        if(e.target.name == 'date'){

            filters = {
                ...this.state.filters,
                startDate: e.target.value,
                endDate: e.target.value
            }

            this.setState({
                filters: filters
            });

            this.refreshBookings(null, filters, 0);

        }else{

            filters = {
                ...this.state.filters,
                [e.target.name]: e.target.value
            }

            this.setState({
                filters: filters
            })

            this.refreshBookings(null, filters, 0);
        }
    }

    handleScroll(event){

        if (this.state.morePage==1 && (this.state.page!=this.state.lastRequest)){

            this.setState({
                lastRequest: this.state.page
            })

            if(this.props.orderOrBook){
                Client.getBookings(this.state.page, this.state.status, this.adminOrBiz ? this.state.currentStore : JSON.parse(localStorage.getItem('store')||'{}').storeId, this.state.filters).then((res)=>{
                    this.setState({
                        bookings: [
                            ...this.state.bookings,
                            ...res.bookingsList
                        ],
                        page: Number(res.page) + 1,
                        morePage: Number(res.morePage),
                        bookingsCount: res.bookingsCount,
                        cashReceived: res.cashReceived,
                        paytmReceived: res.paytmReceived,
                        onlineReceived: res.onlineReceived,
                        upiReceived: res.upiReceived,
                        cashCount: res.cashCount,
                        paytmCount: res.paytmCount,
                        onlineCount: res.onlineCount,
                        upiCount: res.upiCount,
                        dhpReceivable: res.dhpReceivable,
                        dhpCommisionDue: res.dhpCommisionDue,
                        dhpCommision: res.dhpCommision,
                        refreshing: false
                    });
                });
            }
            else{
                Client.getOrders(this.state.page, this.state.status, this.adminOrBiz ? this.state.currentStore : JSON.parse(localStorage.getItem('store')||'{}').storeId, this.state.filters).then((res)=>{

                    this.setState({
                        orders: [
                            ...this.state.orders,
                            ...res.ordersList
                        ],
                        page: Number(res.page) + 1,
                        morePage: Number(res.morePage),
                        ordersCount: res.ordersCount,
                        cashReceived: res.cashReceived,
                        paytmReceived: res.paytmReceived,
                        onlineReceived: res.onlineReceived,
                        upiReceived: res.upiReceived,
                        cashCount: res.cashCount,
                        paytmCount: res.paytmCount,
                        onlineCount: res.onlineCount,
                        upiCount: res.upiCount,
                        dhpReceivable: res.dhpReceivable,
                        dhpCommisionDue: res.dhpCommisionDue,
                        dhpCommision: res.dhpCommision
                    });
                });
            }
        }
    }

    initiateHandleScroll(){
        var container = document.getElementById('scroll');
        if(container.scrollTop >= (container.scrollHeight-container.offsetHeight)){
            this.handleScroll();
        }
    }

    handleBookingDateMode(e, data){

        var filters = {
            ...this.state.filters,
            bookingDateMode: data.value
        }

        this.setState({
            filters: filters
        })

        this.refreshBookings(null, filters, 0);
    };

    handleDateSelect(date){

        if(date == null){
            this.setState({
                selectedDate: null,
                startDate: null,
                endDate: null
            })
        }
        else{
            let actualDate = new Date(date);
            actualDate.setDate(actualDate.getDate()+1);

            let d = actualDate.toISOString();
            d = d.substr(0,10);

            var filters = {
                ...this.state.filters,
                startDate: d,
                endDate: d
            }

            this.setState({
                filters: filters,
                selectedDate: date
            })

            this.refreshBookings(null, filters, 0);
        }
    }

    handleStartDateSelect(date){

        if(date == null){
            this.setState({
                selectedStartDate: null,
                startDate: null
            })
        }
        else {
            var d = date._d.toISOString();
            d = d.substr(0,8) + ((parseInt(d.substr(8,2))+1)>9?(parseInt(d.substr(8,2))+1):('0'+(parseInt(d.substr(8,2))+1)));

            var filters = {
                ...this.state.filters,
                startDate: d
            }

            this.setState({
                filters: filters,
                selectedStartDate: date
            })

            this.refreshBookings(null, filters, 0);
        }
    };

    handleEndDateSelect(date){

        if(date == null){
            this.setState({
                selectedEndDate: null,
                endDate: null
            })
        }
        else{
            var d = date._d.toISOString();
            d = d.substr(0,8) + ((parseInt(d.substr(8,2))+1)>9?(parseInt(d.substr(8,2))+1):('0'+(parseInt(d.substr(8,2))+1)));

            var filters = {
                ...this.state.filters,
                endDate: d
            }

            this.setState({
                filters: filters,
                selectedEndDate: date
            })

            this.refreshBookings(null, filters, 0);
        }
    }
    handlePaymentModeFilter(e, data){
        this.setState({
            filters: {
                ...this.state.filters,
                payMode: data.value,
            }
        })
    }
    handlePaymentMethodChange(e, data){

        this.setState({
            filters: {
                ...this.state.filters,
                payMode: data.value,
            }
        })
    }

    handlePrintClick(){
        var ordersHtml = ''

        if(this.props.orderOrBook){
            this.state.bookings.forEach(function(booking, index) {
                ordersHtml = ordersHtml +
                '<tr><td>' + booking.bookingId + '</td>' +
                '<td>' + booking.bookingDateAndTime.substr(6,10)+", "+booking.bookingDateAndTime.substr(23,5) + '</td>'+
                '<td>' + booking.item.substr(0,20) + '</td>' +
                '<td>' + booking.customerName.substr(0,15) + '</td>' +
                '<td>' + booking.customerPhone.substr(0,15) + '</td>' +
                '<td>' + booking.deliveryAddress.toUpperCase()[0]+booking.deliveryAddress.substr(1,30) + '</td>' +
                '<td>' + booking.amount + '</td>' +
                '<td>' + booking.orderStatus.substr(0,10) + '</td>' +
                '<td>' + booking.paymentMode.substr(0,20) + '</td>' +
                '<td>' + booking.paymentStatus.substr(0,15) + '</td>' +
                '<td> </td></tr>'
            }.bind(this));
            // localStorage.setItem('bookingsForPrint', JSON.stringify(this.state.bookings));
        }
        else{
            this.state.orders.forEach(function(order, index) {
                ordersHtml = ordersHtml +
                '<tr><td>' + order.orderId + '</td>' +
                '<td>' + order.orderDateAndTime.substr(6,10) + ', ' + order.orderDateAndTime.substr(23,5) + '</td>'+
                '<td>' + order.products.substr(0,30) + '</td>' +
                '<td>' + order.customerName.substr(0,15) + '</td>' +
                '<td>' + order.customerPhone.substr(0,15) + '</td>' +
                '<td>' + order.deliveryAddress.toUpperCase()[0]+order.deliveryAddress.substr(1,30) + '</td>' +
                '<td>' + order.amount + '</td>' +
                '<td>' + order.orderStatus.substr(0,10) + '</td>' +
                '<td>' + order.paymentMode.substr(0,20) + '</td>' +
                '<td> </td></tr>'
            }.bind(this));
            // localStorage.setItem('ordersForPrint', JSON.stringify(this.state.orders));
        }

        var html = `
        <!doctype html>
        <html>
        <body style="font-size:10px; margin:10px; border: solid #999 1px;">
        <table>
            <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Item</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Pay Mode</th>
                <th>Pay Status</th>
                <th>Sign & Report</th>
            </tr>
            ${ordersHtml}
        </table>
        </body>
        <style>
            body{
                margin: 0em;
            }
            *{
                font-family: sans-serif;
                font-size: 12px;
            }
            table{
                width:100%;
                border-collapse: collapse;
            }
            table tr:nth-child(even) {
                background: #EEE
            }
            table td{
                padding:5px 2px;
                text-align:center;
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
            table th{
                padding:5px 2px;
                text-align:center;
                background:#CCCCCC;
                border-top:solid 1px #999;
                border-left:solid 1px #999;
            }
        </style>
        </html>`
        var printwindow = window.open('','dhp_bookings_print', 'height=1000, width=1000');
        printwindow.document.write(
            html
        );
        printwindow.focus();
        setTimeout(function() {
            printwindow.document.title = "ORDERS LIST";
            // printwindow.print();
            // printwindow.close();
            // printwindow.document.close();
        }, 500);
    }

    handleUpdateDate(date){

        if(date == null){
            this.setState({
                updateDate: null
            })
        }
        else{
            var d = date._d.toISOString();
            d = d.substr(0,8) + ((parseInt(d.substr(8,2))+1)>9?(parseInt(d.substr(8,2))+1):('0'+(parseInt(d.substr(8,2))+1)));

            this.setState({
                selectedUpdateDate: date,
                updateDate: d
            })
            localStorage.setItem('updateDate', d)
        }
    }

    handleCityChange(e, data){
        this.fetchStores(data.value);
        localStorage.setItem('city', data.value);
    }

    handleStoreChange(e, data){
        this.setState({
            currentStore: data.value
        })

        this.refreshBookings(this.state.statuses[data.activeIndex], null, 0, null, null, null, data.value);
    }

    fetchStores(city){
        this.setState({
            loadingStores: true
        })
        Client.getBusinesses(null, city).then((res)=>{
            var storesOption = [{value:"", text:'All'}];
            res.businessesList.forEach((business)=>{
                const s = {
                    value: business.storeId,
                    text: business.storeName,
                }
                storesOption = [...storesOption, s]
            });
            this.setState({
                stores:storesOption,
                city: city,
                loadingStores: false
            })
        });
    }

    render(){

        if(this.adminOrBiz){
            var id = JSON.parse(localStorage.getItem('currentStore')||'{}').storeId;
        }

        var arrayObj = new Array();
        for(var i = 0; i < this.state.paymentMethods.length; i++){
            var paymet = {};
            paymet.text = this.state.paymentMethods[i]['method'];
            paymet.value = this.state.paymentMethods[i]['id'];
            arrayObj.push(paymet);
        }

        var panes = this.state.statuses.map((status)=>(
            {
                menuItem: status,
                render: this.props.orderOrBook?
                () => <BookingsList nonSearch refreshing={this.state.refreshing} view={this.state.view} handleSort={this.handleSort} bookings={this.state.bookings}/>
                :
                () => <OrdersList nonSearch refreshing={this.state.refreshing} view={this.state.view} handleSort={this.handleSort} orders={this.state.orders}/>,
            }
        ));

        if(this.state.disabled){
            return(
                <div className="page-center"><div className="page-center-content" style={{color: "#aaaaaa"}}>Disabled</div></div>
            )
        }else{
            return(
                <Grid padded="false">
                    {
                        this.state.innerWidth > 720
                        ?
                        <Grid.Row as={Menu} borderless style={{height: "50px", padding: 0, border: "none", borderBottom: "1px solid #999999", borderRadius: "0px", boxShadow: "none", margin: "0"}}>
                            <Button as={Menu.Item} icon='sidebar' onClick={this.props.toggle} style={{margin: 0}}/>
                            <div className="header item">{this.props.orderOrBook?'Bookings':'Orders'}</div>
                            <div className="item">
                                <input type="text" placeholder={this.props.orderOrBook?'Search Bookings':'Search Orders'} onChange={this.handleSearch}/> &nbsp;&nbsp;
                                <i className="search icon" style={{cursor: 'pointer'}} />
                            </div>
                            <div className="right menu">
                                <div className="ui item button" onClick={function(){this.setState({expandSettings: !this.state.expandSettings})}.bind(this)} >Filters</div>
                                <div className="ui item button" onClick={this.changeView.bind(this)} style={{fontWeight: "bolder"}}>
                                    {this.state.view=='card'?'List View':this.state.view=='extended'?"Card View":"Extended List"}
                                </div>
                            </div>
                        </Grid.Row>
                        :
                        <Grid.Row as={Menu} borderless style={{height: "50px", padding: 0, border: "none", borderBottom: "1px solid #999999", borderRadius: "0px", boxShadow: "none", margin: "0"}}>
                            <Button as={Menu.Item} icon='sidebar' onClick={this.props.toggle} style={{margin: 0}}/>
                            <div className="header item">Bookings</div>
                            <div className="right menu">
                                <div className="ui item button" onClick={function(){this.setState({expandSettings: !this.state.expandSettings})}.bind(this)} >Filters</div>
                            </div>
                        </Grid.Row>
                    }
                    <Grid.Row style={{height: 'calc(100vh - 100px)', padding: 0}}>
                        <div className={this.state.expandSettings?"bookings-content-settings":"bookings-content"}>
                            <div className="bookings-section-1">
                                <Grid.Row className="orders-settings-container" style={{padding: 0}}>
                                    {
                                        this.state.showSettings
                                        ?
                                        <div className="orders-settings-dialog">
                                            <i className="ui setting icon orders-settings-icon" style={{cursor: 'pointer'}} onClick={function(){this.setState({showSettings: !this.state.showSettings})}.bind(this)} />
                                            <div className="orders-settings-dialog-content">
                                                Date Mode: <Dropdown inline value={this.state.filters.bookingDateMode} onChange={this.handleBookingDateMode.bind(this)} options={[{text: 'Order Initiation Date', value: 1}, {text: 'Order Status Date', value: 0}]} />
                                            </div>
                                        </div>
                                        :
                                        <i className="ui setting icon orders-settings-dialog" style={{cursor: 'pointer'}} onClick={function(){this.setState({showSettings: !this.state.showSettings})}.bind(this)} />
                                    }

                                    <div onClick={this.handlePrintClick}><i className="ui print icon orders-print-button" /></div>

                                    <div className="orders-settings">
                                        <div className="orders-settings-date">Date:</div>
                                        <div className="orders-settings-range">
                                            <input type="checkbox" onChange={this.toggleRange} name="orders-filter-range-checkbox" className="orders-filter-range-checkbox" /> &nbsp;
                                            Range:
                                        </div>
                                        {
                                            this.state.rangeEnabled
                                            ?
                                            <div className="orders-settings-from-to">
                                                <div>From:</div><DatePicker className="inline" todayButton={"Today"} dateFormat="DD/MM/YYYY" placeholderText="Start Date" selected={this.state.selectedStartDate} onChange={this.handleStartDateSelect}/><div>To:</div><DatePicker todayButton={"Today"} className="inline" dateFormat="DD/MM/YYYY" placeholderText="End Date" selected={this.state.selectedEndDate} onChange={this.handleEndDateSelect}/>
                                            </div>
                                            :
                                            <div className="orders-settings-on">
                                                <DatePicker className="inline" dateFormat="DD/MM/YYYY" todayButton={"Today"}
                                                placeholderText="Select Date" selected={this.state.selectedDate} onChange={this.handleDateSelect}/>
                                            </div>
                                        }
                                        {
                                            this.adminOrBiz
                                            ?
                                            <div className="orders-settings-payment-mode" style={{gridColumn: this.state.innerWidth > 720 ? (this.state.rangeEnabled ? '7/12' : '4/12') : '1'}}>
                                                Payment Mode: &nbsp;
                                                <Dropdown onChange={this.handlePaymentModeFilter} className="orders-settings-payment-mode-dropdown" inline options={arrayObj}/>
                                                Payment Status: &nbsp;
                                                <Dropdown onChange={this.handlePaymentMethodChange} inline search options={[{text: 'All', value: 'All'}, {text: 'Paid', value: 'Paid'}, {text: 'Unpaid', value: 'Unpaid'}]} style={{borderRadius:'0px'}}/>
                                                City: &nbsp;
                                                <Dropdown onChange={this.handleCityChange} inline search options={this.state.cities} defaultValue={localStorage.getItem('city')} style={{borderRadius:'0px'}}/>
                                                Store: &nbsp;
                                                <Dropdown onChange={this.handleStoreChange} inline search options={this.state.stores} loading={this.state.loadingStores} style={{borderRadius:'0px'}}/>
                                            </div>
                                            :
                                            <div className="orders-settings-payment-mode" style={{gridColumn: this.state.innerWidth > 720 ? this.state.rangeEnabled ? '7/12' : '4/12' : '1/13'}}>
                                                Pay Mode: &nbsp;
                                                <Dropdown onChange={this.handlePaymentModeFilter} className="orders-settings-payment-mode-dropdown" inline options={arrayObj}/>
                                                Pay Status: &nbsp;
                                                <Dropdown onChange={this.handlePaymentMethodChange} inline search options={[{text: 'All', value: 'All'}, {text: 'Paid', value: 'Paid'}, {text: 'Unpaid', value: 'Unpaid'}]} style={{borderRadius:'0px'}}/>
                                            </div>
                                        }
                                        <div className="orders-settings-number">Bookings: {this.state.bookings.length}/{this.state.bookingsCount || 0}</div>
                                        {
                                            this.adminOrBiz
                                            ?
                                            <div className="orders-settings-update-date">
                                                <DatePicker onChange={this.handleUpdateDate} dateFormat="DD/MM/YYYY" placeholderText="Select Update Date" selected={this.state.selectedUpdateDate}/>
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="orders-settings-cash">Cash: {this.state.cashReceived} ({this.state.cashCount}/{this.state.bookingsCount || 0})</div>
                                        <div className="orders-settings-paytm">PayTm: {this.state.paytmReceived} ({this.state.paytmCount}/{this.state.bookingsCount || 0})</div>
                                        <div className="orders-settings-online">Online: {this.state.onlineReceived} ({this.state.onlineCount}/{this.state.bookingsCount || 0})</div>
                                        <div className="orders-settings-online">UPI: {this.state.upiReceived} ({this.state.upiCount}/{this.state.bookingsCount || 0})</div>
                                        <div className="orders-settings-doorhopper-receivable">DHP Receivable: {this.state.dhpReceivable}</div>
                                        <div className="orders-settings-doorhopper-commision">DHP Commision: {this.state.dhpCommision}</div>
                                        <div className="orders-settings-doorhopper-commision-due">DHP Commision Due: {this.state.dhpCommisionDue}</div>
                                    </div>
                                </Grid.Row>
                            </div>
                            <div className="bookings-section-2" id="scroll" onScroll={this.initiateHandleScroll}>
                                    {
                                        this.state.showSearchResults
                                        ?
                                        this.props.orderOrBook
                                        ?
                                        <BookingsList refreshing={this.state.refreshing} view={this.state.view} status={this.state.status} handleSort={this.handleSort} bookings={this.state.bookings}/>
                                        :
                                        <OrdersList refreshing={this.state.refreshing} view={this.state.view} status={this.state.status} handleSort={this.handleSort} orders={this.state.orders} />
                                        :
                                        <Tab panes={panes} style={{width: '80vw'}} onTabChange={this.handleTabChange} />
                                    }
                            </div>
                        </div>
                    </Grid.Row>
                </Grid>
            )
        }
    }
};

export default Bookings;
