import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon, Modal, Form, Image, Label, Segment, TableHeader} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import AccountingLedgerVouchers from './accounting-ledger-vouchers'
import Due from './due_report';
import {saveAs} from "file-saver"
import XLSX from "xlsx"
const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingGstReport extends Component{

	constructor(){
		super();

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		var dateVaue = yyyy + '-' + mm + '-' + dd;

		this.state = {
			date: yyyy+'-'+mm,
			multipleAddMode: true,
			data_rows: [],
			table:'b2b',
			new_data_row: {date: dateVaue, new_data_rows:[{}]},
			tableFields_b2b: [
				{"value": "GSTIN", "text": "GSTIN", "type": "field"},
				{"value": "ledger_name", "text": "Ledger Name", "type": "field"},
				{"value": "voucher_id", "text": "Voucher Id", "type": "field"},
				{"value": "date", "text": "Date", "type": "field"},
				{"value": "invoice_amount", "text": "Invoice Amount", "type": "field"},
				{"value": "pos", "text": "POS", "type": "field"},
				{"value": "reverse", "text": "Reverse", "type": "field"},
				{"value": "invoice_type", "text": "Invoice Type", "type": "field"},
				{"value": "ecomm", "text": "Ecomm", "type": "field"},
				{"value": "tax", "text": "Tax", "type": "field"},
				{"value": "eff_tax", "text": "Eff Tax", "type": "field"},
				{"value": "taxable_amount", "text": "Taxable Amount", "type": "field"},
				{"value": "cess", "text": "Cess", "type": "field"},
				{"value": "difference", "text": "Difference", "type": "field"},
			],
			tableFields_b2c_invoice: [
				{"value": "GSTIN", "text": "GSTIN", "type": "field"},
				{"value": "ledger_name", "text": "Ledger Name", "type": "field"},
				{"value": "voucher_id", "text": "Voucher Id", "type": "field"},
				{"value": "date", "text": "Date", "type": "field"},
				{"value": "invoice_amount", "text": "Invoice Amount", "type": "field"},
				{"value": "pos", "text": "POS", "type": "field"},
				{"value": "reverse", "text": "Reverse", "type": "field"},
				{"value": "invoice_type", "text": "Invoice Type", "type": "field"},
				{"value": "ecomm", "text": "Ecomm", "type": "field"},
				{"value": "tax", "text": "Tax", "type": "field"},
				{"value": "eff_tax", "text": "Eff Tax", "type": "field"},
				{"value": "taxable_amount", "text": "Taxable Amount", "type": "field"},
				{"value": "cess", "text": "Cess", "type": "field"},
				{"value": "difference", "text": "Difference", "type": "field"},
			],
			tableFields_b2c_upload: [
				{"value": "type", "text": "Type", "type": "field"},
				{"value": "pos", "text": "Pos", "type": "field"},
				{"value": "tax", "text": "Tax %", "type": "field"},
				{"value": "app_tax", "text": "App Tax", "type": "field"},
				{"value": "taxable_amount", "text": "Taxable Amount", "type": "field"},
				{"value": "cess", "text": "Cess", "type": "field"},
				{"value": "ecom_gstin", "text": "Ecom GSTIN", "type": "field"},
			],
			data_rows_b2b:[],
			data_rows_b2c_upload:[],
			data_rows_b2c_invoice:[],
			defaultSize: 'mini',
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'ledger',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'group',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: {},
			options_data_rows_limit: [
				{text: 10, value: 10},
				{text: 20, value: 20},
				{text: 30, value: 30},
				{text: 40, value: 40},
				{text: 50, value: 50}
			],
			options_month:[],
			// options_parent_group: [],
			// options_voucher_id: [],
			options_ledger: [],
			options_group: [],
		}
	}

	componentDidMount() {
		var options_group = []
		var options_ledger = []
		Client.getAccountingMastersLedger(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_ledger.push({text: data_row.ledger_name, value: data_row.id})
			})
			this.setState({
				options_ledger: options_ledger,
			})
		})
		Client.getAccountingMastersGroup(null, null, null, null, null, null, null, null).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_group.push({text: data_row.group_name, value: data_row.id})
			})
			this.setState({
				options_group: options_group,
			})
		})
		this.setMonthSelections()
		this.getDataRows(this.state);

	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};
		state[data.name] = data.value;

		//SELECTIVE OPERATIONS
		if(data.name == "data_rows_search_field"){
			this.state.tableFields.forEach(function(tableField){
				if(data.value == tableField.value)
					state['data_rows_search_type'] = tableField.type;
			})
		}
		if(data.name == "data_rows_offset"){
			if(data.value < 0){
				state[data.name] = 0;
			}
			if(data.value >= this.state.data_rows_size){
				state[data.name] = data.value - 10;
			}
		}
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data, index){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value || data.checked;
		if(this.state.multipleAddMode == 0 || index == undefined){
			index = 0
		}
		var new_data_rows = [...this.state.new_data_row.new_data_rows]
		new_data_rows[index][data.name] = data.value;
		new_data_row.new_data_rows = new_data_rows
		this.setState({
			new_data_row: new_data_row,
			multipleAddMode: data.name == 'multiple' ? data.checked :  this.state.multipleAddMode
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}

	showDataRowDetails(index){
		// this.setState({
		// 	showCreateDataModal: true,
		// 	new_data_row: this.state.data_rows[index],
		// 	editMode: true
		// })
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createLead({...state.new_data_row, multiple: this.state.multipleAddMode}, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {date: state.new_data_row.date, new_data_rows:[{}]}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getGstReport(
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			null,
			{date:this.state.date}
			// {}
			,{},null
			).then(res=>{
			this.setState({
				data_rows_b2b: res.data_rows_b2b,
				data_rows_b2c_invoice: res.data_rows_b2c_invoice,
				data_rows_b2c_upload: res.data_rows_b2c_upload,
				data_rows_size: res.data_rows_size,
				balance_sum: res.current_balance
			})
		})
	}
    setMonthSelections(){
		var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
		})
	}
	//CUSTOM FUNCTIONS

	handleDateRangeChange(e, data){
		var state = {...this.state}
		state[data.name] = data.value || e.target.value
		this.setState(state)
		this.getDataRows(state);
	}

	showLedgerVouchers(ledger){
		this.setState({
			currentLedger: ledger.ledger,
			showLedgerVouchersModal: true
		})
	}
	excelfyTable(){
		let data = this.state['data_rows_'+this.state.table]
		let work_book = XLSX.utils.book_new()
		let work_sheet = XLSX.utils.json_to_sheet(data)
		XLSX.utils.book_append_sheet(work_book,work_sheet,this.state.date+'_'+this.state.table)
		let exported = XLSX.write(work_book,{bookType:'xlsx',type:'array'})
		saveAs(new Blob([exported],{type:'application/octet-stream'}),this.state.date+'_gst_report.xlsx')
	}
	excelfyReport(){
		let source_suffixes = ["b2b","b2c_invoice","b2c_upload"]
		let work_book = XLSX.utils.book_new()
		source_suffixes.forEach(suffix=>{
			let data = this.state['data_rows_'+suffix]
			let work_sheet = XLSX.utils.json_to_sheet(data)
			XLSX.utils.book_append_sheet(work_book,work_sheet,this.state.date+'_'+suffix)
		})
		let exported = XLSX.write(work_book,{bookType:'xlsx',type:'array'})
		saveAs(new Blob([exported],{type:'application/octet-stream'}),this.state.date+'_gst_report.xlsx')
	}
	render(){
		return(
			<div>
				<span style={{display:'flex',flexWrap:'wrap'}}>
					<Button size={this.state.defaultSize} icon="repeat" color="red" onClick={() => this.componentDidMount()}/>
					<Dropdown
						value={this.state.table}
						name="table"
						search
						selection
						options={[
							{text:'B2B',value:'b2b'},
							{text:'B2C Invoice',value:'b2c_invoice'},
							{text:'B2C Upload',value:'b2c_upload'},
						]}
						onChange={(e,data)=>{
							this.setState({[data.name]:data.value})
						}}
						/>
					<Dropdown
						options={this.state.options_month}
						value={this.state.date}
						name="date"
						search
						selection
						// inline
						placeholder="Select Month"
						onChange={(e,data)=>{this.changeStateVariable(e,data)}}
						/>
					<Button primary   onClick={()=>this.excelfyTable()}>
						Download Table
					</Button>
					<Button positive basic onClick={()=>this.excelfyReport()}>
						Download Report
					</Button>
				</span>

				<Table color="blue" key="blue" size="small" compact>
					<Table.Header>
						<Table.Row>
						{
							this.state['tableFields_'+this.state.table].map((field) => (
								<Table.HeaderCell
								// onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})}
								style={{cursor: 'pointer'}}>
									{field.text}
									{/* {
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									} */}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state['data_rows_'+this.state.table].map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showLedgerVouchers(data_row)}>
							{
								this.state['tableFields_'+this.state.table].map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}

							</Table.Row>
						))
					}
					</Table.Body>
				</Table>


			</div>
        )
	}
}

export default AccountingGstReport;
