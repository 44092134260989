import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown,Modal,TextArea,Segment,Loader,Dimmer,Image, Form, Checkbox} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import Inventory from "./inventory";

class CreateAccountingInvoice extends Component{

	constructor(){
		super();
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
		    dd = '0'+dd
		}
		if(mm<10) {
		    mm = '0'+mm
		}
		today = yyyy + '-' + mm + '-' + dd;
		this.state = {
			new_data_row: {
				voucher_id: "",
				remarks: 'Hope to serve you again soon',
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A', options_description: []}],
				taxable_amount: 0,
				round_off: 0,
				total: 0,
				order_id: null,
				b2b: 1,
				GSTIN: '',
				date: today
			},
			taxDetails: [],
			searchCustomers: [],
			customer: {name:''},
			searchProducts: [{productId: 0, name: 'CREATE NEW', text:'CREATE NEW', value:0}],
			createProduct: false,
			options_voucher_id: [],
			options_branch:[],
			options_tax:[
				{text: '0 %', value: '0.00'},
				{text: '5 %', value: '5.00'},
				{text: '12 %', value: '12.00'},
				{text: '18 %', value: '18.00'},
				{text: '28 %', value: '28.00'},
				{text: 'N/A', value: 'N/A'}]
		}
	}

	componentDidMount() {
		let order_id = localStorage.getItem('/accounting/vouchers/invoice/create')
		if(order_id){
			this.handleInputChangeSale(null, {name:'order_id', value:order_id})
			localStorage.removeItem('/accounting/vouchers/invoice/create')
		}
		this.loadCustomers();
		this.loadInvoiceIds();
		if(localStorage.getItem('currentInvoiceSale') && localStorage.getItem('currentInvoiceSaleEdit') == 1){
			var new_data_row = JSON.parse(localStorage.getItem('currentInvoiceSale'));
			new_data_row.products.forEach(function(product,i) {
				product.options_product = [{text: product.name, value: product.product_id}]
				var options_description = [];
				product.options_description.forEach(function(description){
					options_description.push({
						text: description,
						value: description
					})
				})
				product.options_description = [{text:'Empty',value:undefined},...options_description]
			})
			this.getCustomerBranches(new_data_row.customer);
			this.setState({
				new_data_row: new_data_row
			})

			this.calculateTax()
		}
	}

	componentWillUnmount(){
		localStorage.setItem('currentInvoiceSaleEdit', 0);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	addProduct(e){
		e.preventDefault();
		var products = this.state.new_data_row.products;
		var new_data_row = this.state.new_data_row;
		new_data_row.products = [...products, {id:products.length, qty: 0, rate: 0, tax: 'N/A'}]
		this.setState({
			new_data_row: new_data_row
		})
		setTimeout(() => {
			document.getElementById('accounting-invoice-product-input-name-' + (new_data_row.products.length - 1)).getElementsByTagName('input')[0].focus()
	    },300)
	}

	removeProduct(id){
		var products = [...this.state.new_data_row.products];
		var new_data_row = {...this.state.new_data_row};
		var products1 = []
		var found = 0;
		products.forEach(function(element, index) {
			if(index != id){
				products1.push(element)
			}
			else
				found = 1
		});
		new_data_row.products = [...products1]
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}

	getCustomerBranches(customer){
		this.setState({
			showBranch: true,
			loading_branches: true
		})
		Client.getBranchesOfCustomer(customer).then((res)=>{
			let options_branch = []
			if (res.data_rows_size > 0) {
				res.data_rows.forEach(element => {
					let temp = {
						value: element.id,
						text: element.branch_name,
						GSTIN: element.GSTIN
					}
					options_branch.push(temp)
				})
				this.setState({
					options_branch: options_branch,
					loading_branches: false
				})
			} else {
				this.setState({
					showBranch: false,
					loading_branches: false
				})
			}
		})
	}

	handleInputChangeSale(e, data){
		var new_data_row = this.state.new_data_row;
		if (data.name == 'order_id') {
			this.setState({loading_form_order_id:true})
			Client.getOrderSlips('get',null,null,null,null,null,null,null,{slip_no:{filter_type:'value_like',value:data.value}})
			.then(res=>{
				if (res.data_rows_size > 0) {
					new_data_row.cust_branch = res.data_rows[0].branch?res.data_rows[0].branch:''
					new_data_row.customer = res.data_rows[0].customer?res.data_rows[0].customer:''
					this.getCustomerBranches(new_data_row.customer)
				} else {
					new_data_row.cust_branch = ''
					new_data_row.customer = ''
				}
				this.setState({new_data_row,loading_form_order_id:false})
			})
		}
		if(data.name == 'customer'){
			delete new_data_row.cust_branch
			delete new_data_row.order_id
			this.getCustomerBranches(data.value);
			this.setState({branch_issue:false})

		}
		if(data.name == 'cust_branch'){
			this.setState({branch_issue:false})
			this.state.options_branch.forEach((value) => {
				if(value.value == data.value)
				{
					new_data_row.GSTIN = value.GSTIN
				}
			})
			data.value = data.value != '' ? data.value : undefined
		}
		var value = data.value || e.target.value
		if(data.name == 'b2b'){
			// alert(data.name + ":" + data.checked)
			value = data.checked ? 1 : 0
		}
		new_data_row[data.name] = value
		this.setState({
			new_data_row: new_data_row
		})

		this.calculateTax()
	}
	handleKeyDownRateTransactions(e, data, index){
		// if(e.keyCode == 13){
			console.log(data.value);

			var products = this.state.new_data_row.products;
			var purchase = this.state.new_data_row;
			products[index]['rate'] = this.stringToCalculate(data.value.toString())
			purchase.products = [...products]
			this.setState({
				purchase: purchase
			},()=>{
				// this.han
			})

			this.calculateTax()
		// }
	}
	handleKeyDownRate(e, data, index){
		if(e.keyCode == 13){
			var products = this.state.new_data_row.products;
			var purchase = this.state.new_data_row;
			products[index]['rate'] = this.stringToCalculate(e.target.value)
			purchase.products = [...products]
			this.setState({
				purchase: purchase
			})

			this.calculateTax()
		}
	}

	stringToCalculate(value){
		return this.perfectDecimal(eval(value.substring(1, value.length)));
	}

	handleProductDescriptionAddition(index,e,data){
		var new_data_row = this.state.new_data_row;
		let products = new_data_row.products
		products[index].options_description.push({text:data.value,value:data.value})
		new_data_row['products']= products
		this.setState({new_data_row})
	}

	handleInputChangeProduct(index, e, data){
		var value = data.value || e.target.value
		console.log(value);

		var allowCalculate = true
		var products = this.state.new_data_row.products;
		var purchase = this.state.new_data_row;
		var found = 0;

		if(data.name == "product_id"){

			var options_product = [...products[index].options_product]
			var products = this.state.new_data_row.products;
			var purchase = this.state.new_data_row;
			products[index]['product_id'] = data.value
			products[index]['qty'] = 1
			for(var i = 0; i < options_product.length; i++){
				var prod = options_product[i]
				console.log(prod);
				if(prod.product_id == data.value){
					products[index]['name'] = prod.name
					products[index]['rate'] = prod.rate
					// products[index]['transaction_rates'] = [
					// 	{text:'Purchase|21/10/2019|500',value:500},
					// 	{text:'Contract|14/10/2019|900',value:900}
					// ]
					products[index]['transaction_rates'] = prod.transaction_rates.map((elem,i)=>{
						return{
							text: elem.type.toUpperCase()+" : "+elem.date+" : Rs."+elem.rate,
							value: elem.rate,
							key: elem.rate+':'+i
						}
					})
					products[index]['tax'] = prod.tax
					products[index]['HSN'] = prod.HSN
					products[index]['options_description'] = [{text:'Empty',value:undefined}, ...prod.options_description.map(description=>{return{text:description,value:description}})]
					break;
				}
			}
		}
		else if(data.name == 'rate'){
			if(parseFloat(value) === value){
			}
			else{
				if(value.charAt(0) == "="){
					allowCalculate = false;
					// this.stringToCalculate(value)
				}
			}
			if (data.value.includes(':')) {
				let temp = data.value.split(':')
				data.value =temp[0]
			}
			products[index][data.name] = data.value
		}
		else{
			products[index][data.name] = value
		}
		purchase.products = [...products]
		this.setState({
			purchase: purchase
		})

		if(allowCalculate)
			this.calculateTax()
	}

	handleProductAddition(index, e, { value }) {
		this.setState({
			createProduct: true,
			indexForNewProduct: index,
		})
	}

	handleAdditionTax(index, e, { value }){
		value = value + '';
		var tax = [...this.state.options_tax, {text: value, value: value}];
		this.setState({
			options_tax: tax
		})
	}

	handleInvoiceIdAdditions(e, {value}){
		this.setState({
			options_invoice_id: [
				...this.state.options_invoice_id,
				{text: value, value: value}
			]
		})
	}

	calculateTax(){
		setTimeout(() => {
			var new_data_row = this.state.new_data_row;
			var taxDetails = [];
			var taxable_amount = 0;
			var total = 0;
			var taxStateType = this.state.customer.pos == "AS" ? 0 : 1;
			this.state.new_data_row.products.forEach(function(element) {
				var taxDetail = {};
				var found = 0
				if(element.tax != 'N/A'){
					taxDetails.forEach(function(elem) {
						if(elem.taxPc == element.tax){
							found = 1;
							elem.taxStateType = taxStateType;
							elem.taxableAmount = Number(elem.taxableAmount) + Number((element.qty * element.rate).toFixed(2));
							// elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
							elem.taxAmount = (elem.taxableAmount * elem.taxPc / 100).toFixed(2);
							// elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
						}
					})
					if(found == 0){
						taxDetail['taxStateType'] = taxStateType;
						taxDetail['taxPc'] = element.tax;
						taxDetail['taxableAmount'] = (element.qty * element.rate).toFixed(2);
						// taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
						taxDetail['taxAmount'] = (taxDetail['taxableAmount'] * element.tax / 100).toFixed(2);
						// taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
						taxDetails.push(taxDetail)
					}
				}
				taxable_amount = taxable_amount + Number((element.qty * element.rate).toFixed(2));
			});

			taxDetails.forEach(function(elem) {
				total = total + Number(elem.taxAmount);
				console.log(total);
			});
			new_data_row.taxable_amount = taxable_amount.toFixed(2);
			new_data_row.total = (Number(total) + Number(taxable_amount)).toFixed(2);
			// new_data_row.total = parseFloat(Math.round(new_data_row.total * 100) / 100).toFixed(2);
			new_data_row.payable_amount = Math.round(new_data_row.total).toFixed(2);
			if(new_data_row.adjustment_name != undefined && new_data_row.adjustment_name != ''){
				new_data_row.payable_amount = Math.round(Number(new_data_row.payable_amount) + Number(new_data_row.adjustment_amount || 0)).toFixed(2);
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total) - Number(new_data_row.adjustment_amount)).toFixed(2);
			}
			else{
				new_data_row.round_off = (Number(new_data_row.payable_amount) - Number(new_data_row.total)).toFixed(2);
			}
			// new_data_row.round_off = parseFloat(Math.round(new_data_row.round_off * 100) / 100).toFixed(2);
			// new_data_row.payable_amount = parseFloat(Math.round(new_data_row.payable_amount * 100) / 100).toFixed(2);
			this.setState({
				taxDetails: taxDetails,
				new_data_row: new_data_row
			})
		},300)
	}

	searchCustomer(e, data){
		this.showCustomerSuggestions()
		this.loadCustomers(e.target.value)
	}

	handleRoundOffChange(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row.payable_amount = (Number(new_data_row.payable_amount || 0) - Number(new_data_row.round_off || 0)).toFixed(2);
		new_data_row.round_off = e.target.value
		new_data_row.payable_amount = (Number(new_data_row.payable_amount || 0) + Number(new_data_row.round_off || 0)).toFixed(2);
		this.setState({
			new_data_row: new_data_row
		})
	}
	// batchProductD()
	searchProducts(index, e){
		var find = e != null ? e.target.value : ''
		var new_data_row = {...this.state.new_data_row}
		var products = [...new_data_row.products]
		products[index].searchingProducts = true
		new_data_row.products = products
		this.setState({
			new_data_row: new_data_row
		})
		Client.searchAccountingInvoiceProduct(find,this.state.new_data_row.customer).then(res=>{
			var searchProducts = [...res.products];
			searchProducts.forEach(function(sc){
				sc['text'] = sc.name;
				sc['value'] = sc.product_id;
			})
			// this.state.new_data_row.products.forEach(function(product){
			// 	if(product.product_id != null && product.product_id != undefined && product.product_id != '')
			// 		searchProducts.push({...product, text: product.name, value: product.product_id})
			// })
			products[index].options_product = searchProducts
			products[index].searchingProducts = false
			// products[index].transaction_rates =
			new_data_row.products = products
            this.setState({
                // options_product: searchProducts,
				new_data_row: new_data_row,
				// searchingProducts: false
            })
        })
	}

	loadCustomers(){
		var options_record_ledgers = [];
		Client.getAccountingMastersLedger(null,null,null,null,null,null,null,{group:[151,152]}).then(res=>{
			res.data_rows.forEach(function(data_row) {
				options_record_ledgers.push({text: data_row.ledger_name + (data_row.address != undefined ? ' (' + data_row.address + ')' : ''), value: data_row.id})
			})
			this.setState({
				options_customer: options_record_ledgers,
			})
		})
	}

	loadInvoiceIds(){
		Client.getAccountingInvoiceIds().then(res=>{
			var options_voucher_id = [];
			if(localStorage.getItem('currentInvoiceSaleEdit') == 1){
				options_voucher_id.push({
					text: this.state.new_data_row.voucher_id,
					value: this.state.new_data_row.voucher_id
				})
			}
			res.invoiceIds.forEach(function(voucher_id){
				options_voucher_id.push({
					text: voucher_id,
					value: voucher_id
				})
			})
			this.setState({
                options_voucher_id: options_voucher_id,
            })
        })
	}

	selectCustomer(){
	}

	selectProduct(){
	}

	showCustomerSuggestions(hide){
		if(hide){
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "block";
		}
	}

	showProductSuggestions(index, hide){
		if(hide){
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "block";
		}
	}

	onKeyDown(index, e, searchIndex){
		if(e.target.name == "customer" || e.target.name == "customer-suggestions"){
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-customer-input').focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showCustomerSuggestions(1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				this.setState({
					customer: this.state.searchCustomers[searchIndex]
				})
				this.calculateTax()
				document.getElementById('accounting-invoice-customer-input').value = this.state.searchCustomers[searchIndex].name;
				document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
			}
		}
		else{
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-product-input-name' + index).focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showProductSuggestions(index, 1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				if(this.state.searchProducts[searchIndex]['name'] == "CREATE NEW"){
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
					this.setState({
						createProduct: true,
						indexForNewProduct: index,
					})
				}
				else{
					var products = this.state.new_data_row.products;
					var new_data_row = this.state.new_data_row;
					products[index] = this.state.searchProducts[searchIndex]
					products[index]['qty'] = 1
					new_data_row.products = [...products]
					this.setState({
						new_data_row: new_data_row
					})

					this.calculateTax();
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
				}
			}
		}
	}

	newProductCreated(product){
		var products = this.state.new_data_row.products;
		var new_data_row = this.state.new_data_row;
		products[this.state.indexForNewProduct] = product
		products[this.state.indexForNewProduct]['productId'] = product.itemId
		products[this.state.indexForNewProduct]['qty'] = 1
		new_data_row.products = [...products]
		this.setState({
			new_data_row: new_data_row,
			createProduct: false
		})
		this.calculateTax();
	}

	createSale(){
		var new_data_row = {...this.state.new_data_row}
		var flagInvalidTax = false;
		if (this.state.showBranch) {
			if (this.state.new_data_row.cust_branch == undefined) {
				this.setState({branch_issue:true})
				return 0
			}
		}
		if (!this.state.customer) {
			alert("Please Select Customer")
			return 0
		}
		if (!new_data_row.voucher_id || new_data_row.voucher_id.trim() == '') {
			alert("Invoice # Must Be Selected")
			return 0
		}
		new_data_row.products.forEach((item, i) => {
			if (item.tax == 0 || item.tax == 'N/A') {
				flagInvalidTax = true;
			}
		});
		if (flagInvalidTax && !window.confirm('There are products with 0% or N/A tax. Are you sure to proceed?')) {
			return 0;
		}

		else{
			var products = new_data_row.products
			products.forEach(function(product){
				delete product.options_product
				delete product.options_description
				delete product.searchingProducts
			})
			new_data_row['products'] = products
			console.log(new_data_row);
			Client.accountingInvoiceCreateSale(new_data_row, this.state.customer, localStorage.getItem('currentInvoiceSaleEdit')).then(res=>{
				this.props.history.push('/accounting/vouchers/invoice')
			})
		}
	}
	handleClose(){
		this.setState({
			createProduct:false,
		})
	}
	render(){
		var amountTableTaxData = <div></div>;
		this.state.taxDetails.forEach(function(taxDetail){
			if(taxDetail.taxStateType == 1)
			{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount)}</td></tr>
				</tbody>
			}
			else{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
					<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
				</tbody>
			}
		}.bind(this))

		return(
		    <div>
				{/*<div style={{width:'100%', height:'100%',padding:'10%', background:'rgba(0,0,0,0.8)', position:'fixed', top:0,left:0, zIndex:2000, visibility:this.state.createProduct ? 'visible' : 'hidden'}}>
					<div style={{height:'100%', overflowY:'scroll', background:'white', padding:'1%'}}>
						<img src={RemoveImage} width='25px' height="25px" onClick={()=>this.setState({createProduct:false})}/>
						<Inventory newProductCreated={(product)=>this.newProductCreated(product)}/>
					</div>
				</div>*/}
				<Modal
					open={this.state.createProduct}
					onClose={this.handleClose.bind(this)}
					size='large'>
					<Modal.Content scrolling>
						<Inventory newProductCreated={(product)=>this.newProductCreated(product)}/>
					</Modal.Content>
				</Modal>
				<Form size="mini" onSubmit={() => this.createSale()}>
					<Form.Group widths='equal'>
						<Form.Select options={this.state.options_voucher_id}
							allowAdditions
							search
							onAddItem={(e, value) => this.handleInvoiceIdAdditions(e, value)}
							type="text"
							name="voucher_id"
							placeholder="Invoice #" label="Invoice #" selection value={this.state.new_data_row.voucher_id}
							onChange ={(e, data) => this.handleInputChangeSale(e, data)}
							required/>
						<Form.Input  placeholder='Order #' label="Order #" name="order_id" value={this.state.new_data_row.order_id}
								onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
					</Form.Group>

					{
						this.state.loading_form_order_id
						?
						<Segment>
							<Dimmer active>
							<Loader />
							</Dimmer>
							<Image size="tiny" src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
						</Segment>
						:
						<Form.Group widths='equal'>
							<Form.Field required>
								<Form.Select selection search options={this.state.options_customer} name='customer'
									className="accounting-invoice-input"
									id="accounting-invoice-customer-input"
									placeholder='Choose Customer'
									label="Customer"
									required
									loading={this.state.loading_form_order_id}
									autoComplete="off"
									value={this.state.new_data_row.customer}
									onChange={(e, data) => this.handleInputChangeSale(e, data)}
									required/>
							</Form.Field>
								{
									this.state.new_data_row.customer != null && this.state.showBranch?
									<Form.Field required>
										<Form.Select
											required
											error={this.state.branch_issue}
											loading={this.state.loading_branch || this.state.loading_form_order_id}
											clearable selection search
											options={this.state.options_branch}
											name="cust_branch" value={this.state.new_data_row.cust_branch}
											label={this.state.branch_issue?"Branch (Please Select Branch)":"Branch"} onChange={(e, data) => this.handleInputChangeSale(e, data)}
											/>
									</Form.Field>
									:null
								}
						</Form.Group>
					}

				<Form.Input type='date' placeholder="Invoice Date" label="Invoice Date" name="date" value={this.state.new_data_row.date}
					 onChange={(e, data) => this.handleInputChangeSale(e, data)}
					 required/>

				 <Form.Group widths='equal'>
				 	<Form.Field>
						<Checkbox label='B2B Invoice?' name="b2b" checked={this.state.new_data_row.b2b == 1 ? true : false} onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
					</Form.Field>
					<Form.Input  placeholder='GSTIN' label="GSTIN" name="GSTIN" value={this.state.new_data_row.GSTIN}
							onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				</Form.Group>

				<Table compact className="accounting-invoice-product-table">
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>ITEM</Table.HeaderCell>
							<Table.HeaderCell>HSN</Table.HeaderCell>
							<Table.HeaderCell>QTY</Table.HeaderCell>
							<Table.HeaderCell>RATE</Table.HeaderCell>
							<Table.HeaderCell>TAX</Table.HeaderCell>
							<Table.HeaderCell>AMOUNT</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
					{
						this.state.new_data_row.products.map((product, index) => (
							<Table.Row>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Select search options={product.options_product}
										placeholder="Product Name" name="product_id" autoComplete="off"
										className="accounting-invoice-product-input"
										id={"accounting-invoice-product-input-name-" + index}
										allowAdditions
										onSearchChange={(e) => this.searchProducts(index, e)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										onAddItem={(e, value) => this.handleProductAddition(index, e, value)}
										value={product.product_id}
										loading={product.searchingProducts}/>
									<Dropdown
										options={product.options_description}
										placeholder="Description"
										allowAdditions
										search
										// searchInput
										className="accounting-invoice-product-input"
										selection
										name="description"
										onAddItem={(e, value) => this.handleProductDescriptionAddition(index, e, value)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										value={product.description || ''}
										/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.HSN} placeholder="HSN" name="HSN"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={product.qty} placeholder="Quantity" name="qty"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Dropdown
										search
										selection
										fluid wide
										name="rate"
										placeholder="From Transctions"
										// value={product.rate}
										options={product.transaction_rates}
										onChange={(e, data) => this.handleInputChangeProduct(index, {...e,target:{value:data.value,name:data.name}}, data)}/>
									<Form.Input value={product.rate} placeholder="Rate" name="rate"
										onKeyDown={(e, data) => this.handleKeyDownRate(e, data, index)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Select
										options={this.state.options_tax}
										placeholder="Tax %"
										allowAdditions
										search
										selection
										name="tax"
        								onAddItem={(e, value) => this.handleAdditionTax(index, e, value)}
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}
										value={product.tax || ''}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<Form.Input value={this.perfectDecimal(product.qty * product.rate)} placeholder="Amount" name="amount"
										onChange={(e, data) => this.handleInputChangeProduct(index, e, data)}/>
								</Table.Cell>
								<Table.Cell className="accounting-invoice-product-tablecell">
									<img src={RemoveImage} onClick={() => this.removeProduct(index)} style={{width:'25px', height:'25px'}}/>
								</Table.Cell>
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>
				<Button primary onClick={(e) => this.addProduct(e)}>Add Product</Button>
				<TextArea value={this.state.new_data_row.remarks} placeholder="Terms" name="remarks"
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>

				<div style={{display:'inline-block', float:'right', marginRight:'20px'}}>
				<table>
					<tr><td>Sub Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.taxable_amount}</td></tr>
					{
						this.state.taxDetails.map((taxDetail) => (
							taxDetail.taxStateType == 1
							?
							<tbody>
								<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount)}
								</td></tr>
							</tbody>
							:
							<tbody>
								<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
								<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">
									{this.perfectDecimal(taxDetail.taxAmount/2)}
								</td></tr>
							</tbody>
						))
						//amountTableTaxData
					}
					<tr><td>Total</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.total}</td></tr>
					<tr>
						<td>
						<Input value={this.state.new_data_row.adjustment_name} placeholder="Adjustment" name="adjustment_name"
							className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
						<td>
						<Input value={this.state.new_data_row.adjustment_amount} placeholder="Amount" name="adjustment_amount" type="number" step="any"
							className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
						</td>
					</tr>
					<tr>
						<td>Round Off</td>
						<td>
							<Form.Input value={this.state.new_data_row.round_off} placeholder="Round" name="round_off" type="number" step="any"
								 onChange={(e, data) => this.handleRoundOffChange(e, data)} required/>
						</td>
					</tr>
					<tr><td>Payable Amount</td><td>:</td><td className="accounting-invoice-amount-value">{this.state.new_data_row.payable_amount}</td></tr>
				</table>
				<Form.Button primary type='submit'>CREATE SALE</Form.Button>
				</div>
				</Form>
			</div>
        )
	}
}

export default CreateAccountingInvoice;
