import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Icon,Label, Modal, Form} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import CreateAccountingVendor from './create-accounting-vendor';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingVendor extends Component{

	constructor(){
		super();

		this.state = {
			data_rows: [],
			new_data_row: {},
			tableFields: [
				{"value": "ledger_name", "text": "Vendor Name", "type": "field"},
				{"value": "phone", "text": "Phone", "type": "field"},
				{"value": "alt_phone", "text": "Alternate Phone", "type": "field"},
				{"value": "email", "text": "Email", "type": "field"},
				{"value": "address", "text": "Address", "type": "field"},
				{"value": "city", "text": "City", "type": "field"},
				{"value": "categories", "text": "Categories", "type": "field"},
				{"value": "transport", "text": "Mode Of Transport", "type": "select"},
				{"value": "availability", "text": "Availability", "type": "select"},
				{"value": "reliability", "text": "Reliability", "type": "select"},
				{"value": "experience", "text": "Experience", "type": "select"},
				{"value": "rating", "text": "Rating", "type": "select"},
				{"value": "remarks", "text": "Remarks", "type": "field"},
				{"value": "pos", "text": "Place of Supply", "type": "select"},
			],
			options_pos: [
				{text: "1 - JAMMU AND KASHMIR", value: 1},
				{text: "2 - HIMACHAL PRADESH", value: 2},
				{text: "3 - PUNJAB", value: 3},
				{text: "4 - CHANDIGARH", value: 4},
				{text: "5 - UTTARAKHAND", value: 5},
				{text: "6 - HARYANA", value: 6},
				{text: "7 - DELHI", value: 7},
				{text: "8 - RAJASTHAN", value: 8},
				{text: "9 - UTTAR PRADESH", value: 9},
				{text: "10 - BIHAR", value: 10},
				{text: "11 - SIKKIM", value: 11},
				{text: "12 - ARUNACHAL PRADESH", value: 12},
				{text: "13 - NAGALAND", value: 13},
				{text: "14 - MANIPUR", value: 14},
				{text: "15 - MIZORAM", value: 15},
				{text: "16 - TRIPURA", value: 16},
				{text: "17 - MEGHLAYA", value: 17},
				{text: "18 - ASSAM", value: 18},
				{text: "19 - WEST BENGAL", value: 19},
				{text: "20 - JHARKHAND", value: 20},
				{text: "21 - ODISHA", value: 21},
				{text: "22 - CHATTISGARH", value: 22},
				{text: "23 - MADHYA PRADESH", value: 23},
				{text: "24 - GUJARAT", value: 24},
				{text: "25 - DAMAN AND DIU", value: 25},
				{text: "26 - DADRA AND NAGAR HAVELI", value: 26},
				{text: "27 - MAHARASHTRA", value: 27},
				{text: "28 - ANDHRA PRADESH(BEFORE DIVISION)", value: 28},
				{text: "29 - KARNATAKA", value: 29},
				{text: "30 - GOA", value: 30},
				{text: "31 - LAKSHWADEEP", value: 31},
				{text: "32 - KERALA", value: 32},
				{text: "33 - TAMIL NADU", value: 33},
				{text: "34 - PUDUCHERRY", value: 34},
				{text: "35 - ANDAMAN AND NICOBAR ISLANDS", value: 35},
				{text: "36 - TELANGANA", value: 36},
				{text: "37 - ANDHRA PRADESH (NEW)", value: 37},
				{text: "18 / AS - ASSAM (DO NOT SELECT | VIEW ONLY)", value: 'AS'},
			],
			options_transport: [
				{text: "SELF (Own Vehicle)", value: 'self'},
				{text: "Company Support", value: 'company'},
				{text: "Not Applicable", value: 'n/a'},
			],
			options_availability: [
				{text: "5: Readily Available", value: 5},
				{text: "4: Mostly Available", value: 4},
				{text: "3: Less Available", value: 3},
				{text: "2: Rarely Available", value: 2},
				{text: "1: Highly Unavailable", value: 1},
			],
			options_reliability: [
				{text: "5: Quite Reliable", value: 5},
				{text: "4: Fairly Reliable", value: 4},
				{text: "3: Reliable", value: 3},
				{text: "2: Less Reliable", value: 2},
				{text: "1: Not Reliable", value: 1},
			],
			options_experience: [
				{text: "5: Highly Experienced", value: 5},
				{text: "4: Fairly Experienced", value: 4},
				{text: "3: Quite Experienced", value: 3},
				{text: "2: Less Experienced", value: 2},
				{text: "1: Not Experienced", value: 1},
			],
			options_rating: [
				{text: "5: Very Good", value: 5},
				{text: "4: Good", value: 4},
				{text: "3: Fair", value: 3},
				{text: "2: Okay", value: 2},
				{text: "1: Not Good", value: 1},
			],
			options_credit_period: [
				{text: "No Credit", value: 0},
				{text: "7 Days", value: 7},
				{text: "15 Days", value: 15},
				{text: "One Month", value: 30},
			],
			options_payment_cycle: [
				{text: "1st to 7th", value: 1},
				{text: "8th to 14th", value: 2},
				{text: "15th to 21st", value: 3},
				{text: "22nd to end", value: 4},
			],
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'ledger_name',
			data_rows_sort_order: 'asc',
			data_rows_search_field: 'ledger_name',
			data_rows_search: '',
			data_rows_filter: {
			},
		}
	}

	componentDidMount() {
		this.getDataRows(this.state);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};

		//SELECTIVE OPERATIONS
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		state[data.name] = data.value;
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		new_data_row[data.name] = new_data_row[data.name];
		if (data.name=="GSTIN") {
			data.value = data.value.trim()
			let gst_code = data.value.slice(0,2)
			new_data_row['pos'] = parseInt(gst_code)
		}
		if(data.name == "pos"){
			new_data_row[data.name] = parseInt(data.value)
		}
		this.setState({
			new_data_row: new_data_row
		},()=>{console.log(this.state.new_data_row);
		})
	}

	convertSelectToText(key, value){
		console.log(this.state['options_' + key],key);

		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value ? value + ' : N/A' : 'N/A';
	}
	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	createNewDataRow(){
		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createVendor(state.new_data_row, this.state.editMode).then(res=>{
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {}
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getVendors(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}

	render(){
		return(
			<div style={{fontSize:'10px'}}>
				<Button onClick={() => this.setState({showCreateDataModal:true})} primary>CREATE</Button>
				<div style={{float: 'right'}}>
					<Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
					<Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}></Input>
					<Button icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					{'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
					<Button icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
				</div>
				<Table color="blue" key="blue" collapsing>
					<Table.Header>
						<Table.Row>
						{
							this.state.tableFields.map((field) => (
								<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
									{field.text}
									{
										this.state.data_rows_sort_order == "asc" ?
											<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										:
											<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
									}
								</Table.HeaderCell>
							))
						}
						{
							this.state.tableButtons.map((field) => (
								<Table.HeaderCell>
									{field.text}
								</Table.HeaderCell>
							))
						}
						</Table.Row>
					</Table.Header>

					<Table.Body>
					{
						this.state.data_rows.map((data_row, index) => (
							<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
							{
								this.state.tableFields.map((field) => (
									<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
								))
							}
							{
								this.state.tableButtons.map((field) => (
									<Table.Cell>{field.type == "button" ? <Button style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
								))
							}
							</Table.Row>
						))
					}
					</Table.Body>
				</Table>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.setState({showCreateDataModal:false, new_data_row: {}, editMode: false, creatingNewDataModal: false})}>
					<Modal.Content>
						<Form size="mini" onSubmit={() => this.createNewDataRow()}>
							<Form.Group widths="equal">
								<Form.Input type="text" required fluid name="ledger_name" value={this.state.new_data_row.ledger_name} label="Name (As In Invoice & GSTIN)" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" required fluid name="contact_person" value={this.state.new_data_row.contact_person} label="Contact Person" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="email" value={this.state.new_data_row.email} label="Email" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" required fluid name="phone" value={this.state.new_data_row.phone} label="Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" required fluid name="alt_phone" value={this.state.new_data_row.alt_phone} label="Alternate Phone" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="address" value={this.state.new_data_row.address} label="Address" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select options={this.props.cities} required name="city" value={this.state.new_data_row.city} label="City" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="text" required name="pincode" value={this.state.new_data_row.pincode} label="Pincode" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="GSTIN" value={this.state.new_data_row.GSTIN} label="GSTIN" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select
										label="Place of Supply"
										name="pos"
										fluid required
										search
										selection
										placeholder="Select POS"
										options={this.state.options_pos}
										value={this.state.new_data_row.pos}
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="categories" value={this.state.new_data_row.categories} label="Categories" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select
										label="Mode Of Transport"
										name="transport"
										fluid required
										search
										selection
										placeholder="Select Mode Of Transport"
										options={this.state.options_transport}
										value={this.state.new_data_row.transport}
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select
										label="Credit Duration"
										name="credit_period_days"
										fluid required
										search
										selection
										placeholder="Select Duration"
										options={this.state.options_credit_period}
										value={this.state.new_data_row.credit_period_days}
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
									/>
								<Form.Input type="text" required fluid name="credit_amount" value={this.state.new_data_row.credit_amount} label="Allowed Credit Amount" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select
											label="Availability"
											name="availability"
											fluid required
											search
											selection
											placeholder="Select Availability"
											options={this.state.options_availability}
											value={this.state.new_data_row.availability}
											onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
									<Form.Select
											label="Reliability"
											name="reliability"
											fluid required
											search
											selection
											placeholder="Select Reliability"
											options={this.state.options_reliability}
											value={this.state.new_data_row.reliability}
											onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
							</Form.Group>
							<Form.Group widths='equal'>
								<Form.Select
										label="Experience"
										name="experience"
										fluid required
										search
										selection
										placeholder="Select Experience"
										options={this.state.options_experience}
										value={this.state.new_data_row.experience}
										onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
									<Form.Select
											label="Rating"
											name="rating"
											fluid required
											search
											selection
											placeholder="Select Rating"
											options={this.state.options_rating}
											value={this.state.new_data_row.rating}
											onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
										/>
							</Form.Group>
							<Form.Group widths="equal">
								<Form.Input type="text" required fluid name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default AccountingVendor;
