import React, {Component} from 'react';
import PIcon from '../assets/blue/ic_p.png';
import DIcon from '../assets/blue/ic_d.png';
import { Header, Modal, Table, Icon } from 'semantic-ui-react';

class OrderProductsList extends Component{

    state = {
        open: false,
        order: this.props.order,
        cancelReason: "N/A",
        headOrder: this.props.headOrder
    };

    componentWillReceiveProps(nextProps){
        this.setState({
            order: nextProps.order,
            headOrder: nextProps.headOrder
        })
    }

    handleListOpen = () => {
        this.setState({open: true});
    };

    handleListClose = () => {
        this.setState({open: false});
    };

    showTrackingLog(){
        this.setState({
            showBookingTracking: true
        })
    }

    render(){

        let total = 0;

        let products = [];

        this.state.order.products.map((item)=>
            {
                total += item.price*item.quantity;
                products.push(
                    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                        <img src={item.delivery ? DIcon : PIcon} width="20px" height="20px" style={{padding:'2px'}}/>
                        <div className="ui grid sales-product-list-row" style={{flexGrow:1}}>
                            <div className="five wide column">{item.productName}</div>
                            <div className="five wide column">{item.shopName}</div>
                            <div className="two wide column">{item.quantity}</div>
                            <div className="two wide column">{item.price}</div>
                            <div className="two wide column">{item.price*item.quantity}</div>
                        </div>
                    </div>
                )
            }
        )

        if(this.state.open){
            return(
                <div>
                    <Icon name='time' size='big' onClick={() => this.showTrackingLog()}/>
                    <div className="ui grid sales-product-list-header" onClick={this.handleListClose}>
                        <div className="five wide column"><strong>Product</strong></div>
                        <div className="five wide column"><strong>Store</strong></div>
                        <div className="two wide column"><strong>Qty</strong></div>
                        <div className="two wide column"><strong>Price</strong></div>
                        <div className="two wide column"><strong>Amount</strong><span className="right floated"><i className="caret up icon"></i></span></div>
                    </div>
                    {
                        products
                    }
                    <div className="ui grid">
                        <div className="fourteen wide column"><strong>Total Amount:</strong></div>
                        <div className="two wide column">{total}</div>
                    </div>
                    <div className="ui sixteen column grid">
                        <span className="column"><strong>Additional Comments:</strong></span>
                        <span className="column">{this.state.order.additionalComments}</span>
                    </div>
                    <h3>Delivery Details:</h3>
                    {this.state.order.pickupDetails?
                        <div className="ui two column grid">
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Name:</strong></span>
                                    <span className="column">{this.state.order.pickupDetails.name}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Phone:</strong></span>
                                    <span className="column">{this.state.order.pickupDetails.phone}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Pickup Date And Time:</strong></span>
                                    <span className="column">{this.state.order.pickupDateAndTime}</span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="ui two column grid">
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Name:</strong></span>
                                    <span className="column">{this.state.order.deliveryAddress.name}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Phone:</strong></span>
                                    <span className="column">{this.state.order.deliveryAddress.phone}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Address:</strong></span>
                                    <span className="column">{this.state.order.deliveryAddress.addressLine1 + ", " + this.state.order.address}</span>
                                </div>
                            </div>
                            <div className="column">
                                <div className="ui three column grid">
                                    <span className="column thin"><strong>Delivery Date And Time:</strong></span>
                                    <span className="column">{this.state.order.deliveryDateAndTime}</span>
                                </div>
                            </div>
                        </div>
                    }
                    <h3>Payment Details:</h3>
                    <div className="ui two column grid">
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Payment Mode:</strong></span>
                                <span className="column">{this.state.order.payment}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Order Amount:</strong></span>
                                <span className="column">{this.state.order.orderAmount}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Delivery Charges:</strong></span>
                                <span className="column">{this.state.order.deliveryCharge}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Total Amount:</strong></span>
                                <span className="column">{this.state.order.totalAmount}</span>
                            </div>
                        </div>
                    </div>

                    <Modal
                        open={this.state.showBookingTracking}
                        onClose={() => this.setState({showBookingTracking: false})}
                        basic
                        size='small'
                        >
                        <Header icon='time' content='Tracking Details' />
                        <Modal.Content>
                        <Table celled>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell>STATUS</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED ON</Table.HeaderCell>
                                <Table.HeaderCell>UPDATED BY</Table.HeaderCell>
                                <Table.HeaderCell>REMARKS</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>

                            <Table.Body>
                            {
                                this.state.headOrder.orderTracking.length > 0
                                ?
                                this.state.headOrder.orderTracking.map((tracking) => (
                                    <Table.Row>
                                        <Table.Cell>
                                            {tracking.status}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedOn}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.updatedBy}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {tracking.reason}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                :
                                <Table.Row><Table.Cell>NO STATUS UPDATED YET</Table.Cell></Table.Row>
                            }
                            </Table.Body>
                        </Table>
                        </Modal.Content>
                    </Modal>
                </div>
            )
        }
        return(
            <div className="sales-product-list-header" onClick={this.handleListOpen}>
                <strong>More Details:</strong><span className="right floated"><i className="caret down icon"></i></span>
            </div>
        )
    }
}

export default OrderProductsList;
