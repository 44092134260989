import React,{Component} from 'react';
import ProductCard from "./product-card";
import Client from "../services/Client";
import ProductDetails from "./productDetails";
class Business extends Component{
    constructor(props){
        super(props);
        this.state={
            showDetails:false,
            businessDetails:props.business,
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            businessDetails:nextProps.business
        })
    }

    updateBusiness(){
        console.log("yes");
        Client.updateBusiness(this.state.businessDetails).then((res)=>{
            // this.setState({
            //     selectedBusinessType:val,
            //     businesses:res.businessesList,
            //     fetching:false
            // })
        });
    }

    attemptDirect(){
        console.log("yes");
        Client.attemptDirectBusiness(this.state.businessDetails).then((res)=>{
            // this.setState({
            //     selectedBusinessType:val,
            //     businesses:res.businessesList,
            //     fetching:false
            // })
        });
    }

    // onInputChange(e){
    //     const val=e.target.value;
    //     if(e.target.name=="vendor"){
    //         if(val){
    //             this.suggestVendors(val);
    //         }else{
    //             console.log("val"+val);
    //             this.setState({vendorSuggestions: []})
    //         }
    //
    //     }
    //     const product=this.state.product;
    //     product[e.target.name]=val+"";
    //     this.setState({
    //         product:product
    //     })
    // }

    onApprovalChange(e){
        const val=e.target.value;
        let details = this.state.businessDetails;
        details.archive = val;
        this.setState({
            businessDetails:details
        })
        this.updateBusiness();
    }

    render(){
        console.log(this.state.businessDetails);
        return(
            <div className="ui fluid card">
                <div className="content">
                    <div className="ui two column grid">
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Business Id:</strong></span>
                                <span className="column">{this.state.businessDetails.storeId}</span>
                                <button className="ui fluid red submit button column thin" value="1" onClick={this.attemptDirect.bind(this)}>Direct</button>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Business Name</strong></span>
                                <span className="column">{this.state.businessDetails.storeName}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Address:</strong></span>
                                <span className="column">{this.state.businessDetails.address}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Contact:</strong></span>
                                <span className="column">{this.state.businessDetails.contactPrimary + ", " + this.state.businessDetails.contactSecondary}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Created At:</strong></span>
                                <span className="column">{this.state.businessDetails.createdAt}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Updated At:</strong></span>
                                <span className="column">{this.state.businessDetails.updatedAt}</span>
                            </div>
                        </div>

                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Owner:</strong></span>
                                <span className="column">{this.state.businessDetails.ownerName}</span>
                            </div>
                        </div>
                        <div className="column">
                            <div className="ui three column grid">
                                <span className="column thin"><strong>Timings:</strong></span>
                                <span className="column">{this.state.businessDetails.openAt + "-" + this.state.businessDetails.closeAt}</span>
                            </div>
                        </div>
                        {this.state.businessDetails.archive == 1?
                        <button className="ui fluid large green submit button" value="0" onClick={this.onApprovalChange.bind(this)}>Approve</button>
                        :
                        <button className="ui fluid large red submit button" value="1" onClick={this.onApprovalChange.bind(this)}>Disapprove</button>
                        }
                    </div>
                </div>
            </div>
        )
        // if (this.state.showDetails) {
        //     return <ProductDetails variant={this.state.selectedVariant} productName={this.state.productName}
        //                            variants={this.state.variants} onClose={() => {
        //         this.setState({
        //             showDetails: false
        //         })
        //     }}/>;
        // } else {
        //     return <ProductCard variant={this.state.selectedVariant} productName={this.state.productName}
        //                         onClick={() => {
        //                             this.setState({
        //                                 showDetails: true
        //                             })
        //                         }}/>;
        // }
    }
}
export default Business;
