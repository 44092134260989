import React,{Component} from  'react';
import Client from "../services/Client";
import StoreDetails from '../components/storeDetailsContainer'
import AddShopForm from "../components/add-shop-form";
import TopBarInside from "../components/topbar-inside";
import Calender from "../components/calender"
import Daydetails from "../components/day-details"
import { Dropdown, Input,Divider,Grid, Select, Loader, Label , Dimmer, Button, GridColumn } from 'semantic-ui-react'
import { BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
import '../style/home.css';
import '../style/attendance.css';
import moment from 'moment';

class Attendance extends Component{
    constructor(props){
        super(props);
        this.state={
            staffs:"",
            graph_staff:false,
            day_details : false,
            observedStaff:JSON.parse(localStorage.getItem('userData')).staff_id,
            leave_type : false,
            options_leave:[
                {value:'SL',text:'Sick Leave'},
                {value:'CL',text:'Casual Leave'},
                {value:'HD',text:'Half Day'},
                {value:'WH',text:'Work From Home'},
                {value:'HO',text:'Holiday'},
                {value:'UL',text:'Unpaid Leave'},
            ],
            loading_report:false,
            attendanceList:[
                    "present",
                    "present",
                    "present",
                    "present",
                    "absent",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "absent",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "absent",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                    "present",
                ]
        }
    }
    componentDidMount(){
        this.setState({start_date:this.range(false),end_date:this.range(true),})
        Client.getStaffs(null, null, null, null, null, null, {},"attendance").then(res=>{
            let staffs=[];
            res.data_rows.map(staff =>{
                const individuals = {key:staff.id,value:staff.id,text:staff.name}
                staffs.push(individuals)
            })
            this.setState({staffs},()=>{
                var date = new Date()
                var month = date.getMonth() + 1
                var year = date.getFullYear()
                var separator = month >= 10 ? '-': '-0'
                var yearMonth = year + separator + month;
                this.setState({month:yearMonth},
                    ()=>{
                        this.getAttendance(this.state.month)
                    }
                )
            })
        })
    }
    //Console Handlers
    onMonthChange(data){
        this.setState(
            {month:data.format('YYYY-MM')},
             ()=>{
                 this.getAttendance(this.state.month)
             }
        )
    }
    onChangeStaff(event, data){
        this.setState(
            {observedStaff:data.value},
            ()=>{
                this.getAttendance(this.state.month)
            }    
        )
    }
    onDayClick(e,day){
        var day_details = this.state.attendanceList[day-1]
        this.setState({day_details})
    }
    onYearChange(data){
        alert(data.format('YYYY/MM/DD'))
    }
    //Utilities
    getAmountOfWeekDaysInMonth( weekday= 0 ){
        let date = this.state.month +"-01"
        date = moment(date);
        var daysInMonth = date.daysInMonth()
        date.date(1);
        var dif = (7 + (weekday - date.weekday()))%7+1;
        return daysInMonth - (Math.floor((date.daysInMonth()-dif) / 7)+1);
    }
    getGeneralHolidays( weekday= 0 ){
        let date = this.state.month +"-01"
        date = moment(date);
        var daysInMonth = date.daysInMonth()
        date.date(1);
        var dif = (7 + (weekday - date.weekday()))%7+1;
        return Math.floor((date.daysInMonth()-dif) / 7)+1;
    }
    //api handlers
    getAttendance(month){
        var filter = {staff:[this.state.observedStaff]}
        this.setState({showCalender:false,day_details:false})
        Client.getAttendance(filter,month,{},"get")
        .then(res=>{
            var data = res.data_rows
            var attendanceList = []
            for (let index = 1; index < 32; index++) {
                var separator = index >= 10 ? '-' :'-0'
                let date = month + separator + index
                let slice = data.filter((elem)=>{
                    return date == elem.date
                })
                if (slice.length > 0) {
                    attendanceList.push(slice[0])
                } else {
                    attendanceList.push({date:date,lat_in:0,long_in:0,time_in:null,time_out:null,lat_out:null,long_out:null, leave_type:null})
                }
            }
            var sick_leaves = 0
            var casual_leaves = 0
            var unpaid_leaves = 0
            var half_days = 0
            var holidays = 0
            var work_from_home = 0
            var undefined_leaves  = 0
            attendanceList.forEach(elem=>{
                switch (elem.leave_type) {
                    case 'SL':
                            sick_leaves++
                        break;
                    case 'CL':
                        casual_leaves++
                    break;
                    case 'UL':
                        unpaid_leaves++
                    break;
                    case 'HD':
                        half_days++
                    break;
                    case 'HO':
                        holidays++
                    break;
                    case 'WH':
                        work_from_home++
                    break;
                    default:
                        break;
                }
                if(elem.time_in == null && elem.leave_type == null){
                    undefined_leaves++
                }
            })
            var date = new Date()
            var today = date.getDate() -1
            undefined_leaves= undefined_leaves - this.getGeneralHolidays(0)
            this.setState({
                showCalender:true,
                attendance:res.data_rows,
                attendanceList:attendanceList,
                day_details:attendanceList[today],
                sick_leaves,unpaid_leaves,casual_leaves,half_days,holidays,undefined_leaves,work_from_home
            },()=>{
               var workingDays =  this.getAmountOfWeekDaysInMonth(0)
               this.setState({
                   workingDays
                })
            })
        })
    }
    mark(mark){
        var new_data_row = {
            staff : this.state.observedStaff,
            date:this.state.day_details.date,
            mark_type:2,
            leave_type:mark
        }
        Client.markLeave(new_data_row).then(()=>{
            this.setState({day_details:false},()=>{
                this.getAttendance(this.state.month)
            })
        })
    }
    punchIn(date){
        alert("punch in on "+ date)
        var new_data_row = {
            staff : this.state.observedStaff,
            date:this.state.day_details.date,
            lat:0,
            long:0,
            mark_type:1,
        }
        Client.markLeave(new_data_row).then(()=>{
            this.setState({day_details:false},()=>{
                this.getAttendance(this.state.month)
            })
        })
    }
    punchOut(date){
        alert("punch out on "+date)
        var new_data_row = {
            staff : this.state.observedStaff,
            date:this.state.day_details.date,
            lat:0,
            long:0,
            mark_type:0,
        }
        Client.markLeave(new_data_row).then(()=>{
            this.setState({day_details:false},()=>{
                this.getAttendance(this.state.month)
            })
        })
    }
    // Graphs
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}
	occuranceOfLabelInAxis(label_value,lable_name,axis_value,axis_name,data_rows){
		var matched = data_rows.filter((elem)=>{
			return elem[axis_name] == axis_value && elem[lable_name] == label_value
		})
		return matched.length
    }
    leavesWithMonth(array,leaveType) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
        let leaveArray = []
        array.forEach((elem)=>{
            let date = elem.date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
            if (leaveType.length>0) {
                if (leaveType.includes(elem.leave_type) ) { // for specific leave types
                    let temp = {...elem,month:month+'-'+date[0]}
                    leaveArray.push(temp)
                }
            }
            else if(elem.leave_type != null || elem.time_in == null){ // For All leave Types
                let temp = {...elem,month:month+'-'+date[0]}
                leaveArray.push(temp)
            }
        })
        console.log("Leave array",leaveArray);
        return leaveArray
    }
    countReportLeaves(data_rows){
        var sick_leaves = 0
        var casual_leaves = 0
        var unpaid_leaves = 0
        var half_days = 0
        var holidays = 0
        var undefined_leaves  = 0
        var present_days = 0
        var work_from_home = 0
        data_rows.forEach(elem=>{
            switch (elem.leave_type) {
                case 'SL':
                        sick_leaves++
                    break;
                case 'CL':
                    casual_leaves++
                break;
                case 'UL':
                    unpaid_leaves++
                break;
                case 'HD':
                    half_days++
                break;
                case 'HO':
                    holidays++
                break;
                case 'WH':
                    work_from_home++
                break;
                default:
                    break;
            }
            if(elem.time_in != null && elem.leave_type == null){
                present_days++
            }
        })
        const report_leaves = {
            sl:sick_leaves,
            cl:casual_leaves,
            ul:unpaid_leaves,
            hd:half_days,
            ho:holidays,
            pd:present_days,
            wh:work_from_home
        }
        this.setState({report_leaves})
    }
	prepareGraphData(label,axis){	
		// this.setState({[axis+'_graph']:[]})		
        var data_rows = []
        var stfIDs = []
        this.state.staffs.forEach((elem)=>{
            stfIDs.push(elem.value)
        })
        stfIDs = this.state.graph_staff.length >0 ? this.state.graph_staff : stfIDs
		let filter={staff:[...stfIDs]}
        let range = {date :[this.state.start_date, this.state.end_date]}
        this.setState({loading_report:true,report_leaves:false})
		Client.getAttendance(filter,"",range,axis).then((res)=>{
            if (this.state.graph_staff.length == 1) { //Report aside Graph
                this.countReportLeaves(res.data_rows)
            }
            let leaveType = this.state.leave_type?this.state.leave_type:[]					
			data_rows = this.leavesWithMonth(res.data_rows,leaveType)//onlyLeaves
			var master_data = {}
			var distinct_axises = this.getDistictIn(axis,data_rows)
			for(var key in distinct_axises){
				var distinct_axis_data = distinct_axises[key]
				master_data[key] = this.getDistictIn(label,distinct_axis_data)				
			}
			let data_for_graph = []
			for(var key in master_data){
				var value = master_data[key]
				var mapping = {}
				for(var key1 in value){
					let name = this.staffName(key1)
					mapping[name] = value[key1].length
				}
				data_for_graph.push({
					name:key, ...mapping
				})
			}
			console.log(data_for_graph)
			this.setState({[axis + '_graph']:data_for_graph,loading_report:false})
		})
	}
	range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0' 
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
	handleGraphParamChange(e,data){
		this.setState({
			[data.name]:data.value
		},()=>{
			this.prepareGraphData("staff","month")		
		})
	}
	staffName = (staff)=>{
		var staffarr = this.state.staffs.filter((elem)=>{
			return elem.value == staff
		})
		console.log(staff);
		return staffarr[0].text
	}
	showReportModal(){
		this.prepareGraphData("staff","month")
		this.setState({modalReport:true})
	}
    render(){
        return (
            <React.Fragment >
                {
                    JSON.parse(localStorage.getItem('userData')).role == "admin" || 
                    JSON.parse(localStorage.getItem('userData')).role == "city_head" || 
                    JSON.parse(localStorage.getItem('userData')).role == "hr"
                    ?
                        <Grid.Row >
                            <Dropdown onChange={(e,data)=>this.onChangeStaff(e,data)}
                                placeholder='Select Staff'
                                search selection options={this.state.staffs} 
                            />
                            <hr></hr> 
                        </Grid.Row>
                    :
                    null
                }
                <Grid stackable>
                    <Grid.Row  divided >
                        <Grid.Column  width={5}>
                            <Calender 
                                show={this.state.showCalender}
                                width="100%"
                                attendanceList={this.state.attendanceList} 
                                onDayClick={this.onDayClick.bind(this)}
                                onMonthChange={this.onMonthChange.bind(this)}
                                onYearChange={this.onYearChange.bind(this)}
                                />
                        </Grid.Column>
                        <Grid.Column  width={6}>
                            <Daydetails punchIn={this.punchIn.bind(this)} punchOut={this.punchOut.bind(this)} mark={this.mark.bind(this)} day_details={this.state.day_details}/>
                        </Grid.Column>    
                        <Grid.Column width={4}>
                            <span className="info">
                                No of Working Days : {this.state.workingDays || '--'}
                            </span>
                            <span className="report">
                                No of Sick Leaves : {this.state.sick_leaves}
                                <br></br>
                                No of Casual Leaves : {this.state.casual_leaves}
                                <br></br>
                                No of Half Days : {this.state.half_days}
                                <br></br>
                                No of Unpaid Leaves : {this.state.unpaid_leaves }
                                <br></br>
                                No of Holidays : {this.state.holidays}
                                <br></br>
                                Work from home : {this.state.work_from_home}
                                <br></br>
                                No of undefined_leaves : {this.state.undefined_leaves}
                                <br></br>
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                    <div style={{display:'block'}}>
                        <div style={{display:"inline-flex",marginRight:'10px'}}>
                            <h1>Report</h1>
                        </div>
                        <div style={{display:'inline-flex'}}>
                            <Dropdown 
                                multiple
                                selection
                                search
                                placeholder="Choose Staffs"
                                name="graph_staff"
                                options={this.state.staffs}
                                    onChange={(e, data) => this.handleGraphParamChange(e, data)}
                                    /> 
                            <Dropdown 
                                multiple
                                search
                                selection
                                mini
                                name="leave_type" 
                                placeholder="Choose Leave Type"
                                options={this.state.options_leave}
                                onChange={(e, data) => this.handleGraphParamChange(e, data)} />
                            <Input size={this.state.defaultSize} type="date" name="start_date" label={{ content: 'FROM' }}
                                placeholder="Start Date" value={this.state.start_date} onChange={(e, data) => this.handleGraphParamChange(e, data)}/>
                            <Input size={this.state.defaultSize} type="date" name="end_date" label={{ content: 'TO' }}
                                placeholder="End Date" value={this.state.end_date} onChange={(e, data) => this.handleGraphParamChange(e, data)}/>
                        </div>
                        <hr></hr>
                    </div>
                    <Grid.Row divided>
                       <Grid.Column width={4}>
                           {
                               this.state.report_leaves
                               ?
                                <>
                                    <span className="info">
                                        Staff Was Present: 
                                        {
                                            this.state.report_leaves.pd
                                        } days
                                    </span>
                                    <span className="report">
                                        No of Sick Leaves : {this.state.report_leaves.sl}
                                        <br></br>
                                        No of Casual Leaves : {this.state.report_leaves.cl}
                                        <br></br>
                                        No of Half Days : {this.state.report_leaves.hd}
                                        <br></br>
                                        No of Unpaid Leaves : {this.state.report_leaves.ul}
                                        <br></br>
                                        No of Holidays : {this.state.report_leaves.ho}
                                        <br></br>
                                        Work From Home : {this.state.report_leaves.wh}
                                        <br></br>
                                    </span>
                                    <span className="info">
                                        Total Leaves : 
                                        {
                                        this.state.report_leaves.ho
                                            + this.state.report_leaves.ul+ this.state.report_leaves.hd
                                            + this.state.report_leaves.cl+ this.state.report_leaves.sl+ this.state.report_leaves.wh
                                        }
                                    </span>
                                </>
                               :
                               null
                           }

                       </Grid.Column>
                       <Grid.Column width={12}>
                       {
                            this.state.month_graph
                            ?
                            <LineChart width={500} height={300} data={this.state.month_graph.reverse()}
                                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="10 10" />
                                <XAxis dataKey="name" />
                                <YAxis   allowDecimals={false} />
                                <Tooltip />
                                <Legend />
                                {	
                                    this.state.graph_staff.length > 0?
                                    this.state.graph_staff.map((staff,i)=>
                                        <Line type="monotone" dataKey={this.staffName(staff)} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} label={this.staffName(staff)} />
                                    )
                                    :
                                    this.state.staffs.map((staff,i)=>
                                        <Line type="monotone" dataKey={staff.text} stroke={"#"+Math.floor(100000 + Math.random() * 900000)} label={this.staffName(staff.value)}/>
                                    )
                                }
                            </LineChart>
                            :
                            (this.state.loading_report
                            ?
                            <Dimmer>
                                <Loader large color="black">Loading Graph</Loader>
                            </Dimmer>
                            :null)
                        }
                       </Grid.Column>
                    </Grid.Row>
                </Grid>
            </React.Fragment>
        )
    }
}
export default Attendance;
