import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {Table, Button} from 'semantic-ui-react';

import '../style/print.css';

class PrintBookings extends Component{

	render(){

		const bookings = localStorage.getItem('bookingsForPrint')?JSON.parse(localStorage.getItem('bookingsForPrint')):[];

		return(
			<div>
				<Link to='/bookings'><Button className="noprint" style={{marginLeft: '1em', marginTop: '1em'}}>Back</Button></Link>
				<Button className="noprint" color="green" style={{marginLeft: '0.5em', marginTop: '1em'}} onClick={function(){window.print();}}>Print</Button>
				<Table className="booking-table">
					<Table.Header>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>Date</Table.HeaderCell>
						<Table.HeaderCell>Item</Table.HeaderCell>
						<Table.HeaderCell>Name</Table.HeaderCell>
						<Table.HeaderCell>Phone</Table.HeaderCell>
						<Table.HeaderCell>Address</Table.HeaderCell>
						<Table.HeaderCell>Amount</Table.HeaderCell>
						<Table.HeaderCell>Status</Table.HeaderCell>
						<Table.HeaderCell>Pay Mode</Table.HeaderCell>
						<Table.HeaderCell>Pay Status</Table.HeaderCell>
					</Table.Header>
					<Table.Body>
						{
							bookings.map((booking)=>(
								<Table.Row>
									<Table.Cell>{booking.bookingId}</Table.Cell>
									<Table.Cell>{booking.bookingDateAndTime ? booking.bookingDateAndTime.substr(6,10)+', '+booking.bookingDateAndTime.substr(23,5) : null}</Table.Cell>
									<Table.Cell>{booking.item.substr(0,20)}</Table.Cell>
									<Table.Cell>{booking.customerName.substr(0,15)}</Table.Cell>
									<Table.Cell>{booking.customerPhone.substr(0,15)}</Table.Cell>
									<Table.Cell>{booking.deliveryAddress.toUpperCase()[0]+booking.deliveryAddress.substr(1,30)}</Table.Cell>
									<Table.Cell>{booking.amount}</Table.Cell>
									<Table.Cell>{booking.orderStatus.substr(0,10)}</Table.Cell>
									<Table.Cell>{booking.paymentMode.substr(0,20)}</Table.Cell>
									<Table.Cell>{booking.paymentStatus.substr(0,15)}</Table.Cell>
								</Table.Row>
							))
						}
					</Table.Body>
				</Table>
			</div>
		)
	}

}

export default PrintBookings;
