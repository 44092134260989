import React, {Component} from 'react';
import '../style/suggestion.css';

class AddPurchaseSuggestion extends Component{

	handleClick = () => {
		this.props.click(this.props.name, this.props.barcode, this.props.price, this.props.id);
	}

	render(){
		return(
		       <div className="ui five column grid suggestion" onClick={this.handleClick}>
		       		<div className="column">{this.props.barcode}</div>
	                <div className="column">{this.props.name}</div>	                
	                <div className="column">{this.props.price}</div>
	            </div>	
		)
	}
}

export default AddPurchaseSuggestion;