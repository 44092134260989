import React, {Component} from 'react';

class AddPurchaseProductAdded extends Component{

	handleQuantity = (e) => {
		this.props.onQuantityChange(this.props.index, e.target.value);
        this.props.updateTotal();
	}

    handleCostPrice = (e) => {
        this.props.onCostPriceChange(this.props.index, e.target.value);
    }

    handleRemove = () => {
        this.props.onremove(this.props.index);
        this.props.updateTotal();
    }

    componentDidMount() {
        this.props.updateTotal();
    }

    render(){
        return(
            <tr>
                <td><i className="large red remove circle icon" style={{marginTop: "-2px", cursor: "pointer"}} onClick={this.handleRemove}></i>&nbsp;&nbsp;{this.props.product.barcode}</td>
                <td>{this.props.product.name}</td>
                <td>{this.props.product.price}</td>
                <td><input type="number" value={this.props.product.costPrice||this.props.product.price} onChange={this.handleCostPrice.bind(this)}></input></td>
                <td><input type="number" value={this.props.product.quantity} onChange={this.handleQuantity.bind(this)}/></td>
            </tr>
        )
    }
}

export default AddPurchaseProductAdded;
