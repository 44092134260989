import React, {Component} from 'react';
import $ from 'jquery';
import Order from "../components/order";
import OrderRow from "../components/order-row";
import Client from '../services/Client'

import { Table } from 'semantic-ui-react';

class Orders extends Component{

    state = {
        disabled: false,
        orders: [],
        page: 0,
        morePage: 1,
        lastRequest: 0,
        storeId: 0,
        sort: '',
        sortOrder: ''
    }

    handleScroll(){
        // var container = document.getElementById('scroll');
        // if(container.scrollTop >= (container.scrollHeight-container.offsetHeight)){
        //     this.props.handleScroll();
        // }
    }

    componentDidMount() {

        this.setState({
            orders: this.props.orders
        })

        this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillReceiveProps(props){

        this.setState({
            orders: props.orders
        })
    }

    handleSort(sortBy){

        if(sortBy == this.state.sortBy){

            this.props.handleSort(sortBy, this.state.sortOrder=='' || this.state.sortOrder=='descending' ? 'asc' : 'desc');

            this.setState({
                sortOrder: this.state.sortOrder=='' || this.state.sortOrder=='descending' ? 'ascending' : 'descending',
                sortBy: sortBy
            })

        }else{

            this.setState({
                sortOrder: 'ascending',
                sortBy: sortBy
            })

            this.props.handleSort(sortBy, 'asc');
        }
    }

    render(){
        return(
            <div id="scroll" className="bookingsListContainer" onScroll={this.handleScroll}>
                {
                    this.props.refreshing
                    ?
                    <div style={{position: 'absolute', top: "50%", left: "50%"}}>
                        <div className="ui active inverted dimmer">
                            <div className="ui text loader">Loading</div>
                        </div>
                    </div>
                    :
                    this.props.view === 'card'
                    ?
                    <div className="bookingsListInnerContainer">
                        {
                            this.state.orders.map((item, key)=>(
                                <Order order={item} />
                            ))
                        }
                    </div>
                    :
                    this.props.view == 'list'
                    ?
                    <Table sortable style={{borderRadius: 0}}>
                        <Table.Header>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='orderId' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('orderId')}.bind(this)}>
                            ID</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='orderDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('orderDateAndTime')}.bind(this)}>
                            Order Date</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='deliveryDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('deliveryDateAndTime')}.bind(this)}>
                            Delivery Date</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerName' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerName')}.bind(this)}>
                            Customer</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerPhone' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerPhone')}.bind(this)}>
                            Customer Phone</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='amount' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('amount')}.bind(this)}>
                            Amount</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='orderStatus' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('orderStatus')}.bind(this)}>
                            Order Status</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.orders.map((order)=>(
                                    <OrderRow order={order} storeId={this.props.storeId} />
                                ))
                            }
                        </Table.Body>
                    </Table>
                    :
                    <Table sortable style={{borderRadius: 0, fontSize: '0.8em'}}>
                        <Table.Header>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='orderId' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('orderId')}.bind(this)}>
                            ID</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='orderDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('orderDateAndTime')}.bind(this)}>
                            Order Date</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='deliveryDateAndTime' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('deliveryDateAndTime')}.bind(this)}>
                            Delivery Date</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerName' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerName')}.bind(this)}>
                            Customer</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='customerPhone' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('customerPhone')}.bind(this)}>
                            Customer Phone</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='amount' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('amount')}.bind(this)}>
                            Amount</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {this.state.sortBy=='status' && this.state.sortOrder}
                                onClick = {function(){this.handleSort('status')}.bind(this)}>
                            Order Status</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {false}>
                            Payment Mode</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {false}>
                            Payment Status</Table.HeaderCell>
                            <Table.HeaderCell
                                sorted = {false}>
                            Products</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {
                                this.state.orders.map((order)=>(
                                    <OrderRow order={order} extended={true} storeId={this.props.storeId} />
                                ))
                            }
                        </Table.Body>
                    </Table>
                }
            </div>
        )
    }
}
export default Orders;
