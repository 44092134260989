
import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import '../style/mobilebar.css'
import { Dropdown, Input,Button,Icon } from 'semantic-ui-react'
// import LogOut from "../routes/logout";
class MobileBar extends Component {



    render() {
        return (
            <div id="mobilebar" className="ui  menu fluid wide"  style={{padding:0,background:"#000000",color:"#E1EDF9",borderRadius:0}}>
                <Button color="black"  style={{borderRadius:0,height:'30px',padding:'5px 20px'}}  onClick={this.props.toggle}> 
                    <Icon style={{color:"white"}} name={this.props.visbleSidebar?"left arrow":"right arrow"} />
                </Button>                
            </div>
        );
    }
}

export default MobileBar;
