
import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { Dropdown, Input } from 'semantic-ui-react'

const logo = require('../assets/logo.png')
// import LogOut from "../routes/logout";
class TopBarInside extends Component {

    state = {
        cities:this.props.cities
    }

    constructor(props){
        super(props);
        console.log(this.props.cities);
        this.setState({
            cities: this.props.cities
        })
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            cities: nextProps.cities
        })
    }

    componentDidMount(){
    }

    render() {
        return (
            <div style={{padding:0,background:"#CCCCCC",color:"#E1EDF9"}}>
                <div className="right menu">
                    <Dropdown
                    placeholder='Select City' search selection clearable
                    options={this.state.cities}
                    onChange={this.props.handleCityChange.bind(this)}
                    style={{borderRadius:'0px', height:'40px'}}
                    defaultValue={localStorage.getItem('city')}>
                    </Dropdown>
                </div>
            </div>
        );
    }
}

export default TopBarInside;
