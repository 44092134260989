import React,{Component} from 'react';
import Client from '../services/Client';
import _ from 'underscore';
import ProductSuggestion from './product-suggestion';
import VendorSuggestion from './add-product-vendor-suggestion';

class AddBusinessForm extends Component{
    constructor(props){

        super(props);

        this.state={
            // categories:JSON.parse(localStorage.getItem('tCategory')),
            // subCategories:[],
            // subSubCategories:[],
            business:{
                shopName:'',
                type:'',
                zipcode:'',
                latitude:'',
                longitude:'',
                address:'',
                city:'',
                contactPrimary:'',
                email: '',
                openAt: '',
                closeAt: ''
            },
        }
        this.onInputChange=this.onInputChange.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
    }
    componentWillMount(){
    }
    // handleAddPurchase(){
    //     this.setState({
    //         purchaseAlso:!this.state.purchaseAlso
    //     })
    // }
    handleCategoryClick(e){
       const val=e.target.value;
        this.setState({
            newCategory:'',
            subCategories:this.state.categories[val].ch,
            addSubCategory:true,
            selectedCategory:this.state.categories[val].i,
            selectedCategoryText:this.state.categories[val].c,
            selectedSubCategoryText:'Sub Category',
            selectedSubSubCategoryText:'Sub Sub Category',
            addNewSubSubCategory:false
        })
    }
    handleSubCategoryClick(e){
        const val=e.target.value;
        this.setState({
            newCategory:'',
            subSubCategories:this.state.subCategories[val].ch,
            addSubSubCategory:true,
            selectedSubCategory:this.state.subCategories[val].i,
            selectedSubCategoryText:this.state.subCategories[val].c,
            selectedSubSubCategoryText:'Sub Sub Category',
            addNewSubSubCategory:false
        })
    }

    onInputChange(e){
        const val=e.target.value;
        let business=this.state.business;
        business[e.target.name]=val+"";
        this.setState({
            business:business
        })
    }

    onSubmit(e){
        let business=this.state.business;
        console.log(business);
        Client.addBusiness(business).then(
            (res) => {
                if(res.responseResult==="success"){
                    alert("added");
                }
            }
        )
        e.preventDefault();
    }

    render(){
        return(
            <form className="ui form container">
                <h3 className="ui dividing header">Add Product</h3>

                <div className="field">
                    <input required type="text" name="shopName" value={this.state.product.description} onChange={this.onInputChange} placeholder="Business Name"/>
                </div>
                <div className="field">
                    <input required type="text" name="description" value={this.state.product.description} onChange={this.onInputChange} placeholder="Type"/>
                </div>
                <div className="three fields">
                    <div className="field">
                        <input required type="text"  name="variant" value={this.state.product.variant} onChange={this.onInputChange} placeholder="Variant"/>
                    </div>
                    <div className="field">
                        <input required type="number" name="HSN" value={this.state.product.HSN} onChange={this.onInputChange} placeholder="HSN" min={0}/>
                    </div>
                    <div className="field">
                        <input required type="number" name="stock" value={this.state.product.stock} onChange={this.onInputChange} placeholder="Stock" min={-2}/>
                    </div>
                </div>
                <div className="four fields">
                    <div className="field">
                        <div className="ui compact menu">
                            <div className="ui simple dropdown item" >
                                {this.state.selectedCategoryText||"Category"}
                                <i className="dropdown icon"></i>
                                <div className="menu" style={{maxHeight:'200px',overflowY:'auto'}}>
                                    {
                                        this.state.categories.map(
                                            (cat,index)=>(
                                                <option onClick={this.handleCategoryClick}  className="item" key={index} value={index}>
                                                    {
                                                        cat.c
                                                    }
                                                </option>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        {
                            this.state.addSubCategory?(
                                <div className="ui compact menu">
                                    <div className="ui simple dropdown item" >
                                        {this.state.selectedSubCategoryText||"Sub Category"}
                                        <i className="dropdown icon"></i>
                                        <div className="menu" style={{maxHeight:'200px',overflowY:'auto'}}>
                                            {
                                                this.state.subCategories.map(
                                                    (cat,index)=>(
                                                        <option onClick={this.handleSubCategoryClick.bind(this)}  className="item" key={index} value={index}>
                                                            {
                                                                cat.c
                                                            }
                                                        </option>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            ):('')
                        }
                    </div>
                    <div className="field">
                        {
                            this.state.addSubSubCategory?(
                                <div className="ui compact menu">
                                    <div className="ui simple dropdown item" >
                                        {this.state.selectedSubSubCategoryText||"Sub Sub Category"}
                                        <i className="dropdown icon"></i>
                                        <div className="menu" style={{maxHeight:'200px',overflowY:'auto'}}>
                                            {
                                                // sc
                                            }
                                        </div>
                                    </div>
                                </div>
                            ):('')
                        }
                    </div>
                    <div className="field">
                        {
                            this.state.addNewSubSubCategory?(
                                <input required type="text" onChange={this.onAddNewCategory.bind(this)} value={this.state.newCategory}  placeholder="New Sub Sub Category"/>
                            ):('')
                        }
                    </div>
                </div>
                <div className="row" style={{paddingTop:`15px`}}>
                    <div className="ui two column grid">
                        <div className="column">
                           <h3 className="ui dividing header">Sales</h3>
                            <div className="field">
                                <input onChange={this.onInputChange} required value={this.state.product.MRP}  type="number" min={0} name="MRP"  placeholder="MRP"/>
                            </div>
                            <div className="field">
                                <input required={!this.state.sameAsMrp} onChange={this.onInputChange} value={this.state.sameAsMrp?this.state.product.MRP:this.state.product.price} disabled={this.state.sameAsMrp} type="number" min={0} name="price" placeholder="Sale Price"/>
                                <input checked={this.state.sameAsMrp} type="checkbox" style={{marginTop: "3px"}} onClick={()=>{this.setState({sameAsMrp:!this.state.sameAsMrp})}} name="mrpCheck"/><span>Same as MRP</span>
                            </div>
                        </div>

                        <div className="column">
                            <h3 className="ui dividing header">
                                <input type="checkbox" checked={this.state.purchaseAlso}  onChange={this.handleAddPurchase.bind(this)}/>
                                &nbsp;&nbsp;
                                Purchase
                            </h3>
                            <div className="field">
                                <input placeholder="Cost Price" disabled={!this.state.purchaseAlso} type="number" name="costPrice" onChange={this.onInputChange} value={this.state.product.costPrice} />
                            </div>
                            <div className="field">
                                <input placeholder="Vendor" disabled={!this.state.purchaseAlso} type="text" name="vendor" onChange={this.onInputChange} value={this.state.product.vendor} />
                                <div style={{paddingBottom: "10px", cursor: "pointer"}}>{
                                    this.state.vendorSuggestions.map((vendor)=>(<VendorSuggestion vendor={vendor} click={this.handleVendorSuggestionClick} />))
                                }</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style={{marginTop:`40px`}}></div>
                <button onClick={this.onSubmit}  type="submit" className="ui inverted right floated green button">Add</button>

            </form>
        )
    }
}
export default AddBusinessForm;
