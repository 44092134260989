import React,{Component} from 'react';
import $ from 'jquery';
import Client from "../services/Client";
import Business from "../components/business";
import AddBusinessForm from "../components/add-business-form";
import TopBarInside from "../components/topbar-inside";
class Businesses extends Component{
    state = {
        selectedCategory:'All',
        products:[],
        fetching:true,
        fetchingMore:false,
        selectedBusinessType:"All",
        businesses:[],
        // store:JSON.parse(localStorage.getItem('store')),
        // stores:JSON.parse(localStorage.getItem('stores')),
        categories:["All", "Retailer", "Service Provider", "Retailer cum Service Provider"],
        city: localStorage.getItem('city')
    }

    handleCityChange(e, data){
        localStorage.setItem('city',data.value);
        this.setState({
            city: data.value
        })
        this.getBusinesses(this.state.selectedBusinessType, data.value);
    }

    changeBusinesstype(e){
        const val=e.target.value;
        this.getBusinesses(e.target.value, this.state.city)
    }

    handleScroll(event){
        if ($(window).height()+$(window).scrollTop()===$(document).height()){
            console.log('same');
        }
    }
    componentDidMount(){
        window.addEventListener('scroll',this.handleScroll);
        this.getBusinesses(this.state.selectedBusinessType, this.state.city);
    }

    getBusinesses(type, city){
        this.setState({
            fetching:true
        });
        Client.getBusinesses(type, city).then((res)=>{
            this.setState({
                selectedBusinessType:type,
                businesses:res.businessesList,
                fetching:false
            })
        });
    }

    componentWillUnmount(){
        window.removeEventListener('scroll',this.handleScroll);
    }
    /////Render/////////////////////////
    render(){
        const businessTypes = this.state.categories.map((businessType,index)=>{
            return(
                <option className="item"  key={index} value={businessType} onClick={this.changeBusinesstype.bind(this)} >{businessType}</option>
            )
        });
        return(
            <div className="column">
                <TopBarInside 
                // cities={JSON.parse(localStorage.getItem('cities'))} 
                cities = {this.props.cities}
                handleCityChange={this.handleCityChange.bind(this)}/>
                <div className="row">
                    <div >
                        <h4>Select Store</h4>
                    </div>
                    <div className="ui compact menu">
                        <div className="ui simple dropdown item">
                            {this.state.selectedBusinessType}
                            <i className="dropdown icon"></i>
                            <div className="menu" style={{maxHeight:'200px',overflowY:'auto'}}>
                                {businessTypes}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.businesses.map((business)=>(
                        <Business business={business} />
                    ))
                }
            </div>
        )
    }
}
export default Businesses;
