import React, {Component} from 'react';
import '../style/add-sale.css'
import Client from '../services/Client'
import AddPurchaseProductAdded from "../components/add-purchase-product-added";
import AddPurchaseSuggestion from '../components/add-purchase-suggestion';
import {Link} from 'react-router-dom';

class AddBooking extends Component{

    state = {

        purchase_id: "",
        vendor_name: "",
        vendor_id: "",
        vendor_phone: "",
        vendor_gst: "",
        purchase_date: "",
        payment_mode: "",
        payment_status: "",
        shipment_enabled: false,
        shipment_reqd: false,
        shipment_date: "",
        delivery_by: "",
        products: [],
        product: {
            delivery: 0,
            product: 0,
            name: "",
            barcode: "",
            quantity: 1,
            price: 0,
            costPrice: 0
        },
        suggestions: [],
        recentPurchase: {},
        total : 0

    };

    updateTotal() {
        // let t = this.state.total;
        // t = t+val;
        // this.setState({total: t});
        let t = 0;
        this.state.products.forEach((item) => {
          t += item.price*item.quantity;
        })
        this.setState({total: t});
    }

    componentDidMount() {
        if(localStorage.getItem("recentPurchase")!==null){
            this.setState({
                recentPurchase: JSON.parse(localStorage.recentPurchase),
                payment_mode: JSON.parse(localStorage.recentPurchase).payment_mode,
                payment_status: JSON.parse(localStorage.recentPurchase).payment_status
            });

        }
        this.setState({
            purchase_date: new Date().toISOString().substring(0,10),

        })
    }

    //handle input changes for add product fields
    onInputChange = (e) => {
        const product=this.state.product;
        product[e.target.name]=e.target.value;
        this.setState({
            product
        })
        if(e.target.name==="barcode"){
             this.searchBarcode(e.target.value);
        }
        if(e.target.name==="name"){
             this.suggestProducts(e.target.value);
        }
    };

    onDetailsInputChange = (e) => {

        if(e.target.name==="vendor_phone"){
            const inputParts = e.target.value.trim().split(' ');
            let phone = "";
            inputParts.forEach((part) => {
              phone = phone.concat(part);
            });
            this.setState({vendor_phone: phone});
            if(phone.length>=10){
                phone = phone.substring(phone.length, phone.length-10);
                this.setState({vendor_phone: phone});
                this.searchVendor(phone);
            }
        }else{
            const s = this.state;
            s[e.target.name]=e.target.value;
            this.setState(s);
        }

    };

    //auto input vendor name on entering vendor phone

    searchVendor = (find) => {
        Client.searchVendor(find).then((res)=>{
            if(res.vendors.length>0){
                this.setState({
                    vendor_name: res.vendors[0].name,
                    vendor_gst: res.vendors[0].GST,
                    vendor_id: res.vendors[0].id
                });
            }else{
                this.setState({
                    vendor_name: "",
                    vendor_gst: "",
                    vendor_id: ""
                });
            }
        });
    }

    //suggest products on product name input - MSJ

    suggestProducts = (find) => {
        if(find){
            let suggestions = [];
            Client.suggestProducts(find).then((res)=>{
                this.setState({suggestions: []}); //clear state before adding new products - MSJ
                res.shopProducts.forEach((product)=>{
                    if(product.variants.length==1){
                        suggestions = [
                            ...suggestions,
                            {
                                name: product.productName,
                                barcode: product.variants[0].barcode || '0000000000',
                                price: product.variants[0].price,
                                id: product.variants[0].id
                            }
                        ]
                    }else{
                        product.variants.forEach((variant) => {
                            suggestions = [
                                ...suggestions,
                                {
                                    name: product.productName + " (" + variant.quantity + ")",
                                    barcode: variant.barcode || '0000000000',
                                    price: variant.price,
                                    id: variant.id
                                }
                            ]
                        })
                    }
                })
            }).then(()=>{
                    this.setState({
                        suggestions: suggestions
                    })
                }
            );
        }else{
            this.setState({suggestions: []}); //clear state when no input - MSJ
        }
    }

    //on suggestion click
    handleSuggestionClick = (name, barcode, price, id) => {

        let prods = this.state.products;

        let count = 0;
        prods.forEach((product) => {
            if(product.barcode==barcode){
                product.quantity = product.quantity+1;
                count++;
            }
        })

        if(count==0){
            let p = this.state.product;
            p.name = name;
            p.barcode = barcode;
            p.price = price;
            p.product = id;
            this.setState({product: p, suggestions:[]});
            this.handleAddProduct();
        }else{
            this.setState({
                products: prods,
                product: {
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: ""
                },
                suggestions: []
            })
            this.updateTotal();
        }



    };

    handleAddProduct = () => {
        if(this.state.product.product){
            this.setState({
                products:[
                    ...this.state.products,
                    this.state.product
                ],
                product:{
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: ""
                }
            })
        }else{
            console.log("Invalid Product");
        }

    };

    handleCreatePurchase = (e) => {

        e.preventDefault();

        if(!(this.state.vendor_id)){
            alert("Select a vendor from the existing list or add new.")
        }

        if(this.state.vendor_name.trim()&&this.state.vendor_phone.trim()&&this.state.payment_mode.trim()&&this.state.products.length&&this.state.vendor_id){

            Client.createPurchase(this.state.vendor_name, this.state.vendor_id, this.state.vendor_phone, this.state.vendor_gst, this.state.purchase_date, this.state.payment_mode, this.state.payment_status, this.state.shipment_date, this.state.delivery_by, this.state.products, "sis").then(
                (response)=>{

                    if(response.responseResult==="success"){
                        this.setState({
                            recentPurchase: {
                                purchase_id: response.purchaseId,
                                invoice_id: response.invoiceId,
                                vendor_name: this.state.vendor_name,
                                vendor_id: this.state.vendor_id,
                                vendor_phone: this.state.vendor_phone,
                                vendor_gst: this.state.sale_id,
                                purchase_date: this.state.purchase_date,
                                payment_mode: this.state.payment_mode,
                                payment_status: this.state.payment_status,
                                shipment_reqd: this.state.shipment_reqd,
                                shipment_date: this.state.shipment_date,
                                delivery_by: this.state.delivery_by,
                                products: this.state.products,
                                shipment_address: "Address Address Address Address Address Address"
                            }
                        });
                        localStorage.setItem("recentPurchase", JSON.stringify(this.state.recentPurchase));
                    }
                    if(response.responseError==='invalidCredentials'){
                        this.setState({
                            fetching:false,
                            fieldErrors:{
                                ...this.state.fieldErrors,
                                error:'Invalid Phone or Password'
                            }
                        })
                    }
                }
            ).catch((err)=>{
                this.setState({
                    fetching:false,
                    unExpectedErrors:'Sorry,We could not log you in.Something Wrong Happened'
                })
            })
        }else alert("Please fill in the required fields.");


    }

    handleCheckBox = (e) => {
        this.setState({
            shipment_reqd: e.target.checked,
            shipment_date: "",
            delivery_man: ""
        });
    }

    searchBarcode = (barcode) => {

        let prods = this.state.products;

        let count = 0;
        prods.forEach((product) => {
            if(product.barcode==barcode){
                product.quantity = product.quantity+1;
                count++;
            }
        })

        if(count===0){

            const rootState = this.state.product;

            Client.searchBarcode(barcode).then(
                (response)=>{
                    if(response.responseError==='invalidCredentials'){
                        this.setState({
                            fieldErrors:{
                                ...this.state.fieldErrors,
                                error:'Invalid Phone or Password'
                            }
                        })
                    }
                    else {
                        if(response.productDetailsCount>0){
                            rootState.name = response.productDetails[0].productName;
                            rootState.product = response.productDetails[0].variants[0].id;
                            rootState.price = response.productDetails[0].variants[0].price;
                            rootState.costPrice = response.productDetails[0].variants[0].costPrice||response.productDetails[0].variants[0].price;
                            rootState.quantity = 1;
                            this.setState({
                                product: rootState
                            })
                            this.handleAddProduct();
                        }
                    }
                }
            ).catch((err)=>{
                this.setState({
                    fetching:false,
                    unExpectedErrors:'Sorry,We could not log you in.Something Wrong Happened'
                })
                console.log(err);
            })
        }else{
            this.setState({
                products: prods,
                product: {
                    product: "",
                    name: "",
                    barcode: "",
                    quantity: 1,
                    delivery: 0,
                    price: "",
                    costPrice: ""
                }
            })
            this.updateTotal();
        }
    }

    handleProductQuantity = (index, qty) => {
        let p = this.state.products;
        p[index].quantity =  parseInt(qty);
        this.setState({
            products: p
        });
        // this.updateTotal();
    }

    handleProductCostPrice = (index, cp) => {
        let p = this.state.products;
        p[index].costPrice = cp;
        this.setState({
            products: p
        });
    }

    handleProductRemove = (index) => {
        let p = this.state.products;
        p.splice(index, 1);
        this.setState({
            products: p
        });
    }

    setInvoice = () => {
        localStorage.setItem("invoice", "p"+this.state.recentPurchase.purchase_id);
    }

    render(){

        var total = 0;

        return(
            <div className="add-sale-container">
                <form className="ui form">
                    <div className="three fields">
                        <div className="required field">
                            <label htmlFor="purchase_date">Purchase Date</label>
                            <input name="purchase_date" type="date" placeholder="Purchase Date" value={this.state.purchase_date} onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="required field">
                            <label htmlFor="payment_mode">Payment Method</label>
                            <select className="ui fluid dropdown" name="payment_mode" value={this.state.payment_mode} onChange={this.onDetailsInputChange.bind(this)}>
                                <option disabled>Payment Method</option>
                                <option value="CASH">Cash</option>
                                <option value="ONLINE">Online</option>
                                <option value="COD">COD</option>
                            </select>
                        </div>
                        <div className="required field">
                            <label htmlFor="payment_status">Payment Status</label>
                            <select className="ui fluid dropdown" name="payment_status" value={this.state.payment_status} onChange={this.onDetailsInputChange.bind(this)}>
                                <option disabled>Payment Status</option>
                                <option value="PAID">Paid</option>
                                <option value="CREDIT">Credit</option>
                                <option value="PENDING">Pending</option>
                            </select>
                        </div>
                    </div>

                    <h4 className="ui dividing header">Add New Purchase</h4>
                    <div className="three fields">
                        <div className="required field">
                            <label htmlFor="vendor_phone">Vendor Phone No.</label>
                            <input name="vendor_phone" type="text" required value={this.state.vendor_phone} placeholder="Vendor Phone No." onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="required field">
                            <label htmlFor="vendor_name">Vendor Name</label>
                            <input name="vendor_name" type="text" value={this.state.vendor_name} placeholder="Vendor Name" onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                        <div className="field">
                            <label htmlFor="vendor_gst">Vendor GST No.</label>
                            <input name="vendor_gst" type="text" value={this.state.vendor_gst} placeholder="Vendor GST No." onChange={this.onDetailsInputChange.bind(this)}/>
                        </div>
                    </div>

                    {
                        this.state.shipment_enabled
                        ?
                        <div>
                            {
                                (this.state.shipment_reqd)
                                ?
                                <div className="three fields">
                                    <div className="field" style={{marginTop: "20px"}}>
                                        <input
                                            type="checkbox"
                                            name="shipment_reqd"
                                            style={{marginTop: "5px"}}
                                            onChange={this.handleCheckBox.bind(this)}
                                        />
                                    &nbsp;&nbsp;&nbsp;Shipment Required
                                    </div>
                                    <div className="field">
                                        <label htmlFor="shipment_date">Shipment Date</label>
                                        <input name="shipment_date" type="date" placeholder="Shipment Date" onChange={this.onDetailsInputChange.bind(this)}/>
                                    </div>
                                    <div className="field">
                                        <label htmlFor="delivery_man">Delivery Man</label>
                                        <input name="delivery_man" type="text" placeholder="Delivery Man" onChange={this.onDetailsInputChange.bind(this)}/>
                                    </div>
                                </div>
                                :
                                <div className="three fields">
                                    <div className="field">
                                        <input
                                            type="checkbox"
                                            name="shipment_reqd"
                                            style={{marginTop: "5px"}}
                                            onChange={this.handleCheckBox.bind(this)}
                                        />
                                        &nbsp;&nbsp;&nbsp;Shipping Required
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <div></div>
                    }


                    <div className="field">
                        <table className="ui basic five column table">
                            <thead>
                                <tr>
                                    <th>Barcode</th>
                                    <th>Product</th>
                                    <th>Price</th>
                                    <th>Cost Price</th>
                                    <th>Quantity</th>
                                </tr>
                            </thead>
                            {this.state.products.map((product, key)=>(
                                    <AddPurchaseProductAdded product={product} index={key} onQuantityChange={this.handleProductQuantity} onCostPriceChange={this.handleProductCostPrice} onremove={this.handleProductRemove} updateTotal={this.updateTotal.bind(this)}/>
                                )
                            )}
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{this.state.total}</td>
                            </tr>
                        </table>
                        <div className="five fields">
                            <div className="field">
                                <input
                                    type="text"
                                    name="barcode"
                                    value={this.state.product.barcode}
                                    placeholder="BARCODE"
                                    onChange={this.onInputChange.bind(this)}/>
                            </div>
                            <div className="field">
                                <input
                                    type="text"
                                    name="name"
                                    value={this.state.product.name}
                                    placeholder="Product"
                                    onChange={this.onInputChange.bind(this)}/>
                            </div>
                            <div className="field"></div>
                            <div className="field"></div>
                            <div className="field">
                                <button type="button" style={{}} className="ui button right floated" onClick={this.handleCreatePurchase.bind(this)}>CREATE PURCHASE</button>
                            </div>

                        </div>
                        <div id="suggestions">
                            {this.state.suggestions.map((suggestion, key)=>(
                                <AddPurchaseSuggestion name={suggestion.name} barcode={suggestion.barcode} price={suggestion.price} id={suggestion.id} click={this.handleSuggestionClick} />
                            ))}
                        </div>

                    </div>
                </form>
                {
                    (Object.keys(this.state.recentPurchase).length == 0) ? <div></div> :
                    <div style={{marginTop: "25px"}}>
                        <div><Link to='/invoice' style={{float: "right"}} onClick={this.setInvoice}>Generate Purchase Details</Link></div>
                        <h4 className="ui dividing header">Recent Sale</h4>
                        <div className="ui grid">
                            <div className="four wide column"><strong>Purchase ID:</strong>&nbsp;&nbsp;{this.state.recentPurchase.purchase_id}</div>
                            <div className="four wide column"><strong>Vendor Name:</strong>&nbsp;&nbsp;{this.state.recentPurchase.vendor_name}</div>
                            <div className="four wide column"><strong>Vendor Phone:</strong>&nbsp;&nbsp;{this.state.recentPurchase.vendor_phone}</div>
                            <div className="four wide column"><strong>Purchase Date:</strong>&nbsp;&nbsp;{this.state.recentPurchase.purchase_date}</div>
                            <div className="four wide column"><strong>Payment Mode:</strong>&nbsp;&nbsp;{this.state.recentPurchase.payment_mode}</div>
                            <div className="four wide column"><strong>Payment Status:</strong>&nbsp;&nbsp;{this.state.recentPurchase.payment_status}</div>
                            <div className="eight wide column"><strong>Shipment Address:</strong>&nbsp;&nbsp;{this.state.recentPurchase.shipment_address}</div>
                            <div className="sixteen wide column"><strong>Products:</strong>&nbsp;&nbsp;{this.state.recentPurchase.products.map((product, key)=>(<span>{product.name}, &nbsp;</span>))}</div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default AddBooking;
