import React, {Component} from 'react';
import $ from 'jquery';
import '../style/page-center.css';
import Client from '../services/Client';
import Orders from '../components/orders-list';
import { Grid, Menu, Button, Popup, Tab } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';

class OrdersPage extends Component{

    constructor(props){

        super(props);

        this.state = {
            view: 'card',
            innerWidth: 0,
            disabled: false,
            expandSettings: true,
            rangeEnabled: false,
            bookings: [],
            bookingsCat: {"Payment Failed":[], "Processing Payment":[], "Rejected":[], "Placed":[], "Accepted":[], "Packed":[], "Picked Up":[], "Completed":[], "Completed":[], "Unsuccessful":[], "Cancelled":[]},
            statuses: ["Payment Failed", "Processing Payment", "Rejected", "Placed", "Accepted", "Packed", "Picked Up", "Delivered", "Completed", "Unsuccessful", "Cancelled"],
            status: 'Payment Failed',
            orders: [],
            page: 0,
            morePage: 0,
            filters: {
                startDate: '',
                endDate: '',
                payMode: ''
            },
            ordersCount: null,
            cashReceived: null,
            paytmReceived: null,
            onlineReceived: null,
            dhpReceivable: null,
            dhpCommisionDue: null,
            dhpCommision: null,
            showSearchResults: false
        }

        this.changeView = this.changeView.bind(this);
        this.toggleRange = this.toggleRange.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.refreshOrders = this.refreshOrders.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount(){

        this.setState({
            innerWidth: window.innerWidth,
            cities: JSON.parse(localStorage.getItem('cities')),
            city: localStorage.getItem('city'),
            currentStatus: this.state.statuses[0]
        })
        this.fetchStores(localStorage.getItem('city'));

        Client.getOrders(0, this.props.status, this.state.currentStore).then((res)=>{
            this.setState({
                orders: res.ordersList,
                page: Number(res.page) + 1,
                morePage: Number(res.morePage),
                ordersCount: res.ordersCount
            });
        })

        document.getElementById('scroll-element').addEventListener('scroll', this.handleScroll);
    }

    fetchStores(city){
        this.setState({
            loadingStores: true
        })
        Client.getBusinesses(null, city).then((res)=>{
            var storesOption = [{value:"", text:'All'}];
            res.businessesList.forEach((business)=>{
                const s = {
                    value: business.storeId,
                    text: business.storeName,
                }
                storesOption = [...storesOption, s]
            });
            this.setState({
                stores:storesOption,
                city: city,
                loadingStores: false
            })
        });
    }

    componentWillUnmoount(){
        document.getElementById('scroll-element').removeEventListener('scroll', this.handleScroll);
    }

    refreshOrders(status, filters, page, find, sort, sortOrder){

        return Client.getOrders(page == 0 ? 0 : (page || this.state.page), status || this.state.status, this.state.currentStore, filters || this.state.filters, find, sort, sortOrder).then((res)=>{

            this.setState({
                orders: res.ordersList,
                page: Number(res.page) + 1,
                morePage: Number(res.morePage),
                ordersCount: res.ordersCount,
                // cashReceived: res.cashReceived,
                // paytmReceived: res.paytmReceived,
                // onlineReceived: res.onlineReceived,
                // dhpReceivable: res.dhpReceivable,
                // dhpCommisionDue: res.dhpCommisionDue,
                // dhpCommision: res.dhpCommision
            });
        })
    }

    handleTabChange(e, data){

        this.setState({
            status: this.state.statuses[data.activeIndex]
        });

        this.refreshOrders(this.state.statuses[data.activeIndex], null, 0);
    }

    changeView(){

        this.setState({
            view: this.state.view == 'card' ? 'list' : 'card'
        })
    }

    toggleRange(e){

        this.setState({
            rangeEnabled: e.target.checked
        })
    }

    handleSort(sort, sortOrder){
        this.refreshOrders(null, null, 0, null, sort, sortOrder);
    }

    handleSearch(e){
        if(e.target.value){
            this.refreshOrders('All', null, 0, e.target.value);
            this.setState({
                showSearchResults: true
            })
        }else{
            this.setState({
                showSearchResults: false
            })
        }
    }

    handleFilter(e){

        var filters = {};

        if(e.target.name == 'date'){

            filters = {
                ...this.state.filters,
                startDate: e.target.value,
                endDate: e.target.value
            }

            this.setState({
                filters: filters
            });

            this.refreshOrders(null, filters);

        }else{

            filters = {
                ...this.state.filters,
                [e.target.name]: e.target.value
            }

            this.setState({
                filters: filters
            })

            this.refreshOrders(null, filters);
        }
    }

    handleUpdateDate(e){
        localStorage.setItem('updateDate', e.target.value)
    }

    handleCityChange(e, data){
        this.fetchStores(data.value);
    }

    handleStoreChange(e, data){
        this.setState({
            currentStore: data.value
        })
    }

    handleStatusChange(e, data){
        this.setState({
            currentStatus: data.value
        })
    }

    fetchStores(city){
        this.setState({
            loadingStores: true
        })
        Client.getBusinesses(null, city).then((res)=>{
            var storesOption = [{value:"", text:'All'}];
            res.businessesList.forEach((business)=>{
                const s = {
                    value: business.storeId,
                    text: business.storeName,
                }
                storesOption = [...storesOption, s]
            });
            this.setState({
                stores:storesOption,
                city: city,
                loadingStores: false
            })
        });
    }

    handleScroll(event){

        var scrollElement = document.getElementById('scroll-element');

        if (scrollElement.scrollTop>=(scrollElement.scrollHeight-scrollElement.offsetHeight)){

            console.log('scroll');

            Client.getOrders(this.state.page, this.state.status, this.state.currentStore, this.state.filters).then((res)=>{
                this.setState({
                    orders: [
                        ...this.state.orders,
                        ...res.ordersList
                    ],
                    page: Number(res.page) + 1,
                    morePage: Number(res.morePage),
                    ordersCount: res.ordersCount,
                    // cashReceived: res.cashReceived,
                    // paytmReceived: res.paytmReceived,
                    // onlineReceived: res.onlineReceived,
                    // dhpReceivable: res.dhpReceivable,
                    // dhpCommisionDue: res.dhpCommisionDue,
                    // dhpCommision: res.dhpCommision
                });
            })
        }
    }

    render(){

        // var id = JSON.parse(localStorage.store).storeId;
        var panes = this.state.statuses.map((status)=>(
            {
                menuItem: status,
                render: () => <Orders view={this.state.view} handleSort={this.handleSort} status={status} store={this.state.currentStore} filters={this.state.filters} orders={this.state.orders}/>
            }
        ));

        if(this.state.disabled){
            return(
                <div className="page-center"><div className="page-center-content" style={{color: "#aaaaaa"}}>Disabled</div></div>
            )
        }else{
            return(
                <Grid padded="false">
                    {
                        this.state.innerWidth > 720
                        ?
                        <Grid.Row as={Menu} borderless style={{height: "50px", padding: 0, border: "none", borderBottom: "1px solid #999999", borderRadius: "0px", boxShadow: "none", margin: "0"}}>
                            <Button as={Menu.Item} icon='sidebar' onClick={this.props.toggle} style={{margin: 0}}/>
                            <div className="header item">Orders</div>
                            <div className="item">
                                <input type="text" placeholder="Search Orders" onChange={this.handleSearch}/> &nbsp;&nbsp;
                                <i className="search icon" style={{cursor: 'pointer'}} />
                            </div>
                            <div className="right menu">
                                <div className="ui item button" onClick={this.changeView.bind(this)} style={{fontWeight: "bolder"}}>
                                    {this.state.view == 'card'?"List View":"Card View"}
                                </div>
                            </div>
                        </Grid.Row>
                        :
                        <Grid.Row as={Menu} borderless style={{height: "50px", padding: 0, border: "none", borderBottom: "1px solid #999999", borderRadius: "0px", boxShadow: "none", margin: "0"}}>
                            <Button as={Menu.Item} icon='sidebar' onClick={this.props.toggle} style={{margin: 0}}/>
                            <div className="header item">Orders</div>
                        </Grid.Row>
                    }
                    {
                        this.state.expandSettings
                        ?
                        <Grid.Row className="orders-settings-container" style={{padding: 0}}>
                            <div className="orders-settings">
                                <div className="orders-settings-date">Date:</div>
                                <div className="orders-settings-range">
                                    <input type="checkbox" onChange={this.toggleRange} name="orders-filter-range-checkbox" className="orders-filter-range-checkbox" /> &nbsp;
                                    Range:
                                </div>
                                {
                                    this.state.rangeEnabled
                                    ?
                                    <div className="orders-settings-from-to">
                                        From: &nbsp; <input onChange={this.handleFilter} name="startDate" type="date"/> &nbsp; To: &nbsp; <input name="endDate" onChange={this.handleFilter} type="date"/>
                                    </div>
                                    :
                                    <div className="orders-settings-on"><input onChange={this.handleFilter} name="date" type="date"/></div>
                                }
                                <div style={{height:'50px'}}>
                                    <Dropdown placeholder='Select City' search selection options={this.state.cities} onChange={this.handleCityChange.bind(this)} defaultValue={localStorage.getItem('city')} style={{borderRadius:'0px'}}/>
                                    <Dropdown placeholder='Select Store' search selection options={this.state.stores} onChange={this.handleStoreChange.bind(this)} loading={this.state.loadingStores} style={{borderRadius:'0px'}}/>
                                </div>
                                <div className="orders-settings-number">Update Date: <input onChange={this.handleUpdateDate} name="updateDate" type="date"/></div>
                                <div className="orders-settings-number">Orders: {this.state.ordersCount}</div>
                                <div className="orders-settings-cash">Cash: {this.state.cashReceived}</div>
                                <div className="orders-settings-paytm">PayTm: {this.state.paytmReceived}</div>
                                <div className="orders-settings-online">Online: {this.state.onlineReceived}</div>
                                <div className="orders-settings-doorhopper-receivable">DoorHopper Receivable: {this.state.dhpReceivable}</div>
                                <div className="orders-settings-doorhopper-commision">DoorHopper Commision: {this.state.dhpCommision}</div>
                                <div className="orders-settings-doorhopper-commision-due">DoorHopper Commision Due: {this.state.dhpCommisionDue}</div>
                            </div>
                        </Grid.Row>
                        :
                        null
                    }
                    <Grid.Row id="scroll-element" style={{height: this.state.expandSettings ? 'calc(100vh - 101px - 12.5em)' : 'calc(100vh - 100px)', overflowY: 'auto', padding: 0}}>
                        {
                            this.state.showSearchResults
                            ?
                            <Orders view={this.state.view} handleSort={this.handleSort} status={this.state.status} store={this.state.currentStore} filters={this.state.filters} orders={this.state.orders}/>
                            :
                            <Tab panes={panes} style={{width: '100%'}} onTabChange={this.handleTabChange} />
                        }
                    </Grid.Row>
                </Grid>
            )
        }
    }
};

export default OrdersPage;
