import React, {Component} from 'react';
import '../style/order-slip.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, Modal, Icon, Form, Message} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class OrderSlipUpdate extends Component{

	constructor(props){
		super(props);

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = yyyy + '-' + mm + '-' + dd;
		// document.write(today);

		this.state = {
			today: today,
			data_rows: [],
			new_data_row: {slip_no: props.parent_data_row != undefined ? props.parent_data_row.slip_no : undefined, date: today},
			tableFields: [
				{"value": "id", "text": "ID", "type": "field"},
				{"value": "slip_no", "text": "Slip No.", "type": "field"},
				{"value": "priority", "text": "PRIORITY", "type": "field"},
				{"value": "date", "text": "Update Date", "type": "field"},
				{"value": "remarks", "text": "Remarks", "type": "field"},
				{"value": "status", "text": "Status", "type": "select"},
				{"value": "next_date", "text": "Next Update On", "type": "field"},
				{"value": "department", "text": "Department", "type": "select"},
				{"value": "employee", "text": "Employee", "type": "select"},
				{"value": "updated_by", "text": "Updated By", "type": "select"},
			],
			tableButtons: [
			],
			data_rows_offset: 0,
			data_rows_limit: 10,
			data_rows_size: 0,
			data_rows_sort: 'id',
			data_rows_sort_order: 'desc',
			data_rows_search_field: 'department',
			data_rows_search_type: 'select',
			data_rows_search: '',
			data_rows_filter: props.filter || {},
			options_customer: [],
			options_vendor: [],
			options_department: [],
			options_employee: [],
			options_updated_by: [],
			options_status: [
				{"value": "initiated", "text": "INITIATED", "after": ""},
				{"value": "follow", "text": "FOLLOW UP", "after": "initiated"},
				{"value": "site_visit", "text": "SITE VISIT", "after": "initiated,follow"},
				{"value": "quotation", "text": "QUOTATION", "after": "initiated,follow,site_visit"},
				{"value": "quotation_follow", "text": "QUOTATION FOLLOW", "after": "quotation"},
				{"value": "work_due", "text": "WORK DUE", "after": "initiated,follow,site_visit,quotation,quotation_follow"},
				{"value": "work_progress", "text": "WORK IN PROGRESS", "after": "initiated,follow,site_visit,quotation,quotation_follow,work_due"},
				{"value": "invoice", "text": "INVOICE", "after": "work_progress"},
				{"value": "payment", "text": "PAYMENT", "after": "invoice"},
				{"value": "feedback", "text": "FEEDBACK", "after": "payment,invoice"},
				{"value": "completed", "text": "COMPLETED", "after": "feedback,payment"},
				{"value": "cancelled", "text": "CANCELLED", "after": "initiated,follow,site_visit,quotation,quotation_follow,work_due"}],
			viewMode: props.viewMode || null,
			parent_data_row: props.parent_data_row != undefined ? props.parent_data_row : {},
		}
	}

	componentDidMount() {
		var options_department = [{text: "UNASSIGNED", value: undefined, statuses: 'completed,cancelled'}];
		var options_employee = [{text: "UNASSIGNED", value: undefined, department: '1'}];
		this.getDataRows(this.state);
		Client.getStaffs().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_employee.push({
					text: data_row.name,
					value: data_row.id,
					department: data_row.department
				})
			})
			this.setState({
				options_employee: options_employee,
				options_updated_by: options_employee
			})
		})
		Client.getDepartments().then(res=>{
			res.data_rows.forEach(function(data_row){
				options_department.push({
					text: data_row.department,
					value: data_row.id,
					statuses: data_row.statuses
				})
			})
			this.setState({
				options_department: options_department
			})
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			data_rows_filter: nextProps.filter || {},
			viewMode: nextProps.viewMode || null,
			parent_data_row: nextProps.parent_data_row || {},
			new_data_row: {slip_no: nextProps.parent_data_row != undefined ? nextProps.parent_data_row.slip_no : undefined, date: this.state.today},
		})
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	changeStateVariable(e, data){
		// alert(data.name + data.value);
		var state = {...this.state};

		//SELECTIVE OPERATIONS
		if(data.name != "data_rows_offset")
			state['data_rows_offset'] = 0;
		if(data.name == "data_rows_sort" && data.value == state.data_rows_sort)
			state['data_rows_sort_order'] = state.data_rows_sort_order == "desc" ? 'asc' : 'desc';

		//SET VALUE AND MOVE AHEAD
		state[data.name] = data.value;
		this.setState(state)
		this.getDataRows(state);
	}

	changeStateVariableNewDataRow(e, data){
		var new_data_row = {...this.state.new_data_row}
		new_data_row[data.name] = data.value;
		this.setState({
			new_data_row: new_data_row
		})
	}

	convertSelectToText(key, value){
		var datas = this.state['options_' + key];
		for(var i = 0; i < datas.length; i++){
			var data = datas[i];
			if(data.value == value){
				return data.text;
			}
		}
		return value + ' : N/A';
	}

	showDataRowDetails(index){
		this.setState({
			showCreateDataModal: true,
			new_data_row: this.state.data_rows[index],
			editMode: true
		})
	}

	toggleCreateDataModal(){
		var showCreateDataModal = this.state.showCreateDataModal
		if(showCreateDataModal)
			this.setState({
				showCreateDataModal: false,
				new_data_row: {slip_no: this.state.parent_data_row != undefined ? this.state.parent_data_row.slip_no : undefined, date: this.state.today},
				editMode: false,
				creatingNewDataModal: false
			})
		else{
			this.setState({
				showCreateDataModal: true
			})
			this.getGeneratedInvoice()
		}
	}

	createNewDataRow(){


		this.setState({
			creatingNewDataModal: true
		})
		var state = this.state
		Client.createOrderSlipUpdate(state.new_data_row, this.state.editMode).then(res=>{
			this.props.updateSelectedDataRow(state.new_data_row);
			this.setState({
				showCreateDataModal: false,
				creatingNewDataModal: false,
				editMode: false,
				new_data_row: {slip_no: this.state.parent_data_row != undefined ? this.state.parent_data_row.slip_no : undefined, date: this.state.today},
			})
			this.getDataRows(state);
		})
	}

	getDataRows(state){
		Client.getOrderSlipUpdates(state.data_rows_offset, state.data_rows_limit, state.data_rows_sort, state.data_rows_sort_order, state.data_rows_search_field, state.data_rows_search, state.data_rows_filter).then(res=>{
			this.setState({
				data_rows: res.data_rows,
				data_rows_size: res.data_rows_size
			})
		})
	}

	//CUSTOM FUNCTIONS
	createStatusSequence(){
		var statuses = [...this.state.options_status]
		var status_seq = []
		var parent_data_row = this.state.parent_data_row;
		if(Object.keys(parent_data_row).length > 0 && parent_data_row.current_status != null){
			for(var i = 0; i < statuses.length; i++){
				var status = statuses[i];
				var status_split = status.after.split(",");
				if(status_split.includes(parent_data_row.current_status) || status.value == parent_data_row.current_status){
					status_seq.push(status)
				}
			}
			return status_seq;
		}
		else{
			return statuses
		}
	}

	createDepartmentSequence(){
		var departments = [...this.state.options_department]
		var status = this.state.new_data_row.status;
		var department_seq = []
		for(var i = 0; i < departments.length; i++){
			var department = departments[i];
			var department_split = department.statuses.split(",");
			if(department_split.includes(status)){
				department_seq.push(department)
			}
		}
		return department_seq;
	}

	createEmployeeSequence(){
		var employees = [...this.state.options_employee]
		var department = this.state.new_data_row.department
		var employee_seq = []
		for(var i = 0; i < employees.length; i++){
			var employee = employees[i];
			if(employee.department == department  || employee.department == 1){
				employee_seq.push(employee)
			}
		}
		return employee_seq;
	}

	getGeneratedInvoice(){
		var parent_data_row = {...this.state.parent_data_row}
		if(parent_data_row.current_status == "invoice"){
			this.setState({
				loadingFormDetails: true
			})
			Client.getGeneratedOrderInvoice(this.state.new_data_row.slip_no).then(res => {
				var new_data_row = {...this.state.new_data_row}
				new_data_row.remarks = res.invoice_id
				if(res.invoice_id == null || res.invoice_id == undefined){
					this.setState({
						loadingFormDetails: false,
						formError: "INVOICE NOT YET GENERATED",
						formHasError: true
					})
				}
				else{
					this.setState({
						loadingFormDetails: false,
						new_data_row: new_data_row,
						formHasError: false
					})
				}
			})
		}
	}

	render(){
		return(
			<div style={{fontSize:'12px'}}>
				<div style={{display: (this.state.viewMode == "dialogForm" ? 'none' : 'block')}}>
					<Button size='small' onClick={() => this.toggleCreateDataModal()} primary>ADD UPDATE</Button>
					<div style={{float: 'right'}}>
						<Dropdown selection options={this.state.tableFields} name="data_rows_search_field" value={this.state.data_rows_search_field} placeholder="Search Field" onChange={(e, data) => this.changeStateVariable(e, data)}></Dropdown>
						{
							this.state.data_rows_search_type == 'field'
							?
							<Input type="text" name="data_rows_search" value={this.state.data_rows_search} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
							:
							<Dropdown selection search name="data_rows_search" options={this.state['options_' + this.state.data_rows_search_field]} placeholder="Search" onChange={(e, data) => this.changeStateVariable(e, data)}/>
						}
						<Button size='small' icon="angle left" name="data_rows_offset" value={this.state.data_rows_offset - this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
						{'Showing ' + this.state.data_rows_offset + ' to ' + (this.state.data_rows_offset + this.state.data_rows_limit) + ' of ' + this.state.data_rows_size + ' '}
						<Button size='small' icon="angle right" name="data_rows_offset" value={this.state.data_rows_offset + this.state.data_rows_limit} onClick={(e, data) => this.changeStateVariable(e, data)}></Button>
					</div>
					<Table color="blue" key="blue" collapsing>
						<Table.Header>
							<Table.Row>
							{
								this.state.tableFields.map((field) => (
									<Table.HeaderCell onClick={(e, data) => this.changeStateVariable(e, {"name":"data_rows_sort", "value":field.value})} style={{cursor: 'pointer'}}>
										{field.text}
										{
											this.state.data_rows_sort_order == "asc" ?
												<Icon name='angle up' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
											:
												<Icon name='angle down' style={{visibility: (this.state.data_rows_sort == field.value ? 'visible' : 'hidden')}}/>
										}
									</Table.HeaderCell>
								))
							}
							{
								this.state.tableButtons.map((field) => (
									<Table.HeaderCell>
										{field.text}
									</Table.HeaderCell>
								))
							}
							</Table.Row>
						</Table.Header>

						<Table.Body>
						{
							this.state.data_rows.map((data_row, index) => (
								<Table.Row className="data-row-table-row" onClick={() => this.showDataRowDetails(index)}>
								{
									this.state.tableFields.map((field) => (
										<Table.Cell>{field.type=='select' ? this.convertSelectToText(field.value, data_row[field.value]) : data_row[field.value]}</Table.Cell>
									))
								}
								{
									this.state.tableButtons.map((field) => (
										<Table.Cell>{field.type == "button" ? <Button size='small' style={{fontSize:'10px'}}>{field.text}</Button> : data_row[field.value]}</Table.Cell>
									))
								}
								</Table.Row>
							))
						}
						</Table.Body>
					</Table>
				</div>

				<Modal open={this.state.showCreateDataModal} onClose={() => this.toggleCreateDataModal()}>
					<Modal.Content>
						<Form size="mini" onSubmit={() => this.createNewDataRow()} loading={this.state.loadingFormDetails} warning={this.state.formHasError} disabled>
							<Form.Group widths='equal'>
								<Form.Input type="text" required fluid name="slip_no" value={this.state.new_data_row.slip_no || null} label="Slip No." onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="number" fluid name="priority" value={this.state.new_data_row.priority || undefined} label="PRIORITY" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Input type="date" required name="date" value={this.state.new_data_row.date} label="Update Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Input type="text" fluid name="remarks" value={this.state.new_data_row.remarks} label="Remarks" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}
								error={this.state.formHasError}
								required={this.state.parent_data_row.current_status == "invoice" || this.state.parent_data_row.current_status == "quotation"}/>
							<Form.Group widths='equal'>
								<Form.Select type="text" required selection search options={this.createStatusSequence()} name="status" value={this.state.new_data_row.status} label="Status" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select type="text" selection search options={this.createDepartmentSequence()} name="department" value={this.state.new_data_row.department} label="Current Department" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
								<Form.Select type="text" selection search options={this.createEmployeeSequence()} name="employee" value={this.state.new_data_row.employee} label="Current Employee" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							</Form.Group>
							<Form.Input type="date" name="next_date" value={this.state.new_data_row.next_date} label="Next Update Date" onChange={(e, data) => this.changeStateVariableNewDataRow(e, data)}/>
							<Message
								warning
								header='ACTION FORBIDDEN'
								content={this.state.formError}
						    />
							<Form.Button type="submit" primary loading={this.state.creatingNewDataModal}>{this.state.editMode ? 'UPDATE' : 'CREATE'}</Form.Button>
						</Form>
					</Modal.Content>
				</Modal>
			</div>
        )
	}
}

export default OrderSlipUpdate;
