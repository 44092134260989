import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import '../style/vertical-menu.css'
class VerticalMenu extends Component {
    render() {
        const authorized_for = this.props.authorized_for;
            return(
                <div id="sidebar">
                    <NavLink style={{display:authorized_for.includes("/home")?"-":"none"}} onClick={this.props.toggle}  onClick={this.props.toggle} to="/home" className="Item" activeClassName="Active" >
                        Home
                    </NavLink>

                    <NavLink style={{display:authorized_for.includes("/lead")?"-":"none"}} onClick={this.props.toggle}   exact to="/lead"  activeClassName="Active" className="Item" >
                        Lead Management
                    </NavLink>
                    <NavLink style={{display:authorized_for.includes("/order/slip")?"-":"none"}} onClick={this.props.toggle}   exact to="/order/slip"  activeClassName="Active" className="Item" >
                        Order Slip
                    </NavLink>
                    {
                        window.location.pathname.includes("order/slip")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/order/slip")?"-":"none"}} onClick={this.props.toggle}    exact to="/order/slip/update"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Order Updates
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/order/slip")?"-":"none"}} onClick={this.props.toggle}    exact to="/order/slip/purchase"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Order Purchases
                        </NavLink>
                        </div>
                        :
                        ("")
                    }
                    <NavLink style={{display:authorized_for.includes("/accounting/vouchers/invoice")?"-":"none"}} onClick={this.props.toggle}  exact to="/accounting/vouchers/invoice"  activeClassName="Active" className="Item" >
                         Accounting Vouchers
                    </NavLink>
                    {
                        window.location.pathname.includes("/accounting/vouchers") || window.location.pathname.includes("/swap")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/accounting/vouchers/invoice")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/vouchers/invoice"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Accounting Vouchers
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/swap")?"-":"none"}} onClick={this.props.toggle}    exact to="/swap"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Swap
                        </NavLink>

                        </div>
                        :
                        ("")
                    }

                    {/* <NavLink
                        // style={{display:authorized_for.includes("/attendance" )?"-":"none"}}
                         onClick={this.props.toggle}
                         exact to="/franchizee"  activeClassName="Active" className="Item" >
                        Franchizee Dashboard
                    </NavLink> */}

                    <NavLink
                        style={{display:authorized_for.includes("/accounting/reports/due")?"-":"none"}}
                        onClick={this.props.toggle}  exact to="/accounting/reports/due/1"  activeClassName="Active" className="Item" >
                        Due Report
                    </NavLink>
                    <NavLink style={{display:authorized_for.includes("/accounting/reports/trial")?"-":"none"}} onClick={this.props.toggle}  exact to="/accounting/reports/trial"  activeClassName="Active" className="Item" >
                        Accounting Reports
                    </NavLink>
                    {
                        (!window.location.pathname.includes("/1")) && window.location.pathname.includes("/accounting/reports") || window.location.pathname.includes("accounting/ledger/vouchers") || window.location.pathname.includes("/finance/reports")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/accounting/reports/trial")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/reports/trial"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Accounting Reports
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/ledger/vouchers")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/ledger/vouchers"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Ledger Vouchers
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/reports/due")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/reports/due"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Due Report
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/reports/vendor/due")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/reports/vendor/due"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Vendor Due Report
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/reports/vendor/cycle")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/reports/vendor/cycle"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Vendor Cycle Report
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/finance/reports")?"-":"none"}} onClick={this.props.toggle}    exact to="/finance/reports"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Financial Reports
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/reports/gst")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/reports/gst"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Gst Report
                        </NavLink>
                        </div>
                        :
                        ("")
                    }
                    <NavLink style={{display:authorized_for.includes("/inventory")?"-":"none"}} onClick={this.props.toggle}  exact to="/inventory"  activeClassName="Active" className="Item" >
                        Inventory
                    </NavLink>
                    <NavLink style={{display:authorized_for.includes("/accounting/masters/customer")?"-":"none"}} onClick={this.props.toggle}  exact to="/accounting/masters/customer"  activeClassName="Active" className="Item" >
                        Masters
                    </NavLink>
                    {
                        window.location.pathname.includes("/accounting/masters")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/accounting/masters/ledger")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/masters/customer"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Customers
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/masters/ledger")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/masters/vendor"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Vendors
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/masters/ledger")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/masters/ledger"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Ledger
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounting/masters/ledger")?"-":"none"}} onClick={this.props.toggle}    exact to="/accounting/masters/group"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Group
                        </NavLink>
                        </div>
                        :
                        ("")
                    }



                    <NavLink style={{display:authorized_for.includes("/staffs")?"-":"none"}} onClick={this.props.toggle}  exact to="/staffs"  activeClassName="Active" className="Item" >
                        Team Deskneed
                    </NavLink>
                    {
                        window.location.pathname.includes("/staffs") || window.location.pathname.includes('/departments')
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/staffs")?"-":"none"}} onClick={this.props.toggle}    exact to="/staffs"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Team
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/departments")?"-":"none"}} onClick={this.props.toggle}    exact to="/departments"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Departments
                        </NavLink>

                        </div>
                        :
                        ("")
                    }

                    <NavLink onClick={this.props.toggle}  exact to="/analytics/customer"  activeClassName="Active" className="Item" >
                        Business Reports
                    </NavLink>
                    {
                        window.location.pathname.includes("/analytics/customer")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/analytics/customer")?"-":"none"}} onClick={this.props.toggle}    exact to="/analytics/customer"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Customer Insights
                        </NavLink>

                        </div>
                        :
                        ("")
                    }


                    <NavLink style={{display:authorized_for.includes("/businesses")?"-":"none"}} onClick={this.props.toggle}  exact to="/businesses" className="Item" activeClassName="Active" >
                        Manage Businesses
                    </NavLink>
                    {
                        window.location.pathname.includes("businesses")?
                        <NavLink style={{display:authorized_for.includes("/business")?"-":"none"}} onClick={this.props.toggle}  style={{paddingLeft:'30px',backgroundColor:"#222222"}} exact to="/businesses/add"  activeClassName="Active" className="Item" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Add Business
                        </NavLink>:("")
                    }
                    <NavLink style={{display:authorized_for.includes("/orders")?"-":"none"}} onClick={this.props.toggle}   exact to="/orders"  activeClassName="Active" className="Item" >
                        Manage Orders
                    </NavLink>
                    {
                        window.location.pathname.includes("orders")?
                        <NavLink style={{display:authorized_for.includes("/orders")?"-":"none"}} onClick={this.props.toggle}  style={{paddingLeft:'30px',backgroundColor:"#222222"}}  exact to="/orders/add"  activeClassName="Active" className="Item" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Create Order
                        </NavLink>:("")
                    }
                    <NavLink style={{display:authorized_for.includes("/bookings")?"-":"none"}} onClick={this.props.toggle}   exact to="/bookings"  activeClassName="Active" className="Item" >
                        Manage Bookings
                    </NavLink>
                    {
                        window.location.pathname.includes("bookings")
                        ?
                        <NavLink style={{display:authorized_for.includes("/bookings")?"-":"none"}} onClick={this.props.toggle}  style={{paddingLeft:'30px',backgroundColor:"#222222"}}  exact to="/bookings/add"  activeClassName="Active" className="Item" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Create Booking
                        </NavLink>
                        :
                        ("")
                    }
                    <NavLink style={{display:authorized_for.includes("/attendance" )?"-":"none"}} onClick={this.props.toggle}   exact to="/attendance"  activeClassName="Active" className="Item" >
                        Attendance
                    </NavLink>
                    <NavLink style={{display:authorized_for.includes("/trackRider")?"-":"none"}} onClick={this.props.toggle}  to="/trackRider" className="Item" activeClassName="Active" >
                        TrackRider
                    </NavLink>
                    <NavLink style={{display:authorized_for.includes("/accounts")?"-":"none"}} onClick={this.props.toggle}  to="/accounts" className="Item" activeClassName="Active" >
                        Accounts
                    </NavLink>
                    {
                        window.location.pathname.includes("/accounts")
                        ?
                        <div>
                        <NavLink style={{display:authorized_for.includes("/accounts")?"-":"none"}} onClick={this.props.toggle}   exact to="/accounts/ledger"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Ledger
                        </NavLink>
                        <NavLink style={{display:authorized_for.includes("/accounts")?"-":"none"}} onClick={this.props.toggle}   exact to="/accounts/cash"  activeClassName="Active" className="Item child" activeStyle={{backgroundColor: "rgb(53, 145, 145)"}}>
                            Cash Book
                        </NavLink>
                        </div>
                        :
                        ("")
                    }
                    <NavLink style={{display:authorized_for.includes("/requests")?"-":"none"}} onClick={this.props.toggle}  to="/requests" activeClassName="Active" className="Item">
                        Issues & Requests
                    </NavLink>

                </div>
            )
    }
}

export default VerticalMenu;
