import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

class CreateAccountingVendor extends Component{

	state = {
		vendor: {
		},
	}

	componentDidMount() {
		this.setState({
			vendor: this.props.vendor || {}
		})
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			vendor: nextProps.vendor || {}
		})
	}

	handleInputVendor(e, data){
		var vendor = this.state.vendor;
		vendor[data.name] = e.target.value
		this.setState({
			vendor: vendor
		})
	}

	createVendor(){
		this.setState({
			creatingVendor: true
		})
		Client.accountingVendorCreate(this.state.vendor).then(res=>{
	        this.props.loadVendors();
			this.setState({
				creatingVendor: false,
				vendor: {}
			})
        })
	}

	resetFields(){
		this.setState({
			vendor: {}
		})
	}

	render(){
		return(
		    <div>
				<Button primary onClick={() => this.resetFields()}>RESET FIELDS</Button>
				<Input placeholder='Vendor Name' name="name" value={this.state.vendor.name || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='Email' name="vendorEmail" value={this.state.vendor.vendorEmail || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='Phone' name="vendorPhone" value={this.state.vendor.vendorPhone || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='GSTIN' name="GSTIN" value={this.state.vendor.GSTIN || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='Address' name="address" value={this.state.vendor.address || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='City' name="city" value={this.state.vendor.city || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Input placeholder='Zipcode' name="zipcode" value={this.state.vendor.zipcode || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputVendor(e, data)}/>
				<Button primary onClick={() => this.createVendor()} loading={this.state.creatingVendor || ''}>CREATE CUSTOMER</Button>
			</div>
        )
	}
}

export default CreateAccountingVendor;
