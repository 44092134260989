import React,{Component} from  'react';
import Client from "../services/Client";
import StoreDetails from '../components/storeDetailsContainer'
import AddShopForm from "../components/add-shop-form";
import TopBarInside from "../components/topbar-inside";
import { Dropdown, Input, Card } from 'semantic-ui-react';
import { Divider, Form, Label , Checkbox, Button } from 'semantic-ui-react'
import DashboardOrder from './dashboard-order'
// import '../style/home.css';
import '../style/dashboard.css'

import name from'../assets/blue/ic_acc_2.png';
import email from'../assets/blue/mail.png';
import id from'../assets/blue/ic_acc_3.png';
import phone from'../assets/blue/ic_acc_1.png';
import location from'../assets/blue/ic_acc_4.png';
import role from'../assets/blue/role.jpg';
import company from'../assets/blue/company.jpg';
import DashboardEarning from './dashboard-earing';
import DashboardDayBook from './dashboard-day-book';
import DashboardCustomer from './dashboard-customer';
import DashboardInventory from './dashboard-inventory';

class Home extends Component{
    constructor(props){
        super(props)
        this.state = { pictures: [] };
        this.onDrop = this.onDrop.bind(this);
        this.state={
            showDetails:false,
            fetching:true,
            stores:[],
            store:{},
            cities:[],
            city: localStorage.getItem('city'),
            disabled: true,
            userData: {},
            
        }
    }
    componentDidMount(){
        var cities = [{value:'Silchar', text:'Silchar'},{value:'Guwahati', text:'Guwahati'},{value:'Tezpur', text:'Tezpur'}];
        localStorage.setItem("cities",JSON.stringify(cities));
        this.setState({
            cities: cities,
            userData: JSON.parse(localStorage.getItem("userData"))
        })
    }
    handleCityChange(e, data){
        localStorage.setItem('city',data.value);
        this.setState({
            city: data.value
        })
    }
    handleEditClik() {
        this.setState( {disabled: !this.state.disabled} )
    }
    onDrop(picture) {
        this.setState({
            pictures:(this.state.pictures.concat(picture)),
        });
    }

    render(){
        return (
            <div>
                <div className="art-board">
                    <div className="segment">
                       <DashboardDayBook history={this.props.history}/>
                    </div>
                    <div className="segment wide">
                       <DashboardOrder history={this.props.history}/>
                    </div>
                </div>
                <div className="art-board art-table">
                    <div className="segment wide">
                        <DashboardEarning history={this.props.history}/>
                    </div>
                    <div className="segment ">
                        <DashboardCustomer/>
                    </div>
                </div>
                <div className="art-board  art-table">
                    <div className="segment wide">
                        <DashboardInventory history={this.props.history}/>
                    </div>
                    <div className="segment wide">
                        {/* <DashboardCustomer/> */}
                    </div>
                </div>
            </div>
        )
    }
}
export default Home;
