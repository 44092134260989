import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';

class CreateAccountingCustomer extends Component{

	state = {
		customer: {
		},
	}

	componentDidMount() {
		this.setState({
			customer: this.props.customer || {}
		})
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			customer: nextProps.customer || {}
		})
	}

	handleInputCustomer(e, data){
		var customer = this.state.customer;
		customer[data.name] = e.target.value
		this.setState({
			customer: customer
		})
	}

	createCustomer(){
		this.setState({
			creatingCustomer: true
		})
		Client.accountingCustomerCreate(this.state.customer).then(res=>{
	        this.props.loadCustomers();
			this.setState({
				creatingCustomer: false,
				customer: {}
			})
        })
	}

	render(){
		return(
		    <div>
				<Input placeholder='Customer Name' name="name" value={this.state.customer.name || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Input placeholder='Phone' name="customerPhone" value={this.state.customer.customerPhone || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Input placeholder='GSTIN' name="GSTIN" value={this.state.customer.GSTIN || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Input placeholder='Address' name="address" value={this.state.customer.address || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Input placeholder='City' name="city" value={this.state.customer.city || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Input placeholder='Zipcode' name="zipcode" value={this.state.customer.zipcode || ''}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputCustomer(e, data)}/>
				<Button primary onClick={() => this.createCustomer()} loading={this.state.creatingCustomer}>CREATE CUSTOMER</Button>
			</div>
        )
	}
}

export default CreateAccountingCustomer;
