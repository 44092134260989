import React from 'react'
import {Card, Dropdown, Input,Loader,Segment,Dimmer,Image, Checkbox, Button, Icon} from 'semantic-ui-react'
import Client from '../services/Client';
import '../style/dashboard.css'
import { BarChart,Bar,LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Sector, Cell} from 'recharts';
class DashboardDayBook extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            orders_analytics:[
                
            ],
            vouchers_analytics:[
                
            ],
            today_analytics:[

            ],
            graph_factor:'slip_date',
            monthFlag:false,
            options_month:[]
        }
    }
    componentWillMount(){
        this.setState({start_date:this.range(true),end_date:this.range(true),})
        Date.prototype.getWeekOfMonth = function() {
            var firstWeekday = new Date(this.getFullYear(), this.getMonth(), 1).getDay();
            var offsetDate = this.getDate() + firstWeekday - 1;
            return Math.floor(offsetDate / 7);
        }
    }
    componentDidMount(){
        this.setMonthSelections()
        this.getReport()
    }
    getReport(){
        let date = this.state.start_date
        if (this.state.monthFlag) {
            // let temp = date.split('-')
            date = this.state.voucher_month
        }
        Client.getDaybookReport(null,null,null,null,null,null,null,null,{},{},date).then(res=>{
            this.setState({
                orders_analytics :res.orderReport,
                vouchers_analytics:res.voucherReport,
                today_analytics:res.todaysWork
            })
        })
    }
    setMonthSelections(){
		var monthSelection = [];
		var startMonth = 1;
		var startYear = 2018;
		var today = new Date();
		var endMonth = today.getMonth()+1; //January is 0!
		if(endMonth < 12){
			endMonth++;
		}
		var endYear = today.getFullYear();
		var monthConvert = {1:"Jan",2:"Feb",3:"Mar",4:"Apr",5:"May",6:"June",7:"July",8:"Aug",9:"Sept",10:"Oct",11:"Nov",12:"Dec"}
		while(endYear >= startYear){
			if(startYear == endYear && startMonth > endMonth){
				break;
			}
			else{
				monthSelection.push({
					text: monthConvert[endMonth] + " " + endYear,
					value: endYear + "-" + ("0" + endMonth).slice(-2)
				})
				endMonth--;
				if(endMonth <= 0){
					endYear--;
					endMonth = 12;
				}
			}
		}
		this.setState({
			options_month: monthSelection
		})
	}

    handleConsoleChange(e,data){
        this.setState({[data.name]:data.value})
    }
    //GRAPH CODE
    range(exact){
		var date = new Date()
		var month = date.getMonth() + 1
		var year = date.getFullYear()
		var day = exact ? date.getDate() :1
		var month_separator = month >=10 ? '-' : '-0'
		var day_separator = day >=10 ? '-' : '-0'
		var formatted_date = year+month_separator+month+day_separator+day
		return formatted_date
	}
    getDistictIn(key,data_rows){
		var distincts = {}
		data_rows.forEach((elem)=>{
			if (elem[key] in distincts){
				distincts[elem[key]].push(elem)
			}
			else{
				distincts[elem[key]] = [elem]
			}
		})
		return distincts
	}

    handleGraphDataChange(e,data){
        this.setState({
			[data.name]:data.value,
			loading_graph:true
		},()=>{
			this.prepareGraphDataSingleFactor(this.state.graph_factor)
			
		})
    }
    withMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.slip_date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
			let temp = {...elem,month:month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }

    withWeekAndMonth(array) {
        let monthsArr = [ "Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec" ]
		let withMonthArray = []

        array.forEach((elem)=>{
            let date = elem.slip_date.split('-')
            let month = monthsArr[parseInt(date[1])-1]
            
            let javascriptDate = new Date(parseInt(date[0]),parseInt(date[1])-1,parseInt(date[2]))
            console.log('week_number',javascriptDate.getWeekOfMonth())
			let temp = {...elem,week:'W'+(javascriptDate.getWeekOfMonth()+1     )+'-'+month+'-'+date[0]}
			withMonthArray.push(temp)
        })
        console.log("with month array",withMonthArray);
        return withMonthArray
    }
    changeVocherReportData(e,data){
        this.setState({[data.name]:data.value},()=>{
            this.getReport()
        })
    }

    render(){
        return(
            <Card>
            <Card.Header>
                Day Book
                <span className="header-toggle right">
                    <Checkbox 
                        checked={this.state.monthFlag} 
                        label="Month"
                        toggle
                        onClick={()=>{
                            if (!this.state.monthFlag) {
                                let date = this.state.start_date.split('-')
                                this.setState({voucher_month:date[0]+'-'+date[1]})
                            }
                            this.setState({monthFlag:!this.state.monthFlag},()=>{
                                this.getReport()
                            })
                        }}
                        />
                </span>
            </Card.Header>
            <Card.Content>
                {
                    this.state.monthFlag
                    ?
                    <Dropdown
                        options={this.state.options_month}
                        value={this.state.voucher_month}
                        name="voucher_month"
                        search
                        inline
                        placeholder="Select Month"
                        onChange={(e,data)=>{this.changeVocherReportData(e,data)}}
                        />
                    :
                    <Input 
                        type="date" 
                        inline
                        value={this.state.start_date}
                        name="start_date"
                        onChange={(e,data)=>{this.changeVocherReportData(e,data)}}
                        />
                }
                <Button
                    icon
                    onClick={()=>this.getReport()}
                    negative
                >
                    <Icon name="refresh"/>
                </Button>
                <h2>Todays Work</h2>
            {
                // this.state.orders_analytics.length
                true
                ?
                <div className={"count-bar"}>
                    {	
                        this.state.today_analytics.map((analytic)=>{
                            
                            // console.log('index',count_bar_index);
                            
                            if ((analytic.status != null && analytic.status != '' && analytic.status != undefined) ) {
                                // count_bar_index=count_bar_index+1
                                return(
                                    <span className={"count-box"} 
                                    onClick={()=>{
                                        if (this.state.monthFlag) {
                                            localStorage.setItem('order-redirect-month',this.state.voucher_month)
                                        } else {
                                            localStorage.setItem('order-redirect-date',this.state.start_date)
                                        }
                                        this.props.history.push('order/slip')
                                    }}
                                    >
                                        <span className="status-name">
                                            {analytic.status.toUpperCase()}
                                        </span>
                                        <span className="count-info">
                                            <span className="delayed">{analytic.count}</span>
                                        </span>
                                    </span>
                                )
                            }
                        })}
                    </div>
                    :
                    null
                }
                <h2>Orders</h2>
            {
                // this.state.orders_analytics.length
                true
                ?
                <div className={"count-bar"}>
                    {	
                        this.state.orders_analytics.map((analytic)=>{
                        
                        // console.log('index',count_bar_index);
                        
                        if ((analytic.status != null && analytic.status != '' && analytic.status != undefined) ) {
                            // count_bar_index=count_bar_index+1
                            return(
                                <span className={"count-box"} 
                                    onClick={()=>{
                                        localStorage.setItem('order-redirect-status',analytic.status)
                                        this.props.history.push('/order/slip')
                                    }}
                                >
                                    <span className="status-name">
                                        {analytic.status.toUpperCase()}
                                    </span>
                                    <span className="count-info">
                                        <span className="delayed">{analytic.count}</span>
                                    </span>
                                </span>
                            )
                        }
                    })}
                </div>
                :
                null
            }
            <h2>Vouchers</h2>
            {
                // this.state.vouchers_analytics.length
                true
                ?
                <div className={"count-bar"}>
                    {	
                        this.state.vouchers_analytics.map((analytic)=>{
                        
                        // console.log('index',count_bar_index);
                        
                        if ((analytic.type != null && analytic.type != '' && analytic.type != undefined) ) {
                            // count_bar_index=count_bar_index+1
                            return(
                                <span className={"count-box"} 
                                        onClick={()=>{
                                            if (this.state.monthFlag) {
                                                localStorage.setItem('voucher-redirect-month-'+analytic.type,this.state.voucher_month)
                                            } else {
                                                localStorage.setItem('voucher-redirect-date-'+analytic.type,this.state.start_date)
                                            }
                                            this.props.history.push('accounting/vouchers/'+(analytic.type == "sale"?"invoice":analytic.type == "delivery_note"?"challan":analytic.type))
                                        }}
                                    >
                                    <span className="status-name">
                                        {analytic.type.toUpperCase()}
                                    </span>
                                    <span className="count-info">
                                        <span className="delayed">{analytic.sum}</span>
                                    </span>
                                </span>
                            )
                        }
                    })}
                </div>
                :
                null
            }
            <h2>Issues/Queries</h2>
            {
                this.state.analytics
                ?
                <div className={"count-bar"}>
                    {	
                        this.state.analytics.map((analytic)=>{
                        
                        // console.log('index',count_bar_index);
                        
                        if ((analytic.status != null && analytic.status != '' && analytic.status != undefined) ) {
                            // count_bar_index=count_bar_index+1
                            return(
                                <span className={"count-box"} 
                                    
                                >
                                    <span className="status-name">
                                        {analytic.status.toUpperCase()}
                                    </span>
                                    <span className="count-info">
                                        <span className="delayed">{analytic.delayed}</span>
                                        / 
                                        <span className="total">{analytic.total}</span>
                                    </span>
                                </span>
                            )
                        }
                    })}
                </div>
                :
                null
            }
            </Card.Content>
            
        </Card>
    
        )
    }
}
export default DashboardDayBook