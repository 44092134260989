import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown, TextArea} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import AccountingItem from "./accounting-item";

class OrderSlipCreate extends Component{

	constructor(){
		super();
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth()+1; //January is 0!
		var yyyy = today.getFullYear();
		if(dd<10) {
		    dd = '0'+dd
		}
		if(mm<10) {
		    mm = '0'+mm
		}
		today = yyyy + '-' + mm + '-' + dd;
		this.state = {
			sale: {
				invoiceId: "",
				products: [{id: 0, qty: 0, rate: 0, tax: 'N/A'}],
				subTotal: 0,
				roundOff: 0,
				total: 0,
				orderId: null,
				date: today
			},
			taxDetails: [],
			searchCustomers: [],
			customer: {name:''},
			searchProducts: [{productId: 0, name: 'CREATE NEW', text:'CREATE NEW', value:0}],
			createProduct: false,
			invoiceIds: [],
			draftTerms: 'Terms:\n* Payment: Within 15 days from the date of delivery / service.\n* Freight Charges: Extra as actual.\n* Quotation Validity: 15 days. Rates may vary thereafter.',
			draftContact: 'Name: Deskneed (Science2home Pvt. Ltd.).\nPhone: +91-9954683549.\nEmail: contact@deskneed.com.',
		}
	}

	componentDidMount() {
		this.loadCustomers();
		this.loadVendors();
		// this.loadInvoiceIds();
		if(localStorage.getItem('currentInvoiceSale') && localStorage.getItem('currentInvoiceSaleEdit') == 1){
			var sale = JSON.parse(localStorage.getItem('currentInvoiceSale'));
			document.getElementById('accounting-invoice-customer-input').value = sale.customer.name;
			this.setState({
				sale: sale,
				customer: sale.customer,
				vendors: [],
				statuses: [
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
					{text: '', value: ''},
				]
			})

			this.calculateTax()
		}
	}

	componentWillUnmount(){
		localStorage.setItem('currentInvoiceSaleEdit', 0);
	}

    perfectDecimal(number){
        return parseFloat(Math.round(number * 100) / 100).toFixed(2)
    }

	addProduct(){
		var products = this.state.sale.products;
		var sale = this.state.sale;
		sale.products = [...products, {id:products.length, qty: 0, rate: 0, tax: 'N/A'}]
		this.setState({
			sale: sale
		})
		setTimeout(() => {
			document.getElementById('accounting-invoice-product-input-name' + (sale.products.length - 1)).focus()
	    },300)
	}

	removeProduct(id){
		var products = [...this.state.sale.products];
		var sale = {...this.state.sale};
		var products1 = []
		var found = 0;
		products.forEach(function(element, index) {
			if(index != id){
				products1.push(element)
			}
			else
				found = 1
		});
		sale.products = [...products1]
		this.setState({
			sale: sale
		})

		this.calculateTax()
	}

	handleInputChangeSale(e, data){
		var sale = this.state.sale;
		sale[data.name] = e.target.value || data.value
		this.setState({
			sale: sale
		})

		this.calculateTax()
	}

	handleInputChangeProduct(index, e, data){
		if(data.name == "name"){
			this.searchProducts(index, e.target.value)
		}
		var products = this.state.sale.products;
		var sale = this.state.sale;
		var found = 0;
		products[index][data.name] = e.target.value
		sale.products = [...products]
		this.setState({
			sale: sale
		})

		this.calculateTax()
	}

	calculateTax(){

		setTimeout(() => {
			var sale = this.state.sale;
			var taxDetails = [];
			var subTotal = 0;
			var total = 0;
			var taxStateType = this.state.customer.pos == "AS" ? 0 : 1;
			this.state.sale.products.forEach(function(element) {
				var taxDetail = {};
				var found = 0
				if(element.tax != 'N/A'){
					taxDetails.forEach(function(elem) {
						if(elem.taxPc == element.tax){
							found = 1;
							elem.taxStateType = taxStateType;
							elem.taxableAmount = Number(elem.taxableAmount) + (element.qty * element.rate)
							elem.taxableAmount = parseFloat(Math.round(elem.taxableAmount * 100) / 100).toFixed(2);
							elem.taxAmount = elem.taxableAmount * elem.taxPc / 100;
							elem.taxAmount = parseFloat(Math.round(elem.taxAmount * 100) / 100).toFixed(2);
						}
					})
					if(found == 0){
						taxDetail['taxStateType'] = taxStateType;
						taxDetail['taxPc'] = element.tax;
						taxDetail['taxableAmount'] = element.qty * element.rate;
						taxDetail['taxableAmount'] = parseFloat(Math.round(taxDetail['taxableAmount'] * 100) / 100).toFixed(2);
						taxDetail['taxAmount'] = (element.qty * element.rate) * element.tax / 100;
						taxDetail['taxAmount'] = parseFloat(Math.round(taxDetail['taxAmount'] * 100) / 100).toFixed(2);
						taxDetails.push(taxDetail)
					}
				}
				subTotal = subTotal + (element.qty * element.rate);
			});

			taxDetails.forEach(function(elem) {
				total = total + Number(elem.taxAmount);
				console.log(total);
			});
			sale.subTotal = parseFloat(Math.round(subTotal * 100) / 100).toFixed(2);
			sale.total = Number(total) + Number(subTotal);
			sale.total = parseFloat(Math.round(sale.total * 100) / 100).toFixed(2);
			sale.roundOff = Math.round(sale.total) - Number(sale.total);
			sale.roundOff = parseFloat(Math.round(sale.roundOff * 100) / 100).toFixed(2);
			sale.payableAmount = Math.round(sale.total);
			if(sale.adjustmentName != undefined && sale.adjustmentName != '')
				sale.payableAmount = Number(sale.payableAmount) + Number(sale.adjustmentAmount || 0);
			sale.payableAmount = parseFloat(Math.round(sale.payableAmount * 100) / 100).toFixed(2);
			this.setState({
				taxDetails: taxDetails,
				sale: sale
			})
		},300)
	}

	searchCustomer(e, data){
		this.showCustomerSuggestions()
		this.loadCustomers(e.target.value)
	}

	searchProducts(index, find){
		this.showProductSuggestions(index)
		this.setState({
			product: {productId:0, name:find}
		})
		Client.searchAccountingInvoiceProduct(find).then(res=>{
			var searchProducts = res.products;
			searchProducts.forEach(function(sc){
				sc['text'] = sc.name;
				sc['value'] = sc.productId;
			})
			searchProducts.push({productId: 0, name: 'CREATE NEW'});
            this.setState({
                searchProducts: res.products,
            })
        })
	}

	loadCustomers(search){
		Client.searchAccountingInvoiceCustomer(search || '').then(res=>{
			var searchCustomers = res.customers;
			searchCustomers.push({customerId: 0, name: 'CREATE NEW'});
            this.setState({
                customers: res.customers,
                searchCustomers: searchCustomers,
            })
        })
	}

	loadVendors(){
		Client.getAccountingVendors().then(res=>{
			var vendors = [];
			res.vendors.forEach(function(vendor) {
				vendors.push({text: vendor.name, value: vendor.vendorId})
			})
			this.setState({
				vendors: vendors,
			})
		})
	}

	loadInvoiceIds(){
		Client.getAccountingQuotationIds().then(res=>{
			var invoiceIds = [];
			if(localStorage.getItem('currentInvoiceSaleEdit') == 1){
				invoiceIds.push({
					text: this.state.sale.invoiceId,
					value: this.state.sale.invoiceId
				})
			}
			res.invoiceIds.forEach(function(invoiceId){
				invoiceIds.push({
					text: invoiceId,
					value: invoiceId
				})
			})
			this.setState({
                invoiceIds: invoiceIds,
            })
        })
	}

	selectCustomer(){
	}

	selectProduct(){
	}

	showCustomerSuggestions(hide){
		if(hide){
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "block";
		}
	}

	showProductSuggestions(index, hide){
		if(hide){
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
		}
		else{
			document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "block";
		}
	}

	onKeyDown(index, e, searchIndex){
		if(e.target.name == "customer" || e.target.name == "customer-suggestions"){
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-customer-suggestions');
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-customer-input').focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showCustomerSuggestions(1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				this.setState({
					customer: this.state.searchCustomers[searchIndex]
				})
				this.calculateTax()
				document.getElementById('accounting-invoice-customer-input').value = this.state.searchCustomers[searchIndex].name;
				document.getElementById('accounting-invoice-customer-suggestions-container').style.display = "none";
			}
		}
		else{
			if(e.key=="ArrowDown"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != undefined && searchIndex + 1 < suggestions.length)
					suggestions[searchIndex + 1].focus();
				else if(suggestions.length > 0 && searchIndex == undefined)
					suggestions[0].focus();
			}
			if(e.key=="ArrowUp"){
				e.preventDefault();
				let suggestions = document.getElementsByClassName('accounting-invoice-product-name-suggestions ' + index);
				if(suggestions.length > 0 && searchIndex != 0)
					suggestions[searchIndex != undefined ? searchIndex - 1 : 0].focus();
				else if(searchIndex == 0){
					document.getElementById('accounting-invoice-product-input-name' + index).focus()
				}
			}
			if(e.key == "Escape"){
				e.preventDefault();
				this.showProductSuggestions(index, 1)
			}
			if(e.keyCode == 13){
				e.preventDefault();
				if(this.state.searchProducts[searchIndex]['name'] == "CREATE NEW"){
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
					this.setState({
						createProduct: true,
						indexForNewProduct: index,
					})
				}
				else{
					var products = this.state.sale.products;
					var sale = this.state.sale;
					products[index] = this.state.searchProducts[searchIndex]
					products[index]['qty'] = 1
					sale.products = [...products]
					this.setState({
						sale: sale
					})

					this.calculateTax();
					e.target.blur();
					document.getElementById('accounting-invoice-product-name-suggestions-container' + index).style.display = "none";
				}
			}
		}
	}

	newProductCreated(product){
		var products = this.state.sale.products;
		var sale = this.state.sale;
		products[this.state.indexForNewProduct] = product
		products[this.state.indexForNewProduct]['productId'] = product.itemId
		products[this.state.indexForNewProduct]['qty'] = 1
		sale.products = [...products]
		this.setState({
			sale: sale,
			createProduct: false
		})
		this.calculateTax();
	}

	createOrderSlip(){
		Client.accountingInvoicecreateOrderSlip(this.state.sale, this.state.customer, localStorage.getItem('currentInvoiceSaleEdit')).then(res=>{
	        this.props.history.push('/accounting/quotation')
        })
	}

	render(){
		var amountTableTaxData = <div></div>;
		this.state.taxDetails.forEach(function(taxDetail){
			if(taxDetail.taxStateType == 1)
			{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>IGST [{taxDetail.taxPc}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount)}</td></tr>
				</tbody>
			}
			else{
				amountTableTaxData = amountTableTaxData +
				<tbody>
					<tr><td>CGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
					<tr><td>SGST [{taxDetail.taxPc / 2}%]</td><td>:</td><td className="accounting-invoice-amount-value">{this.perfectDecimal(taxDetail.taxAmount/2)}</td></tr>
				</tbody>
			}
		}.bind(this))

		return(
		    <div>
				<Input list='customers' name='customer'
					className="accounting-invoice-input"
					id="accounting-invoice-customer-input"
					onChange={(e, data) => this.searchCustomer(e, data)}
					placeholder='Choose Customer...'
					onKeyDown={(e) => this.onKeyDown(0, e)}
					autoComplete="off"/>
				<div style={{position:'absolute', zIndex:'1', display: 'none', height:'40vh', overflow:'auto'}}
					id={"accounting-invoice-customer-suggestions-container"}>
					{
						this.state.searchCustomers.map((searchCustomer, searchIndex) => (
							<button className={"accounting-invoice-customer-suggestions"} name="customer-suggestions"
								onKeyDown={(e) => this.onKeyDown(0, e, searchIndex)} tabIndex="-1">
								{searchCustomer.name}
							</button>
						))
					}
				</div>
				<Input placeholder='Slip No.' name="slipNo" value={this.state.sale.slipNo}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='date' placeholder="Slip Creation Date" name="startDate" value={this.state.sale.startDate}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='text' placeholder="Requirement" name="requirement" value={this.state.sale.requirement}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Dropdown placeholder="Vendor" fluid selection search name="vendor" value={this.state.sale.vendor} options={this.state.vendors}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='text' placeholder="Current Status" name="currentStatus" value={this.state.sale.currentStatus}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='text' placeholder="Current Person" name="currentPerson" value={this.state.sale.currentPerson}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='text' placeholder="Next Status" name="nextStatus" value={this.state.sale.nextStatus}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Input type='text' placeholder="Next Person" name="nextPerson" value={this.state.sale.nextPerson}
					className="accounting-invoice-product-input" onChange={(e, data) => this.handleInputChangeSale(e, data)}/>
				<Button primary onClick={() => this.createOrderSlip()}>CREATE ORDER</Button>
			</div>
        )
	}
}

export default OrderSlipCreate;
