import React, {Component} from 'react';
import '../style/accounting-invoice.css';
// import PrintTemplate from 'react-print';
import Client from '../services/Client';
import {Input, Table, Button, Dropdown} from 'semantic-ui-react';
import RemoveImage from '../assets/blue/ic_minus.png';
import CreateAccountingItem from './create-accounting-item';

const logo = require('../assets/DHP_ICON.png')
const converter = require('number-to-words');

class AccountingItem extends Component{

	state = {
		items: []
	}

	componentDidMount() {
		this.loadItems = this.loadItems.bind(this);
		this.loadItems();
	}

	handleInputItem(e, data){
		var item = this.state.item;
		item[data.name] = e.target.value
		this.setState({
			item: item
		})
	}

	loadItems(){
		Client.getAccountingItems().then(res=>{
            this.setState({
                items: res.items,
				currentItem: null
            })
        })
	}

    showItemDetails(index){
		var item = {...this.state.items[index]}
		this.setState({
			currentItem: item
		})
    }

	newProductCreated(product){
		this.loadItems();
		if(this.props.newProductCreated != undefined)
			this.props.newProductCreated(product);
	}

	render(){
		return(
		    <div style={{background:'#ffffff', height:'100%', width:'100%'}}>
                <div style={{float:'left', width:'30%', overflowY:'scroll', height:'80vh'}}>
                {
                    this.state.items.map((item, index) => (
                        <div style={{padding:'5px', margin:'2px', border:'solid #CCC 1px'}} onClick={() => this.showItemDetails(index)}>
                            <table style={{width:'100%'}}>
                                <tr style={{fontWeight:'bold'}}>
                                    <td>{item.name}</td>
								</tr>
                                <tr style={{fontWeight:'bold'}}>
                                    <td style={{textAlign:'right'}}>{item.rate}</td>
                                </tr>
                                <tr>
                                    <td>{item.tax + '%'}</td>
								</tr>
                                <tr>
									<td style={{textAlign:'right'}}>{item.HSN}</td>
                                </tr>
                            </table>
                        </div>
                    ))
                }
                </div>
                <div style={{float:'left', width:'70%'}} id="showInvoiceContainerMain">
                    <CreateAccountingItem history={this.props.history} loadItems={this.loadItems}
						item={this.state.currentItem} newProductCreated={(product)=>this.newProductCreated(product)}/>
                </div>
			</div>
        )
	}
}

export default AccountingItem;
